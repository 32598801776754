import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  format,
  setYear,
  setMonth,
  setDate,
  addMonths,
  getMonth,
  subDays,
} from 'date-fns';

import { connect } from 'react-redux';
import styles from './confirmRequestModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import {
  isSumValid2,
  isInterestRateValid,
  isInterestVariableRateValid
} from './../../utils/auth/validations';
// import { errorhandle } from '../../utils/helpers/helpers';
import { validateTextinput } from './../../utils/auth/validations';

const ConfirmRequestModal = ({
  open,
  requestId,
  loading,
  dispatch,
  comment,
  invegaSigningDate,
  formVersion,
  invegaLoanDelayTerm2,
  invegaPaymentDate2,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [invegaLoanInterestRate, setInvegaLoanInterestRate] = useState(null);
  const [invegaLoanVariableInterestRate, setInvegaLoanVariableInterestRate] = useState(null);
  const [invegaLoanPaymentTerm, setinvegaLoanPaymentTerm] = useState(null);
  const [invegaLoanDelayTerm, setInvegaLoanDelayTerm] = useState(null);
  const [firstInterestChangeDate, setFirstInterestChangeDate] = useState(null);
  const [euriborChangeDate, seteuriborChangeDate] = useState(null);
  const [invegaLoanSum, setInvegaLoanSum] = useState(null);
  const [loanPreparationTax, setLoanPreparationTax] = useState(null);
  const [invegaContractNumber, setInvegaContractNumber] = useState(null);
  const [interestTypeId, setInterestTypeId] = useState(1);
  const [euriborPeriodId, setEuriborPeriodId] = useState(3);
  const [invegaDeMinimis, setInvegaDeMinimis] = useState(null);
  const [invegaSchema, setInvegaSchema] = useState(null);

  //const [invegaLoanDelayTermDate, setInvegaLoanDelayTermDate] = useState(
  //  invegaLoanDelayTermDate2
  //);
  const [invegaPaymentDate, setInvegaPaymentDate] =
    useState(null);

  const [fileToUpload, setFileToUpload] = useState(null);

  const cancel = () => {
    setNotValid(false);
    setInvegaLoanInterestRate(null);
    setInvegaLoanVariableInterestRate(null);
    setinvegaLoanPaymentTerm(null);
    setFirstInterestChangeDate(null);
    seteuriborChangeDate(null);
    setinvegaLoanPaymentTerm(null);
    setInvegaLoanDelayTerm(null);
    setInvegaLoanSum(null);
    setLoanPreparationTax(null);
    setInvegaContractNumber(null);
   // setInterestTypeId(null);
    //setEuriborPeriodId(null);
    setInvegaDeMinimis(null);
    setInvegaSchema(null);
    //setInvegaLoanDelayTermDate(null);
    setInvegaPaymentDate(null);
    setFileToUpload(null);

    const open = {
      modalName: 'confirmRequestModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const validateFieldsBasedOnInterestType = (selectedField) => {
    if (interestTypeId != '2') {
      return true;
    }
    return selectedField;
  };

  const yes = async () => {
    if (
      invegaLoanInterestRate &&
      //validateFieldsBasedOnInterestType(invegaLoanVariableInterestRate) &&
      invegaLoanPaymentTerm &&
      //validateFieldsBasedOnInterestType(euriborChangeDate) &&
      //validateFieldsBasedOnInterestType(euriborPeriodId) &&
      //invegaLoanDelayTerm &&
      //checkLoanDelayTermFormat(invegaLoanDelayTerm) === null &&
      isSumValid2(invegaLoanSum) === null &&
      isInterestRateValid(loanPreparationTax) === null &&
      isInterestRateValid(invegaLoanInterestRate) === null &&
      //isInterestVariableRateValid(invegaLoanVariableInterestRate) === null &&
      invegaContractNumber &&
       //interestTypeId &&
      //euriborPeriodId &&
      invegaSchema &&
      invegaLoanDelayTerm &&
      invegaPaymentDate &&
      fileToUpload
    ) {
      const formData = new FormData();
      const formattedData = invegaLoanPaymentTerm
        ? format(new Date(invegaLoanPaymentTerm), 'yyyy-MM-dd')
        : '';

      const formattedData2 = firstInterestChangeDate
       ? format(new Date(firstInterestChangeDate || ''), 'yyyy-MM-dd')
        : '';
      const formattedData3 = euriborChangeDate
        ? format(new Date(euriborChangeDate), 'yyyy-MM-dd')
        : '';
      formData.append('RequestId', requestId);
      formData.append('InvegaLoanInterestRate', invegaLoanInterestRate);
      formData.append('InvegaLoanPaymentTerm', formattedData);
      if (interestTypeId == 3) {
       formData.append('FirstInterestChangeDate', formattedData2);
      }
      formData.append('InterestTypeId', interestTypeId ? interestTypeId : 1);
      if (interestTypeId == 1 || interestTypeId == 3) {
        formData.append('EuriborChangeDate', formattedData3);
        formData.append('EuriborPeriodId', euriborPeriodId ? euriborPeriodId : 1);
        formData.append(
          'InvegaLoanVariableInterestRate',
          invegaLoanVariableInterestRate
        );
      }
      
      formData.append('InvegaLoanDelayTerm', invegaLoanDelayTerm);
      formData.append('InvegaLoanSum', invegaLoanSum);
      formData.append('LoanPreparationTax', loanPreparationTax);
      formData.append('InvegaContractNumber', invegaContractNumber);

      if (invegaDeMinimis || invegaDeMinimis === 0) {
        formData.append('DeMinimis', invegaDeMinimis);
      }
      formData.append('StateHelpSchemaId', invegaSchema);
      //formData.append(
      //  'InvegaLoanDelayTermDate',
      //  invegaLoanDelayTermDate
      //    ? format(new Date(invegaLoanDelayTermDate), 'yyyy-MM-dd')
       //   : ''
      //);
      formData.append(
        'InvegaPaymentDate',
        invegaPaymentDate
          ? format(new Date(invegaPaymentDate), 'yyyy-MM-dd')
          : ''
      );
      formData.append('File', fileToUpload);
      setIsloading(true);
      const doubleCheck = await Api.doubleCheckRequestEvaluation(requestId);
      if (doubleCheck && doubleCheck?.Decision === 1) {
        fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadContract(comment), {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
          body: formData,
        })
          .then((res) => {
            if (res.ok === true) {
              dispatch(actions.backoffice.getRequestAction(requestId));
              dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
              dispatch(
                actions.backoffice.saveEditAdditionalComments('get', requestId)
              );
              dispatch(actions.backoffice.getRequestLogAction(requestId));

              cancel();

              swal({
                title: 'Paraiška sėkmingai pateikta pareiškėjo pasirašymui',
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn btn-pink',
                    closeModal: true,
                  },
                },
              });
            } else if (res.status === 403) {
              swal({
                title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            } else {
              res.json().then((r) => {
                swal({
                  title: `${
                    r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
                  }`,
                  icon: 'error',
                  buttons: {
                    confirm: {
                      text: 'Gerai',
                      value: true,
                      visible: true,
                      className: 'btn swal-ok-btn',
                      closeModal: true,
                    },
                  },
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsloading(false);
          });
      } else {
        swal({
          title: `Paraiška negali būti finansuojama nes neatitinka visų finansavimo taisyklių`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
        setIsloading(false);
        cancel();
      }
    } else {
      setNotValid(true);
    }
  };
  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const onInvegaSchemaChange = (val) => {
    setInvegaSchema(val.target.value);
  };

  const onInterestTypeIdChange = (val) => {
    setInterestTypeId(val.target.value);
  };

  const onEuriborPeriodIdChange = (val) => {
    setEuriborPeriodId(val.target.value);
  };

  const checkLoanDelayTermFormat = (val) => {
    if (!Number.isInteger(+val)) {
      return 'Turi būti įvestas sveikas skaičius';
    }
    // if (
    //   +val > 0 &&
    //   +val <= 120 &&
    //   invegaLoanPaymentTerm &&
    //   +val > +invegaLoanPaymentTerm
    // ) {
    //   return ' lauko [paskolos atidėjimo terminas (mėn)] reikšmė negali būti didesnė negu lauko [Pageidaujamas Paskolos terminas mėn].';
    // }
    if (+val >= 1 && +val <= 120) {
      return null;
    }

    return 'Turi būti įvestas sveikas skaičius nuo 1 iki 120';
  };

  const returnFirstInterestChangeDate = () => {
    const invedaSign = new Date(invegaSigningDate);

    if (isNaN(invedaSign)) {
      return '';
    }
    if (euriborPeriodId == '4') {
      let nextYearFirstDay = setYear(invedaSign, invedaSign.getFullYear() + 1);
      nextYearFirstDay = setMonth(nextYearFirstDay, 0);
      nextYearFirstDay = setDate(nextYearFirstDay, 1);

      return nextYearFirstDay;
    } else if (euriborPeriodId == '3') {
      const month = getMonth(invedaSign);
      let halfYearLater = new Date(invedaSign);

      if (month > 5) {
        halfYearLater = addMonths(invedaSign, 6);
        halfYearLater = setMonth(halfYearLater, 0);
        halfYearLater = setDate(halfYearLater, 1);
      } else {
        halfYearLater = setMonth(halfYearLater, 5);
        halfYearLater = setDate(halfYearLater, 1);
      }

      return halfYearLater;
    } else if (euriborPeriodId == '2') {
      const month = getMonth(invedaSign);
      let quarterYearLater = new Date(invedaSign);

      if (month < 3) {
        quarterYearLater = setMonth(quarterYearLater, 3);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 2 && month < 6) {
        quarterYearLater = setMonth(quarterYearLater, 7);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 5 && month < 9) {
        quarterYearLater = setMonth(quarterYearLater, 9);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 8) {
        quarterYearLater = setYear(invedaSign, invedaSign.getFullYear() + 1);
        quarterYearLater = setMonth(quarterYearLater, 0);
        quarterYearLater = setDate(quarterYearLater, 1);
      }

      return quarterYearLater;
    }

    return '';
  };

    //useEffect(() => {
  //   setfirstInterestChangeDate(returnFirstInterestChangeDate());
  // }, [euriborPeriodId]);
  
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={isLoading}>
            <h2>Pateikti pareiškėjo pasirašymui</h2>
            <div className={styles.inputsContainer}>
              <div
                className={styles.inputsInline}
                style={{ marginLeft: '1.5rem' }}
              >
                <div style={{ height: '110px' }}>
                  <InputWithTextInline
                    type="string"
                    value={invegaContractNumber}
                    onChange={(e) => setInvegaContractNumber(e.target.value)}
                    placeholder={'Sutarties numeris'}
                    disabled={false}
                  />
                  {notValid && !invegaContractNumber && (
                    <p
                      className={styles.error}
                      style={{ textAlign: 'center', width: '100%' }}
                    >
                      Neįvestas sut. numeris
                    </p>
                  )}
                </div>
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '0.4rem', width: '180px' }}
                >
                  <label
                    style={{ marginTop: '13px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Galutinis paskolos grąžinimo terminas
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    value={
                      invegaLoanPaymentTerm
                        ? new Date(invegaLoanPaymentTerm)
                        : ''
                    }
                    selected={
                      invegaLoanPaymentTerm
                        ? new Date(invegaLoanPaymentTerm)
                        : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setinvegaLoanPaymentTerm(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                    style={{ width: '50px' }}
                  />
                </div>
                {notValid && !invegaLoanPaymentTerm && (
                  <p className={styles.error}>Neįvestas terminas</p>
                )}
                {/* <div
                  className={styles.bottomInput}
                  style={{ marginTop: '0.4rem' }}
                >
                  <label
                    style={{ marginTop: '13px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Atidėjimo terminas
                  </label>
                  <select
                    name="invegaLoanDelayTerm"
                    onChange={(e) => setInvegaLoanDelayTerm(e.target.value)}
                    value={invegaLoanDelayTerm}
                  >
                    {[null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                      (val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      )
                    )}
                  </select>
                </div>
                {notValid && !invegaLoanDelayTerm && (
                  <p className={styles.error}>Įveskite atidėjimo terminą</p>
                )} */}

<div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                ><InputWithTextInline
                type="string"
                value={invegaLoanInterestRate}
                onChange={(e) => setInvegaLoanInterestRate(e.target.value)}
                placeholder={'Marža, %'} // ex{'Palūkanų norma'}
                disabled={false}
              />
              {notValid && (
                <p className={styles.error}>
                  {isInterestRateValid(
                    invegaLoanInterestRate,
                    'privalomas laukas',
                    3
                  )}
                  {/* Privalote įrašyti paskolos sumą */}
                </p>
              )}
                 
                </div>
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-0.6rem' }}
                >
                  <label
                    style={{ marginTop: '33px', width: '110%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Palūkanų normos rūšis
                  </label>

                  <select
                    disabled={false}
                    name="interestTypeId"
                    onChange={onInterestTypeIdChange}
                    value={interestTypeId}
                    style={{ marginTop: '2px' }}
                  >
                    <option value={'1'}>Kintama</option>
                    <option value={'2'}>Fiksuota</option>
                    <option value={'3'}>Dalinai fiksuota</option>
                  </select>
                </div>
                {interestTypeId != '2' && (
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-0.8rem' }}
                >
                  <label
                    style={{ marginTop: '43px', width: '110%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Taikomo Euribor data
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '20px'  }}
                    value={euriborChangeDate ? new Date(euriborChangeDate) : ''}
                    selected={
                      euriborChangeDate ? new Date(euriborChangeDate) : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => seteuriborChangeDate(e)}
                    minDate={subDays(new Date(), 10)}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && !euriborChangeDate && interestTypeId != '2' && (
                    <p className={styles.error}>Neįvesta data</p>
                  )}
                </div>)}
                <div style={{ height: '110px' }}>
                  <label
                    style={{ marginTop: '25px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Valstybės pagalbos schema
                  </label>
                  {formVersion !== 3 && (
                  <select
                    disabled={false}
                    name="invegaSchema"
                    onChange={onInvegaSchemaChange}
                    value={invegaSchema}
                    style={{ marginBottom: '10px' }}
                  >
                    <option value={null}>{null}</option>
                
                    <option value={'1'}>Temporary framework 3.1</option>
                    <option value={'2'}>Temporary Framework 3.2</option>
                    <option value={'3'}>DeMinimis Regulation</option>
                    <option value={'4'}>Without guarantee</option>
                  </select>)}
                  {formVersion == 3 && (
                  <select
                    disabled={false}
                    name="invegaSchema"
                    onChange={onInvegaSchemaChange}
                    value={invegaSchema}
                    style={{ marginBottom: '10px' }}
                  >
                    <option value={null}>{null}</option>
                
                    <option value={'5'}>GBER</option>
                    <option value={'6'}>DeMinimis</option>
                  </select>)}
                  {notValid && !invegaSchema && (
                    <p
                      className={styles.error}
                    >
                      Pasirinkite schemą
                    </p>
                  )}
                </div>
                <div style={{ height: '10px', marginTop: '-30px' }}>
                  <InputWithTextInline
                    type="string"
                    value={loanPreparationTax}
                    onChange={(e) => setLoanPreparationTax(e.target.value)}
                    placeholder={'Sutarties paruošimo mokestis'}
                    disabled={false}
                  />
                  {notValid && (
                    <p className={styles.error}>
                      {isInterestRateValid(
                        loanPreparationTax,
                        'privalomas laukas',
                        3
                      )}
                      {/* Privalote įrašyti paskolos sumą */}
                    </p>
                  )}
                </div>
              </div>

              <div
                className={styles.inputsInline}
                style={{ marginRight: '2.5rem' }}
              >
                <div style={{ height: '135px' }}>
                  <InputWithTextInline
                    type="string"
                    value={invegaLoanSum}
                    onChange={(e) => setInvegaLoanSum(e.target.value)}
                    placeholder={'Paskolos suma'}
                    disabled={false}
                  />
                  {notValid && (
                    <p className={styles.error}>
                      {isSumValid2(invegaLoanSum, 'privalomas laukas', 3)}
                      {/* Privalote įrašyti paskolos sumą */}
                    </p>
                  )}
                </div>
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                >
                  {/*<label
                    //style={{ marginTop: '13px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Paskolos atidėjimo terminas
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '0px' }}
                    value={
                      invegaLoanDelayTermDate
                        ? new Date(invegaLoanDelayTermDate)
                        : ''
                    }
                    selected={
                      invegaLoanDelayTermDate
                        ? new Date(invegaLoanDelayTermDate)
                        : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setInvegaLoanDelayTermDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && !invegaLoanDelayTermDate && (
                    <p
                      className={styles.error}
                      style={{ marginLeft: '2.2rem' }}
                    >
                      Neįvesta data
                    </p>
                  )}*/}
                   <label
                    style={{ marginTop: '13px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Paskolos atidėjimo terminas (mėn.)
                  </label>
                   <InputWithTextInline
                    type="string"
                    value={invegaLoanDelayTerm}
                    onChange={(e) => setInvegaLoanDelayTerm(e.target.value)}
                   // placeholder={'Paskolos atidėjimo terminas (mėn.)'}
                    disabled={false}
                    style={{ marginTop: '-2.1rem' }}
                  />
                  {notValid &&
                    (validateTextinput(
                      invegaLoanDelayTerm,
                      'privalomas laukas',
                      1
                    ) ||
                      checkLoanDelayTermFormat(invegaLoanDelayTerm)) && (
                      //!disableCheck('LoanDelayTerm') &&
                      <p className={styles.error} style={{ width: '180px' }}>
                        {validateTextinput(
                          invegaLoanDelayTerm,
                          'privalomas laukas',
                          1
                        )}
                        {!validateTextinput(
                          invegaLoanDelayTerm,
                          'privalomas laukas',
                          1
                        ) && checkLoanDelayTermFormat(invegaLoanDelayTerm)}
                      </p>
                        )}
                </div>
                <div
                  style={{
                    marginRight: '2.5rem',
                    width: '180px',
                    marginTop: '1.25rem',
                  }}
                >
                  <label
                    style={{ marginTop: '3px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Išmokėjimo terminas{' '}
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '0px' }}
                    value={invegaPaymentDate ? new Date(invegaPaymentDate) : ''}
                    selected={
                      invegaPaymentDate ? new Date(invegaPaymentDate) : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setInvegaPaymentDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && !invegaPaymentDate && (
                    <p
                      className={styles.error}
                    >
                      Neįvesta data
                    </p>
                  )}
                </div>
                {interestTypeId != '2' && (
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                >
                  <InputWithTextInline
                type="string"
                value={invegaLoanVariableInterestRate}
                onChange={(e) => setInvegaLoanVariableInterestRate(e.target.value)}
                placeholder={'Kintama palūkanų dalis, %'}
                disabled={false}
              />
              {notValid && interestTypeId != '2' && (
                <p className={styles.error} style={{width:'250px'}}>
                  {isInterestVariableRateValid(
                    invegaLoanVariableInterestRate,
                    'privalomas laukas',
                    3
                  )}
                </p>
              )}
                 
                </div>
                )}
                {interestTypeId != '2' && (
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                >
                  <label
                    style={{ marginTop: '33px', width: '110%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Palūkanų normos perskaičiavimo dažnumas
                  </label>
                  <select
                    disabled={false}
                    name="euriborPeriodId"
                    onChange={onEuriborPeriodIdChange}
                    value={euriborPeriodId}
                    defaultValue={'3'}
                  >
                    <option value={'1'}>1 mėn.</option>
                    <option value={'2'}>3 mėn.</option>
                    <option value={'3'}>6 mėn.</option>
                    <option value={'4'}>12 mėn.</option>
                  </select>
                </div>)}

                <div style={{ height: '10px', marginTop: '1rem' }}>
                  <label
                      style={{ marginTop: '0.7rem', width: '110%' }}
                      className="inlineDatepickerInputTitle"
                    >De minimis suma, EUR
                     </label>
                     <div
                        className={styles.bottomInput}
                        style={{ marginTop: '-2.1rem' }}
                      >
                        <InputWithTextInline
                          type="string"
                          value={invegaDeMinimis}
                          onChange={(e) => setInvegaDeMinimis(e.target.value)}
                          //placeholder={'De minimis suma, EUR'}
                          disabled={false}
                        /></div>
                        {+invegaSchema === 3 && !invegaDeMinimis && (
                          <p
                            className={styles.error}
                            style={{ textAlign: 'left', width: '100%' }}
                          >
                            Įveskite De minimis
                          </p>
                        )}
                      </div>
                      {interestTypeId == '3' && (
                <div
                  style={{
                    marginRight: '2.5rem',
                    width: '180px',
                    marginTop: '5.5rem'
                    //marginTop: '1.25rem',
                  }}
                >
                  <label
                    style={{ marginTop: '3px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Pirma palūkanų keitimo diena{' '}
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '0px' }}
                    value={firstInterestChangeDate ? new Date(firstInterestChangeDate) : ''}
                    selected={
                      firstInterestChangeDate ? new Date(firstInterestChangeDate) : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setFirstInterestChangeDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && interestTypeId == '3' && !firstInterestChangeDate && (
                    <p
                      className={styles.error}
                    >
                      Neįvesta data
                    </p>
                  )}
                </div>
                )}
                </div>
            </div>
            <div
              className={styles.addFileContainer}
              style={{ marginTop: '510px' }}
            >
              <Button type="standart" onClick={() => selectFile('fileElem')}>
                Prisegti priedą
              </Button>
              <input
                type="file"
                id="fileElem"
                accept="application/pdf"
                className="d-none"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <p style={{ marginTop: '0.5rem' }}>
                {fileToUpload && fileToUpload.name ? fileToUpload.name : ''}
              </p>
              {notValid && !fileToUpload && (
                <p
                  className={styles.error}
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  Privalote pasirinkti priedą
                </p>
              )}
            </div>
            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(requestId)}>
                Išsaugoti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.dashboardState.isOnSync,
// });

export default connect()(ConfirmRequestModal);
