import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from '../changeContractFile/confirmRequestModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { getLocalStorageItemIfNotExpired } from '../../utils';

import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import './changeConditionsModal.scss';

const ChangeConditionsModal = ({ open, requestId, dispatch }) => {
  const [isLoading, setIsloading] = useState(false);
  const [notValid, setNotValid] = useState(false);

  const [fileToUpload, setFileToUpload] = useState([]);

  const [comment, setComment] = useState('');

  const onChangeContractCommentChange = ({ target: { name, value } }) => {
    setComment(value);
  };

  const selectFileToUpload = (file) => {
    //console.log(file);
    setFileToUpload([...fileToUpload, file]);
  };
  const cancel = () => {
    setNotValid(false);
    setFileToUpload(null);

    const open = {
      modalName: 'changeConditionsModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    console.log(fileToUpload);
    if (fileToUpload && fileToUpload.length > 0) {
      const formData = new FormData();

      formData.append('RequestId', requestId);
      formData.append('Comment', comment);

      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append('Files', fileToUpload[i]);
      }

      setIsloading(true);
      fetch(
        Api.API_BASE_URL +
          config.API_ENDPOINTS.RequestChangeConditions(requestId),
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
          },
          body: formData,
        }
      )
        .then((res) => {
          if (res.ok === true) {
            const loginType = getLocalStorageItemIfNotExpired('loginType');
            if (loginType === 'Company') {
              dispatch(
                actions.dashboard.createNewRequestAction(requestId, 'view')
              );
            } else if (loginType === 'Individual') {
              dispatch(
                actions.dashboard.createNewRequestIndividualAction(
                  requestId,
                  'view'
                )
              );
            } else {
              swal({
                title: 'Įvyko klaida',
                icon: 'Error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            }
            dispatch(actions.dashboard.getUploadedFileList(requestId));

            cancel();

            swal({
              title: 'Prašymas pakeisti sutartį sėkmingai pateiktas',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              console.log(r);

              swal({
                title: `${
                  r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
                }`,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
        });
    } else {
      setNotValid(true);
    }
  };
  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <div className="changeConditionsModal">
            <WithSpinner loading={isLoading}>
              <h2>Prašymas pakeisti sutarties sąlygas</h2>
              <p>Prašome prisegti priedą(-us) sutarties sąlygų pakeitimui</p>
              <div
                className={styles.addFileContainer}
                style={{ marginTop: '2rem' }}
              >
                <Button type="standart" onClick={() => selectFile('fileElem')}>
                  Prisegti priedus
                </Button>
                <input
                  type="file"
                  id="fileElem"
                  accept="application/pdf"
                  className="d-none"
                  onChange={(e) => selectFileToUpload(e.target.files[0])}
                />
                <div style={{ marginTop: '0.5rem' }}>
                  {fileToUpload && fileToUpload?.length > 0
                    ? fileToUpload.map((val, i) => (
                        <p key={i} style={{ marginTop: '0px' }}>
                          {val.name}
                        </p>
                      ))
                    : ''}
                </div>
                {notValid && !fileToUpload && (
                  <p
                    className={styles.error}
                    style={{ textAlign: 'center', width: '100%' }}
                  >
                    Privalote pasirinkti priedą
                  </p>
                )}
              </div>
              <div>
                <h4 style={{ marginTop: '20px' }}>Komentaras</h4>
                <textarea
                  style={{
                    width: '100%',
                    height: '80px',
                    marginBottom: '10px',
                    marginTop: '10px',
                    padding: '5px',
                    lineHeight: '1.2rem',
                  }}
                  placeholder=""
                  name={'InvegaReason'}
                  type="string"
                  onChange={onChangeContractCommentChange}
                  value={comment}
                />
              </div>
              <div className="changeConditionsModal__btnContainer">
                <Button type="standart" onClick={() => yes(requestId)}>
                  Pateikti prašymą
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </WithSpinner>
          </div>
        </div>
      </div>
    )
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.dashboardState.isOnSync,
// });

export default connect()(ChangeConditionsModal);
