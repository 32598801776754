import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './surveyModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { history } from './../../App';
import { isValid } from 'date-fns';

const SurveyModal = ({ open, id, loading, dispatch }) => {
  const url = new URL(window.location.href);
  const requestId = url.searchParams.get('requestId');

  const forwardTo = (location) => {
    history.push(location);
    window.location.reload();
  };

  const closeModal = {
    modalName: 'surveyModal',
    visible: false,
  };

  const cancel = () => {
    dispatch(actions.modals.setModalVisibleFlagAction(closeModal));
  };

  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const [surveyValues, setSurveyValues] = useState({
    starterRatingId: '-',
    invegaCommunicationRatingId: '-',
    invegaFinancialRatingId: '-',
  });

  const onDropdownChange = ({ target: { name, value } }) => {
    console.log({ name, value });
    setSurveyValues({ ...surveyValues, [name]: value });

    console.log(surveyValues);
  };

  const isValid = () => {
    console.log(surveyValues.starterRatingId);
    if (
      surveyValues.starterRatingId !== '-' &&
      surveyValues.invegaCommunicationRatingId !== '-' &&
      surveyValues.invegaFinancialRatingId !== '-'
    ) {
      return true;
    }
    return false;
  };

  const yes = () => {
    setTriedToSubmit(true);
    if (isValid()) {
      dispatch(
        actions.dashboard.createNewPaymentRequestAction(
          null,
          'create',
          requestId,
          null,
          surveyValues
        )
      );
      dispatch(actions.modals.setModalVisibleFlagAction(closeModal));
    }
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <h2>
              FINANSINĖS PRIEMONĖS „STARTUOK, FINANSUOJAMOS IŠ EUROPOS
              REGIONINĖS PLĖTROS FONDO" PASKOLOS GAVĖJO APKLAUSOS ANKETA
            </h2>

            <table className={styles.table}>
              <tr>
                <td>
                  1. Kaip vertinate finansinės priemonės „Startuok,
                  finansuojamos iš Europos regioninės plėtros fondo" (toliau -
                  finansinė priemonė) pridėtinę vertę verslo skatinimui (ar
                  paskatina ir padeda verslui)?
                </td>
                <td>
                  <select
                    name="starterRatingId"
                    onChange={onDropdownChange}
                    value={surveyValues.starterRatingId}
                  >
                    <option value={'-'}> - </option>
                    <option value={1}> Labai gerai </option>
                    <option value={2}> Gerai </option>
                    <option value={3}> Patenkinamai </option>
                    <option value={4}> Blogai </option>
                  </select>
                </td>
              </tr>
              <tr>
                {' '}
                <td>
                  2. Kaip vertinate UAB „INVESTICIJŲ IR VERSLO GARANTIJOS"
                  komunikaciją ir konsultavimą apie finansinę priemonę?{' '}
                </td>
                <td>
                  {' '}
                  <select
                    name="invegaCommunicationRatingId"
                    onChange={onDropdownChange}
                    value={surveyValues.invegaCommunicationRatingId}
                  >
                    <option value={null}> - </option>
                    <option value={1}> Labai gerai </option>
                    <option value={2}> Gerai </option>
                    <option value={3}> Patenkinamai </option>
                    <option value={4}> Blogai </option>
                  </select>
                </td>
              </tr>
              <tr>
                {' '}
                <td>
                  3. Kaip vertinate UAB „INVESTICIJŲ IR VERSLO GARANTIJOS"
                  teikiamų paslaugų, susijusių su finansine priemone, kokybę
                  (paskolos išdavimo procedūros, trukmė ir pan.)?{' '}
                </td>
                <td>
                  {' '}
                  <select
                    name="invegaFinancialRatingId"
                    onChange={onDropdownChange}
                    value={surveyValues.invegaFinancialRatingId}
                  >
                    <option value={null}> - </option>
                    <option value={1}> Labai gerai </option>
                    <option value={2}> Gerai </option>
                    <option value={3}> Patenkinamai </option>
                    <option value={4}> Blogai </option>
                  </select>
                </td>
              </tr>
            </table>
            {triedToSubmit && !isValid() && (
              <p className={styles.errorsBlockText}>
                Privalote užpildyti visus atsakymus
              </p>
            )}
            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(id)}>
                Išsaugoti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(SurveyModal);
