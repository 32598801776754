export const SelfDeclarationInfo = [
  {
    name: 'SelfDeclarationDirect',
    type: 'input',
    title:
      'Verslo subjektas ir/ar jo įmonių grupės įmonės yra gavusios valstybės pagalbą:',
    subtitles: [
      'pagal Komunikato 3.1 skirsnį „tiesioginėmis dotacijomis, grąžintinais avansais arba mokesčių lengvatomis teikiama pagalba“',
      'naudodamos parama iš Europos garantijų fondo (toliau – EGF) pagal Komunikato 3.1. skirsnį',
    ],
  },
  {
    name: 'SelfDeclarationCredit',
    type: 'input',
    title:
      'Verslo subjektas yra gavęs valstybės pagalbą „paskolų garantijomis teikiama pagalba“ naudodamasis:',
    subtitles: [
      'parama iš EGF, taikant EGF režimą kaip pagal Komunikato 3.2 skirsnį',
      'bet kokia nacionaline garantijų sistema, kuri veikia pagal Komunikato 3.2 skirsnį.',
    ],
  },
  {
    name: 'SelfDeclarationInterest',
    type: 'input',
    title: 'Verslo subjektas yra gavęs valstybės pagalbą naudodamasis:',
    subtitles: ['palūkanų normos subsidija pagal Komunikato 3.3 skirsnį'],
  },
  {
    name: 'SelfDeclarationWar',
    type: 'input',
    title:
      'Deklaracija apie gautą valstybės pagalbą pagal 2022 m. kovo 24 d. Europos Komisijos komunikatą dėl laikinosios valstybės pagalbos priemonių, skirtų ekonomikai remti krizės sąlygomis reaguojant į Rusijos agresiją prieš Ukrainą, sistemą ir vėlesnius pakeitimus',
  },
  {
    name: 'SelfDeclarationOther',
    type: 'input',
    title: 'Verslo subjektas yra gavęs valstybės pagalbą pagal Komunikato:',
    subtitles: [
      '3.6 skirsnį „pagalba su COVID-19 susijusiems moksliniams tyrimams ir plėtrai“ įgyvendinama (-os) priemonė (-ės)',
      '3.7 skirsnį „bandymo ir gamybos apimties didinimo infrastruktūrai skirta investicinė pagalba“ įgyvendinama (-os) priemonė (-ės)',
      '3.8 skirsnį „investicinė pagalba kovai su COVID-19 reikalingų produktų gamybai“ įgyvendinama (-os) priemonė (-ės)',
      'pagal Komunikato 3.10 skirsnį „darbo užmokesčio subsidijomis darbuotojams, siekiant išvengti atleidimo iš darbo per COVID-19 protrūkį, teikiama pagalba“',
      '3.12 skirsnį „pagalba paramos nepadengtoms pastoviosioms išlaidoms pavidalu“ įgyvendinama (-os) priemonė (-ės)',
    ],
  },

  {
    name: 'SelfDeclarationCheck1',
    type: 'checkbox',
    title:
      'Patvirtinu, kad tai pačiai investicijai negavau jokios kitos valstybės pagalbos',
  },
  {
    name: 'SelfDeclarationCheck2',
    type: 'checkbox',
    title:
      'Patvirtinu, kad man žinoma, jog valstybės pagalba pagal aukščiau išvardytus Komunikato skirsnius ir reglamentus, sumuojama su valstybės pagalba, gauta pagal EGF garantiją.',
  },
  {
    name: 'SelfDeclarationCheck3',
    type: 'checkbox',
    title:
      'Patvirtinu, kad, kiek man žinoma, nepasinaudojau valstybės pagalba, kurią Europos Komisija paskelbė neteisėta arba neatitinkančia reikalavimų ir kuri dar nėra grąžinta.',
  },
];
