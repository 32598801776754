import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styles from '../newRequest/newRequest.module.scss';
import { actions } from '../../state/actions';
import { WithSpinner, Button } from '../../components';
import InputWithTextInline from '../../components/input/InputWithTextInline';
import {
  isNotEmptyString,
  isValidIban,
  isValidDropdown,
  isValidDateTillToday,
  isSumValid,
  minimum2Symbols,
} from '../../utils/helpers/validators';

import { checkBOPage, formatNumber } from '../../utils/helpers/helpers';
import lt from 'date-fns/locale/lt';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import { config } from '../../api/config';
import { Api } from '../../api/api';
import { set } from 'date-fns';

const PaymentDocumentSumEdit = ({
  dispatch,
  loading,
  requestState,
  paymentRequest,
  paymentRequestPrDocument,
  confirmModalVisible,
  closedDialogOKMessage,
}) => {
  registerLocale('lt', lt);
  const history = useHistory();
  const [triedSave, setTriedSave] = useState(false);
  const url = new URL(window.location.href);
  const [actionType, setActionType] = useState(
    url.searchParams.get('actionType')
  );

  const checkBOEditPage = () => {
    return checkBOPage() &&
      paymentRequest?.ContractInfo?.PaymentRequestStatusId === 2 &&
      actionType === 'edit'
      ? true
      : false;
  };
  const getInputEditStatus = (boCanEdit) => {
    if (checkBOEditPage() && boCanEdit) {
      return false;
    }
    if (
      (paymentRequest?.ContractInfo?.PaymentRequestStatusId === 1 ||
        paymentRequest?.ContractInfo?.PaymentRequestStatusId === 3) &&
      (!paymentRequestPrDocument?.IsApproved || !paymentRequestPrDocument.Id)
    ) {
      if (actionType === 'view') {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const [boCanEditInfoValues, setBOCanEditInfoValues] = useState({});

  const [loanPurpose, setLoanPurpose] = useState(1);
  const [documentSum, setDocumentSum] = useState(0);
  const [investmentSumLeft, setInvestmentSumLeft] = useState(0);
  const [turnoverSumLeft, setTurnoverSumLeft] = useState(0);
  const [investmentSum, setInvestmentSum] = useState('');
  const [turnoverSum, setTurnoverSum] = useState('');
  const [managerInfo, setManagerInfo] = useState({});

  const getValue = (value, name) => {
    if (checkBOEditPage()) {
      return value
        ? convertStrValueToNumber(value)
        : boCanEditInfoValues[name]
        ? convertStrValueToNumber(boCanEditInfoValues[name])
        : 0;
    } else {
      return value || value === 0 ? convertStrValueToNumber(value) : '';
    }
  };

  const convertStrValueToNumber = (value) => {
    value = value?.toString().replace(/\s/gi, '');
    value = value?.toString().replace(/,/gi, '.');
    return value;
  };

  const checkMinusSumFormat = (sum, orgSum) => {
    if (sum == 0) {
      return null;
    }
    if (!sum && sum != 0) {
      return 'Neįvesta suma. Formatas -0.00 EUR';
    }
    // const ex = /^-?[0-9]\d*(\.\d+)?$/;

    const ex = /^-\d*\.?\d{0,2}$/;
    if (ex.test(sum) === false) {
      return 'Turi būti įvesti skaičiai (formatas -0.00)';
    }

    if (Math.sign(sum) !== -1) {
      return 'Įvesta suma privalo būti minusinė';
    }

    if (orgSum + +sum < 0) {
      return 'Įvesta suma negali viršyti originalios dukumento sumos';
    }

    return null;
  };

  const { ContractInfo, FinancialInfo, Remainders } = paymentRequest;

  const supplierTypeDropdown = {
    options: [
      { name: ' ', value: '', type: 'boolean' },
      { name: 'Įmonė', value: true, type: 'boolean' },
      { name: 'Fizinis asmuo', value: false, type: 'boolean' },
    ],
  };

  const documentTypeDropdown = {
    options: [
      { name: ' ', value: '', type: 'boolean' },
      {
        name: 'Sąskaita faktūra arba PVM sąskaita faktūra',
        value: 1,
        type: 'number',
      },
      { name: 'Darbo užmokesčio žiniaraštis', value: 2, type: 'number' },
    ],
  };

  const documentInfo = [
    /*{
      title: 'Eil. Nr.',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'Number',
    },*/
    {
      title: 'Tiekėjo tipas',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'dropdown',
      dropdownInfo: supplierTypeDropdown,
      name: 'SupplierIsCompany',
    },
    {
      title: 'Tiekėjo pavadinimas/Vardas pavardė',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'SupplierName',
    },
    {
      title: 'Tiekėjo įmonės kodas/Tiekėjo IV pažymėjimo arba VL Nr.',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'SupplierCode',
    },
    {
      title: 'Tiekėjo banko sąskaitos numeris',
      value: '',
      type: 'string',
      maxlength: 20,
      disabled: true,
      fieldType: 'input',
      name: 'Iban',
    },
    {
      title: 'Dokumento tipas',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'dropdown',
      dropdownInfo: documentTypeDropdown,
      name: 'DocumentType',
    },
    {
      title: 'Dokumento numeris',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'DocumentNumber',
    },
    {
      title: 'Dokumento data',
      value: '',
      type: 'date',
      disabled: true,
      fieldType: 'date',
      name: 'DocumentDate',
    },
    {
      title: 'Dokumento suma, EUR',
      value: null,
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'DocumentSum',
    },
    {
      title: 'Investicinė, EUR',
      value: null,
      type: 'numberString',
      disabled: false,
      fieldType: 'input',
      name: 'InvestmentSum',
      required: true,
      requiredType: 'numberSum',
      customFunction: () =>
        checkMinusSumFormat(
          documentInfoValues.InvestmentSum,
          documentInfoValues.OriginalInvestmentSum
        ),
      checkConnectedValues: true,
    },
    {
      title: 'Apyvartinė, EUR',
      value: null,
      type: 'numberString',
      disabled: false,
      fieldType: 'input',
      name: 'TurnoverSum',
      required: true,
      requiredType: 'numberSum',
      customFunction: () =>
        checkMinusSumFormat(
          documentInfoValues.TurnoverSum,
          documentInfoValues.OriginalTurnovertSum
        ),
      checkConnectedValues: true,
    },
    {
      title: 'INVEGA teikiamos paskolos lėšomis finansuojama suma, EUR',
      value: null,
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'RequiredSum',
    },
    {
      title: 'Išmokėta paskolos suma pagal paskolos tipą proc.:',
      value: '',
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'PaidOutTotalSumPercent',
      additionalSymbols: '%',
      showWhenStatus: [2],
      minFractionDigits: 4,
    },
    {
      title: 'Investicijoms',
      value: '',
      type: 'numberString',
      additionalSymbols: '%',
      disabled: true,
      fieldType: 'input',
      name: 'FundedInvestmentSumPercent',
      showWhenStatus: [2],
      minFractionDigits: 4,
    },
    {
      title: 'Apyvartai',
      value: '',
      type: 'numberString',
      additionalSymbols: '%',
      disabled: true,
      fieldType: 'input',
      name: 'FundedTurnoverSumPercent',
      showWhenStatus: [2],
      minFractionDigits: 4,
    },
    {
      title: 'Finansuojama suma, EUR',
      value: null,
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'FundedSum',
      showWhenStatus: [2],
    },
    {
      title: 'Pastabos',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'Comment',
    },
    // {
    //   title: 'Lėšų kilmė',
    //   value: '',
    //   type: 'string',
    //   disabled: getInputEditStatus(true),
    //   fieldType: 'dropdown',
    //   dropdownInfo: fundsOriginTypeDropdown,
    //   name: 'FundsOriginTypeId1',
    //   required: true,
    //   requiredType: 'custom',
    //   customFunction: () => checkFundsDropdown('1'),
    //   defaultErrorMessage: 'Turite pasirinkti dokumento tipą',
    //   boCanEdit: true,
    //   checkConnectedValues: true,
    // },
    {
      title: 'Kliento nuosavomis lėšomis finansuojama projekto dalis, EUR.',
      value: '',
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'FundsSum1',
    },
    // {
    //   title: 'Lėšų kilmė',
    //   value: '',
    //   type: 'string',
    //   disabled: getInputEditStatus(true),
    //   fieldType: 'dropdown',
    //   dropdownInfo: fundsOriginTypeDropdown,
    //   name: 'FundsOriginTypeId2',
    //   required: true,
    //   requiredType: 'custom',
    //   customFunction: () => checkFundsDropdown('2'),
    //   defaultErrorMessage: 'Turite pasirinkti dokumento tipą',
    //   boCanEdit: true,
    //   checkConnectedValues: true,
    // },
    {
      title:
        'Privataus finansuotojo (suteiktos paskolos) dalyvavimas projekte, EUR',
      value: '',
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'FundsSum2',
    },
  ];

  const k = {};
  documentInfo.forEach((el) => {
    k[el.name] = el.value;
  });
  const [documentInfoValues, setDocumentInfoValues] = useState(k);

  const setValueChanged = (value, name) => {
    if (saved || error) {
      dispatch(actions.dashboard.approveRequestResset(true));
    }
    if (!isUnsavedChanges) {
      setIsUnsavedChanges(true);
    }
    setDocumentInfoValues({ ...documentInfoValues, [name]: value });
  };

  const ValidateInput = (
    value,
    type,
    defaultMessage,
    minValue,
    maxValue,
    customFunction,
    checkConnectedValues,
    orgSum
  ) => {
    if (
      !checkBOPage() ||
      (checkBOPage() && value) ||
      (checkBOPage() && checkConnectedValues)
    ) {
      if (type === 'anyString') {
        return isNotEmptyString(value);
      } else if (type === 'iban') {
        return isValidIban(value);
      } else if (type === 'dropdown') {
        return isValidDropdown(value, defaultMessage);
      } else if (type === 'dateTillToday') {
        return isValidDateTillToday(value);
      } else if (type === 'numberSum') {
        return checkMinusSumFormat(value, orgSum);
      } else if (type === 'custom') {
        return customFunction();
      } else if (type === 'minimum2Symbols') {
        return minimum2Symbols(value);
      }
    }
    return '';
  };

  const submit = () => {
    setTriedSave(true);
    setTimeout(() => {
      const el = document.getElementsByClassName('error1');
      const el2 = Object.values(el)?.some((val) => val.innerText.length > 0);
      if (el2) {
        return;
      } else {
        save();
      }
    }, 0);
  };

  function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  const save = async (action) => {
    try {
      setTriedSave(false);
      const payload = {
        ...documentInfoValues,
        FundsSum1: documentInfoValues.FundsSum1,
        FundsSum2: documentInfoValues.FundsSum2,
      };

      const id = ContractInfo.Id;
      // dispatch(
      //   actions.dashboard.createPaymentRequestNewPrDocumentAction(id, payload)
      // );

      await Api.SavePrDocumentPayBack(id, payload);
      setSaved(true);
      setIsUnsavedChanges(false);
    } catch (error) {
      dispatch(actions.dashboard.approveRequestError(error));
      setError(error.error.ErrorDescription);
    } finally {
    }
  };

  const onDropdownInputChange = ({ target: { name, value } }) => {
    setIsUnsavedChanges(true);
    setDocumentInfoValues({ ...documentInfoValues, [name]: value });
  };

  const checkBOCanEdit = (name) => {
    return documentInfo.find((el) => el.boCanEdit && el.name === name);
  };

  const RecalculateRequiredSum = () => {
    let managerInvestmentSum =
      paymentRequestPrDocument?.ManagerInfo?.InvestmentSum;
    let managerTurnoverSum = paymentRequestPrDocument?.ManagerInfo?.TurnoverSum;
    if (managerInvestmentSum !== null || managerTurnoverSum !== null) {
      if (managerInvestmentSum === null) {
        managerInvestmentSum = paymentRequestPrDocument?.InvestmentSum;
      }
      if (managerTurnoverSum === null) {
        managerTurnoverSum = paymentRequestPrDocument?.TurnoverSum;
      }
    }

    paymentRequestPrDocument.RequiredSum = Number(
      Number(managerTurnoverSum) + Number(managerInvestmentSum)
    );
  };
  useEffect(() => {
    if (checkBOEditPage()) {
      // if (paymentRequestPrDocument?.ManagerInfo) {
      //   RecalculateRequiredSum();
      // }
      const boCantEditFields = {};
      const boCanEdit = {};
      for (let name in paymentRequestPrDocument) {
        if (checkBOCanEdit(name)) {
          boCanEdit[name] = paymentRequestPrDocument[name];
          const managerValue = paymentRequestPrDocument?.ManagerInfo?.[name];
          if (managerValue || managerValue === 0) {
            boCantEditFields[name] = managerValue;
          } else if (documentInfoValues[name]) {
            boCantEditFields[name] = null;
          }
        } else {
          boCantEditFields[name] = paymentRequestPrDocument[name];
        }
      }
      boCantEditFields.ManagerComment =
        paymentRequestPrDocument?.ManagerComment || '';
      setDocumentInfoValues(boCantEditFields);
      setBOCanEditInfoValues(boCanEdit);
    } else {
      if (checkBOPage()) {
        paymentRequestPrDocument.ManagerComment =
          paymentRequestPrDocument?.ManagerComment || '';
      }
      setDocumentInfoValues(paymentRequestPrDocument);
    }
    setManagerInfo(paymentRequestPrDocument.ManagerInfo);
  }, [paymentRequestPrDocument, actionType]);

  useEffect(() => {
    const InvestmentSumNum = getValue(
      documentInfoValues.InvestmentSum,
      'InvestmentSum'
    );
    const TurnoverSumNum = getValue(
      documentInfoValues.TurnoverSum,
      'TurnoverSum'
    );

    setDocumentInfoValues({
      ...documentInfoValues,
      RequiredSum: Number(InvestmentSumNum) + Number(TurnoverSumNum),
    });
    setInvestmentSum(InvestmentSumNum);
    setTurnoverSum(TurnoverSumNum);
  }, [documentInfoValues.InvestmentSum, documentInfoValues.TurnoverSum]);

  useEffect(() => {
    setDocumentSum(documentInfoValues.DocumentSum);
  }, [documentInfoValues.DocumentSum]);

  useEffect(() => {
    setDocumentInfoValues({
      ...documentInfoValues,
      PaidOutTotalSumPercent:
        documentInfoValues.FundedInvestmentSumPercent +
        documentInfoValues.FundedTurnoverSumPercent,
    });
  }, [
    documentInfoValues.FundedInvestmentSumPercent,
    documentInfoValues.FundedTurnoverSumPercent,
  ]);

  const getSumValue = (name) => {
    if (checkBOEditPage()) {
      return (paymentRequestPrDocument?.ManagerInfo?.[name] &&
        paymentRequestPrDocument?.ManagerInfo?.[name] !== null) ||
        paymentRequestPrDocument?.ManagerInfo?.[name] === 0
        ? paymentRequestPrDocument?.ManagerInfo?.[name]
        : paymentRequestPrDocument?.[name] || 0;
    } else {
      return paymentRequestPrDocument?.[name] || 0;
    }
  };

  useEffect(() => {
    const currInvestmentSum = getSumValue('InvestmentSum');
    const currTurnoverSum = getSumValue('TurnoverSum');
    setLoanPurpose(FinancialInfo?.LoanPurposeId || 1);
    if (FinancialInfo?.LoanPurposeId === 2) {
      setDocumentInfoValues({ ...documentInfoValues, InvestmentSum: 0 });
      setTurnoverSumLeft(
        Number(FinancialInfo.LoanSumRemainder) +
          Number(currTurnoverSum) +
          Number(currInvestmentSum)
      );
    }

    if (FinancialInfo?.LoanPurposeId === 1) {
      setTurnoverSumLeft(
        Number(Remainders.TurnoverRemainder) + Number(currTurnoverSum)
      );
      setInvestmentSumLeft(
        Number(Remainders.InvestmentRemainder) + Number(currInvestmentSum)
      );
    }
  }, [FinancialInfo, documentInfoValues.Id]);

  const dropdownItemSelected = (value, type, name) => {
    if (type === 'boolean' && typeof value === 'boolean') {
      const valStr = value.toString();
      const infStr = documentInfoValues[name].toString();
      const selected = valStr && infStr && valStr === infStr ? true : false;
      return selected;
    } else if (value && type === 'number') {
      return Number(value) === Number(documentInfoValues[name]);
    }
  };

  const backToPaymentRequest = () => {
    window.location.href = `${
      checkBOPage()
        ? config.APP_PATHS.backofficePaymentRequest
        : config.APP_PATHS.paymentrequest
    }?requestId=${paymentRequest.requestId}&paymentRequestId=${
      ContractInfo?.Id
    }&actionType=${paymentRequest.actionType || 'view'}`;
    // history.goBack();
  };

  useEffect(() => {
    if (closedDialogOKMessage === 'closeIfNotAllSaved') {
      const payload = {
        message: '',
      };
      dispatch(actions.modals.setModalClosedOKAction(payload));
      dispatch(actions.dashboard.approveRequestResset(true));
      backToPaymentRequest();
    }
  }, [closedDialogOKMessage]);

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  // useEffect(() => {
  //   setSaved(requestState.saved);
  //   setError(requestState.error);
  //   if (requestState.saved) {
  //     setIsUnsavedChanges(false);
  //     if (checkBOPage()) {
  //       dispatch(
  //         actions.dashboard.createNewPaymentRequestPrDocumentAction(
  //           ContractInfo?.Id,
  //           paymentRequestPrDocument.Id,
  //           'view',
  //           documentInfoValues.Number || ''
  //         )
  //       );
  //       setActionType('view');
  //     }
  //   }
  // }, [requestState]);

  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalMainText, setModalMainText] = useState('');

  useEffect(() => {
    if (!paymentRequest?.ContractInfo) {
      // page reloaded. lost state -> back to prev page
      // backToPaymentRequest();
      history.goBack();
    }
    dispatch(actions.dashboard.approveRequestResset(true));
  }, []);

  const displayNotSavedMessage = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText(
      'Ar tikrai norite grįžti į mokėjimų prašymų sąrašą. Ne visi pakeitimai yra išsaugoti.'
    );
    setModalData({ comments: 'closeIfNotAllSaved' });
    setModalAction('YES_NO_ACTION');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const cancel = () => {
    if (isUnsavedChanges) {
      displayNotSavedMessage();
      return;
    }
    dispatch(actions.dashboard.approveRequestResset(true));
    backToPaymentRequest();
  };

  const createDateAsUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const checkVisibility = (statusArr) => {
    if (!statusArr) {
      return true;
    }
    if (
      paymentRequest?.ContractInfo?.PaymentRequestStatusId &&
      statusArr.includes(paymentRequest.ContractInfo?.PaymentRequestStatusId)
    ) {
      return true;
    }
    return false;
  };

  const clearDateField = (name) => {
    setDocumentInfoValues({ ...documentInfoValues, [name]: '' });
  };

  return (
    <WithSpinner loading={loading === true ? true : false}>
      <div
        className="container"
        style={{ backgroundColor: '#fff', marginTop: '4rem' }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.requestBlockAll}>
              <h1>Dokumento duomenys</h1>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h4>Dokumento informacija</h4>
                  {
                    <div className="row director-data-margin">
                      {documentInfoValues.Number && (
                        <>
                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              type="string"
                              value={documentInfoValues.Number}
                              placeholder="Eil. Nr."
                              disabled={true}
                              name="Number"
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12"></div>
                        </>
                      )}
                      {documentInfo.map((el, i) => (
                        <React.Fragment key={i}>
                          {checkVisibility(el.showWhenStatus) && (
                            <div className="col-lg-6 col-sm-12">
                              {el.fieldType === 'input' && (
                                <>
                                  <InputWithTextInline
                                    type={el.type || 'string'}
                                    value={documentInfoValues[el.name]}
                                    onKeyPress={(e) => {
                                      if (
                                        e.which !== 48 &&
                                        e.which !== 49 &&
                                        e.which !== 50 &&
                                        e.which !== 51 &&
                                        e.which !== 52 &&
                                        e.which !== 53 &&
                                        e.which !== 54 &&
                                        e.which !== 55 &&
                                        e.which !== 56 &&
                                        e.which !== 57 &&
                                        e.which !== 8 &&
                                        e.which !== 188 &&
                                        e.which !== 190 &&
                                        e.which !== 44 &&
                                        e.which !== 45 &&
                                        e.which !== 46 &&
                                        e.which !== 32
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={el.title}
                                    disabled={el.disabled}
                                    name={el.name}
                                    maxlength={el.maxlength || ''}
                                    onChange={(e) =>
                                      setValueChanged(
                                        e.target.value,
                                        e.target.name
                                      )
                                    }
                                    onBlur={(e) => {
                                      if (el.onblur) {
                                        el.onblur(e.target.value, el.name);
                                      }
                                    }}
                                  />
                                  {el.name === 'InvestmentSum' &&
                                    !documentInfoValues?.InvestmentSum && (
                                      <p className="boAdminValue">
                                        Originali dokumento suma:{' '}
                                        <span className="boUserValue">
                                          {
                                            documentInfoValues?.OriginalInvestmentSum
                                          }
                                        </span>{' '}
                                      </p>
                                    )}
                                  {el.name === 'TurnoverSum' &&
                                    !documentInfoValues?.TurnoverSum && (
                                      <p className="boAdminValue">
                                        Originali dokumento suma:{' '}
                                        <span className="boUserValue">
                                          {
                                            documentInfoValues?.OriginalTurnovertSum
                                          }
                                        </span>{' '}
                                      </p>
                                    )}
                                  {el.required &&
                                    triedSave &&
                                    ValidateInput(
                                      documentInfoValues[el.name],
                                      el.requiredType,
                                      el.defaultErrorMessage,
                                      el.minValue,
                                      el.maxValue,
                                      el.customFunction,
                                      el.checkConnectedValues,
                                      el.name === 'TurnoverSum'
                                        ? documentInfoValues.OriginalTurnovertSum
                                        : documentInfoValues.OriginalInvestmentSum
                                    ) && (
                                      <p className="errorsBlockText error1">
                                        {ValidateInput(
                                          documentInfoValues[el.name],
                                          el.requiredType,
                                          el.defaultErrorMessage,
                                          el.minValue,
                                          el.maxValue,
                                          el.customFunction,
                                          el.checkConnectedValues,
                                          el.name === 'TurnoverSum'
                                            ? documentInfoValues.OriginalTurnovertSum
                                            : documentInfoValues.OriginalInvestmentSum
                                        )}
                                      </p>
                                    )}
                                </>
                              )}
                              {el.fieldType === 'dropdown' && el.dropdownInfo && (
                                <>
                                  <label
                                    style={{
                                      margin: '8px 0 8px',
                                      color: '#597a96',
                                      fontSize: '0.8rem',
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    {el.title}
                                  </label>
                                  <select
                                    name={el.name}
                                    style={{
                                      background: el.disabled
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                    }}
                                    disabled={el.disabled}
                                    onChange={onDropdownInputChange}
                                  >
                                    {el.dropdownInfo.options.map((sel, j) => (
                                      <option
                                        value={sel.value}
                                        selected={dropdownItemSelected(
                                          sel.value,
                                          sel.type,
                                          el.name
                                        )}
                                        key={j}
                                      >
                                        {sel.name}
                                      </option>
                                    ))}
                                  </select>
                                  {el.required &&
                                    triedSave &&
                                    ValidateInput(
                                      documentInfoValues[el.name],
                                      el.requiredType,
                                      el.defaultErrorMessage,
                                      el.minValue,
                                      el.maxValue,
                                      el.customFunction,
                                      el.checkConnectedValues
                                    ) && (
                                      <p className="errorsBlockText error1">
                                        {ValidateInput(
                                          documentInfoValues[el.name],
                                          el.requiredType,
                                          el.defaultErrorMessage,
                                          el.minValue,
                                          el.maxValue,
                                          el.customFunction,
                                          el.checkConnectedValues
                                        )}
                                      </p>
                                    )}
                                </>
                              )}
                              {el.fieldType === 'date' && (
                                <>
                                  <label
                                    style={{
                                      margin: '8px 0 8px',
                                      color: '#597a96',
                                      fontSize: '0.8rem',
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    {el.title}
                                  </label>
                                  <div className="customDatePickerWidth">
                                    <DatePicker
                                      className="inlineDatepickerInput"
                                      selected={
                                        documentInfoValues[el.name]
                                          ? new Date(
                                              documentInfoValues[el.name]
                                            )
                                          : ''
                                      }
                                      onChange={(e) => {
                                        setValueChanged(
                                          createDateAsUTC(e),
                                          el.name
                                        );
                                      }}
                                      maxDate={new Date()}
                                      dateFormat="yyyy-MM-dd"
                                      disabled={el.disabled}
                                      locale="lt"
                                    />
                                    <span
                                      className={styles.clearDateButton}
                                      onClick={() => clearDateField(el.name)}
                                    >
                                      X
                                    </span>
                                  </div>
                                  {el.required &&
                                    triedSave &&
                                    ValidateInput(
                                      documentInfoValues[el.name],
                                      el.requiredType,
                                      el.defaultErrorMessage,
                                      el.minValue,
                                      el.maxValue,
                                      el.customFunction
                                    ) && (
                                      <p className="errorsBlockText error1">
                                        {ValidateInput(
                                          documentInfoValues[el.name],
                                          el.requiredType,
                                          el.defaultErrorMessage,
                                          el.minValue,
                                          el.maxValue,
                                          el.customFunction
                                        )}
                                      </p>
                                    )}
                                </>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  }
                </div>
              </div>
              <div className="row">
                <br />
                <div className="col-lg-12 col-sm-12">
                  {saved && (
                    <p className="success-block-message">
                      <strong>Dokumentas išsaugotas sėkmingai</strong>
                    </p>
                  )}
                  {error && (
                    <p className="errors-block-message">
                      <strong>{error}</strong>
                    </p>
                  )}
                </div>
                <br />
                {((!paymentRequestPrDocument?.Id ||
                  !paymentRequestPrDocument?.IsApproved) &&
                  (paymentRequest?.ContractInfo?.PaymentRequestStatusId === 1 ||
                    paymentRequest?.ContractInfo?.PaymentRequestStatusId ===
                      3) &&
                  actionType !== 'view') ||
                checkBOEditPage() ? (
                  <div className={`${styles.btnBlock} col-lg-12 col-sm-12`}>
                    <Button
                      className={'btn-dark-blue-standart'}
                      type="cancel"
                      onClick={() => submit()}
                    >
                      Išsaugoti
                    </Button>
                    <Button type="cancel" onClick={() => cancel()}>
                      Atgal į mokėjimo prašymą
                    </Button>
                  </div>
                ) : (
                  <div className={`pt-5 col-lg-12 col-sm-12 row`}>
                    <div className={'col-lg-4 col-sm-4'}>
                      <Link
                        to={`${
                          checkBOPage()
                            ? config.APP_PATHS.backofficePaymentRequest
                            : config.APP_PATHS.paymentrequest
                        }?requestId=${
                          paymentRequest.requestId
                        }&paymentRequestId=${ContractInfo?.Id}&actionType=${
                          paymentRequest.actionType || 'view'
                        }`}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                          float: 'left',
                        }}
                      >
                        <Button
                          type="cancel"
                          onClick={() =>
                            dispatch(
                              actions.dashboard.approveRequestResset(true)
                            )
                          }
                        >
                          Atgal
                        </Button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle="Taip"
        modalTitle={modalTitle}
        checkbox={false}
        mainText={modalMainText}
      />
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  requestState: state.requestState,
  paymentRequest: state.dashboardState.paymentRequest,
  paymentRequestPrDocument: state.dashboardState.paymentRequestPrDocument,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
  closedDialogOKMessage: state.modalsState.closedDialogOKMessage,
});
export default connect(mapStateToProps)(PaymentDocumentSumEdit);
