import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './fileUploadContainer.module.scss';
import { config } from './../../api/config';
import { actions } from './../../state/actions';
import { Api } from './../../api/api';
import { WithSpinner, TableAdmin, TableRow, TableCell } from '../../components';
import {
  paymentRequestDocumentTypes,
  getNameByFileType,
  getAllowedFileTypes,
} from '../../utils/translation/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { checkBOPage, getAutorization } from './../../utils/helpers/helpers';

const PaymentRequestFileUploadContainer = ({
  dispatch,
  viewWindow,
  status,
  data,
  actionType,
}) => {
  const onFileTypeSelectorChange = ({ target: { name, value } }) => {
    setFileType(value === '-' ? null : value);
  };

  const [isLoading, setIsloading] = useState(false);

  const [fileType, setFileType] = useState(null);
  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const acceptFiles = (fileType) => {
    if (fileType === 1) {
      return '.pdf';
    }

    if (fileType === 3) {
      return ' .doc, .docx, .pdf, .jpg';
    }

    if (fileType === 6 || fileType === 7 || fileType === 8) {
      return ' .pdf, .jpg';
    }

    if (fileType === 9) {
      return '.xlsx, .xls';
    }
    return '.xlsx, .xls, .doc, .docx, .xml, .txt, .pdf';
  };

  const gotoBottom = (id) => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const upload = (e) => {
    setIsloading(true);
    const file = e.target.files[0];

    if (file.size > 25 * 1048 * 1048) {
      setIsloading(false);

      swal({
        title: 'Įkeliamas priedas viršija 25mb leistiną limitą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
    } else {
      const formData = new FormData();
      formData.append('Files', file);
      formData.append('RelatedId', data.ContractInfo.Id);
      formData.append('PRDocumentTypeId', fileType);

      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadFilesPr, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: getAutorization(),
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok === true) {
            dispatch(
              actions.dashboard.createNewPaymentRequestAction(
                data.requestId,
                'edit',
                data.ContractInfo.Id
              )
            );

            swal({
              title: 'Priedas sėkmingai įkeltas',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              let title = 'Nepavyko pateikti priedo';
              if (r.errors) {
                title = r.errors['Files[0].FileName'][0];
              } else if (r.ErrorDescription) {
                title = r.ErrorDescription;
              }
              swal({
                title: title,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
          setTimeout(() => {
            gotoBottom();
          }, 2000);
        });
    }
  };

  const tableHeader = ['Priedo tipas', 'Įkelto priedo pavadinimas', 'Veiksmai'];

  const downloadRejectionFile = (invoiceId, fileId, fileName) => {
    setIsloading(true);
    fetch(
      Api.API_BASE_URL + config.API_ENDPOINTS.DownloadFilePr(invoiceId, fileId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: getAutorization(),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const deleteFile = (invoiceId, fileId) => {
    setIsloading(true);
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DeleteUploadedFilePr(invoiceId, fileId),
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: getAutorization(),
        },
      }
    )
      .then((res) => {
        if (res.ok === true) {
          if (data.requestId) {
            dispatch(
              actions.dashboard.createNewPaymentRequestAction(
                data.requestId,
                'edit',
                data.ContractInfo.Id
              )
            );
          }

          swal({
            title: 'Priedas sėkmingai ištrintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else {
          swal({
            title: 'Nepavyko pašalinti priedo',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log('catch block');
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
        setTimeout(() => {
          gotoBottom();
        }, 2000);
      });
  };

  const deleteConfirmation = (invoiceId, fileId) => {
    swal({
      title: 'Ar tikrai norite ištrinti šį failą?',
      icon: 'warning',
      showCancelButton: true,

      buttons: {
        confirm: {
          text: 'Taip',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
        cancel: {
          text: 'Ne',
          value: false,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true,
        },
      },
    }).then((result) => {
      if (result) {
        deleteFile(invoiceId, fileId);
      }
    });
  };

  const getFileType = (fileType) => {
    return getNameByFileType(paymentRequestDocumentTypes, fileType);
  };

  return (
    <WithSpinner loading={isLoading}>
      <div className={styles.container}>
        <h4>
          {(data?.ContractInfo?.PaymentRequestStatusId === 1 ||
            data?.ContractInfo?.PaymentRequestStatusId === 3) &&
          !checkBOPage() &&
          actionType !== 'view'
            ? 'Priedų įkėlimas'
            : 'Priedai'}
        </h4>
        <div className={styles.container}>
          {data.Files != null && data.Files.length > 0 ? (
            <>
              <TableAdmin header={tableHeader} isEmpty={false}>
                {data.Files.map(({ Id, Name, Type }) => (
                  <TableRow key={Id}>
                    <TableCell>{getFileType(Type)}</TableCell>
                    <TableCell>{Name}</TableCell>

                    <TableCell>
                      <div className={styles.iconsContainer}>
                        {(data?.ContractInfo?.PaymentRequestStatusId === 1 ||
                          data?.ContractInfo?.PaymentRequestStatusId === 3) &&
                          !checkBOPage() &&
                          actionType !== 'view' && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              className={styles.iconPointer}
                              onClick={() =>
                                deleteConfirmation(data.ContractInfo.Id, Id)
                              }
                              style={{
                                color: '#597a96',
                              }}
                            />
                          )}

                        <FontAwesomeIcon
                          icon={faDownload}
                          className={styles.iconPointer}
                          onClick={() =>
                            downloadRejectionFile(
                              data.ContractInfo.Id,
                              Id,
                              Name
                            )
                          }
                          style={{
                            color: '#597a96',
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableAdmin>
            </>
          ) : (
            <p className="nofileText">Nėra įkeltų priedų</p>
          )}
          {(data?.ContractInfo?.PaymentRequestStatusId === 1 ||
            data?.ContractInfo?.PaymentRequestStatusId === 3) &&
            !checkBOPage() &&
            actionType !== 'view' && (
              <>
                <div style={{ display: 'flex' }}>
                  <div className={styles.selectflex}>
                    <label>Prisegamo priedo paskirtis</label>
                    <select
                      disabled={viewWindow}
                      name="InvoiceType"
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                        height: '44px',
                      }}
                      id="scrollToFromTop"
                      onChange={onFileTypeSelectorChange}
                      value={fileType || ''}
                    >
                      <option value=""> - </option>
                      {paymentRequestDocumentTypes.map((el, i) => (
                        <option value={el.fileType} key={i}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <span
                    className={styles.disabled}
                    style={{
                      width: '155px',
                      marginTop: '-5px',
                      marginLeft: '10px',
                    }}
                  >
                    <span className={styles.textUptheButton}>
                      Pasirinkti priedą
                    </span>

                    <button
                      className={
                        fileType
                          ? 'btn ui-button btn-dark-blue-standart'
                          : 'btn ui-button btn-dark-blue-disabled'
                      }
                      disabled={!fileType}
                      type="longBtn"
                      onClick={() => selectFile('fileElem')}
                      style={{ marginTop: '9px', padding: '9px' }}
                    >
                      PASIRINKTI PRIEDĄ
                    </button>
                    <input
                      type="file"
                      id="fileElem"
                      accept={getAllowedFileTypes(
                        paymentRequestDocumentTypes,
                        fileType
                      )}
                      className="d-none"
                      onChange={upload}
                    />
                  </span>
                </div>
              </>
            )}
        </div>
      </div>
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  filesTableList: state.dashboardState.filesTableList,
});

export default connect(mapStateToProps)(PaymentRequestFileUploadContainer);
