import React from 'react';
import styles from './input.module.scss';

const Input = ({
  name,
  type,
  onChange,
  value,
  width,
  onEnterClick,
  ...rest
}) => {
  return (
    <input
      className={styles.input}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      onKeyDown={onEnterClick}
      {...rest}
    />
  );
};

export default Input;
