import { combineReducers } from 'redux';
import {
  userReducer,
  invoiceReducer,
  companyReducer,
  requestReducer,
} from './dashboard/DashboardReducer';
import { modalsReducer } from './modals/ModalsReducer';
import { authReducer } from './auth/auth.reducer';
import {
  backofficeRequestsReducer,
  backofficePaymentRequestsReducer,
  backofficeInvoicesReducer,
  backofficeInvoiceReducer,
  backofficeStatsReducer,
  backofficeRepresentetivesReducer,
} from './backoffice/BackofficeReducer';

export const reducers = combineReducers({
  dashboardState: userReducer,
  invoiceState: invoiceReducer,
  companyState: companyReducer,
  requestState: requestReducer,
  modalsState: modalsReducer,
  auth: authReducer,
  backofficeRequestsState: backofficeRequestsReducer,
  backofficePaymentRequestsState: backofficePaymentRequestsReducer,
  backofficeInvoicesState: backofficeInvoicesReducer,
  backofficeInvoiceState: backofficeInvoiceReducer,
  backofficeStatsState: backofficeStatsReducer,
  backofficeRepresentetivesState: backofficeRepresentetivesReducer,
});
