import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';

import {
  WithSpinner,
  InputWithTextInline,
  InputWithTrueFalseSelection,
  Button,
  Input,
  RowTableAdmin,
  RowTableRow,
  RowTableCell,
} from '../../components';

import {
  FileUploadContainerOld,
  AdditionalFileUploadContainerOld,
  FinancialTable
} from '../../containers';
import {
  requestDataLabelTranslation,
  companySize,
  rentContractTranslation,
  applicantTranslation,
  requestStatus,
  questionListTranslations,
} from './../../utils/translation/translation';
import styles from './newRequest.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { config } from '../../api/config';
import { Api } from './../../api/api';
import { format, addHours, subYears } from 'date-fns';
import {
  validateTextinput,
  validatePhone,
  validateEmail,
  isLoadSumValid,
  LoanTermValidation,
  isIntegerNumber,
} from './../../utils/auth/validations';
import { formatNumber } from './../../utils/helpers/helpers';
import SignContractModal from './../../modals/signContractModal/signContractModal';
import ChangeConditionsModal from './../../modals/changeConditionsModal/changeConditionsModal';
import FinancialCriteriaModal from './../../modals/financialCriteriaModal/financialCriteriaModal';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import ConfirmWithFinancialCriteriaModal from '../../modals/confirmWithFinancialCriteriaModal/confirmWithFinancialCriteriaModal';
import ConfirmWithoutFinancialCriteriaModal from '../../modals/confirmWithoutFinancialCriteriaModal/confirmWithoutFinancialCriteriaModal';
import GoBackConfirmationModal from '../../modals/goBackConfirmationModal/goBackConfirmationModal';
import ConfirmWithoutFinancialCriteriaForChangeConditionsModal from '../../modals/confirmWithoutFinancialCriteriaForChangeConditionsModal/confirmWithoutFinancialCriteriaForChangeConditionsModal';

import DatePicker from 'react-datepicker';
import { getLocalStorageItemIfNotExpired } from '../../utils';
import {
  controllingCompaniesInputValidation,
  isCommentFilled,
  controlingForeignCompaiesInputValidation,
} from './../../utils/auth/validations';
import swal from 'sweetalert';
import {
  evrkJson,
  evrkFull,
  evrkFullWithoutZeros,
  evrkFullWithZeros,
  formatCountryJson,
} from './../../utils/helpers/evrk';
import { SurwayAnswers } from '../requests/surwayAnswers';
import { AddressModal } from '../../containers/addressInputContainer/addressInputContainer';
import { SelfDeclarationInfo } from '../../utils/mapsData/selfDeclaration/selfDeclaration';

const NewRequest = ({
  requestData,
  canSeeBills,
  dispatch,
  loading,
  signContractModalVisible,
  financialCriteriaModalVisible,
  financialCriteriaResults,
  confirmWithFinancialCriteriaModalVisible,
  confirmWithoutFinancialCriteriaModalVisible,
  changeConditionsModalVisible,
  signInInfo,
  confirmModalVisible,
  goBackConfirmationModalVisible,
  confirmWithoutFinancialCriteriaForChangeConditionsModalVisible,
}) => {
  const loginType = getLocalStorageItemIfNotExpired('loginType');

  const [suppliers, setSuppliers] = useState(null);

  const options = [
    { value: null, label: '-' },
    { value: true, label: 'Taip' },
    { value: false, label: 'Ne' },
  ];

  const CompanyEvrkcheck = () => (loginType === 'Company' ? 'CompanyEvrk' : '');
  const CompanyManagerNamecheck = () =>
    loginType === 'Company' ? 'CompanyManagerName' : '';

    let history = useHistory();

  const disableCheck = (val) => {
    if (viewWindow) {
      return true;
    } else {
      if (
        val === 'ApplicantName' ||
        val === 'ApplicantSurname' ||
        val === 'PersonalCode' ||
        val === 'CompanyName' ||
        val === 'CompanyCode' ||
        val === CompanyManagerNamecheck() ||
        val === 'CompanyType' ||
        val === 'CompanySizeType' ||
        val === 'CompanyRegistrationDate' ||
        // val === 'CompanyRegistrationAddress' ||
        // val === 'CompanyPhone' ||
        // val === 'CompanyEmail' ||
        val === 'StatusInitial' ||
        //val === 'Turnover18' ||
        val === 'Turnover19' ||
        //val === 'Income18' ||
        val === 'Income19' ||
        //val === 'Assets18' ||
        val === 'Assets19' ||
        //val === 'PropertyAmount18' ||
        val === 'PropertyAmount19' ||
        //val === 'Liabilities18' ||
        val === 'Liabilities19' ||
        //val === 'ShortTermDebts18' ||
        val === 'ShortTermDebts19' ||
        val === 'Status' ||
        //val === 'PvmCode' ||
        val === 'FsiStartDate' ||
        val === 'FsiEndDate' ||
        val === CompanyEvrkcheck()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isNumberType = (val) => {
    if (
      val === 'MonthlyRentalPrice' ||
      val === 'RentedArea' ||
      val === 'OwnerCompanyCode' ||
      val === 'AlreadyReceivedCovidSupportSum' ||
      val === 'OwnerPersonalCode' ||
      val === 'OwnerPhone'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const rowTableHeader = [
    'Tipas',
    'Priedo pavadinimas',
    'Sudarymo data',
    'Pareiškėjo pasirašymo data',
    'Invega pasirašymo data',
    'Veiksmai',
  ];

  const commentsHistoryTableHeader = ['Data', 'Komentaras'];

  const check1Confirment = () => (
    <div>
      <p className="agreementTextp">
        Paskolos gavėjas patvirtina ir garantuoja, kad:
      </p>
      <p className="agreementTextp">
        1. Yra susipažinęs (-usi) su finansinės priemonės „Startuok“,
        finansuojamos Europos regioninės plėtros fondo lėšomis (toliau –
        priemonė), aprašyme (toliau – Aprašymas) nustatyta tvarka ir
        reikalavimais;
      </p>

      <p className="agreementTextp">
        2. Man žinoma, kad projektas, kuriam finansuoti teikiama ši paraiška,
        bus finansuojamas iš Verslo finansavimo fondo, finansuojamo iš Europos
        regioninės plėtros fondo, lėšų;
      </p>
      <p className="agreementTextp">
        3. Įsipareigoju per paskolos teikėjo nustatytą terminą pateikti jam
        reikiamą informaciją ir (arba) atlikti Lietuvos Respublikos ir ES
        atsakingų institucijų nurodytus veiksmus, vykdomus pagal Aprašymą ir
        pagal Lietuvos Respublikos ir ES teisės aktus, reglamentuojančius
        veiksmų programos įgyvendinimą;
      </p>
      <p className="agreementTextp">
        4. Paraiškos pateikimo metu veikia Lietuvos Respublikoje arba kitoje ES
        šalyje narėje, jei vykdant Paskolos sutartį sukurta nauda atitenka
        Lietuvos Respublikai, kaip nurodyta Finansinių priemonių išlaidų
        tinkamumo finansuoti rekomendacijose, kaip nurodyta Finansinių priemonių
        išlaidų tinkamumo finansuoti rekomendacijose, patvirtintose Veiksmų
        programos valdymo komiteto 2016 m. birželio 29 d. protokolo sprendimu
        Nr. 38 su vėlesniais pakeitimais (toliau – Finansinių priemonių išlaidų
        tinkamumo finansuoti rekomendacijos), kurios yra skelbiamos interneto
        svetainėje adresu
        <a
          href="http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3
        </a>
        , tačiau toks finansavimas negali viršyti 15 procentų šios schemos 2
        punkte nurodytos Priemonei skirtos bendros lėšų sumos, kaip tai nurodyta
        2013 m. gruodžio 17 d. Europos Parlamento ir Tarybos reglamento (ES) Nr.
        1303/2013, kuriuo nustatomos Europos regioninės plėtros fondui, Europos
        socialiniam fondui, Sanglaudos fondui, Europos žemės ūkio fondui kaimo
        plėtrai ir Europos jūros reikalų ir žuvininkystės fondui bendros
        nuostatos ir Europos regioninės plėtros fondui, Europos socialiniam
        fondui, Sanglaudos fondui ir Europos jūros reikalų ir žuvininkystės
        fondui taikytinos bendrosios nuostatos ir panaikinamas Tarybos
        reglamentas (EB) Nr. 1083/2006, su paskutiniais pakeitimais, padarytais
        2020 m. gruodžio 23 d. Europos Parlamento ir Tarybos reglamentu (ES) Nr.
        2020/2221, 70 straipsnio 2 dalies b punkte;
      </p>
      <p className="agreementTextp">
        5. Įmonė ir (ar) įmonių grupė, kaip ši sąvoka apibrėžta Lietuvos
        Respublikos įmonių grupių konsoliduotosios finansinės atskaitomybės
        įstatyme, kuriai priklauso paskolos gavėjas, paskutinę praėjusių
        kalendorinių metų dieną ir paraiškos INVEGAI pateikimo metu nebuvo
        laikomi sunkumų patiriančiais, kaip tai apibrėžta:
        <br />
        - Europos Komisijos Gairėse dėl valstybės pagalbos sunkumų patiriančioms
        ne finansų įmonėms sanuoti ir restruktūrizuoti (2014/C 249/01) (taikoma
        visiems);
        <br />
        - Bendrosios išimties reglamento 2 straipsnio 18 punkte (taikoma tik
        labai mažoms, mažoms įmonėms ar verslininkams, vykdantiems socialinio
        poveikio projektą);
        <br />- De minimis reglamento 4 straipsnio 3 dalies a punkte (taikoma
        tik vidutinėms įmonėms, vykdančioms socialinio poveikio projektą);
      </p>
      <p className="agreementTextp">
        6. Paraiškos pateikimo metu atitinka minimalius patikimų mokesčių
        mokėtojų kriterijus, nustatytus Lietuvos Respublikos mokesčių
        administravimo įstatymo 401 straipsnyje. Atitiktis šiam reikalavimui
        vertinama pagal viešai paskelbtą Valstybinės mokesčių inspekcijos prie
        Lietuvos Respublikos finansų ministerijos informaciją, skelbiamą
        interneto svetainėje adresu
        <a
          href="https://www.vmi.lt/evmi/mokesciu-moketoju-informacija"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          https://www.vmi.lt/evmi/mokesciu-moketoju-informacija
        </a>
        ;
      </p>
      <p className="agreementTextp">
        7. Paraiškos pateikimo metu, kai ją teikia juridinis asmuo, jam nėra
        iškelta nemokumo byla ir (ar) jis nėra restruktūrizuojamas pagal
        Lietuvos Respublikos juridinių asmenų nemokumo įstatymą arba iki jo
        įsigaliojimo galiojusius Lietuvos Respublikos įmonių bankroto įstatymą
        ir Lietuvos Respublikos restruktūrizavimo įstatymą arba, kai paraišką
        teikia verslininkas, kaip jis apibrėžtas SVV įstatymo 2 straipsnio 21
        dalyje, – jam nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
        Respublikos fizinių asmenų bankroto įstatymą. Atitikimas reikalavimui
        vertinamas pagal viešai prieinamus Juridinių asmenų registro ir Audito,
        apskaitos, turto vertinimo ir nemokumo valdymo tarnybos prie Lietuvos
        Respublikos finansų ministerijos duomenis;
      </p>
      <p className="agreementTextp">
        8. Neveikia žemės ūkio sektoriuje, kaip tai apibrėžta 2014 m. birželio
        25 d. Komisijos reglamento (ES) Nr. 702/2014, kuriuo skelbiama, kad tam
        tikrų kategorijų pagalba žemės bei miškų ūkio sektoriuose ir kaimo
        vietovėse yra suderinama su vidaus rinka pagal Sutarties dėl Europos
        Sąjungos veikimo 107 ir 108 straipsnius, su visais pakeitimais 2
        straipsnio 3 dalyje, išskyrus atvejus, kai šis Paskolų gavėjas vykdo
        arba ketina vykdyti inovacinę veiklą, nevykdo žuvininkystės ir
        akvakultūros veiklos, kaip tai apibrėžta Lietuvos Respublikos
        žuvininkystės įstatyme, ar jo vykdoma pagrindinė veikla pagal
        Statistikos departamento prie Lietuvos Respublikos Vyriausybės
        generalinio direktoriaus įsakymu patvirtintą Ekonominės veiklos rūšių
        klasifikatorių (EVRK 2 red.)(toliau – EVRK 2 red.) nėra finansinė ir
        draudimo veikla (visi veiklos kodai pagal EVRK 2 red. 64–66 skyrius),
        išskyrus atvejus, kai šis Paskolų gavėjas vysto finansines
        technologijas;
      </p>
      <p className="agreementTextp">
        9. Paskolos gavėjui nėra išduotas vykdomasis raštas sumoms išieškoti
        pagal ankstesnį Europos Komisijos sprendimą, kuriame Lietuvos valstybės
        suteikta pagalba skelbiama neteisėta ir nesuderinama su vidaus rinka,
        arba Paskolos gavėjas yra grąžinęs visą neteisėtos pagalbos sumą,
        įskaitant palūkanas, kaip nustatyta 2015 m. liepos 13 d. Tarybos
        reglamente (ES) 2015/1589, nustatančiame išsamias Sutarties dėl Europos
        Sąjungos veikimo 108 straipsnio taikymo taisykles;
      </p>
      <p className="agreementTextp">
        10. Esant poreikiui ir (ar) kilus įtarimui, bet kuriuo paraiškos
        vertinimo metu ir (ar) po paskolos sutarties pasirašymo ir paskolos
        išmokėjimo, INVEGAI pareikalavus, Paskolos gavėjas pateiks dokumentus,
        patvirtinančius, kad Paskolos gavėjui ir (ar) Paskolos gavėjo
        akcininkui, turinčiam ne mažiau nei 25 proc. Verslo subjekto akcijų,
        Paskolos gavėjo vadovui, kitam valdymo ar priežiūros organo nariui ar
        kitam asmeniui, turinčiam (turintiems) teisę atstovauti Paskolos gavėją
        ar jį kontroliuoti, jo vardu priimti sprendimą, sudaryti sandorį, ar
        buhalteriui (buhalteriams) ar kitam (kitiems) asmeniui (asmenims),
        turinčiam (turintiems) teisę surašyti ir pasirašyti Paskolos gavėjo
        apskaitos dokumentus, per pastaruosius 5 metus nebuvo priimtas ir
        įsiteisėjęs apkaltinamasis teismo nuosprendis ir šis asmuo neturi
        neišnykusio ar nepanaikinto teistumo už nusikalstamos ekonominės veiklos
        pažeidimus;
      </p>
      <p className="agreementTextp">
        11. Man žinoma, kad projektas ir projekto veiklos negali būti finansuoti
        ar finansuojami bei suteikus finansavimą teikiami finansuoti iš kitų
        programų, finansuojamų valstybės biudžeto lėšomis, kitų fondų ar
        finansinių mechanizmų (Europos ekonominės erdvės, Norvegijos,
        Šveicarijos Konfederacijos ir pan.) ir kitų veiksmų programų priemonių,
        jei dėl to projekto ar jo dalies tinkamos finansuoti išlaidos gali būti
        finansuotos kelis kartus;{' '}
      </p>
      <p className="agreementTextp">
        12. Man žinoma, kad pareiškėjas, kuris yra fizinis asmuo, projekto
        įgyvendinimo ir kompensacijos mokėjimo laikotarpiu privalo vykdyti
        veiklą pagal individualios veiklos pažymą arba turėti išduotą galiojantį
        verslo liudijimą, patvirtinantį, kad ūkinė ir (arba) ekonominė veikla
        vykdoma;{' '}
      </p>
      <p className="agreementTextp">
        13. Yra informuotas (-a), kad paraiška gali būti atmesta, jeigu Aprašyme
        nustatyta tvarka ir terminais nebus pateikti prašomi dokumentai ir (ar)
        informacija.
      </p>
    </div>
  );

  const isSmallText = () => (
    <span>
      Bendrovė ir visos su ja susijusios įmonės atitinka labai mažos arba mažos
      įmonės statusą pagal{' '}
      <a
        href="https://www.e-tar.lt/portal/lt/legalActEditions/TAR.640D50DB8877?faces-redirect=true"
        target="_blank"
        rel="noreferrer noopener"
      >
        Lietuvos Respublikos smulkaus ir vidutinio verslo plėtros įstatymo{' '}
      </a>
      nuostatas. Pagrindiniai kriterijai: įmonėje (atsižvelgiant ir į susijusias
      ir partnerines įmones) dirba mažiau kaip 50 darbuotojų ir įmonė atitinka
      bent vieną iš šių kriterijų: metinės pajamos neviršija 10 mln. Eur arba
      įmonės balanse nurodyto turto vertė neviršija 10 mln. Eur
    </span>
  );

  const downloadSigningInContract = (requestId) => {
    // setIsloading(true);
    fetch(
      Api.API_BASE_URL + config.API_ENDPOINTS.GetSignedDocumentUser(requestId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Paskolos_sutartis_Nr_${requestId}.pdf`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const returnSignedDocumentEndpoint = (
    requestId,
    fileType,
    fileId,
    applicantSigningDate,
    invegaSigningDate
  ) => {
    if (fileType === 'Decision') {
      return config.API_ENDPOINTS.GetSignedDocumentDecision(requestId);
    }

    if (fileType === 'RejectDecision') {
      return config.API_ENDPOINTS.GetSignedDocumentRejectDecision(requestId);
    }

    if (
      fileType === 'ChangeConditions' ||
      fileType === 'ChangeConditionsRequest'
    ) {
      if (!applicantSigningDate || !invegaSigningDate) {
        return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
      }
      return config.API_ENDPOINTS.GetSignedDocumentChangeConditions(requestId);
    }

    if (
      fileType === 'ChangeConditionsReject' ||
      fileType === 'ChangeConditionsDecision'
    ) {
      return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
    }

    return config.API_ENDPOINTS.GetSignedDocumentContract(requestId);
  };

  const GetSignedDocumentContractUser = (
    requestId,
    fileType,
    name,
    fileId,
    applicantSigningDate,
    invegaSigningDate
  ) => {
    fetch(
      Api.API_BASE_URL +
        returnSignedDocumentEndpoint(
          requestId,
          fileType,
          fileId,
          applicantSigningDate,
          invegaSigningDate
        ),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
          'Content-Type': 'application/pdf',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Sutartis_Nr_${requestId}.pdf`;
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const GetSignedDocumentDecisionUser = (requestId) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.GetSignedDocumentDecision(requestId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Sprendimas_Nr_${requestId}.pdf`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const [viewWindow, setViewWindow] = useState(false);

  const [companyQuestionList, setCompanyQuestionList] = useState(null);
  const [ceoDataList, setCeoDataList] = useState(null);
  const [companyDataList, setCompanyDataList] = useState(null);
  const [applicantData, setApplicantData] = useState(null);
  const [pepData, setPepData] = useState({});
  const [declarationInfo, setDeclarationInfo] = useState(null);
  const [loanInfo, setLoanInfo] = useState(null);
  const [checks, setChecks] = useState(null);

  const [evrksList, setevrksList] = useState([]);

  const [hasParentCompanyValue, setHasParentCompanyValue] = useState(false);
  const [hasParentCompanyValueFA, setHasParentCompanyValueFA] = useState(false);

  const [hasParentCompanyState, setHasParentCompanyState] = useState(false);

  // const [turnoversInfo, setTurnoversInfo] = useState([{Date: '2019', Value: 134443654}, {Date: '2020-02-01', Value: 433443} ])
  const [turnoversInfo, setTurnoversInfo] = useState([]);

  const onPepDataInputChange = ({ target: { name, value } }) => {
    setPepData({ ...pepData, [name]: value });
  };

  const [controllingCompanies, setControllingCompanies] = useState(null);
  const [controllingForeignCompanies, setControllingForeignCompanies] =
    useState(null);

  const [notValid, setNotvalid] = useState(false);

  const onInputChange = ({ target: { name, value } }) => {
    setCeoDataList({ ...ceoDataList, [name]: value });
  };

  const onVatDeclarationChange = ({ target: { name, value } }) => {
    setCompanyDataList({ ...companyDataList, [name]: value });
  };

  const onFAVatDeclarationChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };

  const title = (id) => {
    switch (id) {
      case '1':
        return 'Kelionių organizatorius';
      case '2':
        return 'Apgyvendinimo paslaugos';
      case '3':
        return 'Viešojo maitinimo paslaugos';
    }
  };

  const canCreateRequestSectorTypeId = (id) => {
    const OtherRequests = requestData.OtherRequests;
    const filtered = OtherRequests.some((val) => val.SectorTypeId == id);

    const has2Or3 = OtherRequests.some(
      (val) => val.SectorTypeId == 2 || val.SectorTypeId == 3
    );

    if (OtherRequests && OtherRequests.length > 0 && filtered) {
      swal({
        title: `Paraišką su veikla "${title(
          id
        )}" jau rengiate, todėl su šia veiklos sritimi naujos paraiškos rengti nebegalite. Pasirinkite kitą veiklos sritį.`,
        icon: 'info',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } else if ((id == 2 || id == 3) && has2Or3) {
      swal({
        title:
          'Paraišką su veikla "Apgyvendinimo paslaugos" arba "Viešojo maitinimo paslaugos" jau rengiate, todėl su šia veiklos sritimi naujos paraiškos rengti nebegalite. Pasirinkite kitą veiklos sritį.',
        icon: 'info',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  };
  const onLoanInfoChange = ({ target: { name, value } }) => {
    if (name === 'SectorType') {
      canCreateRequestSectorTypeId(value);
      if (value == null || value.length === 0) {
        setLoanInfo({ ...loanInfo, [name]: null });
      } else {
        setLoanInfo({ ...loanInfo, [name]: value });
      }
    } else {
      if (value == null || value.length === 0) {
        setLoanInfo({ ...loanInfo, [name]: null });
      } else {
        setLoanInfo({ ...loanInfo, [name]: value });
      }
    }
  };

  const onCompanyInputChange = ({ target: { name, value } }) => {
    setCompanyDataList({ ...companyDataList, [name]: value });
  };

  const onApplicantDatanputChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };
  const onCheckBoxChange = (e, name) => {
    setChecks({ ...checks, [name]: e.target.checked });
  };

  const scrollToError = () => {
    setTimeout(() => {
      let el = document.getElementsByClassName('error1');

      let e = null;
      for (let i = 0; i < el.length; i++) {
        if (el[i].innerText != '') {
          e = el[i];
          break;
        }
      }

      if (e) {
        const y = e.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 0);
  };

  const checkResponsibilityPersons = (data) => {
    for (let i = 0; i < data?.length; i++) {
      let input = data[i];
      if (
        input.name &&
        input.name.length > 0 &&
        input.surname &&
        input.surname.length > 0 &&
        input.birthDate &&
        input.birthDate.length > 0 &&
        input.position &&
        input.position.length > 0 &&
        input.country &&
        input.country.length > 0 &&
        input.personRelation &&
        input.personRelation.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const validateVatDeclarationPeriod = () => {
    if (companyQuestionList.IsVatPayer) {
      return companyDataList.VatDeclarationPeriod ? true : false;
    }
    return true;
  };

  const validateFAVatDeclarationPeriod = () => {
    if (companyQuestionList.IsVatPayer) {
      return applicantData.VatDeclarationPeriod ? true : false;
    }
    return true;
  };

  const validateQuestionnaireBlock = () => {
    if (ShowQuestionList()) {
      return (
        checkifAllQuestionListValuesCheck() &&
        checkifAllQuestionListReqCommentHasNoValidationError()
      );
    }
    return true;
  };

  const questionereEmpty = {
    IsSmall: null,
    IsSmallComment: null,
    IsNotListed: null,
    IsNotListedComment: null,
    IsProfitNotShared: null,
    IsProfitNotSharedComment: null,
    IsNotJoined: null,
    IsNotJoinedComment: null,
    IsNotTaken: null,
    IsNotTakenComment: null,
    IsNotProblemic: null,
    IsNotProblemicComment: null,
  };

  const validateElderships = (settlement, elderships) => {
    if (settlement && !elderships) {
      return true;
    }
    if (elderships) {
      return true;
    }

    return false;
  };

  const returnEldership = (a, b) => {
    const arr = [11, 30, 27, 25, 21, 23, 12];
    const isInArray = arr.some((val) => val == b);

    if (isInArray) {
      return null;
    }

    return a;
  };

  const returnSettlement = (a, b) => {
    const arr = [13, 18, 19, 29];
    const isInArray = arr.some((val) => val == b);

    if (isInArray) {
      return null;
    }

    return a;
  };

  const check = () => {
    setNotvalid(true);
    if (loginType === 'Company') {
      if (
        controllingCompaniesInputValidation(
          companyDataList?.ParentCompanyCodesFromRequest,
          !hasParentCompanyState
        ) === null &&
        validateEmail(ceoDataList.ApplicantEmail) === null &&
        validatePhone(ceoDataList.ApplicantPhone) === null &&
        isLoadSumValid(
          loanInfo.LoanSum,
          companyDataList.CompanyRegistrationDate,
          companyDataList.CompanySizeType,
          loanInfo.LoanPurposeId,
          loanInfo.LoanSocialBusiness,
          loginType
        ) === null &&
        LoanTermValidation(loanInfo.LoanTerm, loanInfo.LoanPurposeId) ===
          null &&
        validateTextinput(companyDataList.CompanyManagerCitizenshipId) ===
          null &&
        validateTextinput(companyDataList.CompanyEvrk) === null &&
        validatePhone(companyDataList.CompanyPhone) === null &&
        validateEmail(companyDataList.CompanyEmail) === null &&
        validateEmail(companyDataList.ContractEmail) === null &&
        isValidIban(companyDataList.Iban) !== null &&
        loanInfo.LoanTerm !== null &&
        loanInfo.LoanTerm != 0 &&
        loanInfo.LoanDelayTerm !== null &&
        loanInfo.LoanSocialBusiness !== null &&
        loanInfo.LoanPurposeId !== '' &&
        loanInfo.LoanPurposeId !== null &&
        loanInfo.LoanPaymentDay !== null &&
        loanInfo.LoanPaymentDay.length !== 0 &&
        (!pepData.HasImportantResponsibilities ||
          (pepData.HasImportantResponsibilities &&
            !checkResponsibilityPersons(
              pepData.ImportantResponsibilityPersons
            ))) &&
        !checkControllingPersons(pepData.ControllingPersons) &&
        companyDataList.CompanySizeType !== null &&
        companyDataList.CompanySizeType.length !== 0 &&
        checks.Check1Confirment &&
        checks.Check2Correctness &&
        checks.Check3AgreementToCheck &&
        checks.Check4AgreementToPublish &&
        checks.Check5AgreementToManage &&
        checks.Check6OtherCircuference &&
        checks.Check7Restructuring &&
        companyDataList.ActivityAddressDistrict !== null &&
        companyDataList.RegistrationAddressDistrict !== null &&
        companyDataList.ActivityAddressMunicipality !== null &&
        companyDataList.RegistrationAddressMunicipality !== null &&
        // companyDataList.ActivityAddressSettlement !== null &&
        // companyDataList.RegistrationAddressSettlement !== null &&
        validateElderships(
          companyDataList.ActivityAddressSettlement,
          companyDataList.ActivityAddressEldership
        ) &&
        validateElderships(
          companyDataList.RegistrationAddressSettlement,
          companyDataList.RegistrationAddressEldership
        ) &&
        validateTextinput(companyDataList.ActivityAddressStreet, 'error', 1) ===
          null &&
        validateTextinput(
          companyDataList.RegistrationAddressStreet,
          'error',
          1
        ) === null &&
        validateTextinput(
          companyDataList.ActivityAddressApartmentNumber,
          'error',
          1
        ) === null &&
        validateTextinput(
          companyDataList.RegistrationAddressApartmentNumber,
          'error',
          1
        ) === null &&
        validateTextinput(
          companyDataList.ActivityAddressPostalCode,
          'error',
          1
        ) === null &&
        validateTextinput(
          companyDataList.RegistrationAddressPostalCode,
          'error',
          1
        ) === null &&
        validateVatDeclarationPeriod() &&
        validateQuestionnaireBlock() &&
        validateSelfDeclaration() === null
      ) {
        const data = {
          requestSystemInfo: {
            ...requestSystemInfo,
            requestType: loginType,
          },
          ApplicantData: requestData.ApplicantData,
          ContactInfo: ceoDataList,
          CompanyData: {
            ...companyDataList,
            ActivityAddressEldership: returnEldership(
              companyDataList.ActivityAddressEldership,
              companyDataList.ActivityAddressMunicipalityId
            ),
            ActivityAddressEldershipId: returnEldership(
              companyDataList.ActivityAddressEldershipId,
              companyDataList.ActivityAddressMunicipalityId
            ),
            RegistrationAddressSettlement: returnSettlement(
              companyDataList.RegistrationAddressSettlement,
              companyDataList.RegistrationAddressMunicipalityId
            ),
            RegistrationAddressSettlementId: returnSettlement(
              companyDataList.RegistrationAddressSettlementId,
              companyDataList.RegistrationAddressMunicipalityId
            ),
          },
          DeclarationInfo: declarationInfo,
          LoanInfo: loanInfo,
          FinancialData: companyQuestionList,
          SelfDeclaration: selfDeclarationData,
          suppliers: suppliers,

          ControllingCompanies: controllingCompanies,
          ControllingForeignCompanies: controllingForeignCompanies,
          Checks: checks,
          Questionnaire: ShowQuestionList() ? questionnaire : questionereEmpty,
          pepData: pepData
            ? {
                parentCompanyCode: pepData.ParentCompanyCode,
                HasImportantResponsibilities:
                  pepData.HasImportantResponsibilities,
                HasImportantResponsibilitiesMoreThan4:
                  pepData.HasImportantResponsibilitiesMoreThan4,
                importantResponsibilityPersons:
                  pepData.ImportantResponsibilityPersons,
                controllingPersons: pepData.ControllingPersons,
                controllingCompanies: pepData.ControllingCompanies,
              }
            : null,
        };
        dispatch(actions.dashboard.saveRquestAction(data));
        setSavedRequest(true);
      } else {
        scrollToError();
      }
    } else if (loginType === 'Individual') {
      // validateTextinput(applicantData.CompanyActivityAddress) === null &&
      if (
        controllingCompaniesInputValidation(
          applicantData.ParentCompanyCodesFromRequest,
          !hasParentCompanyState
        ) === null &&
        isLoadSumValid(
          loanInfo.LoanSum,
          applicantData.IndividualActivityCertificateNumberStartDate,
          applicantData.CompanySizeType,
          loanInfo.LoanPurposeId,
          loanInfo.LoanSocialBusiness,
          loginType
        ) === null &&
        LoanTermValidation(loanInfo.LoanTerm, loanInfo.LoanPurposeId) ===
          null &&
        validateTextinput(applicantData.CompanyManagerCitizenshipId) === null &&
        validatePhone(applicantData.CompanyPhone) === null &&
        validateEmail(applicantData.CompanyEmail) === null &&
        validateEmail(applicantData.ContractEmail) === null &&
        isValidIban(applicantData.Iban) !== null &&
        isIntegerNumber(applicantData.NumberOfEmployees) === null &&
        validateTextinput(applicantData.IndividualActivityCertificateNumber) ===
          null &&
        validateTextinput(applicantData.CompanyEvrk) === null &&
        //loanInfo.SectorType !== null &&
        loanInfo.LoanTerm !== null &&
        loanInfo.LoanTerm != 0 &&
        loanInfo.LoanDelayTerm !== null &&
        loanInfo.LoanDelayTerm.length !== 0 &&
        loanInfo.LoanSocialBusiness !== null &&
        //loanInfo.LoanSocialBusiness.length !== 0 &&
        loanInfo.LoanPurposeId !== '' &&
        loanInfo.LoanPurposeId !== null &&
        loanInfo.LoanPaymentDay !== null &&
        loanInfo.LoanPaymentDay.length !== 0 &&
        (!pepData.HasImportantResponsibilities ||
          (pepData.HasImportantResponsibilities &&
            !checkResponsibilityPersons(
              pepData.ImportantResponsibilityPersons
            ))) &&
        !checkControllingPersons(pepData.ControllingPersons) &&
        validateTextinput(
          applicantData.IndividualActivityCertificateNumberStartDate
        ) === null &&
        applicantData.CompanySizeType !== null &&
        applicantData.CompanySizeType.length !== 0 &&
        checks.Check1Confirment &&
        checks.Check2Correctness &&
        checks.Check4AgreementToPublish &&
        checks.Check5AgreementToManage &&
        checks.Check6OtherCircuference &&
        applicantData.ActivityAddressDistrict !== null &&
        applicantData.ActivityAddressMunicipality !== null &&
        // applicantData.ActivityAddressEldership !== null &&
        // applicantData.ActivityAddressSettlement !== null &&

        validateTextinput(applicantData.ActivityAddressStreet, 'error', 1) ===
          null &&
        validateTextinput(
          applicantData.ActivityAddressApartmentNumber,
          'error',
          1
        ) === null &&
        validateTextinput(
          applicantData.ActivityAddressPostalCode,
          'error',
          1
        ) === null &&
        validateFAVatDeclarationPeriod() &&
        validateQuestionnaireBlock() &&
        validateSelfDeclaration() === null
      ) {
        const data = {
          requestSystemInfo: {
            ...requestSystemInfo,
            requestType: loginType,
          },
          ApplicantData: {
            ...applicantData,
            IndividualActivityCertificateNumberStartDate:
              applicantData.IndividualActivityCertificateNumberStartDate != null
                ? applicantData.IndividualActivityCertificateNumberStartDate
                    .length !== 10
                  ? addHours(
                      applicantData.IndividualActivityCertificateNumberStartDate,
                      3
                    )
                  : applicantData.IndividualActivityCertificateNumberStartDate
                : null,
            ActivityAddressEldership: returnEldership(
              applicantData.ActivityAddressEldership,
              applicantData.ActivityAddressMunicipalityId
            ),
            ActivityAddressEldershipId: returnEldership(
              applicantData.ActivityAddressEldershipId,
              applicantData.ActivityAddressMunicipalityId
            ),
          },
          ContactInfo: ceoDataList,
          CompanyData: companyDataList,
          DeclarationInfo: declarationInfo,
          LoanInfo: loanInfo,
          FinancialData: companyQuestionList,
          SelfDeclaration: selfDeclarationData,
          Questionnaire: ShowQuestionList() ? questionnaire : questionereEmpty,
          Checks: checks,
          suppliers: suppliers,

          pepData: pepData
            ? {
                parentCompanyCode: pepData.ParentCompanyCode,
                HasImportantResponsibilities:
                  pepData.HasImportantResponsibilities,
                HasImportantResponsibilitiesMoreThan4:
                  pepData.HasImportantResponsibilitiesMoreThan4,
                importantResponsibilityPersons:
                  pepData.ImportantResponsibilityPersons,
                controllingPersons: pepData.ControllingPersons,
                controllingCompanies: pepData.ControllingCompanies,
              }
            : null,
        };
        dispatch(actions.dashboard.saveRquestAction(data));
        setSavedRequest(true);
      } else {
        scrollToError();
      }
    } else {
      console.log('negalima nustatyti prisijungusiojo tipo');
    }
  };

  const submit = () => {
    setNotvalid(true);
    setTimeout(() => {
      const el = document.getElementsByClassName('error1');
      const el2 = Object.values(el)?.some((val) => val.innerText.length > 0);
      if (el2) {
        scrollToError();
      } else {
        check();
      }
    }, 0);
  };

  const onHasImportantResponsibilitiesMoreThan4Change = (e) => {
    setPepData({
      ...pepData,
      ['HasImportantResponsibilitiesMoreThan4']: e.target.checked,
    });
  };

  const onHasImportantResponsibilities = (e) => {
    setPepData({
      ...pepData,
      ['HasImportantResponsibilities']: !pepData.HasImportantResponsibilities,
    });
  };

  // const onHasImportantResponsibilities = ({ target: { name, value } }) => {
  //   setPepData({
  //     ...pepData,
  //     [name]: value,
  //   });
  // };

  const onImportantResponsibilitiesChange = (index, propName, e) => {
    setPepData({
      ...pepData,
      ...(pepData.ImportantResponsibilityPersons[index][propName] =
        e.target.value),
    });
  };

  const onControllingPersonsChange = (index, propName, e) => {
    if (propName === 'sharesPart' || propName === 'votingPart') {
      if (
        (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) ||
        !e.target.value
      ) {
        setPepData({
          ...pepData,
          ...(pepData.ControllingPersons[index][propName] = e.target.value),
        });
      }
      return null;
    } else {
      setPepData({
        ...pepData,
        ...(pepData.ControllingPersons[index][propName] = e.target.value),
      });
    }
  };

  const onControllingCompaniesChange = (index, propName, e) => {
    if (propName === 'sharesPart' || propName === 'votingPart') {
      if (
        (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) ||
        !e.target.value
      ) {
        setPepData({
          ...pepData,
          ...(pepData.ControllingCompanies[index][propName] = e.target.value),
        });
      }
      return null;
    } else {
      setPepData({
        ...pepData,
        ...(pepData.ControllingCompanies[index][propName] = e.target.value),
      });
    }
  };

  const amlPepTrueFalseTranslation = (val) => (val === true ? 'Taip' : 'Ne');

  const getFinancialCriteriaResults = (id) => {
    dispatch(actions.dashboard.getFinancialCriteriaResultsAction(id));
    const open = {
      modalName: 'financialCriteriaModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const cancel = () => {
    const open = {
      modalName: 'goBackConfirmationModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const [requestId, setRequestId] = useState(null);

  const [savedRequest, setSavedRequest] = useState(false);

  //add values, that is n ot requered here
  const isRequeredToCheck = (val) => {
    if (
      val === 'OwnerCompanyCode' ||
      val === 'OwnerPersonalCode' ||
      val === 'OwnerIndividualActivityCertificateNumber' ||
      val === 'MonthlyRentalPrice' ||
      val === 'ParentCompanyCode'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCheckBox = (name) =>
    name.slice(0, 5) === 'check' || name.slice(0, 5) === 'Check' ? true : false;

  const isDate = (name) =>
    name.slice(name.length - 4) === 'Date' ? true : false;

  const onControllingCompaniesInputChange = ({ target: { name, value } }) => {
    setControllingCompanies(value);
  };

  const onCompanySizeTypeChange = ({ target: { name, value } }) => {
    setCompanyDataList({ ...companyDataList, [name]: value });
    setApplicantData({ ...applicantData, [name]: value });
  };
  {
    /*const onVatDeclarationChange = ({ target: { name, value } }) => {
    setCompanyDataList({ ...companyDataList, [name]: value });
  };

  const onFAVatDeclarationChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };*/
  }

  const onControllingForeignCompaniesInputChange = ({
    target: { name, value },
  }) => {
    setControllingForeignCompanies(value);
  };

  const onCompanyDataCheckBoxChange = (e, name) => {
    setCompanyDataList({ ...companyDataList, [name]: e.target.checked });
  };

  const onApplicantInputChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };
  const onApplicantDataCheckBoxChange = (e, name) => {
    setApplicantData({ ...applicantData, [name]: e.target.checked });
  };

  const handleApplicantDateChange = (date, name) => {
    if (date) {
      setApplicantData({ ...applicantData, [name]: date });
    }
  };

  const clearContractStartDate = () => {
    if (!viewWindow) {
      setApplicantData({
        ...applicantData,
        IndividualActivityCertificateNumberStartDate: null,
      });
    }
  };

  const copyRequest = (requestId) => {
    const loginType = getLocalStorageItemIfNotExpired('loginType');
    history.replace('/main-window/requests/new-request');

    if (loginType === 'Company') {
      dispatch(
        actions.dashboard.createNewRequestAction(null, 'copy', requestId)
      );
    } else if (loginType === 'Individual') {
      dispatch(
        actions.dashboard.createNewRequestIndividualAction(
          null,
          'copy',
          requestId
        )
      );
    } else {
      console.log('errror');
      swal({
        title: 'Įvyko klaida',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  };

  const checkControllingPersons = (data) => {
    for (let i = 0; i < data.length; i++) {
      let input = data[i];
      if (
        input.name &&
        input.name.length > 0 &&
        input.surname &&
        input.surname.length > 0 &&
        input.citizenshipId &&
        input.citizenshipId.length > 0 &&
        input.personCode &&
        input.personCode.length > 0 &&
        input.position &&
        input.position.length > 0 &&
        input.sharesPart &&
        input.sharesPart.length > 0 &&
        input.votingPart &&
        input.votingPart.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const checkControllingCompanies = (data) => {
    for (let i = 0; i < data.length; i++) {
      let input = data[i];
      if (
        input.name &&
        input.name.length > 0 &&
        input.code &&
        input.code.length > 0 &&
        input.country &&
        input.country.length > 0 &&
        input.sharesPart &&
        input.sharesPart.length > 0 &&
        input.votingPart &&
        input.votingPart.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const [modalData, setModalData] = useState({});
  const [modalCheckbox, setModalCheckbox] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalOkTitle, setModalOkTitle] = useState();
  const [comment, setComment] = useState('');
  const [userComment, setUserComment] = useState(false);
  const [mainText, setMainText] = useState('');
  const [questionnaire, setQuestionnaire] = useState(null);

  const approveRequest = () => {
    setModalData({ requestId });

    dispatch(
      actions.modals.setModalConfirmAction(
        requestId,
        'APPROVE_REQUEST',
        userComment
      )
    );
  };

  const approveRequestWithDocs = () => {
    setModalData({ requestId });

    dispatch(
      actions.modals.setModalConfirmAction(
        requestId,
        'APPROVE_REQUEST_WITH_DOCS'
      )
    );
  };

  const sign = () => {
    const open = {
      modalName: 'signContractModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeConditions = () => {
    const open = {
      modalName: 'changeConditionsModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const [isStatusGood, setIsStatusGood] = useState(false);

  const [requestSystemInfo, setRequestSystemInfo] = useState(null);

  const isSelectedEvrkInTheList = (val, fromWhere) => {
    if (evrksList && evrksList.length > 0) {
      const isInArray = evrksList.filter((x) => x == val);
      return isInArray.length === 0 ? false : true;
    }

    const isInArray = requestData.evrksList.filter((x) => x == val);
    return isInArray.length === 0 ? false : true;
  };
  useEffect(() => {
    dispatch(actions.dashboard.SetCanCreateRequest(false));
    setCeoDataList(requestData.ContactInfo); //kontaktinė informacija JA/FA bendras
    if (requestData.CompanyData) {
      setCompanyDataList({
        ...requestData.CompanyData,
        Iban: requestData.CompanyData.Iban
          ? requestData.CompanyData.Iban
          : 'LT',
        CompanyEvrk:
          viewWindow ||
          isSelectedEvrkInTheList(
            requestData.CompanyData.CompanyEvrk,
            'isuseeefect'
          )
            ? requestData.CompanyData.CompanyEvrk
            : null,
        RegistrationAddressMunicipality: !requestData.CompanyData
          .RegistrationAddressMunicipality
          ? '-'
          : requestData.CompanyData.RegistrationAddressMunicipality,
        RegistrationAddressSettlement:
          requestData.CompanyData.RegistrationAddressSettlement,
        ActivityAddressEldership:
          !requestData.CompanyData.ActivityAddressEldership &&
          !requestData.CompanyData.ActivityAddressMunicipality
            ? '-'
            : requestData.CompanyData.ActivityAddressEldership,
        ActivityAddressSettlement: !requestData.CompanyData
          .ActivityAddressSettlement
          ? '-'
          : requestData.CompanyData.ActivityAddressSettlement,
      });
    }

    loginType === 'Company'
      ? setHasParentCompanyValue(
          requestData.CompanyData &&
            requestData.CompanyData.ParentCompanyCodesFromRequest &&
            requestData.CompanyData.ParentCompanyCodesFromRequest.length !== 0
            ? true
            : false
        )
      : setHasParentCompanyValue(
          requestData.ApplicantData &&
            requestData.ApplicantData.ParentCompanyCodesFromRequest &&
            requestData.ApplicantData.ParentCompanyCodesFromRequest.length !== 0
            ? true
            : false
        );

    setDeclarationInfo(requestData.DeclarationInfo);
    setLoanInfo({
      ...requestData.LoanInfo,
    });
    setChecks(requestData.Checks);
    setevrksList(requestData.evrksList);
    requestData.ApplicantData != null &&
      setApplicantData({
        ...requestData.ApplicantData,
        Iban: requestData.ApplicantData.Iban
          ? requestData.ApplicantData.Iban
          : 'LT',
        CompanyEvrk:
          viewWindow ||
          isSelectedEvrkInTheList(
            requestData.ApplicantData.CompanyEvrk,
            'isuseeefect'
          )
            ? requestData.ApplicantData.CompanyEvrk
            : null,
      });
    setCompanyQuestionList(requestData.FinancialData);
    setRequestSystemInfo(requestData.RequestSystemInfo);
    setRequestId(
      requestData.RequestSystemInfo != null
        ? requestData.RequestSystemInfo.RequestId
        : null
    );

    // Pep
    if (requestData && requestData.PepData) {
      for (let i = 0; i < 4; i++) {
        const item = requestData.PepData.ImportantResponsibilityPersons[i];
        if (item) {
          requestData.PepData.ImportantResponsibilityPersons[i] = {
            name: item.Name,
            surname: item.Surname,
            birthDate: item.BirthDate,
            position: item.Position,
            country: item.Country,
            personRelation: item.PersonRelation,
          };
        } else {
          requestData.PepData.ImportantResponsibilityPersons[i] = {
            name: '',
            surname: '',
            birthDate: '',
            position: '',
            country: '',
            personRelation: '',
          };
        }
      }

      for (let i = 0; i < 4; i++) {
        const item = requestData.PepData.ControllingPersons[i];
        if (item) {
          requestData.PepData.ControllingPersons[i] = {
            name: item.Name,
            surname: item.Surname,
            citizenshipId: item.CitizenshipId,
            personCode: item.PersonCode,
            position: item.Position,
            sharesPart: item.SharesPart,
            votingPart: item.VotingPart,
          };
        } else {
          requestData.PepData.ControllingPersons[i] = {
            name: '',
            surname: '',
            citizenshipId: '',
            personCode: '',
            position: '',
            sharesPart: '',
            votingPart: '',
          };
        }
      }

      for (let i = 0; i < 4; i++) {
        const item = requestData.PepData.ControllingCompanies[i];
        if (item) {
          requestData.PepData.ControllingCompanies[i] = {
            name: item.Name,
            code: item.Code,
            country: item.Country,
            sharesPart: item.SharesPart,
            votingPart: item.VotingPart,
          };
        } else {
          requestData.PepData.ControllingCompanies[i] = {
            name: '',
            code: '',
            country: '',
            sharesPart: '',
            votingPart: '',
          };
        }
      }
    }
    if (requestData && requestData.Suppliers) {
      for (let i = 0; i < 5; i++) {
        const item = requestData.Suppliers[i];
        if (item) {
          requestData.Suppliers[i] = {
            name: item.Name,
            code: item.Code,
            registrationCountryId: item.RegistrationCountryId,
            evrk: item.Evrk,
            basisForPayments: item.BasisForPayments,
          };
        } else {
          requestData.Suppliers[i] = {
            name: '',
            code: '',
            registrationCountryId: '',
            evrk: '',
            basisForPayments: '',
          };
        }
      }
    }

    if (requestData) {
      setPepData(requestData.PepData);
      setSuppliers(requestData?.Suppliers);
    }

    //pep end

    setViewWindow(requestData.type === 'view' ? true : false);
    setIsStatusGood(
      requestData.RequestSystemInfo != null &&
        requestData.RequestSystemInfo.Status != null &&
        requestData.RequestSystemInfo.Status !== 6
        ? true
        : false
    );
    if (requestData.Invoice) {
      if (!requestData.Invoice.RentDate) {
        requestData.Invoice.RentDate = '2020-02-01';
      }
      if (!requestData.Invoice.InvoiceType) {
        requestData.Invoice.InvoiceType = 1;
      }
    }

    if (requestData.type != null && requestData.type === 'view') {
      setNotvalid(false);
    }

    setControllingCompanies(requestData.ControllingCompanies);
    setControllingForeignCompanies(requestData.ControllingForeignCompanies);
    setTurnoversInfo(arrangeTurnovers(requestData.TurnoversInfo));
    setSelfDeclarationtData(requestData.SelfDeclaration);
  }, [requestData]);

  const arrangeTurnovers = (list) => {
    if (
      list &&
      list.length === 6 &&
      list[0].Date &&
      list[0].Date === '2019-11-01T00:00:00' &&
      list[5].Date &&
      list[5].Date === '2021-01-01T00:00:00'
    ) {
      return [list[0], list[3], list[1], list[4], list[2], list[5]];
    }

    return list;
  };
  //Use efect for getting uploaded files
  useEffect(() => {
    if (
      requestData.RequestSystemInfo &&
      requestData.RequestSystemInfo.RequestId &&
      requestData.RequestSystemInfo.RequestId !== null
    ) {
      dispatch(
        actions.dashboard.getUploadedFileList(
          requestData.RequestSystemInfo.RequestId
        )
      );
    }
  }, [requestData]);

  const isOldEvrkValid = (val) => {
    if (!val || val === null) {
      return 'neįvestas EVRK kodas';
    }
    var ex = /\b\d{6}\b/g;
    if (ex.test(val) === false) {
      return 'Neteisingai įvestas EVRK kodas';
    }
    return null;
  };

  const validateRealEsateObjectId = (id) => {
    if (id === null || id.length === 0) {
      return 'Neįvestas privalomas laukas';
    }
    const ex = /^\d{4}-\d{4}-\d{4}$/;
    if (ex.test(id) === false) {
      return validateRealEsateObjectId2(id);
    }

    return null;
  };

  const validateRealEsateObjectId2 = (id) => {
    const ex2 = /^\d{4}-\d{4}-\d{4}:\d{4}$/;
    if (ex2.test(id) === false) {
      return 'Neteisingai įvestas formatas';
    }
    return null;
  };

  const isValidIban = (v) => {
    if (!v) {
      return false;
    }
    return v.match(/[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}/);
  };
  const hasParentCompany = (e) => {
    setHasParentCompanyState(e.target.value === 'true' ? true : false);

    const has = e.target.value === 'true';
    if (!has) {
      setCompanyDataList({
        ...companyDataList,
        ['ParentCompanyCodesFromRequest']: '',
      });

      setApplicantData({
        ...applicantData,
        ['ParentCompanyCodesFromRequest']: '',
      });
    }
    setHasParentCompanyValue(has);
  };

  const hasParentCompanyFA = (e) => {
    setHasParentCompanyState(e.target.value === 'true' ? true : false);

    const has = e.target.value === 'true';
    if (!has) {
      setCompanyDataList({
        ...applicantData,
        ['ParentCompanyCodesFromRequest']: '',
      });
    }
    setHasParentCompanyValue(has);
  };

  const notValidCompanyCode = () => {
    if (
      hasParentCompanyValue &&
      !/^([0-9]{9})$/.test(companyDataList.ParentCompanyCodesFromRequest)
    ) {
      return true;
    }
    return false;
  };

  const notValidCompanyCodeFA = () => {
    if (
      hasParentCompanyValue &&
      !/^([0-9]{9})$/.test(applicantData.ParentCompanyCodesFromRequest)
    ) {
      return true;
    }
    return false;
  };
  const translateTurnover = (val) => {
    const year = val.substring(0, 4);
    const month = val.substring(5, 7);

    const dictionary = {
      '01': 'sausio',
      '02': 'vasario',
      '03': 'kovo',
      '04': 'balandžio',
      '05': 'gegužės',
      '06': 'birželio',
      '07': 'liepos',
      '08': 'rugpjūčio',
      '09': 'rugsėjo',
      10: 'spalio',
      11: 'lapkričio',
      12: 'gruodžio',
    };

    if (val && val.length === 4) {
      return `Apyvarta ${year}m `;
    }
    return `Apyvarta ${year}m. ${dictionary[month]} mėn.`;
  };

  const financialDataArray2019 = [
    'Fsi2019Submitted',
    'FsiStartDate2019',
    'FsiEndDate2019',
    'Assets19',
    'Liabilities19',
    'PropertyAmount19',
    'Turnover19',
  ];

  const financialDataArray2022 = [
    'Fsi2022Submitted',
    'FsiStartDate2022',
    'FsiEndDate2022',
    'Assets22',
    'Liabilities22',
    'PropertyAmount22',
    'Turnover22',
  ];

  const financialDataArray2021 = [
    'Fsi2021Submitted',
    'FsiStartDate2021',
    'FsiEndDate2021',
    'Assets21',
    'Liabilities21',
    'PropertyAmount21',
    'Turnover21',
  ];

  const financialDataArray2020 = [
    'Fsi2020Submitted',
    'FsiStartDate2020',
    'FsiEndDate2020',
    'Assets20',
    'Liabilities20',
    'PropertyAmount20',
    'Turnover20',
  ];

  const financialDataArray = (fsiYear) =>
    fsiYear == 2022
      ? financialDataArray2022
      : fsiYear == 2021
      ? financialDataArray2021
      : fsiYear == 2019
      ? financialDataArray2019
      : financialDataArray2020;

  const agreementsCheckBoxesArray = [
    'Check2Correctness',
    'Check5AgreementToManage',
    'Check3AgreementToCheck',
    'Check4AgreementToPublish',
    'Check6OtherCircuference',
    'Check7Restructuring',
    'Check1Confirment',
  ];

  const fileTypeTranslation = (name) => {
    if (name === 'Contract') {
      return 'Sutartis';
    } else if (name === 'Decision') {
      return 'Sprendimas';
    } else if (name === 'ChangeConditionsRequest') {
      return 'Prašymas pakeisti susitarimą';
    } else if (name === 'ChangeConditions') {
      return 'Sąlygų keitimo dokumentas';
    } else if (name === 'ChangeConditionsReject') {
      return 'Sąlygų keitimas atmestas';
    } else if (name === 'ChangeConditionsDecision') {
      return 'Sąlygų keitimo sprendimas';
    } else if (name === 'RejectDecision') {
      return 'Atmetimo sprendimas';
    }
  };

  const applicantDataArray = [
    'CompanyManagerName',
    'PersonalCode',
    'CompanyManagerCitizenshipId',
    'CompanyEvrk',
    'IndividualActivityCertificateNumberStartDate',
    'IndividualActivityCertificateNumber',
    'CompanyActivityAddress',
    // 'CompanyPhone',
    // 'CompanyEmail',
    // 'Iban',
  ];

  const ceoDataListArray = ['ApplicantName', 'ApplicantSurname'];

  const companyDataListArray = [
    'CompanyName',
    'CompanyCode',
    'CompanyManagerName',
    'CompanyManagerCitizenshipId',
    'CompanyType',
    'StatusInitial',
    'CompanyEvrk',

    'CompanyRegistrationDate',
    'CompanyRegistrationAddress',
    'CompanyActivityAddress',
    'CompanyPhone',
    'CompanyEmail',
    'ContractEmail',
    'Iban',
  ];

  const declarationInfoArray = [
    'EuLoanSum',
    'InvegaAsapSumLoan',
    'InvegaMostAffectedLoanSum',
    'InvegaTurapSumLoan',
    'InvegaPortfolioLoan2Sum',
  ];

  const selectStyle = {
    control: (base, state) => ({
      ...base,
      borderColor: '#033878',
      fontSize: '0.8rem',
      textColor: 'red',
      background: 'rgb(241, 240, 241)',
    }),
  };

  const formatEvrkJson2 = (evrk) => {
    return evrksList
      ? evrksList.map((val) => ({
          [val]: evrkFull[val],
        }))
      : null;
  };

  const formatEvrkJson = (evrk) => {
    if (Object.entries(evrkJson).some((val) => val[0] != val)) {
      evrkJson[evrk] = `${
        evrkJson[evrk] ? evrkJson[evrk] : evrkFull[evrk] ? evrkFull[evrk] : ''
      }`;
      return evrkJson;
    } else {
      return evrkJson;
    }
  };

  const getValueByCode = (list, code) => {
    if (list && code) {
      const foundValue = list?.find((val) => val.value?.Code === code);
      return foundValue?.label?.Name;
    }
  };

  const onEvrkInputChange = ({ target: { name, value } }, listData) => {
    if (loginType === 'Individual') {
      setApplicantData({
        ...applicantData,
        [name]: value === '-' ? null : value,
      });
    } else {
      setCompanyDataList({
        ...companyDataList,
        [name]: value === '-' ? null : value,
      });
    }
  };

  const onAddressInputChange = ({ target: { name, value } }, listData) => {
    if (name === 'RegistrationAddressDistrict') {
      setCompanyDataList({
        ...companyDataList,
        RegistrationAddressDistrict: getValueByCode(listData, value),
        RegistrationAddressDistrictId: value,
        RegistrationAddressMunicipality: null,
        RegistrationAddressMunicipalityId: null,
        RegistrationAddressEldership: null,
        RegistrationAddressEldershipId: null,
        RegistrationAddressSettlement: null,
        RegistrationAddressSettlementId: null,
      });
    }

    if (name === 'ActivityAddressDistrict') {
      if (loginType === 'Individual') {
        setApplicantData({
          ...applicantData,
          ActivityAddressDistrict: getValueByCode(listData, value),
          ActivityAddressDistrictId: value,
          ActivityAddressMunicipality: null,
          ActivityAddressMunicipalityId: null,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      } else {
        setCompanyDataList({
          ...companyDataList,
          ActivityAddressDistrict: getValueByCode(listData, value),
          ActivityAddressDistrictId: value,
          ActivityAddressMunicipality: null,
          ActivityAddressMunicipalityId: null,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      }
    }

    if (name === 'RegistrationAddressMunicipality') {
      setCompanyDataList({
        ...companyDataList,
        RegistrationAddressMunicipality: getValueByCode(listData, value),
        RegistrationAddressMunicipalityId: value,
        RegistrationAddressEldership: null,
        RegistrationAddressEldershipId: null,
        RegistrationAddressSettlement: null,
        RegistrationAddressSettlementId: null,
      });
    }

    if (name === 'ActivityAddressMunicipality') {
      if (loginType === 'Individual') {
        setApplicantData({
          ...applicantData,
          ActivityAddressMunicipality: getValueByCode(listData, value),
          ActivityAddressMunicipalityId: value,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      } else {
        setCompanyDataList({
          ...companyDataList,
          ActivityAddressMunicipality: getValueByCode(listData, value),
          ActivityAddressMunicipalityId: value,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      }
    }

    if (name === 'RegistrationAddressEldership') {
      setCompanyDataList({
        ...companyDataList,
        RegistrationAddressEldership: getValueByCode(listData, value),
        RegistrationAddressEldershipId: value,
        RegistrationAddressSettlement: null,
        RegistrationAddressSettlementId: null,
      });
    }

    if (name === 'ActivityAddressEldership') {
      if (loginType === 'Individual') {
        setApplicantData({
          ...applicantData,
          ActivityAddressEldership: getValueByCode(listData, value),
          ActivityAddressEldershipId: value,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      } else {
        setCompanyDataList({
          ...companyDataList,
          ActivityAddressEldership: getValueByCode(listData, value),
          ActivityAddressEldershipId: value,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      }
    }

    if (loginType === 'Individual' && name === 'ActivityAddressSettlement') {
      setApplicantData({
        ...applicantData,
        [name + 'Id']: value === '-' ? null : value,
        [name]: value === '-' ? null : getValueByCode(listData, value),
      });
    }

    if (
      loginType !== 'Individual' &&
      (name === 'RegistrationAddressSettlement' ||
        name === 'ActivityAddressSettlement')
    ) {
      setCompanyDataList({
        ...companyDataList,
        [name + 'Id']: value === '-' ? null : value,
        [name]: value === '-' ? null : getValueByCode(listData, value),
      });
    }
  };

  const onCountryInputChange = (value) => {
    if (loginType === 'Individual') {
      setApplicantData({
        ...applicantData,
        CompanyManagerCitizenshipId:
          value.target.value === '-' ? null : value.target.value,
      });
    } else {
      setCompanyDataList({
        ...companyDataList,
        CompanyManagerCitizenshipId:
          value.target.value === '-' ? null : value.target.value,
      });
    }
  };

  const from19To20 = (val) => `${val.slice(0, -2)}20`;

  const ageLessThen5 = (regDate) => {
    const date = new Date();
    const ageMinus5Years = subYears(new Date(date), 5);
    return new Date(regDate) > ageMinus5Years;
  };

  const ShowQuestionList = () => {
    const size =
      loginType === 'Company'
        ? companyDataList && companyDataList.CompanySizeType
        : applicantData && applicantData.CompanySizeType;
    const socialBusiness = loanInfo && loanInfo.LoanSocialBusiness;
    const companyRegistrationDate =
      companyDataList && companyDataList.CompanyRegistrationDate;
    const individualRegistrationData =
      applicantData &&
      applicantData.IndividualActivityCertificateNumberStartDate;
    if (size == 1 || size == 2) {
      if (
        (socialBusiness == 1 || socialBusiness == 2 || socialBusiness == 3) &&
        ageLessThen5(
          loginType === 'Company'
            ? companyRegistrationDate
            : individualRegistrationData
        )
      ) {
        return true;
      }
    }

    return false;
  };

  const onQuestionListCheckBoxChange = ({ target: { name, value } }) => {
    setQuestionnaire({
      ...questionnaire,
      [name]: value === 'true' ? true : false,
    });
  };

  const onQuestionnaireCommentChange = ({ target: { name, value } }) => {
    setQuestionnaire({
      ...questionnaire,
      [name]: value,
    });
  };

  const questionListArray = [
    'IsSmall',
    'IsNotListed',
    'IsProfitNotShared',
    'IsNotJoined',
    'IsNotTaken',
    'IsNotProblemic',
  ];

  const checkifAllQuestionListValuesCheck = () =>
    questionnaire &&
    questionnaire.IsSmall != null &&
    questionnaire &&
    questionnaire.IsNotListed != null &&
    questionnaire &&
    questionnaire.IsProfitNotShared != null &&
    questionnaire &&
    questionnaire.IsNotJoined != null &&
    questionnaire &&
    questionnaire.IsNotTaken != null &&
    questionnaire &&
    questionnaire.IsNotProblemic != null;

  const checkifAllQuestionListReqCommentHasNoValidationError = () =>
    questionnaire &&
    !questionListArray.some(
      (val) =>
        questionnaire[val] == false &&
        isCommentFilled(questionnaire[`${val}Comment`]) !== false
    );

  const returnCountryNameById = (id, x) => {
    const find = formatCountryJson.find((val) => val.value == id);
    if (x) {
      return find ? find?.label : id;
    }
    return find ? find[0]?.label : '-';
  };

  const validateItsNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    let key = '';
    if (theEvent.type === 'paste') {
      key = evt.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const [administrationInvoices, setAdministrationInvoices] = useState([]);
  const administrationInvoicesTableHeader = [
    'Sąskaitos numeris',
    'Failo pavadinimas',
    'Sukūrimo data ',
    'Paskolos paruošimo mokestis, Eur',
    'Veiksmai',
  ];

  const GetAdministrationInvoices = async () => {
    try {
      const response = await Api.GetAdministrationInvoices(requestId);
      const cleanedResponse = response.filter((val) => val != null);
      setAdministrationInvoices(
        cleanedResponse?.[0] !== null ? cleanedResponse : []
      );
    } catch (e) {
      // errorHandler(e, e?.response?.status, e?.response && e?.response?.data?.ErrorMessage, t('Ok'));
    } finally {
      // setLoadingStatus(false);
    }
  };

  useEffect(() => {
    if (requestSystemInfo?.RequestId) {
      GetAdministrationInvoices();
    }
  }, [requestSystemInfo?.RequestId]);

  const getContractName = (id) => {
    return `Sąskaita_${id}.pdf`;
  };

  const DownloadAdministrationInvoice = async (contractId, contractNumber) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadAdministrationInvoice(contractId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = getContractName(contractNumber);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const DownloadChangeConditionsInvoice = async (
    contractId,
    contractNumber
  ) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadChangeConditionsInvoice(contractId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = getContractName(contractNumber);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const [selfDeclarationData, setSelfDeclarationtData] = useState(null);
  const onSelfDeclarationChange = ({ target: { name, value } }) => {
    setSelfDeclarationtData({ ...selfDeclarationData, [name]: value });
  };

  const onSelfDeclarationtCheckBoxChange = (e, name) => {
    setSelfDeclarationtData({
      ...selfDeclarationData,
      [name]: e.target.checked,
    });
  };

  const validateSelfDeclaration = () => {
    let error = null;
    SelfDeclarationInfo.forEach((item) => {
      if (
        item.type === 'input' &&
        validateTextinput(selfDeclarationData?.[item.name], 'error', 1)
      ) {
        error = 'error';
      } else if (
        item.type === 'checkbox' &&
        selfDeclarationData?.[item.name] !== true
      ) {
        error = 'error';
      }
    });
    return error;
  };

  const checkLoanDelayTermFormat = (val) => {
    if (!Number.isInteger(+val)) {
      return 'Turi būti įvestas sveikas skaičius';
    }
    if (
      +val > 0 &&
      +val <= 120 &&
      loanInfo.LoanTerm &&
      +val > +loanInfo.LoanTerm
    ) {
      return ' lauko [paskolos atidėjimo terminas (mėn)] reikšmė negali būti didesnė negu lauko [Pageidaujamas Paskolos terminas mėn].';
    }
    if (+val >= 1 && +val <= 120) {
      return null;
    }

    return 'Turi būti įvestas sveikas skaičius nuo 1 iki 120';
  };

  const checkSuppliers = (data) => {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let input = data[i];
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }
    }
  };

  const checkControlingPersonsLinesFullyFilled = (data, index) => {
    if (data) {
      let input = data[index];

      if (
        input?.name ||
        input?.surname ||
        input?.citizenshiId ||
        input?.personCode ||
        input?.position ||
        input?.sharesPart ||
        input?.votingPart
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.surname &&
          input.surname.length > 0 &&
          input.citizenshipId &&
          input.citizenshipId.length > 0 &&
          input.personCode &&
          input.personCode.length > 0 &&
          input.position &&
          input.position.length > 0 &&
          input.sharesPart &&
          input.sharesPart.length > 0 &&
          input.votingPart &&
          input.votingPart.length > 0
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkControlingCompaniesLinesFullyFilled = (data, index) => {
    if (data) {
      let input = data[index];

      if (
        input?.name ||
        input?.code ||
        input?.country ||
        input?.sharesPart ||
        input?.votingPart
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.country &&
          input.country.length > 0 &&
          input.sharesPart &&
          input.sharesPart.length > 0 &&
          input.votingPart &&
          input.votingPart.length > 0
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkResponsibilityPersonsLinesFullyFilled = (data, index) => {
    if (data) {
      let input = data[index];

      if (
        input?.name ||
        input?.surname ||
        input?.birthDate ||
        input?.position ||
        input?.country ||
        input?.personRelation
      ) {
        if (
          input?.name &&
          input?.name?.length > 0 &&
          input.surname &&
          input?.surname?.length > 0 &&
          input?.birthDate &&
          input?.birthDate?.length > 0 &&
          input?.position &&
          input?.position?.length > 0 &&
          input?.country &&
          input?.country.length > 0 &&
          input?.personRelation &&
          input?.personRelation?.length > 0
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled1 = (data) => {
    if (data) {
      let input = data[1];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled2 = (data) => {
    if (data) {
      let input = data[2];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled3 = (data) => {
    if (data) {
      let input = data[3];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled4 = (data) => {
    if (data) {
      let input = data[4];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIf2021FinancesToShow = () => {
    const checkDate = new Date('2022-06-01');
    const now = new Date();
    if (now > checkDate) {
      return true;
    }
    return false;
  };

  const onSuppliersChange = (index, propName, e) => {
    suppliers[index][propName] = e.target.value;
    setSuppliers([...suppliers]);
  };

  const approveRequestWithDocsForChangeConditions = () => {
    setModalData({ requestId });

    dispatch(
      actions.modals.setModalConfirmAction(
        requestId,
        'APPROVE_REQUEST_WITH_DOCS_FOR_CHANGE_CONDITIONS'
      )
    );
  };

  const enforceMinMax = (el) => {
    if (el?.target?.value != '') {
      if (parseInt(el?.target?.value) < parseInt(el?.target?.min)) {
        el = el?.target?.min;
        return false;
      }
      if (parseInt(el?.target?.value) > parseInt(el?.target?.max)) {
        el = el?.target?.max;
        return false;
      }
    }
  };

  const checkIf2022FinancesToShow = () => {
    if (requestData?.RequestSystemInfo?.FirstSubmittedDate) {
      const checkDate = new Date('2023-05-31');
      const now = new Date(requestData?.RequestSystemInfo?.FirstSubmittedDate);
      if (now > checkDate) {
        return true;
      }
    }

    return false;
  };

  return (
    <WithSpinner loading={loading}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.requestBlockAll}>
              <div style={{ display: 'flex' }}>
                <h1 style={{ width: '50%', marginTop: '100px' }}>Paraiška</h1>
                <p
                  style={{ textAlign: 'right', width: '70%', height: '150px' }}
                >
                  <img src="/logo.png" height="100px"></img>
                </p>
              </div>
              {/*  {requestSystemInfo && requestSystemInfo.IsReserved && (
                <p style={{ marginBottom: '10px', color: '#BC2432' }}>
                  Dėmesio! Šiuo metu jau yra gauta tiek paraiškų, kurių
                  finansavimui bus išnaudota visa šiai finansinei priemonei
                  skirta lėšų suma. Dėl šios priežasties Jūsų teikiama paraiška
                  bus įtraukiama į rezervinį sąrašą. Paraiška bus nagrinėjama
                  tuo atveju, jei liktų lėšų arba būtų nuspręsta skirti
                  papildomą sumą priemonei įgyvendinti.
                </p>
            )}*/}
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h4>Paraiškos duomenys</h4>
                  {requestSystemInfo != null &&
                    requestSystemInfo.RequestId != null && (
                      <div className="row director-data-margin">
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name="RequestId"
                            type="string"
                            value={requestSystemInfo.RequestId}
                            placeholder={'Paraiškos numeris'}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name="DateCreated"
                            type="string"
                            value={
                              format(
                                new Date(requestSystemInfo.Created),
                                'yyyy-MM-dd HH:mm:ss'
                              ) || ''
                            }
                            placeholder={'Paraiškos sukūrimo data'}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name="DataStatus"
                            type="string"
                            value={requestStatus(requestSystemInfo.Status)}
                            placeholder={'Paraiškos būsena'}
                            disabled={true}
                          />
                        </div>
                      </div>
                    )}

                  <h4>Kontaktinė informacija</h4>
                  {ceoDataList != null && requestData.ContactInfo != null && (
                    <div className="row director-data-margin">
                      {ceoDataListArray.map((key, i) => (
                        <div className="col-lg-6 col-sm-12" key={i}>
                          <InputWithTextInline
                            name={key}
                            type={isNumberType(key) ? 'number' : 'string'}
                            onChange={onInputChange}
                            value={ceoDataList[key]}
                            placeholder={
                              loginType === 'Company'
                                ? requestDataLabelTranslation(key)
                                : applicantTranslation(key)
                            }
                            disabled={disableCheck(key)}
                          />
                          {notValid && !disableCheck(key) && (
                            <p className="errorsBlockText error1">
                              {validateTextinput(
                                ceoDataList[key],
                                'privalomas laukas',
                                3
                              )}
                            </p>
                          )}
                        </div>
                      ))}

                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'ApplicantEmail'}
                          type={'string'}
                          onChange={onInputChange}
                          value={ceoDataList['ApplicantEmail']}
                          placeholder={
                            loginType === 'Company'
                              ? requestDataLabelTranslation('ApplicantEmail')
                              : applicantTranslation('ApplicantEmail')
                          }
                          disabled={disableCheck('ApplicantEmail')}
                        />
                        {notValid && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validateEmail(ceoDataList['ApplicantEmail'])}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'ApplicantPhone'}
                          type={'string'}
                          onChange={onInputChange}
                          value={ceoDataList['ApplicantPhone']}
                          placeholder={
                            loginType === 'Company'
                              ? requestDataLabelTranslation('ApplicantPhone')
                              : applicantTranslation('ApplicantPhone')
                          }
                          disabled={disableCheck('ApplicantPhone')}
                        />

                        {notValid && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validatePhone(
                              ceoDataList['ApplicantPhone'],
                              'telefono numeris'
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* {Company data andly for JA} */}
                  {loginType === 'Company' && (
                    <>
                      <h4>Duomenys apie Verslo subjektą </h4>
                      {companyDataList != null &&
                        requestData.CompanyData != null && (
                          <div className="row director-data-margin">
                            {companyDataListArray.map((key, i) => {
                              if (isCheckBox(key) === true) {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <div className="checkbox">
                                      <label className="containerLabel noBlock">
                                        <input
                                          type="checkbox"
                                          name={key}
                                          checked={companyDataList[key]}
                                          disabled={viewWindow}
                                          onChange={(e) =>
                                            onCompanyDataCheckBoxChange(e, key)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                        {requestDataLabelTranslation(key)}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else if (key === 'CompanyEvrk') {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={i}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'CompanyEvrk'
                                      )}
                                    </label>
                                    <select
                                      name="CompanyEvrk"
                                      onChange={onEvrkInputChange}
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                      }}
                                    >
                                      {(!viewWindow ||
                                        evrksList.length === 0 ||
                                        !companyDataList.CompanyEvrk) && (
                                        <option
                                          selected={
                                            companyDataList.CompanyEvrk === null
                                          }
                                          value={null}
                                        >
                                          -
                                        </option>
                                      )}
                                      {/* {companyDataList.CompanyEvrk &&
                                        evrksList.length === 0 &&
                                        isSelectedEvrkInTheList(
                                          companyDataList.CompanyEvrk
                                        ) && (
                                          <option
                                            selected={
                                              companyDataList.CompanyEvrk
                                            }
                                            value={companyDataList.CompanyEvrk}
                                          >
                                            {`${
                                              companyDataList.CompanyEvrk
                                            } - ${
                                              evrkFull[
                                                companyDataList.CompanyEvrk
                                              ]
                                                ? evrkFull[
                                                    companyDataList.CompanyEvrk
                                                  ]
                                                : ''
                                            }`}
                                          </option>
                                        )} */}
                                      {formatEvrkJson2().map((val, j) => (
                                        <option
                                          key={j}
                                          selected={
                                            companyDataList.CompanyEvrk ==
                                            Object.keys(val)[0]
                                          }
                                          value={Object.keys(val)[0]}
                                        >
                                          {Object.keys(val)[0]} -{' '}
                                          {Object.values(val)[0]}
                                        </option>
                                      ))}
                                      {viewWindow &&
                                        companyDataList.CompanyEvrk && (
                                          <option
                                            selected={true}
                                            value={companyDataList.CompanyEvrk}
                                          >
                                            {companyDataList.CompanyEvrk} -{' '}
                                            {
                                              evrkFull[
                                                companyDataList.CompanyEvrk
                                              ]
                                            }
                                          </option>
                                        )}
                                    </select>
                                    {!viewWindow &&
                                      // !companyDataList.CompanyEvrk &&
                                      evrksList.length === 0 && (
                                        <p
                                          className="errorsBlockText error1"
                                          style={{ color: 'var(--pinkDark)' }}
                                        >
                                          Pagal iš VMI gautą informaciją, šiuo
                                          metu nevykdote jokios veiklos.
                                          Paraiškos užpildyti ir pateikti
                                          nepavyks.
                                        </p>
                                      )}
                                    {notValid && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {!companyDataList.CompanyEvrk
                                          ? 'Privalote pasirinkti pagrindinę ekonominę veiklą'
                                          : ''}
                                      </p>
                                    )}
                                    <p
                                      style={{
                                        color: '#597a96',
                                        marginTop: '5px',
                                      }}
                                    >
                                      *Jeigu įmonės pagrindinė veikla nesutampa
                                      su užregistruota, patikslinkite pagrindinę
                                      įmonės vykdomą veiklą VMI.
                                    </p>
                                  </div>
                                );
                              } else if (
                                key === 'CompanyManagerCitizenshipId'
                              ) {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={key}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'CompanyManagerCitizenship'
                                      )}
                                    </label>
                                    <select
                                      name="CompanyManagerCitizenshipId"
                                      onChange={(e) => onCountryInputChange(e)}
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                      }}
                                    >
                                      {companyDataList.CompanyManagerCitizenshipId ===
                                        requestData.CompanyData
                                          .CompanyManagerCitizenshipId &&
                                        companyDataList.CompanyManagerCitizenshipId && (
                                          <option
                                            selected={
                                              companyDataList.CompanyManagerCitizenshipId
                                            }
                                            value={
                                              companyDataList.CompanyManagerCitizenshipId
                                            }
                                          >
                                            {returnCountryNameById(
                                              companyDataList.CompanyManagerCitizenshipId
                                            )}
                                          </option>
                                        )}

                                      <option
                                        selected={
                                          companyDataList.CompanyManagerCitizenshipId ===
                                          null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>

                                      {formatCountryJson.map((val, j) => (
                                        <option
                                          selected={
                                            companyDataList.CompanyManagerCitizenshipId ==
                                            val.value
                                          }
                                          value={val.value}
                                          key={j}
                                        >
                                          {val.label}
                                        </option>
                                      ))}
                                    </select>

                                    {notValid && !viewWindow && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {!companyDataList.CompanyManagerCitizenshipId
                                          ? 'Privalote pasirinkti pilietybę'
                                          : ''}
                                      </p>
                                    )}
                                  </div>
                                );
                              } else if (key === 'Iban') {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <InputWithTextInline
                                      name={key}
                                      type={
                                        isNumberType(key) ? 'number' : 'string'
                                      }
                                      onChange={onCompanyInputChange}
                                      value={companyDataList[key]}
                                      placeholder={requestDataLabelTranslation(
                                        key
                                      )}
                                      maxLength="20"
                                      disabled={disableCheck(key)}
                                    />
                                    {!isRequeredToCheck(key) &&
                                      notValid &&
                                      !disableCheck(key) && (
                                        <p className="errorsBlockText error1">
                                          {!isValidIban(
                                            companyDataList[key]
                                          ) && (
                                            <p className="errorsBlockText error1">
                                              Neteisingas formatas
                                            </p>
                                          )}
                                        </p>
                                      )}
                                  </div>
                                );
                              } else if (
                                key === 'CompanyActivityAddress' ||
                                key === 'CompanyRegistrationAddress'
                              ) {
                                return (
                                  <AddressModal
                                    title={key}
                                    type={key.replace(/Company/gi, '')}
                                    onInputChange={onCompanyInputChange}
                                    onDropdownImputChange={onAddressInputChange}
                                    data={companyDataList}
                                    notValid={notValid}
                                    viewWindow={viewWindow}
                                    key={i}
                                  />
                                );
                              } else {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <InputWithTextInline
                                      name={key}
                                      type={
                                        isNumberType(key) ? 'number' : 'string'
                                      }
                                      onChange={onCompanyInputChange}
                                      value={
                                        key === 'CompanyRegistrationDate'
                                          ? format(
                                              new Date(companyDataList[key]),
                                              'yyyy-MM-dd'
                                            )
                                          : key === 'CompanySizeType'
                                          ? companySize(companyDataList[key])
                                          : companyDataList[key] || ''
                                      }
                                      placeholder={requestDataLabelTranslation(
                                        key
                                      )}
                                      disabled={disableCheck(key)}
                                    />
                                    {!isRequeredToCheck(key) &&
                                      notValid &&
                                      !disableCheck(key) && (
                                        <p className="errorsBlockText error1">
                                          {key === 'CompanyPhone'
                                            ? validatePhone(
                                                companyDataList[key],
                                                'telefono numeris'
                                              )
                                            : key === 'CompanyEmail' ||
                                              key === 'ContractEmail'
                                            ? validateEmail(
                                                companyDataList[key],
                                                'elektroninis paštas'
                                              )
                                            : key === 'Iban'
                                            ? !isValidIban(
                                                companyDataList[key]
                                              ) && (
                                                <p className="errorsBlockText error1">
                                                  Neteisingas formatas
                                                </p>
                                              )
                                            : validateTextinput(
                                                companyDataList[key],
                                                'privalomas laukas',
                                                1
                                              )}
                                        </p>
                                      )}
                                  </div>
                                );
                              }
                            })}
                            <div className="col-lg-6 col-sm-12">
                              <label
                                style={{
                                  margin: '8px 0 8px',
                                  color: '#597a96',
                                  fontSize: '0.8rem',
                                  lineHeight: '1.5',
                                }}
                              >
                                Verslo subjekto statusas
                              </label>
                              <select
                                disabled={viewWindow}
                                name="CompanySizeType"
                                onChange={(e) => onCompanySizeTypeChange(e)}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  // color: '#2e2d2d',
                                }}
                                value={companyDataList.CompanySizeType}
                              >
                                <option value={null}>{null}</option>
                                <option value={'1'}>Labai maža įmonė</option>
                                <option value={'2'}>Maža įmonė</option>
                                <option value={'3'}>Vidutinė įmonė</option>
                                <option value={'4'}>Didelė įmonė</option>
                              </select>
                              {notValid &&
                                (companyDataList.CompanySizeType === null ||
                                  companyDataList.CompanySizeType.length ===
                                    0) && (
                                  <p className="errorsBlockText error1">
                                    Privalote nurodyti verslo subjekto statusą
                                  </p>
                                )}
                            </div>
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{ marginTop: '10px' }}
                            >
                              <p style={{ color: '#597a96' }}>
                                Ar Verslo subjektas, teikiantis paraišką,
                                priklauso Įmonių grupei, kaip tai nurodyta
                                teikiamoje SVV deklaracijoje?
                              </p>

                              <label
                                style={{
                                  display: 'flex',
                                  // width: '48.5%',
                                  justifyContent: 'space-between',
                                  fontSize: '0.8rem',
                                  lineHeight: '0.8rem',
                                  marginTop: '1.5rem',
                                }}
                              >
                                <select
                                  style={{
                                    height: '39px',
                                    border: '1px solid #033878',
                                    borderRadius: '5px',
                                    background: viewWindow
                                      ? 'rgb(241, 240, 241'
                                      : '#fff',
                                    // color: '#2e2d2d',
                                  }}
                                  onChange={(e) => hasParentCompany(e)}
                                >
                                  <option
                                    value={false}
                                    selected={
                                      companyDataList &&
                                      (!companyDataList.ParentCompanyCodesFromRequest ||
                                        companyDataList
                                          .ParentCompanyCodesFromRequest
                                          .length == 0)
                                    }
                                  >
                                    Ne
                                  </option>
                                  <option
                                    value={true}
                                    selected={
                                      companyDataList &&
                                      companyDataList.ParentCompanyCodesFromRequest &&
                                      companyDataList
                                        .ParentCompanyCodesFromRequest.length >
                                        0
                                    }
                                  >
                                    Taip
                                  </option>
                                </select>
                              </label>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <p
                                className="text-style-new-request"
                                style={{ color: '#597a96', lineHeight: '1.5' }}
                              >
                                Nurodykite visus Įmonių grupei priklausančių
                                įmonių kodus, kurie nurodyti jūsų užpildytoje ir
                                teikiamoje SVV subjekto statuso deklaracijoje.
                                Jei esate didelė įmonė, nurodykite visus Įmonių
                                grupei priklausančių įmonių kodus. Pvz.
                                111111111, 222222222
                              </p>
                              <Input
                                style={{ color: '#747474' }}
                                name="ParentCompanyCodesFromRequest"
                                type="string"
                                disabled={viewWindow || !hasParentCompanyValue}
                                onChange={onCompanyInputChange}
                                value={
                                  companyDataList &&
                                  companyDataList.ParentCompanyCodesFromRequest
                                    ? companyDataList.ParentCompanyCodesFromRequest
                                    : ''
                                }
                                onKeyPress={(e) => {
                                  if (
                                    e.which !== 48 &&
                                    e.which !== 49 &&
                                    e.which !== 50 &&
                                    e.which !== 51 &&
                                    e.which !== 52 &&
                                    e.which !== 53 &&
                                    e.which !== 54 &&
                                    e.which !== 55 &&
                                    e.which !== 56 &&
                                    e.which !== 57 &&
                                    e.which !== 8 &&
                                    e.which !== 188 &&
                                    e.which !== 190 &&
                                    e.which !== 44 &&
                                    e.which !== 32
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />

                              {notValid && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {controllingCompaniesInputValidation(
                                    companyDataList.ParentCompanyCodesFromRequest,
                                    !hasParentCompanyState
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <div className="checkbox">
                                <label
                                  className="containerLabel noBlock"
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  <input
                                    type="checkbox"
                                    name={'CheckCompanyAge'}
                                    checked={companyDataList['CheckCompanyAge']}
                                    disabled={viewWindow}
                                    onChange={(e) =>
                                      onCompanyDataCheckBoxChange(
                                        e,
                                        'CheckCompanyAge'
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  {requestDataLabelTranslation(
                                    'CheckCompanyAge'
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <div className={styles.inlineContainer}>
                                <div className={styles.titleContainer}>
                                  <p>
                                    {rentContractTranslation(
                                      'ControllingCompanies'
                                    )}
                                  </p>
                                  <p>
                                    Jadis pateikti akcininkai:{' '}
                                    {requestData.ControllingCompaniesFromRegister !=
                                    null
                                      ? requestData.ControllingCompaniesFromRegister
                                      : 'nėra pateiktų akcininkų'}
                                  </p>
                                </div>
                                <input
                                  className={styles.inlineInput}
                                  style={{
                                    background: viewWindow && '#f1f0f1',
                                  }}
                                  name={'ControllingCompanies'}
                                  type={'string'}
                                  onChange={onControllingCompaniesInputChange}
                                  value={controllingCompanies}
                                  disabled={viewWindow}
                                  onKeyPress={(e) => {
                                    if (
                                      e.which !== 48 &&
                                      e.which !== 49 &&
                                      e.which !== 50 &&
                                      e.which !== 51 &&
                                      e.which !== 52 &&
                                      e.which !== 53 &&
                                      e.which !== 54 &&
                                      e.which !== 55 &&
                                      e.which !== 56 &&
                                      e.which !== 57 &&
                                      e.which !== 8 &&
                                      e.which !== 188 &&
                                      e.which !== 190 &&
                                      e.which !== 44 &&
                                      e.which !== 32
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {notValid && (
                                  <p
                                    className="errorsBlockText error1"
                                    style={{ color: 'var(--pinkDark)' }}
                                  >
                                    {controllingCompaniesInputValidation(
                                      controllingCompanies,
                                      true
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>{' '}
                            <div className="col-lg-6 col-sm-12">
                              <InputWithTextInline
                                name={'ControllingForeignCompanies'}
                                type="text"
                                onChange={
                                  onControllingForeignCompaniesInputChange
                                }
                                value={controllingForeignCompanies}
                                placeholder={rentContractTranslation(
                                  'ControllingForeignCompanies'
                                )}
                                disabled={disableCheck(
                                  'ControllingForeignCompanies'
                                )}
                                onKeyPress={(e) => {
                                  if (
                                    e.which !== 48 &&
                                    e.which !== 49 &&
                                    e.which !== 50 &&
                                    e.which !== 51 &&
                                    e.which !== 52 &&
                                    e.which !== 53 &&
                                    e.which !== 54 &&
                                    e.which !== 55 &&
                                    e.which !== 56 &&
                                    e.which !== 57 &&
                                    e.which !== 8 &&
                                    e.which !== 188 &&
                                    e.which !== 190 &&
                                    e.which !== 44 &&
                                    e.which !== 32
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {notValid && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {controlingForeignCompaiesInputValidation(
                                    controllingForeignCompanies
                                  )}
                                </p>
                              )}
                              <p style={{ color: '#597a96' }}>
                                *Kontrolė suprantama, kai įmonių tarpusavio
                                santykiai yra bent vienos rūšies iš toliau
                                išvardintų: <br />
                                a) viena įmonė turi kitos įmonės akcininkų arba
                                narių balsų daugumą; <br />
                                b) viena įmonė turi teisę paskirti arba atleisti
                                daugumą kitos įmonės administracijos, valdymo
                                arba priežiūros organo narių; <br />
                                c) pagal sutartį arba vadovaujantis steigimo
                                sutarties ar įstatų nuostata vienai įmonei
                                suteikiama teisė daryti kitai įmonei lemiamą
                                įtaką; <br />
                                d) viena įmonė, būdama kitos įmonės akcininkė
                                arba narė, vadovaudamasi su tos įmonės kitais
                                akcininkais ar nariais sudaryta sutartimi,
                                viena.
                              </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <InputWithTextInline
                                name="NumberOfEmployees"
                                type="number"
                                onChange={onCompanyInputChange}
                                onKeyPress={validateItsNumber}
                                value={companyDataList.NumberOfEmployees}
                                placeholder={rentContractTranslation(
                                  'NumberOfEmployees'
                                )}
                                disabled={disableCheck('NumberOfEmployees')}
                              />
                            {notValid && (
                              <p
                                className="errorsBlockText error1"
                                style={{ color: 'var(--pinkDark)' }}
                              >
                                {isIntegerNumber(
                                  companyDataList['NumberOfEmployees']
                                )}
                              </p>
                            )}
                          </div>

                          </div>
                        )}
                    </>
                  )}

                  {/* {Company data for JA} */}
                {/*}  {loginType === 'Company' && (
                    <>
                      <div className="">
                        {companyDataList != null &&
                          requestData.FinancialData &&
                          requestData.RequestSystemInfo && (
                            <>
                              <h4>Finansiniai duomenys </h4>
                              {requestData.RequestSystemInfo.Status !== 1 &&
                                requestData.RequestSystemInfo.Status !== 6 && (
                                  <div
                                    className={styles.titleContainer}
                                    style={{ marginTop: '16px' }}
                                  >
                                    <p style={{ fontSize: '16px' }}>
                                      Finansinių atskaitomybių informacija
                                    </p>
                                  </div>
                                )}
                              {!checkIf2022FinancesToShow() && (
                                <div className="row">
                                  {requestData.RequestSystemInfo.Status !== 1 &&
                                    requestData.RequestSystemInfo.Status !==
                                      6 &&
                                    financialDataArray(
                                      companyQuestionList.FsiYear
                                    ).map((key, i) => {
                                      if (
                                        key === 'Fsi2022Submitted' ||
                                        key === 'Fsi2021Submitted' ||
                                        key === 'Fsi2020Submitted' ||
                                        key === 'Fsi2019Submitted'
                                      ) {
                                        return (
                                          <div
                                            className="col-lg-6 col-sm-12"
                                            key={i}
                                          >
                                            <label
                                              style={{
                                                margin: '8px 0 8px',
                                                color: '#597a96',
                                                fontSize: '0.8rem',
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              {requestDataLabelTranslation(key)}
                                            </label>
                                            <select
                                              disabled={true}
                                              name={key}
                                              style={{
                                                background: true
                                                  ? 'rgb(241, 240, 241'
                                                  : '#fff',
                                                // color: '#2e2d2d',
                                              }}
                                              value={
                                                key === 'Fsi2022Submitted'
                                                  ? companyQuestionList.Fsi2022Submitted
                                                  : key === 'Fsi2021Submitted'
                                                  ? companyQuestionList.Fsi2021Submitted
                                                  : key === 'Fsi2019Submitted'
                                                  ? companyQuestionList.Fsi2019Submitted
                                                  : companyQuestionList.Fsi2020Submitted
                                              }
                                            >
                                              <option value={null}>Ne</option>
                                              <option value={true}>Taip</option>
                                              <option value={false}>Ne</option>
                                            </select>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            className="col-lg-6 col-sm-12"
                                            key={i}
                                          >
                                            <InputWithTextInline
                                              name={key}
                                              type={
                                                isNumberType(key)
                                                  ? 'number'
                                                  : 'string'
                                              }
                                              value={
                                                key === 'FsiEndDate' ||
                                                key === 'FsiStartDate' ||
                                                key === 'FsiEndDate2019' ||
                                                key === 'FsiStartDate2019' ||
                                                key === 'FsiEndDate2020' ||
                                                key === 'FsiStartDate2020' ||
                                                key === 'FsiEndDate2021' ||
                                                key === 'FsiStartDate2021' ||
                                                key === 'FsiEndDate2022' ||
                                                key === 'FsiStartDate2022' ||
                                                key === 'FsiEndDate2023' ||
                                                key === 'FsiStartDate2023'
                                                  ? companyQuestionList[key] &&
                                                    format(
                                                      new Date(
                                                        companyQuestionList[key]
                                                      ),
                                                      'yyyy-MM-dd'
                                                    )
                                                  : formatNumber(
                                                      companyQuestionList[key]
                                                    )
                                              }
                                              placeholder={requestDataLabelTranslation(
                                                key
                                              )}
                                              disabled={disableCheck(key)}
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              )}

                              {checkIf2022FinancesToShow() &&
                                companyQuestionList?.Fsi2022Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2022m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2022.map((key, i) => {
                                          if (key === 'Fsi2022Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2022Submitted'
                                                      ? companyQuestionList.Fsi2022Submitted
                                                      : companyQuestionList.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {checkIf2022FinancesToShow() &&
                                companyQuestionList?.Fsi2021Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (key === 'Fsi2021Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    companyQuestionList.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                      </div>
                    </>
                  )}*/}
                  {loginType === 'Company' && (
                    <>
                      <div className="">
                        {companyDataList != null &&
                          requestData.FinancialData &&
                          requestData.RequestSystemInfo && (
                            <>
                              {requestData.RequestSystemInfo.Status !== 1 && (
                                // requestData.RequestSystemInfo.Status !== 6 && (
                                <div
                                  className={styles.titleContainer}
                                  style={{
                                    marginTop: '16px',
                                    marginBottom: '15px',
                                  }}
                                >
                                  <h4>Finansiniai duomenys</h4>
                                  <FinancialTable
                                    data={requestData?.FinancialData}
                                    regDate={
                                      requestData?.CompanyData
                                        ?.CompanyRegistrationDate
                                    }
                                  />
                                </div>
                              )}

                              {companyQuestionList?.Fsi2021Submitted &&
                                checkIf2021FinancesToShow() && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (
                                            key === 'Fsi2020Submitted' ||
                                            key === 'Fsi2021Submitted'
                                          ) {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2020Submitted'
                                                      ? companyQuestionList.Fsi2020Submitted
                                                      : companyQuestionList.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate2021' ||
                                                    key === 'FsiStartDate2021'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {companyQuestionList?.Fsi2020Submitted && (
                                <>
                                  <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                    2020m. finansinės atskaitomybės duomenys{' '}
                                  </h4>
                                  <div className="row">
                                    {
                                      // requestData.RequestSystemInfo.Status !== 1 &&
                                      // requestData.RequestSystemInfo.Status !== 6 &&
                                      financialDataArray2020.map((key, i) => {
                                        if (
                                          key === 'Fsi2019Submitted' ||
                                          key === 'Fsi2020Submitted'
                                        ) {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2019Submitted'
                                                    ? companyQuestionList.Fsi2019Submitted
                                                    : companyQuestionList.Fsi2020Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else if (key === 'br') {
                                          return (
                                            <p style={{ visibility: 'hidden' }}>
                                              /
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020'
                                                    ? companyQuestionList[
                                                        key
                                                      ] &&
                                                      format(
                                                        new Date(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        ),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(
                                                        companyQuestionList[key]
                                                      )
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={disableCheck(key)}
                                              />
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                  </div>
                                </>
                              )}

                              {companyQuestionList?.Fsi2019Submitted &&
                                !checkIf2021FinancesToShow() && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2019m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2019.map((key, i) => {
                                          if (
                                            key === 'Fsi2019Submitted' ||
                                            key === 'Fsi2020Submitted'
                                          ) {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2019Submitted'
                                                      ? companyQuestionList.Fsi2019Submitted
                                                      : companyQuestionList.Fsi2020Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate2019' ||
                                                    key === 'FsiStartDate2019'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                      </div>
                    </>
                  )}

                  {/* <div className="row"> */}
                  {/*} <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className={styles.inlineContainer}>
                        <div className={styles.titleContainer}>
                          <p>
                            {rentContractTranslation('ControllingCompanies')}
                          </p>
                          <p>
                            Jadis pateikti akcininkai:{' '}
                            {requestData.ControllingCompaniesFromRegister !=
                            null
                              ? requestData.ControllingCompaniesFromRegister
                              : 'nėra pateiktų akcininkų'}
                          </p>
                        </div>
                        <input
                          className={styles.inlineInput}
                          style={{
                            background: viewWindow && '#f1f0f1',
                          }}
                          name={'ControllingCompanies'}
                          type={'string'}
                          onChange={onControllingCompaniesInputChange}
                          value={controllingCompanies}
                          disabled={viewWindow}
                        />
                        {/* {notValid && (
                                    <p
                                      className="errorsBlockText error1"
                                      style={{ color: 'var(--pinkDark)' }}
                                    >
                                      {controllingCompaniesInputValidation(
                                        controllingCompanies
                                      )}
                                    </p>
                                  )} */}
                  {/*</div>
                    </div>{' '}
                    <div className="col-lg-6 col-sm-12">
                      <InputWithTextInline
                        name={'ControllingForeignCompanies'}
                        type="text"
                        onChange={onControllingForeignCompaniesInputChange}
                        value={controllingForeignCompanies}
                        placeholder={rentContractTranslation(
                          'ControllingForeignCompanies'
                        )}
                        disabled={disableCheck('ControllingForeignCompanies')}
                      />
                      <p style={{ color: '#597a96' }}>
                        *Kontrolė suprantama, kai įmonių tarpusavio santykiai
                        yra bent vienos rūšies iš toliau išvardintų: <br />
                        a) viena įmonė turi kitos įmonės akcininkų arba narių
                        balsų daugumą; <br />
                        b) viena įmonė turi teisę paskirti arba atleisti daugumą
                        kitos įmonės administracijos, valdymo arba priežiūros
                        organo narių; <br />
                        c) pagal sutartį arba vadovaujantis steigimo sutarties
                        ar įstatų nuostata vienai įmonei suteikiama teisė daryti
                        kitai įmonei lemiamą įtaką; <br />
                        d) viena įmonė, būdama kitos įmonės akcininkė arba narė,
                        vadovaudamasi su tos įmonės kitais akcininkais ar
                        nariais sudaryta sutartimi, viena.
                      </p>
                    </div>
                  </div>*/}
                </div>

                {/* {Person data data for FA} */}
                {loginType === 'Individual' && (
                  <div className="col-lg-12 col-sm-12">
                    <h4>Duomenys apie verslo subjektą</h4>
                    {applicantData != null &&
                      requestData.ApplicantData != null && (
                        <div className="row">
                          {applicantDataArray.map((key, i) => {
                            if (isCheckBox(key) === true) {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <div className="checkbox">
                                    <label className="containerLabel noBlock">
                                      <input
                                        type="checkbox"
                                        name={key}
                                        checked={applicantData[key]}
                                        disabled={viewWindow}
                                        onChange={(e) =>
                                          onApplicantDataCheckBoxChange(e, key)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                      {requestDataLabelTranslation(key)}
                                    </label>
                                    {notValid &&
                                      !isRequeredToCheck(key) &&
                                      applicantData[key] !== true && (
                                        <p
                                          className="errorsBlockText error1"
                                          style={{
                                            color: 'var(--pinkDark)',
                                          }}
                                        >
                                          Turite sutikti su sąlyga
                                        </p>
                                      )}
                                  </div>
                                </div>
                              );
                            } else if (isDate(key) === true) {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <div className={styles.selectflex}>
                                    <label className="inlineDatepickerInputTitle">
                                      {requestDataLabelTranslation(key)}
                                    </label>
                                    <DatePicker
                                      className="inlineDatepickerInput"
                                      selected={
                                        applicantData[key] != null
                                          ? new Date(applicantData[key])
                                          : ''
                                      }
                                      onChange={(e) =>
                                        handleApplicantDateChange(e, key)
                                      }
                                      maxDate={
                                        key ===
                                        'IndividualActivityCertificateNumberStartDate'
                                          ? new Date()
                                          : null
                                      }
                                      // minDate={
                                      //   key ===
                                      //   'IndividualActivityCertificateNumberStartDate'
                                      //     ? new Date('2020-03-16')
                                      //     : null
                                      // }
                                      dateFormat="yyyy-MM-dd"
                                      disabled={viewWindow}
                                    />
                                    <span
                                      className={styles.clearDateButton}
                                      onClick={() => clearContractStartDate()}
                                    >
                                      X
                                    </span>
                                    {notValid && applicantData[key] == null && (
                                      <p
                                        className="errorsBlock error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        Turite pasirinkti datą
                                      </p>
                                    )}
                                  </div>
                                </div>
                              );
                            } else if (key === 'CompanyEvrk') {
                              return (
                                <div
                                  className="col-lg-6 col-sm-12"
                                  style={{
                                    width: '200px',
                                  }}
                                >
                                  <label className={styles.evrkTitle}>
                                    {' '}
                                    {requestDataLabelTranslation(
                                      'IndividualEvrk'
                                    )}
                                  </label>
                                  <select
                                    name="CompanyEvrk"
                                    onChange={onEvrkInputChange}
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                    }}
                                  >
                                    {/* {console.log('-----------+')}
                                    {console.log((evrksList.length === 0 ||
                                      !isSelectedEvrkInTheList(
                                        applicantData.CompanyEvrk
                                      )))}
                                      {console.log(evrksList.length === 0 )}
                                      {console.log(isSelectedEvrkInTheList(
                                        applicantData.CompanyEvrk
                                      ))}
                                      {console.log(applicantData)}
                                      {console.log('-----------apacia')} */}

                                    {(!viewWindow ||
                                      evrksList.length === 0 ||
                                      !isSelectedEvrkInTheList(
                                        applicantData.CompanyEvrk
                                      )) && (
                                      <option
                                        selected={
                                          applicantData.CompanyEvrk === null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>
                                    )}

                                    {/* {applicantData.CompanyEvrk &&
                                      evrksList.length !== 0 &&
                                      isSelectedEvrkInTheList(
                                        applicantData.CompanyEvrk
                                      ) && (
                                        <option
                                          selected={applicantData.CompanyEvrk}
                                          value={applicantData.CompanyEvrk}
                                        >
                                          {`${applicantData.CompanyEvrk} - ${
                                            evrkFull[applicantData.CompanyEvrk]
                                              ? evrkFull[
                                                  applicantData.CompanyEvrk
                                                ]
                                              : applicantData.CompanyEvrk
                                          }`}
                                        </option>
                                      )} */}

                                    {formatEvrkJson2().map((val) => (
                                      <option
                                        selected={
                                          applicantData.CompanyEvrk ==
                                          Object.keys(val)[0]
                                        }
                                        value={Object.keys(val)[0]}
                                      >
                                        {Object.keys(val)[0]} -{' '}
                                        {Object.values(val)[0]}
                                      </option>
                                    ))}
                                    {viewWindow &&
                                      applicantData.CompanyEvrk && (
                                        <option
                                          selected={true}
                                          value={applicantData.CompanyEvrk}
                                        >
                                          {applicantData.CompanyEvrk} -{' '}
                                          {evrkFull[applicantData.CompanyEvrk]}
                                        </option>
                                      )}
                                  </select>
                                  {!viewWindow &&
                                    // !applicantData.CompanyEvrk &&
                                    evrksList.length === 0 && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        Pagal iš VMI gautą informaciją, šiuo
                                        metu nevykdote jokios veiklos. Paraiškos
                                        užpildyti ir pateikti nepavyks.
                                      </p>
                                    )}
                                  {notValid && (
                                    <p
                                      className="errorsBlockText error1"
                                      style={{ color: 'var(--pinkDark)' }}
                                    >
                                      {!applicantData.CompanyEvrk
                                        ? 'Privalote pasirinkti pagrindinę ekonominę veiklą'
                                        : ''}
                                    </p>
                                  )}
                                </div>
                              );
                            } else if (key === 'CompanyManagerCitizenshipId') {
                              return (
                                <div
                                  className="col-lg-6 col-sm-12"
                                  style={{
                                    width: '200px',
                                  }}
                                >
                                  <label className={styles.evrkTitle}>
                                    {' '}
                                    {requestDataLabelTranslation(
                                      'CompanyManagerCitizenship'
                                    )}
                                  </label>
                                  <select
                                    name="CompanyManagerCitizenshipId"
                                    onChange={(e) => onCountryInputChange(e)}
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                    }}
                                  >
                                    {applicantData.CompanyManagerCitizenshipId ===
                                      requestData.ApplicantData
                                        .CompanyManagerCitizenshipId &&
                                      applicantData.CompanyManagerCitizenshipId && (
                                        <option
                                          selected={
                                            applicantData.CompanyManagerCitizenshipId
                                          }
                                          value={
                                            applicantData.CompanyManagerCitizenshipId
                                          }
                                        >
                                          {returnCountryNameById(
                                            applicantData.CompanyManagerCitizenshipId
                                          )}
                                        </option>
                                      )}

                                    <option
                                      selected={
                                        applicantData.CompanyManagerCitizenshipId ===
                                        null
                                      }
                                      value={null}
                                    >
                                      -
                                    </option>

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          applicantData.CompanyManagerCitizenshipId ==
                                          val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>

                                  {notValid && !viewWindow && (
                                    <p
                                      className="errorsBlockText error1"
                                      style={{ color: 'var(--pinkDark)' }}
                                    >
                                      {!applicantData.CompanyManagerCitizenshipId
                                        ? 'Privalote pasirinkti pilietybę'
                                        : ''}
                                    </p>
                                  )}
                                </div>
                              );
                            } else if (
                              key === 'CompanyActivityAddress' ||
                              key === 'CompanyRegistrationAddress'
                            ) {
                              return (
                                <AddressModal
                                  title={key}
                                  type={key.replace(/Company/gi, '')}
                                  onInputChange={onApplicantInputChange}
                                  onDropdownImputChange={onAddressInputChange}
                                  data={applicantData}
                                  notValid={notValid}
                                  viewWindow={viewWindow}
                                  key={i}
                                />
                              );
                            } else {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <InputWithTextInline
                                    name={key}
                                    type={
                                      isNumberType(key) ? 'number' : 'string'
                                    }
                                    onChange={onApplicantInputChange}
                                    value={applicantData[key]}
                                    placeholder={
                                      key === 'CompanyManagerName'
                                        ? 'Verslininko vardas ir pavardė'
                                        : requestDataLabelTranslation(key)
                                    }
                                    disabled={disableCheck(key)}
                                  />
                                  {!isRequeredToCheck(key) &&
                                    notValid &&
                                    !disableCheck(key) && (
                                      <p className="errorsBlockText error1">
                                        {key !==
                                        'IndividualActivityCertificateNumber2' ? (
                                          validateTextinput(
                                            applicantData[key],
                                            'privalomas laukas',
                                            1
                                          )
                                        ) : (
                                          <p className="errorsBlockText error1">
                                            {isOldEvrkValid(applicantData[key])}
                                          </p>
                                        )}
                                      </p>
                                    )}
                                </div>
                              );
                            }
                          })}

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'CompanyPhone'}
                              type={'string'}
                              onChange={onApplicantInputChange}
                              value={applicantData['CompanyPhone']}
                              placeholder={requestDataLabelTranslation(
                                'CompanyPhone'
                              )}
                              disabled={disableCheck('CompanyPhone')}
                            />
                            {notValid && !disableCheck('CompanyPhone') && (
                              <p className="errorsBlockText error1">
                                {validatePhone(
                                  applicantData['CompanyPhone'],
                                  'telefono numeris'
                                )}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'CompanyEmail'}
                              type={'string'}
                              onChange={onApplicantInputChange}
                              value={applicantData['CompanyEmail']}
                              placeholder={requestDataLabelTranslation(
                                'CompanyEmail'
                              )}
                              disabled={disableCheck('CompanyPhone')}
                            />
                            {notValid && !disableCheck('CompanyEmail') && (
                              <p className="errorsBlockText error1">
                                {validateEmail(
                                  applicantData['CompanyEmail'],
                                  'elektroninis paštas'
                                )}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'ContractEmail'}
                              type={'string'}
                              onChange={onApplicantInputChange}
                              value={applicantData['ContractEmail']}
                              placeholder={requestDataLabelTranslation(
                                'ContractEmail'
                              )}
                              disabled={disableCheck('CompanyPhone')}
                            />
                            {notValid && !disableCheck('ContractEmail') && (
                              <p className="errorsBlockText error1">
                                {validateEmail(
                                  applicantData['ContractEmail'],
                                  'elektroninis paštas'
                                )}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'Iban'}
                              type={'string'}
                              onChange={onApplicantInputChange}
                              value={applicantData['Iban']}
                              placeholder={requestDataLabelTranslation('Iban')}
                              disabled={disableCheck('Iban')}
                              maxLength="20"
                            />
                            {notValid && !disableCheck('Iban') && (
                              <p className="errorsBlockText error1">
                                {!isValidIban(applicantData['Iban']) && (
                                  <p className="errorsBlockText error1">
                                    Neteisingas formatas
                                  </p>
                                )}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <label
                              style={{
                                margin: '8px 0 8px',
                                color: '#597a96',
                                fontSize: '0.8rem',
                                lineHeight: '1.5',
                              }}
                            >
                              Verslo subjekto statusas
                            </label>
                            <select
                              disabled={viewWindow}
                              name="CompanySizeType"
                              onChange={(e) => onCompanySizeTypeChange(e)}
                              style={{
                                background: viewWindow
                                  ? 'rgb(241, 240, 241'
                                  : '#fff',
                                // color: '#2e2d2d',
                              }}
                              value={applicantData.CompanySizeType}
                            >
                              <option value={null}>{null}</option>
                              <option value={'1'}>Labai maža įmonė</option>
                              <option value={'2'}>Maža įmonė</option>
                              <option value={'3'}>Vidutinė įmonė</option>
                              <option value={'4'}>Didelė įmonė</option>
                            </select>
                            {notValid &&
                              (applicantData.CompanySizeType === null ||
                                applicantData.CompanySizeType.length === 0) && (
                                <p className="errorsBlockText error1">
                                  Privalote nurodyti verslo subjekto statusą
                                </p>
                              )}
                          </div>

                          <div
                            className="col-lg-6 col-sm-12"
                            style={{ marginTop: '10px' }}
                          >
                            <p style={{ color: '#597a96' }}>
                              Ar Verslo subjektas, teikiantis paraišką,
                              priklauso Įmonių grupei, kaip tai nurodyta
                              teikiamoje SVV deklaracijoje?
                            </p>

                            <label
                              style={{
                                display: 'flex',
                                // width: '48.5%',
                                justifyContent: 'space-between',
                                fontSize: '0.8rem',
                                lineHeight: '0.8rem',
                                marginTop: '1.5rem',
                              }}
                            >
                              <select
                                disabled={viewWindow}
                                style={{
                                  height: '39px',
                                  border: '1px solid #033878',
                                  borderRadius: '5px',
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  // color: '#2e2d2d',
                                }}
                                onChange={(e) => hasParentCompany(e)}
                              >
                                <option
                                  value={false}
                                  selected={
                                    applicantData &&
                                    (!applicantData.ParentCompanyCodesFromRequest ||
                                      applicantData
                                        .ParentCompanyCodesFromRequest.length ==
                                        0)
                                  }
                                >
                                  Ne
                                </option>
                                <option
                                  value={true}
                                  selected={
                                    applicantData &&
                                    applicantData.ParentCompanyCodesFromRequest &&
                                    applicantData.ParentCompanyCodesFromRequest
                                      .length > 0
                                  }
                                >
                                  Taip
                                </option>
                              </select>
                            </label>
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <p
                              className="text-style-new-request"
                              style={{ color: '#597a96' }}
                            >
                              Nurodykite visus Įmonių grupei priklausančių
                              įmonių kodus, kurie nurodyti jūsų užpildytoje ir
                              teikiamoje SVV subjekto statuso deklaracijoje. Jei
                              esate didelė įmonė, nurodykite visus Įmonių grupei
                              priklausančių įmonių kodus. Pvz. 111111111,
                              222222222.
                            </p>
                            <Input
                              style={{ color: '#747474' }}
                              name="ParentCompanyCodesFromRequest"
                              type="string"
                              disabled={viewWindow || !hasParentCompanyValue}
                              onChange={onApplicantDatanputChange}
                              value={
                                applicantData &&
                                applicantData.ParentCompanyCodesFromRequest
                                  ? applicantData.ParentCompanyCodesFromRequest
                                  : ''
                              }
                              onKeyPress={(e) => {
                                if (
                                  e.which !== 48 &&
                                  e.which !== 49 &&
                                  e.which !== 50 &&
                                  e.which !== 51 &&
                                  e.which !== 52 &&
                                  e.which !== 53 &&
                                  e.which !== 54 &&
                                  e.which !== 55 &&
                                  e.which !== 56 &&
                                  e.which !== 57 &&
                                  e.which !== 8 &&
                                  e.which !== 188 &&
                                  e.which !== 190 &&
                                  e.which !== 44 &&
                                  e.which !== 32
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />

                            {notValid && (
                              <p
                                className="errorsBlockText error1"
                                style={{ color: 'var(--pinkDark)' }}
                              >
                                {controllingCompaniesInputValidation(
                                  applicantData.ParentCompanyCodesFromRequest,
                                  !hasParentCompanyState
                                )}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <div className="checkbox">
                              <label className="containerLabel noBlock">
                                <input
                                  type="checkbox"
                                  name={'CheckIndividualAge'}
                                  checked={applicantData['CheckIndividualAge']}
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onApplicantDataCheckBoxChange(
                                      e,
                                      'CheckIndividualAge'
                                    )
                                  }
                                />
                                <span className="checkmark"></span>
                                {requestDataLabelTranslation(
                                  'CheckIndividualAge'
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name="NumberOfEmployees"
                              type="number"
                              onChange={onApplicantInputChange}
                              onKeyPress={validateItsNumber}
                              value={applicantData.NumberOfEmployees}
                              placeholder={rentContractTranslation(
                                'NumberOfEmployees'
                              )}
                              disabled={disableCheck('NumberOfEmployees')}
                            />
                            {notValid && (
                              <p
                                className="errorsBlockText error1"
                                style={{ color: 'var(--pinkDark)' }}
                              >
                                {isIntegerNumber(
                                  applicantData['NumberOfEmployees']
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>

              {/* ControllingCompaniesFromRequest */}
              {requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest.map((val, i) => {
                  return (
                    <>
                      <div
                        className={styles.titleContainer}
                        style={{ marginTop: '16px' }}
                      ></div>

                      <p
                        style={{
                          marginTop: '1rem',
                          marginLeft: '0',
                          fontSize: '1rem',
                        }}
                      >
                        Verslo subjekto kodas {val.CompanyCode}
                      </p>
                      {val.IsFound ? (
                        <>
                          <div
                            className="col-lg-12 col-sm-12"
                            key={i}
                            style={{ paddingLeft: '0px' }}
                          ></div>

                          <FinancialTable
                            data={val?.Financials}
                            regDate={val.RegistrationDate}
                          />
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>
                          Įmonė nerasta arba jos duomenys dar nepatikrinti.
                        </p>
                      )}
                    </>
                  );
                })}
              {/*{requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest.map((val, i) => {
                  return (
                    <>
                      <div
                        className={styles.titleContainer}
                        style={{ marginTop: '16px' }}
                      >
                        <h4 style={{ fontSize: '16px' }}>
                          Įmonių grupės finansinių atskaitomybių informacija
                        </h4>
                      </div>
                      <p
                        style={{
                          marginTop: '1rem',
                          marginLeft: '0',
                          fontSize: '1rem',
                        }}
                      >
                        Verslo subjekto kodas {val.CompanyCode}
                      </p>
                      {val.IsFound ? (
                        <>
                          <div
                            className="col-lg-12 col-sm-12"
                            key={i}
                            style={{ paddingLeft: '0px' }}
                          >
                            <div>
                              {!checkIf2022FinancesToShow() && (
                                <div className="row">
                                  {requestData.RequestSystemInfo.Status !== 1 &&
                                    requestData.RequestSystemInfo.Status !==
                                      6 &&
                                    financialDataArray(val.FsiYear).map(
                                      (key, i) => {
                                        if (
                                          key === 'Fsi2022Submitted' ||
                                          key === 'Fsi2021Submitted' ||
                                          key === 'Fsi2020Submitted' ||
                                          key === 'Fsi2019Submitted'
                                        ) {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2022Submitted'
                                                    ? val.Fsi2022Submitted
                                                    : key === 'Fsi2021Submitted'
                                                    ? val.Fsi2021Submitted
                                                    : key === 'Fsi2019Submitted'
                                                    ? val.Fsi2019Submitted
                                                    : val.Fsi2020Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate' ||
                                                  key === 'FsiStartDate' ||
                                                  key === 'FsiEndDate2019' ||
                                                  key === 'FsiStartDate2019' ||
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020' ||
                                                  key === 'FsiEndDate2021' ||
                                                  key === 'FsiStartDate2021' ||
                                                  key === 'FsiEndDate2022' ||
                                                  key === 'FsiStartDate2022' ||
                                                  key === 'FsiEndDate2023' ||
                                                  key === 'FsiStartDate2023'
                                                    ? val[key] &&
                                                      format(
                                                        new Date(val[key]),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(val[key])
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={disableCheck(key)}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              )}

                              {checkIf2022FinancesToShow() &&
                                val?.Fsi2022Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2022m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2022.map((key, i) => {
                                          if (key === 'Fsi2022Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2022Submitted'
                                                      ? val.Fsi2022Submitted
                                                      : val.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {checkIf2022FinancesToShow() &&
                                val?.Fsi2021Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (key === 'Fsi2021Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={val.Fsi2021Submitted}
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                      )}
                    </>
                  );
                })} */}

              <div className="col-lg-12 col-sm-12">
                {requestData.ControllingCompaniesFromRequest != null &&
                  requestData.RequestSystemInfo &&
                  requestData.RequestSystemInfo.Status !== 1 &&
                  requestData.RequestSystemInfo.Status !== 6 &&
                  ((val, i) => {
                    return (
                      <div className="col-lg-12 col-sm-12">
                        <p
                          style={{
                            marginTop: '1rem',
                            marginLeft: '1rem',
                            fontSize: '1rem',
                          }}
                        >
                          Verslo subjekto kodas {val.CompanyCode}
                        </p>
                        {val.IsFound ? (
                          <>
                            {/* <div className="col-lg-12 col-sm-12" key={i}>
                              <div className="row">
                                {requestData.RequestSystemInfo.Status !== 1 &&
                                  requestData.RequestSystemInfo.Status !== 6 &&
                                  financialDataArray(
                                    companyQuestionList?.FsiYear
                                  ).map((key, i) => {
                                    if (
                                      key === 'Fsi2022Submitted' ||
                                      key === 'Fsi2021Submitted' ||
                                      key === 'Fsi2020Submitted' ||
                                      key === 'Fsi2019Submitted'
                                    ) {
                                      return (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={i}
                                        >
                                          <label
                                            style={{
                                              margin: '8px 0 8px',
                                              color: '#597a96',
                                              fontSize: '0.8rem',
                                              lineHeight: '1.5',
                                            }}
                                          >
                                            {requestDataLabelTranslation(
                                              val['Fsi2022Submitted']
                                                ? key
                                                : val['Fsi2021Submitted']
                                                ? 'Fsi2021Submitted'
                                                : val['Fsi2019Submitted']
                                                ? 'Fsi2019Submitted'
                                                : 'Fsi2020Submitted'
                                            )}
                                          </label>
                                          <select
                                            disabled={true}
                                            name={
                                              val['Fsi2022Submitted']
                                                ? 'Fsi2022Submitted'
                                                : val['Fsi2021Submitted']
                                                ? 'Fsi2021Submitted'
                                                : val['Fsi2019Submitted']
                                                ? 'Fsi2019Submitted'
                                                : 'Fsi2020Submitted'
                                            }
                                            style={{
                                              background: true
                                                ? 'rgb(241, 240, 241'
                                                : '#fff',
                                              // color: '#2e2d2d',
                                            }}
                                            value={
                                              val['Fsi2022Submitted']
                                                ? val[key]
                                                : val['Fsi2019Submitted']
                                                ? val['Fsi2019Submitted']
                                                : val['Fsi2020Submitted']
                                                ? val['Fsi2020Submitted']
                                                : val['Fsi2021Submitted']
                                            }
                                          >
                                            <option value={null}>Ne</option>
                                            <option value={true}>Taip</option>
                                            <option value={false}>Ne</option>
                                          </select>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={i}
                                        >
                                          <InputWithTextInline
                                            name={key}
                                            type={'string'}
                                            value={
                                              key === 'FsiEndDate' ||
                                              key === 'FsiStartDate'
                                                ? val[key] &&
                                                  format(
                                                    new Date(val[key]),
                                                    'yyyy-MM-dd'
                                                  )
                                                : formatNumber(
                                                    val.FsiYear === 2021
                                                      ? val[key]
                                                      : val[from19To20(key)]
                                                  )
                                            }
                                            placeholder={requestDataLabelTranslation(
                                              key
                                            )}
                                            disabled={disableCheck(key)}
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div> */}
                            <div>
                              {!checkIf2022FinancesToShow() && (
                                <div className="row">
                                  {requestData.RequestSystemInfo.Status !== 1 &&
                                    requestData.RequestSystemInfo.Status !==
                                      6 &&
                                    financialDataArray(val.FsiYear).map(
                                      (key, i) => {
                                        if (
                                          key === 'Fsi2022Submitted' ||
                                          key === 'Fsi2021Submitted' ||
                                          key === 'Fsi2020Submitted' ||
                                          key === 'Fsi2019Submitted'
                                        ) {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2022Submitted'
                                                    ? val.Fsi2022Submitted
                                                    : key === 'Fsi2021Submitted'
                                                    ? val.Fsi2021Submitted
                                                    : key === 'Fsi2019Submitted'
                                                    ? val.Fsi2019Submitted
                                                    : val.Fsi2020Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate' ||
                                                  key === 'FsiStartDate' ||
                                                  key === 'FsiEndDate2019' ||
                                                  key === 'FsiStartDate2019' ||
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020' ||
                                                  key === 'FsiEndDate2021' ||
                                                  key === 'FsiStartDate2021' ||
                                                  key === 'FsiEndDate2022' ||
                                                  key === 'FsiStartDate2022' ||
                                                  key === 'FsiEndDate2023' ||
                                                  key === 'FsiStartDate2023'
                                                    ? val[key] &&
                                                      format(
                                                        new Date(val[key]),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(val[key])
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={disableCheck(key)}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              )}

                              {checkIf2022FinancesToShow() &&
                                val?.Fsi2022Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2022m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2022.map((key, i) => {
                                          if (key === 'Fsi2022Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2022Submitted'
                                                      ? val.Fsi2022Submitted
                                                      : val.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {checkIf2022FinancesToShow() &&
                                val?.Fsi2021Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (key === 'Fsi2021Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={val.Fsi2021Submitted}
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </div>
                          </>
                        ) : (
                          <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                        )}
                      </div>
                    );
                  })}
              </div>

              {/* Pep AML data */}

              <h4 style={{ marginTop: '2rem' }}>AML ir PEP </h4>

              {pepData && (
                <div>
                  <div style={{ width: '48.5%' }}>
                    <p className="text-style-new-request">
                      Ar Verslo subjekto vadovui, atstovui, naudos gavėjams ar
                      jų artimiesiems šeimos nariams[1] arba artimiesiems
                      pagalbininkams[2] šiuo metu arba per paskutinius 12
                      mėnesių patikėtos svarbios viešosios pareigos (PEP)?[3]
                    </p>

                    <label
                      style={{
                        display: 'flex',
                        // width: '48.5%',
                        justifyContent: 'space-between',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '0.8rem',
                        marginTop: '1.5rem',
                      }}
                    >
                      <select
                        disabled={viewWindow}
                        name="HasImportantResponsibilities"
                        onChange={onHasImportantResponsibilities}
                        style={{
                          background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                          // color: '#2e2d2d',
                        }}
                        value={pepData.HasImportantResponsibilities}
                      >
                        <option value={false}>Ne</option>
                        <option value={true}>Taip</option>
                      </select>
                    </label>
                  </div>

                  <p className="text-style-new-request">
                    Jei pažymėjote "taip", nurodykite asmenis ir jų informaciją:
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-lg-2 col-sm-2">Vardas</span>
                    <span className="col-lg-2 col-sm-2">Pavardė</span>
                    <span className="col-lg-2 col-sm-2">Gimimo data</span>
                    <span className="col-lg-2 col-sm-2">Pareigos</span>
                    <span className="col-lg-2 col-sm-2">
                      Valstybė, kurioje asmuo yra PEP
                    </span>
                    <span className="col-lg-2 col-sm-2">
                      Asmens ryšys su Verslo subjekte veikiančiais asmenimis
                    </span>
                  </div>
                  {/* {notValid &&
                    checkResponsibilityPersons(
                      pepData.ImportantResponsibilityPersons
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Užpildykite asmenis ir jų informaciją
                      </p>
                    )} */}

                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      0
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      1
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      2
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      3
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      4
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą pradėtą asmens arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ImportantResponsibilityPersons &&
                      pepData.ImportantResponsibilityPersons.map((obj, k) =>
                        Object.keys(obj).map((p, i) => {
                          if (p === 'country') {
                            return (
                              <div
                                className="col-lg-2 col-sm-2 padding-column"
                                key={i}
                              >
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="country"
                                >
                                  <select
                                    name="country"
                                    onChange={(e) =>
                                      onImportantResponsibilitiesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData
                                        .ImportantResponsibilityPersons?.[k]
                                        ?.country) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ImportantResponsibilityPersons?.[k]
                                            ?.country === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ImportantResponsibilityPersons?.[k]
                                            ?.country == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="col-lg-2 col-sm-2 padding-column"
                                key={i}
                              >
                                <Input
                                  key={i}
                                  name={p}
                                  type="string"
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onImportantResponsibilitiesChange(k, p, e)
                                  }
                                  value={obj[p]}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                  </div>

                  <p className="text-style-new-request">Paaiškinimas:</p>
                  <p className="text-style-new-request">
                    [1] Artimieji šeimos nariai - sutuoktinis, asmuo, su kuriuo
                    registruota partnerystė, tėvai, broliai, seserys, vaikai ir
                    vaikų sutuoktiniai, vaikų sugyventiniai.
                    <br />
                    [2] Artimas pagalbininkas:
                    <br />
                    1) fizinis asmuo, kuris su svarbias viešąsias pareigas
                    einančiu ar ėjusiu asmeniu yra to paties juridinio asmens ar
                    juridinio asmens statuso neturinčios organizacijos dalyvis
                    arba palaiko kitus dalykinius santykius;
                    <br />
                    2) fizinis asmuo, kuris yra vienintelis juridinio asmens ar
                    juridinio asmens statuso neturinčios organizacijos,
                    įsteigtos ar veikiančios de facto siekiant turtinės ar
                    kitokios asmeninės naudos svarbias viešąsias pareigas
                    einančiam ar ėjusiam asmeniui, naudos gavėjas.
                    <br />
                    [3] <b>Svarbios viešosios pareigos</b> – pareigos Lietuvos
                    Respublikoje, Europos Sąjungoje, tarptautinėse ar užsienio
                    valstybių institucijose:
                    <br />
                    1) valstybės vadovas, vyriausybės vadovas, ministras,
                    viceministras arba ministro pavaduotojas, valstybės
                    sekretorius, parlamento, vyriausybės arba ministerijos
                    kancleris;
                    <br />
                    2) parlamento narys;
                    <br />
                    3) aukščiausiųjų teismų, konstitucinių teismų ar kitų
                    aukščiausiųjų teisminių institucijų, kurių sprendimai negali
                    būti skundžiami, narys;
                    <br />
                    4) savivaldybės meras, savivaldybės administracijos
                    direktorius;
                    <br />
                    5) aukščiausiosios valstybių audito ir kontrolės
                    institucijos valdymo organo narys ar centrinio banko
                    valdybos pirmininkas, jo pavaduotojas ar valdybos narys;
                    <br />
                    6) ambasadorius, laikinasis reikalų patikėtinis, Lietuvos
                    kariuomenės vadas, kariuomenės pajėgų ir junginių vadai,
                    Gynybos štabo viršininkas ar užsienio valstybių aukšto rango
                    ginkluotųjų pajėgų karininkas;
                    <br />
                    7) valstybės įmonės, akcinės bendrovės, uždarosios akcinės
                    bendrovės, kurių akcijos ar dalis akcijų, suteikiančių
                    daugiau kaip 1/2 visų balsų šių bendrovių visuotiniame
                    akcininkų susirinkime, priklauso valstybei nuosavybės teise,
                    valdymo ar priežiūros organo narys;
                    <br />
                    8) savivaldybės įmonės, akcinės bendrovės, uždarosios
                    akcinės bendrovės, kurių akcijos ar dalis akcijų,
                    suteikiančių daugiau kaip 1/2 visų balsų šių bendrovių
                    visuotiniame akcininkų susirinkime, priklauso savivaldybei
                    nuosavybės teise ir kurios laikomos didelėmis įmonėmis pagal
                    Lietuvos Respublikos įmonių finansinės atskaitomybės
                    įstatymą, valdymo ar priežiūros organo narys;
                    <br />
                    9) tarptautinės tarpvyriausybinės organizacijos vadovas, jo
                    pavaduotojas, valdymo ar priežiūros organo narys;
                    <br />
                    10) politinės partijos vadovas, jo pavaduotojas, valdymo
                    organo narys.
                    <br />
                  </p>

                  <div className="checkbox">
                    <label className="containerLabel noBlock">
                      <input
                        type="checkbox"
                        name="HasImportantResponsibilitiesMoreThan4"
                        checked={
                          pepData &&
                          pepData.HasImportantResponsibilitiesMoreThan4
                        }
                        onChange={(e) =>
                          onHasImportantResponsibilitiesMoreThan4Change(e)
                        }
                        disabled={viewWindow}
                      />
                      <span className="checkmark"></span>
                      Ar yra daugiau Verslo subjekto vadovų, atstovų, naudos
                      gavėjų ar jų artimųjų šeimos narių arba artimųjų
                      pagalbininkų, kuriems šiuo metu arba per paskutinius 12
                      mėnesių patikėtos svarbios viešosios pareigos (PEP)?
                    </label>
                  </div>

                  <p
                    className="text-style-new-request"
                    style={{ marginLeft: '0px' }}
                  >
                    Nurodykite fizinius asmenis, kurie tiesiogiai ar
                    netiesiogiai turi arba kontroliuoja jūsų Verslo subjekto
                    daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie
                    tiesiogiai ar netiesiogiai kontroliuoja jūsų Verslo subjektą
                    kitais būdais
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-seven">Vardas</span>
                    <span className="col-seven">Pavardė</span>
                    <span className="col-seven">
                      Pilietybė<sup>*</sup>
                    </span>
                    <span className="col-seven">
                      Asmens kodas (jei ne Lietuvos pilietis - gimimo data)
                    </span>
                    <span className="col-seven">Pareigos</span>
                    <span className="col-seven">Akcijų dalis, proc.</span>
                    <span className="col-seven">
                      Balsavimo teisių dalis, proc.
                    </span>
                  </div>
                  {notValid &&
                    checkControllingPersons(pepData.ControllingPersons) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Užpildykite asmenis ir jų informaciją
                      </p>
                    )}

                  {notValid &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      0
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      1
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      2
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      3
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      4
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      5
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}

                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ControllingPersons &&
                      pepData.ControllingPersons.map((obj, k) =>
                        Object.keys(obj).map((p, i) => {
                          if (p === 'citizenshipId') {
                            return (
                              <div className="col-seven padding-column" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="citizenship"
                                >
                                  <select
                                    name="citizenshipId"
                                    onChange={(e) =>
                                      onControllingPersonsChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData.ControllingPersons?.[
                                        k
                                      ]?.citizenship) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.citizenshipId === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.citizenshipId == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col-seven padding-column" key={i}>
                                <Input
                                  key={i}
                                  name={p}
                                  type={
                                    p === 'sharesPart' || p === 'votingPart'
                                      ? 'number'
                                      : 'string'
                                  }
                                  onKeyUp={(e) => enforceMinMax(e)}
                                  onKeyPress={(e) => {
                                    if (
                                      e.which === 101 &&
                                      (p === 'sharesPart' || p === 'votingPart')
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onControllingPersonsChange(k, p, e)
                                  }
                                  value={obj[p]}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                  </div>
                  <p className="specify-info" style={{ color: '#597a96' }}>
                    <sup>*</sup> jeigu asmuo be pilietybės – nurodoma valstybė,
                    kuri išdavė asmens tapatybę patvirtinantį dokumentą
                  </p>
                  <p className="text-style-new-request">
                    Nurodykite įmones, kurios tiesiogiai ar netiesiogiai turi
                    arba kontroliuoja jūsų Verslo subjekto daugiau negu 25 proc.
                    akcijų ar balsavimo teisių
                  </p>
                  <p className="text-style-new-request">
                    Jeigu nurodėte įmonę(-es), kuri(-os) tiesiogiai ar
                    netiesiogiai turi arba kontroliuoja jūsų Verslo subjekto
                    daugiau negu 25 proc. akcijų ar balsavimo teisių, būtina
                    užpildyti aukščiau prašomą informaciją apie fizinius asmenis
                    - netiesioginius kliento akcininkus (dalyvius) arba asmenis,
                    kitaip kontroliuojančius kliento valdymą.
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-five">Įmonės pavadinimas</span>
                    <span className="col-five">Įmonės kodas</span>
                    <span className="col-five">Įmonės registracijos šalis</span>
                    <span className="col-five">Akcijų dalis, proc.</span>
                    <span className="col-five">
                      Balsavimo teisių dalis, proc.
                    </span>
                  </div>
                  {/*{notValid &&
                    checkControllingCompanies(pepData.ControllingCompanies) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Užpildykite asmenis ir jų informaciją
                      </p>
                    )}*/}
                  {notValid &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      0
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      1
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      2
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      3
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      4
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      5
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ControllingCompanies &&
                      pepData.ControllingCompanies.map((obj, k) =>
                        Object.keys(obj).map((p, i) => {
                          if (p === 'country') {
                            return (
                              <div className="col-five padding-column" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="country"
                                >
                                  <select
                                    name="country"
                                    onChange={(e) =>
                                      onControllingCompaniesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData
                                        .ControllingCompanies?.[k]
                                        ?.country) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingCompanies?.[k]
                                            ?.country === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingCompanies?.[k]
                                            ?.country == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col-five padding-column" key={i}>
                                <Input
                                  key={i}
                                  name={p}
                                  onKeyUp={(e) => enforceMinMax(e)}
                                  type={
                                    p === 'sharesPart' || p === 'votingPart'
                                      ? 'number'
                                      : 'string'
                                  }
                                  onKeyPress={(e) => {
                                    if (
                                      e.which === 101 &&
                                      (p === 'sharesPart' || p === 'votingPart')
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onControllingCompaniesChange(k, p, e)
                                  }
                                  value={obj[p]}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                  </div>
                  <p
                    className="text-style-new-request"
                    style={{ paddingBottom: '20px' }}
                  >
                    PASTABA: Jei tokių fizinių ir (ar) juridinių asmenų nėra
                    (nei vienas iš jų neturi arba nekontroliuoja daugiau nei 25
                    proc. akcijų ar balsavimo teisių), prašome nurodyti
                    pagrindinius akcininkus, turinčius daugiausiai akcijų.
                  </p>
                </div>
              )}

              {/* Pep Aml data end */}

              {/* {Tiekėjo (-ų) informacija start} */}
              <h4>Tiekėjo (-ų) informacija</h4>

              <div
                className="row table-style-header"
                style={{ marginLeft: '0px' }}
              >
                {' '}
                <span className="col-five">
                  Tiekėjo įmonės/asmens pavadinimas
                </span>
                <span className="col-five">Tiekėjo įmonės/asmens kodas</span>
                <span className="col-five">Įmonės registracijos šalis</span>
                <span className="col-five">
                  Tiekėjo vykdoma veikla (pagal EVRK2)
                </span>
                <span className="col-five">Mokėjimų pagrindas </span>
              </div>
              {checkSuppliers(suppliers)}
              {notValid && suppliers && checkSuppliers(suppliers) && (
                <p
                  className="errorsBlockText error1"
                  style={{ color: 'var(--pinkDark)' }}
                >
                  Užpildykite bent vieną tiekėją
                </p>
              )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled1(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled2(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled3(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled4(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              <div
                className="row inputs-block"
                style={{ marginLeft: '0px', marginBottom: '20px' }}
              >
                {suppliers &&
                  suppliers?.map((obj, k) =>
                    Object.keys(obj).map((p, i) => {
                      if (p === 'registrationCountryId') {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <div
                              // className="col-lg-12 col-sm-12"

                              className="registrationCountryIdInput"
                            >
                              <select
                                name="registrationCountryId"
                                onChange={(e) => onSuppliersChange(k, p, e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  height: 'auto',
                                }}
                              >
                                {(!viewWindow ||
                                  !requestData?.Suppliers?.[k]
                                    ?.registrationCountryId) && (
                                  <option
                                    selected={
                                      requestData?.Suppliers?.[k]
                                        ?.registrationCountryId === null
                                    }
                                    value={null}
                                  >
                                    {null}
                                  </option>
                                )}

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      requestData?.Suppliers?.[k]
                                        ?.registrationCountryId == val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      } else if (p === 'evrk') {
                        return (
                          <div
                            className="col-five padding-column registrationCountryIdInput"
                            key={i}
                          >
                            <select
                              name="evrk"
                              onChange={(e) => onSuppliersChange(k, p, e)}
                              disabled={viewWindow}
                              style={{
                                background: viewWindow
                                  ? 'rgb(241, 240, 241'
                                  : '#fff',
                                height: 'auto',
                              }}
                            >
                              {(!viewWindow ||
                                !requestData?.Suppliers?.[k]?.evrk) && (
                                <option
                                  selected={
                                    requestData?.Suppliers?.[k]?.evrk === null
                                  }
                                  value={null}
                                >
                                  {null}
                                </option>
                              )}

                              {Object.keys(evrkFullWithZeros).map((val, j) => (
                                <option
                                  key={j}
                                  selected={
                                    requestData?.Suppliers?.[k]?.evrk == val
                                  }
                                  value={val}
                                >
                                  {`${val} - ${evrkFullWithZeros[val]}`}
                                </option>
                              ))}

                              {Object.keys(evrkFullWithoutZeros).map(
                                (val, j) => (
                                  <option
                                    key={j}
                                    selected={
                                      requestData?.Suppliers?.[k]?.evrk == val
                                    }
                                    value={val}
                                  >
                                    {`${val} - ${evrkFullWithoutZeros[val]}`}
                                  </option>
                                )
                              )}

                              {viewWindow && (
                                <option
                                  selected={true}
                                  value={requestData?.Suppliers?.[k]?.evrk}
                                >
                                  {requestData?.Suppliers?.[k]?.evrk
                                    ? `${requestData?.Suppliers?.[k]?.evrk} - ${
                                        evrkFull[
                                          requestData?.Suppliers?.[k]?.evrk
                                        ]
                                      }`
                                    : '-'}
                                </option>
                              )}
                            </select>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <Input
                              key={i}
                              name={p}
                              //type={p === 'code' ? 'number' : 'string'}
                              type={p === 'code' ? 'string' : 'string'} //reikia ir raidziu
                              disabled={viewWindow}
                              onChange={(e) => onSuppliersChange(k, p, e)}
                              value={obj[p]}
                            />
                          </div>
                        );
                      }
                    })
                  )}
                <p
                  className="text-style-new-request"
                  style={{ paddingBottom: '20px' }}
                >
                  PASTABA: turi būti pateikti 5 pagrindiniai tiekėjai. Esant
                  mažiau, teikiamas mažesnis skaičius, bet ne mažiau nei 1
                  tiekėjas.
                </p>
              </div>

              {/* STAR-125 */}
              <h4>Deklaracijos apie gautą valstybės pagalbą duomenys</h4>
              <div style={{ width: '100%' }}>
                <p className="text-style-new-request">
                  Deklaracijos apie gautą valstybės pagalbą pagal 2020 m. kovo
                  19 d. Europos Komisijos komunikatą dėl laikinosios valstybės
                  pagalbos priemonių, skirtų ekonomikai remti reaguojant į
                  dabartinį COVID-19 protrūkį, sistemą (toliau - Komunikatas) ir
                  vėlesnius pakeitimus:
                </p>
              </div>
              <div className="row">
                {selfDeclarationData &&
                  SelfDeclarationInfo.map((item, i) => {
                    if (item.type === 'input') {
                      return (
                        <div className="col-lg-6 col-sm-6" key={i}>
                          <InputWithTextInline
                            name={item.name}
                            type="number"
                            value={selfDeclarationData?.[item.name]}
                            placeholder={item.title}
                            subtitles={item.subtitles}
                            onChange={onSelfDeclarationChange}
                            disabled={disableCheck(item.name)}
                            onKeyPress={validateItsNumber}
                          />
                          {notValid && (
                            <p className="errorsBlockText error1">
                              {isIntegerNumber(
                                selfDeclarationData?.[item.name]
                              )}
                            </p>
                          )}
                        </div>
                      );
                    } else if (item.type === 'checkbox') {
                      return (
                        <div className="checkbox mb-0 mt-2 mx-3 pl-2" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={item.name}
                              checked={selfDeclarationData?.[item.name]}
                              disabled={viewWindow}
                              onChange={(e) =>
                                onSelfDeclarationtCheckBoxChange(e, item.name)
                              }
                            />
                            <span className="checkmark"></span>
                            {item.title}
                          </label>
                          {notValid &&
                            selfDeclarationData?.[item.name] !== true && (
                              <p
                                className="errorsBlockText error1"
                                style={{
                                  color: 'var(--pinkDark)',
                                  textAlign: 'left',
                                }}
                              >
                                Turite sutikti su sąlyga
                              </p>
                            )}
                        </div>
                      );
                    }
                  })}
              </div>

              <h4>Informacija Paskolos sutarties parengimui</h4>
              {loanInfo && (
                <div className="row director-data-margin">
                  <div className="col-lg-6 col-sm-12">
                    <InputWithTextInline
                      name={'LoanSum'}
                      type={isNumberType('LoanSum') ? 'number' : 'string'}
                      onChange={onLoanInfoChange}
                      value={loanInfo['LoanSum'] || ''}
                      placeholder={requestDataLabelTranslation('LoanSum')}
                      disabled={disableCheck('LoanSum')}
                    />

                    {notValid && !disableCheck('LoanSum') && (
                      <p className="errorsBlockText error1">
                        {isLoadSumValid(
                          loanInfo.LoanSum,
                          loginType === 'Company'
                            ? companyDataList.CompanyRegistrationDate
                            : applicantData.IndividualActivityCertificateNumberStartDate,
                          loginType === 'Company'
                            ? companyDataList.CompanySizeType
                            : applicantData.CompanySizeType,
                          loanInfo.LoanPurposeId,
                          loanInfo.LoanSocialBusiness,
                          loginType
                        )}
                      </p>
                    )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanSocialBusiness')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanSocialBusiness"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={loanInfo.LoanSocialBusiness}
                    >
                      <option value={null}>{null}</option>
                      <option value={1}>Taip</option>
                      <option value={0}>Ne</option>
                      <option value={3}>Taip (esu Ukrainos pilietis)</option>
                      <option value={2}>Ne (esu Ukrainos pilietis)</option>
                    </select>
                    <p style={{ color: '#597a96' }}>
                      * Socialinio poveikio projektas – tai projektas, pagal
                      kurį verslininko ar įmonės pelno siekimas susiejamas su
                      socialiniais tikslais ir prioritetais{' '}
                    </p>
                    {notValid && loanInfo.LoanSocialBusiness == null && (
                      <p className="errorsBlockText error1">
                        Privalote pasirinkti paskolos tipą
                      </p>
                    )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanPurposeId')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanPurposeId"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={loanInfo.LoanPurposeId}
                    >
                      <option value={null}>{null}</option>
                      <option value={'1'}>Investicinė</option>
                      <option value={'2'}>Apyvartinė</option>
                    </select>
                    {notValid &&
                      (loanInfo.LoanPurposeId == null ||
                        loanInfo.LoanPurposeId == null) && (
                        <p className="errorsBlockText error1">
                          Privalote pasirinkti paskolos paskirtį
                        </p>
                      )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanTerm')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanTerm"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                        // color: '#2e2d2d',
                      }}
                      value={loanInfo.LoanTerm}
                    >
                      {/*} {[...new Array(110)].map((i, val) => (
                        <option value={i}>{val == 0 ? null : val + 11}</option>
                     ))}*/}
                      <option value={null}>{null}</option>
                      <option value={12}>{12}</option>
                      <option value={24}>{24}</option>
                      <option value={36}>{36}</option>
                      <option value={48}>{48}</option>
                      <option value={60}>{60}</option>
                      <option value={72}>{72}</option>
                      <option value={84}>{84}</option>
                      <option value={96}>{96}</option>
                      <option value={108}>{108}</option>
                      <option value={120}>{120}</option>
                    </select>
                    <p style={{ color: '#597a96', fontSize: '0.7rem' }}>
                      Pastaba. Minimalus terminas 12 mėn. Maksimalus apyvartinės
                      paskolos terminas 36 mėn., investicinės paskolos - 120
                      mėn.).
                    </p>

                    {notValid && !disableCheck('LoanTerm') && (
                      <p className="errorsBlockText error1">
                        {LoanTermValidation(
                          loanInfo.LoanTerm,
                          loanInfo.LoanPurposeId
                        )}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    {/* <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanDelayTerm')}
                    </label>



                    <select
                      disabled={viewWindow}
                      name="LoanDelayTerm"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                        // color: '#2e2d2d',
                      }}
                      value={loanInfo.LoanDelayTerm}
                    >
                      {[null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                        (val) => (
                          <option value={val} key={val}>
                            {val}
                          </option>
                        )
                      )}
                    </select> */}

                    <InputWithTextInline
                      name={'LoanDelayTerm'}
                      type="string"
                      onChange={onLoanInfoChange}
                      value={loanInfo['LoanDelayTerm'] || ''}
                      placeholder={requestDataLabelTranslation('LoanDelayTerm')}
                      disabled={disableCheck('LoanDelayTerm')}
                    />

                    {notValid &&
                      (validateTextinput(
                        loanInfo['LoanDelayTerm'],
                        'privalomas laukas',
                        1
                      ) ||
                        checkLoanDelayTermFormat(
                          loanInfo['LoanDelayTerm']
                        )) && (
                        //!disableCheck('LoanDelayTerm') &&
                        <p className="errorsBlockText error1">
                          {validateTextinput(
                            loanInfo['LoanDelayTerm'],
                            'privalomas laukas',
                            1
                          )}
                          {!validateTextinput(
                            loanInfo['LoanDelayTerm'],
                            'privalomas laukas',
                            1
                          ) &&
                            checkLoanDelayTermFormat(loanInfo['LoanDelayTerm'])}
                        </p>
                      )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanPaymentDay')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanPaymentDay"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={loanInfo.LoanPaymentDay}
                    >
                      {[null, 5, 15, 25].map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>

                    {notValid &&
                      (loanInfo.LoanPaymentDay == null ||
                        loanInfo.LoanPaymentDay == 0) && (
                        <p className="errorsBlockText error1">
                          Privalote pasirinkti pageidaujamą mokėjimo dieną
                        </p>
                      )}
                  </div>
                  {/* <div className="col-lg-12 col-sm-12">
                    <p
                      className="text-style-new-request"
                      style={{ marginTop: '6px' }}
                    >
                      1. Veikia Lietuvos Respublikoje, kaip tai detaliau
                      apibūdinta Apraše; <br />
                      2. Atitinka minimalius Lietuvos Respublikos mokesčių
                      administravimo įstatymo 401 straipsnyje nustatytus
                      patikimų mokesčių mokėtojų kriterijus;
                      <br />
                      3. Jam nėra iškelta nemokumo byla ir (ar) jis nėra
                      restruktūrizuojamas pagal Lietuvos Respublikos juridinių
                      asmenų nemokumo įstatymą arba iki jo įsigaliojimo
                      galiojusius Lietuvos Respublikos įmonių bankroto įstatymą
                      ir Lietuvos Respublikos restruktūrizavimo įstatymą; / jam
                      nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
                      Respublikos fizinių asmenų bankroto įstatymą; <br />
                      4. Nėra gavęs neteisėtos pagalbos, kuri Europos Komisijos
                      sprendimu (dėl individualios pagalbos arba pagalbos
                      schemos) buvo pripažinta neteisėta ir nesuderinama su
                      vidaus rinka, arba yra grąžinęs visą jos sumą, įskaitant
                      palūkanas, teisės aktuose nustatyta tvarka. <br />
                    </p>
                  </div> */}
                </div>
              )}

              {/* inline checkBoxes */}
              {checks != null && (
                <div
                  className="col-lg-12 col-sm-12"
                  style={{ paddingLeft: '5px' }}
                >
                  {/* {Object.keys(checks).map((key, i) => { */}

                  {agreementsCheckBoxesArray.map((key, i) => {
                    if (isCheckBox(key) === true) {
                      return (
                        <div className="checkbox" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={key}
                              checked={checks[key]}
                              disabled={viewWindow}
                              onChange={(e) => onCheckBoxChange(e, key)}
                            />
                            <span className="checkmark"></span>
                            {/* {checks[key] === 'Check1Confirment' ? check1Confirment() : requestDataLabelTranslation(key)} */}
                            {key === 'Check1Confirment'
                              ? check1Confirment()
                              : requestDataLabelTranslation(key)}
                          </label>
                          {notValid &&
                            !isRequeredToCheck(key) &&
                            checks[key] !== true && (
                              <p
                                className="errorsBlockText error1"
                                style={{
                                  color: 'var(--pinkDark)',
                                  textAlign: 'left',
                                }}
                              >
                                Turite sutikti su sąlyga
                              </p>
                            )}
                        </div>
                      );
                    }
                  })}
                </div>
              )}

              {requestData.CompanyData && ShowQuestionList() && (
                <div style={{ marginTop: '2rem' }}>
                  <h4>
                    ATITIKIMO KOMISIJOS REGLAMENTE (ES) NR.
                    651/2014 NUSTATYTIEMS PAREIŠKĖJUI TAIKOMIEMS REIKALAVIMAMS
                    KLAUSIMYNAS
                  </h4>
                  <div style={{ paddingBottom: '30px' }}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className={styles.tableHeaderLeft}>Kriterijai</th>
                          <th className={styles.tableHeaderRight}>
                            <span>Taip</span>
                            <span>Ne</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className={styles.qtabletBody}>
                        {questionListArray.map((val, i) => (
                          <>
                            <tr key={i}>
                              <td
                                style={{
                                  paddingLeft: '15px',
                                  paddingRight: '25px',
                                  color: '#597a96',
                                  lineHeight: '20px',
                                }}
                              >
                                {val === 'IsSmall'
                                  ? isSmallText()
                                  : questionListTranslations(val)}
                              </td>
                              <td>
                                {' '}
                                <div
                                  className={styles.radioButtonsBox}
                                  onChange={onQuestionListCheckBoxChange}
                                >
                                  {' '}
                                  <input
                                    type="radio"
                                    className={styles.radioButton}
                                    value={true}
                                    checked={questionnaire?.[val] == true}
                                    name={val}
                                    disabled={viewWindow}
                                  />{' '}
                                  <input
                                    type="radio"
                                    className={styles.radioButton}
                                    value={false}
                                    checked={questionnaire?.[val] == false}
                                    name={val}
                                    disabled={viewWindow}
                                  />
                                </div>
                              </td>
                            </tr>
                            {questionnaire?.[val] == false && (
                              <tr>
                                <td>
                                  <textarea
                                    style={{
                                      width: '95%',
                                      height: '50px',
                                      marginLeft: '15px',
                                      marginBottom: '10px',
                                      marginTop: '10px',
                                      padding: '5px',
                                      lineHeight: '1.2rem',
                                    }}
                                    placeholder="Kadangi pažymėjote Ne, detaliai apibūdinkite to priežastis"
                                    name={`${val}Comment`}
                                    type="string"
                                    value={questionnaire?.[`${val}Comment`]}
                                    onChange={onQuestionnaireCommentChange}
                                    disabled={viewWindow}
                                  />
                                  {notValid &&
                                    isCommentFilled(
                                      questionnaire[`${val}Comment`]
                                    ) && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ margin: '10px' }}
                                      >
                                        {isCommentFilled(
                                          questionnaire?.[`${val}Comment`]
                                        )}
                                      </p>
                                    )}
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                    {notValid && !checkifAllQuestionListValuesCheck() && (
                      <p className="errorsBlockText error1">
                        Visi klausimyno atsakymai privalomo būti užpildyti
                      </p>
                    )}
                  </div>
                </div>
              )}

              <SurwayAnswers Ratings={requestData?.Ratings} />

              <FileUploadContainerOld
                requestId={requestId}
                viewWindow={viewWindow}
                status={
                  requestData.RequestSystemInfo
                    ? requestData.RequestSystemInfo.Status
                    : null
                }
              />

              {requestData != null &&
                requestData.GuaranteesLoanSum !== undefined &&
                requestData.CovidLoanSum !== undefined &&
                requestData.RequestSystemInfo.Status > 7 && (
                  <>
                    <h2>Deklaracijos duomenys</h2>
                    <p style={{ marginTop: '1rem' }}>
                      Įmonė yra gavusi paskolą (- as) ar sudariusi lizingo
                      sandorį (- ius), kurie yra garantuoti skatinamosios
                      finansinės priemonės „Portfelinės garantijos paskoloms 2"
                      lėšomis: {requestData.GuaranteesLoanSum} Eur.
                    </p>

                    <p style={{ marginBottom: '1rem' }}>
                      Įmonė yra gavusi paskolą iš skatinamosios finansinės
                      priemonės „Paskolos labiausiai nuo COVID-19 nukentėjusiems
                      verslams" lėšų: {requestData.CovidLoanSum} Eur.
                    </p>
                  </>
                )}
              {((signInInfo != null &&
                signInInfo[0] != null &&
                signInInfo[0].DocumentCreatedAt != null) ||
                requestData?.RequestSystemInfo?.Status == 11 ||
                requestData?.RequestSystemInfo?.Status == 12 ||
                requestData?.RequestSystemInfo?.Status == 3 ||
                requestData?.RequestSystemInfo?.Status == 4) && (
                <>
                  <h4 style={{ marginTop: '1rem' }}>
                    Sutartys, sprendimai ir prašymai
                  </h4>
                  <RowTableAdmin header={rowTableHeader} isEmpty={false}>
                    {signInInfo?.map((val, i) => (
                      <RowTableRow>
                        <RowTableCell>
                          {fileTypeTranslation(signInInfo[i].FileType)}
                        </RowTableCell>
                        <RowTableCell>{signInInfo[i].FileName}</RowTableCell>
                        <RowTableCell>
                          {' '}
                          {signInInfo[i].DocumentCreatedAt != null &&
                            format(
                              new Date(signInInfo[i].DocumentCreatedAt),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                        </RowTableCell>
                        <RowTableCell>
                          {' '}
                          {signInInfo[i].ApplicantSigningDate != null &&
                            format(
                              new Date(signInInfo[i].ApplicantSigningDate),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                        </RowTableCell>
                        <RowTableCell>
                          {' '}
                          {signInInfo[i].InvegaSigningDate != null &&
                            format(
                              new Date(signInInfo[i].InvegaSigningDate),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                        </RowTableCell>

                        <RowTableCell>
                          <p
                            className="pdownload"
                            onClick={() =>
                              GetSignedDocumentContractUser(
                                requestId,
                                val.FileType,
                                val.FileName,
                                val?.ChangeConditionsFileId,
                                val?.ApplicantSigningDate,
                                val?.InvegaSigningDate
                              )
                            }
                          >
                            Parsisiųsti
                          </p>
                        </RowTableCell>
                      </RowTableRow>
                    ))}
                  </RowTableAdmin>
                </>
              )}

              {administrationInvoices != null &&
                administrationInvoices.length > 0 &&
                requestData?.RequestSystemInfo?.Status !== 1 &&
                requestData?.RequestSystemInfo?.Status !== 2 &&
                requestData?.RequestSystemInfo?.Status !== 3 &&
                requestData?.RequestSystemInfo?.Status !== 4 &&
                requestData?.RequestSystemInfo?.Status !== 5 &&
                requestData?.RequestSystemInfo?.Status !== 6 &&
                requestData?.RequestSystemInfo?.Status !== 11 &&
                requestData?.RequestSystemInfo?.Status !== 12 && (
                  <>
                    <h4 style={{ marginTop: '1rem' }}>Sąskaitos</h4>
                    <RowTableAdmin
                      header={administrationInvoicesTableHeader}
                      isEmpty={false}
                    >
                      {administrationInvoices?.map(
                        ({
                          ContractId,
                          CreatedAt,
                          ContractNumber,
                          AdministrationFee,
                          ChangeConditionsFileId,
                        }) => (
                          <RowTableRow key={ContractId}>
                            <RowTableCell>{ContractNumber}</RowTableCell>
                            <RowTableCell>
                              {getContractName(ContractNumber)}
                            </RowTableCell>
                            <RowTableCell>
                              {format(
                                new Date(CreatedAt),
                                'yyyy-MM-dd HH:mm:ss'
                              )}
                            </RowTableCell>
                            <RowTableCell>
                              {formatNumber(AdministrationFee)}
                            </RowTableCell>
                            <RowTableCell>
                              <p
                                className="pdownload"
                                onClick={() =>
                                  !ContractId && ChangeConditionsFileId
                                    ? DownloadChangeConditionsInvoice(
                                        ChangeConditionsFileId,
                                        ContractNumber
                                      )
                                    : DownloadAdministrationInvoice(
                                        ContractId,
                                        ContractNumber
                                      )
                                }
                              >
                                Parsisiųsti
                              </p>
                            </RowTableCell>
                          </RowTableRow>
                        )
                      )}
                    </RowTableAdmin>
                  </>
                )}

              {/*{requestData && requestData.Comment && (
                <>
                  <h4>Invega komentaras</h4>
                  <textarea
                    style={{ width: '100%', height: '100px' }}
                    placeholder="Invega komentaras"
                    disabled
                    value={requestData.Comment}
                  />
                </>
              )}*/}

              {(requestData?.RequestSystemInfo?.Status === 11 ||
                requestData?.RequestSystemInfo?.Status === 19) && (
                <AdditionalFileUploadContainerOld
                  requestId={requestId}
                  viewWindow={viewWindow}
                  status={
                    requestData.RequestSystemInfo
                      ? requestData.RequestSystemInfo.Status
                      : null
                  }
                  loanPurposeId={loanInfo?.LoanPurposeId}
                />
              )}

              {requestData &&
              requestData.CommentsHistory &&
              requestData.CommentsHistory.length ? (
                <div style={{ marginTop: '3rem', marginBottom: '2rem' }}>
                  <h4>Komentarų istorija </h4>
                  {
                    <RowTableAdmin
                      header={commentsHistoryTableHeader}
                      isEmpty={false}
                    >
                      {requestData.CommentsHistory.map((data) => {
                        return (
                          <RowTableRow>
                            <RowTableCell>
                              {data.Date &&
                                format(
                                  new Date(data.Date),
                                  'yyyy-MM-dd HH:mm:ss'
                                )}
                            </RowTableCell>
                            <RowTableCell>{data.NewComment}</RowTableCell>
                          </RowTableRow>
                        );
                      })}
                    </RowTableAdmin>
                  }
                </div>
              ) : null}

              <div className="col-lg-12 col-sm-12">
                <div className={styles.btnBlock}>
                  {!viewWindow && (
                    <>
                      <Button type="longBtn" onClick={() => submit()}>
                        IŠSAUGOTI IR PATIKRINTI ATITIKIMO KRITERIJUS
                      </Button>
                    </>
                  )}
                  {viewWindow &&
                    requestData.DeclarationInfo &&
                    //requestData.DeclarationInfo.EuLoanSum != null &&
                    (requestData.RequestSystemInfo.Status === 1 ||
                      requestData.RequestSystemInfo.Status === 5) && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() => approveRequest()}
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti paraišką
                        </Button>
                      </>
                    )}

                  {viewWindow &&
                    requestData.DeclarationInfo &&
                    requestData.RequestSystemInfo.Status === 11 && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() => approveRequestWithDocs()}
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti paraišką su papildomais dokumentais
                        </Button>
                      </>
                    )}

                  {viewWindow &&
                    requestData.DeclarationInfo &&
                    requestData.RequestSystemInfo.Status === 19 && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() =>
                            approveRequestWithDocsForChangeConditions()
                          }
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti papildomus dokumentus sąlygų keitimui
                        </Button>
                      </>
                    )}
                  {viewWindow &&
                    requestData.RequestSystemInfo &&
                    (requestData.RequestSystemInfo.Status === 9 ||
                      requestData.RequestSystemInfo.Status === 13 ||
                      requestData.RequestSystemInfo.Status === 14 ||
                      requestData.RequestSystemInfo.Status === 15 ||
                      requestData.RequestSystemInfo.Status === 16 ||
                      requestData.RequestSystemInfo.Status === 17 ||
                      requestData.RequestSystemInfo.Status === 18 ||
                      requestData.RequestSystemInfo.Status === 19) && (
                      <Link
                        to={`${config.APP_PATHS.paymentrequests}?requestId=${requestId}`}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                        }}
                      >
                        <Button type="longBtn">
                          {' '}
                          Išmokėti paskolą arba jos dalį
                        </Button>
                      </Link>
                    )}
                  {viewWindow ? (
                    <>
                      {requestData.RequestSystemInfo &&
                        (requestData.RequestSystemInfo.Status == 1 ||
                          requestData.RequestSystemInfo.Status == 5) && (
                          <Button
                            type="standart"
                            onClick={() => setViewWindow(false)}
                            className="btn-dark-blue-standart"
                          >
                            Redaguoti
                          </Button>
                        )}
                      <Link
                        to={config.APP_PATHS.requests}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                        }}
                      >
                        {' '}
                        <Button type="cancel">Atgal</Button>
                      </Link>
                    </>
                  ) : (
                    <Button type="cancel" onClick={() => cancel()}>
                      Atgal
                    </Button>
                  )}

                  {requestData.RequestSystemInfo &&
                    (requestData.RequestSystemInfo.Status === 6 ||
                      requestData.RequestSystemInfo.Status === 4) && (
                      <Button
                        type="longBtn"
                        onClick={() =>
                          copyRequest(requestData.RequestSystemInfo.RequestId)
                        }
                      >
                        Kurti naują paraišką esamos pagrindu
                      </Button>
                    )}

                  {requestData.RequestSystemInfo != null && (
                    <Button
                      type="longBtn"
                      onClick={() =>
                        getFinancialCriteriaResults(
                          requestData.RequestSystemInfo.RequestId
                        )
                      }
                    >
                      Peržiūrėti atitikimo kriterijus
                    </Button>
                  )}

                  {requestData.RequestSystemInfo != null &&
                    (requestData.RequestSystemInfo.Status === 7 ||
                      requestData.RequestSystemInfo.Status === 14) && (
                      <div style={{ width: '14rem' }}>
                        <Button type="longBtn" onClick={() => sign()}>
                          Pasirašyti
                        </Button>
                      </div>
                    )}

                  {requestData.RequestSystemInfo != null &&
                    (requestData.RequestSystemInfo.Status === 9 ||
                      requestData.RequestSystemInfo.Status === 16) && (
                      <div style={{ width: '14rem' }}>
                        <Button
                          type="longBtn"
                          onClick={() => changeConditions()}
                        >
                          Pildyti prašymą dėl sutarties sąlygų keitimo
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SignContractModal
        open={signContractModalVisible}
        requestId={requestId}
      />
      <ChangeConditionsModal
        open={changeConditionsModalVisible}
        requestId={requestId}
      />

      <FinancialCriteriaModal
        open={financialCriteriaModalVisible}
        data={financialCriteriaResults}
        sectorType={requestData.LoanInfo && requestData.LoanInfo.SectorType}
        finYear={companyQuestionList?.FsiYear}
      />

      <ConfirmWithFinancialCriteriaModal
        open={confirmWithFinancialCriteriaModalVisible}
        loading={loading}
        finYear={companyQuestionList?.FsiYear}
      />

      <ConfirmWithoutFinancialCriteriaModal
        open={confirmWithoutFinancialCriteriaModalVisible}
        loading={loading}
      />

      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle={modalOkTitle}
        // modalTitle={modalTitle}
        checkbox={modalCheckbox}
        userComment={userComment}
        mainText={mainText}
      />

      <GoBackConfirmationModal
        open={goBackConfirmationModalVisible}
        loading={loading}
      />
      <ConfirmWithoutFinancialCriteriaForChangeConditionsModal
        open={confirmWithoutFinancialCriteriaForChangeConditionsModalVisible}
        loading={loading}
      />
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  requestData: state.dashboardState.requestData,
  loading: state.dashboardState.isOnSync,
  canSeeBills: state.dashboardState.savedRequestDesissionAccepted,
  financialCriteriaResults: state.dashboardState.financialCriteriaResults,
  signContractModalVisible: state.modalsState.signContractModalVisible,
  changeConditionsModalVisible: state.modalsState.changeConditionsModalVisible,
  financialCriteriaModalVisible:
    state.modalsState.financialCriteriaModalVisible,
  signInInfo: state.dashboardState.signInInfo,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
  confirmWithFinancialCriteriaModalVisible:
    state.modalsState.confirmWithFinancialCriteriaModalVisible,
  confirmWithoutFinancialCriteriaModalVisible:
    state.modalsState.confirmWithoutFinancialCriteriaModalVisible,
  goBackConfirmationModalVisible:
    state.modalsState.goBackConfirmationModalVisible,
  confirmWithoutFinancialCriteriaForChangeConditionsModalVisible:
    state.modalsState
      .confirmWithoutFinancialCriteriaForChangeConditionsModalVisible,
});

export default connect(mapStateToProps)(NewRequest);
