import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../state';
import styles from './loginBackOfficeModal.module.scss';
import { WithSpinner } from './../../components';
import {
  validateEmail,
  validatePassword,
} from './../../utils/auth/validations';
// import { config } from '../../api/config';
import { isProd } from '../../api/config';
// import { Api } from './../../api/api';

const LoginModal = ({ dispatch, open, loginErr, loading }) => {
  const [values, setValues] = useState({ username: '', password: '' }); //email

  const [notValid, setNotvalid] = useState(false);
  const [insideLoginErr, setInsideLoginError] = useState(false);

  useEffect(() => {
    setInsideLoginError(loginErr);
  }, [loginErr]);

  const handleLoginInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    dispatch(actions.auth.loginUserAction(values));
  };

  const goToMainWindow = () => {
    window.location.href = isProd()
      ? 'https://paslaugos.invega.lt/backofficelogin'
      : 'https://barzda.scorify.lt/backofficelogin';
  };

  const onEnterClick = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '550px' }}
        >
          <WithSpinner loading={loading}>
            <div className={styles.loginInputsContainer}>
              <h4>Prisijungimas Invega darbuotojams</h4>
              {/*{insideLoginErr && (
                <p className={styles.badLoginMessage}>
                  Neteisingas prisijungimo vardas ar/ir slaptažodis
                </p>
              )}
              <div className={styles.inputBlock}>
                <input
                  placeholder="El. pašto adresas"
                  name="username"
                  className="m-input"
                  onChange={handleLoginInputChange}
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className={styles.inputBlock}>
                <input
                  placeholder="Slaptažodis"
                  name="password"
                  type="password"
                  className="m-input"
                  onChange={handleLoginInputChange}
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className={styles.spanText}>
                <span>
                  *Pamiršus prisijungimo duomenis kreipkitės į administratorių
                </span>
              </div>*/}
              <div className="btnBlock" style={{ marginTop: '20px' }}>
                <button
                  type="buttom"
                  className="btn-dark-blue-standart"
                  onClick={(e) => goToMainWindow()}
                >
                  Prisijungti
                </button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loggedIn: state.auth.loggedIn,
  loginErr: state.auth.loginErr,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(LoginModal);
