import React, { useEffect, useState } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';

import {
  WithSpinner,
  InputWithTextInline,
  Button,
  Input,
  RowTableAdmin,
  RowTableRow,
  RowTableCell,
  RequestMultiSelect,
} from '../../components';

import {
  FileUploadContainer,
  FileUploadContainerOld,
  AdditionalFileUploadContainer,
  AdditionalFileUploadContainerOld,
  FinancialTable,
} from '../../containers';
import {
  requestDataLabelTranslation,
  requestDataLabelTranslation2,
  companySize,
  rentContractTranslation,
  applicantTranslation,
  requestStatus,
  questionListTranslations,
} from './../../utils/translation/translation';
import styles from './newRequest.module.scss';
import { Link } from 'react-router-dom';
import { config } from '../../api/config';
import { Api } from './../../api/api';
import { format, addHours, subYears } from 'date-fns';
import {
  validateTextinput,
  validatePhone,
  validateEmail,
  isLoadSumValid,
  isIntegerNumber,
  LoanTermValidation,
  LoanDelayTermValidation,
  controlingForeignCompaiesInputValidation,
} from './../../utils/auth/validations';
import { formatNumber } from './../../utils/helpers/helpers';
import SignContractModal from './../../modals/signContractModal/signContractModal';
import FinancialCriteriaModal from './../../modals/financialCriteriaModal/financialCriteriaModal';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import ConfirmWithFinancialCriteriaModal from '../../modals/confirmWithFinancialCriteriaModal/confirmWithFinancialCriteriaModal';
import ChangeConditionsModal from './../../modals/changeConditionsModal/changeConditionsModal';
import ConfirmWithoutFinancialCriteriaModal from '../../modals/confirmWithoutFinancialCriteriaModal/confirmWithoutFinancialCriteriaModal';
import GoBackConfirmationModal from '../../modals/goBackConfirmationModal/goBackConfirmationModal';
import ConfirmWithoutFinancialCriteriaForChangeConditionsModal from '../../modals/confirmWithoutFinancialCriteriaForChangeConditionsModal/confirmWithoutFinancialCriteriaForChangeConditionsModal';
import GlobalMessageModal from './../../modals/globalMessageModal/globalMessageModal';
import DatePicker from 'react-datepicker';
import { getLocalStorageItemIfNotExpired } from '../../utils';
import {
  controllingCompaniesInputValidation,
  isCommentFilled,
} from './../../utils/auth/validations';
import swal from 'sweetalert';
import {
  evrkFull,
  formatCountryJson,
  evrkFullWithoutZeros,
  evrkFullWithZeros,
  fullEvrkCodeList,
  evrkFarm,
} from './../../utils/helpers/evrk';
import { SurwayAnswers } from '../requests/surwayAnswers';
import { AddressModal } from '../../containers/addressInputContainer/addressInputContainer';
import { SelfDeclarationInfo } from '../../utils/mapsData/selfDeclaration/selfDeclaration';

const NewRequest = ({
  requestData,
  dispatch,
  loading,
  signContractModalVisible,
  financialCriteriaModalVisible,
  financialCriteriaResults,
  confirmWithFinancialCriteriaModalVisible,
  confirmWithoutFinancialCriteriaModalVisible,
  changeConditionsModalVisible,
  signInInfo,
  confirmModalVisible,
  goBackConfirmationModalVisible,
  confirmWithoutFinancialCriteriaForChangeConditionsModalVisible,
  globalMessageModalVisible,
}) => {
  const loginType = getLocalStorageItemIfNotExpired('loginType');
  const CompanyEvrkcheck = () => (loginType === 'Company' ? 'CompanyEvrk' : '');

  const CompanyManagerNamecheck = () =>
    loginType === 'Company' ? 'CompanyManagerName' : '';

  const disableCheck = (val) => {
    if (viewWindow) {
      return true;
    } else {
      if (
        val === 'ApplicantName' ||
        val === 'ApplicantSurname' ||
        val === 'PersonalCode' ||
        val === 'FirstName' ||
        val === 'LastName' ||
        val === 'CompanyName' ||
        val === 'CompanyCode' ||
        val === CompanyManagerNamecheck() ||
        val === 'CompanyType' ||
        val === 'CompanySizeType' ||
        val === 'CompanyRegistrationDate' ||
        val === 'StatusInitial' ||
        val === 'Turnover19' ||
        val === 'Income19' ||
        val === 'Assets19' ||
        val === 'PropertyAmount19' ||
        val === 'Liabilities19' ||
        val === 'ShortTermDebts19' ||
        val === 'Status' ||
        val === 'FsiStartDate2019' ||
        val === 'FsiEndDate2019' ||
        val === 'FsiStartDate2020' ||
        val === 'FsiEndDate2020' ||
        val === 'Assets20' ||
        val === 'Liabilities20' ||
        val === 'PropertyAmount20' ||
        val === 'Turnover20' ||
        val === 'InvegaAligTurnSumLoan' ||
        val === 'InvegaAligInvSumLoan' ||
        val === CompanyEvrkcheck()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isNumberType = (val) => {
    if (
      val === 'MonthlyRentalPrice' ||
      val === 'RentedArea' ||
      val === 'OwnerCompanyCode' ||
      val === 'AlreadyReceivedCovidSupportSum' ||
      val === 'OwnerPersonalCode' ||
      val === 'OwnerPhone'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [openedMessageText, setOpenedMessagetext] = useState('');
  
  const openMessageModal = (message) => {
    setOpenedMessagetext(message);
    const open = {
      modalName: 'globalMessageModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
    return message;
  };

  const rowTableHeader = [
    'Tipas',
    'Priedo pavadinimas',
    'Sudarymo data',
    'Pareiškėjo pasirašymo data',
    'Invega pasirašymo data',
    'Veiksmai',
  ];

  const commentsHistoryTableHeader = ['Data', 'Komentaras'];

  const check1Confirment = () => (
    <div>
      <p className="agreementTextp">
        Paskolos gavėjas patvirtina ir garantuoja, kad:
      </p>
      <p className="agreementTextp">
        1. Yra susipažinęs (-usi) su finansinės priemonės „Startuok“,
        finansuojamos Europos regioninės plėtros fondo lėšomis (toliau –
        priemonė), aprašyme (toliau – Aprašymas) nustatyta tvarka ir
        reikalavimais;
      </p>

      <p className="agreementTextp">
        2. Man žinoma, kad projektas, kuriam finansuoti teikiama ši paraiška,
        bus finansuojamas iš Verslo finansavimo fondo, finansuojamo iš Europos
        regioninės plėtros fondo, lėšų;
      </p>
      <p className="agreementTextp">
        3. Įsipareigoju per paskolos teikėjo nustatytą terminą pateikti jam
        reikiamą informaciją ir (arba) atlikti Lietuvos Respublikos ir ES
        atsakingų institucijų nurodytus veiksmus, vykdomus pagal Aprašymą ir
        pagal Lietuvos Respublikos ir ES teisės aktus, reglamentuojančius
        veiksmų programos įgyvendinimą;
      </p>
      <p className="agreementTextp">
        4. Paraiškos pateikimo metu veikia Lietuvos Respublikoje arba kitoje ES
        šalyje narėje, jei vykdant Paskolos sutartį sukurta nauda atitenka
        Lietuvos Respublikai, kaip nurodyta Finansinių priemonių išlaidų
        tinkamumo finansuoti rekomendacijose, kaip nurodyta Finansinių priemonių
        išlaidų tinkamumo finansuoti rekomendacijose, patvirtintose Veiksmų
        programos valdymo komiteto 2016 m. birželio 29 d. protokolo sprendimu
        Nr. 38 su vėlesniais pakeitimais (toliau – Finansinių priemonių išlaidų
        tinkamumo finansuoti rekomendacijos), kurios yra skelbiamos interneto
        svetainėje adresu
        <a
          href="http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3
        </a>
        , tačiau toks finansavimas negali viršyti 15 procentų šios schemos 2
        punkte nurodytos Priemonei skirtos bendros lėšų sumos, kaip tai nurodyta
        2013 m. gruodžio 17 d. Europos Parlamento ir Tarybos reglamento (ES) Nr.
        1303/2013, kuriuo nustatomos Europos regioninės plėtros fondui, Europos
        socialiniam fondui, Sanglaudos fondui, Europos žemės ūkio fondui kaimo
        plėtrai ir Europos jūros reikalų ir žuvininkystės fondui bendros
        nuostatos ir Europos regioninės plėtros fondui, Europos socialiniam
        fondui, Sanglaudos fondui ir Europos jūros reikalų ir žuvininkystės
        fondui taikytinos bendrosios nuostatos ir panaikinamas Tarybos
        reglamentas (EB) Nr. 1083/2006, su paskutiniais pakeitimais, padarytais
        2020 m. gruodžio 23 d. Europos Parlamento ir Tarybos reglamentu (ES) Nr.
        2020/2221, 70 straipsnio 2 dalies b punkte;
      </p>
      <p className="agreementTextp">
        5. Įmonė ir (ar) įmonių grupė, kaip ši sąvoka apibrėžta Lietuvos
        Respublikos įmonių grupių konsoliduotosios finansinės atskaitomybės
        įstatyme, kuriai priklauso paskolos gavėjas, paskutinę praėjusių
        kalendorinių metų dieną ir paraiškos INVEGAI pateikimo metu nebuvo
        laikomi sunkumų patiriančiais, kaip tai apibrėžta:
        <br />
        - Europos Komisijos Gairėse dėl valstybės pagalbos sunkumų patiriančioms
        ne finansų įmonėms sanuoti ir restruktūrizuoti (2014/C 249/01) (taikoma
        visiems);
        <br />
        - Bendrosios išimties reglamento 2 straipsnio 18 punkte (taikoma tik
        labai mažoms, mažoms įmonėms ar verslininkams, vykdantiems socialinio
        poveikio projektą);
        <br />- De minimis reglamento 4 straipsnio 3 dalies a punkte (taikoma
        tik vidutinėms įmonėms, vykdančioms socialinio poveikio projektą);
      </p>
      <p className="agreementTextp">
        6. Paraiškos pateikimo metu atitinka minimalius patikimų mokesčių
        mokėtojų kriterijus, nustatytus Lietuvos Respublikos mokesčių
        administravimo įstatymo 401 straipsnyje. Atitiktis šiam reikalavimui
        vertinama pagal viešai paskelbtą Valstybinės mokesčių inspekcijos prie
        Lietuvos Respublikos finansų ministerijos informaciją, skelbiamą
        interneto svetainėje adresu
        <a
          href="https://www.vmi.lt/evmi/mokesciu-moketoju-informacija"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          https://www.vmi.lt/evmi/mokesciu-moketoju-informacija
        </a>
        ;
      </p>
      <p className="agreementTextp">
        7. Paraiškos pateikimo metu, kai ją teikia juridinis asmuo, jam nėra
        iškelta nemokumo byla ir (ar) jis nėra restruktūrizuojamas pagal
        Lietuvos Respublikos juridinių asmenų nemokumo įstatymą arba iki jo
        įsigaliojimo galiojusius Lietuvos Respublikos įmonių bankroto įstatymą
        ir Lietuvos Respublikos restruktūrizavimo įstatymą arba, kai paraišką
        teikia verslininkas, kaip jis apibrėžtas SVV įstatymo 2 straipsnio 21
        dalyje, – jam nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
        Respublikos fizinių asmenų bankroto įstatymą. Atitikimas reikalavimui
        vertinamas pagal viešai prieinamus Juridinių asmenų registro ir Audito,
        apskaitos, turto vertinimo ir nemokumo valdymo tarnybos prie Lietuvos
        Respublikos finansų ministerijos duomenis;
      </p>
      <p className="agreementTextp">
        8. Neveikia žemės ūkio sektoriuje, kaip tai apibrėžta 2014 m. birželio
        25 d. Komisijos reglamento (ES) Nr. 702/2014, kuriuo skelbiama, kad tam
        tikrų kategorijų pagalba žemės bei miškų ūkio sektoriuose ir kaimo
        vietovėse yra suderinama su vidaus rinka pagal Sutarties dėl Europos
        Sąjungos veikimo 107 ir 108 straipsnius, su visais pakeitimais 2
        straipsnio 3 dalyje, išskyrus atvejus, kai šis Paskolų gavėjas vykdo
        arba ketina vykdyti inovacinę veiklą, nevykdo žuvininkystės ir
        akvakultūros veiklos, kaip tai apibrėžta Lietuvos Respublikos
        žuvininkystės įstatyme, ar jo vykdoma pagrindinė veikla pagal
        Statistikos departamento prie Lietuvos Respublikos Vyriausybės
        generalinio direktoriaus įsakymu patvirtintą Ekonominės veiklos rūšių
        klasifikatorių (EVRK 2 red.)(toliau – EVRK 2 red.) nėra finansinė ir
        draudimo veikla (visi veiklos kodai pagal EVRK 2 red. 64–66 skyrius),
        išskyrus atvejus, kai šis Paskolų gavėjas vysto finansines
        technologijas;
      </p>
      <p className="agreementTextp">
        9. Paskolos gavėjui nėra išduotas vykdomasis raštas sumoms išieškoti
        pagal ankstesnį Europos Komisijos sprendimą, kuriame Lietuvos valstybės
        suteikta pagalba skelbiama neteisėta ir nesuderinama su vidaus rinka,
        arba Paskolos gavėjas yra grąžinęs visą neteisėtos pagalbos sumą,
        įskaitant palūkanas, kaip nustatyta 2015 m. liepos 13 d. Tarybos
        reglamente (ES) 2015/1589, nustatančiame išsamias Sutarties dėl Europos
        Sąjungos veikimo 108 straipsnio taikymo taisykles;
      </p>
      <p className="agreementTextp">
        10. Esant poreikiui ir (ar) kilus įtarimui, bet kuriuo paraiškos
        vertinimo metu ir (ar) po paskolos sutarties pasirašymo ir paskolos
        išmokėjimo, INVEGAI pareikalavus, Paskolos gavėjas pateiks dokumentus,
        patvirtinančius, kad Paskolos gavėjui ir (ar) Paskolos gavėjo
        akcininkui, turinčiam ne mažiau nei 25 proc. Verslo subjekto akcijų,
        Paskolos gavėjo vadovui, kitam valdymo ar priežiūros organo nariui ar
        kitam asmeniui, turinčiam (turintiems) teisę atstovauti Paskolos gavėją
        ar jį kontroliuoti, jo vardu priimti sprendimą, sudaryti sandorį, ar
        buhalteriui (buhalteriams) ar kitam (kitiems) asmeniui (asmenims),
        turinčiam (turintiems) teisę surašyti ir pasirašyti Paskolos gavėjo
        apskaitos dokumentus, per pastaruosius 5 metus nebuvo priimtas ir
        įsiteisėjęs apkaltinamasis teismo nuosprendis ir šis asmuo neturi
        neišnykusio ar nepanaikinto teistumo už nusikalstamos ekonominės veiklos
        pažeidimus;
      </p>
      <p className="agreementTextp">
        11. Man žinoma, kad projektas ir projekto veiklos negali būti finansuoti
        ar finansuojami bei suteikus finansavimą teikiami finansuoti iš kitų
        programų, finansuojamų valstybės biudžeto lėšomis, kitų fondų ar
        finansinių mechanizmų (Europos ekonominės erdvės, Norvegijos,
        Šveicarijos Konfederacijos ir pan.) ir kitų veiksmų programų priemonių,
        jei dėl to projekto ar jo dalies tinkamos finansuoti išlaidos gali būti
        finansuotos kelis kartus;{' '}
      </p>
      <p className="agreementTextp">
        12. Man žinoma, kad pareiškėjas, kuris yra fizinis asmuo, projekto
        įgyvendinimo ir kompensacijos mokėjimo laikotarpiu privalo vykdyti
        veiklą pagal individualios veiklos pažymą arba turėti išduotą galiojantį
        verslo liudijimą, patvirtinantį, kad ūkinė ir (arba) ekonominė veikla
        vykdoma;{' '}
      </p>
      <p className="agreementTextp">
        13. Yra informuotas (-a), kad paraiška gali būti atmesta, jeigu Aprašyme
        nustatyta tvarka ir terminais nebus pateikti prašomi dokumentai ir (ar)
        informacija.
      </p>
    </div>
  );

  const check1ConfirmentNew = () => (
    <div>
      <p className="agreementTextp">
      Verslo subjektas, pateikęs paraišką patvirtina ir garantuoja, kad:
      </p>
      <p className="agreementTextp">
      1. Yra susipažinęs (-usi) su finansinės priemonės „Startuok“, finansuojamos Europos regioninės plėtros fondo lėšomis (toliau – priemonė), aprašyme (toliau – Aprašymas) ir Lietuvos Respublikos ekonomikos ir inovacijų ministro 2021 m. rugsėjo 2 d. įsakymu Nr. 4-970 „Dėl finansinės priemonės „Startuok, finansuojamos Europos regioninės plėtros fondo lėšomis, schemos patvirtinimo“ patvirtintoje schemoje, su vėlesniais pakeitimais nustatytais reikalavimais;
      </p>

      <p className="agreementTextp">
      2. Projektas, kuriam finansuoti teikiama ši paraiška, bus finansuojamas iš Verslo finansavimo fondo Europos regioninės plėtros fondo ir grįžusių ir (ar) grįšiančių lėšų arba iš Inovacijų skatinimo fondo, finansuojamo iš Europos regioninės plėtros fondo, ir nacionalinio bendrojo finansavimo, lėšų;
      </p>
      <p className="agreementTextp">
      3. Įsipareigoja per paskolos davėjo nustatytą terminą pateikti jam reikiamą informaciją ir (arba) atlikti Lietuvos Respublikos ir ES atsakingų institucijų nurodytus veiksmus, vykdomus pagal Aprašymą ir pagal Lietuvos Respublikos ir ES teisės aktus, reglamentuojančius veiksmų programos įgyvendinimą;
      </p>
      <p className="agreementTextp">
      4. Paraiškos pateikimo metu veikia Lietuvos Respublikoje. Verslo subjektas laikomas veikiančiu Lietuvos Respublikoje tuo atveju, jei jis kuria darbo vietas Lietuvos Respublikoje ir (arba) nuo savo vykdomos veiklos moka mokesčius arba Valstybinio socialinio draudimo fondo valdybos prie Socialinės apsaugos ir darbo ministerijos įmokas į Lietuvos Respublikos biudžetą; 
      </p>
      <p className="agreementTextp">
      5. Verslo subjektas ir Įmonių grupė (jei Verslo subjektas priklauso Įmonių grupei) nėra sunkumų patiriantys, kaip nustatyta Bendrosios išimties reglamento 2 straipsnio 18 punkte;
      </p>
      <p className="agreementTextp">
        6. Paraiškos pateikimo metu atitinka minimalius patikimų mokesčių mokėtojų kriterijus, nustatytus Lietuvos Respublikos mokesčių administravimo įstatymo 40<sup>1</sup> straipsnyje. 
      </p>
      <p className="agreementTextp">
        7. Paraiškos pateikimo metu, kai ją teikia juridinis asmuo, nėra iškelta bankroto ir (ar) restruktūrizavimo byla pagal Lietuvos Respublikos juridinių asmenų nemokumo įstatymą, jis nėra likviduojamas. Jei pareiškėjas yra verslininkas, kaip apibrėžta SVV įstatyme, jam nėra iškelta fizinio asmens bankroto byla pagal Lietuvos Respublikos fizinių asmenų bankroto įstatymą;
      </p>
      <p className="agreementTextp">
        8. Neveikia žemės ūkio sektoriuje, kaip tai apibrėžta 2014 m. birželio 25 d. Komisijos reglamento (ES) Nr. 702/2014, kuriuo skelbiama, kad tam tikrų kategorijų pagalba žemės bei miškų ūkio sektoriuose ir kaimo vietovėse yra suderinama su vidaus rinka pagal Sutarties dėl Europos Sąjungos veikimo 107 ir 108 straipsnius, su visais pakeitimais 2 straipsnio 3 dalyje, išskyrus atvejus, kai šis Paskolų gavėjas vykdo arba ketina vykdyti inovacinę veiklą, nevykdo žuvininkystės ir akvakultūros veiklos, kaip tai apibrėžta Lietuvos Respublikos žuvininkystės įstatyme, ar jo vykdoma veikla nėra finansinė ir draudimo veikla, pagal Valstybės duomenų agentūros generalinio direktoriaus įsakymu tvirtinamą Ekonominės veiklos rūšių klasifikatorių (EVRK 2 red.; toliau – EVRK 2 red.; visi veiklos kodai pagal EVRK 2 red. 64–66 skyrius), išskyrus atvejus, kai šis Verslo subjektas vysto Finansines technologijas;
      </p>
      <p className="agreementTextp">
        9. Nėra gavęs valstybės pagalbos, kuri INVEGOS sprendimu buvo pripažinta nepagrįstai išmokėta ir (arba), kaip nurodyta Lietuvos Respublikos konkurencijos įstatymo 55 straipsnio 2 dalyje  Europos Komisijos buvo pripažinta  nesuderinama su Europos Sąjungos vidaus rinka (toliau – nesuderinama pagalba)  ir (arba) pagalbos teikėjo, kaip jis apibrėžtas Konkurencijos įstatyme, sprendimu pripažinta neteisėta pagalba (toliau – neteisėta pagalba), arba yra grąžinęs visą jos sumą, įskaitant palūkanas, kaip nustatyta 2015 m. liepos 13 d. Tarybos reglamente (ES) 2015/1589, nustatančiame išsamias Sutarties dėl Europos Sąjungos veikimo 108 straipsnio taikymo taisykles;
      </p>
      <p className="agreementTextp">
        10. Verslo subjektui, jo vadovui, atstovui, Verslo subjekto nuosavybės ir valdymo struktūroje esantiems asmenims, naudos gavėjui, kaip jis apibrėžtas Lietuvos Respublikos pinigų plovimo ir teroristų finansavimo prevencijos įstatymo 2 straipsnio 14 dalyje, arba fiziniams ir juridiniams asmenims, kurių naudai bus naudojama paskola, sandorio ir (arba) mokėjimo bei tiekimo grandinėje dalyvaujantiems subjektams nėra taikomos tarptautinės sankcijos ir (ar) ribojamosios priemonės, kaip šios sąvokos apibrėžtos Lietuvos Respublikos tarptautinių sankcijų įstatyme, taip pat sankcijos, kurias nustato, taiko ar administruoja Jungtinių Amerikos Valstijų Vyriausybė (įskaitant Jungtinių Amerikos Valstijų Iždo departamento Užsienio lėšų kontrolės biurą (angl. The Office of Foreign Assets Control of the U.S. Department of the Treasury), Jungtinė Didžiosios Britanijos ir Šiaurės Airijos Karalystė;
      </p>
      <p className="agreementTextp">
        11. Verslo subjektas neturi arba yra nutraukęs prekybinius įsipareigojimus su  Lietuvos Respublikai priešiškų šalių (Rusijos Federacija, Baltarusijos Respublika, Rusijos Federacijos aneksuotas Krymas, Moldovos Respublikos vyriausybės nekontroliuojama Padniestrės teritorija bei Sakartvelo vyriausybės nekontroliuojamos Abchazijos ir Pietų Osetijos teritorijos) fiziniais ir (ar) juridiniais asmenimis ne vėliau kaip iki 2022 m. rugpjūčio 31 d;
      </p>
      <p className="agreementTextp">
        12. Man žinoma, kad kai Verslo subjektas , yra fizinis asmuo, dirbantis pagal verslo liudijimą arba Individualios veiklos pažymą, viso Projekto įgyvendinimo metu privalo turėti galiojantį verslo liudijimą arba individualios veiklos pažymą;
      </p>
      <p className="agreementTextp">
        13. Projektas nepažeis Chartijos pagrindinių teisių: orumo; asmenų, privataus ir šeimos gyvenimo, sąžinės ir saviraiškos laisvės; asmens duomenų; prieglobsčio ir apsaugos perkėlimo, išsiuntimo ar išdavimo atvejų; teisių į nuosavybę ir teisių užsiimti verslu; lyčių lygybės, vienodo požiūrio ir lygių galimybių, nediskriminavimo ir asmenų su negalia teisių; vaiko teisių; gerojo administravimo, veiksmingo teisinės gynybos, teisingumo; solidarumo ir darbuotojų teisių; aplinkos apsaugos;
      </p>
      <p className="agreementTextp">
      14. Projektas negali daryti neigiamo poveikio Horizontaliesiems Principams: darnaus vystymosi, lygių galimybių ir nediskriminavimo (dėl lyties, rasės, tautybės, pilietybės, kalbos, kilmės, socialinės padėties, tikėjimo, religijos ar įsitikinimų pažiūrų, amžiaus, lytinės orientacijos, etninės priklausomybės, negalios ar kt.);
       </p>
      <p className="agreementTextp">
        15. Per paskutinius 5 metus galutiniu teismo sprendimu arba galutiniu administraciniu sprendimu Paskolos gavėjas nėra pripažintas kaltu ir neturi neišnykusio ar nepanaikinto teistumo dėl pareigų, susijusių su mokesčių ar socialinio draudimo įmokų mokėjimu, neatlikimo;
        </p>
      <p className="agreementTextp">
      16. Paskolos gavėjas nepalaiko verslo santykių su juridiniais asmenimis, registruotais teritorijose, kurios pagal savo jurisdikciją nebendradarbiauja su ES tarptautiniu mastu suderintų mokesčių taikymo srityje, taip pat nevykdo arba neketina vykdyti sandorių su tikslinėse teritorijose registruotais juridiniais asmenimis (Tikslinių teritorijų sąrašas patvirtintas Lietuvos Respublikos finansų ministro 2001 m. gruodžio 22 d. įsakymu Nr. 344 „Dėl Tikslinių teritorijų sąrašo patvirtinimo“);
      </p>
      <p className="agreementTextp">
      17. Yra informuotas (-a), kad paraiška gali būti atmesta, jeigu Aprašyme nustatyta tvarka ir terminais nebus pateikti prašomi dokumentai ir (ar) informacija.
      </p>
    </div>
  );

  const isSmallText = () => (
    <span>
      Bendrovė ir visos su ja susijusios įmonės atitinka labai mažos arba mažos
      įmonės statusą pagal{' '}
      <a
        href="https://www.e-tar.lt/portal/lt/legalActEditions/TAR.640D50DB8877?faces-redirect=true"
        target="_blank"
        rel="noreferrer noopener"
      >
        Lietuvos Respublikos smulkaus ir vidutinio verslo plėtros įstatymo{' '}
      </a>
      nuostatas. Pagrindiniai kriterijai: įmonėje (atsižvelgiant ir į susijusias
      ir partnerines įmones) dirba mažiau kaip 50 darbuotojų ir įmonė atitinka
      bent vieną iš šių kriterijų: metinės pajamos neviršija 10 mln. Eur arba
      įmonės balanse nurodyto turto vertė neviršija 10 mln. Eur
    </span>
  );

  const returnSignedDocumentEndpoint = (
    requestId,
    fileType,
    fileId,
    applicantSigningDate,
    invegaSigningDate
  ) => {
    if (fileType === 'Decision') {
      return config.API_ENDPOINTS.GetSignedDocumentDecision(requestId,fileId);
    }
    if (fileType === 'RejectDecision') {
      return config.API_ENDPOINTS.GetSignedDocumentRejectDecision(requestId);
    }
    if (
      fileType === 'ChangeConditions' ||
      fileType === 'ChangeConditionsRequest'
    ) {
      if (!applicantSigningDate || !invegaSigningDate) {
        return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
      }
      return config.API_ENDPOINTS.GetSignedDocumentChangeConditions(requestId);
    }

    return config.API_ENDPOINTS.GetSignedDocumentContract(requestId);
  };

  const GetSignedDocumentContractUser = (
    requestId,
    fileType,
    name,
    fileId,
    applicantSigningDate,
    invegaSigningDate
  ) => {
    fetch(
      Api.API_BASE_URL +
        returnSignedDocumentEndpoint(
          requestId,
          fileType,
          fileId,
          applicantSigningDate,
          invegaSigningDate
        ),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
          'Content-Type': 'application/pdf',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Sutartis_Nr_${requestId}.pdf`;
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const [viewWindow, setViewWindow] = useState(false);

  const [companyQuestionList, setCompanyQuestionList] = useState(null);
  const [ceoDataList, setCeoDataList] = useState(null);
  const [companyDataList, setCompanyDataList] = useState(null);
  const [applicantData, setApplicantData] = useState(null);
  const [farmData, setFarmData] = useState(null);
  const [managerData, setManagerData] = useState(null);
  const [otherRequestData, setOtherRequestData] = useState(null);

  const [pepData, setPepData] = useState({});
  
  const [suppliers, setSuppliers] = useState(null);
  const [buyers, setBuyers] = useState(null);
  const [declarationInfo, setDeclarationInfo] = useState(null);
  const [loanInfo, setLoanInfo] = useState(null);
  const [checks, setChecks] = useState(null);
  const [checksNew, setChecksNew] = useState(null);

  const [evrksList, setevrksList] = useState([]);

  const [hasParentCompanyValue, setHasParentCompanyValue] = useState(false);
  const [hasParentCompanyValueFA, setHasParentCompanyValueFA] = useState(false);

  const [hasParentCompanyState, setHasParentCompanyState] = useState(false);

  const [turnoversInfo, setTurnoversInfo] = useState([]);

  const onPepDataInputChange = ({ target: { name, value } }) => {
    setPepData({ ...pepData, [name]: value });
  };

  const [controllingCompanies, setControllingCompanies] = useState(null);
  const [controllingForeignCompanies, setControllingForeignCompanies] =
    useState(null);

  const [notValid, setNotvalid] = useState(false);

  const onInputChange = ({ target: { name, value } }) => {
    setCeoDataList({ ...ceoDataList, [name]: value });
  };

  const onVatDeclarationChange = ({ target: { name, value } }) => {
    setCompanyDataList({ ...companyDataList, [name]: value });
  };

  const onFAVatDeclarationChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };
  
  const title = (id) => {
    switch (id) {
      case '1':
        return 'Kelionių organizatorius';
      case '2':
        return 'Apgyvendinimo paslaugos';
      case '3':
        return 'Viešojo maitinimo paslaugos';
      default:
        return id;
    }
  };

  const canCreateRequestSectorTypeId = (id) => {
    const OtherRequests = requestData.OtherRequests;
    const filtered = OtherRequests.some((val) => val.SectorTypeId == id);

    const has2Or3 = OtherRequests.some(
      (val) => val.SectorTypeId == 2 || val.SectorTypeId == 3
    );

    if (OtherRequests && OtherRequests.length > 0 && filtered) {
      swal({
        title: `Paraišką su veikla "${title(
          id
        )}" jau rengiate, todėl su šia veiklos sritimi naujos paraiškos rengti nebegalite. Pasirinkite kitą veiklos sritį.`,
        icon: 'info',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } else if ((id == 2 || id == 3) && has2Or3) {
      swal({
        title:
          'Paraišką su veikla "Apgyvendinimo paslaugos" arba "Viešojo maitinimo paslaugos" jau rengiate, todėl su šia veiklos sritimi naujos paraiškos rengti nebegalite. Pasirinkite kitą veiklos sritį.',
        icon: 'info',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  };
  const onLoanInfoChange = ({ target: { name, value } }) => {
    if (name === 'SectorType') {
      canCreateRequestSectorTypeId(value);
      if (value == null || value.length === 0) {
        setLoanInfo({ ...loanInfo, [name]: null });
      } else {
        setLoanInfo({ ...loanInfo, [name]: value });
      }
    } else {
      if (value == null || value.length === 0) {
        setLoanInfo({ ...loanInfo, [name]: null });
      } else {
        setLoanInfo({ ...loanInfo, [name]: value });
      }
    }
  };

  const onCompanyInputChange = ({ target: { name, value } }) => {
    setCompanyDataList({ ...companyDataList, [name]: value });
  };

  const onApplicantDatanputChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };

  const onFarmDataInputChange = ({ target: { name, value } }) => {
    setFarmData({ ...farmData, [name]: value });
  };
  const onManagerDataInputChange = ({ target: { name, value } }) => {
    setManagerData({ ...managerData, [name]: value });
  };

  const onOtherRequestDataInputChange = ({ target: { name, value } }) => {
    setOtherRequestData({ ...otherRequestData, [name]: value });
  };

  const onCheckBoxChange = (e, name) => {
    setChecks({ ...checks, [name]: e.target.checked });
  };

  const onCheckBoxNewChange = (e, name) => {
    setChecksNew({ ...checksNew, [name]: e.target.checked });
  };


  const scrollToError = () => {
    setTimeout(() => {
      let el = document.getElementsByClassName('error1');

      let e = null;
      for (let i = 0; i < el.length; i++) {
        if (el[i].innerText != '') {
          e = el[i];
          break;
        }
      }

      if (e) {
        const y = e.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 0);
  };

  const checkResponsibilityPersons = (data) => {
    for (let i = 0; i < data.length; i++) {
      let input = data[i];
      if (
        input.name &&
        input.name.length > 0 &&
        input.surname &&
        input.surname.length > 0 &&
        input.birthDate &&
        input.birthDate.length > 0 &&
        input.position &&
        input.position.length > 0 &&
        input.country &&
        input.country.length > 0 &&
        input.personRelation &&
        input.personRelation.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const validateVatDeclarationPeriod = () => {
    if (companyQuestionList.IsVatPayer) {
      return companyDataList.VatDeclarationPeriod ? true : false;
    }
    return true;
  };

  const validateFAVatDeclarationPeriod = () => {
    if (companyQuestionList.IsVatPayer) {
      return applicantData.VatDeclarationPeriod ? true : false;
    }
    return true;
  };

  const onSelfDeclarationtCheckBoxChange = (e, name) => {
    setSelfDeclarationtData({
      ...selfDeclarationData,
      [name]: e.target.checked,
    });
  };

  const checkLoanDelayTermFormat = (val) => {
    if (!Number.isInteger(+val)) {
      return 'Turi būti įvestas sveikas skaičius';
    }
    if (
      +val > 0 &&
      +val <= 120 &&
      loanInfo.LoanTerm &&
      +val > +loanInfo.LoanTerm
    ) {
      return ' lauko [paskolos atidėjimo terminas (mėn)] reikšmė negali būti didesnė negu lauko [Pageidaujamas Paskolos terminas mėn].';
    }
    if (+val >= 1 && +val <= 120) {
      return null;
    }

    return 'Turi būti įvestas sveikas skaičius nuo 1 iki 120';
  };

  const checkSuppliers = (data) => {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let input = data[i];
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }
    }
  };

  const checkBuyers = (data) => {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let input = data[i];
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }
    }
  };

  const questionereEmpty = {
    IsSmall: null,
    IsSmallComment: null,
    IsNotListed: null,
    IsNotListedComment: null,
    IsProfitNotShared: null,
    IsProfitNotSharedComment: null,
    IsNotJoined: null,
    IsNotJoinedComment: null,
    IsNotTaken: null,
    IsNotTakenComment: null,
    IsNotProblemic: null,
    IsNotProblemicComment: null,
  };

  const returnEldership = (a, b) => {
    const arr = [11, 30, 27, 25, 21, 23];
    const isInArray = arr.some((val) => val == b);

    if (isInArray) {
      return null;
    }

    return a;
  };

  const returnSettlement = (a, b) => {
    const arr = [13, 18, 19, 29];
    const isInArray = arr.some((val) => val == b);

    if (isInArray) {
      return null;
    }

    return a;
  };

  const check = () => {
    if (loginType === 'Company') {
      if (validateEmail(ceoDataList.ApplicantEmail) === null) {
        const data = {
          requestSystemInfo: {
            ...requestSystemInfo,
            requestType: loginType,
          },
          FarmData: farmData, //???
          ManagerData: {
            ...managerData,
            DocumentDate:
              managerData.DocumentDate != null
                ? managerData.DocumentDate.length !== 10
                  ? addHours(new Date(managerData.DocumentDate), 3)
                  : managerData.DocumentDate
                : null,
            DocumentExpiredDate:
              managerData.DocumentExpiredDate != null
                ? managerData.DocumentExpiredDate.length !== 10
                  ? addHours(new Date(managerData.DocumentExpiredDate), 3)
                  : managerData.DocumentExpiredDate
                : null,
          },
          OtherRequestData: {
            ...otherRequestData,
            ControllingCompanies: controllingCompanies,
            ControllingForeignCompanies: controllingForeignCompanies,
          },
          ContactInfo: ceoDataList,
          CompanyData: {
            ...companyDataList,
            ActivityAddressEldership: returnEldership(
              companyDataList.ActivityAddressEldership,
              companyDataList.ActivityAddressMunicipalityId
            ),
            ActivityAddressEldershipId: returnEldership(
              companyDataList.ActivityAddressEldershipId,
              companyDataList.ActivityAddressMunicipalityId
            ),
            RegistrationAddressSettlement: returnSettlement(
              companyDataList.RegistrationAddressSettlement,
              companyDataList.RegistrationAddressMunicipalityId
            ),
            RegistrationAddressSettlementId: returnSettlement(
              companyDataList.RegistrationAddressSettlementId,
              companyDataList.RegistrationAddressMunicipalityId
            ),
          },
          DeclarationInfo: declarationInfo,
          LoanInfo: {...loanInfo,
            ProjectRegionId: requestData.RequestSystemInfo?.FormVersion !== 3 ? '2' : loanInfo.ProjectRegionId
          },
          //requestData.RequestSystemInfo?.FormVersion !== 3 ? '2' : loanInfo.ProjectRegionId
          FinancialData: companyQuestionList,
          SelfDeclaration: selfDeclarationData,
          suppliers: suppliers,
          buyers: buyers,
          //ActivityRegionId: activityRegionId,
          ActivityRegions: activityRegionIds,
          AMLPEPcomment: aMLPEPcomment,
          Checks: requestData?.RequestSystemInfo?.FormVersion == 3 ? checksNew : checks,
          Questionnaire: ShowQuestionList() ? questionnaire : questionereEmpty,
          pepData: pepData
            ? {
                parentCompanyCode: pepData.ParentCompanyCode,
                HasImportantResponsibilities:
                  pepData.HasImportantResponsibilities,
                HasControllingPersons: pepData.HasControllingPersons,
                HasControllingCompanies: pepData.HasControllingCompanies,
                HasImportantResponsibilitiesMoreThan4:
                  pepData.HasImportantResponsibilitiesMoreThan4,
                importantResponsibilityPersons:
                  pepData.ImportantResponsibilityPersons,
                controllingPersons: pepData.ControllingPersons,
                controllingCompanies: areAllValuesFalsy(
                  pepData.ControllingCompanies
                )
                  ? []
                  : pepData.ControllingCompanies,
              }
            : null,
        };
        dispatch(actions.dashboard.saveRquestAction(data, requestData?.RequestSystemInfo?.FormVersion));
        setSavedRequest(true);
      } else {
        scrollToError();
      }
    } else if (loginType === 'Individual') {
      if (true) {
        const data = {
          requestSystemInfo: {
            ...requestSystemInfo,
            requestType: loginType,
          },
          ApplicantData: {
            ...applicantData,
            ActivityAddressEldership: returnEldership(
              applicantData.ActivityAddressEldership,
              applicantData.ActivityAddressMunicipalityId
            ),
            ActivityAddressEldershipId: returnEldership(
              applicantData.ActivityAddressEldershipId,
              applicantData.ActivityAddressMunicipalityId
            ),
          },
          FarmData: {
            ...farmData,
            IncomeSources: incomeSourceIds,
            IndividualActivityCertificateNumberStartDate:
              farmData.IndividualActivityCertificateNumberStartDate != null
                ? farmData.IndividualActivityCertificateNumberStartDate
                    .length !== 10
                  ? addHours(
                      farmData.IndividualActivityCertificateNumberStartDate,
                      3
                    )
                  : farmData.IndividualActivityCertificateNumberStartDate
                : null,
            ActivityAddressEldership: returnEldership(
              farmData.ActivityAddressEldership,
              farmData.ActivityAddressMunicipalityId
            ),
            ActivityAddressEldershipId: returnEldership(
              farmData.ActivityAddressEldershipId,
              farmData.ActivityAddressMunicipalityId
            ),
          },
          ContactInfo: ceoDataList,
          CompanyData: companyDataList,
          LoanInfo: loanInfo,
          FinancialData: companyQuestionList,
          Questionnaire: ShowQuestionList() ? questionnaire : questionereEmpty,
          Checks: requestData?.RequestSystemInfo?.FormVersion == 3 ? checksNew : checks,
          suppliers: suppliers,
          buyers: buyers,
          //ActivityRegionId: activityRegionId,
          ActivityRegions: activityRegionIds,
          AMLPEPcomment: aMLPEPcomment,
          ManagerData: {
            ...managerData,
            DocumentDate:
              managerData.DocumentDate != null
                ? managerData.DocumentDate.length !== 10
                  ? addHours(new Date(managerData.DocumentDate), 3)
                  : managerData.DocumentDate
                : null,
            DocumentExpiredDate:
              managerData.DocumentExpiredDate != null
                ? managerData.DocumentExpiredDate.length !== 10
                  ? addHours(new Date(managerData.DocumentExpiredDate), 3)
                  : managerData.DocumentExpiredDate
                : null,
          },
          OtherRequestData: {
            ...otherRequestData,
            ControllingCompanies: controllingCompanies,
            ControllingForeignCompanies: controllingForeignCompanies,
          },
          DeclarationInfo: declarationInfo,
          FinancialData: companyQuestionList,
          SelfDeclaration: selfDeclarationData,
          pepData: pepData
            ? {
                parentCompanyCode: pepData.ParentCompanyCode,
                HasImportantResponsibilities:
                  pepData.HasImportantResponsibilities,
                HasControllingPersons: pepData.HasControllingPersons,
                HasControllingCompanies: pepData.HasControllingCompanies,
                HasImportantResponsibilitiesMoreThan4:
                  pepData.HasImportantResponsibilitiesMoreThan4,
                importantResponsibilityPersons:
                  pepData.ImportantResponsibilityPersons,
                controllingPersons: pepData.ControllingPersons,
                controllingCompanies: pepData.ControllingCompanies,
              }
            : null,
        };
        dispatch(actions.dashboard.saveRquestAction(data, requestData?.RequestSystemInfo?.FormVersion));
        setSavedRequest(true);
      } else {
        scrollToError();
      }
    } else {
      console.log('negalima nustatyti prisijungusiojo tipo');
    }
  };

  const submit = () => {
    setNotvalid(true);
    setTimeout(() => {
      const el = document.getElementsByClassName('error1');
      const el2 = Object.values(el)?.some((val) => val.innerText.length > 0);
      if (el2) {
        scrollToError();
      } else {
        check();
      }
    }, 0);
  };
  
  const onHasImportantResponsibilitiesMoreThan4Change = (e) => {
    setPepData({
      ...pepData,
      HasImportantResponsibilitiesMoreThan4: e.target.checked,
    });
  };

  const onHasImportantResponsibilities = (e) => {
    setPepData({
      ...pepData,
      HasImportantResponsibilities: !pepData.HasImportantResponsibilities,
    });
  };

  const onHasControllingPersons = (e) => {
    setPepData({
      ...pepData,
      HasControllingPersons: !pepData.HasControllingPersons,
    });
  };

  const onHasControllingCompanies = (e) => {
    setPepData({
      ...pepData,
      HasControllingCompanies: !pepData.HasControllingCompanies,
    });
  };

  const onImportantResponsibilitiesChange = (index, propName, e) => {
    setPepData({
      ...pepData,
      ...(pepData.ImportantResponsibilityPersons[index][propName] =
        e.target.value),
    });
  };

  const onControllingPersonsChange = (index, propName, e) => {
    if (propName === 'sharesPart' || propName === 'votingPart') {
      if (
        (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) ||
        !e.target.value
      ) {
        setPepData({
          ...pepData,
          ...(pepData.ControllingPersons[index][propName] = e.target.value),
        });
      }
      return null;
    } else {
      setPepData({
        ...pepData,
        ...(pepData.ControllingPersons[index][propName] = e.target.value),
      });
    }
  };

  const onControllingCompaniesChange = (index, propName, e) => {
    if (propName === 'sharesPart' || propName === 'votingPart') {
      if (
        (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) ||
        !e.target.value
      ) {
        setPepData({
          ...pepData,
          ...(pepData.ControllingCompanies[index][propName] = e.target.value),
        });
      }
      return null;
    } else {
      setPepData({
        ...pepData,
        ...(pepData.ControllingCompanies[index][propName] = e.target.value),
      });
    }
  };

  const amlPepTrueFalseTranslation = (val) => (val === true ? 'Taip' : 'Ne');

  const getFinancialCriteriaResults = (id, formVersion) => {
    dispatch(actions.dashboard.getFinancialCriteriaResultsAction(id, formVersion));
    const open = {
      modalName: 'financialCriteriaModal',
      visible: true,
      formVersion: requestData?.RequestSystemInfo?.FormVersion,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const cancel = () => {
    const open = {
      modalName: 'goBackConfirmationModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const [requestId, setRequestId] = useState(null);

  const [savedRequest, setSavedRequest] = useState(false);

  //add values, that is n ot requered here
  const isRequeredToCheck = (val) => {
    if (
      val === 'OwnerCompanyCode' ||
      val === 'OwnerPersonalCode' ||
      val === 'OwnerIndividualActivityCertificateNumber' ||
      val === 'MonthlyRentalPrice' ||
      val === 'ParentCompanyCode'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCheckBox = (name) =>
    name.slice(0, 5) === 'check' || name.slice(0, 5) === 'Check' ? true : false;

  const isDate = (name) =>
    name.slice(name.length - 4) === 'Date' ? true : false;

  const onControllingCompaniesInputChange = ({ target: { name, value } }) => {
    setControllingCompanies(value);
  };

  const onCompanySizeTypeChange = ({ target: { name, value } }) => {
    setOtherRequestData({ ...otherRequestData, [name]: value });
  };

  const [reason, setReason] = useState('');
  const onReasonChange = ({ target: { name, value } }) => {
    setReason(value);
  };

  const onSubjectRelationIdChange = ({ target: { name, value } }) => {
    setOtherRequestData({ ...otherRequestData, [name]: value });
  };

  const onBasisOfRepresentationIdChange = ({ target: { name, value } }) => {
    setManagerData({ ...managerData, [name]: value });
  };

  const onDocumentTypeIdChange = ({ target: { name, value } }) => {
    setManagerData({ ...managerData, [name]: value });
  };

  const onControllingForeignCompaniesInputChange = ({
    target: { name, value },
  }) => {
    setControllingForeignCompanies(value);
  };

  const onCompanyDataCheckBoxChange = (e, name) => {
    setCompanyDataList({ ...companyDataList, [name]: e.target.checked });
  };

  const onApplicantInputChange = ({ target: { name, value } }) => {
    setApplicantData({ ...applicantData, [name]: value });
  };
  const onFarmInputChange = ({ target: { name, value } }) => {
    setFarmData({ ...farmData, [name]: value });
  };

  const onManagerInputChange = ({ target: { name, value } }) => {
    setManagerData({ ...managerData, [name]: value });
  };

  const onOtherRequestInputChange = ({ target: { name, value } }) => {
    setOtherRequestData({ ...otherRequestData, [name]: value });
  };

  const onApplicantDataCheckBoxChange = (e, name) => {
    setApplicantData({ ...applicantData, [name]: e.target.checked });
  };

  const onFarmDataCheckBoxChange = (e, name) => {
    setFarmData({ ...farmData, [name]: e.target.checked });
  };

  const onOtherRequestDataCheckBoxChange = (e, name) => {
    setOtherRequestData({ ...otherRequestData, [name]: e.target.checked });
  };

  const onActivityRegionIdChange = (e) => {
    setActivityRegionId(e.target.value);
  };

  const onAMLPEPcommentChange = (e) => {
    if (e?.target?.value?.length < 2047) {
      setAMLPEPcomment(e.target.value);
    }
  };

  const handleApplicantDateChange = (date, name) => {
    if (date) {
      setApplicantData({ ...applicantData, [name]: date });
    }
  };

  const handleFarmDateChange = (date, name) => {
    const format = addHours(date, 3);
    if (date) {
      setFarmData({
        ...farmData,
        [name]: format.toISOString().split('T')[0],
      });
    }
  };

  const handleManagerDateChange = (date, name) => {
    if (date) {
      setManagerData({ ...managerData, [name]: date });
    }
  };

  const clearContractStartDate = () => {
    if (!viewWindow) {
      setFarmData({
        ...farmData,
        IndividualActivityCertificateNumberStartDate: null,
      });
    }
  };

  const clearDocumentDate = () => {
    if (!viewWindow) {
      setManagerData({
        ...managerData,
        DocumentDate: null,
      });
    }
  };

  const clearDocumentExpiredDate = () => {
    if (!viewWindow) {
      setManagerData({
        ...managerData,
        DocumentExpiredDate: null,
      });
    }
  };

  const clearFarmRegistrationDate = () => {
    if (!viewWindow) {
      setFarmData({
        ...farmData,
        FarmRegistrationDate: null,
      });
    }
  };

  const clearIndividualActivityCertificateNumberStartDate = () => {
    if (!viewWindow) {
      setFarmData({
        ...farmData,
        IndividualActivityCertificateNumberStartDate: null,
      });
    }
  };

  const copyRequest = (requestId) => {
    const loginType = getLocalStorageItemIfNotExpired('loginType');
    if (loginType === 'Company') {
      dispatch(
        actions.dashboard.createNewRequestAction(null, 'copy', requestId)
      );
    } else if (loginType === 'Individual') {
      dispatch(
        actions.dashboard.createNewRequestIndividualAction(
          null,
          'copy',
          requestId
        )
      );
    } else {
      swal({
        title: 'Įvyko klaida',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  };

  const checkControllingPersons = (data) => {
    if (pepData.HasControllingPersons != true) {
      return false;
    } else {
      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (data[i][key]) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const checkControllingCompanies = (data) => {
    if (pepData?.HasControllingCompanies == 'true') {
      return false;
    } else {
      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (data[i][key]) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const [modalData, setModalData] = useState({});
  const [modalCheckbox, setModalCheckbox] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalOkTitle, setModalOkTitle] = useState();
  const [userComment, setUserComment] = useState(false);
  const [mainText, setMainText] = useState('');
  const [questionnaire, setQuestionnaire] = useState(null);

  const [activityRegionId, setActivityRegionId] = useState(null);
  const [aMLPEPcomment, setAMLPEPcomment] = useState(null);

  const approveRequest = () => {
    setModalData({ requestId });

    dispatch(
      actions.modals.setModalConfirmAction(
        requestId,
        'APPROVE_REQUEST',
        userComment
      )
    );
  };

  const approveRequestWithDocs = () => {
    setModalData({ requestId });

    dispatch(
      actions.modals.setModalConfirmAction(
        requestId,
        'APPROVE_REQUEST_WITH_DOCS'
      )
    );
  };

  const sign = () => {
    const open = {
      modalName: 'signContractModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeConditions = () => {
    const open = {
      modalName: 'changeConditionsModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const [isStatusGood, setIsStatusGood] = useState(false);

  const [requestSystemInfo, setRequestSystemInfo] = useState(null);

  const isSelectedEvrkInTheList = (val, fromWhere) => {
   {/*} if (evrksList && evrksList.length > 0) {
      const isInArray = evrksList.filter((x) => x == val);
      return isInArray.length === 0 ? false : true;
    }

    const isInArray = requestData.evrksList.filter((x) => x == val);
  return isInArray.length === 0 ? false : true;*/}
  return true;
  };

  useEffect(() => {
    dispatch(actions.dashboard.SetCanCreateRequest(false));
    setCeoDataList(requestData.ContactInfo); //kontaktinė informacija JA/FA bendras
    if (requestData.CompanyData) {
      setCompanyDataList({
        ...requestData.CompanyData,
        CompanyEvrk:
        viewWindow ||
        isSelectedEvrkInTheList(requestData.CompanyData.CompanyEvrk)
          ? requestData.CompanyData.CompanyEvrk
          : null,
        RegistrationAddressMunicipality: !requestData.CompanyData
          .RegistrationAddressMunicipality
          ? '-'
          : requestData.CompanyData.RegistrationAddressMunicipality,
        RegistrationAddressSettlement:
          requestData.CompanyData.RegistrationAddressSettlement,
        ActivityAddressEldership:
          !requestData.CompanyData.ActivityAddressEldership &&
          !requestData.CompanyData.ActivityAddressMunicipality
            ? '-'
            : requestData.CompanyData.ActivityAddressEldership,
        ActivityAddressSettlement: !requestData.CompanyData
          .ActivityAddressSettlement
          ? '-'
          : requestData.CompanyData.ActivityAddressSettlement,
      });
    }

    setHasParentCompanyValue(
      requestData.OtherRequestData &&
        requestData.OtherRequestData.ParentCompanyCodesFromRequest &&
        requestData.OtherRequestData.ParentCompanyCodesFromRequest.length !== 0
        ? true
        : false
    );
    setDeclarationInfo(requestData.DeclarationInfo);
    
    setLoanInfo({
      ...requestData.LoanInfo,
    });
    setChecks(requestData.Checks);
    setChecksNew(requestData.Checks);
    setevrksList(requestData.evrksList);
    requestData.ApplicantData != null &&
      setApplicantData({
        ...requestData.ApplicantData,
        CompanyEvrk: requestData.ApplicantData.CompanyEvrk,
      });

    requestData.FarmData != null &&
      setFarmData({
        ...requestData.FarmData,
        CompanyEvrk:
        viewWindow ||
        isSelectedEvrkInTheList(requestData.FarmData.CompanyEvrk)
          ? requestData.FarmData.CompanyEvrk
          : null,
      });
    setIncomeSourcesIds(requestData?.FarmData?.IncomeSources);
    setActivityRegionIds(requestData?.ActivityRegions);
    requestData.ManagerData != null &&
      setManagerData({
        ...requestData.ManagerData,
      });
    requestData.OtherRequestData != null &&
      setOtherRequestData({
        ...requestData.OtherRequestData,
        Iban: requestData.OtherRequestData.Iban
          ? requestData.OtherRequestData.Iban
          : 'LT',
      });

    setCompanyQuestionList(requestData.FinancialData);
    setRequestSystemInfo(requestData.RequestSystemInfo);
    setRequestId(
      requestData.RequestSystemInfo != null
        ? requestData.RequestSystemInfo.RequestId
        : null
    );

    // Pep
    if (requestData && requestData.PepData) {
      for (let i = 0; i < 4; i++) {
        const item = requestData.PepData.ImportantResponsibilityPersons[i];
        if (item) {
          requestData.PepData.ImportantResponsibilityPersons[i] = {
            positionId: item.PositionId,
            name: item.Name,
            surname: item.Surname,
            birthDate: item.BirthDate,
            pepPositionId: item.PepPositionId,
            institution: item.Institution,
            country: item.Country,
            //personRelation: item.PersonRelation,
          };
        } else {
          requestData.PepData.ImportantResponsibilityPersons[i] = {
            positionId: '',
            name: '',
            surname: '',
            birthDate: '',
            pepPositionId: '',
            institution: '',
            country: '',
            //personRelation: '',
          };
        }
      }

      for (let i = 0; i < 4; i++) {
        const item = requestData.PepData.ControllingPersons[i];
        if (item) {
          requestData.PepData.ControllingPersons[i] = {
            name: item.Name,
            surname: item.Surname,
            citizenshipId: item.CitizenshipId,
            personCode: item.PersonCode,
            position: item.Position,
            personControlId: item.PersonControlId,
            sharesPart: item.SharesPart,
            votingPart: item.VotingPart,
            stateOfResidenceForTaxPurposesId:
              item.StateOfResidenceForTaxPurposesId,
            stateOfTheTaxpayerId: item.StateOfTheTaxpayerId,
            stateTaxIdentificationNumber: item.StateTaxIdentificationNumber,
          };
        } else {
          requestData.PepData.ControllingPersons[i] = {
            name: '',
            surname: '',
            citizenshipId: '',
            personCode: '',
            position: '',
            personControlId: '',
            sharesPart: '',
            votingPart: '',
            stateOfResidenceForTaxPurposesId: '',
            stateOfTheTaxpayerId: '',
            stateTaxIdentificationNumber: '',
          };
        }
      }

      for (let i = 0; i < 4; i++) {
        const item = requestData.PepData.ControllingCompanies[i];
        if (item) {
          requestData.PepData.ControllingCompanies[i] = {
            name: item.Name,
            code: item.Code,
            country: item.Country,
            sharesPart: item.SharesPart,
            votingPart: item.VotingPart,
            stockApprove: item.StockApprove,
            stockName: item.StockName,
            stockCountryId: item.StockCountryId,
            stockIsin: item.StockIsin,
          };
        } else {
          requestData.PepData.ControllingCompanies[i] = {
            name: '',
            code: '',
            country: '',
            sharesPart: '',
            votingPart: '',
            stockApprove: null,
            stockName: '',
            stockCountryId: '',
            stockIsin: '',
          };
        }
      }
    }

    if (requestData && requestData.Suppliers) {
      for (let i = 0; i < 5; i++) {
        const item = requestData.Suppliers[i];
        if (item) {
          requestData.Suppliers[i] = {
            name: item.Name,
            code: item.Code,
            registrationCountryId: item.RegistrationCountryId,
            evrk: item.Evrk,
            basisForPayments: item.BasisForPayments,
          };
        } else {
          requestData.Suppliers[i] = {
            name: '',
            code: '',
            registrationCountryId: '',
            evrk: '',
            basisForPayments: '',
          };
        }
      }
    }

    if (requestData && requestData.Buyers) {
      for (let i = 0; i < 5; i++) {
        const item = requestData.Buyers[i];
        if (item) {
          requestData.Buyers[i] = {
            name: item.Name,
            code: item.Code,
            registrationCountryId: item.RegistrationCountryId,
            evrk: item.Evrk,
            basisForPayments: item.BasisForPayments,
          };
        } else {
          requestData.Buyers[i] = {
            name: '',
            code: '',
            registrationCountryId: '',
            evrk: '',
            basisForPayments: '',
          };
        }
      }
    }

    if (requestData) {
      setPepData(requestData.PepData);
      setSuppliers(requestData?.Suppliers);
      setBuyers(requestData?.Buyers);
    }

    //pep end

    setViewWindow(requestData.type === 'view' ? true : false);
    setIsStatusGood(
      requestData.RequestSystemInfo != null &&
        requestData.RequestSystemInfo.Status != null &&
        requestData.RequestSystemInfo.Status !== 6
        ? true
        : false
    );
    if (requestData.Invoice) {
      if (!requestData.Invoice.RentDate) {
        requestData.Invoice.RentDate = '2020-02-01';
      }
      if (!requestData.Invoice.InvoiceType) {
        requestData.Invoice.InvoiceType = 1;
      }
    }

    if (requestData.type != null && requestData.type === 'view') {
      setNotvalid(false);
    }

    {
      /*setControllingCompanies(requestData.ControllingCompanies);
    setControllingForeignCompanies(requestData.ControllingForeignCompanies);
    setTurnoversInfo(arrangeTurnovers(requestData.TurnoversInfo));
    
    setReason(requestData.InvegaReason);
  }, [requestData]);*/
    }
    setControllingCompanies(
      requestData?.OtherRequestData?.ControllingCompanies
    );
    setControllingForeignCompanies(
      requestData?.OtherRequestData?.ControllingForeignCompanies
    );
    setTurnoversInfo(arrangeTurnovers(requestData.TurnoversInfo));
    //setActivityRegionId(requestData.ActivityRegionId);
    setAMLPEPcomment(requestData.AMLPEPComment);
    setSelfDeclarationtData(requestData.SelfDeclaration);
  }, [requestData]);
  

  const arrangeTurnovers = (list) => {
    if (
      list &&
      list.length === 6 &&
      list[0].Date &&
      list[0].Date === '2019-11-01T00:00:00' &&
      list[5].Date &&
      list[5].Date === '2021-01-01T00:00:00'
    ) {
      return [list[0], list[3], list[1], list[4], list[2], list[5]];
    }

    return list;
  };
  //Use efect for getting uploaded files
  useEffect(() => {
    if (
      requestData.RequestSystemInfo &&
      requestData.RequestSystemInfo.RequestId &&
      requestData.RequestSystemInfo.RequestId !== null
    ) {
      dispatch(
        actions.dashboard.getUploadedFileList(
          requestData.RequestSystemInfo.RequestId
        )
      );
    }
  }, [requestData]);

  const isOldEvrkValid = (val) => {
    if (!val || val === null) {
      return 'neįvestas EVRK kodas';
    }
    var ex = /\b\d{6}\b/g;
    if (ex.test(val) === false) {
      return 'Neteisingai įvestas EVRK kodas';
    }
    return null;
  };

  const isValidIban = (v) => {
    if (!v) {
      return false;
    }
    return v.match(/[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}/);
  };

  const isPostCodeValid = (code) => {
    if (!code) {
      return false;
    }

    if (code.length > 5) {
      return false;
    }
    return code.match(/^\d+$/);
  };
 
  const hasParentCompany = (e) => {
    setHasParentCompanyState(e.target.value === 'true' ? true : false);

    const has = e.target.value === 'true';
    if (!has) {
      setOtherRequestData({
        ...otherRequestData,
        ParentCompanyCodesFromRequest: '',
      });
    }
    setHasParentCompanyValue(has);
  };

  const hasParentCompanyFA = (e) => {
    setHasParentCompanyState(e.target.value === 'true' ? true : false);

    const has = e.target.value === 'true';
    if (!has) {
      setOtherRequestData({
        ...otherRequestData,
        ParentCompanyCodesFromRequest: '',
      });
    }
    setHasParentCompanyValue(has);
  };

  const notValidCompanyCode = () => {
    if (
      hasParentCompanyValue &&
      !/^([0-9]{9})$/.test(otherRequestData.ParentCompanyCodesFromRequest)
    ) {
      return true;
    }
    return false;
  };

  const notValidCompanyCodeFA = () => {
    if (
      hasParentCompanyValue &&
      !/^([0-9]{9})$/.test(otherRequestData.ParentCompanyCodesFromRequest)
    ) {
      return true;
    }
    return false;
  };

  const translateTurnover = (val) => {
    const year = val.substring(0, 4);
    const month = val.substring(5, 7);

    const dictionary = {
      '01': 'sausio',
      '02': 'vasario',
      '03': 'kovo',
      '04': 'balandžio',
      '05': 'gegužės',
      '06': 'birželio',
      '07': 'liepos',
      '08': 'rugpjūčio',
      '09': 'rugsėjo',
      10: 'spalio',
      11: 'lapkričio',
      12: 'gruodžio',
    };

    if (val && val.length === 4) {
      return `Apyvarta ${year}m `;
    }
    return `Apyvarta ${year}m. ${dictionary[month]} mėn.`;
  };

  const financialDataArray2019 = [
    'Fsi2019Submitted',
    'FsiStartDate2019',
    'FsiEndDate2019',
    'Assets19',
    'Liabilities19',
    'PropertyAmount19',
    'Turnover19',
  ];

  const financialDataArray2022 = [
    'Fsi2022Submitted',
    'FsiStartDate2022',
    'FsiEndDate2022',
    'Assets22',
    'Liabilities22',
    'PropertyAmount22',
    'Turnover22',
  ];

  const financialDataArray2021 = [
    'Fsi2021Submitted',
    'FsiStartDate2021',
    'FsiEndDate2021',
    'Assets21',
    'Liabilities21',
    'PropertyAmount21',
    'Turnover21',
  ];

  const financialDataArray2020 = [
    'Fsi2020Submitted',
    'FsiStartDate2020',
    'FsiEndDate2020',
    'Assets20',
    'Liabilities20',
    'PropertyAmount20',
    'Turnover20',
  ];

  const financialDataArray = (fsiYear) =>
    fsiYear == 2019 ? financialDataArray2019 : financialDataArray2020;
  const financialDataArray2 = (fsiYear) =>
    fsiYear == 2020 ? financialDataArray2020 : financialDataArray2021;

    const agreementsCheckBoxesArray = [
      'Check2Correctness',
      'Check5AgreementToManage',
      'Check3AgreementToCheck',
      'Check4AgreementToPublish',
      'Check6OtherCircuference',
      'Check7Restructuring',
      'Check1Confirment',
    ];

    const agreementsCheckBoxesNewArray = [
      'Check2Correctness',
      'Check5AgreementToManage',
      'Check3AgreementToCheck',
      'Check4AgreementToPublish',
      'Check6OtherCircuference',
      'Check7Restructuring',
      'Check1Confirment',
    ];

  const fileTypeTranslation = (name) => {
    if (name === 'Contract') {
      return 'Sutartis';
    } else if (name === 'Decision') {
      return 'Sprendimas';
    } else if (name === 'ChangeConditionsRequest') {
      return 'Prašymas pakeisti susitarimą';
    } else if (name === 'ChangeConditions') {
      return 'Sąlygų keitimo dokumentas';
    } else if (name === 'ChangeConditionsReject') {
      return 'Sąlygų keitimas atmestas';
    } else if (name === 'ChangeConditionsDecision') {
      return 'Sąlygų keitimo sprendimas';
    } else if (name === 'RejectDecision') {
      return 'Atmetimo sprendimas';
    }
  };

  const applicantDataArray = [
    'IndividualActivityCertificateNumber',
    'IndividualActivityCertificateNumberStartDate',
    'FarmCode',
    'FarmHouseNo',
    //'CompanyManagerName',
    //'PersonalCode',
    //'CompanyManagerCitizenshipId',
    //'CompanyManagerCitizenshipId2',//pakeisti
    //'StateOfResidenceForTaxPurposes',
    // 'CompanyEvrk',
    'ActivityInfo',
    'CompanyActivityAddress',
  ];

  const farmDataArray = [
    'CompanyEvrk',
    'ActivityInfo',
    'IndividualActivityCertificateNumber',
    'IndividualActivityCertificateNumberStartDate',
    'IncomeSources',
    'OtherIncomeSource',
    'CompanyActivityAddress',
  ];

  const managerDataArray = [
    'FirstName',
    'LastName',
    'PersonalCode',
    'Position',
    'BasisOfRepresentationId',
    'DocumentTypeId',
    'DocumentNumber',
    'DocumentIssuedCountryId',
    'DocumentDate',
    'DocumentExpiredDate',
    'CompanyManagerCitizenshipId',
    'StateOfResidenceForTaxPurposesId',
    'StateOfTheTaxpayerId',
    'StateTaxIdentificationNumber',
    'PermanentResidenceCountryId',
  ];
  const otherRequestDataArray = [
    'CompanyPhone',
    'CompanyEmail',
    'ContractEmail',
    'CompanyWeb',
    'Iban',
    'SubjectRelationId',
    'CompanySizeType',
    'ParentCompanyCodesFromRequest',
    // 'CheckCompanyAge',
    'ControllingCompanies',
    'ControllingForeignCompanies',
    'NumberOfEmployees',
  ];

  const ceoDataListArray = ['ApplicantName', 'ApplicantSurname'];

  const companyDataListArray = [
    'CompanyName',
    'CompanyCode',
    'CompanyType',
    'StatusInitial',
    'CompanyEvrk',
    'ActivityInfo',
    'LicenceNeeded',
    'CompanyRegistrationDate',
    'CompanyRegistrationCountryId',
    'StateOfResidenceForTaxPurposesId',
    'StateOfTheTaxpayerId',
    'StateTaxIdentificationNumber',
    'CompanyRegistrationAddress',
    'CompanyActivityAddress',
  ];

  const declarationInfoArray = [
    'EuLoanSum',
    'InvegaAsapSumLoan',
    'InvegaMostAffectedLoanSum',
    'InvegaTurapSumLoan',
    'InvegaPortfolioLoan2Sum',
  ];

  const formatEvrkJson2 = () => {
    return evrksList
      ? evrksList.map((val) => ({
          [val]: evrkFull[val],
        }))
      : null;
  };

  const getValueByCode = (list, code) => {
    if (list && code) {
      const foundValue = list.find((val) => val.value?.Code === code);
      return foundValue?.label?.Name;
    }
  };

  const onEvrkInputChange = ({ target: { name, value } }, listData) => {
    if (loginType === 'Individual') {
      setFarmData({
        ...farmData,
        [name]: value === '-' ? null : value,
      });
    } else {
      setCompanyDataList({
        ...companyDataList,
        [name]: value === '-' ? null : value,
      });
    }
  };

  const onAddressInputChange = ({ target: { name, value } }, listData) => {
    if (name === 'RegistrationAddressDistrict') {
      setCompanyDataList({
        ...companyDataList,
        RegistrationAddressDistrict: getValueByCode(listData, value),
        RegistrationAddressDistrictId: value,
        RegistrationAddressMunicipality: null,
        RegistrationAddressMunicipalityId: null,
        RegistrationAddressEldership: null,
        RegistrationAddressEldershipId: null,
        RegistrationAddressSettlement: null,
        RegistrationAddressSettlementId: null,
      });
    }

    if (name === 'ActivityAddressDistrict') {
      if (loginType === 'Individual') {
        setFarmData({
          ...farmData,
          ActivityAddressDistrict: getValueByCode(listData, value),
          ActivityAddressDistrictId: value,
          ActivityAddressMunicipality: null,
          ActivityAddressMunicipalityId: null,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      } else {
        setCompanyDataList({
          ...companyDataList,
          ActivityAddressDistrict: getValueByCode(listData, value),
          ActivityAddressDistrictId: value,
          ActivityAddressMunicipality: null,
          ActivityAddressMunicipalityId: null,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      }
    }

    if (name === 'RegistrationAddressMunicipality') {
      setCompanyDataList({
        ...companyDataList,
        RegistrationAddressMunicipality: getValueByCode(listData, value),
        RegistrationAddressMunicipalityId: value,
        RegistrationAddressEldership: null,
        RegistrationAddressEldershipId: null,
        RegistrationAddressSettlement: null,
        RegistrationAddressSettlementId: null,
      });
    }

    if (name === 'ActivityAddressMunicipality') {
      if (loginType === 'Individual') {
        setFarmData({
          ...farmData,
          ActivityAddressMunicipality: getValueByCode(listData, value),
          ActivityAddressMunicipalityId: value,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      } else {
        setCompanyDataList({
          ...companyDataList,
          ActivityAddressMunicipality: getValueByCode(listData, value),
          ActivityAddressMunicipalityId: value,
          ActivityAddressEldership: null,
          ActivityAddressEldershipId: null,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      }
    }

    if (name === 'RegistrationAddressEldership') {
      setCompanyDataList({
        ...companyDataList,
        RegistrationAddressEldership: getValueByCode(listData, value),
        RegistrationAddressEldershipId: value,
        RegistrationAddressSettlement: null,
        RegistrationAddressSettlementId: null,
      });
    }

    if (name === 'ActivityAddressEldership') {
      if (loginType === 'Individual') {
        setFarmData({
          ...farmData,
          ActivityAddressEldership: getValueByCode(listData, value),
          ActivityAddressEldershipId: value,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      } else {
        setCompanyDataList({
          ...companyDataList,
          ActivityAddressEldership: getValueByCode(listData, value),
          ActivityAddressEldershipId: value,
          ActivityAddressSettlement: null,
          ActivityAddressSettlementId: null,
        });
      }
    }

    if (loginType === 'Individual' && name === 'ActivityAddressSettlement') {
      setFarmData({
        ...farmData,
        [name + 'Id']: value === '-' ? null : value,
        [name]: value === '-' ? null : getValueByCode(listData, value),
      });
    }

    if (
      loginType !== 'Individual' &&
      (name === 'RegistrationAddressSettlement' ||
        name === 'ActivityAddressSettlement')
    ) {
      setCompanyDataList({
        ...companyDataList,
        [name + 'Id']: value === '-' ? null : value,
        [name]: value === '-' ? null : getValueByCode(listData, value),
      });
    }
  };

  const onCountryInputChange = (value) => {
    setManagerData({
      ...managerData,
      CompanyManagerCitizenshipId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const onCountryInputChange2 = (value) => {
    setManagerData({
      ...managerData,
      CompanyRegistrationCountryId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const onCountryInputChange3 = (value) => {
    setCompanyDataList({
      ...companyDataList,
      StateOfResidenceForTaxPurposesId:
        value.target.value === '-' ? null : value.target.value,
    });
  };
  const onCountryInputChange4 = (value) => {
    setCompanyDataList({
      ...companyDataList,
      StateOfTheTaxpayerId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const onCountryInputChange5 = (value) => {
    setManagerData({
      ...managerData,
      DocumentIssuedCountryId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const onCountryInputChange6 = (value) => {
    setManagerData({
      ...managerData,
      StateOfResidenceForTaxPurposesId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const onCountryInputChange7 = (value) => {
    setManagerData({
      ...managerData,
      StateOfTheTaxpayerId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const onCountryInputChange8 = (value) => {
    setManagerData({
      ...managerData,
      PermanentResidenceCountryId:
        value.target.value === '-' ? null : value.target.value,
    });
  };

  const from19To20 = (val) => `${val.slice(0, -2)}20`;

  const ageLessThen5 = (regDate) => {
    const date = new Date();
    const ageMinus5Years = subYears(new Date(date), 5);
    return new Date(regDate) > ageMinus5Years;
  };

  const ShowQuestionList = () => {
    const size =
      loginType === 'Company'
        ? companyDataList && companyDataList.CompanySizeType
        : applicantData && applicantData.CompanySizeType;
    const socialBusiness = loanInfo && loanInfo.LoanSocialBusiness;
    const companyRegistrationDate =
      companyDataList && companyDataList.CompanyRegistrationDate;
    const individualRegistrationData =
      farmData &&
      farmData.IndividualActivityCertificateNumberStartDate;
    if (size == 1 || size == 2) {
      if (
        (socialBusiness == 1 || socialBusiness == 2 || socialBusiness == 3) &&
        ageLessThen5(
          loginType === 'Company'
            ? companyRegistrationDate
            : individualRegistrationData
        )
      ) {
        return true;
      }
    }

    return false;
  };

  const onQuestionListCheckBoxChange = ({ target: { name, value } }) => {
    setQuestionnaire({
      ...questionnaire,
      [name]: value === 'true' ? true : false,
    });
  };

  const onQuestionnaireCommentChange = ({ target: { name, value } }) => {
    setQuestionnaire({
      ...questionnaire,
      [name]: value,
    });
  };

  const questionListArray = [
    'IsSmall',
    'IsNotListed',
    'IsProfitNotShared',
    'IsNotJoined',
    'IsNotTaken',
    'IsNotProblemic',
  ];

  const checkifAllQuestionListValuesCheck = () =>
    questionnaire &&
    questionnaire.IsSmall != null &&
    questionnaire &&
    questionnaire.IsNotListed != null &&
    questionnaire &&
    questionnaire.IsProfitNotShared != null &&
    questionnaire &&
    questionnaire.IsNotJoined != null &&
    questionnaire &&
    questionnaire.IsNotTaken != null &&
    questionnaire &&
    questionnaire.IsNotProblemic != null;

  const checkifAllQuestionListReqCommentHasNoValidationError = () =>
    questionnaire &&
    !questionListArray.some(
      (val) =>
        questionnaire[val] == false &&
        isCommentFilled(questionnaire[`${val}Comment`]) !== false
    );

  const returnCountryNameById = (id) => {
    const find = formatCountryJson.find((val) => val.value == id);
    return find ? find[0]?.label : '-';
  };

  const validateItsNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    let key = '';
    if (theEvent.type === 'paste') {
      key = evt.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const [administrationInvoices, setAdministrationInvoices] = useState([]);
  const administrationInvoicesTableHeader = [
    'Sąskaitos numeris',
    'Failo pavadinimas',
    'Sukūrimo data ',
    'Paskolos paruošimo mokestis, Eur',
    'Veiksmai',
  ];

  const GetAdministrationInvoices = async () => {
    try {
      const response = await Api.GetAdministrationInvoices(requestId);
      const cleanedResponse = response.filter((val) => val != null);
      setAdministrationInvoices(
        cleanedResponse?.[0] !== null ? cleanedResponse : []
      );
    } catch (e) {
      // errorHandler(e, e?.response?.status, e?.response && e?.response?.data?.ErrorMessage, t('Ok'));
    } finally {
      // setLoadingStatus(false);
    }
  };

  useEffect(() => {
    if (requestSystemInfo?.RequestId) {
      GetAdministrationInvoices();
    }
  }, [requestSystemInfo?.RequestId]);

  const getContractName = (id) => {
    return `Sąskaita_${id}.pdf`;
  };

  const DownloadAdministrationInvoice = async (contractId, contractNumber) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadAdministrationInvoice(contractId),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = getContractName(contractNumber);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const [selfDeclarationData, setSelfDeclarationtData] = useState(null);
  
  const onSelfDeclarationChange = ({ target: { name, value } }) => {
    setSelfDeclarationtData({ ...selfDeclarationData, [name]: value });
  };

  const DownloadChangeConditionsInvoice = async (
    contractId,
    contractNumber
  ) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadChangeConditionsInvoice(contractId),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = getContractName(contractNumber);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const validateSelfDeclaration = () => {
    let error = null;
    SelfDeclarationInfo.forEach((item) => {
      if (
        item.type === 'input' &&
        validateTextinput(selfDeclarationData?.[item.name], 'error', 1)
      ) {
        error = 'error';
      } else if (
        item.type === 'checkbox' &&
        selfDeclarationData?.[item.name] !== true
      ) {
        error = 'error';
      }
    });
    return error;
  };

  const checkIf2021FinancesToShow = () => {
    const checkDate = new Date('2022-06-01');
    const now = new Date();
    if (now > checkDate) {
      return true;
    }
    return false;
  };

  const onSuppliersChange = (index, propName, e) => {
    suppliers[index][propName] = e.target.value;
    setSuppliers([...suppliers]);
  };

  const onBuyersChange = (index, propName, e) => {
    buyers[index][propName] = e.target.value;
    setBuyers([...buyers]);
  };

  const approveRequestWithDocsForChangeConditions = () => {
    setModalData({ requestId });

    dispatch(
      actions.modals.setModalConfirmAction(
        requestId,
        'APPROVE_REQUEST_WITH_DOCS_FOR_CHANGE_CONDITIONS'
      )
    );
  };

  const checkControlingPersonsLinesFullyFilled = (data, index) => {
    if (data) {
      let input = data[index];

      if (
        input?.name ||
        input?.surname ||
        input?.citizenshipId ||
        input?.personCode ||
        input?.position ||
        input?.personControlId ||
        input?.stateOfResidenceForTaxPurposesId ||
        input?.sharesPart ||
        input?.votingPart
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.surname &&
          input.surname.length > 0 &&
          input.citizenshipId &&
          input.personCode &&
          input.personCode.length > 0 &&
          input.position &&
          input.position.length > 0 &&
          input.personControlId &&
          input.sharesPart &&
          input.sharesPart.length > 0 &&
          input.votingPart &&
          input.votingPart.length > 0 &&
          input.stateOfResidenceForTaxPurposesId
        ) {
          if (input.stateOfResidenceForTaxPurposesId === 'LT') {
            return false;
          } else {
            return (
              !(
                input.stateOfTheTaxpayerId &&
                input.stateOfTheTaxpayerId.length > 0
              ) ||
              !(
                input.stateTaxIdentificationNumber &&
                input.stateTaxIdentificationNumber.length > 0
              )
            );
          }
        }
        return true;
      }
      return false;
    }
  };

  const checkControlingCompaniesLinesFullyFilled = (data, index) => {
    if (data) {
      let input = data[index];

      if (
        input?.name ||
        input?.code ||
        input?.country ||
        input?.sharesPart ||
        input?.votingPart ||
        input?.stockApprove
      ) {
        const areBaseValuesFilled =
          input.name &&
          //  input.name.length > 0 &&
          input.code &&
          //  input.code.length > 0 &&
          input.country &&
          //input.country.length > 0 &&
          input.stockApprove !== null &&
          //input.stockApprove.length > 0 &&
          input.sharesPart &&
          // input.sharesPart.length > 0 &&
          input.votingPart;
        // input.votingPart.length > 0;

        const areAdditionalValuesFilled =
          input.stockName &&
          //input.stockName.length > 0 &&
          input.stockCountryId &&
          // input.stockCountryId.length > 0 &&
          input.stockIsin;
        //input.stockIsin.length > 0;

        if (input.stockApprove === 'true' || input.stockApprove === true) {
          return !(areBaseValuesFilled && areAdditionalValuesFilled);
        } else if (
          input.stockApprove === 'false' ||
          input.stockApprove === false
        ) {
          return !areBaseValuesFilled;
        } else {
          return true;
        }
      }
      return false;
    }
  };

  const checkResponsibilityPersonsLinesFullyFilled = (data, index) => {
    if (data) {
      let input = data[index];
      if (
        input?.positionId ||
        input?.name ||
        input?.surname ||
        input?.birthDate ||
        input?.country ||
        input?.pepPositionId ||
        input?.institution
        //input?.personRelation
      ) {
        if (
          input?.positionId &&
          // input?.positionId.length > 0 &&
          input?.name &&
          input?.name.length > 0 &&
          input?.surname &&
          input?.surname.length > 0 &&
          input?.birthDate &&
          input?.birthDate.length > 0 &&
          input?.pepPositionId &&
          //input?.pepPositionId.length > 0 &&
          input?.country &&
          //input?.country.length > 0 &&
          input?.institution &&
          input?.institution.length > 0
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled1 = (data) => {
    if (data) {
      let input = data[1];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled2 = (data) => {
    if (data) {
      let input = data[2];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled3 = (data) => {
    if (data) {
      let input = data[3];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllSuppliersLinesFullyFilled4 = (data) => {
    if (data) {
      let input = data[4];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllBuyersLinesFullyFilled1 = (data) => {
    if (data) {
      let input = data[1];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };
  const checkIfAllBuyersLinesFullyFilled2 = (data) => {
    if (data) {
      let input = data[2];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllBuyersLinesFullyFilled3 = (data) => {
    if (data) {
      let input = data[3];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const checkIfAllBuyersLinesFullyFilled4 = (data) => {
    if (data) {
      let input = data[4];

      if (
        input.name ||
        input.code ||
        input.basisForPayments ||
        input.evrk ||
        input.registrationCountryId
      ) {
        if (
          input.name &&
          input.name.length > 0 &&
          input.code &&
          input.code.length > 0 &&
          input.basisForPayments &&
          input.basisForPayments.length > 0 &&
          input.evrk &&
          input.evrk.length > 0 &&
          input.evrk !== '-' &&
          input.registrationCountryId &&
          input.registrationCountryId.length > 0 &&
          input.registrationCountryId !== '-'
        ) {
          return false;
        }
        return true;
      }

      return false;
    }
  };

  const enforceMinMax = (el) => {
    if (el?.target?.value != '') {
      if (parseInt(el?.target?.value) < parseInt(el?.target?.min)) {
        el = el?.target?.min;
        return false;
      }
      if (parseInt(el?.target?.value) > parseInt(el?.target?.max)) {
        el = el?.target?.max;
        return false;
      }
    }
  };

  const [incomeSourceIds, setIncomeSourcesIds] = useState([]);

  const [activityRegionIds, setActivityRegionIds] = useState([]);

  const incomeSourceOptions = [
    { label: 'pajamos iš ūkio', value: 1 },
    { label: 'darbo užmokestis', value: 2 },
    { label: 'santaupos, pensija/socialinės išmokos/alimentai', value: 3 },
    { label: 'palikimas', value: 4 },
    { label: 'pajamos iš NT pardavimo', value: 5 },
    { label: 'paskolos/skolintos lėšos', value: 6 },
    { label: 'stipendija', value: 7 },
    { label: 'pajamos iš šeimos narių/artimųjų giminaičių', value: 8 },
    { label: 'laimėjimai (lošimai/lažybos)', value: 9 },
    { label: 'dividendai', value: 10 },
    { label: 'turto nuoma', value: 11 },
    { label: 'individuali veikla', value: 12 },
    { label: 'kita', value: 13 },
  ];

  const activityRegionOptions = [
    { label: 'Lietuva', value: 1 },
    { label: 'ES/EEE', value: 2 },
    { label: 'NVS', value: 3 },
    { label: 'JAV', value: 4 },
    { label: 'Kita', value: 5 },
  ];

  const onMultiSelectChange = (selectedOptions) => {
    const ids = selectedOptions?.map((option) => option.value) || [];
    setIncomeSourcesIds(ids);
  };

  const onMultiSelectChange2 = (selectedOptions) => {
    const ids = selectedOptions?.map((option) => option.value) || [];
    setActivityRegionIds(ids);
  };

  function areAllValuesFalsy(array) {
    return array.every((obj) => Object.values(obj).every((value) => !value));
  }

  const checkIf2022FinancesToShow = () => {
    if (requestData?.RequestSystemInfo?.FirstSubmittedDate) {
      const checkDate = new Date('2023-05-31');
      const now = new Date(requestData?.RequestSystemInfo?.FirstSubmittedDate);
      if (now > checkDate) {
        return true;
      }
    }

    return false;
  };

  return (
    <WithSpinner loading={loading}>
      <div className="container">
      
        <div className="row">
        
          <div className="col-lg-12">
         
            <div className={styles.requestBlockAll}>
              {requestSystemInfo && requestSystemInfo.IsReserved && (
                <p style={{ marginBottom: '10px', color: '#BC2432' }}>
                  Dėmesio! Šiuo metu jau yra gauta tiek paraiškų, kurių
                  finansavimui bus išnaudota visa šiai finansinei priemonei
                  skirta lėšų suma. Dėl šios priežasties Jūsų teikiama paraiška
                  bus įtraukiama į rezervinį sąrašą. Paraiška bus nagrinėjama
                  tuo atveju, jei liktų lėšų arba būtų nuspręsta skirti
                  papildomą sumą priemonei įgyvendinti.
                </p>
              )}
             
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                <div style={{ display: 'flex' }}>
                <h1 style={{ width: '50%', marginTop: '100px' }}>Paraiškos duomenys</h1>
                <p
                  style={{ textAlign: 'right', width: '70%', height: '150px' }}
                >
                  <img src="/logo.png" height="100px"></img>
                </p>
              </div>
                  
                  {requestSystemInfo != null &&
                    requestSystemInfo.RequestId != null && (
                      <div className="row director-data-margin">
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name="RequestId"
                            type="string"
                            value={requestSystemInfo.RequestId}
                            placeholder={'Paraiškos numeris'}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name="DateCreated"
                            type="string"
                            value={
                              format(
                                new Date(requestSystemInfo.Created),
                                'yyyy-MM-dd HH:mm:ss'
                              ) || ''
                            }
                            placeholder={'Paraiškos sukūrimo data'}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name="DataStatus"
                            type="string"
                            value={requestStatus(requestSystemInfo.Status)}
                            placeholder={'Paraiškos būsena'}
                            disabled={true}
                          />
                        </div>
                      </div>
                    )}

                  <h4>Kontaktinė informacija</h4>
                  {ceoDataList != null && requestData.ContactInfo != null && (
                    <div className="row director-data-margin">
                      {ceoDataListArray.map((key, i) => (
                        <div className="col-lg-6 col-sm-12" key={i}>
                          <InputWithTextInline
                            name={key}
                            type={isNumberType(key) ? 'number' : 'string'}
                            onChange={onInputChange}
                            value={ceoDataList[key]}
                            placeholder={
                              loginType === 'Company'
                                ? requestDataLabelTranslation(key)
                                : applicantTranslation(key)
                            }
                            disabled={disableCheck(key)}
                          />
                          {notValid && !disableCheck(key) && (
                            <p className="errorsBlockText error1">
                              {validateTextinput(
                                ceoDataList[key],
                                'privalomas laukas',
                                3
                              )}
                            </p>
                          )}
                        </div>
                      ))}

                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'ApplicantEmail'}
                          type={'string'}
                          onChange={onInputChange}
                          value={ceoDataList['ApplicantEmail']}
                          placeholder={
                            loginType === 'Company'
                              ? requestDataLabelTranslation('ApplicantEmail')
                              : applicantTranslation('ApplicantEmail')
                          }
                          disabled={disableCheck('ApplicantEmail')}
                        />
                        {notValid && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validateEmail(ceoDataList['ApplicantEmail'])}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'ApplicantPhone'}
                          type={'string'}
                          onChange={onInputChange}
                          value={ceoDataList['ApplicantPhone']}
                          placeholder={
                            loginType === 'Company'
                              ? requestDataLabelTranslation('ApplicantPhone')
                              : applicantTranslation('ApplicantPhone')
                          }
                          disabled={disableCheck('ApplicantPhone')}
                        />

                        {notValid && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validatePhone(
                              ceoDataList['ApplicantPhone'],
                              'telefono numeris'
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* {Company data andly for JA} 
                  Duomenys apie verslo subjektą*/}
                  {loginType === 'Company' && (
                    <>
                      <h4>Duomenys apie verslo subjektą</h4>
                      {companyDataList != null &&
                        requestData.CompanyData != null && (
                          <div className="row">
                            {companyDataListArray.map((key, i) => {
                              if (isCheckBox(key) === true) {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <div className="checkbox">
                                      <label className="containerLabel noBlock">
                                        <input
                                          type="checkbox"
                                          name={key}
                                          checked={companyDataList[key]}
                                          disabled={viewWindow}
                                          onChange={(e) =>
                                            onCompanyDataCheckBoxChange(e, key)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                        {requestDataLabelTranslation(key)}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else if (key === 'CompanyEvrk') {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={i}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'CompanyEvrk'
                                      )}
                                    </label>
                                    <select
                                      name="CompanyEvrk"
                                      onChange={onEvrkInputChange}
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241)'
                                          : '#fff',
                                      }}
                                      defaultValue={
                                        companyDataList?.CompanyEvrk || null
                                      }
                                    >
                                      {(!viewWindow ||
                                        !companyDataList?.CompanyEvrk) && (
                                        <option value={null}>{null}</option>
                                      )}

                                      {Object.keys(evrkFullWithZeros).map(
                                        (val, j) => (
                                          <option key={j} value={val}>
                                            {`${val} - ${evrkFullWithZeros[val]}`}
                                          </option>
                                        )
                                      )}

                                      {Object.keys(evrkFullWithoutZeros).map(
                                        (val, j) => (
                                          <option key={j} value={val}>
                                            {`${val} - ${evrkFullWithoutZeros[val]}`}
                                          </option>
                                        )
                                      )}

                                      {viewWindow &&
                                        companyDataList.CompanyEvrk && (
                                          <option
                                            value={companyDataList?.CompanyEvrk}
                                          >
                                            {companyDataList?.CompanyEvrk} -{' '}
                                            {
                                              evrkFull[
                                                companyDataList?.CompanyEvrk
                                              ]
                                            }
                                          </option>
                                        )}
                                    </select>
                                    {notValid && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {!companyDataList.CompanyEvrk
                                          ? 'Privalote pasirinkti ekonominę veiklą'
                                          : ''}
                                      </p>
                                    )}
                                    <p
                                      style={{
                                        color: '#597a96',
                                        marginTop: '5px',
                                      }}
                                    >
                                     * vykdomo projekto dėl kurio kreipiamasi finansavimo ekonominė veikla
                                    </p>
                                  </div>
                                );
                              } else if (key === 'ActivityInfo') {
                                return (
                                  <div className="col-lg-6 col-sm-12">
                                    <InputWithTextInline
                                      name="ActivityInfo"
                                      type="string"
                                      onChange={onCompanyInputChange}
                                      value={companyDataList.ActivityInfo}
                                      placeholder={requestDataLabelTranslation(
                                        'ActivityInfo'
                                      )}
                                     
                                      disabled={disableCheck('ActivityInfo')}
                                    />
                                    {notValid && !viewWindow && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {!companyDataList.ActivityInfo
                                          ? 'Aprašykite veiklą'
                                          : ''}
                                      </p>
                                    )}
                                  </div>
                                );
                              } else if (key === 'LicenceNeeded') {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={key}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'LicenceNeeded'
                                      )}
                                    </label>
                                    <select
                                      disabled={viewWindow}
                                      name="LicenceNeeded"
                                      onChange={onCompanyInputChange}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                        // color: '#2e2d2d',
                                      }}
                                      value={companyDataList.LicenceNeeded}
                                    >
                                      {/*<option value={null}>{null}</option>*/}
                                      <option value={false}>Ne</option>
                                      <option value={true}>Taip</option>
                                    </select>
                                  </div>
                                );
                              } else if (
                                key === 'CompanyRegistrationCountryId'
                              ) {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={key}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'CompanyRegistrationCountry'
                                      )}
                                    </label>
                                    <select
                                      name="CompanyRegistrationCountryId"
                                      onChange={(e) => onCountryInputChange2(e)}
                                      disabled={true}
                                      style={{
                                        background: 'rgb(241, 240, 241',
                                      }}
                                    >
                                      {companyDataList.CompanyRegistrationCountryId ===
                                        requestData.CompanyData
                                          .CompanyRegistrationCountryId &&
                                        companyDataList.CompanyRegistrationCountryId && (
                                          <option
                                            selected={
                                              companyDataList.CompanyRegistrationCountryId
                                            }
                                            value={
                                              companyDataList.CompanyRegistrationCountryId
                                            }
                                          >
                                            {returnCountryNameById(
                                              companyDataList.CompanyRegistrationCountryId
                                            )}
                                          </option>
                                        )}

                                      <option
                                        selected={
                                          companyDataList.CompanyRegistrationCountryId ===
                                          null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>

                                      {formatCountryJson.map((val, j) => (
                                        <option
                                          selected={
                                            companyDataList.CompanyRegistrationCountryId ==
                                            val.value
                                          }
                                          value={val.value}
                                          key={j}
                                        >
                                          {val.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                );
                              } else if (
                                key === 'StateOfResidenceForTaxPurposesId'
                              ) {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={key}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'StateOfResidenceForTaxPurposes'
                                      )}
                                    </label>
                                    <select
                                      name="StateOfResidenceForTaxPurposesId"
                                      onChange={(e) => onCountryInputChange3(e)}
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                      }}
                                    >
                                      {companyDataList.StateOfResidenceForTaxPurposesId ===
                                        requestData.CompanyData
                                          .StateOfResidenceForTaxPurposesId &&
                                        companyDataList.StateOfResidenceForTaxPurposesId && (
                                          <option
                                            selected={
                                              companyDataList.StateOfResidenceForTaxPurposesId
                                            }
                                            value={
                                              companyDataList.StateOfResidenceForTaxPurposesId
                                            }
                                          >
                                            {returnCountryNameById(
                                              companyDataList.StateOfResidenceForTaxPurposesId
                                            )}
                                          </option>
                                        )}

                                      <option
                                        selected={
                                          companyDataList.StateOfResidenceForTaxPurposesId ===
                                          null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>

                                      {formatCountryJson.map((val, j) => (
                                        <option
                                          selected={
                                            companyDataList.StateOfResidenceForTaxPurposesId ==
                                            val.value
                                          }
                                          value={val.value}
                                          key={j}
                                        >
                                          {val.label}
                                        </option>
                                      ))}
                                    </select>

                                    {notValid && !viewWindow && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {!companyDataList.StateOfResidenceForTaxPurposesId
                                          ? 'Privalote pasirinkti rezidavimo valstybę mokesčių tikslais'
                                          : ''}
                                      </p>
                                    )}
                                  </div>
                                );
                              } else if (key === 'StateOfTheTaxpayerId') {
                                return companyDataList?.StateOfResidenceForTaxPurposesId !==
                                  'LT' ? (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                    key={key}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'StateOfTheTaxpayer'
                                      )}
                                    </label>
                                    <select
                                      name="StateOfTheTaxpayerId"
                                      onChange={(e) => onCountryInputChange4(e)}
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                      }}
                                    >
                                      {companyDataList.StateOfTheTaxpayerId ===
                                        requestData.CompanyData
                                          .StateOfTheTaxpayer &&
                                        companyDataList.StateOfTheTaxpayerId && (
                                          <option
                                            selected={
                                              companyDataList.StateOfTheTaxpayerId
                                            }
                                            value={
                                              companyDataList.StateOfTheTaxpayerId
                                            }
                                          >
                                            {returnCountryNameById(
                                              companyDataList.StateOfTheTaxpayerId
                                            )}
                                          </option>
                                        )}

                                      <option
                                        selected={
                                          companyDataList.StateOfTheTaxpayerId ===
                                          null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>

                                      {formatCountryJson.map((val, j) => (
                                        <option
                                          selected={
                                            companyDataList.StateOfTheTaxpayerId ==
                                            val.value
                                          }
                                          value={val.value}
                                          key={j}
                                        >
                                          {val.label}
                                        </option>
                                      ))}
                                    </select>

                                    {notValid && !viewWindow && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {!companyDataList.StateOfTheTaxpayerId
                                          ? 'Privalote pasirinkti mokesčio mokėtojo valstybę'
                                          : ''}
                                      </p>
                                    )}
                                  </div>
                                ) : null;
                              } else if (
                                key === 'CompanyActivityAddress'
                                || key === 'CompanyRegistrationAddress'
                              ) {
                                return (
                                  <AddressModal
                                    title={key}
                                    type={key.replace(/Company/gi, '')}
                                    onInputChange={onCompanyInputChange}
                                    onDropdownImputChange={onAddressInputChange}
                                    data={companyDataList}
                                    notValid={notValid}
                                    viewWindow={viewWindow}
                                    key={i}
                                  />
                                );
                              } else if (
                                key === 'StateOfTheTaxpayerId ' ||
                                key === 'StateTaxIdentificationNumber'
                              ) {
                                return companyDataList?.StateOfResidenceForTaxPurposesId !==
                                  'LT' ? (
                                  <>
                                    <div className="col-lg-6 col-sm-12" key={i}>
                                      <InputWithTextInline
                                        name={key}
                                        type={
                                          isNumberType(key)
                                            ? 'number'
                                            : 'string'
                                        }
                                        onChange={onCompanyInputChange}
                                        value={
                                          key === 'CompanyRegistrationDate'
                                            ? format(
                                                new Date(companyDataList[key]),
                                                'yyyy-MM-dd'
                                              )
                                            : key === 'CompanySizeType'
                                            ? companySize(companyDataList[key])
                                            : companyDataList[key] || ''
                                        }
                                        placeholder={requestDataLabelTranslation(
                                          key
                                        )}
                                        disabled={disableCheck(key)}
                                      />
                                      {notValid && !viewWindow && (
                                        <p
                                          className="errorsBlockText error1"
                                          style={{ color: 'var(--pinkDark)' }}
                                        >
                                          {!companyDataList.StateTaxIdentificationNumber
                                            ? 'Privalote įvesti mokesčių mokėtojo kodą'
                                            : ''}
                                        </p>
                                      )}
                                    </div>
                                  </>
                                ) : null;
                              } else {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <InputWithTextInline
                                      name={key}
                                      type={
                                        isNumberType(key) ? 'number' : 'string'
                                      }
                                      onChange={onCompanyInputChange}
                                      value={
                                        key === 'CompanyRegistrationDate'
                                          ? format(
                                              new Date(companyDataList[key]),
                                              'yyyy-MM-dd'
                                            )
                                          : key === 'CompanySizeType'
                                          ? companySize(companyDataList[key])
                                          : companyDataList[key] || ''
                                      }
                                      placeholder={requestDataLabelTranslation(
                                        key
                                      )}
                                      disabled={disableCheck(key)}
                                    />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                    </>
                  )}
                  <br />
                  {/*Duomenys apie įmonės vadovą / Pareiškėjo asmens dokumento informacija*/}
                  <h4>
                    {loginType === 'Company'
                      ? 'Duomenys apie įmonės vadovą'
                      : 'Pareiškėjo asmens dokumento informacija'}
                  </h4>
                  {managerData != null && requestData.ManagerData != null && (
                    <div className="row">
                      {managerDataArray.map((key, i) => {
                        if (key === 'DocumentDate') {
                          return (
                            <div className="col-lg-6 col-sm-12" key={i}>
                              <div className={styles.selectflex}>
                                <label className="inlineDatepickerInputTitle">
                                  {requestDataLabelTranslation('DocumentDate')}
                                </label>
                                <DatePicker
                                  className="inlineDatepickerInput"
                                  selected={
                                    managerData['DocumentDate'] != null
                                      ? new Date(managerData['DocumentDate'])
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handleManagerDateChange(e, 'DocumentDate')
                                  }
                                  maxDate={
                                    key === 'DocumentDate' ? new Date() : null
                                  }
                                  dateFormat="yyyy-MM-dd"
                                  disabled={viewWindow}
                                />
                                <span
                                  className={styles.clearDateButton}
                                  onClick={() => clearDocumentDate()}
                                >
                                  X
                                </span>
                                {notValid &&
                                  managerData.DocumentDate == null && (
                                    <p
                                      className="errorsBlockText error1"
                                      style={{ color: 'var(--pinkDark)' }}
                                    >
                                      Turite pasirinkti datą
                                    </p>
                                  )}
                              </div>
                            </div>
                          );
                        } else if (key === 'DocumentExpiredDate') {
                          return (
                            <div className="col-lg-6 col-sm-12" key={i}>
                              <div className={styles.selectflex}>
                                <label className="inlineDatepickerInputTitle">
                                  {requestDataLabelTranslation(
                                    'DocumentExpiredDate'
                                  )}
                                </label>
                                <DatePicker
                                  className="inlineDatepickerInput"
                                  selected={
                                    managerData['DocumentExpiredDate'] != null
                                      ? new Date(
                                          managerData['DocumentExpiredDate']
                                        )
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handleManagerDateChange(
                                      e,
                                      'DocumentExpiredDate'
                                    )
                                  }
                                  dateFormat="yyyy-MM-dd"
                                  disabled={viewWindow}
                                />
                                <span
                                  className={styles.clearDateButton}
                                  onClick={() => clearDocumentExpiredDate()}
                                >
                                  X
                                </span>
                                {notValid &&
                                  managerData.DocumentExpiredDate == null && (
                                    <p
                                      className="errorsBlockText error1"
                                      style={{ color: 'var(--pinkDark)' }}
                                    >
                                      Turite pasirinkti datą
                                    </p>
                                  )}
                              </div>
                            </div>
                          );
                        } else if (
                          key === 'BasisOfRepresentationId' &&
                          loginType === 'Company'
                        ) {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation(
                                  'BasisOfRepresentationId'
                                )}
                              </label>
                              <select
                                disabled={viewWindow}
                                name="BasisOfRepresentationId"
                                onChange={(e) =>
                                  onBasisOfRepresentationIdChange(e)
                                }
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  // color: '#2e2d2d',
                                }}
                                value={managerData.BasisOfRepresentationId}
                              >
                                <option value={null}>{null}</option>
                                <option value={'1'}>Įstatai</option>
                                <option value={'2'}>Įgaliojimas</option>
                                <option value={'3'}>Prokūra</option>
                              </select>
                              {notValid &&
                                (managerData.BasisOfRepresentationId === null ||
                                  managerData.BasisOfRepresentationId.length ===
                                    0) && (
                                  <p
                                    className="errorsBlockText error1"
                                    style={{ color: 'var(--pinkDark)' }}
                                  >
                                    Privalote nurodyti atstovavimo pagrindą
                                  </p>
                                )}
                            </div>
                          );
                        } else if (
                          key === 'BasisOfRepresentationId' &&
                          loginType === 'Individual'
                        ) {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <InputWithTextInline
                                name={'BasisOfRepresentationId'}
                                type={'string'}
                                value={'nepildoma'}
                                disabled={true}
                                placeholder={requestDataLabelTranslation(
                                  'BasisOfRepresentationId'
                                )}
                              />
                            </div>
                          );
                        } else if (key === 'DocumentTypeId') {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation('DocumentTypeId')}
                              </label>
                              <select
                                disabled={viewWindow}
                                name="DocumentTypeId"
                                onChange={(e) => onDocumentTypeIdChange(e)}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  // color: '#2e2d2d',
                                }}
                                value={managerData.DocumentTypeId}
                              >
                                <option value={null}>{null}</option>
                                <option value={'1'}>
                                  Asmens tapatybės dokumentas
                                </option>
                                <option value={'2'}>Pasas</option>
                                <option value={'3'}>
                                  Leidimas gyventi Lietuvos Respublikoje
                                </option>
                              </select>
                              {notValid &&
                                (managerData.DocumentTypeId === null ||
                                  managerData.DocumentTypeId.length === 0) && (
                                  <p
                                    className="errorsBlockText error1"
                                    style={{ color: 'var(--pinkDark)' }}
                                  >
                                    Privalote nurodyti dokumento tipą
                                  </p>
                                )}
                            </div>
                          );
                        } else if (key === 'CompanyManagerCitizenshipId') {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation(
                                  'CompanyManagerCitizenship'
                                )}
                              </label>
                              <select
                                name="CompanyManagerCitizenshipId"
                                onChange={(e) => onCountryInputChange(e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                }}
                              >
                                {managerData.CompanyManagerCitizenshipId ===
                                  requestData.ManagerData
                                    .CompanyManagerCitizenshipId &&
                                  managerData.CompanyManagerCitizenshipId && (
                                    <option
                                      selected={
                                        managerData.CompanyManagerCitizenshipId
                                      }
                                      value={
                                        managerData.CompanyManagerCitizenshipId
                                      }
                                    >
                                      {returnCountryNameById(
                                        managerData.CompanyManagerCitizenshipId
                                      )}
                                    </option>
                                  )}

                                <option
                                  selected={
                                    managerData.CompanyManagerCitizenshipId ===
                                    null
                                  }
                                  value={null}
                                >
                                  -
                                </option>

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      managerData.CompanyManagerCitizenshipId ==
                                      val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>

                              {notValid && !viewWindow && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {!managerData.CompanyManagerCitizenshipId
                                    ? 'Privalote pasirinkti pilietybę'
                                    : ''}
                                </p>
                              )}
                            </div>
                          );
                        } else if (key === 'DocumentIssuedCountryId') {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation(
                                  'DocumentIssuedCountryId'
                                )}
                              </label>
                              <select
                                name="DocumentIssuedCountryId"
                                onChange={(e) => onCountryInputChange5(e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                }}
                              >
                                {managerData.DocumentIssuedCountryId ===
                                  requestData.ManagerData
                                    .DocumentIssuedCountryId &&
                                  managerData.DocumentIssuedCountryId && (
                                    <option
                                      selected={
                                        managerData.DocumentIssuedCountryId
                                      }
                                      value={
                                        managerData.DocumentIssuedCountryId
                                      }
                                    >
                                      {returnCountryNameById(
                                        managerData.DocumentIssuedCountryId
                                      )}
                                    </option>
                                  )}

                                <option
                                  selected={
                                    managerData.DocumentIssuedCountryId === null
                                  }
                                  value={null}
                                >
                                  -
                                </option>

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      managerData.DocumentIssuedCountryId ==
                                      val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>

                              {notValid && !viewWindow && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {!managerData.DocumentIssuedCountryId
                                    ? 'Privalote pasirinkti dokumentą išdavusią valstybę'
                                    : ''}
                                </p>
                              )}
                            </div>
                          );
                        } else if (key === 'StateOfResidenceForTaxPurposesId') {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation(
                                  'StateOfResidenceForTaxPurposesId'
                                )}
                              </label>
                              <select
                                name="StateOfResidenceForTaxPurposesId"
                                onChange={(e) => onCountryInputChange6(e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                }}
                              >
                                {managerData.StateOfResidenceForTaxPurposesId ===
                                  requestData.ManagerData
                                    .StateOfResidenceForTaxPurposesId &&
                                  managerData.StateOfResidenceForTaxPurposesId && (
                                    <option
                                      selected={
                                        managerData.StateOfResidenceForTaxPurposesId
                                      }
                                      value={
                                        managerData.StateOfResidenceForTaxPurposesId
                                      }
                                    >
                                      {returnCountryNameById(
                                        managerData.StateOfResidenceForTaxPurposesId
                                      )}
                                    </option>
                                  )}

                                <option
                                  selected={
                                    managerData.StateOfResidenceForTaxPurposesId ===
                                    null
                                  }
                                  value={null}
                                >
                                  -
                                </option>

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      managerData.StateOfResidenceForTaxPurposesId ==
                                      val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>

                              {notValid && !viewWindow && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {!managerData.StateOfResidenceForTaxPurposesId
                                    ? 'Privalote pasirinkti rezidavimo valstybę mokesčių tikslais'
                                    : ''}
                                </p>
                              )}
                            </div>
                          );
                        } else if (key === 'StateOfTheTaxpayerId') {
                          return managerData?.StateOfResidenceForTaxPurposesId !==
                            'LT' ? (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation(
                                  'StateOfTheTaxpayerId'
                                )}
                              </label>
                              <select
                                name="StateOfTheTaxpayerId"
                                onChange={(e) => onCountryInputChange7(e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                }}
                              >
                                {managerData.StateOfTheTaxpayerId ===
                                  requestData.ManagerData
                                    .StateOfTheTaxpayerId &&
                                  managerData.StateOfTheTaxpayerId && (
                                    <option
                                      selected={
                                        managerData.StateOfTheTaxpayerId
                                      }
                                      value={managerData.StateOfTheTaxpayerId}
                                    >
                                      {returnCountryNameById(
                                        managerData.StateOfTheTaxpayerId
                                      )}
                                    </option>
                                  )}

                                <option
                                  selected={
                                    managerData.StateOfTheTaxpayerId === null
                                  }
                                  value={null}
                                >
                                  -
                                </option>

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      managerData.StateOfTheTaxpayerId ==
                                      val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>

                              {notValid && !viewWindow && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {!managerData.StateOfTheTaxpayerId
                                    ? 'Privalote pasirinkti mokesčio mokėtojo valstybę'
                                    : ''}
                                </p>
                              )}
                            </div>
                          ) : null;
                        } else if (key === 'PermanentResidenceCountryId') {
                          return (
                            <div
                              className="col-lg-6 col-sm-12"
                              style={{
                                width: '200px',
                              }}
                            >
                              <label className={styles.evrkTitle}>
                                {' '}
                                {requestDataLabelTranslation(
                                  'PermanentResidenceCountryId'
                                )}
                              </label>
                              <select
                                name="PermanentResidenceCountryId"
                                onChange={(e) => onCountryInputChange8(e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                }}
                              >
                                {managerData.StateOfTheTaxpayerId ===
                                  requestData.ManagerData
                                    .PermanentResidenceCountryId &&
                                  managerData.PermanentResidenceCountryId && (
                                    <option
                                      selected={
                                        managerData.PermanentResidenceCountryId
                                      }
                                      value={
                                        managerData.PermanentResidenceCountryId
                                      }
                                    >
                                      {returnCountryNameById(
                                        managerData.PermanentResidenceCountryId
                                      )}
                                    </option>
                                  )}

                                <option
                                  selected={
                                    managerData.PermanentResidenceCountryId ===
                                    null
                                  }
                                  value={null}
                                >
                                  -
                                </option>

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      managerData.PermanentResidenceCountryId ==
                                      val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>

                              {notValid && !viewWindow && (
                                <p
                                  className="errorsBlockText error1"
                                  style={{ color: 'var(--pinkDark)' }}
                                >
                                  {!managerData.PermanentResidenceCountryId
                                    ? 'Privalote pasirinkti nuolatinės gyvenamosios vietos valstybę'
                                    : ''}
                                </p>
                              )}
                            </div>
                          );
                        } else if (
                          key === 'StateOfTheTaxpayerId ' ||
                          key === 'StateTaxIdentificationNumber'
                        ) {
                          return managerData?.StateOfResidenceForTaxPurposesId !==
                            'LT' ? (
                            <div className="col-lg-6 col-sm-12" key={i}>
                              <InputWithTextInline
                                name={key}
                                type={isNumberType(key) ? 'number' : 'string'}
                                onChange={onManagerInputChange}
                                value={managerData[key]}
                                placeholder={requestDataLabelTranslation(key)}
                                disabled={disableCheck(key)}
                              />
                              {!isRequeredToCheck(key) &&
                                notValid &&
                                !disableCheck(key) && (
                                  <p
                                    className="errorsBlockText error1"
                                    style={{ color: 'var(--pinkDark)' }}
                                  >
                                    {!managerData.StateTaxIdentificationNumber
                                      ? 'Privalote įvesti mokesčių mokėtojo kodą'
                                      : ''}
                                  </p>
                                )}
                            </div>
                          ) : null;
                        } else {
                          return (
                            <div className="col-lg-6 col-sm-12" key={i}>
                              <InputWithTextInline
                                name={key}
                                type={isNumberType(key) ? 'number' : 'string'}
                                onChange={onManagerInputChange}
                                value={managerData[key]}
                                placeholder={requestDataLabelTranslation(key)}
                                disabled={disableCheck(key)}
                              />
                              {!isRequeredToCheck(key) &&
                                notValid &&
                                !disableCheck(key) && (
                                  <p
                                    className="errorsBlockText error1"
                                    style={{ color: 'var(--pinkDark)' }}
                                  >
                                    {key !==
                                    'IndividualActivityCertificateNumber2' ? (
                                      validateTextinput(
                                        managerData[key],
                                        'privalomas laukas',
                                        1
                                      )
                                    ) : (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {isOldEvrkValid(managerData[key])}
                                      </p>
                                    )}
                                  </p>
                                )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}

                  {/* Pareiškėjo duomenys apie vykdomą veiklą */}
                  {loginType === 'Individual' && (
                    <>
                      <br />
                      <h4>Pareiškėjo duomenys apie vykdomą veiklą </h4>
                      {farmData != null && requestData.FarmData != null && (
                        <div className="row">
                          {farmDataArray.map((key, i) => {
                            if (isCheckBox(key) === true) {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <div className="checkbox">
                                    <label className="containerLabel noBlock">
                                      <input
                                        type="checkbox"
                                        name={key}
                                        checked={farmData[key]}
                                        disabled={viewWindow}
                                        onChange={(e) =>
                                          onFarmDataCheckBoxChange(e, key)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                      {requestDataLabelTranslation(key)}
                                    </label>
                                    {notValid &&
                                      !isRequeredToCheck(key) &&
                                      farmData[key] !== true && (
                                        <p
                                          className="errorsBlockText error1"
                                          style={{
                                            color: 'var(--pinkDark)',
                                          }}
                                        >
                                          Turite sutikti su sąlyga
                                        </p>
                                      )}
                                  </div>
                                </div>
                              );
                            } else if (
                              key ===
                              'IndividualActivityCertificateNumberStartDate'
                            ) {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <div className={styles.selectflex}>
                                    <label className="inlineDatepickerInputTitle">
                                      {requestDataLabelTranslation(key)}
                                    </label>
                                    <DatePicker
                                      className="inlineDatepickerInput"
                                      selected={
                                        farmData[key] != null
                                          ? new Date(farmData[key])
                                          : ''
                                      }
                                      onChange={(e) =>
                                        handleFarmDateChange(e, key)
                                      }
                                      maxDate={
                                        key ===
                                        'IndividualActivityCertificateNumberStartDate'
                                          ? new Date()
                                          : null
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      disabled={viewWindow}
                                    />
                                    <span
                                      className={styles.clearDateButton}
                                      onClick={() =>
                                        clearIndividualActivityCertificateNumberStartDate()
                                      }
                                    >
                                      X
                                    </span>
                                  </div>
                                </div>
                              );
                            } else if (key === 'CompanyEvrk') {
                              return (
                                <div
                                  className="col-lg-6 col-sm-12"
                                  style={{
                                    width: '200px',
                                  }}
                                  key={i}
                                >
                                  <label className={styles.evrkTitle}>
                                    {' '}
                                    {requestDataLabelTranslation(
                                      'IndividualEvrk'
                                    )}
                                  </label>
                                  <select
                                    name="CompanyEvrk"
                                    onChange={onEvrkInputChange}
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241)'
                                        : '#fff',
                                    }}
                                    defaultValue={
                                      farmData?.CompanyEvrk || null
                                    }
                                  >
                                    {(!viewWindow ||
                                      !farmData?.CompanyEvrk) && (
                                      <option value={null}>{null}</option>
                                    )}

                                    {Object.keys(evrkFullWithZeros).map(
                                      (val, j) => (
                                        <option key={j} value={val}>
                                          {`${val} - ${evrkFullWithZeros[val]}`}
                                        </option>
                                      )
                                    )}

                                    {Object.keys(evrkFullWithoutZeros).map(
                                      (val, j) => (
                                        <option key={j} value={val}>
                                          {`${val} - ${evrkFullWithoutZeros[val]}`}
                                        </option>
                                      )
                                    )}

                                    {viewWindow &&
                                      farmData.CompanyEvrk && (
                                        <option
                                          value={farmData?.CompanyEvrk}
                                        >
                                          {farmData?.CompanyEvrk} -{' '}
                                          {
                                            evrkFull[
                                              farmData?.CompanyEvrk
                                            ]
                                          }
                                        </option>
                                      )}
                                  </select>
                                  {notValid && (
                                    <p
                                      className="errorsBlockText error1"
                                      style={{ color: 'var(--pinkDark)' }}
                                    >
                                      {!farmData.CompanyEvrk
                                        ? 'Privalote pasirinkti ekonominę veiklą'
                                        : ''}
                                    </p>
                                  )}
                                  <p
                                    style={{
                                      color: '#597a96',
                                      marginTop: '5px',
                                    }}
                                  >
                                   * vykdomo projekto dėl kurio kreipiamasi finansavimo ekonominė veikla
                                  </p>
                                </div>
                              );
                            }
                           else if (
                              key === 'CompanyActivityAddress'
                             // ||                              key === 'CompanyRegistrationAddress'
                            ) {
                              return (
                                <AddressModal
                                  //title={key}
                                  title="Verslo subjekto faktinės veiklos vykdymo adresas"
                                  type={key.replace(/Company/gi, '')}
                                  onInputChange={onFarmDataInputChange}
                                  onDropdownImputChange={onAddressInputChange}
                                  data={farmData}
                                  notValid={notValid}
                                  viewWindow={viewWindow}
                                  key={i}
                                />
                              );
                            } else if (key === 'IncomeSources') {
                              return (
                                <>
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <label
                                      className={styles.evrkTitle}
                                      style={{ display: 'flex' }}
                                    >
                                      Pajamų lėšų šaltinis
                                    </label>

                                    <RequestMultiSelect
                                      placeholder={''}
                                      onChange={(e) => onMultiSelectChange(e)}
                                      defaultValue={
                                        incomeSourceIds.map((id) =>
                                          incomeSourceOptions.find(
                                            (option) => option.value === id
                                          )
                                        ) || []
                                      }
                                      value={
                                        incomeSourceIds.map((id) =>
                                          incomeSourceOptions.find(
                                            (option) => option.value === id
                                          )
                                        ) || []
                                      }
                                      options={incomeSourceOptions}
                                      disabled={disableCheck(key)}
                                    />
                                    {!isRequeredToCheck(key) &&
                                      notValid &&
                                      !disableCheck(key) &&
                                      incomeSourceIds?.length === 0 && (
                                        <p
                                          className="errorsBlockText error1"
                                          style={{ color: 'var(--pinkDark)' }}
                                        >
                                          Privalomas laukas
                                        </p>
                                      )}
                                  </div>
                                </>
                              );
                            } else if (key === 'OtherIncomeSource') {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <InputWithTextInline
                                    name={key}
                                    type={
                                      isNumberType(key) ? 'number' : 'string'
                                    }
                                    onChange={onFarmDataInputChange}
                                    value={farmData[key]}
                                    placeholder={requestDataLabelTranslation(
                                      key
                                    )}
                                    disabled={disableCheck(key)}
                                  />
                                  {!isRequeredToCheck(key) &&
                                    notValid &&
                                    !disableCheck(key) &&
                                    incomeSourceIds?.some((val) => val == 13) &&
                                    validateTextinput(
                                      farmData[key],
                                      'privalomas laukas',
                                      1
                                    ) && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        Privalomas laukas
                                      </p>
                                    )}
                                </div>
                              );
                            } else {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <InputWithTextInline
                                    name={key}
                                    type={
                                      isNumberType(key) ? 'number' : 'string'
                                    }
                                    onChange={onFarmDataInputChange}
                                    value={farmData[key]}
                                    placeholder={requestDataLabelTranslation(
                                      key
                                    )}
                                    disabled={disableCheck(key)}
                                  />
                                  {!isRequeredToCheck(key) &&
                                    notValid &&
                                    !disableCheck(key) && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ color: 'var(--pinkDark)' }}
                                      >
                                        {key === 'FarmHoldingNumber' ||
                                        key === 'FarmIdentificationCode' ||
                                        key === 'ActivityInfo' ? (
                                          validateTextinput(
                                            farmData[key],
                                            'privalomas laukas',
                                            1
                                          )
                                        ) : (
                                          <p
                                            className="errorsBlockText error1"
                                            style={{ color: 'var(--pinkDark)' }}
                                          ></p>
                                        )}
                                      </p>
                                    )}
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                    </>
                  )}

                  <br />
                  {/* {Company data for JA} */}
                  {/*{loginType === 'Company' && (
                    <>
                      <div className="">
                        {companyDataList != null &&
                          requestData.FinancialData &&
                          requestData.RequestSystemInfo && (
                            <>
                             {requestData.RequestSystemInfo.Status !== 1 && (
                                // requestData.RequestSystemInfo.Status !== 6 && (
                                <div
                                  className={styles.titleContainer}
                                  style={{
                                    marginBottom: '15px',
                                  }}
                                >
                                  <h4>Finansiniai duomenys</h4>
                                </div>
                              )}

                        {loginType === 'Company' && requestData.RequestSystemInfo.Status !== 1 &&
                                  requestData.RequestSystemInfo.Status !== 6 && (
                    <>
                      <div className=""  style={{paddingBottom:'10px'}}>
                        {companyDataList != null &&
                          requestData.FinancialData &&
                          requestData.RequestSystemInfo && (
                            <>
                              {requestData.RequestSystemInfo.Status !== 1 &&
                                requestData.RequestSystemInfo.Status !== 6 && (
                                  <div
                                    className={styles.titleContainer}
                                    style={{ marginTop: '16px' }}
                                  >
                                    <p style={{ fontSize: '16px' }}>
                                      Finansinių atskaitomybių informacija
                                    </p>
                                  </div>
                                )}
                              {!checkIf2022FinancesToShow() && (
                                <div className="row">
                                  {requestData.RequestSystemInfo.Status !== 1 &&
                                    requestData.RequestSystemInfo.Status !==
                                      6 &&
                                    financialDataArray(
                                      companyQuestionList.FsiYear
                                    ).map((key, i) => {
                                      if (
                                        key === 'Fsi2022Submitted' ||
                                        key === 'Fsi2021Submitted' ||
                                        key === 'Fsi2020Submitted' ||
                                        key === 'Fsi2019Submitted'
                                      ) {
                                        return (
                                          <div
                                            className="col-lg-6 col-sm-12"
                                            key={i}
                                          >
                                            <label
                                              style={{
                                                margin: '8px 0 8px',
                                                color: '#597a96',
                                                fontSize: '0.8rem',
                                                lineHeight: '1.5',
                                              }}
                                            >
                                              {requestDataLabelTranslation(key)}
                                            </label>
                                            <select
                                              disabled={true}
                                              name={key}
                                              style={{
                                                background: true
                                                  ? 'rgb(241, 240, 241'
                                                  : '#fff',
                                                // color: '#2e2d2d',
                                              }}
                                              value={
                                                key === 'Fsi2022Submitted'
                                                  ? companyQuestionList.Fsi2022Submitted
                                                  : key === 'Fsi2021Submitted'
                                                  ? companyQuestionList.Fsi2021Submitted
                                                  : key === 'Fsi2019Submitted'
                                                  ? companyQuestionList.Fsi2019Submitted
                                                  : companyQuestionList.Fsi2020Submitted
                                              }
                                            >
                                              <option value={null}>Ne</option>
                                              <option value={true}>Taip</option>
                                              <option value={false}>Ne</option>
                                            </select>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            className="col-lg-6 col-sm-12"
                                            key={i}
                                          >
                                            <InputWithTextInline
                                              name={key}
                                              type={
                                                isNumberType(key)
                                                  ? 'number'
                                                  : 'string'
                                              }
                                              value={
                                                key === 'FsiEndDate' ||
                                                key === 'FsiStartDate' ||
                                                key === 'FsiEndDate2019' ||
                                                key === 'FsiStartDate2019' ||
                                                key === 'FsiEndDate2020' ||
                                                key === 'FsiStartDate2020' ||
                                                key === 'FsiEndDate2021' ||
                                                key === 'FsiStartDate2021' ||
                                                key === 'FsiEndDate2022' ||
                                                key === 'FsiStartDate2022' ||
                                                key === 'FsiEndDate2023' ||
                                                key === 'FsiStartDate2023'
                                                  ? companyQuestionList[key] &&
                                                    format(
                                                      new Date(
                                                        companyQuestionList[key]
                                                      ),
                                                      'yyyy-MM-dd'
                                                    )
                                                  : formatNumber(
                                                      companyQuestionList[key]
                                                    )
                                              }
                                              placeholder={requestDataLabelTranslation(
                                                key
                                              )}
                                              disabled={disableCheck(key)}
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              )}

                              {checkIf2022FinancesToShow() &&
                                companyQuestionList?.Fsi2022Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2022m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2022.map((key, i) => {
                                          if (key === 'Fsi2022Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2022Submitted'
                                                      ? companyQuestionList.Fsi2022Submitted
                                                      : companyQuestionList.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {checkIf2022FinancesToShow() &&
                                companyQuestionList?.Fsi2021Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (key === 'Fsi2021Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    companyQuestionList.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                      </div>
                    </>
                  )} 
                            </>
                          )}
                      </div>
                    </>
                  )}*/}
               

               {loginType === 'Company' && (
                    <>
                      <div className="">
                        {companyDataList != null &&
                          requestData.FinancialData &&
                          requestData.RequestSystemInfo && (
                            <>
                              {requestData.RequestSystemInfo.Status !== 1 && (
                                // requestData.RequestSystemInfo.Status !== 6 && (
                                <div
                                  className={styles.titleContainer}
                                  style={{
                                    marginTop: '16px',
                                    marginBottom: '15px',
                                  }}
                                >
                                  <h4>Finansiniai duomenys</h4>
                                  <FinancialTable
                                    data={requestData?.FinancialData}
                                    regDate={
                                      requestData?.CompanyData
                                        ?.CompanyRegistrationDate
                                    }
                                  />
                                </div>
                              )}

                              {companyQuestionList?.Fsi2021Submitted &&
                                checkIf2021FinancesToShow() && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (
                                            key === 'Fsi2020Submitted' ||
                                            key === 'Fsi2021Submitted'
                                          ) {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2020Submitted'
                                                      ? companyQuestionList.Fsi2020Submitted
                                                      : companyQuestionList.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate2021' ||
                                                    key === 'FsiStartDate2021'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {companyQuestionList?.Fsi2020Submitted && (
                                <>
                                  <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                    2020m. finansinės atskaitomybės duomenys{' '}
                                  </h4>
                                  <div className="row">
                                    {
                                      // requestData.RequestSystemInfo.Status !== 1 &&
                                      // requestData.RequestSystemInfo.Status !== 6 &&
                                      financialDataArray2020.map((key, i) => {
                                        if (
                                          key === 'Fsi2019Submitted' ||
                                          key === 'Fsi2020Submitted'
                                        ) {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2019Submitted'
                                                    ? companyQuestionList.Fsi2019Submitted
                                                    : companyQuestionList.Fsi2020Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else if (key === 'br') {
                                          return (
                                            <p style={{ visibility: 'hidden' }}>
                                              /
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020'
                                                    ? companyQuestionList[
                                                        key
                                                      ] &&
                                                      format(
                                                        new Date(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        ),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(
                                                        companyQuestionList[key]
                                                      )
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={disableCheck(key)}
                                              />
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                  </div>
                                </>
                              )}

                              {companyQuestionList?.Fsi2019Submitted &&
                                !checkIf2021FinancesToShow() && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2019m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2019.map((key, i) => {
                                          if (
                                            key === 'Fsi2019Submitted' ||
                                            key === 'Fsi2020Submitted'
                                          ) {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2019Submitted'
                                                      ? companyQuestionList.Fsi2019Submitted
                                                      : companyQuestionList.Fsi2020Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate2019' ||
                                                    key === 'FsiStartDate2019'
                                                      ? companyQuestionList[
                                                          key
                                                        ] &&
                                                        format(
                                                          new Date(
                                                            companyQuestionList[
                                                              key
                                                            ]
                                                          ),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        )
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                      </div>
                    </>
                  )}
                </div>
                <br />
                {/*Kita paraiškos informacija */}
                <div className="col-lg-12 col-sm-12">
                  <h4>Kita paraiškos informacija</h4>
                  {otherRequestData && (
                    <div className="row director-data-margin">
                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'CompanyPhone'}
                          type={'string'}
                          onChange={onOtherRequestInputChange}
                          value={otherRequestData['CompanyPhone']}
                          placeholder={
                            loginType === 'Company'
                              ? 'Verslo subjekto kontaktinis telefono numeris'
                              : 'Verslo subjekto kontaktinis telefono numeris'
                          }
                          disabled={disableCheck('CompanyPhone')}
                        />
                        {notValid && !disableCheck('CompanyPhone') && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validatePhone(
                              otherRequestData['CompanyPhone'],
                              'telefono numeris'
                            )}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'CompanyEmail'}
                          type={'string'}
                          onChange={onOtherRequestInputChange}
                          value={otherRequestData['CompanyEmail']}
                          //placeholder={requestDataLabelTranslation(                                'CompanyEmail'                              )}
                          placeholder={
                            loginType === 'Company'
                              ? 'Verslo subjekto el. pašto adresas'
                              : 'Verslo subjekto el. pašto adresas'
                          }
                          disabled={disableCheck('CompanyEmail')}
                        />
                        {notValid && !disableCheck('CompanyEmail') && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validateEmail(
                              otherRequestData['CompanyEmail'],
                              'elektroninis paštas'
                            )}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'ContractEmail'}
                          type={'string'}
                          onChange={onOtherRequestInputChange}
                          value={otherRequestData['ContractEmail']}
                          placeholder={requestDataLabelTranslation(
                            'ContractEmail'
                          )}
                          disabled={disableCheck('CompanyEmail')}
                        />
                        {notValid && !disableCheck('ContractEmail') && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {validateEmail(
                              otherRequestData['ContractEmail'],
                              'elektroninis paštas'
                            )}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'CompanyWeb'}
                          type={'string'}
                          onChange={onOtherRequestInputChange}
                          value={otherRequestData['CompanyWeb']}
                          // placeholder={requestDataLabelTranslation(                                'CompanyWeb'                              )}
                          placeholder={
                            loginType === 'Company'
                              ? 'Interneto puslapis'
                              : 'Interneto puslapis'
                          }
                          disabled={disableCheck('CompanyWeb')}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name={'Iban'}
                          type={'string'}
                          onChange={onOtherRequestInputChange}
                          value={otherRequestData['Iban']}
                          //placeholder={requestDataLabelTranslation('Iban')}
                          placeholder={
                            loginType === 'Company'
                              ? 'Verslo subjekto atsiskaitomoji sąskaita (nurodoma LR įsteigtoje kredito įstaigoje ar užsienio kredito įstaigos filiale, įsteigtame Lietuvoje, esanti ir pareiškėjui priklausanti atsiskaitomoji sąskaita)'
                              : 'Verslo subjekto atsiskaitomoji sąskaita (nurodoma LR įsteigtoje kredito įstaigoje ar užsienio kredito įstaigos filiale, įsteigtame Lietuvoje, esanti ir pareiškėjui priklausanti atsiskaitomoji sąskaita)'
                          }
                          disabled={disableCheck('Iban')}
                          maxLength="20"
                        />
                        {notValid && !disableCheck('Iban') && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {!isValidIban(otherRequestData['Iban']) && (
                              <p
                                className="errorsBlockText error1"
                                style={{ color: 'var(--pinkDark)' }}
                              >
                                Neteisingas formatas
                              </p>
                            )}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label
                          style={{
                            margin: '20px 0 20px',
                            color: '#597a96',
                            fontSize: '0.8rem',
                            lineHeight: '2.5',
                          }}
                        >
                          Informacija apie dalykinių santykių pobūdį su Paskolos
                          davėju
                        </label>
                        <select
                          disabled={viewWindow}
                          name="SubjectRelationId"
                          onChange={(e) => onSubjectRelationIdChange(e)}
                          style={{
                            background: viewWindow
                              ? 'rgb(241, 240, 241'
                              : '#fff',
                            // color: '#2e2d2d',
                          }}
                          value={otherRequestData.SubjectRelationId}
                        >
                          <option value={null}>{null}</option>
                          <option value={'1'}>Paskola</option>
                          <option value={'2'}>Garantija</option>
                          <option value={'3'}>Investavimas</option>
                          <option value={'4'}>Subsidija/Dotacija</option>
                          <option value={'5'}>Kita</option>
                        </select>
                        {notValid &&
                          (otherRequestData.SubjectRelationId === null ||
                            otherRequestData.SubjectRelationId.length ===
                              0) && (
                            <p
                              className="errorsBlockText error1"
                              style={{ color: 'var(--pinkDark)' }}
                            >
                              Privalote nurodyti dalykinių santykių pobūdį
                            </p>
                          )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label
                          style={{
                            margin: '13px 0 13px',
                            color: '#597a96',
                            fontSize: '0.8rem',
                          }}
                        >
                          Verslo subjekto statusas
                        </label>
                        <select
                          disabled={viewWindow}
                          name="CompanySizeType"
                          onChange={(e) => onCompanySizeTypeChange(e)}
                          style={{
                            background: viewWindow
                              ? 'rgb(241, 240, 241'
                              : '#fff',
                            // color: '#2e2d2d',
                          }}
                          value={otherRequestData.CompanySizeType}
                        >
                          <option value={null}>{null}</option>
                          <option value={'1'}>Labai maža įmonė</option>
                          <option value={'2'}>Maža įmonė</option>
                          <option value={'3'}>Vidutinė įmonė</option>
                          <option value={'4'}>Didelė įmonė</option>
                        </select>
                        {notValid &&
                          (otherRequestData.CompanySizeType === null ||
                            otherRequestData.CompanySizeType.length === 0) && (
                            <p
                              className="errorsBlockText error1"
                              style={{ color: 'var(--pinkDark)' }}
                            >
                              Privalote nurodyti įmonės statusą
                            </p>
                          )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <InputWithTextInline
                          name="NumberOfEmployees"
                          type="number"
                          onChange={onOtherRequestInputChange}
                          onKeyPress={validateItsNumber}
                          value={otherRequestData.NumberOfEmployees}
                          placeholder={rentContractTranslation('NumberOfEmployees')}
                          disabled={viewWindow}
                          style={{marginTop:'2px'}}
                        />
                        {notValid && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {isIntegerNumber(
                              otherRequestData['NumberOfEmployees']
                            )}
                          </p>
                        )}
                      </div>
                      <div
                        className="col-lg-6 col-sm-12"
                        style={{ marginTop: '10px' }}
                      >
                        <p style={{ color: '#597a96' }}>
                          {loginType === 'Company'
                            ? 'Ar Verslo subjektas, teikianti paraišką, priklauso Įmonių grupei, kaip tai nurodyta teikiamoje SVV deklaracijoje?'
                            : 'Ar Verslo subjektas, teikiantis paraišką, priklauso Įmonių grupei, kaip tai nurodyta teikiamoje SVV deklaracijoje?'}
                        </p>

                        <label
                          style={{
                            display: 'flex',
                            // width: '48.5%',
                            justifyContent: 'space-between',
                            fontSize: '0.8rem',
                            lineHeight: '0.8rem',
                            marginTop: '0.5rem',
                          }}
                        >
                          <select
                            disabled={viewWindow}
                            style={{
                              height: '39px',
                              border: '1px solid #033878',
                              borderRadius: '5px',
                              marginTop: '0.8rem',
                              background: viewWindow
                                ? 'rgb(241, 240, 241'
                                : '#fff',
                              // color: '#2e2d2d',
                            }}
                            onChange={(e) => hasParentCompany(e)}
                          >
                            <option
                              value={false}
                              selected={
                                otherRequestData &&
                                (!otherRequestData.ParentCompanyCodesFromRequest ||
                                  otherRequestData.ParentCompanyCodesFromRequest
                                    .length == 0)
                              }
                            >
                              Ne
                            </option>
                            <option
                              value={true}
                              selected={
                                otherRequestData &&
                                otherRequestData.ParentCompanyCodesFromRequest &&
                                otherRequestData.ParentCompanyCodesFromRequest
                                  .length > 0
                              }
                            >
                              Taip
                            </option>
                          </select>
                        </label>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <p
                          className="text-style-new-request"
                          style={{ color: '#597a96' }}
                        >
                          Nurodykite visus Įmonių grupei priklausančių įmonių
                          kodus, kurie nurodyti jūsų užpildytoje ir teikiamoje
                          SVV subjekto statuso deklaracijoje. Jei esate didelė
                          įmonė, nurodykite visus Įmonių grupei priklausančių
                          įmonių kodus. Pvz. 111111111, 222222222.
                        </p>
                        <Input
                          style={{ color: '#747474', marginTop:'0rem' }}
                          name="ParentCompanyCodesFromRequest"
                          type="string"
                          disabled={viewWindow || !hasParentCompanyValue}
                          onChange={onOtherRequestInputChange}
                          value={
                            otherRequestData &&
                            otherRequestData.ParentCompanyCodesFromRequest
                              ? otherRequestData.ParentCompanyCodesFromRequest
                              : ''
                          }
                          onKeyPress={(e) => {
                            if (
                              e.which !== 48 &&
                              e.which !== 49 &&
                              e.which !== 50 &&
                              e.which !== 51 &&
                              e.which !== 52 &&
                              e.which !== 53 &&
                              e.which !== 54 &&
                              e.which !== 55 &&
                              e.which !== 56 &&
                              e.which !== 57 &&
                              e.which !== 8 &&
                              e.which !== 188 &&
                              e.which !== 190 &&
                              e.which !== 44 &&
                              e.which !== 32
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />

                        {notValid && (
                          <p
                            className="errorsBlockText error1"
                            style={{ color: 'var(--pinkDark)' }}
                          >
                            {controllingCompaniesInputValidation(
                              otherRequestData.ParentCompanyCodesFromRequest,
                              !hasParentCompanyState
                            )}
                          </p>
                        )}
                      </div>
                      {/*<div className="col-lg-6 col-sm-12">
                    <div className="checkbox">
                      <label
                        className="containerLabel noBlock"
                        style={{ lineHeight: '1.2', fontSize: '0.8rem' }}
                      >
                        <input
                          type="checkbox"
                          name={'CheckCompanyAge'}
                          checked={otherRequestData['CheckCompanyAge']}
                          disabled={viewWindow}
                          onChange={(e) =>
                            onOtherRequestDataCheckBoxChange(
                              e,
                              'CheckCompanyAge'
                            )
                          }
                        />
                        <span className="checkmark"></span>
                        {requestDataLabelTranslation('CheckCompanyAge')}
                      </label>
                    </div>
                        </div>*/}{' '}
                      <div className="col-lg-6 col-sm-12">
                    <div className={styles.inlineContainer}>
                      <div className={styles.titleContainer}>
                        <p>{rentContractTranslation('ControllingCompanies')}</p>
                        {loginType === 'Company' && (
                          <p>
                            Jadis pateikti akcininkai:{' '}
                            {requestData.ControllingCompaniesFromRegister !=
                            null
                              ? requestData.ControllingCompaniesFromRegister
                              : 'nėra pateiktų akcininkų'}
                          </p>
                        )}
                      </div>
                      <input
                        className={styles.inlineInput}
                        style={{
                          background: viewWindow && '#f1f0f1',
                        }}
                        name={'ControllingCompanies'}
                        type={'string'}
                        onChange={onControllingCompaniesInputChange}
                        value={controllingCompanies}
                        disabled={viewWindow}
                        onKeyPress={(e) => {
                          if (
                            e.which !== 48 &&
                            e.which !== 49 &&
                            e.which !== 50 &&
                            e.which !== 51 &&
                            e.which !== 52 &&
                            e.which !== 53 &&
                            e.which !== 54 &&
                            e.which !== 55 &&
                            e.which !== 56 &&
                            e.which !== 57 &&
                            e.which !== 8 &&
                            e.which !== 188 &&
                            e.which !== 190 &&
                            e.which !== 44 &&
                            e.which !== 32
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {notValid && (
                        <p
                          className="errorsBlockText error1"
                          style={{ color: 'var(--pinkDark)' }}
                        >
                          {controllingCompaniesInputValidation(
                            controllingCompanies,
                            true
                          )}
                        </p>
                      )}
                      <p style={{ color: '#597a96' }}>
                      *Kontrolė suprantama, kai įmonių tarpusavio santykiai yra
                      bent vienos rūšies iš toliau išvardintų: <br />
                      a) viena įmonė turi kitos įmonės akcininkų arba narių
                      balsų daugumą; <br />
                      b) viena įmonė turi teisę paskirti arba atleisti daugumą
                      kitos įmonės administracijos, valdymo arba priežiūros
                      organo narių; <br />
                      c) pagal sutartį arba vadovaujantis steigimo sutarties ar
                      įstatų nuostata vienai įmonei suteikiama teisė daryti
                      kitai įmonei lemiamą įtaką; <br />
                      d) viena įmonė, būdama kitos įmonės akcininkė arba narė,
                      vadovaudamasi su tos įmonės kitais akcininkais ar nariais
                      sudaryta sutartimi, viena.
                    </p>
                    </div>
                  </div>{' '}
                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '20px 0 6px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      Nurodykite užsienio įmonių kodus, kurios gali turėti
                      kontrolę Jums, kaip pareiškėjui, arba kurias Jūs, kaip
                      pareiškėjas, galite kontroliuoti
                    </label>
                    <InputWithTextInline
                    style={{marginTop:'1.7rem'}}
                      name={'ControllingForeignCompanies'}
                      type="text"
                      onChange={onControllingForeignCompaniesInputChange}
                      value={controllingForeignCompanies}
                      disabled={disableCheck('ControllingForeignCompanies')}
                      onKeyPress={(e) => {
                        if (
                          e.which !== 48 &&
                          e.which !== 49 &&
                          e.which !== 50 &&
                          e.which !== 51 &&
                          e.which !== 52 &&
                          e.which !== 53 &&
                          e.which !== 54 &&
                          e.which !== 55 &&
                          e.which !== 56 &&
                          e.which !== 57 &&
                          e.which !== 8 &&
                          e.which !== 188 &&
                          e.which !== 190 &&
                          e.which !== 44 &&
                          e.which !== 32
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {notValid && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        {controlingForeignCompaiesInputValidation(
                          controllingForeignCompanies
                        )}
                      </p>
                    )}
                  </div>
                      
                    </div>
                  )}
                </div>
              </div>

              {/*{requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest.map((val, i) => {
                  return (
                    <>
                      <div
                        className={styles.titleContainer}
                        style={{ marginTop: '16px' }}
                      >
                        <h4 style={{ fontSize: '16px' }}>
                          Įmonių grupės finansinių atskaitomybių informacija
                        </h4>
                      </div>
                      <p
                        style={{
                          marginTop: '1rem',
                          marginLeft: '0',
                          fontSize: '1rem',
                        }}
                      >
                        Verslo subjekto kodas {val.CompanyCode}
                      </p>
                      {val.IsFound ? (
                        <>
                          <div
                            className="col-lg-12 col-sm-12"
                            key={i}
                            style={{ paddingLeft: '0px', paddingBottom:'20px' }}
                          >

                            <div>
                              {!checkIf2022FinancesToShow() && (
                                <div className="row">
                                  {requestData.RequestSystemInfo.Status !== 1 &&
                                    requestData.RequestSystemInfo.Status !==
                                      6 &&
                                    financialDataArray(val.FsiYear).map(
                                      (key, i) => {
                                        if (
                                          key === 'Fsi2022Submitted' ||
                                          key === 'Fsi2021Submitted' ||
                                          key === 'Fsi2020Submitted' ||
                                          key === 'Fsi2019Submitted'
                                        ) {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2022Submitted'
                                                    ? val.Fsi2022Submitted
                                                    : key === 'Fsi2021Submitted'
                                                    ? val.Fsi2021Submitted
                                                    : key === 'Fsi2019Submitted'
                                                    ? val.Fsi2019Submitted
                                                    : val.Fsi2020Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate' ||
                                                  key === 'FsiStartDate' ||
                                                  key === 'FsiEndDate2019' ||
                                                  key === 'FsiStartDate2019' ||
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020' ||
                                                  key === 'FsiEndDate2021' ||
                                                  key === 'FsiStartDate2021' ||
                                                  key === 'FsiEndDate2022' ||
                                                  key === 'FsiStartDate2022' ||
                                                  key === 'FsiEndDate2023' ||
                                                  key === 'FsiStartDate2023'
                                                    ? val[key] &&
                                                      format(
                                                        new Date(val[key]),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(val[key])
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={disableCheck(key)}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              )}

                              {checkIf2022FinancesToShow() &&
                                val?.Fsi2022Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2022m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2022.map((key, i) => {
                                          if (key === 'Fsi2022Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2022Submitted'
                                                      ? val.Fsi2022Submitted
                                                      : val.Fsi2021Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}

                              {checkIf2022FinancesToShow() &&
                                val?.Fsi2021Submitted && (
                                  <>
                                    <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                      2021m. finansinės atskaitomybės duomenys{' '}
                                    </h4>
                                    <div className="row">
                                      {
                                        // requestData.RequestSystemInfo.Status !== 1 &&
                                        // requestData.RequestSystemInfo.Status !== 6 &&
                                        financialDataArray2021.map((key, i) => {
                                          if (key === 'Fsi2021Submitted') {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={val.Fsi2021Submitted}
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else if (key === 'br') {
                                            return (
                                              <p
                                                style={{ visibility: 'hidden' }}
                                              >
                                                /
                                              </p>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled={disableCheck(key)}
                                                />
                                              </div>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                      )}
                    </>
                  );
                })}*/}
                {requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest !== null &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest && (
                  <h4 style={{ fontSize: '16px', marginTop: '0px' }}>
                    Įmonių grupės finansinių atskaitomybių informacija
                  </h4>
                )}

              {/* ControllingCompaniesFromRequest */}
              {requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest.map((val, i) => {
                  return (
                    <>
                      <div
                        className={styles.titleContainer}
                        style={{ marginTop: '16px' }}
                      ></div>

                      <p
                        style={{
                          marginTop: '1rem',
                          marginLeft: '0',
                          fontSize: '1rem',
                        }}
                      >
                        Verslo subjekto kodas {val.CompanyCode}
                      </p>
                      {val.IsFound ? (
                        <>
                          <div
                            className="col-lg-12 col-sm-12"
                            key={i}
                            style={{ paddingLeft: '0px' }}
                          ></div>

                          <FinancialTable
                            data={val?.Financials}
                            regDate={val.RegistrationDate}
                          />
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>
                          Įmonė nerasta arba jos duomenys dar nepatikrinti.
                        </p>
                      )}
                    </>
                  );
                })}
              <br/>

              {/* Pep AML data */}
              <h4>AML ir PEP </h4>
              <h4>PEP </h4>

              {pepData && (
                <div>
                  <div style={{ width: '48.5%' }}>
                    <p className="text-style-new-request">
                      {loginType === 'Company'
                        ? 'Ar Verslo subjekto vadovui, atstovui, naudos gavėjams ar jų artimiesiems šeimos nariams[1] arba artimiesiems pagalbininkams[2] šiuo metu arba per paskutinius 12 mėnesių patikėtos svarbios viešosios pareigos (PEP)?[3]'
                        : 'Ar Verslo subjekto vadovui, atstovui, naudos gavėjams ar jų artimiesiems šeimos nariams[1] arba artimiesiems pagalbininkams[2] šiuo metu arba per paskutinius 12 mėnesių patikėtos svarbios viešosios pareigos (PEP)?[3]'}
                    </p>

                    <label
                      style={{
                        display: 'flex',
                        // width: '48.5%',
                        justifyContent: 'space-between',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '0.8rem',
                        marginTop: '1.5rem',
                      }}
                    >
                      <select
                        disabled={viewWindow}
                        name="HasImportantResponsibilities"
                        onChange={onHasImportantResponsibilities}
                        style={{
                          background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                          // color: '#2e2d2d',
                        }}
                        value={pepData.HasImportantResponsibilities}
                      >
                        <option value={false}>Ne</option>
                        <option value={true}>Taip</option>
                      </select>
                    </label>
                  </div>

                  <p className="text-style-new-request">
                    Jei pažymėjote "taip", nurodykite asmenis ir jų informaciją:
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-seven">
                      Kam patikėtos svarbios viešosios pareigos?
                    </span>
                    <span className="col-seven">Vardas</span>
                    <span className="col-seven">Pavardė</span>
                    <span className="col-seven">Gimimo data</span>
                    <span className="col-seven">PEP pareigos</span>
                    <span className="col-seven">
                      Institucija, kurioje eina pareigas
                    </span>
                    <span className="col-seven">
                      Valstybė, kurioje asmuo yra PEP
                    </span>
                    {/*<span className="col-lg-2 col-sm-2">
                      Asmens ryšys su Verslo subjekte veikiančiais asmenimis
                      </span>*/}
                  </div>
                  {/*{console.log(pepData.ImportantResponsibilityPersons)}*/}

                  {notValid &&
                    suppliers &&
                    pepData.HasImportantResponsibilities &&
                    areAllValuesFalsy(
                      pepData.ImportantResponsibilityPersons
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Privalote užpildyti bent vieną asmenį
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      0
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      1
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      2
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      3
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    checkResponsibilityPersonsLinesFullyFilled(
                      pepData.ImportantResponsibilityPersons,
                      4
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą pradėtą asmens arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ImportantResponsibilityPersons &&
                      pepData.ImportantResponsibilityPersons.map((obj, k) =>
                        Object.keys(obj).map((p, i) => {
                          if (p === 'country') {
                            return (
                              <div className="col-seven" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="country"
                                >
                                  <select
                                    name="country"
                                    onChange={(e) =>
                                      onImportantResponsibilitiesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData
                                        .ImportantResponsibilityPersons?.[k]
                                        ?.country) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ImportantResponsibilityPersons?.[k]
                                            ?.country === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ImportantResponsibilityPersons?.[k]
                                            ?.country == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'positionId') {
                            return (
                              <div className="col-seven" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="positionId"
                                >
                                  <select
                                    name="positionId"
                                    onChange={(e) =>
                                      onImportantResponsibilitiesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                    value={
                                      requestData.PepData
                                        .ImportantResponsibilityPersons?.[k]
                                        ?.positionId
                                    }
                                  >
                                    <option value={null}>{null}</option>
                                    <option value={'1'}>Man pačiam</option>
                                    <option value={'2'}>
                                      Artimam šeimos nariui
                                    </option>
                                    <option value={'3'}>
                                      Artimam pagalbininkui
                                    </option>
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'pepPositionId') {
                            return (
                              <div className="col-seven" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="pepPositionId"
                                >
                                  <select
                                    name="pepPositionId"
                                    onChange={(e) =>
                                      onImportantResponsibilitiesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                    value={
                                      requestData.PepData
                                        .ImportantResponsibilityPersons?.[k]
                                        ?.pepPositionId
                                    }
                                  >
                                    <option value={null}>{null}</option>
                                    <option value={'1'}>
                                      Valstybės vadovas, vyriausybės vadovas,
                                      ministras, viceministras arba ministro
                                      pavaduotojas, valstybės sekretorius,
                                      parlamento, vyriausybės arba ministerijos
                                      kancleris
                                    </option>
                                    <option value={'2'}>
                                      Parlamento narys
                                    </option>
                                    <option value={'3'}>
                                      Aukščiausiųjų teismų, konstitucinių teismų
                                      ar kitų aukščiausiųjų teisminių
                                      institucijų, kurių sprendimai negali būti
                                      skundžiami, narys
                                    </option>
                                    <option value={'4'}>
                                      Savivaldybės meras, savivaldybės
                                      administracijos direktorius
                                    </option>
                                    <option value={'5'}>
                                      Aukščiausiosios valstybių audito ir
                                      kontrolės institucijos valdymo organo
                                      narys ar centrinio banko valdybos
                                      pirmininkas, jo pavaduotojas ar valdybos
                                      narys
                                    </option>
                                    <option value={'6'}>
                                      Ambasadorius, laikinasis reikalų
                                      patikėtinis, Lietuvos kariuomenės vadas,
                                      kariuomenės pajėgų ir junginių vadai,
                                      Gynybos štabo viršininkas ar užsienio
                                      valstybių aukšto rango ginkluotųjų pajėgų
                                      karininkas
                                    </option>
                                    <option value={'7'}>
                                      Valstybės įmonės, akcinės bendrovės,
                                      uždarosios akcinės bendrovės, kurių
                                      akcijos ar dalis akcijų, suteikiančių
                                      daugiau kaip 1/2 visų balsų šių bendrovių
                                      visuotiniame akcininkų susirinkime,
                                      priklauso valstybei nuosavybės teise,
                                      valdymo ar priežiūros organo narys
                                    </option>
                                    <option value={'8'}>
                                      Savivaldybės įmonės, akcinės bendrovės,
                                      uždarosios akcinės bendrovės, kurių
                                      akcijos ar dalis akcijų, suteikiančių
                                      daugiau kaip 1/2 visų balsų šių bendrovių
                                      visuotiniame akcininkų susirinkime,
                                      priklauso savivaldybei nuosavybės teise ir
                                      kurios laikomos didelėmis įmonėmis pagal
                                      Lietuvos Respublikos įmonių finansinės
                                      atskaitomybės įstatymą, valdymo ar
                                      priežiūros organo narys
                                    </option>
                                    <option value={'9'}>
                                      Tarptautinės tarpvyriausybinės
                                      organizacijos vadovas, jo pavaduotojas,
                                      valdymo ar priežiūros organo narys
                                    </option>
                                    <option value={'10'}>
                                      Politinės partijos vadovas, jo
                                      pavaduotojas, valdymo organo narys
                                    </option>
                                  </select>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col-seven" key={i}>
                                <Input
                                  key={i}
                                  name={p}
                                  type="string"
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onImportantResponsibilitiesChange(k, p, e)
                                  }
                                  value={obj[p]}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                  </div>
                  <div className="checkbox">
                    <label
                      className="containerLabel noBlock"
                      style={{ lineHeight: '1.2', fontSize: '0.8rem' }}
                    >
                      <input
                        type="checkbox"
                        name="HasImportantResponsibilitiesMoreThan4"
                        checked={
                          pepData &&
                          pepData.HasImportantResponsibilitiesMoreThan4
                        }
                        onChange={(e) =>
                          onHasImportantResponsibilitiesMoreThan4Change(e)
                        }
                        disabled={viewWindow}
                      />
                      <span className="checkmark"></span>
                      {loginType === 'Company'
                        ? 'Ar yra daugiau Verslo subjekto vadovų, atstovų, naudos gavėjų ar jų artimųjų šeimos narių arba artimųjų pagalbininkų, kuriems šiuo metu arba per paskutinius 12 mėnesių patikėtos svarbios viešosios pareigos (PEP)?'
                        : 'Ar yra daugiau Verslo subjekto vadovų, atstovų, naudos gavėjų ar jų artimųjų šeimos narių arba artimųjų pagalbininkų, kuriems šiuo metu arba per paskutinius 12 mėnesių patikėtos svarbios viešosios pareigos (PEP)?'}
                    </label>
                  </div>
                  <h4>Akcininkai</h4>
                  <div style={{ width: '48.5%' }}>
                    <p className="text-style-new-request">
                      {loginType === 'Company'
                        ? 'Ar yra įmonėje akcininkų fizinių asmenų kurie  tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų įmonės daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie tiesiogiai ar netiesiogiai kontroliuoja jūsų įmonę kitais būdais?'
                        : 'Ar yra įmonėje akcininkų fizinių asmenų kurie  tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų įmonės daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie tiesiogiai ar netiesiogiai kontroliuoja jūsų įmonę kitais būdais?'}
                    </p>

                    <label
                      style={{
                        display: 'flex',
                        // width: '48.5%',
                        justifyContent: 'space-between',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '0.8rem',
                        marginTop: '1.5rem',
                      }}
                    >
                      <select
                        disabled={viewWindow}
                        name="HasControllingPersons"
                        onChange={onHasControllingPersons}
                        style={{
                          background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                          // color: '#2e2d2d',
                        }}
                        value={pepData.HasControllingPersons}
                      >
                        <option value={false}>Ne</option>
                        <option value={true}>Taip</option>
                      </select>
                    </label>
                  </div>
                  <p
                    className="text-style-new-request"
                    style={{ marginLeft: '0px' }}
                  >
                    {loginType === 'Company'
                      ? 'Nurodykite 4 pagrindinius akcininkus (jeigu tiek yra) fizinius asmenis, kurie tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų įmonę daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie tiesiogiai ar netiesiogiai kontroliuoja jūsų įmonę kitais būdais'
                      : 'Nurodykite 4 pagrindinius akcininkus (jeigu tiek yra) fizinius asmenis, kurie tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų Verslo subjektą daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie tiesiogiai ar netiesiogiai kontroliuoja jūsų Verslo subjektą kitais būdais'}
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-eleven">Vardas</span>
                    <span className="col-eleven">Pavardė</span>
                    <span className="col-eleven">
                      Pilietybė<sup>*</sup>
                    </span>
                    <span className="col-eleven">
                      Asmens kodas (jei ne Lietuvos pilietis - gimimo data)
                    </span>
                    <span className="col-eleven">Pareigos</span>
                    <span className="col-eleven">Valdymas</span>
                    <span className="col-eleven">Akcijų dalis, proc.</span>
                    <span className="col-eleven">
                      Balsavimo teisių dalis, proc.
                    </span>
                    <span className="col-eleven">
                      Rezidavimo valstybė mokesčių tikslais
                    </span>
                    <span className="col-eleven">
                      Mokesčių mokėtojo valstybė
                    </span>
                    <span className="col-eleven">Mokesčių mokėtojo kodas</span>
                  </div>
                  {/*{notValid &&
                    checkControllingPersons(pepData.ControllingPersons) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Užpildykite asmenis ir jų informaciją
                      </p>
                    )}*/}
                  {notValid &&
                    suppliers &&
                    pepData.HasControllingPersons &&
                    areAllValuesFalsy(pepData.ControllingPersons) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Privalote užpildyti bent vieną asmenį
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      0
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      1
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      2
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      3
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      4
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingPersons &&
                    checkControlingPersonsLinesFullyFilled(
                      pepData.ControllingPersons,
                      5
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą asmens informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}

                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ControllingPersons &&
                      pepData.ControllingPersons.map((obj, k) =>
                        Object.keys(obj).map((p, i) => {
                          if (p === 'citizenshipId') {
                            return (
                              <div
                                className="col-eleven padding-column"
                                key={i}
                              >
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="citizenshipId"
                                >
                                  <select
                                    name="citizenshipId"
                                    onChange={(e) =>
                                      onControllingPersonsChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData.ControllingPersons?.[
                                        k
                                      ]?.citizenshipId) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.citizenshipId === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.citizenshipId == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'stateOfResidenceForTaxPurposesId') {
                            return (
                              <div
                                className="col-eleven padding-column"
                                key={i}
                              >
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="stateOfResidenceForTaxPurposesId"
                                >
                                  <select
                                    name="stateOfResidenceForTaxPurposesId"
                                    onChange={(e) =>
                                      onControllingPersonsChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData.ControllingPersons?.[
                                        k
                                      ]?.stateOfResidenceForTaxPurposesId) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.stateOfResidenceForTaxPurposesId ===
                                          null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.stateOfResidenceForTaxPurposesId ==
                                          val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'stateOfTheTaxpayerId') {
                            return (
                              <div
                                className="col-eleven padding-column"
                                key={i}
                              >
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="stateOfTheTaxpayerId"
                                >
                                  <select
                                    name="stateOfTheTaxpayerId"
                                    onChange={(e) =>
                                      onControllingPersonsChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData.ControllingPersons?.[
                                        k
                                      ]?.stateOfTheTaxpayerId) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.stateOfTheTaxpayerId === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingPersons?.[k]
                                            ?.stateOfTheTaxpayerId == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'personControlId') {
                            return (
                              <div
                                className="col-eleven padding-column"
                                key={i}
                              >
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="personControlId"
                                >
                                  <select
                                    name="personControlId"
                                    onChange={(e) =>
                                      onControllingPersonsChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                    value={
                                      requestData.PepData.ControllingPersons?.[
                                        k
                                      ]?.personControlId
                                    }
                                  >
                                    <option value={null}>{null}</option>
                                    <option value={'1'}>Tiesiogiai</option>
                                    <option value={'2'}>Netiesiogiai</option>
                                    <option value={'3'}>
                                      Per patikos struktūrą/fondą
                                    </option>
                                  </select>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="col-eleven padding-column"
                                key={i}
                              >
                                <Input
                                  key={i}
                                  name={p}
                                  type={
                                    p === 'sharesPart' || p === 'votingPart'
                                      ? 'number'
                                      : 'string'
                                  }
                                  onKeyUp={(e) => enforceMinMax(e)}
                                  onKeyPress={(e) => {
                                    if (
                                      e.which === 101 &&
                                      (p === 'sharesPart' || p === 'votingPart')
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onControllingPersonsChange(k, p, e)
                                  }
                                  value={obj[p]}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                  </div>
                  <p className="specify-info" style={{ color: '#597a96' }}>
                    <sup>*</sup> jeigu asmuo be pilietybės – nurodoma valstybė,
                    kuri išdavė asmens tapatybę patvirtinantį dokumentą
                  </p>
                  <br />
                  <br />
                  {/*<p className="text-style-new-request">
                  
                    Nurodykite įmones, kurios tiesiogiai ar netiesiogiai turi
                    arba kontroliuoja jūsų Verslo subjekto daugiau negu 25 proc.
                    akcijų ar balsavimo teisių
                      </p>*/}

                  <div style={{ width: '48.5%' }}>
                    <p className="text-style-new-request">
                      {loginType === 'Company'
                        ? 'Ar įmonėje yra akcininkų juridinių asmenų kurie  tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų įmonės daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie tiesiogiai ar netiesiogiai kontroliuoja jūsų įmonę kitais būdais?'
                        : 'Ar įmonėje yra akcininkų juridinių asmenų kurie  tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų įmonės daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie tiesiogiai ar netiesiogiai kontroliuoja jūsų įmonę kitais būdais? '}
                    </p>

                    <label
                      style={{
                        display: 'flex',
                        // width: '48.5%',
                        justifyContent: 'space-between',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '0.8rem',
                        marginTop: '1.5rem',
                      }}
                    >
                      <select
                        disabled={viewWindow}
                        name="HasControllingCompanies"
                        onChange={onHasControllingCompanies}
                        style={{
                          background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                          // color: '#2e2d2d',
                        }}
                        value={pepData.HasControllingCompanies}
                      >
                        <option value={false}>Ne</option>
                        <option value={true}>Taip</option>
                      </select>
                    </label>
                  </div>
                  <p className="text-style-new-request">
                    {loginType === 'Company'
                      ? 'Jeigu nurodėte įmonę (-es), kuri(-os) tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų įmonę daugiau negu 25 proc. akcijų ar balsavimo teisių, būtina užpildyti aukščiau prašomą informaciją apie fizinius asmenis - netiesioginius kliento akcininkus (dalyvius) arba asmenis, kitaip kontroliuojančius įmonės valdymą.'
                      : 'Jeigu nurodėte įmonę (-es), kuri(-os) tiesiogiai ar netiesiogiai turi arba kontroliuoja jūsų Verslo subjektą daugiau negu 25 proc. akcijų ar balsavimo teisių, būtina užpildyti aukščiau prašomą informaciją apie fizinius asmenis - netiesioginius kliento akcininkus (dalyvius) arba asmenis, kitaip kontroliuojančius kliento valdymą.'}
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-nine">Įmonės pavadinimas</span>
                    <span className="col-nine">Įmonės kodas</span>
                    <span className="col-nine">Įmonės registracijos šalis</span>
                    <span className="col-nine">Akcijų dalis, proc.</span>
                    <span className="col-nine">
                      Balsavimo teisių dalis, proc.
                    </span>
                    <span className="col-nine">
                      Patvirtinimas dėl VP prekybos biržoje
                    </span>
                    <span className="col-nine">Biržos pavadinimas</span>
                    <span className="col-nine">Biržos šalis</span>
                    <span className="col-nine">Biržos ISIN* nr.</span>
                  </div>
                  {notValid &&
                    suppliers &&
                    pepData.HasControllingCompanies &&
                    areAllValuesFalsy(pepData.ControllingCompanies) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Privalote užpildyti bent vieną asmenį
                      </p>
                    )}
                  {/*} {notValid &&
                    checkControllingCompanies(pepData.ControllingCompanies) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Užpildykite asmenis ir jų informaciją
                      </p>
                    )}*/}

                  {notValid &&
                    suppliers &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      0
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą įmonės informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      1
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą įmonės informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      2
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą įmonės informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      3
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą įmonės informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      4
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą įmonės informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  {notValid &&
                    suppliers &&
                    pepData.ControllingCompanies &&
                    checkControlingCompaniesLinesFullyFilled(
                      pepData.ControllingCompanies,
                      5
                    ) && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Pilnai užpildykite pradėtą įmonės informaciją arba
                        pašalinkite pradėtą eilutę
                      </p>
                    )}
                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ControllingCompanies &&
                      pepData.ControllingCompanies.map((obj, k) =>
                        Object.keys(obj).map((p, i) => {
                          if (p === 'country') {
                            return (
                              <div className="col-nine padding-column" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="country"
                                >
                                  <select
                                    name="country"
                                    onChange={(e) =>
                                      onControllingCompaniesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData
                                        .ControllingCompanies?.[k]
                                        ?.country) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingCompanies?.[k]
                                            ?.country === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingCompanies?.[k]
                                            ?.country == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'stockApprove') {
                            return (
                              <div className="col-nine padding-column" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="stockApprove"
                                >
                                  <select
                                    name="stockApprove"
                                    onChange={(e) =>
                                      onControllingCompaniesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    <option
                                      selected={
                                        requestData.PepData
                                          .ControllingCompanies?.[k] &&
                                        requestData.PepData
                                          .ControllingCompanies?.[k]
                                          ?.stockApprove == null
                                      }
                                      value={null}
                                    >
                                      {null}
                                    </option>
                                    <option
                                      value={true}
                                      selected={
                                        requestData.PepData
                                          .ControllingCompanies?.[k]
                                          ?.stockApprove == true
                                      }
                                    >
                                      Taip
                                    </option>

                                    <option
                                      value={false}
                                      selected={
                                        requestData.PepData
                                          .ControllingCompanies?.[k]
                                          ?.stockApprove == false
                                      }
                                    >
                                      Ne
                                    </option>
                                  </select>
                                </div>
                              </div>
                            );
                          } else if (p === 'stockCountryId') {
                            return (
                              <div className="col-nine padding-column" key={i}>
                                <div
                                  // className="col-lg-12 col-sm-12"

                                  className="stockCountryId"
                                >
                                  <select
                                    name="stockCountryId"
                                    onChange={(e) =>
                                      onControllingCompaniesChange(k, p, e)
                                    }
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                      height: 'auto',
                                      marginTop: '0.5rem',
                                    }}
                                  >
                                    {(!viewWindow ||
                                      !requestData.PepData
                                        .ControllingCompanies?.[k]
                                        ?.stockCountryId) && (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingCompanies?.[k]
                                            ?.stockCountryId === null
                                        }
                                        value={null}
                                      >
                                        {null}
                                      </option>
                                    )}

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          requestData.PepData
                                            .ControllingCompanies?.[k]
                                            ?.stockCountryId == val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col-nine padding-column" key={i}>
                                <Input
                                  key={i}
                                  name={p}
                                  onKeyUp={(e) => enforceMinMax(e)}
                                  type={
                                    p === 'sharesPart' || p === 'votingPart'
                                      ? 'number'
                                      : 'string'
                                  }
                                  onKeyPress={(e) => {
                                    if (
                                      e.which === 101 &&
                                      (p === 'sharesPart' || p === 'votingPart')
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  disabled={viewWindow}
                                  onChange={(e) =>
                                    onControllingCompaniesChange(k, p, e)
                                  }
                                  value={obj[p]}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                  </div>
                </div>
              )}
              <p className="specify-info" style={{ color: '#597a96' }}>
                <sup>*</sup> Vertybinių popierių identifikavimo nr. 12 sk.
              </p>

              <br />
              <br />

              <h4>Veiklos vykdymo regionas</h4>
              <div>
                <div>
                  <p className="text-style-new-request">
                    Nurodykite pagrindinius vertinamo projekto verslo partnerių
                    veiklos vykdymo regionus
                  </p>
                  <RequestMultiSelect
                      placeholder={''}
                      onChange={(e) => onMultiSelectChange2(e)}
                      defaultValue={
                        activityRegionIds?.map((id) =>
                        activityRegionOptions.find(
                            (option) => option.value === id
                          )
                        ) || []
                      }
                      value={
                        activityRegionIds?.map((id) =>
                        activityRegionOptions.find(
                            (option) => option.value === id
                          )
                        ) || []
                      }
                      options={activityRegionOptions}
                      disabled={false}
                    />
                    {notValid &&
                      activityRegionIds?.length === 0 && (
                        <p
                          className="errorsBlockText error1"
                          style={{ color: 'var(--pinkDark)' }}
                        >
                          Privalote pasirinkti veiklos vykdymo regioną
                        </p>
                      )}
                </div>
              </div>

              <br />
              <br />

              {/* {Tiekėjo (-ų) informacija start} */}
              <h4>Pagrindinių partnerių/tiekėjų informacija</h4>

              <div
                className="row table-style-header"
                style={{ marginLeft: '0px' }}
              >
                {' '}
                <span className="col-five">
                  Partnerio/tiekėjo įmonės/asmens pavadinimas
                </span>
                <span className="col-five">
                  Partnerio/tiekėjo įmonės/asmens kodas
                </span>
                <span className="col-five">Įmonės registracijos šalis</span>
                <span className="col-five">
                  Tiekėjo vykdoma veikla (pagal EVRK2)
                </span>
                <span className="col-five">Mokėjimų pagrindas </span>
              </div>
              {checkSuppliers(suppliers)}
              {notValid && suppliers && checkSuppliers(suppliers) && (
                <p
                  className="errorsBlockText error1"
                  style={{ color: 'var(--pinkDark)' }}
                >
                  Privalote užpildyti bent vieną tiekėją
                </p>
              )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled1(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled2(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled3(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                suppliers &&
                !checkSuppliers(suppliers) &&
                checkIfAllSuppliersLinesFullyFilled4(suppliers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą tiekėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              <div
                className="row inputs-block"
                style={{ marginLeft: '0px', marginBottom: '20px' }}
              >
                {suppliers &&
                  suppliers?.map((obj, k) =>
                    Object.keys(obj).map((p, i) => {
                      if (p === 'registrationCountryId') {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <div
                              // className="col-lg-12 col-sm-12"

                              className="registrationCountryIdInput"
                            >
                              <select
                                name="registrationCountryId"
                                onChange={(e) => onSuppliersChange(k, p, e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  height: 'auto',
                                }}
                              >
                                {(!viewWindow ||
                                  !requestData?.Suppliers?.[k]
                                    ?.registrationCountryId) && (
                                  <option
                                    selected={
                                      requestData?.Suppliers?.[k]
                                        ?.registrationCountryId === null
                                    }
                                    value={null}
                                  >
                                    {null}
                                  </option>
                                )}

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      requestData?.Suppliers?.[k]
                                        ?.registrationCountryId == val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      } else if (p === 'evrk') {
                        return (
                          <div
                            className="col-five padding-column registrationCountryIdInput"
                            key={i}
                          >
                            <select
                              name="evrk"
                              onChange={(e) => onSuppliersChange(k, p, e)}
                              disabled={viewWindow}
                              style={{
                                background: viewWindow
                                  ? 'rgb(241, 240, 241'
                                  : '#fff',
                                height: 'auto',
                              }}
                            >
                              {(!viewWindow ||
                                !requestData?.Suppliers?.[k]?.evrk) && (
                                <option
                                  selected={
                                    requestData?.Suppliers?.[k]?.evrk === null
                                  }
                                  value={null}
                                >
                                  {null}
                                </option>
                              )}

                              {Object.keys(evrkFullWithZeros).map((val, j) => (
                                <option
                                  key={j}
                                  selected={
                                    requestData?.Suppliers?.[k]?.evrk == val
                                  }
                                  value={val}
                                >
                                  {`${val} - ${evrkFullWithZeros[val]}`}
                                </option>
                              ))}

                              {Object.keys(evrkFullWithoutZeros).map(
                                (val, j) => (
                                  <option
                                    key={j}
                                    selected={
                                      requestData?.Suppliers?.[k]?.evrk == val
                                    }
                                    value={val}
                                  >
                                    {`${val} - ${evrkFullWithoutZeros[val]}`}
                                  </option>
                                )
                              )}

                              {viewWindow && (
                                <option
                                  selected={true}
                                  value={requestData?.Suppliers?.[k]?.evrk}
                                >
                                  {requestData?.Suppliers?.[k]?.evrk
                                    ? `${requestData?.Suppliers?.[k]?.evrk} - ${
                                        evrkFull[
                                          requestData?.Suppliers?.[k]?.evrk
                                        ]
                                      }`
                                    : '-'}
                                </option>
                              )}
                            </select>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <Input
                              key={i}
                              name={p}
                              //type={p === 'code' ? 'number' : 'string'}
                              type={p === 'code' ? 'string' : 'string'} //reikia ir raidziu
                              disabled={viewWindow}
                              onChange={(e) => onSuppliersChange(k, p, e)}
                              value={obj[p]}
                            />
                          </div>
                        );
                      }
                    })
                  )}
                {/*<p
    className="text-style-new-request"
    style={{ marginLeft: '10px' }}
  >
    PASTABA: turi būti pateikti 5 pagrindiniai tiekėjai. Esant
    mažiau, teikiamas mažesnis skaičius, bet ne mažiau nei 1
    tiekėjas.
    </p>*/}
              </div>

              {/* {Pirkėjo (-ų) informacija start} */}
              <h4>Pagrindinių partnerių/pirkėjų informacija</h4>

              <div
                className="row table-style-header"
                style={{ marginLeft: '0px' }}
              >
                {' '}
                <span className="col-five">
                  Partnerio/pirkėjo įmonės/asmens pavadinimas
                </span>
                <span className="col-five">
                  Partnerio/pirkėjo įmonės/asmens kodas
                </span>
                <span className="col-five">Įmonės registracijos šalis</span>
                <span className="col-five">
                  Pirkėjo vykdoma veikla (pagal EVRK2)
                </span>
                <span className="col-five">Mokėjimų pagrindas </span>
              </div>
              {checkBuyers(buyers)}
              {notValid && buyers && checkBuyers(buyers) && (
                <p
                  className="errorsBlockText error1"
                  style={{ color: 'var(--pinkDark)' }}
                >
                  Privalote užpildyti bent vieną pirkėją
                </p>
              )}
              {notValid &&
                buyers &&
                !checkBuyers(buyers) &&
                checkIfAllBuyersLinesFullyFilled1(buyers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą pirkėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                buyers &&
                !checkBuyers(suppliers) &&
                checkIfAllBuyersLinesFullyFilled2(buyers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą pirkėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                buyers &&
                !checkBuyers(buyers) &&
                checkIfAllBuyersLinesFullyFilled3(buyers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą pirkėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              {notValid &&
                buyers &&
                !checkBuyers(buyers) &&
                checkIfAllBuyersLinesFullyFilled4(buyers) && (
                  <p
                    className="errorsBlockText error1"
                    style={{ color: 'var(--pinkDark)' }}
                  >
                    Pilnai užpildykite pradėtą pirkėją arba pašalinkite pradėtą
                    eilutę
                  </p>
                )}
              <div
                className="row inputs-block"
                style={{ marginLeft: '0px', marginBottom: '20px' }}
              >
                {buyers &&
                  buyers?.map((obj, k) =>
                    Object.keys(obj).map((p, i) => {
                      if (p === 'registrationCountryId') {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <div
                              // className="col-lg-12 col-sm-12"

                              className="registrationCountryIdInput"
                            >
                              <select
                                name="registrationCountryId"
                                onChange={(e) => onBuyersChange(k, p, e)}
                                disabled={viewWindow}
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  height: 'auto',
                                }}
                              >
                                {(!viewWindow ||
                                  !requestData?.Buyers?.[k]
                                    ?.registrationCountryId) && (
                                  <option
                                    selected={
                                      requestData?.Buyers?.[k]
                                        ?.registrationCountryId === null
                                    }
                                    value={null}
                                  >
                                    {null}
                                  </option>
                                )}

                                {formatCountryJson.map((val) => (
                                  <option
                                    selected={
                                      requestData?.Buyers?.[k]
                                        ?.registrationCountryId == val.value
                                    }
                                    value={val.value}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      } else if (p === 'evrk') {
                        return (
                          <div
                            className="col-five padding-column registrationCountryIdInput"
                            key={i}
                          >
                            <select
                              name="evrk"
                              onChange={(e) => onBuyersChange(k, p, e)}
                              disabled={viewWindow}
                              style={{
                                background: viewWindow
                                  ? 'rgb(241, 240, 241'
                                  : '#fff',
                                height: 'auto',
                              }}
                            >
                              {(!viewWindow ||
                                !requestData?.Buyers?.[k]?.evrk) && (
                                <option
                                  selected={
                                    requestData?.Buyers?.[k]?.evrk === null
                                  }
                                  value={null}
                                >
                                  {null}
                                </option>
                              )}

                              {Object.keys(evrkFullWithZeros).map((val, j) => (
                                <option
                                  key={j}
                                  selected={
                                    requestData?.Buyers?.[k]?.evrk == val
                                  }
                                  value={val}
                                >
                                  {`${val} - ${evrkFullWithZeros[val]}`}
                                </option>
                              ))}

                              {Object.keys(evrkFullWithoutZeros).map(
                                (val, j) => (
                                  <option
                                    key={j}
                                    selected={
                                      requestData?.Buyers?.[k]?.evrk == val
                                    }
                                    value={val}
                                  >
                                    {`${val} - ${evrkFullWithoutZeros[val]}`}
                                  </option>
                                )
                              )}

                              {viewWindow && (
                                <option
                                  selected={true}
                                  value={requestData?.Buyers?.[k]?.evrk}
                                >
                                  {requestData?.Buyers?.[k]?.evrk
                                    ? `${requestData?.Buyers?.[k]?.evrk} - ${
                                        evrkFull[requestData?.Buyers?.[k]?.evrk]
                                      }`
                                    : '-'}
                                </option>
                              )}
                            </select>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <Input
                              key={i}
                              name={p}
                              //type={p === 'code' ? 'number' : 'string'}
                              type={p === 'code' ? 'string' : 'string'} //reikia ir raidziu
                              disabled={viewWindow}
                              onChange={(e) => onBuyersChange(k, p, e)}
                              value={obj[p]}
                            />
                          </div>
                        );
                      }
                    })
                  )}
                {/*<p
    className="text-style-new-request"
    style={{ marginLeft: '10px' }}
  >
    PASTABA: turi būti pateikti 5 pagrindiniai pirkėjai. Esant
    mažiau, teikiamas mažesnis skaičius, bet ne mažiau nei 1
    pirkėjas.
    </p>*/}
              </div>

              <h4>
                Pareiškėjo pastabos dėl AML/PEP kurios nebuvo aprašytos aukščiau
              </h4>
              <div>
                <div>
                  <label
                    style={{
                      display: 'flex',
                      // width: '48.5%',
                      justifyContent: 'space-between',
                      color: '#597a96',
                      fontSize: '0.8rem',
                      lineHeight: '0.8rem',
                      marginTop: '1.5rem',
                    }}
                  >
                    <textarea
                      style={{ width: '100%', height: '100px', padding: '7px' }}
                      //placeholder="Invega komentaras"
                      name="Comment"
                      type="string"
                      disabled={viewWindow}
                      value={aMLPEPcomment}
                      onChange={onAMLPEPcommentChange}
                    />
                  </label>
                </div>
              </div>

              <br />
              <br />
              <h4>Deklaracijos apie gautą valstybės pagalbą duomenys</h4>
              <div style={{ width: '100%' }}>
                <p className="text-style-new-request">
                  Deklaracijos apie gautą valstybės pagalbą pagal 2020 m. kovo
                  19 d. Europos Komisijos komunikatą dėl laikinosios valstybės
                  pagalbos priemonių, skirtų ekonomikai remti reaguojant į
                  dabartinį COVID-19 protrūkį, sistemą (toliau - Komunikatas) ir
                  vėlesnius pakeitimus:
                </p>
              </div>
              <div className="row">
                {selfDeclarationData &&
                  SelfDeclarationInfo.map((item, i) => {
                    if (item.type === 'input') {
                      return (
                        <div className="col-lg-6 col-sm-6" key={i}>
                          <InputWithTextInline
                            name={item.name}
                            type="number"
                            value={selfDeclarationData?.[item.name]}
                            placeholder={item.title}
                            subtitles={item.subtitles}
                            onChange={onSelfDeclarationChange}
                            disabled={disableCheck(item.name)}
                            onKeyPress={validateItsNumber}
                          />
                          {notValid && (
                            <p className="errorsBlockText error1">
                              {isIntegerNumber(
                                selfDeclarationData?.[item.name]
                              )}
                            </p>
                          )}
                        </div>
                      );
                    } else if (item.type === 'checkbox') {
                      return (
                        <div className="checkbox mb-0 mt-2 mx-3 pl-2" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={item.name}
                              checked={selfDeclarationData?.[item.name]}
                              disabled={viewWindow}
                              onChange={(e) =>
                                onSelfDeclarationtCheckBoxChange(e, item.name)
                              }
                            />
                            <span className="checkmark"></span>
                            {item.title}
                          </label>
                          {notValid &&
                            selfDeclarationData?.[item.name] !== true && (
                              <p
                                className="errorsBlockText error1"
                                style={{
                                  color: 'var(--pinkDark)',
                                  textAlign: 'left',
                                }}
                              >
                                Turite sutikti su sąlyga
                              </p>
                            )}
                        </div>
                      );
                    }
                  })}
              </div>
              <h4>Informacija Paskolos sutarties parengimui</h4>
              {loanInfo && (
                <div className="row director-data-margin">
                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('ProjectRegionId')}
                    </label>
                    <select
                      disabled={viewWindow || requestData.RequestSystemInfo?.FormVersion !== 3 }
                      name="ProjectRegionId"
                      onChange={onLoanInfoChange}
                      style={{
                        background: (viewWindow || requestData.RequestSystemInfo?.FormVersion !== 3) ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={requestData.RequestSystemInfo?.FormVersion !== 3 ? '2' : loanInfo.ProjectRegionId}
                      defaultValue={requestData.RequestSystemInfo?.FormVersion !== 3 ? '2': null }
                    >
                      <option value={null}>{null}</option>
                      <option value={'1'}>
                        Vidurio vakarų Lietuvos regionas
                      </option>
                      <option value={'2'}>Sostinės regionas ir visa Lietuva</option>
                    </select>
                    {notValid && loanInfo.ProjectRegionId == null && requestData.RequestSystemInfo?.FormVersion == 3 && (
                      <p
                        className="errorsBlockText error1"
                        style={{ color: 'var(--pinkDark)' }}
                      >
                        Privalote pasirinkti regioną
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <InputWithTextInline
                      name={'LoanSum'}
                      type={isNumberType('LoanSum') ? 'number' : 'string'}
                      onChange={onLoanInfoChange}
                      value={loanInfo['LoanSum'] || ''}
                      placeholder={requestDataLabelTranslation('LoanSum')}
                      disabled={disableCheck('LoanSum')}
                    />

                    {notValid && !disableCheck('LoanSum') && (
                      <p className="errorsBlockText error1">
                        {isLoadSumValid(
                          loanInfo.LoanSum,
                          loginType === 'Company'
                            ? companyDataList.CompanyRegistrationDate
                            : farmData.IndividualActivityCertificateNumberStartDate,
                          loginType === 'Company'
                            ? companyDataList.CompanySizeType
                            : applicantData.CompanySizeType,
                          loanInfo.LoanPurposeId,
                          loanInfo.LoanSocialBusiness,
                          loginType
                        )}
                      </p>
                    )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanSocialBusiness')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanSocialBusiness"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={loanInfo.LoanSocialBusiness}
                    >
                      <option value={null}>{null}</option>
                      <option value={1}>Taip</option>
                      <option value={0}>Ne</option>
                      <option value={3}>Taip (esu Ukrainos pilietis)</option>
                      <option value={2}>Ne (esu Ukrainos pilietis)</option>
                    </select>
                    <p style={{ color: '#597a96' }}>
                      * Socialinio poveikio projektas – tai projektas, pagal
                      kurį verslininko ar įmonės pelno siekimas susiejamas su
                      socialiniais tikslais ir prioritetais{' '}
                    </p>
                    {notValid && loanInfo.LoanSocialBusiness == null && (
                      <p className="errorsBlockText error1">
                        Privalote pasirinkti paskolos tipą
                      </p>
                    )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanPurposeId')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanPurposeId"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={loanInfo.LoanPurposeId}
                    >
                      <option value={null}>{null}</option>
                      <option value={'1'}>Investicinė</option>
                      <option value={'2'}>Apyvartinė</option>
                    </select>
                    {notValid &&
                      (loanInfo.LoanPurposeId == null ||
                        loanInfo.LoanPurposeId == null) && (
                        <p className="errorsBlockText error1">
                          Privalote pasirinkti paskolos paskirtį
                        </p>
                      )}
                  </div>
                  {loanInfo?.ProjectRegionId == 1 && loanInfo?.LoanPurposeId == 1 && (otherRequestData?.CompanySizeType == 2 || otherRequestData?.CompanySizeType ==1) && 
                  requestData?.RequestSystemInfo?.FormVersion == 3 && (
                    <div
                      className="col-lg-6 col-sm-12"
                      style={{
                        width: '200px',
                      }}
                      //key={IsNeedSubsidy}
                    >
                      <label className={styles.evrkTitle}>
                        {' '}
                        {requestDataLabelTranslation('IsNeedSubsidy')}
                      </label>
                      <select
                        disabled={viewWindow}
                        name="IsNeedSubsidy"
                        onChange={onLoanInfoChange}
                        style={{
                          background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                          // color: '#2e2d2d',
                        }}
                        value={loanInfo.IsNeedSubsidy}
                      >
                        <option value={null}>{null}</option>
                        <option value={false}>Ne</option>
                        <option value={true}>Taip</option>
                      </select>
                      {notValid &&
                      (loanInfo.IsNeedSubsidy == null ||
                        loanInfo.IsNeedSubsidy == null) && (
                        <p className="errorsBlockText error1">
                          Privalote pasirinkti, ar kreipsitės dėl dotacijos
                        </p>
                      )}
                    </div>
                  
                  )}
                    
                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanTerm')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanTerm"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                        // color: '#2e2d2d',
                      }}
                      value={loanInfo.LoanTerm}
                    >
                      {/*} {[...new Array(110)].map((i, val) => (
                        <option value={i}>{val == 0 ? null : val + 11}</option>
                     ))}*/}
                      <option value={null}>{null}</option>
                      <option value={12}>{12}</option>
                      <option value={24}>{24}</option>
                      <option value={36}>{36}</option>
                      <option value={48}>{48}</option>
                      <option value={60}>{60}</option>
                      <option value={72}>{72}</option>
                      <option value={84}>{84}</option>
                      <option value={96}>{96}</option>
                      <option value={108}>{108}</option>
                      <option value={120}>{120}</option>
                    </select>
                    <p style={{ color: '#597a96', fontSize: '0.7rem' }}>
                      Pastaba. Minimalus terminas 12 mėn. Maksimalus apyvartinės
                      paskolos terminas 36 mėn., investicinės paskolos - 120
                      mėn.).
                    </p>

                    {notValid && !disableCheck('LoanTerm') && (
                      <p className="errorsBlockText error1">
                        {LoanTermValidation(
                          loanInfo.LoanTerm,
                          loanInfo.LoanPurposeId
                        )}
                      </p>
                    )}
                  </div>
                  {/*<div className="col-lg-6 col-sm-12">*/}

                    {/* <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanDelayTerm')}
                    </label>



                    <select
                      disabled={viewWindow}
                      name="LoanDelayTerm"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                        // color: '#2e2d2d',
                      }}
                      value={loanInfo.LoanDelayTerm}
                    >
                      {[null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                        (val) => (
                          <option value={val} key={val}>
                            {val}
                          </option>
                        )
                      )}
                    </select> */}

                   {/*} <InputWithTextInline
                      name={'LoanDelayTerm'}
                      type="string"
                      onChange={onLoanInfoChange}
                      value={loanInfo['LoanDelayTerm'] || ''}
                      placeholder={requestDataLabelTranslation('LoanDelayTerm')}
                      disabled={disableCheck('LoanDelayTerm')}
                    />

                    {notValid &&
                      (validateTextinput(
                        loanInfo['LoanDelayTerm'],
                        'privalomas laukas',
                        1
                      ) ||
                        checkLoanDelayTermFormat(
                          loanInfo['LoanDelayTerm']
                        )) && (
                        //!disableCheck('LoanDelayTerm') &&
                        <p className="errorsBlockText error1">
                          {validateTextinput(
                            loanInfo['LoanDelayTerm'],
                            'privalomas laukas',
                            1
                          )}
                          {!validateTextinput(
                            loanInfo['LoanDelayTerm'],
                            'privalomas laukas',
                            1
                          ) &&
                            checkLoanDelayTermFormat(loanInfo['LoanDelayTerm'])}
                        </p>
                      )}
                          </div>*/}

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanPaymentDay')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanPaymentDay"
                      onChange={onLoanInfoChange}
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      }}
                      value={loanInfo.LoanPaymentDay}
                    >
                      {[null, 5, 15, 25].map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>

                    {notValid &&
                      (loanInfo.LoanPaymentDay == null ||
                        loanInfo.LoanPaymentDay == 0) && (
                        <p className="errorsBlockText error1">
                          Privalote pasirinkti pageidaujamą mokėjimo dieną
                        </p>
                      )}
                  </div>
                  {/* <div className="col-lg-12 col-sm-12">
                    <p
                      className="text-style-new-request"
                      style={{ marginTop: '6px' }}
                    >
                      1. Veikia Lietuvos Respublikoje, kaip tai detaliau
                      apibūdinta Apraše; <br />
                      2. Atitinka minimalius Lietuvos Respublikos mokesčių
                      administravimo įstatymo 401 straipsnyje nustatytus
                      patikimų mokesčių mokėtojų kriterijus;
                      <br />
                      3. Jam nėra iškelta nemokumo byla ir (ar) jis nėra
                      restruktūrizuojamas pagal Lietuvos Respublikos juridinių
                      asmenų nemokumo įstatymą arba iki jo įsigaliojimo
                      galiojusius Lietuvos Respublikos įmonių bankroto įstatymą
                      ir Lietuvos Respublikos restruktūrizavimo įstatymą; / jam
                      nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
                      Respublikos fizinių asmenų bankroto įstatymą; <br />
                      4. Nėra gavęs neteisėtos pagalbos, kuri Europos Komisijos
                      sprendimu (dėl individualios pagalbos arba pagalbos
                      schemos) buvo pripažinta neteisėta ir nesuderinama su
                      vidaus rinka, arba yra grąžinęs visą jos sumą, įskaitant
                      palūkanas, teisės aktuose nustatyta tvarka. <br />
                    </p>
                  </div> */}
                </div>
              )}

              {/* inline checkBoxes */}
              {checks != null && requestData?.RequestSystemInfo?.FormVersion == 3 && (
                <div
                  className="col-lg-12 col-sm-12"
                  style={{ paddingLeft: '5px' }}
                >
                  {/* {Object.keys(checks).map((key, i) => { */}

                  {agreementsCheckBoxesNewArray.map((key, i) => {
                    if (isCheckBox(key) === true) {
                      return (
                        <div className="checkbox" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={key}
                              checked={checksNew[key]}
                              disabled={viewWindow}
                              onChange={(e) => onCheckBoxNewChange(e, key)}
                            />
                            <span className="checkmark"></span>
                            {/* {checks[key] === 'Check1Confirment' ? check1Confirment() : requestDataLabelTranslation(key)} */}
                            {key === 'Check1Confirment'
                              ? check1ConfirmentNew()
                              : requestDataLabelTranslation2(key)}
                          </label>
                          {notValid &&
                            !isRequeredToCheck(key) &&
                            checksNew[key] !== true && (
                              <p
                                className="errorsBlockText error1"
                                style={{
                                  color: 'var(--pinkDark)',
                                  textAlign: 'left',
                                }}
                              >
                                Turite sutikti su sąlyga
                              </p>
                            )}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              
              {checks != null && requestData?.RequestSystemInfo?.FormVersion !== 3 && (
                <div
                  className="col-lg-12 col-sm-12"
                  style={{ paddingLeft: '5px' }}
                >
                  {/* {Object.keys(checks).map((key, i) => { */}

                  {agreementsCheckBoxesArray.map((key, i) => {
                    if (isCheckBox(key) === true) {
                      return (
                        <div className="checkbox" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={key}
                              checked={checks[key]}
                              disabled={viewWindow}
                              onChange={(e) => onCheckBoxChange(e, key)}
                            />
                            <span className="checkmark"></span>
                            {/* {checks[key] === 'Check1Confirment' ? check1Confirment() : requestDataLabelTranslation(key)} */}
                            {key === 'Check1Confirment'
                              ? check1Confirment()
                              : requestDataLabelTranslation(key)}
                          </label>
                          {notValid &&
                            !isRequeredToCheck(key) &&
                            checks[key] !== true && (
                              <p
                                className="errorsBlockText error1"
                                style={{
                                  color: 'var(--pinkDark)',
                                  textAlign: 'left',
                                }}
                              >
                                Turite sutikti su sąlyga
                              </p>
                            )}
                        </div>
                      );
                    }
                  })}
                </div>
              )}

{requestData.CompanyData && ShowQuestionList() && (
                <div style={{ marginTop: '2rem' }}>
                  <h4>
                    ATITIKIMO KOMISIJOS REGLAMENTE (ES) NR.
                    651/2014 NUSTATYTIEMS PAREIŠKĖJUI TAIKOMIEMS REIKALAVIMAMS
                    KLAUSIMYNAS
                  </h4>
                  <div style={{ paddingBottom: '30px' }}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className={styles.tableHeaderLeft}>Kriterijai</th>
                          <th className={styles.tableHeaderRight}>
                            <span>Taip</span>
                            <span>Ne</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className={styles.qtabletBody}>
                        {questionListArray.map((val, i) => (
                          <>
                            <tr key={i}>
                              <td
                                style={{
                                  paddingLeft: '15px',
                                  paddingRight: '25px',
                                  color: '#597a96',
                                  lineHeight: '20px',
                                }}
                              >
                                {val === 'IsSmall'
                                  ? isSmallText()
                                  : questionListTranslations(val)}
                              </td>
                              <td>
                                {' '}
                                <div
                                  className={styles.radioButtonsBox}
                                  onChange={onQuestionListCheckBoxChange}
                                >
                                  {' '}
                                  <input
                                    type="radio"
                                    className={styles.radioButton}
                                    value={true}
                                    checked={questionnaire?.[val] == true}
                                    name={val}
                                    disabled={viewWindow}
                                  />{' '}
                                  <input
                                    type="radio"
                                    className={styles.radioButton}
                                    value={false}
                                    checked={questionnaire?.[val] == false}
                                    name={val}
                                    disabled={viewWindow}
                                  />
                                </div>
                              </td>
                            </tr>
                            {questionnaire?.[val] == false && (
                              <tr>
                                <td>
                                  <textarea
                                    style={{
                                      width: '95%',
                                      height: '50px',
                                      marginLeft: '15px',
                                      marginBottom: '10px',
                                      marginTop: '10px',
                                      padding: '5px',
                                      lineHeight: '1.2rem',
                                    }}
                                    placeholder="Kadangi pažymėjote Ne, detaliai apibūdinkite to priežastis"
                                    name={`${val}Comment`}
                                    type="string"
                                    value={questionnaire?.[`${val}Comment`]}
                                    onChange={onQuestionnaireCommentChange}
                                    disabled={viewWindow}
                                  />
                                  {notValid &&
                                    isCommentFilled(
                                      questionnaire[`${val}Comment`]
                                    ) && (
                                      <p
                                        className="errorsBlockText error1"
                                        style={{ margin: '10px' }}
                                      >
                                        {isCommentFilled(
                                          questionnaire?.[`${val}Comment`]
                                        )}
                                      </p>
                                    )}
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                    {notValid && !checkifAllQuestionListValuesCheck() && (
                      <p className="errorsBlockText error1">
                        Visi klausimyno atsakymai privalomo būti užpildyti
                      </p>
                    )}
                  </div>
                </div>
              )}

              <SurwayAnswers Ratings={requestData?.Ratings} />
           {requestData?.RequestSystemInfo?.FormVersion !== 3 && (
              <FileUploadContainerOld
                requestId={requestId}
                viewWindow={viewWindow}
                status={
                  requestData.RequestSystemInfo
                    ? requestData.RequestSystemInfo.Status
                    : null
                }
              />)}

             {requestData?.RequestSystemInfo?.FormVersion == 3 && (
              <FileUploadContainer
                requestId={requestId}
                viewWindow={viewWindow}
                status={
                  requestData.RequestSystemInfo
                    ? requestData.RequestSystemInfo.Status
                    : null
                }
              />)}

            {requestData != null &&
                requestData.GuaranteesLoanSum !== undefined &&
                requestData.CovidLoanSum !== undefined &&
                requestData.RequestSystemInfo.Status > 7 && (
                  <>
                    <h2>Deklaracijos duomenys</h2>
                    <p style={{ marginTop: '1rem' }}>
                      Įmonė yra gavusi paskolą (- as) ar sudariusi lizingo
                      sandorį (- ius), kurie yra garantuoti skatinamosios
                      finansinės priemonės „Portfelinės garantijos paskoloms 2"
                      lėšomis: {requestData.GuaranteesLoanSum} Eur.
                    </p>

                    <p style={{ marginBottom: '1rem' }}>
                      Įmonė yra gavusi paskolą iš skatinamosios finansinės
                      priemonės „Paskolos labiausiai nuo COVID-19 nukentėjusiems
                      verslams" lėšų: {requestData.CovidLoanSum} Eur.
                    </p>
                  </>
                )}



              
              {/*matymas */}
              {((signInInfo != null &&
                signInInfo[0] != null &&
                signInInfo[0].DocumentCreatedAt != null) ||
                requestData?.RequestSystemInfo?.Status == 11 ||
                requestData?.RequestSystemInfo?.Status == 12 ||
                requestData?.RequestSystemInfo?.Status == 3 ||
                requestData?.RequestSystemInfo?.Status == 4) && (
                <>
                  <h4 style={{ marginTop: '1rem' }}>
                    Sutartys, sprendimai ir prašymai
                  </h4>
                  <RowTableAdmin header={rowTableHeader} isEmpty={false}>
                    {signInInfo?.map((val, i) => (
                      <RowTableRow>
                        <RowTableCell>
                          {fileTypeTranslation(signInInfo[i].FileType)}
                        </RowTableCell>
                        <RowTableCell>{signInInfo[i].FileName}</RowTableCell>
                        <RowTableCell>
                          {' '}
                          {signInInfo[i].DocumentCreatedAt != null &&
                            format(
                              new Date(signInInfo[i].DocumentCreatedAt),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                        </RowTableCell>
                        <RowTableCell>
                          {' '}
                          {signInInfo[i].ApplicantSigningDate != null &&
                            format(
                              new Date(signInInfo[i].ApplicantSigningDate),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                        </RowTableCell>
                        <RowTableCell>
                          {' '}
                          {signInInfo[i].InvegaSigningDate != null &&
                            format(
                              new Date(signInInfo[i].InvegaSigningDate),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                        </RowTableCell>

                        <RowTableCell>
                          <p
                            className="pdownload"
                            onClick={() =>
                              GetSignedDocumentContractUser(
                                requestId,
                                val.FileType,
                                val.FileName,
                                val?.ChangeConditionsFileId,
                                val?.ApplicantSigningDate,
                                val?.InvegaSigningDate
                              )
                            }
                          >
                            Parsisiųsti
                          </p>
                        </RowTableCell>
                      </RowTableRow>
                    ))}
                  </RowTableAdmin>
                </>
              )}

              {administrationInvoices != null &&
                administrationInvoices.length > 0 &&
                requestData?.RequestSystemInfo?.Status !== 1 &&
                requestData?.RequestSystemInfo?.Status !== 2 &&
                requestData?.RequestSystemInfo?.Status !== 3 &&
                requestData?.RequestSystemInfo?.Status !== 4 &&
                requestData?.RequestSystemInfo?.Status !== 5 &&
                requestData?.RequestSystemInfo?.Status !== 6 &&
                requestData?.RequestSystemInfo?.Status !== 11 &&
                requestData?.RequestSystemInfo?.Status !== 12 && (
                  <>
                    <h4 style={{ marginTop: '1rem' }}>Sąskaitos</h4>
                    <RowTableAdmin
                      header={administrationInvoicesTableHeader}
                      isEmpty={false}
                    >
                      {administrationInvoices?.map(
                        ({
                          ContractId,
                          CreatedAt,
                          ContractNumber,
                          AdministrationFee,
                          ChangeConditionsFileId,
                        }) => (
                          <RowTableRow key={ContractId}>
                            <RowTableCell>{ContractNumber}</RowTableCell>
                            <RowTableCell>
                              {getContractName(ContractNumber)}
                            </RowTableCell>
                            <RowTableCell>
                              {format(
                                new Date(CreatedAt),
                                'yyyy-MM-dd HH:mm:ss'
                              )}
                            </RowTableCell>
                            <RowTableCell>
                              {formatNumber(AdministrationFee)}
                            </RowTableCell>
                            <RowTableCell>
                              <p
                                className="pdownload"
                                onClick={() =>
                                  !ContractId && ChangeConditionsFileId
                                    ? DownloadChangeConditionsInvoice(
                                        ChangeConditionsFileId,
                                        ContractNumber
                                      )
                                    : DownloadAdministrationInvoice(
                                        ContractId,
                                        ContractNumber
                                      )
                                }
                              >
                                Parsisiųsti
                              </p>
                            </RowTableCell>
                          </RowTableRow>
                        )
                      )}
                    </RowTableAdmin>
                  </>
                )}

              {requestData?.RequestSystemInfo?.FormVersion !== 3 &&  (requestData?.RequestSystemInfo?.Status === 11 ||
                requestData?.RequestSystemInfo?.Status === 19) && (
                <AdditionalFileUploadContainerOld
                  requestId={requestId}
                  viewWindow={viewWindow}
                  status={
                    requestData.RequestSystemInfo
                      ? requestData.RequestSystemInfo.Status
                      : null
                  }
                  loanPurposeId={loanInfo?.LoanPurposeId}
                />
              )}  

            {requestData?.RequestSystemInfo?.FormVersion == 3 && 
              (requestData?.RequestSystemInfo?.Status === 11 ||
                requestData?.RequestSystemInfo?.Status === 19) && (
                <AdditionalFileUploadContainer
                  requestId={requestId}
                  viewWindow={viewWindow}
                  status={
                    requestData.RequestSystemInfo
                      ? requestData.RequestSystemInfo.Status
                      : null
                  }
                  // loanPurposeId={loanInfo?.LoanPurposeId}
                />
              )}

            {requestData &&
              requestData.CommentsHistory &&
              requestData.CommentsHistory.length ? (
                <div style={{ marginTop: '3rem', marginBottom: '2rem' }}>
                  <h4>Komentarų istorija </h4>
                  {
                    <RowTableAdmin
                      header={commentsHistoryTableHeader}
                      isEmpty={false}
                    >
                      {requestData.CommentsHistory.map((data) => {
                        return (
                          <RowTableRow>
                            <RowTableCell>
                              {data.Date &&
                                format(
                                  new Date(data.Date),
                                  'yyyy-MM-dd HH:mm:ss'
                                )}
                            </RowTableCell>
                            <RowTableCell>{data.NewComment}</RowTableCell>
                          </RowTableRow>
                        );
                      })}
                    </RowTableAdmin>
                  }
                </div>
              ) : null}

<div className="col-lg-12 col-sm-12">
                <div className={styles.btnBlock}>
                  {!viewWindow && (
                    <>
                      <Button type="longBtn" onClick={() => submit()}>
                        IŠSAUGOTI IR PATIKRINTI ATITIKIMO KRITERIJUS
                      </Button>
                    </>
                  )}
                  {viewWindow &&
                    //requestData.DeclarationInfo &&
                    //requestData.DeclarationInfo.EuLoanSum != null &&
                    (requestData.RequestSystemInfo.Status === 1 ||
                      requestData.RequestSystemInfo.Status === 5) &&
                    requestData.RequestSystemInfo.DaysTillAdjust >= 0 && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() => approveRequest()}
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti paraišką
                        </Button>
                      </>
                    )}

                  {viewWindow &&
                    //requestData.DeclarationInfo &&
                    //requestData.DeclarationInfo.EuLoanSum != null &&
                    (requestData.RequestSystemInfo.Status === 1 ||
                      requestData.RequestSystemInfo.Status === 5) &&
                    requestData.RequestSystemInfo.DaysTillAdjust < 0 && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() => openMessageModal('Paraiškos tikslinimo terminas yra pasibaigęs, todėl tikslinti paraiškos nebegalite.')}
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti paraišką
                        </Button>
                      </>
                    )}

                  {viewWindow &&
                    requestData.DeclarationInfo &&
                    requestData.RequestSystemInfo.Status === 11 && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() => approveRequestWithDocs()}
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti paraišką su papildomais dokumentais
                        </Button>
                      </>
                    )}

                  {viewWindow &&
                    requestData.DeclarationInfo &&
                    requestData.RequestSystemInfo.Status === 19 && (
                      <>
                        <Button
                          type="longBtn"
                          onClick={() =>
                            approveRequestWithDocsForChangeConditions()
                          }
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            float: 'left',
                          }}
                        >
                          Pateikti papildomus dokumentus sąlygų keitimui
                        </Button>
                      </>
                    )}
                  {viewWindow &&
                    requestData.RequestSystemInfo &&
                    (requestData.RequestSystemInfo.Status === 9 ||
                      requestData.RequestSystemInfo.Status === 13 ||
                      requestData.RequestSystemInfo.Status === 14 ||
                      requestData.RequestSystemInfo.Status === 15 ||
                      requestData.RequestSystemInfo.Status === 16 ||
                      requestData.RequestSystemInfo.Status === 17 ||
                      requestData.RequestSystemInfo.Status === 18 ||
                      requestData.RequestSystemInfo.Status === 19) && (
                      <Link
                        to={`${config.APP_PATHS.paymentrequests}?requestId=${requestId}`}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                        }}
                      >
                        <Button type="longBtn">
                          {' '}
                          Išmokėti paskolą arba jos dalį
                        </Button>
                      </Link>
                    )}
                  {viewWindow ? (
                    <>
                      {requestData.RequestSystemInfo &&
                        (requestData.RequestSystemInfo.Status == 1 ||
                          requestData.RequestSystemInfo.Status == 5) && 
                          requestData.RequestSystemInfo.DaysTillAdjust >= 0 && (
                          <Button
                            type="standart"
                            onClick={() => setViewWindow(false)}
                            className="btn-dark-blue-standart"
                          >
                            Redaguoti
                          </Button>
                        )}

                      {requestData.RequestSystemInfo &&
                        (requestData.RequestSystemInfo.Status == 1 ||
                          requestData.RequestSystemInfo.Status == 5) && 
                          requestData.RequestSystemInfo.DaysTillAdjust < 0 && (
                          <Button
                            type="standart"
                            onClick={() => openMessageModal('Paraiškos tikslinimo terminas yra pasibaigęs, todėl tikslinti paraiškos nebegalite.')}
                            className="btn-dark-blue-standart"
                          >
                            Redaguoti
                          </Button>
                        )}     

                      <Link
                        to={config.APP_PATHS.requests}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                        }}
                      >
                        {' '}
                        <Button type="cancel">Atgal</Button>
                      </Link>
                    </>
                  ) : (
                    <Button type="cancel" onClick={() => cancel()}>
                      Atgal
                    </Button>
                  )}

                  {requestData.RequestSystemInfo &&
                    (requestData.RequestSystemInfo.Status === 6 ||
                      requestData.RequestSystemInfo.Status === 4) && (
                      <Button
                        type="longBtn"
                        onClick={() =>
                          copyRequest(requestData.RequestSystemInfo.RequestId)
                        }
                      >
                        Kurti naują paraišką esamos pagrindu
                      </Button>
                    )}

                  {requestData.RequestSystemInfo != null && (
                    <Button
                      type="longBtn"
                      onClick={() =>
                        getFinancialCriteriaResults(
                          requestData.RequestSystemInfo.RequestId,
                          requestData.RequestSystemInfo?.FormVersion
                        )
                      }
                    >
                      Peržiūrėti atitikimo kriterijus
                    </Button>
                  )}

                {requestData.RequestSystemInfo != null &&
                    (requestData.RequestSystemInfo.Status === 7 ||
                      requestData.RequestSystemInfo.Status === 14) &&
                    requestData.RequestSystemInfo
                      .DeadlineBusinessDaysUntilSignature >= 0 && (
                      <div style={{ width: '14rem' }}>
                        <Button type="longBtn" onClick={() => sign()}>
                          Pasirašyti
                        </Button>
                      </div>
                    )}

                  {requestData.RequestSystemInfo != null &&
                    (requestData.RequestSystemInfo.Status === 7 ||
                      requestData.RequestSystemInfo.Status === 14) &&
                    requestData.RequestSystemInfo
                      .DeadlineBusinessDaysUntilSignature < 0 && (
                      <div style={{ width: '14rem' }}>
                        <Button type="longBtn" onClick={() => openMessageModal('Sutarties pasirašymo terminas yra pasibaigęs, todėl pasirašyti dokumento nebegalite.')}>
                          Pasirašyti
                        </Button>
                      </div>
                    )}
                  {requestData.RequestSystemInfo != null &&
                    (requestData.RequestSystemInfo.Status === 9 ||
                      requestData.RequestSystemInfo.Status === 16) && (
                      <div style={{ width: '14rem' }}>
                        <Button
                          type="longBtn"
                          onClick={() => changeConditions()}
                        >
                          Pildyti prašymą dėl sutarties sąlygų keitimo
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SignContractModal
        open={signContractModalVisible}
        requestId={requestId}
      />
      <ChangeConditionsModal
        open={changeConditionsModalVisible}
        requestId={requestId}
      />

      <FinancialCriteriaModal
        open={financialCriteriaModalVisible}
        data={financialCriteriaResults}
        sectorType={requestData.LoanInfo && requestData.LoanInfo.SectorType}
        finYear={companyQuestionList?.FsiYear}
        formVersion={requestData?.RequestSystemInfo?.FormVersion}
      />

      <ConfirmWithFinancialCriteriaModal
        open={confirmWithFinancialCriteriaModalVisible}
        loading={loading}
        finYear={companyQuestionList?.FsiYear}
        formVersion={requestData?.RequestSystemInfo?.FormVersion}
      />

      <ConfirmWithoutFinancialCriteriaModal
        open={confirmWithoutFinancialCriteriaModalVisible}
        loading={loading}
      />

      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle={modalOkTitle}
        // modalTitle={modalTitle}
        checkbox={modalCheckbox}
        userComment={userComment}
        mainText={mainText}
      />

      <GoBackConfirmationModal
        open={goBackConfirmationModalVisible}
        loading={loading}
      />
      <ConfirmWithoutFinancialCriteriaForChangeConditionsModal
        open={confirmWithoutFinancialCriteriaForChangeConditionsModalVisible}
        loading={loading}
      />

      <GlobalMessageModal
        message={openedMessageText}
        open={globalMessageModalVisible}
      />
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  requestData: state.dashboardState.requestData,
  loading: state.dashboardState.isOnSync,
  canSeeBills: state.dashboardState.savedRequestDesissionAccepted,
  financialCriteriaResults: state.dashboardState.financialCriteriaResults,
  signContractModalVisible: state.modalsState.signContractModalVisible,
  changeConditionsModalVisible: state.modalsState.changeConditionsModalVisible,
  financialCriteriaModalVisible:
    state.modalsState.financialCriteriaModalVisible,
  signInInfo: state.dashboardState.signInInfo,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
  confirmWithFinancialCriteriaModalVisible:
    state.modalsState.confirmWithFinancialCriteriaModalVisible,
  confirmWithoutFinancialCriteriaModalVisible:
    state.modalsState.confirmWithoutFinancialCriteriaModalVisible,
  goBackConfirmationModalVisible:
    state.modalsState.goBackConfirmationModalVisible,
  confirmWithoutFinancialCriteriaForChangeConditionsModalVisible:
    state.modalsState
      .confirmWithoutFinancialCriteriaForChangeConditionsModalVisible,
      globalMessageModalVisible: state.modalsState.globalMessageModalVisible,
});

export default connect(mapStateToProps)(NewRequest);
