import React, { useState, useEffect } from 'react';
import styles from './fileUploadContainer.module.scss';
import {
  WithSpinner,
  Button,
  TableAdmin,
  TableRow,
  TableCell,
} from '../../components';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { actions } from './../../state/actions';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { fileTypeTranslationNew } from './../../utils/translation/translation';
import { getAutorization } from '../../utils/helpers/helpers';

const FileUploadContainer = ({
  dispatch,
  loading,
  requestId,
  filesTableList,
  viewWindow,
  status,
}) => {
  const tableHeader = [
    'Priedo paskirtis',
    'Įkelto priedo pavadinimas',
    'Data',
    'Ar papildomas?',
    'Veiksmai',
  ];
  const [isLoading, setIsloading] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [additional, setAdditional] = useState(null);

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const gotoBottom = (id) => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const upload = (e) => {
    setIsloading(true);
    const file = e.target.files[0];

    if (file.size > 25 * 1048 * 1048) {
      setIsloading(false);

      swal({
        title: 'Įkeliamas priedas viršija 25mb leistiną limitą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
    } else {
      const formData = new FormData();
      formData.append('Files', file);
      formData.append('RequestId', requestId);
      formData.append('FileType', fileType);

      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadFiles, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.dashboard.getUploadedFileList(requestId));

            swal({
              title: 'Priedas sėkmingai įkeltas',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              let title = 'Nepavyko pašalinti priedo';
              if (r.ErrorDescription) {
                title = r.ErrorDescription;
              }
              swal({
                title: title,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
          setTimeout(() => {
            gotoBottom();
          }, 2000);
        });
      //  dispatch(actions.dashboard.fileUploadAction(formData, 'upload'));
    }
  };

  const onFileTypeSelectorChange = ({ target: { name, value } }) => {
    setFileType(value === '-' ? null : value);
    setAdditional(value === '-' ? false : false);
  };

  const [fileArr, setFileArray] = useState([]);
  const [rejection, setRejection] = useState(null);

  useEffect(() => {
    if (filesTableList && filesTableList.length !== 0 && status === 10) {
      setRejection(filesTableList.slice(-1)[0]);
      setFileArray([filesTableList]);
      setFileArray([...filesTableList].slice(0, filesTableList.length - 1));
    } else {
      setFileArray(filesTableList);
    }
  }, [filesTableList, status, rejection]);

  const deleteFile = (id) => {
    setIsloading(true);
    const requestIdFileId = `${requestId}/${id}`;
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DeleteUploadedFile(requestIdFileId),
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.dashboard.getUploadedFileList(requestId));

          swal({
            title: 'Priedas sėkmingai ištrintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else {
          swal({
            title: 'Nepavyko pašalinti priedo',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log('catch block');
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
        setTimeout(() => {
          gotoBottom();
        }, 2000);
      });
  };

  const deleteConfirmation = (id) => {
    swal({
      title: 'Ar tikrai norite ištrinti šį failą?',
      icon: 'warning',
      showCancelButton: true,

      buttons: {
        confirm: {
          text: 'Taip',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
        cancel: {
          text: 'Ne',
          value: false,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true,
        },
      },
    }).then((result) => {
      if (result) {
        deleteFile(id);
      }
    });
  };

  const downloadRejectionFile = (requestId, id, fileName) => {
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadFile(requestId, id), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: getAutorization(),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const acceptFiles = (fileType) => {
    if (fileType == 6 || fileType == 3
      || fileType == 4
      || fileType == 5
      || fileType == 7
      || fileType == 11
      || fileType == 47
      || fileType == 39
      || fileType == 55) {
      return '.pdf, .adoc';
    }

    if (fileType == 22 || 
      fileType == 56 ) {
      return ' .adoc, .pdf, .jpg';
    }

    if (fileType == 1 ||
      fileType == 2 ||
      fileType == 9 ) {
      return '.xlsx, .xls, .pdf, .adoc';
    }
    return '.xlsx, .xls, .doc, .docx, .adoc, .asice, .xml, .txt, .pdf';
  };

  const downloadAllFiles = () => {
    //console.log('clicked');
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadAllFiles(requestId), {
      method: 'GET',
      headers: {
        'content-Type': 'application/zip',
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Visi_priedai_${requestId}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .finally(() => {
        setIsloading(false);
      });
    // dispatch(actions.backoffice.downloadAllFilesAction(requestId));
  };

  return (
    <WithSpinner loading={isLoading}>
      <div className={styles.container}>
        <h4>Priedų įkėlimas</h4>
        {fileArr != null && fileArr.length > 0 ? (
          <>
            <TableAdmin header={tableHeader} isEmpty={false}>
              {fileArr.map(
                ({
                  Id,
                  FileName,
                  FileType,
                  UploadedAt,
                  Additional,
                  Submitted,
                  AllowDelete,
                }) => (
                  <TableRow key={Id}>
                    <TableCell>{fileTypeTranslationNew(FileType)}</TableCell>
                    <TableCell>{FileName}</TableCell>
                    <TableCell>
                      {format(new Date(UploadedAt), 'yyyy-MM-dd')}
                    </TableCell>
                    <TableCell>{Additional ? 'Taip' : 'Ne'}</TableCell>
                    <TableCell>
                      <div className={styles.iconsContainer}>
                        {((Additional == true && status == 11) ||
                          !viewWindow &&
                          (Submitted == false || Submitted == null)) && (
                          <FontAwesomeIcon
                            icon={faTrash}
                            className={styles.icon}
                            onClick={() => deleteConfirmation(Id)}
                            style={{
                              color: '#597a96',
                            }}
                          />
                        )}

                        <FontAwesomeIcon
                          icon={faDownload}
                          className={styles.icon}
                          onClick={() =>
                            downloadRejectionFile(requestId, Id, FileName)
                          }
                          style={{
                            color: '#597a96',
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableAdmin>
            <br />
            <br />

            <Button
              type="standart"
              onClick={() => downloadAllFiles()}
              float="right"
            >
              Atsisiųsti visus įkeltus priedus
            </Button>
            <br />
            <br />
          </>
        ) : (
          <p className="nofileText">Nėra įkeltų priedų</p>
        )}
        {!viewWindow && (
          <div className={styles.container}>
            <div style={{ display: 'flex' }}>
              <div className={styles.selectflex}>
                <label>Prisegamo priedo paskirtis</label>
                <select
                  disabled={viewWindow}
                  name="InvoiceType"
                  style={{
                    background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                    height: '44px',
                  }}
                  id="scrollToFromTop"
                  onChange={onFileTypeSelectorChange}
                >
                  <option selected={fileType == null} value={null}>
                    {' '}
                    -{' '}
                  </option>
                  <option selected={fileType == 1} value={1}>
                    SVV subjekto statuso deklaracija (1)
                  </option>
                  <option selected={fileType == 2} value={2}>
                    Vienos įmonės deklaracija (2)
                  </option>
                  <option selected={fileType == 6} value={6}>
                  Privataus finansuotojo ketimo dalyvauti Projekto finansavime raštas (3)
                  </option>
                  <option selected={fileType == 3} value={3}>
                  Verslo subjektą apibūdinantys dokumentai (4)
                  </option>
                  <option selected={fileType == 22} value={22}>
                    Vadovo asmens tapatybės dokumentas (pvz., pasas, asmens
                    tapatybės kortelė)
                  </option>
                  <option selected={fileType == 4} value={4}> 
                  Verslininko individualios veiklos registracijos dokumentai (5)
                  </option>
                  <option selected={fileType == 5} value={5}>
                  Verslo planas (projekto aprašymas) (6)
                </option>
                  <option selected={fileType == 7} value={7}>
                  Einamųjų finansinių metų tarpinis paskutinio ketvirčio finansinių ataskaitų rinkinys (7)
                  </option>
                  <option selected={fileType == 9} value={9}>
                  Verslo subjekto veiklos (finansinių) duomenų detalizacijos forma (8)
                  </option>
                  <option selected={fileType == 55} value={55}>
                    Laisvos formos dokumentas, kuriame nurodyti Verslo subjekto
                    ir su juo susijusių įmonių ryšiai
                  </option>
                  
                  <option selected={fileType == 56} value={56}>
                  Verslo subjekto ar jo dalyvio (akcininko, nario, dalininko) ar savininko leidimo gyventi Lietuvoje kopija (9) 
                  </option>
                  <option selected={fileType == 47} value={47}>
                  Motyvuotas paaiškinimas dėl įvykusių pokyčių Verslo subjekto veikloje (10)
                  </option>
                  <option selected={fileType == 39} value={39}>
                  Reikšmingos žalos nedarymo principui klausimynas (11)
                  </option>
                  <option selected={fileType == 10} value={10}>
                    Kiti dokumentai
                  </option>
                </select>
              </div>
              <span
                className={styles.disabled}
                style={{
                  width: '155px',
                  marginTop: '-5px',
                  marginLeft: '10px',
                }}
              >
                <span className={styles.textUptheButton}>
                  Pasirinkti priedą
                </span>

                <button
                  className={
                    fileType
                      ? 'btn ui-button btn-dark-blue-standart'
                      : 'btn ui-button btn-dark-blue-disabled'
                  }
                  type="longBtn"
                  onClick={() => selectFile('fileElem')}
                  disabled={!fileType}
                  style={{ marginTop: '9px', padding: '9px' }}
                >
                  PASIRINKTI PRIEDĄ
                </button>
                <input
                  type="file"
                  id="fileElem"
                  accept={acceptFiles(fileType)}
                  className="d-none"
                  onChange={upload}
                />
              </span>
            </div>
            <p className={styles.note}>
              Prisekite reikiamas rinkmenas. <br />
              Galimi priedų formatai: .xlsx, .xls, .doc, .docx, .adoc, .asice,
              .xml, .txt, .pdf. Maksimalus dydis 20MB. <br /> <br />-{' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq296"
                target="_blank"
                rel="noreferrer noopener"
              >
                Įmonės vadovo pasirašyta SVV deklaracija
              </a>
              *;
              <br />-{' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq290"
                target="_blank"
                rel="noreferrer noopener"
              >
                Įmonės vadovo pasirašyta Vienos įmonės deklaracija
              </a>
             ;
              <br /> <br />
              {/*<p>
                <b>Privalomi pateikti priedai yra šie:</b>
                <br />
                - SVV subjekto statuso deklaracija (1);
                <br />
                - Vienos įmonės deklaracija (2);
                <br />
                - Verslo subjektą apibūdinantys dokumentai (3);
                <br />
                - Verslininko individualios veiklos registracijos dokumentai
                (4);
                <br />
                - Verslo planas (Projekto aprašymas) (5);
                <br />
                - Privataus finansuotojo ketinimo dalyvauti Projekte raštas (6);
                <br />
                - Einamųjų finansinių metų tarpinė finansinė ataskaita arba
                lygiaverčiai dokumentai (7);
                <br />
                - Verslo subjekto valdymo organo sprendimas dėl Paskolos gavimo,
                turto įkeitimo ir įgaliotų asmenų paskyrimo sutarties su
                Paskolos davėju sudarymui;
                <br />- {' '}
                  <a
                href="https://invega.lt/lt/doclib/qjgabty4fze4se22wwbvd4myc9mq3hg3"
                target="_blank"
                rel="noreferrer noopener"
              >
                  Paskolos gavėjo veiklos (finansinių) duomenų
                detalizacijos forma
                </a>
                <br />- Paskolos gavėjo atitikimo žemės ūkio inovacinei veiklai
                aprašymas (
                  <a
                href="https://invega.lt/lt/doclib/ox6ppkhbw4hccq5euuaz19wexjvd8hzq"
                target="_blank"
                rel="noreferrer noopener"
              >
                  Priedas Nr. 1
                </a>
                ) (8)
                <br />- Kiti dokumentai.
              </p>*/}
              <br />
              <b>Pastaba:</b> <br />
              (1) įmonės Vadovo pasirašyta SVV subjekto statuso deklaracija (išskyrus atvejį, kai Paskolos gavėjas patvirtina, kad yra didelė įmonė);
              <br />
              (2) taikoma, kai pagalba  Verslo subjektui teikiama pagal De minimis reglamentą. Nuoroda į {' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq290"
                target="_blank"
                rel="noreferrer noopener"
              >
                  dokumentą;
                </a>
              <br />
              (3) raštas teikiamas tuo atveju, kai Privatus finansuotojas ketina suteikti paskolą. INVEGAI pareikalavus, Privatus finansuotojas turi įrodyti, kad yra finansiškai pajėgus dalyvauti Projekto finansavime ir pagrįsti skolinamų lėšų kilmę (netaikoma, kai Privatus finansuotojas yra finansų įstaiga). Jei  Verslo subjektas ketina Projekto finansavime dalyvauti nuosavomis lėšomis, tokiu atveju ši informacija turi būti pagrindžiama verslo plane ir pateikiami nuosavų lėšų kilmę pagrindžiantys dokumentai;
              <br />
              (4) registracijos dokumentai, nuostatai, vadovo asmens tapatybės dokumento kopijos, informacija apie akcininkus (savininkus, naudos gavėjus), aktuali Verslo subjekto akcininkų struktūra ir laisvos formos dokumentas, kuriame būtų nurodyti Verslo subjekto ir su juo susijusių įmonių ryšiai (netaikoma verslininkams);
              <br />
              (5) verslininko individualios veiklos registracijos dokumentai, asmens tapatybės dokumento kopija ir laisvos formos dokumentas, kuriame būtų nurodyti verslininko ir su juo susijusių įmonių ryšiai (taikoma verslininkams);
              <br />
              (6) verslo planas kuriame būtų aprašyta vykdoma ir (ar) planuojama vykdyti veikla, esama ir prognozuojama finansinė būklė, siūlymas dėl Paskolos įmokų mokėjimo grafiko išdėstymo ir užtikrinimo priemonių, investicijų aprašymas ir investicijas pagrindžiantys dokumentai (kai kreipiamasi Paskolos investicijų finansavim atveju), jei tokie investiciją pagrindžiantys dokumentai yra, pvz., statybos, remonto ar rekonstrukcijos darbų sąmata, sutartys, užsakymai, ketinimų protokolai, perkamo ir (ar) įkeičiamo turto vertinimas, planuojamo įsigyti turto sąrašas su kainomis, komerciniai pasiūlymai, leidimai ir licencijos, reikalingos veiklai vykdyti ar projektui įgyvendinti ir pan. Verslo subjektas, vykdantis Socialinio poveikio projektą, verslo plane papildomai turi nurodyti kaip įgyvendinant Projektą bus siekiama teigiamo socialinio poveikio, nurodyto Aprašymo 3 punkte, t. y. kaip spręs visuomenės socialines problemas teikdamas paslaugas ar gamindamas prekes ir (ar) įdarbins socialiai pažeidžiamus asmenis. Taip pat turi būti pateiktos finansinės veiklos prognozės (skaičiavimai), atspindintys Verslo subjekto galimybę laiku mokėti įmokas pagal visus esamus ir būsimus Verslo subjekto finansinius įsipareigojimus visam Paskolos terminui. Rengiant verslo planą siūloma vadovautis VšĮ Inovacijų agentūros rekomenduojama verslo plano struktūra;
              <br />
              (7) einamųjų finansinių metų tarpinis paskutinio ketvirčio finansinių ataskaitų rinkinys, jei nuo einamojo ketvirčio pradžios yra praėję 40 kalendorinių dienų. Tuo atveju, jei nuo einamojo ketvirčio pradžios nėra praėję 40 dienų, turi būti teikiamas ketvirčio, ėjusio prieš paskutinį ketvirtį, finansinių ataskaitų rinkinys arba lygiaverčiai dokumentai, kai Verslininkai arba įmonės veikia trumpiau nei vienerius metus;
              <br />
              (8)  Verslo subjekto veiklos (finansinių) duomenų detalizacijos forma (skelbiama viešai {' '}
              <a
                href="https://www.invega.lt"
                target="_blank"
                rel="noreferrer noopener"
              >
                  interneto svetainėje
                </a>);
              <br />
              
              (9) Verslo subjekto ar jo dalyvio (akcininko, nario, dalininko) ar savininko leidimo gyventi Lietuvoje kopija ir (ar) Ukrainos juridinio asmens steigimo dokumentų kopijos (taikoma SVV subjektams, kurių dalyvis (akcininkas, narys, dalininkas) ar savininkas yra leidimą gyventi Lietuvoje turintis Ukrainos pilietis arba Ukrainos juridinis asmuo, ir Ukrainos pilietybę ir leidimą gyventi Lietuvoje turintiems verslininkams);
              <br />
              (10) motyvuotas paaiškinimas dėl įvykusių pokyčių Verslo subjekto veikloje ir su paraiška teikiamuose dokumentuose (duomenyse) bei paaiškinimas pagrindžiantis dokumentus, lyginant su paskutine teikta paraiška, jei teikiama paraiška dėl šios Priemonės ne pirmą kartą (t. y., kai ankstesnė (-s) paraiška (-os) buvo atmesta (-os);
              <br />
              (11) Reikšmingos žalos nedarymo principui  (<a
                href="https://invega.lt/lt/doclib/60hg0tteleasdm4emkbvv6y51pgbpq6w"
                target="_blank"
                rel="noreferrer noopener"
              >
                 klausimynas
                </a>).
              <br />
            </p>
          </div>
        )}
      </div>
      {rejection && (
        <div className={styles.container}>
          <h4> Sutarties nutraukimo dokumentas </h4>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              downloadRejectionFile(requestId, rejection.Id, rejection.FileName)
            }
          >
            {rejection.FileName}
          </p>
        </div>
      )}
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  filesTableList: state.dashboardState.filesTableList,
});

export default connect(mapStateToProps)(FileUploadContainer);
