import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { config } from '../../api/config';
import Home from './../../pages/home/home';
import Main from './../../pages/main/main';
import Requests from '../../pages/requests/requests';
import Authorization from '../../pages/autorization/autorization';
import Representative from '../../pages/autorization/representative';
import TurnoversFiles from './../../pages/backoffice/TurnoversFiles';

import Backoffice from './../../pages/backoffice/backoffice';
import LoginReload from './../../pages/loginReload/loginReload';
import NewRequest from '../../pages/newRequest/newRequest';
import NewRequest2 from '../../pages/newRequest/newRequest2';
import BackofficeRequest from '../../pages/backoffice/request';
import BackofficeRequest2 from '../../pages/backoffice/request2';
import BackofficeStats from '../../pages/backoffice/stats';
import BackofficeAuthorization from '../../pages/backoffice/autorization/autorization';
import { connect } from 'react-redux';
import { removeLocalStorageItem, getCookieValue } from '../../utils';
import PaymentRequestsList from '../../pages/paymentRequestsList/paymentRequestsList';
//import BackofficePaymentRequestsList from '../../pages/paymentRequestsList/paymentRequestsList';
import PaymentRequest from '../../pages/paymentRequest/paymentRequest';
import PaymentDocument from '../../pages/paymentDocument/paymentDocument';
import PaymentDocumentSumEdit from '../../pages/paymentDocument/paymentDocumentSumEdit';
import AdditionalRequests from '../../pages/backoffice/additionalRequests/additionalRequests';
import { checkBOPage } from './../../utils/helpers/helpers';

const PrivateRoute = ({ location }) => {
  const path = location?.pathname;
  const path2 = path.substring(0, path.lastIndexOf('/'));
  const path3 = path2.substring(0, path2.lastIndexOf('/'));
  const tokenstring = window.location.search;
  const token =
    window.localStorage.getItem('userToken') || getCookieValue('brzd');

  const backofficeToken = checkBOPage();

  if (backofficeToken) {
    if (path === config.APP_PATHS.main) {
      removeLocalStorageItem('token');
    }
    if (path === config.APP_PATHS.backofficeMain) {
      return <Route component={Backoffice} exact />;
    } else if (
      path === config.APP_PATHS.backofficeRequest ||
      path2 === config.APP_PATHS.backofficeRequest
    ) {
      return (
        <Route
          path="/backoffice/request/:requestId"
          component={BackofficeRequest}
          exact
        />
      );
    } else if (
      path === config.APP_PATHS.backofficeRequest2 ||
      path2 === config.APP_PATHS.backofficeRequest2
    ) {
      return (
        <Route
          path="/backoffice/request-2/:requestId"
          component={BackofficeRequest2}
          exact
        />
      );
    } else if (path === config.APP_PATHS.backofficeStats) {
      return <Route component={BackofficeStats} exact />;
    } else if (path === config.APP_PATHS.backofficePaymentRequests) {
      return <Route component={PaymentRequestsList} exact />;
    } else if (path === config.APP_PATHS.backofficePaymentRequest) {
      return <Route component={PaymentRequest} exact />;
    } else if (path === config.APP_PATHS.backofficePaymentDocument) {
      return <Route component={PaymentDocument} exact />;
    } else if (path === config.APP_PATHS.PaymentDocumentSumEdit) {
      return <Route component={PaymentDocumentSumEdit} exact />;
    } else if (path === config.APP_PATHS.turnoversFiles) {
      return <Route component={TurnoversFiles} exact />;
    } else if (path === config.APP_PATHS.backofficeRepresentatives) {
      return <Route component={BackofficeAuthorization} exact />;
    } else if (path === config.APP_PATHS.AdditionalRequests) {
      return <Route component={AdditionalRequests} exact />;
    } else if (
      path === config.APP_PATHS.backofficePaymentRequest ||
      path2 === config.APP_PATHS.backofficePaymentRequest
    ) {
      return (
        <Route
          path="/backoffice/paymentrequest/:requestId"
          component={PaymentRequest}
          exact
        />
      );
    }
  } else if (true) {
    if (path === '/') {
      return <Redirect to={config.APP_PATHS.login} />;
    } else if (path === config.APP_PATHS.main) {
      return <Route component={Main} exact />;
    } else if (path === config.APP_PATHS.representative) {
      return <Route component={Representative} exact />;
    } else if (path === config.APP_PATHS.requests) {
      return <Route component={Requests} exact />;
    } else if (path === config.APP_PATHS.authorization) {
      return <Route component={Authorization} exact />;
    } else if (path === config.APP_PATHS.newRequest) {
      return <Route component={NewRequest} exact />;
    } else if (path === config.APP_PATHS.newRequest2) {
      return <Route component={NewRequest2} exact />;
    } else if (
      path === config.APP_PATHS.newRequest ||
      path2 === config.APP_PATHS.newRequest
    ) {
      return (
        <Route
          path={config.APP_PATHS.newRequestWithId}
          component={NewRequest}
          exact
        />
      );
    } else if (path === config.APP_PATHS.paymentrequests) {
      return <Route component={PaymentRequestsList} exact />;
    } else if (path === config.APP_PATHS.paymentrequest) {
      return <Route component={PaymentRequest} exact />;
    } else if (path === config.APP_PATHS.paymentDocument) {
      return <Route component={PaymentDocument} exact />;
    }
  }
  if (path === config.APP_PATHS.vaiisis) {
    return <Route component={LoginReload} exact />;
  } else if (tokenstring.length > 30) {
    // return null;
    return <Route component={Home} exact />;
  } else {
    return null;
    // return <Redirect to={config.APP_PATHS.login} />;
  }
};

export default connect()(PrivateRoute);
