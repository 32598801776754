import React, { useState, useEffect } from 'react';
import styles from './fileUploadContainerOld.module.scss';
import {
  WithSpinner,
  Button,
  TableAdmin,
  TableRow,
  TableCell,
} from '../../components';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { actions } from './../../state/actions';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { fileTypeTranslation } from './../../utils/translation/translation';
import { getAutorization } from '../../utils/helpers/helpers';

const FileUploadContainerOld = ({
  dispatch,
  loading,
  requestId,
  filesTableList,
  viewWindow,
  status,
}) => {
  const tableHeader = [
    'Priedo paskirtis',
    'Įkelto priedo pavadinimas',
    'Data',
    'Ar papildomas?',
    'Veiksmai',
  ];
  const [isLoading, setIsloading] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [additional, setAdditional] = useState(null);

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const gotoBottom = (id) => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const upload = (e) => {
    setIsloading(true);
    const file = e.target.files[0];

    if (file.size > 25 * 1048 * 1048) {
      setIsloading(false);

      swal({
        title: 'Įkeliamas priedas viršija 25mb leistiną limitą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
    } else {
      const formData = new FormData();
      formData.append('Files', file);
      formData.append('RequestId', requestId);
      formData.append('FileType', fileType);

      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadFiles, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.dashboard.getUploadedFileList(requestId));

            swal({
              title: 'Priedas sėkmingai įkeltas',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              let title = 'Nepavyko pašalinti priedo';
              if (r.ErrorDescription) {
                title = r.ErrorDescription;
              }
              swal({
                title: title,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
          setTimeout(() => {
            gotoBottom();
          }, 2000);
        });
      //  dispatch(actions.dashboard.fileUploadAction(formData, 'upload'));
    }
  };

  const onFileTypeSelectorChange = ({ target: { name, value } }) => {
    setFileType(value === '-' ? null : value);
    setAdditional(value === '-' ? false : false);
  };

  const [fileArr, setFileArray] = useState([]);
  const [rejection, setRejection] = useState(null);

  useEffect(() => {
    if (filesTableList && filesTableList.length !== 0 && status === 10) {
      setRejection(filesTableList.slice(-1)[0]);
      setFileArray([filesTableList]);
      setFileArray([...filesTableList].slice(0, filesTableList.length - 1));
    } else {
      setFileArray(filesTableList);
    }
  }, [filesTableList, status, rejection]);

  const deleteFile = (id) => {
    setIsloading(true);
    const requestIdFileId = `${requestId}/${id}`;
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DeleteUploadedFile(requestIdFileId),
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.dashboard.getUploadedFileList(requestId));

          swal({
            title: 'Priedas sėkmingai ištrintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else {
          swal({
            title: 'Nepavyko pašalinti priedo',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log('catch block');
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
        setTimeout(() => {
          gotoBottom();
        }, 2000);
      });
  };

  const deleteConfirmation = (id) => {
    swal({
      title: 'Ar tikrai norite ištrinti šį failą?',
      icon: 'warning',
      showCancelButton: true,

      buttons: {
        confirm: {
          text: 'Taip',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
        cancel: {
          text: 'Ne',
          value: false,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true,
        },
      },
    }).then((result) => {
      if (result) {
        deleteFile(id);
      }
    });
  };

  const downloadRejectionFile = (requestId, id, fileName) => {
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadFile(requestId, id), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: getAutorization(),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const acceptFiles = (fileType) => {
    if (fileType == 1 || fileType == 2) {
      return '.pdf';
    }

    if (fileType == 3) {
      return ' .doc, .docx, .pdf, .jpg';
    }

    if (fileType == 7 || fileType == 8) {
      return ' .pdf, .jpg';
    }

    if (fileType == 9) {
      return '.xlsx, .xls';
    }
    return '.xlsx, .xls, .doc, .docx, .adoc, .asice, .xml, .txt, .pdf';
  };

  const downloadAllFiles = () => {
    console.log('clicked');
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadAllFiles(requestId), {
      method: 'GET',
      headers: {
        'content-Type': 'application/zip',
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Visi_priedai_${requestId}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .finally(() => {
        setIsloading(false);
      });
    // dispatch(actions.backoffice.downloadAllFilesAction(requestId));
  };

  return (
    <WithSpinner loading={isLoading}>
      <div className={styles.container}>
        <h4>Priedų įkėlimas</h4>
        {fileArr != null && fileArr.length > 0 ? (
          <>
            <TableAdmin header={tableHeader} isEmpty={false}>
              {fileArr.map(
                ({
                  Id,
                  FileName,
                  FileType,
                  UploadedAt,
                  Additional,
                  Submitted,
                  AllowDelete,
                }) => (
                  <TableRow key={Id}>
                    <TableCell>{fileTypeTranslation(FileType)}</TableCell>
                    <TableCell>{FileName}</TableCell>
                    <TableCell>
                      {format(new Date(UploadedAt), 'yyyy-MM-dd')}
                    </TableCell>
                    <TableCell>{Additional ? 'Taip' : 'Ne'}</TableCell>
                    <TableCell>
                      <div className={styles.iconsContainer}>
                        {((Additional == true && status == 11) ||
                          !viewWindow &&
                          (Submitted == false || Submitted == null)) && (
                          <FontAwesomeIcon
                            icon={faTrash}
                            className={styles.icon}
                            onClick={() => deleteConfirmation(Id)}
                            style={{
                              color: '#597a96',
                            }}
                          />
                        )}

                        <FontAwesomeIcon
                          icon={faDownload}
                          className={styles.icon}
                          onClick={() =>
                            downloadRejectionFile(requestId, Id, FileName)
                          }
                          style={{
                            color: '#597a96',
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableAdmin>
            <br />
            <br />

            <Button
              type="standart"
              onClick={() => downloadAllFiles()}
              float="right"
            >
              Atsisiųsti visus įkeltus priedus
            </Button>
            <br />
            <br />
          </>
        ) : (
          <p className="nofileText">Nėra įkeltų priedų</p>
        )}
        {!viewWindow && (
          <div className={styles.container}>
            <div style={{ display: 'flex' }}>
              <div className={styles.selectflex}>
                <label>Prisegamo priedo paskirtis</label>
                <select
                  disabled={viewWindow}
                  name="InvoiceType"
                  style={{
                    background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                    height: '44px',
                  }}
                  id="scrollToFromTop"
                  onChange={onFileTypeSelectorChange}
                >
                  <option selected={fileType == null} value={null}>
                    {' '}
                    -{' '}
                  </option>
                  <option selected={fileType == 1} value={1}>
                    SVV subjekto statuso deklaracija
                  </option>
                  <option selected={fileType == 2} value={2}>
                    Vienos įmonės deklaracija
                  </option>
                  <option selected={fileType == 3} value={3}>
                    Verslo subjektą apibūdinantys dokumentai
                  </option>
                  <option selected={fileType == 4} value={4}>
                    Verslininko individualios veiklos registracijos dokumentai
                  </option>
                  <option selected={fileType == 5} value={5}>
                    Verslo planas (Projekto aprašymas)
                  </option>
                  <option selected={fileType == 6} value={6}>
                    Privataus finansuotojo ketinimo dalyvauti Projekte raštas
                  </option>
                  <option selected={fileType == 7} value={7}>
                    Einamųjų finansinių metų tarpinė finansinė ataskaita arba
                    lygiaverčiai dokumentai
                  </option>
                  <option selected={fileType == 8} value={8}>
                    Verslo subjekto valdymo organo sprendimas dėl Paskolos
                    gavimo, turto įkeitimo ir įgaliotų asmenų paskyrimo
                    sutarties su Paskolos davėju sudarymui
                  </option>
                  <option selected={fileType == 9} value={9}>
                    Paskolos gavėjo veiklos (finansinių) duomenų detalizacijos
                    forma
                  </option>
                  <option selected={fileType == 11} value={11}>
                    Paskolos gavėjo atitikimo žemės ūkio inovacinei veiklai
                    aprašymas (Priedas Nr. 1)
                  </option>
                  <option selected={fileType == 10} value={10}>
                    Kiti dokumentai
                  </option>
                </select>
              </div>
              <span
                className={styles.disabled}
                style={{
                  width: '155px',
                  marginTop: '-5px',
                  marginLeft: '10px',
                }}
              >
                <span className={styles.textUptheButton}>
                  Pasirinkti priedą
                </span>

                <button
                  className={
                    fileType
                      ? 'btn ui-button btn-dark-blue-standart'
                      : 'btn ui-button btn-dark-blue-disabled'
                  }
                  type="longBtn"
                  onClick={() => selectFile('fileElem')}
                  disabled={!fileType}
                  style={{ marginTop: '9px', padding: '9px' }}
                >
                  PASIRINKTI PRIEDĄ
                </button>
                <input
                  type="file"
                  id="fileElem"
                  accept={acceptFiles(fileType)}
                  className="d-none"
                  onChange={upload}
                />
              </span>
            </div>
            <p className={styles.note}>
              Prisekite reikiamas rinkmenas. <br />
              Galimi priedų formatai: .xlsx, .xls, .doc, .docx, .adoc, .asice,
              .xml, .txt, .pdf. Maksimalus dydis 20MB. <br /> <br />-{' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq296"
                target="_blank"
                rel="noreferrer noopener"
              >
                Įmonės vadovo pasirašyta SVV deklaracija
              </a>
              *;
              <br />-{' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq290"
                target="_blank"
                rel="noreferrer noopener"
              >
                Įmonės vadovo pasirašyta Vienos įmonės deklaracija
              </a>
             ;
              <br /> <br />
              <p>
                <b>Privalomi pateikti priedai yra šie:</b>
                <br />
                - SVV subjekto statuso deklaracija (1);
                <br />
                - Vienos įmonės deklaracija (2);
                <br />
                - Verslo subjektą apibūdinantys dokumentai (3);
                <br />
                - Verslininko individualios veiklos registracijos dokumentai
                (4);
                <br />
                - Verslo planas (Projekto aprašymas) (5);
                <br />
                - Privataus finansuotojo ketinimo dalyvauti Projekte raštas (6);
                <br />
                - Einamųjų finansinių metų tarpinė finansinė ataskaita arba
                lygiaverčiai dokumentai (7);
                <br />
                - Verslo subjekto valdymo organo sprendimas dėl Paskolos gavimo,
                turto įkeitimo ir įgaliotų asmenų paskyrimo sutarties su
                Paskolos davėju sudarymui;
                <br />- {' '}
                  <a
                href="https://invega.lt/lt/doclib/qjgabty4fze4se22wwbvd4myc9mq3hg3"
                target="_blank"
                rel="noreferrer noopener"
              >
                  Paskolos gavėjo veiklos (finansinių) duomenų
                detalizacijos forma
                </a>
                <br />- Paskolos gavėjo atitikimo žemės ūkio inovacinei veiklai
                aprašymas (
                  <a
                href="https://invega.lt/lt/doclib/ox6ppkhbw4hccq5euuaz19wexjvd8hzq"
                target="_blank"
                rel="noreferrer noopener"
              >
                  Priedas Nr. 1
                </a>
                ) (8)
                <br />- Kiti dokumentai.
              </p>
              <br />
              <b>Pastaba:</b> <br />
              (1) įmonės Vadovo pasirašyta SVV subjekto statuso deklaracija
              (išskyrus atvejį, kai Paskolos gavėjas patvirtina, kad yra didelė
              įmonė);
              <br />
              (2) taikoma Socialiniam verslui, kuriam yra taikoma palūkanų
              nuolaida ir kuris yra vidutinė įmonė, arba labai maža arba maža
              įmonė, veikianti ilgiau nei penkerius metus;
              <br />
              (3) registracijos dokumentai, nuostatai, vadovo asmens tapatybės
              dokumento kopijos, informacija apie akcininkus (savininkus, naudos
              gavėjus), aktualią Paskolos gavėjo akcininkų struktūrą ir laisvos
              formos dokumentą, kuriame būtų nurodyti Verslo subjekto ir su juo
              susijusių įmonių ryšiai (netaikoma verslininkams);
              <br />
              (4) asmens tapatybės dokumento kopija ir vadovo pasirašytas
              laisvos formos dokumentas, kuriame būtų nurodyti verslininko ir su
              juo susijusių įmonių ryšiai (Nurodant fizinių asmenų asmens kodus,
              jei ne Lietuvos piliečiai - datas);
              <br />
              (5) verslo planas (Projekto aprašymas), parengtas laikantis
              rekomenduojamų reikalavimų verslo planui, kuriame, be kita ko,
              būtų aprašytas vykdomas verslas, esama ir prognozuojama finansinė
              būklė, siūlymas dėl Paskolos įmokų mokėjimo grafiko išdėstymo ir
              užtikrinimo priemonių. Socialinį verslą vykdantis Verslo subjektas
              ar Verslo subjektas, kuris finansuojamu Projektu siekia socialinio
              poveikio ir įgyvendinus Projektą atitiks Socialinio verslo sąvoką,
              teikdamas paraišką Verslo subjektas Paskolos davėjui turi pateikti
              verslo planą, kuriame būtų nustatyti aiškūs, pamatuojami
              pagrindiniai socialinio teigiamo poveikio rodikliai, kurių bus
              siekiama įgyvendinant Projektą (teigiamas socialinis poveikis –
              prognozuojamas ir išmatuojamas poveikis, kuris teigiama linkme
              keičia socialinę padėtį visuomenėje ar jos grupėje). Taip pat turi
              būti pateiktos finansinės veiklos prognozės (skaičiavimai),
              atspindintys Verslo subjekto galimybę laiku mokėti įmokas pagal
              visus esamus ir būsimus Verslo subjekto finansinius
              įsipareigojimus visam Paskolos terminui;
              <br />
              (6) Verslo subjektas, kartu su paraiška Paskolai gauti, turi
              pateikti Privataus finansuotojo ketinimo dalyvauti Projekte raštą.
              Raštas teikiamas tuo atveju, kai Privatus finansuotojas ketina
              suteikti paskolą. Jei Paskolos gavėjas ketina Projekte dalyvauti
              turimomis lėšomis, tokiu atveju ši informacija turi būti
              pagrindžiama verslo plane, kuris teikiamas kartu su paraiška
              Paskolai gauti;
              <br />
              (7) kai verslininkai arba įmonės veikia trumpiau nei vienerius
              metus.
              <br />
              (8) Aprašymo priedas Nr. 1, taikoma tik Paskolos gavėjams, kurie
              veikia žemės ūkio sektoriuje ir vykdo arba ketina vykdyti
              inovacinę veiklą.
              <br />
            </p>
          </div>
        )}
      </div>
      {rejection && (
        <div className={styles.container}>
          <h4> Sutarties nutraukimo dokumentas </h4>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              downloadRejectionFile(requestId, rejection.Id, rejection.FileName)
            }
          >
            {rejection.FileName}
          </p>
        </div>
      )}
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  filesTableList: state.dashboardState.filesTableList,
});

export default connect(mapStateToProps)(FileUploadContainerOld);
