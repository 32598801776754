import { constants } from './constants';
import { takeLatest, all, call } from 'redux-saga/effects';

import * as dashboardSaga from './dashboard/DashboardSaga';
import * as backofficeSaga from './backoffice/BackofficeSaga';
import * as modalSaga from './modals/ModalsSaga';

import * as saga from '.';

function* actionWatcher() {
  yield takeLatest(
    constants.dashboard.LOAD_INVOICE_LIST_DATA,
    dashboardSaga.loadSellerInvoiceList
  );

  yield takeLatest(
    constants.dashboard.LOAD_REQUEST_LIMIT_DATA,
    dashboardSaga.getRequestLimit
  );

  yield takeLatest(
    constants.dashboard.LOAD_REQUEST_GENERAL_LIMIT_DATA,
    dashboardSaga.getRequestGeneralLimit
  );

  yield takeLatest(
    constants.dashboard.LOAD_INVOICE_APPROVE_LIST_DATA,
    dashboardSaga.loadBuyerInvoiceList
  );

  yield takeLatest(
    constants.dashboard.GET_REQUEST_LIST,
    dashboardSaga.getRequestListSaga
  );

  yield takeLatest(
    constants.dashboard.CREATE_NEW_REQUEST,
    dashboardSaga.createNewRequestSaga
  );

  yield takeLatest(
    constants.dashboard.CREATE_NEW_REQUEST_INDIVIDUAL,
    dashboardSaga.createNewRequestIndividualSaga
  );

  yield takeLatest(
    constants.dashboard.SAVE_REQUEST,
    dashboardSaga.saveRequestSaga
  );

  yield takeLatest(
    constants.dashboard.DELETE_REQUEST,
    dashboardSaga.deleteRequestSaga
  );

  yield takeLatest(
    constants.dashboard.LOAD_INVOICE_DATA,
    dashboardSaga.loadInvoiceData
  );

  yield takeLatest(
    constants.dashboard.LOAD_OWNER_INVOICE_DATA,
    dashboardSaga.loadOwnerInvoiceData
  );

  yield takeLatest(
    constants.dashboard.SAVE_INVOICE_DATA,
    dashboardSaga.saveInvoiceData
  );

  yield takeLatest(
    constants.dashboard.SAVE_COMPANY_DATA,
    dashboardSaga.saveCompanyData
  );

  yield takeLatest(
    constants.modals.SET_MODAL_CONFIRM_ACTION,
    modalSaga.modalConfirm
  );

  yield takeLatest(
    constants.modals.SET_PAYMENT_MODAL_CONFIRM_ACTION,
    modalSaga.paymentModalConfirm
  );

  yield takeLatest(
    constants.modals.CONFIRM_INVOICES_FROM_MANAGER,
    modalSaga.confirmInvoicesFromManagerSaga
  );

  yield takeLatest(
    constants.modals.REGENERATE_DECISION_DOC,
    modalSaga.regenerateDecisionDocSaga
  );

  yield takeLatest(
    constants.dashboard.PASS_COMPANY_INFO_ACTION,
    dashboardSaga.passCompanyInfoSaga
  );

  yield takeLatest(
    constants.dashboard.GET_IS_CONSENTAGREED,
    dashboardSaga.getIsConsentAgreedSaga
  );

  yield takeLatest(
    constants.dashboard.LOAD_GENERAL_DATA,
    dashboardSaga.loadGeneralData
  );

  yield takeLatest(
    constants.dashboard.FILE_UPLOAD,
    dashboardSaga.fileUploadSaga
  );

  yield takeLatest(
    constants.dashboard.GET_UPLOADED_FILES_LIST,
    dashboardSaga.getUploadedFileListSaga
  );

  yield takeLatest(
    constants.dashboard.GET_FINANCIALCRITERIA_RESULTS,
    dashboardSaga.getFinancialCriteriaResultsSaga
  );

  yield takeLatest(
    constants.dashboard.SEND_FOR_APPROVE,
    dashboardSaga.sendForApproveSaga
  );

  yield takeLatest(
    constants.dashboard.SEND_FOR_APPROVE_WITH_DOCS,
    dashboardSaga.sendForApproveWithDocsSaga
  );

  yield takeLatest(
    constants.dashboard.SEND_PAYMENT_REQUEST_FOR_APPROVE,
    dashboardSaga.paymentRequestSendForApproveSaga
  );
  yield takeLatest(
    constants.dashboard.SEND_FOR_APPROVE_WITH_DOCS_FOR_CHANGE_CONDITIONS,
    dashboardSaga.sendForApproveWithDocsForChangeConditionsSaga
  );

  yield takeLatest(
    constants.dashboard.GET_SIGNING_URL,
    dashboardSaga.getSigningUrlSaga
  );

  yield takeLatest(
    constants.dashboard.GET_SIGNING_DECISION_URL,
    dashboardSaga.getSigningDecisionUrlSaga
  );

  yield takeLatest(
    constants.dashboard.GET_SIGNING_URL_BATCH,
    dashboardSaga.GetSigningUrlBatchSaga
  );

  yield takeLatest(
    constants.dashboard.SAVE_REPRESENTATIVE,
    dashboardSaga.saveRepresentative
  );

  yield takeLatest(
    constants.dashboard.LOAD_REPRESENTATIVES,
    dashboardSaga.loadRepresentatives
  );

  yield takeLatest(
    constants.dashboard.LOAD_COMPANY_REPRESENTATIVES,
    dashboardSaga.loadCompanyRepresentatives
  );

  yield takeLatest(constants.auth.LOGIN_VAIISIS, saga.loginVaiisisSaga);
  yield takeLatest(constants.auth.LOGIN_USER, saga.loginUserSaga);
  yield takeLatest(
    constants.auth.LOGIN_VAIISIS_TEST_CRED,
    saga.loginVaiisisTestSaga
  );
  yield takeLatest(constants.auth.CLEAR_USER, saga.clearUserSaga);
  yield takeLatest(
    constants.auth.PUSH_TOKEN_TO_SERVER,
    saga.pushTokenToServerSaga
  );

  yield takeLatest(
    constants.backoffice.LOAD_REQUESTS_LIST,
    backofficeSaga.getRequestsListSaga
  );

  yield takeLatest(
    constants.backoffice.GET_REQUEST,
    backofficeSaga.getRequestSaga
  );

  yield takeLatest(
    constants.backoffice.GET_PAYMENT_REQUEST,
    backofficeSaga.getRequestSaga
  );

  yield takeLatest(
    constants.backoffice.LOAD_INVOICE_LIST_DATA,
    backofficeSaga.getInvoicesSaga
  );

  yield takeLatest(
    constants.backoffice.LOAD_INVOICE_DATA,
    backofficeSaga.getInvoiceSaga
  );

  yield takeLatest(
    constants.backoffice.EXPORT_DATA_ACTION,
    backofficeSaga.exportDataSaga
  );

  yield takeLatest(
    constants.backoffice.GET_UPLOADED_FILES_LIST,
    backofficeSaga.getUploadedFileListSaga
  );

  yield takeLatest(constants.backoffice.GET_STATS, backofficeSaga.getStatsSaga);

  yield takeLatest(
    constants.backoffice.DOWNLOAD_CONTRACT_DOC,
    backofficeSaga.downloadContractSaga
  );

  yield takeLatest(
    constants.backoffice.DOWNLOAD_LIST_ACTION,
    backofficeSaga.downloadListSaga
  );

yield takeLatest(
    constants.backoffice.DOWNLOAD_ALL_PR_FILES,
    backofficeSaga.downloadAllPrFiles
  );

  yield takeLatest(
    constants.backoffice.GET_SIGN_IN_DOC,
    backofficeSaga.getSignInDocSaga
  );

  yield takeLatest(
    constants.backoffice.LOAD_REPRESENTATIVES,
    backofficeSaga.loadRepresentatives
  );

  yield takeLatest(
    constants.backoffice.SAVE_REPRESENTATIVE,
    backofficeSaga.saveRepresentative
  );

  yield takeLatest(
    constants.backoffice.SAVE_EDIT_ADDITIONAL_COMMENTS,
    backofficeSaga.saveEditAdditionalCommentsSaga
  );

  yield takeLatest(
    constants.backoffice.SAVE_RISK_ASSESMENT_RESULT,
    backofficeSaga.saveRiskAssessmentResultSaga
  );

  yield takeLatest(
    constants.backoffice.GET_REQUEST_LOG,
    backofficeSaga.getRequestLog
  );

  yield takeLatest(
    constants.backoffice.GET_INVEGA_USERS,
    backofficeSaga.getInvegaUsers
  );

  yield takeLatest(
    constants.backoffice.CALL_SAGA_FOR_ERR_HANDLE,
    backofficeSaga.callSagaForErrHandle
  );

  yield takeLatest(
    constants.dashboard.GET_PAYMENT_REQUESTS_LIST,
    dashboardSaga.getPaymentRequestListSaga
  );

  yield takeLatest(
    constants.dashboard.CREATE_NEW_PAYMENT_REQUEST,
    dashboardSaga.createNewPaymentRequestSaga
  );

  yield takeLatest(
    constants.dashboard.CREATE_PR_NEW_PR_DOCUMENT_REQUEST,
    dashboardSaga.createPaymentRequestNewPrDocumentSaga
  );

  yield takeLatest(
    constants.dashboard.CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT,
    dashboardSaga.createPaymentRequestPrDocumentSaga
  );

  yield takeLatest(
    constants.dashboard.CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT_PAY_BACK,
    dashboardSaga.createPaymentRequestPrDocumentPayBackSaga
  );

  yield takeLatest(
    constants.dashboard.PR_DOCUMENT_DELETE_REQUEST,
    dashboardSaga.deletePrDocumentRequestSaga
  );
}

export default function* rootSaga() {
  yield all([call(actionWatcher)]);
}
