import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { WithSpinner, TableAdmin, TableRow, TableCell } from '../../components';
// import { RequirementsText } from '../../containers';

import styles from './requests.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faPencilAlt,
  faTrash,
  faPlusSquare,
  faFileAlt,
  faEuroSign,
} from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

import DeleteComfirmationModal from '../../modals/deleteComfirmationModal/deleteComfirmationModal';
import GlobalMessageModal from './../../modals/globalMessageModal/globalMessageModal';

import { format } from 'date-fns';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../../api/config';
import { Api } from '../../api/api';
import { requestStatus } from './../../utils/translation/translation';
import { formatNumber } from './../../utils/helpers/helpers';
import {
  getLocalStorageItemIfNotExpired,
  resetLocalStorageItem,
  getLocalStorageItem,
} from '../../utils';

const Requests = ({
  requestList,
  dispatch,
  loading,
  deleteComfirmationModalVisible,
  globalMessageModalVisible,
  canCreateRequest,
}) => {
  const tableHeader = [
    'Paraiškos numeris',
    'Pareiškėjas',
    'Pareiškėjo kodas',
    'Sukūrimo data',
    'Pateikimo vertinimui data',
    'Paskolos suma, EUR',
    'Būsena',
    'Liko dienų paraiškos patikslinimui',
    'Liko dienų sutarties pasirašymui',
    'Atsakingas darbuotojas',
    'Telefonas pasiteiravimui',
    'Veiksmai',
  ];

  const [selectedId, setSelectedId] = useState(null);
  const [editable, setEditable] = useState(false);

  const [deletingItemId, setDeletingItemId] = useState(null);

  const [openedMessageText, setOpenedMessagetext] = useState('');

  const openMessageModal = (message) => {
    setOpenedMessagetext(message);
    const open = {
      modalName: 'globalMessageModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
    return message;
  };

  const actionClick = (action, Id) => {
    const loginType = getLocalStorageItemIfNotExpired('loginType');

    if (action === 'view') {
      if (loginType === 'Company') {
        dispatch(actions.dashboard.createNewRequestAction(Id, 'view'));
      } else if (loginType === 'Individual') {
        dispatch(
          actions.dashboard.createNewRequestIndividualAction(Id, 'view')
        );
      } else {
        swal({
          title: 'Įvyko klaida',
          icon: 'Error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      }

      setEditable(false);
      setSelectedId(Id);
    } else if (action === 'edit') {
      setEditable(false);
      setSelectedId(Id);

      if (loginType === 'Company') {
        dispatch(actions.dashboard.createNewRequestAction(Id, 'edit'));
      } else if (loginType === 'Individual') {
        dispatch(
          actions.dashboard.createNewRequestIndividualAction(Id, 'edit')
        );
      } else {
        swal({
          title: 'Įvyko klaida',
          icon: 'Error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      }
    } else if (action === 'delete') {
      setDeletingItemId(Id);

      const open = {
        modalName: 'deleteComfirmationModal',
        visible: true,
      };

      dispatch(actions.modals.setModalVisibleFlagAction(open));
    }
  };

  const fetchdata = useCallback(() => {
    dispatch(actions.dashboard.getRequestListAction());
  }, [dispatch, requestList]);

  const createJustOneValidRequest = () => {
    const validate = requestList.filter((val) => {
      return val.Status !== 1 || val.Status !== 5 || val.Status !== 6;
    });
    return validate.length < 2 ? true : false;
  };

  const [canCreateNewRequest, setCanCreateNewRequest] = useState(false);
  const addNewRequest = () => {
    const loginType = getLocalStorageItemIfNotExpired('loginType');

    console.log(canCreateNewRequest);

    // call cancreate request

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.canCreateRequest, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.ok === true) {
          res.json().then((r) => {
            if (r) {
              setCanCreateNewRequest(true);
              if (loginType === 'Company') {
                dispatch(actions.dashboard.createNewRequestAction());
              } else if (loginType === 'Individual') {
                dispatch(actions.dashboard.createNewRequestIndividualAction());
              } else {
                swal({
                  title: 'Klaida',
                  icon: 'Error',
                  buttons: {
                    confirm: {
                      text: 'Gerai',
                      value: true,
                      visible: true,
                      className: 'btn swal-ok-btn',
                      closeModal: true,
                    },
                  },
                });
              }
            } else {
              swal({
                title:
                  'Vienam paskolos gavėjui iš Priemonės lėšų gali būti suteikta tik viena paskola. Jūs jau esate pateikę paraišką paskolai gauti.',
                icon: 'info',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        console.log(error.status);
      })
      .finally(() => {
        setCanCreateNewRequest(false);

        // dispatch(actions.dashboard.setUserOnSyncFlagAction(false));
        // setLocalLoading(false);
      });
  };

  const openPaymentRequest = (id) => {
    dispatch(actions.dashboard.getPaymentRequestsListAction(id));
  };

  useEffect(() => {
    // dispatch(actions.dashboard.clearUserStateAction());
    dispatch(actions.dashboard.setNewRequestData({}));

    fetchdata();
    dispatch(actions.dashboard.setSavedRequestDesissionAccepted(false));
    dispatch(actions.auth.pushTokenToServerAction());

    const role = getLocalStorageItem('role');
    if (!role) {
      resetLocalStorageItem('Role', 0);
    }
  }, []);

  const returnContantEmail = (status, assesorEmail) => {
    const assessorDic = [2, 3, 4, 5, 13, 17, 18, 19];
    const operationDic = [7, 8, 9, 10, 11, 12, 14, 15, 16];

    if (assessorDic.some((val) => val === status)) {
      return assesorEmail || 'uzklausos@invega.lt';
    }

    if (operationDic.some((val) => val === status)) {
      return 'operacijos@invega.lt';
    }

    if (status === 1 || status === 6) {
      return 'uzklausos@invega.lt';
    }

    return 'uzklausos@invega.lt';
  };

  return (
    <WithSpinner loading={loading}>
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          textAlign: 'center',
          marginLeft: '10%',
          marginRight: '10%',
        }}
      >
        {/*<div class="pf-content">
          <p>
            Informuojame, kad INVEGAI gavus paraiškų už visą Priemonei skirtą
            sumą, Jūsų pateikta paraiška bus įtraukta į rezervinį sąrašą ir bus
            vertinama pagal paraiškų pateikimo datą tuo atveju, jei
            atsilaisvintų priemonei skirtų lėšų arba būtų nuspręsta skirti
            papildomą finansavimą priemonei įgyvendinti.
          </p>
      </div>*/}
      </div>
      <div style={{ width: '96%', marginLeft: '2%' }}>
        <h2>Paraiškos</h2>

        <TableAdmin header={tableHeader} isEmpty={false}>
          {requestList &&
            requestList?.map(
              ({
                Id,
                CompanyName,
                CompanyCode,
                CreateDate,
                SubmittedDate,
                Status,
                DaysTillAdjust,
                DeadlineBusinessDaysUntilSignature,
                InvegaLoanSum,
                AssesorEmail,
                AssesorPhone,
                FormVersion,
              }) => (
                <TableRow key={Id}>
                  <TableCell style={{ width: '30px' }}>{Id}</TableCell>
                  <TableCell>{CompanyName}</TableCell>
                  <TableCell>{CompanyCode}</TableCell>
                  <TableCell style={{ width: '200px' }}>
                    {CreateDate !== null &&
                      format(new Date(CreateDate), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>
                    {SubmittedDate !== null &&
                      format(new Date(SubmittedDate), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>{formatNumber(InvegaLoanSum)}</TableCell>
                  <TableCell>{requestStatus(Status)}</TableCell>

                  <TableCell>{Status === 5 && DaysTillAdjust} </TableCell>
                  <TableCell>
                    {Status === 7 && DeadlineBusinessDaysUntilSignature}{' '}
                  </TableCell>
                  <TableCell>
                    {returnContantEmail(Status, AssesorEmail)}
                  </TableCell>
                  <TableCell>
                    {AssesorPhone ? AssesorPhone : '(85) 210 7510'}
                  </TableCell>

                  <TableCell>
                    <div className={styles.iconsContainer}>
                      {/* <Link to={`${config.APP_PATHS.newRequest}/${Id}`}> */}
                      <Link
                        to={`${
                          (FormVersion === 2 || FormVersion === 3)
                            ? config.APP_PATHS.newRequest
                            : config.APP_PATHS.newRequest2
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          className={styles.icon}
                          onClick={() => actionClick('view', Id)}
                          style={{
                            color: '#009fe3',
                          }}
                        />
                      </Link>
                      {(Status === 1 || Status === 5) && DaysTillAdjust >= 0 && (
                        // <Link to={`${config.APP_PATHS.newRequest}/${Id}`}>
                        <Link
                        to={`${
                          (FormVersion === 2 || FormVersion === 3)
                            ? config.APP_PATHS.newRequest
                            : config.APP_PATHS.newRequest2
                        }`}
                      >
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={styles.icon}
                            onClick={() => actionClick('edit', Id)}
                            style={{
                              color: '#005eae',
                            }}
                          />
                        </Link>
                      )}

                  {(Status === 1 || Status === 5) && DaysTillAdjust < 0 && (
                        // <Link to={`${config.APP_PATHS.newRequest}/${Id}`}>
                        
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={styles.icon}
                            onClick={() => openMessageModal('Paraiškos tikslinimo terminas yra pasibaigęs, todėl tikslinti paraiškos nebegalite.')}
                            style={{
                              color: '#005eae',
                            }}
                          />
                      
                      )}

                      {(Status === 9 ||
                        Status === 13 ||
                        Status === 14 ||
                        Status === 15 ||
                        Status === 16 ||
                        Status === 17 ||
                        Status === 18 ||
                        Status === 19) && (
                        <Link
                          to={`${config.APP_PATHS.paymentrequests}?requestId=${Id}`}
                        >
                          <FontAwesomeIcon
                            icon={faEuroSign}
                            className={styles.icon}
                            onClick={() => openPaymentRequest(Id)}
                            style={{
                              color: '#009fe3',
                            }}
                          />
                        </Link>
                      )}

                      {(Status === 1 || Status === 6) && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={styles.icon}
                          onClick={() => actionClick('delete', Id)}
                          style={{
                            color: '#597a96',
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
        </TableAdmin>
        <Link
          to={
            canCreateNewRequest
              ? config.APP_PATHS.newRequest
              : config.APP_PATHS.newRequest
          }
        >
          <FontAwesomeIcon
            size="4x"
            icon={faPlusSquare}
            className={styles.icon}
            style={{ float: 'right', width: '26px', color: '#033878' }}
            onClick={() => addNewRequest()}
          />
        </Link>
      </div>
      <DeleteComfirmationModal
        open={deleteComfirmationModalVisible}
        id={deletingItemId}
      />
      <GlobalMessageModal
        message={openedMessageText}
        open={globalMessageModalVisible}
      />

      {canCreateNewRequest ? (
        <Redirect to={config.APP_PATHS.newRequest} />
      ) : null}
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  requestList: state.dashboardState.requestList,
  loading: state.dashboardState.isOnSync,
  deleteComfirmationModalVisible:
    state.modalsState.deleteComfirmationModalVisible,
  canCreateRequest: state.dashboardState.canCreateRequest,
  globalMessageModalVisible: state.modalsState.globalMessageModalVisible,
});

export default connect(mapStateToProps)(Requests);
