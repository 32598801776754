import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  TableAdmin,
  WithSpinner,
  TableRow,
  TableCell,
  Button,
} from '../../components';
import swal from 'sweetalert';
import styles from '../newRequest/newRequest.module.scss';
import InputWithTextInline from '../../components/input/InputWithTextInline';
import { format } from 'date-fns';
import paymentReqStyles from './paymentRequest.module.scss';
import { config } from '../../api/config';
import PaymentRequestSendforApproveModal from '../../modals/paymentRequestSendforApproveModal/paymentRequestSendforApproveModal';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faPencilAlt,
  faTrash,
  faPlusSquare,
  faBrush,
  faFilePen,
  faDownload,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {
  documentType,
  loanPurpose,
  paymentRequestStatus,
} from '../../utils/translation/translation';
import {
  checkBOPage,
  formatNumber,
  downloadListXlsFile,
} from '../../utils/helpers/helpers';
import PaymentRequestFileUploadContainer from './../../containers/fileUploadContainer/paymentRequestFileUploadContainer';
import { Api } from '../../api/api';
import { getLocalStorageItemIfNotExpired } from './../../utils/auth/localStorage';
import ManagerActionLog from './managerActionLog';
import { getAutorization } from '../../utils/helpers/helpers';

const PaymentRequest = ({
  dispatch,
  requestsState,
  loading,
  paymentRequest,
  paymentRequestSendforApproveModalVisible,
  confirmModalVisible,
  closedDialogResponse,
}) => {
  const url = new URL(window.location.href);
  const paymentRequestId = url.searchParams.get('paymentRequestId');
  const requestId = url.searchParams.get('requestId');
  const actionType = url.searchParams.get('actionType');

  const fetchdata = useCallback(() => {
    if (requestId && paymentRequestId) {
      dispatch(
        actions.dashboard.createNewPaymentRequestAction(
          requestId,
          'edit',
          paymentRequestId
        )
      );
    }
  }, [dispatch, paymentRequest]);

  useEffect(() => {
    if (!loading) {
      fetchdata();
    }
    if (requestsState && requestsState.data) {
      setComments(
        requestsState.data.Comments ? requestsState.data.Comments : ''
      );

      const checkboxValues =
        paymentRequest &&
        paymentRequest.DocumentsInfo &&
        paymentRequest.DocumentsInfo.Documents.filter((val) => !val.IsApproved);
      const checkedArray =
        checkboxValues && checkboxValues.length > 0
          ? checkboxValues.map((val) => val.Id)
          : [];
      setCheckboxValues(checkedArray);

      const checkboxSfValues =
        paymentRequest &&
        paymentRequest.DocumentsInfo &&
        paymentRequest.DocumentsInfo.Documents.filter((val) => val.Unpaid);
      const checkedSfArray =
        checkboxSfValues && checkboxSfValues.length > 0
          ? checkboxSfValues.map((val) => val.Id)
          : [];
      setCheckboxSfValues(checkedSfArray);
    }
  }, [paymentRequest]);

  const formDate = (value = '2022-02-22') => {
    //return value;  const formDate = (value) => {
    return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
  };
  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalMainText, setModalMainText] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const [comments, setComments] = useState('');

  const onCommentChange = ({ target: { name, value } }) => {
    setComments(value);
  };

  const approvePaymentRequest = () => {
    const id = url.searchParams.get('requestId');
    setModalData({ id });

    dispatch(
      actions.modals.setPaymentModalConfirmAction(id, 'APPROVE_PAYMENT_REQUEST')
    );
  };

  const returnClick = () => {
    const id = paymentRequest.ContractInfo.Id;
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText('Ar tikrai norite grąžinti mokėjimo prašymą tikslinimui?');
    setModalData({ id, comments, checkboxValues, requestId });
    setModalAction('INVEGA_RETURN_PAYMENT_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const rejectClick = () => {
    const id = paymentRequest.ContractInfo.Id;
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText(
      'Ar tikrai norite atmesti mokėjimo prašymą?<br />Sprendimas negalės būti pakeistas.'
    );
    setModalData({ id, comments, requestId });
    setModalAction('INVEGA_REJECT_PAYMENT_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const confirmClick = () => {
    const id = paymentRequest.ContractInfo.Id;
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText(
      'Ar tikrai norite patvirtinti mokėjimo prašymą?<br />Sprendimas negalės būti pakeistas.'
    );
    setModalData({ id, comments, requestId });
    setModalAction('INVEGA_CONFIRM_PAYMENT_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const downloadFilePrdAll = async () => {
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadFilePrdAll(paymentRequestId), {
      method: 'GET',
      headers: {
        'content-Type': 'application/zip',
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Visi_priedai_${requestId}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .finally(() => {
        setIsloading(false);
      });
    // dispatch(actions.backoffice.downloadAllFilesAction(requestId));
  };

  const contractInfo = [
    {
      title: 'Mokėjimo prašymo numeris',
      value: paymentRequest?.ContractInfo?.Number || '-',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'Number',
    },
    {
      title: 'Mokėjimo prašymo (pirmo) pateikimo data',
      value: paymentRequest?.ContractInfo?.FirstSubmittedDate
        ? formDate(paymentRequest?.ContractInfo?.FirstSubmittedDate)
        : '-',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'FirstSubmittedDate',
    },
    {
      title: 'Mokėjimo prašymo (paskutinio) pateikimo data',
      value: paymentRequest?.ContractInfo?.SubmittedDate
        ? formDate(paymentRequest?.ContractInfo?.SubmittedDate)
        : '-',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'SubmittedDate',
    },
    {
      title: 'Mokėjimo prašymo būsena',
      value: paymentRequestStatus(
        paymentRequest?.ContractInfo?.PaymentRequestStatusId
      ),
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'paymentRequestStatus',
      name: 'PaymentRequestStatusId',
    },
    {
      title: 'Paskolos gavėjo pavadinimas/Vardas pavardė',
      value: paymentRequest?.ContractInfo?.Name,
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'Name',
    },
    {
      title: 'Paskolos gavėjo kodas',
      value: paymentRequest?.ContractInfo?.Code,
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'Code',
    },
    {
      title: 'Paskolos sutarties Nr.',
      value: paymentRequest?.ContractInfo?.ContractNumber || '-',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'ContractNumber',
    },
  ];

  const financialInfo = [
    {
      title: 'Suteikta paskolos suma, EUR',
      value: formatNumber(paymentRequest?.FinancialInfo?.InvegaLoanSum || 0),
      type: 'string',
      disabled: true,
      fieldType: 'input',
    },
    {
      title: 'Paskolos sumos likutis, EUR',
      value: formatNumber(paymentRequest?.FinancialInfo?.LoanSumRemainder || 0),
      type: 'string',
      disabled: true,
      fieldType: 'input',
    },
    {
      title: 'Paskolos paskirtis',
      value: loanPurpose(paymentRequest?.FinancialInfo?.LoanPurposeId) || '-',
      type: 'string',
      disabled: true,
      fieldType: 'input',
    },
    {
      title: 'Finansuojama suma pagal šį mokėjimo prašymą, EUR',
      value: formatNumber(
        paymentRequest?.DocumentsInfo?.Totals?.FundedSumTotal || 0
      ),
      type: 'string',
      disabled: true,
      fieldType: 'input',
      showWhenStatus: [2],
    },
    {
      title: 'Išmokėta paskolos suma pagal paskolos tipą proc.',
      value: `${formatNumber(
        paymentRequest?.DocumentsInfo?.Totals?.InvestmentSumTotalPercent +
          paymentRequest?.DocumentsInfo?.Totals?.TurnoverSumTotalPercent || 0,
        4
      )}%`,
      type: 'string',
      disabled: true,
      fieldType: 'input',
      showWhenStatus: [2],
    },
    {
      title: 'Investijoms',
      value: `${formatNumber(
        paymentRequest?.DocumentsInfo?.Totals?.InvestmentSumTotalPercent || 0,
        4
      )}%`,
      additionalSymbols: '%',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      showWhenStatus: [2],
    },
    {
      title: 'Apyvartai',
      value: `${formatNumber(
        paymentRequest?.DocumentsInfo?.Totals?.TurnoverSumTotalPercent || 0,
        4
      )}%`,
      additionalSymbols: '%',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      showWhenStatus: [2],
    },
  ];

  const cancel = () => {
    //window.history.back();
  };

  const save = () => {
    // console.log('save changes!!!');
  };

  useEffect(() => {
    if (
      closedDialogResponse &&
      closedDialogResponse.action === 'removePaymentReqDoc'
    ) {
      const docId = closedDialogResponse.docId;
      const payload = {
        message: '',
      };
      dispatch(actions.modals.setModalClosedOKAction(payload));
      if (docId) {
        dispatch(
          actions.dashboard.deletePrDocumentRequestAction(
            docId,
            requestId,
            paymentRequestId
          )
        );
      }
    }
  }, [closedDialogResponse]);

  const displayNotSavedMessage = (docId) => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText('Ar tikrai norite ištrinti šį mokėjimo prašymo dokumentą?');
    setModalData({ comments: { action: 'removePaymentReqDoc', docId: docId } });
    setModalAction('YES_NO_ACTION');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const actionClick = (id, docId, actionType, number) => {
    if (actionType === 'remove') {
      displayNotSavedMessage(docId);
    } else {
      dispatch(
        actions.dashboard.createNewPaymentRequestPrDocumentAction(
          id,
          docId,
          actionType,
          number
        )
      );
    }
  };

  const editPrDocPayBack = (
    id,
    docId,
    actionType,
    number,
    parentPrDocumentId
  ) => {
    if (actionType === 'remove') {
      displayNotSavedMessage(docId);
    } else {
      dispatch(
        actions.dashboard.createNewPaymentRequestPrDocumentPayBackAction(
          id,
          docId,
          actionType,
          number,
          parentPrDocumentId
        )
      );
    }
  };

  const deleteDoc = (id) => {
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DeletePrDocumentPayBack(id), {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.ok === true) {
          // reload the page

          swal({
            title: 'Dokumentas sėkmingai ištrintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
          fetchdata();
        } else {
          swal({
            title: 'Nepavyko pašalinti dokumento',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log('catch block');
        console.log(err);
      });
  };

  const deletePrDocPayBack = (id) => {
    swal({
      title: 'Ar tikrai norite ištrinti šį dokumentą?',
      icon: 'warning',
      showCancelButton: true,

      buttons: {
        confirm: {
          text: 'Taip',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
        cancel: {
          text: 'Ne',
          value: false,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true,
        },
      },
    }).then((result) => {
      if (result) {
        deleteDoc(id);
      }
    });
  };

  const downloadRejectionFile = (invoiceId, fileId, fileName) => {
    // setIsloading(true);
    const path = window.location.pathname?.split('/')?.[1];

    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadFilePrd(invoiceId, fileId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: getAutorization(),
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        //  setIsloading(false);
      });
  };

  const viewRequest = () => {
    const loginType = getLocalStorageItemIfNotExpired('loginType');
    if (loginType === 'Company') {
      dispatch(actions.dashboard.createNewRequestAction(requestId, 'view'));
    } else if (loginType === 'Individual') {
      dispatch(
        actions.dashboard.createNewRequestIndividualAction(requestId, 'view')
      );
    } else {
      swal({
        title: 'Įvyko klaida',
        icon: 'Error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  };

  const checkVisibility = (statusArr) => {
    if (!statusArr) {
      return true;
    }
    if (
      paymentRequest?.ContractInfo?.PaymentRequestStatusId &&
      statusArr.includes(paymentRequest.ContractInfo?.PaymentRequestStatusId)
    ) {
      return true;
    }
    return false;
  };

  const checkInvegaCommentDisabled = () => {
    return !checkBOPage() ||
      (checkBOPage() &&
        paymentRequest?.ContractInfo?.PaymentRequestStatusId !== 5)
      ? true
      : false;
  };

  const getLastInvegaComment = () => {
    return paymentRequest?.Comments?.length > 0
      ? paymentRequest.Comments[paymentRequest.Comments.length - 1].Comment
      : '';
  };

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [checkboxSfValues, setCheckboxSfValues] = useState([]);

  const onCheckboxSelect = (e, id) => {
    const val = id;
    const find = checkboxValues.find((item) => item === val);
    if (!find) {
      let arr = [...checkboxValues, val];
      setCheckboxValues(arr);
    } else {
      const newArr = checkboxValues.filter((item) => item !== val);
       //sfSelectSave(newArr);
     setCheckboxValues(newArr);
    }
  };

  const sfSelectSave = (data) => {
    // setIsloading(true);

    fetch(
      Api.API_BASE_URL + config.API_ENDPOINTS.MarkUnpaidCheck(paymentRequestId),
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAutorization(),
        },
        body: JSON.stringify([...data]),
      }
    )
      .then((res) => {
        if (res.ok === true) {
          swal({
            title: 'Pakeista',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          }).then(function () {
            fetchdata();
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          res.json().then((err) => {
            console.log(err);
            swal({
              title: err.ErrorDescription,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          });
        }
      })
      .finally(() => {
        //  setIsloading(false);
      });
  };

  const onCheckboxSfSelect = (e, id) => {
    console.log(id);
    const val = id;
    const find = checkboxSfValues.find((item) => item === val);
    if (!find) {
      let arr = [...checkboxSfValues, val];
      setCheckboxSfValues(arr);
      sfSelectSave(arr);
    } else {
      const newArr = checkboxSfValues.filter((item) => item !== val);
      setCheckboxSfValues(newArr);
      sfSelectSave(newArr);
    }
  };

  const commentsHistoryTableHeader = ['Data', 'Komentaras'];

  const downloadPrList = () => {
    const fileName = `Mokejimas_prasymas_Nr_${paymentRequest.ContractInfo.Id}`;

    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.ExportPr(
          requestId,
          paymentRequest.ContractInfo.Id
        ),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    // .finally(() => {
    // });
  };

  return (
    <WithSpinner
      loading={
        requestsState.loading === true || loading === true ? true : false
      }
    >
      <div
        className="container"
        style={{ backgroundColor: '#fff', marginTop: '4rem' }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.requestBlockAll}>
              <h1>Mokėjimo prašymas</h1>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h4>Mokėjimo prašymo informacija</h4>
                  {
                    <div className="row director-data-margin">
                      {contractInfo.map((el, i) => (
                        <div className="col-lg-6 col-sm-12" key={i}>
                          {el.fieldType === 'input' && (
                            <InputWithTextInline
                              type={el.type || 'string'}
                              value={el.value || ''}
                              placeholder={el.title}
                              disabled={el.disabled}
                              name={el.name}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
              <h1>Paskolos sutarties duomenys</h1>
              {
                <div className="row director-data-margin">
                  {financialInfo.map((el, i) => (
                    <React.Fragment key={i}>
                      {checkVisibility(el.showWhenStatus) && (
                        <div className="col-lg-6 col-sm-12">
                          {el.fieldType === 'input' && (
                            <InputWithTextInline
                              type={el.type || 'string'}
                              value={el.value}
                              placeholder={el.title}
                              disabled={el.disabled}
                              name="test1"
                            />
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              }
              <h1>Mokėjimo prašymo dokumentai</h1>

              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'auto',
                  paddingBottom: '40px',
                  maxHeight: '600px'
                }}
              >
                <div className={paymentReqStyles.shadow}>
                  <table className={paymentReqStyles.table}>
                    <thead
                      className={paymentReqStyles.paymentRequestsListTHead}
                    >
                      <tr>
                        {/*<th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Eil. Nr.
              </th>*/}
                        <th
                          rowSpan="2"  style={{padding:0}}
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Veiksmai
                        </th>
                        {checkBOPage() && (
                          <th
                            rowSpan="2"
                            className={paymentReqStyles.paymentRequestsListTH}
                          >
                            Ar galės reda- guoti?
                          </th>
                        )}
                        {checkBOPage() && (
                          <th
                            rowSpan="2"  style={{padding:0}}
                            className={paymentReqStyles.paymentRequestsListTH}
                          >
                            Neap- moka- mos SF
                          </th>
                        )}
                        <th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Tiekėjo informacija
                        </th>
                        {/*<th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Tiekėjo įmonės kodas/Tiekėjo IV pažymėjimo arba VL Nr.
                        </th>
                        <th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Tiekėjo banko sąskaitos numeris
                        </th>*/}
                        {/*<th
                          rowSpan="2"  style={{padding:0}}
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Dokumento tipas
                      </th>*/}
                        <th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Dokumento Nr. ir data
                        </th>
                        {/*<th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Dokumento data
                      </th>*/}
                        <th
                          rowSpan="2"  style={{padding:0}}
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Dokumento suma, EUR:
                        </th>
                        <th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Prašoma suma, EUR
                        </th>
                        <th
                          colSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Išmokėta paskolos suma pagal paskolos tipą proc.:
                        </th>
                        <th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Finansuo- jama suma, EUR
                        </th>
                        {/*<th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Pastabos
                        </th>*/}
                        <th
                          rowSpan="2"
                          className={paymentReqStyles.paymentRequestsListTH}
                        >
                          Priedai
                        </th>
                      </tr>
                      <tr>
                        <th style={{padding:'0 0 10px 0'}}>Investicinė</th>
                        <th style={{padding:'0 0 10px 0'}}>Apyvartinė</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentRequest?.DocumentsInfo?.Documents?.map(
                        (el, i) => (
                          <tr
                            className={paymentReqStyles.paymentRequestsListTR}
                            key={i}
                          >
                            {/*<td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                             
                        </td>*/}
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                               
                              <div className={paymentReqStyles.iconsContainer}>
                              {i + 1}{'. '}<Link
                                  to={`${
                                    checkBOPage()
                                      ? config.APP_PATHS
                                          .backofficePaymentDocument
                                      : config.APP_PATHS.paymentDocument
                                  }?documentId=${el.Id}&actionType=view`}
                                >
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className={paymentReqStyles.icon}
                                    onClick={() =>
                                      actionClick(
                                        paymentRequest.ContractInfo.Id,
                                        el.Id,
                                        `view`,
                                        i + 1
                                      )
                                    }
                                    style={{
                                      color: '#009fe3',
                                    }}
                                  />
                                </Link>

                                {!el.IsApproved &&
                                  (paymentRequest.ContractInfo
                                    .PaymentRequestStatusId === 1 ||
                                    paymentRequest.ContractInfo
                                      .PaymentRequestStatusId === 3) &&
                                  !checkBOPage() &&
                                  actionType !== 'view' && (
                                    <>
                                      <Link
                                        to={`${
                                          checkBOPage()
                                            ? config.APP_PATHS
                                                .backofficePaymentDocument
                                            : config.APP_PATHS.paymentDocument
                                        }?documentId=${el.Id}&actionType=edit`}
                                      >
                                        {!checkBOPage() && (
                                          <FontAwesomeIcon
                                            icon={faPencilAlt}
                                            className={paymentReqStyles.icon}
                                            onClick={() =>
                                              actionClick(
                                                paymentRequest.ContractInfo.Id,
                                                el.Id,
                                                `edit`,
                                                i + 1
                                              )
                                            }
                                            style={{
                                              color: '#009fe3',
                                            }}
                                          />
                                        )}
                                      </Link>

                                      {!checkBOPage() && (
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className={paymentReqStyles.icon}
                                          onClick={() =>
                                            actionClick(
                                              paymentRequest.ContractInfo.Id,
                                              el.Id,
                                              'remove'
                                            )
                                          }
                                          style={{
                                            color: '#597a96',
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                {checkBOPage() &&
                                  paymentRequest.ContractInfo
                                    .PaymentRequestStatusId === 5 && (
                                    <>
                                      {!el?.IsPayBackDocument && (
                                        <Link
                                          to={`${config.APP_PATHS.backofficePaymentDocument}?documentId=${el.Id}&actionType=edit`}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPencilAlt}
                                            className={paymentReqStyles.icon}
                                            onClick={() =>
                                              actionClick(
                                                paymentRequest.ContractInfo.Id,
                                                el.Id,
                                                `edit`,
                                                i + 1
                                              )
                                            }
                                            style={{
                                              color: '#009fe3',
                                            }}
                                          />
                                        </Link>
                                      )}
                                    </>
                                  )}
                                {checkBOPage() &&
                                  paymentRequest.ContractInfo
                                    .PaymentRequestStatusId === 2 && (
                                    <>
                                      {!el?.PayBackDocumentId && (
                                        <Link
                                          to={`${
                                            checkBOPage()
                                              ? config.APP_PATHS
                                                  .PaymentDocumentSumEdit
                                              : config.APP_PATHS.paymentDocument
                                          }?PaymentDocumentSumEdit=${
                                            el.Id
                                          }&actionType=edit`}
                                        >
                                          <FontAwesomeIcon
                                            icon={faBrush}
                                            className={paymentReqStyles.icon}
                                            onClick={() =>
                                              editPrDocPayBack(
                                                paymentRequest.ContractInfo.Id,
                                                el.Id,
                                                `view`,
                                                i + 1,
                                                el.ParentPrDocumentId
                                              )
                                            }
                                            style={{
                                              color: '#009fe3',
                                            }}
                                          />
                                        </Link>
                                      )}

                                      {el?.IsPayBackDocument && (
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className={paymentReqStyles.icon}
                                          onClick={() =>
                                            deletePrDocPayBack(el.Id)
                                          }
                                          style={{
                                            color: '#009fe3',
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                {el.IsManagerEdited && (
                                  <FontAwesomeIcon icon={faExclamation} />
                                )}
                              </div>
                            </td>
                            {checkBOPage() && (
                              <td
                                className={
                                  paymentReqStyles.paymentRequestsListTD
                                }
                              >
                                <div className="checkbox bills-aprove-checkbox-change">
                                  <label
                                    className="containerLabel noBlock"
                                    style={{
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        onCheckboxSelect(e, el.Id)
                                      }
                                      checked={checkboxValues.some(
                                        (val) => val == el.Id
                                      )}
                                      disabled={
                                        paymentRequest?.ContractInfo
                                          ?.PaymentRequestStatusId === 5
                                          ? false
                                          : true
                                      }
                                    />
                                    <span className="checkmark bills-aprove-checkmark-change"></span>
                                  </label>
                                </div>
                              </td>
                            )}

                            {checkBOPage() && (
                              <td
                                className={
                                  paymentReqStyles.paymentRequestsListTD
                                }
                              >
                                <div className="checkbox bills-aprove-checkbox-change">
                                  <label
                                    className="containerLabel noBlock"
                                    style={{
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        onCheckboxSfSelect(e, el.Id)
                                      }
                                      checked={checkboxSfValues.some(
                                        (val) => val == el.Id
                                      )}
                                      disabled={
                                        paymentRequest?.ContractInfo
                                          ?.PaymentRequestStatusId === 5
                                          ? false
                                          : true
                                      }
                                    />
                                    <span className="checkmark bills-aprove-checkmark-change"></span>
                                  </label>
                                </div>
                              </td>
                            )}

                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.SupplierName} <br/>{el.SupplierCode}
                            </td>
                           {/*} <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.SupplierCode}
                                    </td>*/}
                           {/*} <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.Iban}
                                    </td>*/}
                            {/*<td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {documentType(el.DocumentType)}
                                  </td>*/}
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.DocumentNumber} <br/>{format(new Date(el.DocumentDate), 'yyyy-MM-dd')}
                            </td>
                            {/*<td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {format(new Date(el.DocumentDate), 'yyyy-MM-dd')}
                                  </td>*/}
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {formatNumber(el.DocumentSum)}
                            </td>
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.RequiredSum
                                ? formatNumber(el.RequiredSum)
                                : formatNumber(0)}
                            </td>
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.FundedInvestmentSumPercent
                                ? `${formatNumber(
                                    el.FundedInvestmentSumPercent,
                                    4
                                  )}%`
                                : '-'}
                            </td>
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.FundedTurnoverSumPercent
                                ? `${formatNumber(
                                    el.FundedTurnoverSumPercent,
                                    4
                                  )}%`
                                : '-'}
                            </td>
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.FundedSum ? formatNumber(el.FundedSum) : '-'}
                            </td>
                            {/*<td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              {el.Comment || ''}
                            </td>*/}
                            <td
                              className={paymentReqStyles.paymentRequestsListTD}
                            >
                              <div className="row justify-content-center">
                                {el?.Files &&
                                  el?.Files.length > 0 &&
                                  el.Files.map((file, i) => (
                                    <div
                                      className="px-2"
                                      title={file.Name}
                                      key={i}
                                    >
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        className={`${styles.icon} ${paymentReqStyles.icon}`}
                                        onClick={() =>
                                          downloadRejectionFile(
                                            el.Id,
                                            file.Id,
                                            file.Name
                                          )
                                        }
                                        style={{
                                          color: '#597a96',
                                        }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                      {paymentRequest?.DocumentsInfo?.Documents.length > 0 && (
                        <tr
                          className={paymentReqStyles.paymentRequestsListTRFoot}
                        >
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          >
                            Viso:
                          </td>
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          />
                          {checkBOPage() && (
                            <>
                              <td
                                className={
                                  paymentReqStyles.paymentRequestsListTD
                                }
                              />
                              <td
                                className={
                                  paymentReqStyles.paymentRequestsListTD
                                }
                              />
                            </>
                          )}
                          {/*<td
                            className={paymentReqStyles.paymentRequestsListTD}
                          />
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          />
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          />
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                              />
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          />*/}
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          />
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          >
                            {formatNumber(
                              paymentRequest.DocumentsInfo.Documents.reduce(
                                (sum, el) => sum + el.DocumentSum,
                                0
                              )
                            )}
                          </td>
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          >
                           {/*} {formatNumber(
                              paymentRequest?.DocumentsInfo?.Totals
                                .RequiredSumTotal || 0
                           )}*/}
                            {formatNumber(
                              paymentRequest.DocumentsInfo?.Documents.reduce(
                                (sum, el) => sum + el.RequiredSum,
                                0
                              )
                            )}
                          </td>
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          >
                            {paymentRequest?.DocumentsInfo?.Totals
                              .InvestmentSumTotalPercent
                              ? `${formatNumber(
                                  paymentRequest?.DocumentsInfo?.Totals
                                    .InvestmentSumTotalPercent,
                                  4
                                )}%`
                              : '-'}
                          </td>
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          >
                            {paymentRequest?.DocumentsInfo?.Totals
                              .TurnoverSumTotalPercent
                              ? `${formatNumber(
                                  paymentRequest?.DocumentsInfo?.Totals
                                    .TurnoverSumTotalPercent,
                                  4
                                )}%`
                              : '-'}
                          </td>
                          <td
                            className={paymentReqStyles.paymentRequestsListTD}
                          >
                            {formatNumber(
                              paymentRequest?.DocumentsInfo?.Totals
                                ?.FundedSumTotal || '-'
                            )}
                          </td>
                          <td />
                        </tr>
                      )}
                      <tr>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
                <br />
                {checkBOPage() && (
                 <WithSpinner loading={isLoading}>
              <Button
                type="standart"
                onClick={() => downloadFilePrdAll()}
                float="right"
              >
                Atsisiųsti visus įkeltus priedus
              </Button>
                            </WithSpinner>
                )}
              </div>

              <div className="col-lg-12 col-sm-12">
                {(paymentRequest?.ContractInfo?.PaymentRequestStatusId === 1 ||
                  paymentRequest?.ContractInfo?.PaymentRequestStatusId === 3) &&
                  !checkBOPage() &&
                  actionType !== 'view' && (
                    <div className="row justify-content-end">
                      <Link
                        to={`${config.APP_PATHS.paymentDocument}?actionType=new`}
                      >
                        <FontAwesomeIcon
                          icon={faPlusSquare}
                          className={paymentReqStyles.icon}
                          onClick={() =>
                            actionClick(
                              paymentRequest.ContractInfo.Id,
                              null,
                              `edit`
                            )
                          }
                          style={{
                            float: 'right',
                            width: '48px',
                            height: '48px',
                            color: '#033878',
                            padding: '0.5rem',
                          }}
                        />
                      </Link>
                    </div>
                  )}
                <div>
                  <PaymentRequestFileUploadContainer
                    data={paymentRequest}
                    actionType={actionType}
                  />
                </div>

                {/*Invegos komentaras rodomas pareiškėjui visada disabled ir rodomas, išskyrus 1 statusą. BO - rodomas visada, redaguojamas - kai statusas = pateikta (dabar tokio nėra) */}
                {checkBOPage() && (
                  <>
                    <h4 style={{ marginTop: '2rem' }}>Invega komentaras</h4>

                    <div className="inputs-block">
                      <textarea
                        style={{ width: '100%', height: '100px' }}
                        placeholder="Invega komentaras"
                        name="Comments"
                        type="string"
                        disabled={checkInvegaCommentDisabled()}
                        value={
                          checkInvegaCommentDisabled()
                            ? getLastInvegaComment()
                            : comments
                        }
                        onChange={onCommentChange}
                      />
                    </div>
                  </>
                )}

                <div className={styles.btnBlock}>
                  {!checkBOPage() &&
                    (paymentRequest?.ContractInfo?.PaymentRequestStatusId ===
                      1 ||
                      paymentRequest?.ContractInfo?.PaymentRequestStatusId ===
                        3) && (
                      <Button
                        type="longBtn"
                        onClick={() => approvePaymentRequest()}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                          float: 'left',
                        }}
                      >
                        Pateikti mokėjimo prašymą
                      </Button>
                    )}

                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    to={`${
                      checkBOPage()
                        ? config.APP_PATHS.backofficePaymentRequests
                        : config.APP_PATHS.paymentrequests
                    }?requestId=${requestId}`}
                  >
                    <Button type="cancel" onClick={() => cancel()}>
                      Atgal
                    </Button>
                  </Link>

                  {actionType === 'view' && (
                    <>
                      {checkBOPage() && (
                        <div>
                          <Link
                            to={`${config.APP_PATHS.backofficeRequest}/${requestId}`}
                            style={{
                              color: '#fff',
                              textDecoration: 'none',
                              float: 'left',
                            }}
                          >
                            <Button type="longBtn">
                              Peržiūrėti paraiškos duomenis
                            </Button>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                  {/*vertintojo funkcijos: galima tuomet, kai busena = 5 (pateikta) */}
                  {checkBOPage() && (
                    <Button type="standart" onClick={() => downloadPrList()}>
                      Eksportuoti mokėjimo prašymą
                    </Button>
                  )}
                  <div style={{ marginTop: '0rem' }}>
                    {paymentRequest?.ContractInfo?.PaymentRequestStatusId ==
                      5 && checkBOPage() ? (
                      <>
                        <Button
                          type="standart"
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                          }}
                          onClick={confirmClick}
                        >
                          Patvirtinti
                        </Button>

                        <div style={{ marginTop: '1rem' }}>
                          <Button
                            type="standart"
                            style={{
                              color: '#fff',
                              textDecoration: 'none',
                            }}
                            onClick={returnClick}
                          >
                            Grąžinti tikslinimui
                          </Button>
                        </div>

                        <div style={{ marginTop: '1rem' }}>
                          <Button
                            type="standart"
                            style={{
                              color: '#fff',
                              textDecoration: 'none',
                            }}
                            onClick={rejectClick}
                          >
                            Atmesti
                          </Button>
                        </div>
                      </>
                    ) : checkBOPage() ? (
                      <div style={{ visibility: 'hidden' }}>
                        <Button type="standart"></Button>
                      </div>
                    ) : (
                      <Link
                        onClick={viewRequest}
                        to={config.APP_PATHS.newRequest}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                          float: 'left',
                        }}
                      >
                        <Button type="longBtn">
                          Peržiūrėti paraiškos duomenis
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: '3rem',
                  marginBottom: '2rem',
                  marginLeft: '15px',
                }}
              >
                <h4>Komentarų istorija </h4>
                {paymentRequest?.Comments &&
                paymentRequest?.Comments.length > 0 ? (
                  <TableAdmin
                    header={commentsHistoryTableHeader}
                    isEmpty={false}
                  >
                    {paymentRequest?.Comments.map((data, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {data.Created &&
                              format(
                                new Date(data.Created),
                                'yyyy-MM-dd HH:mm:ss'
                              )}
                          </TableCell>
                          <TableCell>{data.Comment}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableAdmin>
                ) : (
                  <p>Nėra komentarų istorijos</p>
                )}
              </div>
              {checkBOPage() && (
                <ManagerActionLog paymentRequestId={paymentRequestId} />
              )}
            </div>
          </div>
        </div>
      </div>

      <PaymentRequestSendforApproveModal
        open={paymentRequestSendforApproveModalVisible}
        loading={loading}
      />
      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle="Taip"
        modalTitle={modalTitle}
        checkbox={false}
        mainText={modalMainText}
      />
    </WithSpinner>
  );
};

/*
<div className="pr-2" title="file1.jpg">
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className={styles.icon}
                                    onClick={() =>
                                      downloadRejectionFile(
                                        'requestId',
                                        'Id',
                                        'FileName'
                                      )
                                    }
                                    style={{
                                      color: '#597a96',
                                    }}
                                  />
                                </div>
                                <div className="pr-2" title="file2.pdf">
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className={styles.icon}
                                    onClick={() =>
                                      downloadRejectionFile(
                                        'requestId',
                                        'Id',
                                        'FileName'
                                      )
                                    }
                                    style={{
                                      color: '#597a96',
                                    }}
                                  />
                                </div> 
*/
/*
<Link to={config.APP_PATHS.paymentDocument}>
                <FontAwesomeIcon
                  size="4x"
                  icon={faPlusSquare}
                  className={styles.icon}
                  style={{ float: 'right', width: '26px', color: '#033878' }}
                  onClick={() => addNewPaymentDocument()}
                />
              </Link>
*/

const mapStateToProps = (state) => ({
  requestsState: state.backofficeRequestsState,
  paymentRequest: state.dashboardState.paymentRequest,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
  paymentRequestSendforApproveModalVisible:
    state.modalsState.paymentRequestSendforApproveModalVisible,
  loading: state.dashboardState.isOnSync,
  closedDialogResponse: state.modalsState.closedDialogOKMessage,
});
export default connect(mapStateToProps)(PaymentRequest);
