import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './invegaLoanSumModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';
import { config } from './../../api/config';
import { Api } from './../../api/api';

const InvegaLoanSumModal = ({ open, loading, dispatch, requestId }) => {
  const [sum, setSum] = useState(null);
  const [notValid, setNotvalid] = useState(false);

  const cancel = () => {
    const open = {
      modalName: 'invegaLoanSumModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const setValue = (e) => {
    setSum(e.target.value);
  };

  const isSumValid = () => {
    if (!sum) {
      return 'Neįvesta paskolos suma. Formatas 0.00 EUR';
    }
    var ex = /^\d*\.?\d{0,2}$/;
    if (ex.test(sum) === false) {
      return 'Turi būti įvesti skaičiai (formatas 0.00)';
    }
    return null;
  };

  const saveSum = () => {
    setNotvalid(true);
    if (isSumValid() == null) {
      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.SetInvegaLoanSum, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          RequestId: requestId,
          Sum: sum,
        }),
      })
        //.then((response) => response.json())
        .finally(() => {
          dispatch(actions.backoffice.getRequestAction(requestId));
          cancel();
        });
    }
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <div className="modal-title">
              <InputWithTextInline
                type="text"
                value={sum}
                inOneLine={false}
                placeholder="Paskolos suma, EUR"
                onChange={(e) => setValue(e)}
              ></InputWithTextInline>
            </div>
            {notValid && <p className="errorsBlockText">{isSumValid()}</p>}

            <div className={styles.btnContainer}>
              <div>
                <Button type="standart" onClick={() => saveSum()}>
                  Saugoti
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(InvegaLoanSumModal);
