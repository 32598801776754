import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './autorizationModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { Link } from 'react-router-dom';

const AutorizationModal = ({
  open,
  id,
  loading,
  dispatch,
  errors,
  representative,
}) => {
  const [values, setValues] = useState({
    Id: null,
    RoleId: 1,
    FirstName: '',
    LastName: '',
    PersonalCode: '',
    ValidFrom: '',
    ValidTo: '',
  });

  console.log(representative);

  const [file, setFile] = useState({
    file: '',
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const handleStartDateChange = (date) => {
    if (!date) {
      setValues({ ...values, ['ValidFrom']: '' });
      return;
    }
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, 0);
    const day = `${date.getDate()}`.padStart(2, 0);
    setValues({ ...values, ['ValidFrom']: [year, month, day].join('-') });
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      setValues({ ...values, ['ValidTo']: '' });
      return;
    }
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, 0);
    const day = `${date.getDate()}`.padStart(2, 0);
    setValues({ ...values, ['ValidTo']: [year, month, day].join('-') });
  };

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const downloadFile = (id, fileId) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.downloadRepresentativeFile(id, fileId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Igaliojimas_Nr_${fileId}.pdf`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const setUploadFile = (e) => {
    const file = e.target.files[0];

    if (file && file.size > 20 * 1048 * 1048) {
      swal({
        title: 'Įkeliamas priedas viršija 20mb leistiną limitą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
      return;
    }

    setFile(file);
  };

  const cancel = () => {
    const open = {
      modalName: 'autorizationModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
    setValues(representative);
  };

  const yes = () => {
    const formData = new FormData();
    formData.append('UploadedFile', file);
    formData.append('FirstName', values.FirstName);
    formData.append('LastName', values.LastName);
    formData.append('PersonalCode', values.PersonalCode);
    formData.append('ValidFrom', values.ValidFrom);
    formData.append('ValidTo', values.ValidTo);
    formData.append('RepresentativeType', 'Company');
    if (values.Id) {
      formData.append('Id', values.Id);
    }

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.saveRepresentative, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          const open = {
            modalName: 'autorizationModal',
            visible: false,
          };
          dispatch(actions.modals.setModalVisibleFlagAction(open));
          dispatch(actions.dashboard.loadRepresentatives());
        } else {
          res.json().then((r) => {
            if (r.ErrorMessage) {
              dispatch(
                actions.dashboard.setSaveRepresentativeError(r.ErrorMessage)
              );
            } else {
              dispatch(actions.dashboard.setSaveRepresentativeError(r.errors));
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setIsloading(false);
      });
  };

  useEffect(() => {
    if (representative) {
      setValues(representative);
    }
  }, [representative]);

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <h2>Pridėti įgaliotinį</h2>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <InputWithTextInline
                  name={'FirstName'}
                  type="string"
                  onChange={handleInputChange}
                  value={values.FirstName}
                  placeholder={'Vardas'}
                  disabled={false}
                />
              </div>

              <div className="col-lg-6 col-sm-12">
                <InputWithTextInline
                  name={'LastName'}
                  type="string"
                  onChange={handleInputChange}
                  value={values.LastName}
                  placeholder={'Pavardė'}
                  disabled={false}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <InputWithTextInline
                  name={'PersonalCode'}
                  type="string"
                  onChange={handleInputChange}
                  value={values.PersonalCode}
                  placeholder={'Asmens kodas'}
                  disabled={values.Id}
                />
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className={styles.selectflex}>
                  <label className="inlineDatepickerInputTitle">
                    Įgaliojimo pradžia
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    selected={
                      values.ValidFrom ? new Date(values.ValidFrom) : ''
                    }
                    onChange={handleStartDateChange}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className={styles.selectflex}>
                  <label className="inlineDatepickerInputTitle">
                    Įgaliojimo pabaiga
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    selected={values.ValidTo ? new Date(values.ValidTo) : ''}
                    onChange={handleEndDateChange}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
              {/*
              <div className="col-lg-6 col-sm-12">
                <div className={styles.selectflex}>
                  <label className="inlineDatepickerInputTitle">Rolė </label>
                  <select name="RoleId" onChange={handleInputChange}>
                    <option selected={values.RoleId == 1} value={1}>
                      Pareiškėjas
                    </option>
                    <option selected={values.RoleId == 2} value={2}>
                      Sąskaitų tvirtintojas
                    </option>
                    <option selected={values.RoleId == 3} value={3}>
                      Pareiškėjas ir sąskaitų tvirtintojas
                    </option>
                  </select>
                </div>
              </div>
              */}
              <div
                className={styles.selectflex}
                style={{ marginTop: '2rem', marginLeft: '1rem' }}
              >
                {representative &&
                  representative.Files &&
                  representative.Files.map((f) => (
                    <div style={{ marginBottom: '5px' }}>
                      <Link
                        onClick={() => downloadFile(representative.Id, f)}
                        style={{ color: '#009fe3', textDecoration: 'none' }}
                      >
                        Įgaliojimas Nr. {f}
                      </Link>
                    </div>
                  ))}
                <label for="fileElem"></label>
                <input
                  type="file"
                  id="fileElem"
                  accept=".pdf, .adoc"
                  onChange={setUploadFile}
                  name="file"
                  className="inputfile"
                />
                <p className={styles.note}>
                  Galimi priedų formatai: .pdf, .adoc. Maksimalus dydis 20MB.
                </p>
              </div>
            </div>

            {errors && (
              <div style={{ paddingTop: '4rem' }}>
                {typeof errors == 'string' && (
                  <div style={{ color: 'red' }}>{errors}</div>
                )}
                {errors.FirstName &&
                  errors.FirstName.map((e) => (
                    <div style={{ color: 'red' }}>{e}</div>
                  ))}
                {errors.LastName &&
                  errors.LastName.map((e) => (
                    <div style={{ color: 'red' }}>{e}</div>
                  ))}
                {errors.PersonalCode &&
                  errors.PersonalCode.map((e) => (
                    <div style={{ color: 'red' }}>{e}</div>
                  ))}
                {errors.ValidFrom &&
                  errors.ValidFrom.map((e) => (
                    <div style={{ color: 'red' }}>{e}</div>
                  ))}
                {errors.ValidTo &&
                  errors.ValidTo.map((e) => (
                    <div style={{ color: 'red' }}>{e}</div>
                  ))}
                {errors.UploadedFile &&
                  errors.UploadedFile.map((e) => (
                    <div style={{ color: 'red' }}>{e}</div>
                  ))}
              </div>
            )}

            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes()}>
                Saugoti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  errors: state.dashboardState.errors,
  representative: state.dashboardState.representative,
});

export default connect(mapStateToProps)(AutorizationModal);
