import { actions } from '../actions';
import { call, put, delay, select } from 'redux-saga/effects';
// import { Api } from './../../api/api';
import { config } from './../../api/config';

import { checkBOPage, errorhandle } from '../../utils/helpers/helpers';
import swal from 'sweetalert';
import { Api } from '../../api/api';
import { history } from './../../App';
import { getLocalStorageItemIfNotExpired } from '../../utils';

const forwardTo = (location) => {
  history.push(location);
};

const setLocation = (location) => {
  window.location = location;
};

function* loadSellerInvoiceList(request) {
  try {
    let data = yield call(Api.getSellerInvoiceList, request.payload);
    yield put(actions.dashboard.setInvoiceListDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* loadBuyerInvoiceList() {
  try {
    let data = yield call(Api.getBuyerInvoiceList);
    yield put(actions.dashboard.setInvoiceListDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* loadInvoiceData(request) {
  try {
    let data = yield call(
      Api.getInvoiceData,
      request.payload.requestId,
      request.payload.invoiceId
    );
    yield put(actions.dashboard.setInvoiceDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* loadOwnerInvoiceData(request) {
  try {
    let data = yield call(
      Api.getOwnerInvoiceData,
      request.payload.requestId,
      request.payload.invoiceId
    );
    yield put(actions.dashboard.setOwnerInvoiceDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* saveInvoiceData(request) {
  try {
    yield call(Api.saveInvoiceData, request.requestId, request.data);

    yield put(actions.dashboard.setInvoiceSavedAction(true));
  } catch (error) {
    yield put(actions.dashboard.setInvoiceErrorAction(error));
  } finally {
    if (request.data.Id) {
      yield put(
        actions.dashboard.loadInvoiceDataAction({
          requestId: request.requestId,
          invoiceId: request.data.Id,
        })
      );
    }
  }
}

function* passCompanyInfoSaga({ payload }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    //call enpointo to pass the date
    const formattedPayload = {
      accepted: true,
      email: payload.email,
      phoneNumber: payload.phone,
    };

    yield call(Api.createConsent, formattedPayload);

    // yield call(forwardTo, config.APP_PATHS.requests);
    // window.location.reload();
    yield put(actions.dashboard.setFirstStepPassedAction(true));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getRequestListSaga() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const data = yield call(Api.getRequestList);
    yield put(actions.dashboard.setRequestListAction(data));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* createNewRequestSaga({ id, actionType, requestId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    if (id != null) {
      const requestData = yield call(Api.requestGet, id);
      const dataWithType = {
        type: actionType,
        ...requestData,
      };
      // const hardcode = [100000, 291000, 592000, 493900];
      const getCompanyEvrks = yield call(Api.GetCompanyEvrks, id);

      yield put(
        actions.dashboard.setNewRequestData({
          ...dataWithType,
          evrksList: getCompanyEvrks,
        })
      );
    } else {
      const canCreateRequest = yield call(Api.canCreateRequest);

      yield put(actions.dashboard.SetCanCreateRequest(canCreateRequest));

      if (canCreateRequest) {
        const { RequestId, CompanyId } =
          actionType === 'copy'
            ? yield call(Api.copy, requestId)
            : yield call(Api.requestStart);
        const companyId = {
          CompanyId: CompanyId,
        };

        yield call(Api.startRegistersProcessing, companyId);

        while (true) {
          yield delay(3000);
          const processStatus = yield call(
            Api.getRegistersProcessingStatus,
            CompanyId
          );
          if (processStatus.status === 'Failed') {
            yield call(forwardTo, config.APP_PATHS.requests);
            swal({
              title: 'Įvyko klaida',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });

            break;
          } else if (processStatus.status === 'Finished') {
            const newRequestData = yield call(Api.requestGet, RequestId);
            const dataWithType = {
              type: actionType,
              ...newRequestData,
            };

            // const hardcode = [100000, 291000, 592000, 493900];

            const getCompanyEvrks = yield call(Api.GetCompanyEvrks, RequestId);

            yield put(
              actions.dashboard.setNewRequestData({
                ...dataWithType,
                evrksList: getCompanyEvrks,
              })
            );
            yield put(actions.dashboard.getRequestListAction());
            break;
          }
        }
      } else {
        swal({
          title:
            'Vienam paskolos gavėjui iš Priemonės lėšų gali būti suteikta tik viena paskola. Jūs jau esate pateikę paraišką paskolai gauti.',
          icon: 'info',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      }
    }
  } catch (error) {
    if (actionType === 'copy') {
      swal({
        title:
          error.error && error.error.ErrorMessage
            ? error.error.ErrorMessage
            : 'Įvyko klaida',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } else {
      yield* errorhandle(error);
    }
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* createNewRequestIndividualSaga({ id, actionType, requestId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    // const individualsEvrk = yield call(Api.GetIndividualEvrks, id);
    // console.log(individualsEvrk)
    if (id != null) {
      const requestData = yield call(Api.requestGet, id);

      const hardcode = [100000, 291000, 592000, 493900];

      const getIndividualEvrks = yield call(Api.GetIndividualEvrks, id);
      const dataWithType = {
        type: actionType,
        ...requestData,
      };
      yield put(
        actions.dashboard.setNewRequestData({
          ...dataWithType,
          evrksList: getIndividualEvrks,
        })
      );
    } else {
      const canCreateRequest = yield call(Api.canCreateRequest);

      yield put(actions.dashboard.SetCanCreateRequest(canCreateRequest));

      if (canCreateRequest) {
        const { RequestId, IndividualId } =
          actionType === 'copy'
            ? yield call(Api.copy, requestId)
            : yield call(Api.requestStart);

        const individualId = {
          IndividualId: IndividualId,
        };

        yield call(Api.startRegistersProcessingIndividual, individualId);

        while (true) {
          yield delay(3000);
          const processStatus = yield call(
            Api.getRegistersProcessingStatusIndividual,
            IndividualId
          );
          if (processStatus.status === 'Failed') {
            // yield call(forwardTo, config.APP_PATHS.requests);
            swal({
              title: 'Įvyko klaida',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });

            break;
          } else if (processStatus.status === 'Finished') {
            const newRequestData = yield call(Api.requestGet, RequestId);
            const dataWithType = {
              type: actionType,
              ...newRequestData,
            };

            // const hardcode = [100000, 291000, 592000, 493900];

            const getIndividualEvrks = yield call(
              Api.GetIndividualEvrks,
              RequestId
            );

            yield put(
              actions.dashboard.setNewRequestData({
                ...dataWithType,
                evrksList: getIndividualEvrks,
              })
            );
            yield put(actions.dashboard.getRequestListAction());
            break;
          }
        }
      } else {
        swal({
          title:
            'Vienam paskolos gavėjui iš Priemonės lėšų gali būti suteikta tik viena paskola. Jūs jau esate pateikę paraišką paskolai gauti.',
          icon: 'info',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      }
    }
  } catch (error) {
    console.log(error);
    // yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* saveRequestSaga({ payload, formVersion }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const requestId = payload.requestSystemInfo.RequestId;

    const requestSave = yield call(Api.requestSave, payload);

    // yield call(Api.GetFinancialEvaluationStatus, requestId);
    if (requestSave && requestSave.saveSuccessfull) {
      while (true) {
        yield delay(2000);
        const processStatus = yield call(
          Api.GetFinancialEvaluationStatus,
          requestId
        );
        if (processStatus.status === 'Failed') {
          swal({
            title: 'Įvyko klaida',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
          const open = {
            modalName: 'confirmWithFinancialCriteriaModal',
            visible: false,
            formVersion: formVersion,
          };
          yield put(actions.modals.setModalVisibleFlagAction(open));

          break;
        } else if (processStatus.status === 'Finished') {
          console.log('FINISHEDD');
          // yield put(
          //   actions.dashboard.setRequestViewWindowAction()
          // );
          const loginType = getLocalStorageItemIfNotExpired('loginType');
          if (loginType === 'Company') {
            yield put(
              actions.dashboard.createNewRequestAction(requestId, 'view')
            );
          } else if (loginType === 'Individual') {
            yield put(
              actions.dashboard.createNewRequestIndividualAction(
                requestId,
                'view'
              )
            );
          }
          const data = yield call(
            Api.getFinancialCriteriaResultsForSaveRequest,
            requestId
          );
          yield put(actions.dashboard.setFinancialCriteriaResultsAction(data, formVersion));

          const open = {
            modalName: 'financialCriteriaModal',
            visible: true,
            formVersion: formVersion,
          };
          console.log(data);
          const desission =
            data.Decision != null && data.Decision === 'Accepted'
              ? true
              : false;
          yield put(
            actions.dashboard.setSavedRequestDesissionAccepted(desission)
          );
          yield put(actions.modals.setModalVisibleFlagAction(open, data));
          break;
        }
      }
    } else {
      swal({
        title: 'Nepavyko išsaugoti paraiškos',
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* deleteRequestSaga({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    yield call(Api.requestdelete, id);
    const requestListData = yield call(Api.getRequestList);
    yield put(actions.dashboard.setRequestListAction(requestListData));
    const open = {
      modalName: 'deleteComfirmationModal',
      visible: false,
    };
    yield put(actions.dashboard.setNewRequestData([]));

    yield put(actions.modals.setModalVisibleFlagAction(open));
    swal({
      title: 'Paraiška sėkmingai ištrinta',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* saveCompanyData(request) {
  try {
    request.payload.RequestId = request.requestId;
    let res = yield call(Api.saveCompanyData, request.payload);

    const companyId = {
      CompanyId: res.CompanyId,
    };

    yield call(Api.startRegistersProcessing, companyId);

    while (true) {
      yield delay(1000);
      const processStatus = yield call(
        Api.getRegistersProcessingStatus,
        res.CompanyId
      );
      if (processStatus.status === 'Failed') {
        //failed to check status
        break;
      } else if (processStatus.status === 'Finished') {
        yield delay(1000);
        const result = yield call(
          Api.getFinancialCriteriaResults,
          res.CompanyId
        );

        if (result.Decision === 'Accepted') {
          yield put(
            actions.dashboard.setCompanySavedAction({
              id: res.CompanyId,
              saved: true,
              checking: false,
              error: false,
              decision: {},
            })
          );
        } else {
          yield put(
            actions.dashboard.setCompanySavedAction({
              id: res.CompanyId,
              saved: false,
              checking: false,
              error: true,
              decision: result,
            })
          );
        }
        break;
      }
    }
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    //yield put(actions.dashboard.setInvoiceDataAction(request.data));
  }
}

function* getIsConsentAgreedSaga() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const loginType = getLocalStorageItemIfNotExpired('loginType');

    if (loginType != null) {
      yield put(actions.dashboard.setLoginType(loginType));
    } else {
      throw 'NoLoginType';
    }

    const isAgreed = yield call(Api.isConsentAgreed);
    yield put(actions.dashboard.setIsConsentAgreedAction(isAgreed));

    if (isAgreed.isAgreed !== true) {
      // get company info
      const getConsentData = yield call(Api.getConsent);
      yield put(actions.dashboard.setConsentDataAction(getConsentData));
    }
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getRequestLimit(request) {
  try {
    let data = yield call(Api.getRequestLimit, request.payload);
    yield put(actions.dashboard.setRequestLimitDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* getRequestGeneralLimit(request) {
  try {
    let data = yield call(Api.getRequestGeneralLimit, request.payload);
    yield put(actions.dashboard.setRequestGeneralLimitDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* loadGeneralData() {
  try {
    if (checkBOPage()) {
      const data = yield call(Api.getBackofficeGeneralData);
      yield put(actions.dashboard.setGeneralDataAction(data));
    } else {
      const data = yield call(Api.getGeneralData);
      yield put(actions.dashboard.setGeneralDataAction(data));
    }
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* getCanCreateRequestSaga() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    // canCreateRequest
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* fileUploadSaga({ payload, actionType }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    if (actionType === 'upload') {
      yield call(Api.UploadFiles, payload);
    }
  } catch (error) {
    swal({
      title: 'Klaida',
      icon: 'error',
      buttons: {
        confirm: {
          text: error.errorDescription,
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getUploadedFileListSaga({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const data = yield call(Api.GetUploadedFiles, id);
    yield put(actions.dashboard.setUploadedFileList(data));
    //sign Table
    const signingInfo = yield call(Api.GetSigningInfoUser, id);
    yield put(actions.dashboard.setSigningInfoAction(signingInfo));
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getSigningUrlSaga({ payload, requestId, user }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    if (user === 'user') {
      const getUrl = yield call(Api.getSigningUrl, requestId);
      if (getUrl) {
        const win = window.open(getUrl.signingUrl, '_blank');
        // console.log(win)
        // win.focus();
      }
    } else if (user === 'manager') {
      //do manager stuff
      const getUrl = yield call(Api.getSigningUrlManager, requestId);
      if (getUrl) {
        const win = window.open(getUrl.signingUrl, '_blank');
        // win.focus();
      }
      console.log('manager saga was called');
      console.log(requestId);
    }
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
    const open = {
      modalName: 'signContractModal',
      visible: false,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));
  }
}

function* getSigningDecisionUrlSaga({ requestId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const getUrl = yield call(Api.getSigningUrlDecision, requestId);
    if (getUrl) {
      const win = window.open(getUrl.signingUrl, '_blank');
      // win.focus();
    }
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* GetSigningUrlBatchSaga() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const getUrl = yield call(Api.getSigningUrlBatch);
    if (getUrl) {
      const win = window.open(getUrl.signingUrl, '_blank');
      // win.focus();
    }
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getFinancialCriteriaResultsSaga({ id, user, doubleCheck, formVersion }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    if (user === 'Manager') {
      const data = yield call(Api.getFinancialCriteriaResultsManager, id);
      yield put(actions.dashboard.setFinancialCriteriaResultsAction(data, formVersion));
    } else {
      if (doubleCheck === 'approve') {
        yield put(actions.dashboard.setUserOnSyncFlagAction(true));

        yield call(Api.checkForApprove, { requestId: id });

        //loop
        while (true) {
          yield delay(2000);
          const processStatus = yield call(
            Api.GetFinancialEvaluationStatus,
            id
          );
          if (processStatus.status === 'Failed') {
            const open = {
              modalName: 'confirmWithFinancialCriteriaModal',
              visible: false,
              formVersion: formVersion,
            };
            yield put(actions.modals.setModalVisibleFlagAction(open));
            swal({
              title: 'Įvyko klaida',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
            break;
          } else if (processStatus.status === 'Finished') {
            yield put(actions.dashboard.setUserOnSyncFlagAction(true));

            const loginType = getLocalStorageItemIfNotExpired('loginType');
            if (loginType === 'Company') {
              const data = yield call(Api.getFinancialCriteriaResultsFinal, id);
              yield put(
                actions.dashboard.setFinancialCriteriaResultsAction(data, formVersion)
              );
            } else if (loginType === 'Individual') {
              console.log('Individual GetFinancialEvaluationStatus');
              const data = yield call(Api.getFinancialCriteriaResultsFinal, id);
              yield put(
                actions.dashboard.setFinancialCriteriaResultsAction(data, formVersion)
              );
            }
            break;
          }
        }
      } else {
        const getRequestStatus = (state) =>
          state.dashboardState.requestData.RequestSystemInfo
            .AllEvaluationCompleted;
        const status = yield select(getRequestStatus);

        console.log(status);
        if (status) {
          const data = yield call(Api.getFinancialCriteriaResultsFinal, id);
          yield put(actions.dashboard.setFinancialCriteriaResultsAction(data, formVersion));
        } else {
          const data = yield call(Api.getFinancialCriteriaResults, id);
          yield put(actions.dashboard.setFinancialCriteriaResultsAction(data, formVersion));
        }
      }
    }
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
    // yield put(actions.modals.setModalOnSyncFlagAction(false));
  }
}

function* sendForApproveSaga({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    yield call(Api.requestSendForApprove, id);

    yield put(actions.dashboard.createNewRequestAction(id, 'view'));

    swal({
      title: 'Paraiška pateikta',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* sendForApproveWithDocsSaga({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    yield call(Api.requestSendForApproveWithDocs, id);

    yield put(actions.dashboard.createNewRequestAction(id, 'view'));

    swal({
      title: 'Papildomi dokumentai pateikti vertinimui',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* paymentRequestSendForApproveSaga({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    yield call(Api.paymentRequestSendForApprove, id);
    const url = new URL(window.location.href);
    //yield put(actions.dashboard.createNewRequestAction(id, 'view'));

    const requestId = url.searchParams.get('requestId');
    const paymentId = url.searchParams.get('paymentRequestId');

    yield put(
      actions.dashboard.createNewPaymentRequestAction(
        requestId,
        'view',
        paymentId
      )
    );

    swal({
      title: 'Mokėjimo prašymas pateiktas vertinimui',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* saveRepresentative({ data }) {
  try {
    yield call(Api.saveRepresentative, data);
    const open = {
      modalName: 'autorizationModal',
      visible: false,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));
    yield put(actions.dashboard.loadRepresentatives());
  } catch (error) {
    yield put(actions.dashboard.setSaveRepresentativeError(error));
    yield put(actions.dashboard.setRepresentativeAction(data));
    //yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* loadRepresentatives() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const data = yield call(Api.loadRepresentatives);
    yield put(actions.dashboard.setRepresentatives(data));
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* loadCompanyRepresentatives() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const data = yield call(Api.loadCompanyRepresentatives);
    yield put(actions.dashboard.setCompanyRepresentatives(data));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getPaymentRequestListSaga({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    //yield delay(1000); //AV todo: get paymensList
    const data = yield call(Api.paymentRequestList, id);
    yield put(actions.dashboard.setPaymentRequestsListAction(data));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* createNewPaymentRequestSaga({
  id,
  actionType,
  paymentId,
  requestId,
  surveyData,
}) {
  try {
    if (id !== null) {
      yield put(actions.dashboard.setUserOnSyncFlagAction(true));
      // yield delay(1000); //AV todo: get paymensList
      const data = yield call(Api.paymentRequest, id, paymentId, actionType);
      data.actionType = actionType;
      data.requestId = id;
      yield put(actions.dashboard.setPaymentRequestAction(data));
    } else {
      let data;

      if (surveyData) {
        data = yield call(
          Api.paymentRequestStartWithSurvey,
          paymentId,
          surveyData
        );
      } else {
        data = yield call(Api.paymentRequestStart, {
          requestId: paymentId,
        });
      }

      if (data && !isNaN(data)) {
        yield call(
          setLocation,
          `${config.APP_PATHS.paymentrequest}?requestId=${paymentId}&paymentRequestId=${data}&actionType=edit`
        );
      }
    }
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* createPaymentRequestNewPrDocumentSaga({ id, payload }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const data = yield call(Api.paymentRequestSavePrDocument, payload, id);
    if (data && !checkBOPage()) {
      yield createPaymentRequestPrDocumentSaga({
        id,
        docId: data,
        actionType: 'edit',
      });
    }
    yield put(actions.dashboard.approveRequestSuccess(true));
  } catch (error) {
    yield* errorhandle(error);
    yield put(actions.dashboard.approveRequestError(error));
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* createPaymentRequestPrDocumentSaga({
  id,
  docId,
  actionType,
  number,
}) {
  try {
    if (docId !== null) {
      yield put(actions.dashboard.setUserOnSyncFlagAction(true));
      const data = yield call(Api.paymentRequestPrDocument, id, docId);
      data.actionType = actionType;
      data.Number = number;
      data.PaymentRequestId = id;
      yield put(actions.dashboard.setPaymentRequestPrDocument(data));
    } else {
      yield put(
        actions.dashboard.setPaymentRequestPrDocument({
          actionType: actionType,
        })
      );
    }
    //yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    //const data = yield call(Api.paymentRequestSavePrDocument, payload, id);
    //console.log('daaa:' + JSON.stringify(data));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* createPaymentRequestPrDocumentPayBackSaga({
  id,
  docId,
  actionType,
  number,
  parentPrDocumentId,
}) {
  try {
    if (docId !== null) {
      yield put(actions.dashboard.setUserOnSyncFlagAction(true));

      const data = yield call(
        Api.paymentRequestPrDocumentPayBack,
        id,
        parentPrDocumentId ? parentPrDocumentId : docId,
        parentPrDocumentId ? docId : ''
      );
      data.actionType = actionType;
      data.Number = number;
      data.PaymentRequestId = id;
      yield put(actions.dashboard.setPaymentRequestPrDocument(data));
    } else {
      yield put(
        actions.dashboard.setPaymentRequestPrDocument({
          actionType: actionType,
        })
      );
    }
    //yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    //const data = yield call(Api.paymentRequestSavePrDocument, payload, id);
    //console.log('daaa:' + JSON.stringify(data));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* deletePrDocumentRequestSaga({ docId, requestId, paymentId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    yield call(Api.paymentRequestDeletePrDocument, docId);
    const data = yield call(Api.paymentRequest, requestId, paymentId, 'edit');
    data.actionType = 'edit';
    data.requestId = requestId;
    yield put(actions.dashboard.setPaymentRequestAction(data));
    //const requestListData = yield call(Api.getRequestList);
    //yield put(actions.dashboard.setRequestListAction(requestListData));
    const open = {
      modalName: 'deleteComfirmationModal',
      visible: false,
    };
    //yield put(actions.dashboard.setNewRequestData([]));

    yield put(actions.modals.setModalVisibleFlagAction(open));
    swal({
      title: 'Mokėjimo prašymo dokumentas sėkmingai ištrintas',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* sendForApproveWithDocsForChangeConditionsSaga({ id }) {
  console.log(id);
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    yield call(Api.requestSendForApproveWithDocsForChangeConditions, id);

    yield put(actions.dashboard.createNewRequestAction(id, 'view'));

    swal({
      title: 'Papildomi dokumentai pateikti sąlygų keitimui',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}
export {
  loadSellerInvoiceList,
  passCompanyInfoSaga,
  getRequestListSaga,
  loadBuyerInvoiceList,
  createNewRequestSaga,
  loadInvoiceData,
  saveInvoiceData,
  saveRequestSaga,
  saveCompanyData,
  deleteRequestSaga,
  getIsConsentAgreedSaga,
  getRequestLimit,
  loadGeneralData,
  getCanCreateRequestSaga,
  getRequestGeneralLimit,
  fileUploadSaga,
  getUploadedFileListSaga,
  getSigningUrlSaga,
  getFinancialCriteriaResultsSaga,
  createNewRequestIndividualSaga,
  loadOwnerInvoiceData,
  getSigningDecisionUrlSaga,
  GetSigningUrlBatchSaga,
  saveRepresentative,
  loadRepresentatives,
  loadCompanyRepresentatives,
  sendForApproveSaga,
  getPaymentRequestListSaga,
  createNewPaymentRequestSaga,
  sendForApproveWithDocsSaga,
  createPaymentRequestNewPrDocumentSaga,
  createPaymentRequestPrDocumentSaga,
  deletePrDocumentRequestSaga,
  paymentRequestSendForApproveSaga,
  sendForApproveWithDocsForChangeConditionsSaga,
  createPaymentRequestPrDocumentPayBackSaga,
};
