import React, { useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import styles from './globalConfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { config } from './../../api/config';
import { Api } from './../../api/api';

const ConfirmWithAdditionalDocs = ({
  open,
  data,
  loading,
  dispatch,
  requestId,
  comment,
}) => {
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [rejectWithoutFile, setRejectWithoutFile] = useState(false);
  const [rejectByClient, setRejectByClient] = useState(false);

  const cancel = () => {
    const open = {
      modalName: 'confirmRejectNew',
      visible: false,
    };
    setShowError(false);
    setFileToUpload(null);
    setRejectWithoutFile(false);
    setRejectByClient(false);
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    //    console.log('aa');
    const formData = new FormData();
    formData.append('RequestId', requestId);
    formData.append('File', fileToUpload);
    formData.append('Comment', comment);
    formData.append('RejectWithoutFile', rejectWithoutFile);
    formData.append('RejectByClient', rejectByClient);

    setIsloading(true);
    //fetch(Api.API_BASE_URL + config.API_ENDPOINTS.requestApprove(requestId), {
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.requestReject(requestId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: 'include',

      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.backoffice.getRequestAction(requestId));
          dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
          dispatch(
            actions.backoffice.saveEditAdditionalComments('get', requestId)
          );
          dispatch(actions.backoffice.getRequestLogAction(requestId));

          cancel();

          swal({
            title: 'Paraiška sėkmingai atmesta',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          res.json().then((r) => {
            swal({
              title: `${
                r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
              }`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={isLoading}>
            <h2>Atmesti paraišką</h2>

            <br />
            <p>Prašome prisegti neigiamą sprendimą</p>
            <br />
            <br />
            <div className={styles.inline}>
              <label
                className={styles.containerLabel}
                style={{ marginTop: '0rem', marginBottom: '1.5rem' }}
              >
                <input
                  type="checkbox"
                  name={'generateInvoice'}
                  checked={rejectByClient}
                  onChange={() => setRejectByClient(!rejectByClient)}
                />
                <span className="checkmark"></span>
                <span className={styles.checkboxTitle}>
                  Atmesti paraišką pareiškėjo prašymu
                </span>
              </label>
            </div>
            <div className={styles.inline}>
              <label
                className={styles.containerLabel}
                style={{ marginTop: '0rem', marginBottom: '1.5rem' }}
              >
                <input
                  type="checkbox"
                  name={'generateInvoice'}
                  checked={rejectWithoutFile}
                  onChange={() => setRejectWithoutFile(!rejectWithoutFile)}
                />
                <span className="checkmark"></span>
                <span className={styles.checkboxTitle}>
                  Atmesti paraišką be sprendimo pridėjimo
                </span>
              </label>
            </div>

            {!rejectWithoutFile && (
              <div className={styles.addFileContainer}>
                <Button type="standart" onClick={() => selectFile('fileElem')}>
                  Prisegti priedą
                </Button>
                <input
                  type="file"
                  id="fileElem"
                  //accept="application/pdf"
                  className="d-none"
                  onChange={(e) => setFileToUpload(e.target.files[0])}
                />
                <p style={{ marginTop: '0.5rem' }}>
                  {fileToUpload && fileToUpload.name ? fileToUpload.name : ''}
                </p>
              </div>
            )}
            
            <br />
            <br />
            <br />
            <div className={styles.btnContainer}>
              <div>
                <Button type="standart" onClick={() => yes(data)}>
                  Atmesti
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(ConfirmWithAdditionalDocs);
