import React, { useState } from 'react';

import { connect } from 'react-redux';
import styles from './changeCompanySize.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';

const ChangeSetNotDeletable = ({
  open,
  requestId,
  dispatch,
  notDeletableProp,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [notDeletable, setNotDeletable] = useState(notDeletableProp);

  const cancel = () => {
    //setNotValid(false);
    setNotDeletable(notDeletableProp);

    const open = {
      modalName: 'changeSetNotDeletable',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    setNotValid(true);
    if (notDeletable !== null) {
      setIsloading(true);

      fetch(
        Api.API_BASE_URL +
          config.API_ENDPOINTS.SetNotDeletable(requestId, notDeletable),
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            RequestId: requestId,
            notDeletable:
              notDeletable == 'true'
                ? true
                : notDeletable == 'false'
                ? false
                : null,
          }),
        }
      )
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.backoffice.getRequestAction(requestId));
            cancel();

            swal({
              title: 'Paraiškos netrynimo požymis sėkmingai pakeistas',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              swal({
                title: `${
                  r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
                }`,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
        });
    } else {
      setNotValid(true);
    }
  };
  const onCompanySizeTypeInvegaChange = (val) => {
    setNotDeletable(val.target.value);
    //console.log(val);
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <h2>Paraiškos netrynimo požymis</h2>
          <WithSpinner loading={isLoading}>
            <div
              className={styles.inputsContainer}
              style={{ marginTop: '30px' }}
            >
              <select
                disabled={false}
                name="companySizeTypeIdInvega"
                onChange={onCompanySizeTypeInvegaChange}
                value={notDeletable}
              >
                <option value={'false'}>Paraiška gali būti ištrinta</option>
                <option value={'true'}>Paraiška negali būti ištrinta</option>
              </select>
              {notValid && notDeletable === null && (
                <p className="errorsBlockText error1">
                  Privalote pasirinkti požymį
                </p>
              )}
            </div>
            <div className={styles.btnContainer} style={{ display: 'block' }}>
              <Button type="standart" onClick={() => yes(requestId)}>
                Išsaugoti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.dashboardState.isOnSync,
// });

export default connect()(ChangeSetNotDeletable);
