import React from 'react';
import styles from './validationErrorModal.module.scss';
import { actions } from './../../state/actions';
import { connect } from 'react-redux';
import { translationsForValidationInputs } from '../../utils/translation/translation';

const ValidationErrorModal = ({ open, data, dispatch }) => {
  const cancel = () => {
    const open = {
      modalName: 'validationErrorModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '300px' }}
        >
          <div className={styles.contentBlock}>
            <h2> Nustatytos klaidos:</h2>
            <div className={styles.criteriaContainer}>
              {data != null && typeof data !== 'string' ? (
                Object.keys(data).length !== 0 &&
                Object.keys(data).map((key, i) => (
                  <>
                    <p key={i}>{data[key][0]}</p>
                    <p style={{ marginBottom: '0.7rem' }}>
                      Laukelis pavadinimu:{' '}
                      {translationsForValidationInputs(
                        key.substring(key.indexOf('.') + 1)
                      )}
                    </p>
                  </>
                ))
              ) : (
                <>
                  <p>{data}</p>
                </>
              )}
            </div>
            <div className={styles.btnContainer}>
              <button
                type="standart"
                onClick={() => cancel()}
                className="vaiisisloginbtn"
              >
                Gerai
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  data: state.modalsState.validationErr,
});

export default connect(mapStateToProps)(ValidationErrorModal);
