import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {
  format,
  setYear,
  setMonth,
  setDate,
  addMonths,
  getMonth,
  subDays,
} from 'date-fns';
import { connect } from 'react-redux';
import styles from './confirmRequestModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import {
  isSumValid2,
  isInterestRateValid,
  isInterestVariableRateValid,
  validateTextinput,
} from './../../utils/auth/validations';

const SubmitConditionsChangeRequestModal = ({
  open,
  requestId,
  loading,
  dispatch,
  comment,
  loanDelayTerm,
  loanPurposeId,
  invegaContractNumber2,
  invegaLoanPaymentTerm2,
  invegaLoanInterestRate2,
  invegaLoanVariableInterestRate2,
  invegaLoanSum2,
  loanPaymentDay2,
  euriborChangeDate2,
  firstInterestChangeDate2,
  interestTypeId2,
  euriborPeriodId2,
  iban2,
  invegaSigningDate,
  invegaLoanDelayTerm2,
  invegaPaymentDate2,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [invegaLoanInterestRate, setInvegaLoanInterestRate] = useState(
    invegaLoanInterestRate2
  );
  const [invegaLoanVariableInterestRate, setInvegaLoanVariableInterestRate] =
    useState(invegaLoanVariableInterestRate2);
  const [invegaLoanPaymentTerm, setinvegaLoanPaymentTerm] = useState(
    invegaLoanPaymentTerm2
      ? format(new Date(invegaLoanPaymentTerm2), 'yyyy-MM-dd')
      : ''
  );
  const [invegaLoanSum, setInvegaLoanSum] = useState(invegaLoanSum2);
  const [invegaContractNumber, setInvegaContractNumber] = useState(
    invegaContractNumber2
  );
  const [euriborChangeDate, setEuriborChangeDate] =
    useState(euriborChangeDate2);

  const [firstInterestChangeDate, setFirstInterestChangeDate] = useState(
    firstInterestChangeDate2
  );

  const [interestTypeId, setInterestTypeId] = useState(interestTypeId2);
  const [euriborPeriodId, setEuriborPeriodId] = useState(euriborPeriodId2);
  const [invegaLoanDelayTerm, setInvegaLoanDelayTerm] = useState(
    loanDelayTerm || 6
  );
  const [paymentDate, setPaymentDate] = useState(loanPaymentDay2);
  const [iban, setIban] = useState(iban2);
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const [invoiceFee, setInvoiceFee] = useState(null);
  //const [invegaLoanDelayTermDate, setInvegaLoanDelayTermDate] = useState(
  //  invegaLoanDelayTermDate2
  //);
  const [invegaPaymentDate, setInvegaPaymentDate] =
    useState(invegaPaymentDate2);

  const [fileToUpload, setFileToUpload] = useState(null);

  const cancel = () => {
    setNotValid(false);
    setInvegaLoanInterestRate(invegaLoanInterestRate2);
    setInvegaLoanVariableInterestRate(invegaLoanVariableInterestRate2);
    setinvegaLoanPaymentTerm(
      invegaLoanPaymentTerm2
        ? format(new Date(invegaLoanPaymentTerm2), 'yyyy-MM-dd')
        : ''
    );
    setInvegaLoanSum(invegaLoanSum2);
    setInvegaLoanDelayTerm(invegaLoanDelayTerm2);
    setInvegaContractNumber(invegaContractNumber2);
    setFirstInterestChangeDate(firstInterestChangeDate2);
    setEuriborChangeDate(euriborChangeDate2);
    setInterestTypeId(interestTypeId2);
    setEuriborPeriodId(euriborPeriodId2);
    //setInvegaLoanDelayTermDate(
    //  invegaLoanDelayTermDate2
    //    ? format(new Date(invegaLoanDelayTermDate2), 'yyyy-MM-dd')
    //    : ''
    //);
    setInvegaPaymentDate(
      invegaPaymentDate2
        ? format(new Date(invegaPaymentDate2), 'yyyy-MM-dd')
        : ''
    );
    setGenerateInvoice(false);
    setFileToUpload(null);
    setInvoiceFee(null);

    const open = {
      modalName: 'acceptConditionsChangeRequestModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const validatateIfSF = () => {
    if (!generateInvoice) {
      return true;
    }
    if (generateInvoice && isSumValid2(invoiceFee) === null) {
      return true;
    }
    return false;
  };

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const onInterestTypeIdChange = (val) => {
    setInterestTypeId(val.target.value);
  };

  const onEuriborPeriodIdChange = (val) => {
    setEuriborPeriodId(val.target.value);
  };

  const checkLoanDelayTermFormat = (val) => {
    if (!Number.isInteger(+val)) {
      return 'Turi būti įvestas sveikas skaičius';
    }
    // if (
    //   +val > 0 &&
    //   +val <= 120 &&
    //   invegaLoanPaymentTerm &&
    //   +val > +invegaLoanPaymentTerm
    // ) {
    //   return ' lauko [paskolos atidėjimo terminas (mėn)] reikšmė negali būti didesnė negu lauko [Pageidaujamas Paskolos terminas mėn].';
    // }
    if (+val >= 1 && +val <= 120) {
      return null;
    }

    return 'Turi būti įvestas sveikas skaičius nuo 1 iki 120';
  };

  const yes = async () => {
    if (
      invegaLoanInterestRate &&
      //invegaLoanVariableInterestRate &&
      //validateFieldsBasedOnInterestType(euriborChangeDate) &&
      //validateFieldsBasedOnInterestType(euriborPeriodId) &&
      //validateFieldsBasedOnInterestType(invegaLoanVariableInterestRate) &&
      invegaLoanPaymentTerm &&
      //euriborChangeDate &&
      isSumValid2(invegaLoanSum) === null &&
      isInterestRateValid(invegaLoanInterestRate) === null &&
      //isInterestVariableRateValid(invegaLoanVariableInterestRate) === null &&
      invegaContractNumber &&
      isValidIban(iban) &&
      validatateIfSF() &&
      invegaLoanDelayTerm &&
      invegaPaymentDate &&
      fileToUpload
    ) {
      const formData = new FormData();
      const formattedData = format(
        new Date(invegaLoanPaymentTerm),
        'yyyy-MM-dd'
      );
      const formattedData2 = firstInterestChangeDate
        ? format(new Date(firstInterestChangeDate), 'yyyy-MM-dd')
        : '';
      const formattedData3 = format(new Date(euriborChangeDate), 'yyyy-MM-dd');
      formData.append('RequestId', requestId);
      formData.append('InvegaLoanPaymentTerm', formattedData);
      formData.append('InvegaDocumentNumber', invegaContractNumber);
      formData.append('InvegaLoanSum', invegaLoanSum);
      formData.append('InvegaLoanInterestRate', invegaLoanInterestRate);
      formData.append('InterestTypeId', interestTypeId ? interestTypeId : 1);
      if (interestTypeId == 1 || interestTypeId == 3) {
        formData.append(
          'EuriborChangeDate', formattedData3);
          formData.append('EuriborPeriodId', euriborPeriodId ? euriborPeriodId : 1);
          formData.append('FirstInterestChangeDate', formattedData2);  
          formData.append('InvegaLoanVariableInterestRate', invegaLoanVariableInterestRate);
      };
    
      formData.append('PaymentDay', paymentDate ? paymentDate : '');

      formData.append('Iban', iban ? iban : '');
      formData.append('GenerateInvoice', generateInvoice);
      formData.append('InvegaLoanDelayTerm', invegaLoanDelayTerm);
      //formData.append(
       // 'InvegaLoanDelayTermDate',
       // invegaLoanDelayTermDate
      //    ? format(new Date(invegaLoanDelayTermDate), 'yyyy-MM-dd')
      //    : ''
      //);
      formData.append(
        'InvegaPaymentDate',
        invegaPaymentDate
          ? format(new Date(invegaPaymentDate), 'yyyy-MM-dd')
          : ''
      );
      formData.append('File', fileToUpload);
      formData.append('Comment', comment);
      if (generateInvoice) {
        formData.append('InvoiceFee', invoiceFee);
      }

      setIsloading(true);

      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.ApproveChangeConditions, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.backoffice.getRequestAction(requestId));
            dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
            dispatch(
              actions.backoffice.saveEditAdditionalComments('get', requestId)
            );
            dispatch(actions.backoffice.getRequestLogAction(requestId));

            cancel();

            swal({
              title:
                'Sąlygų keitimo dokumentas sėkmingai pateiktas pareiškėjo pasirašymui',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            }).then((results) => {
              if (results) {
                window.location.reload();
              }
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              swal({
                title: `${
                  r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
                }`,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
        });
    } else {
      setNotValid(true);
    }
  };

  const isValidIban = (v) => {
    if (!v) {
      return false;
    }
    return v.match(/[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}/);
  };

  const returnFirstInterestChangeDate = () => {
    const invedaSign = new Date(invegaSigningDate);

    if (!invegaSigningDate || isNaN(invedaSign)) {
      return '';
    }
    if (euriborPeriodId == '4') {
      let nextYearFirstDay = setYear(invedaSign, invedaSign.getFullYear() + 1);
      nextYearFirstDay = setMonth(nextYearFirstDay, 0);
      nextYearFirstDay = setDate(nextYearFirstDay, 1);

      return nextYearFirstDay;
    } else if (euriborPeriodId == '3') {
      const month = getMonth(invedaSign);
      let halfYearLater = new Date(invedaSign);

      if (month > 5) {
        halfYearLater = addMonths(invedaSign, 6);
        halfYearLater = setMonth(halfYearLater, 0);
        halfYearLater = setDate(halfYearLater, 1);
      } else {
        halfYearLater = setMonth(halfYearLater, 5);
        halfYearLater = setDate(halfYearLater, 1);
      }

      return halfYearLater;
    } else if (euriborPeriodId == '2') {
      const month = getMonth(invedaSign);
      let quarterYearLater = new Date(invedaSign);

      if (month < 3) {
        quarterYearLater = setMonth(quarterYearLater, 3);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 2 && month < 6) {
        quarterYearLater = setMonth(quarterYearLater, 7);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 5 && month < 9) {
        quarterYearLater = setMonth(quarterYearLater, 9);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 8) {
        quarterYearLater = setYear(invedaSign, invedaSign.getFullYear() + 1);
        quarterYearLater = setMonth(quarterYearLater, 0);
        quarterYearLater = setDate(quarterYearLater, 1);
      }

      return quarterYearLater;
    }

    return '';
  };

  const validateFieldsBasedOnInterestType = (selectedField) => {
    if (interestTypeId != '2') {
      return true;
    }
    return selectedField;
  };

  useEffect(() => {
    setFirstInterestChangeDate(returnFirstInterestChangeDate());
  }, [euriborPeriodId]);

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={isLoading}>
            <h2>Pateikti sąlygų keitimą pasirašymui</h2>
            <div className={styles.inputsContainer}>
              <div
                className={styles.inputsInline}
                style={{ marginLeft: '2.5rem' }}
              >
                <div>
                  <InputWithTextInline
                    type="string"
                    value={invegaContractNumber}
                    onChange={(e) => setInvegaContractNumber(e.target.value)}
                    placeholder={'Sutarties numeris'}
                    disabled={false}
                  />
                  {notValid && !invegaContractNumber && (
                    <p
                      className={styles.error}
                      style={{ textAlign: 'center', width: '100%' }}
                    >
                      Neįvestas numeris
                    </p>
                  )}
                </div>
              </div>

              <div
                className={styles.inputsInline}
                style={{ marginRight: '2rem', marginLeft: '2rem' }}
              >
                <div>
                  <InputWithTextInline
                    type="string"
                    value={invegaLoanSum}
                    onChange={(e) => setInvegaLoanSum(e.target.value)}
                    placeholder={'Paskolos suma'}
                    disabled={false}
                  />
                  {notValid && (
                    <p
                      className={styles.error}
                      style={{ textAlign: 'center', width: '100%' }}
                    >
                      {isSumValid2(invegaLoanSum, 'privalomas laukas', 3)}
                      {/* Privalote įrašyti paskolos sumą */}
                    </p>
                  )}
                </div>
              </div>

              <div
                className={styles.inputsInline}
                style={{ marginRight: '0rem', width: '150px' }}
              >
                <div>
                  <label
                    className="inlineDatepickerInputTitle"
                    style={{ width: '150px', marginTop: '1.5rem' }}
                  >
                    Galutinis paskolos grąžinimo terminas
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    value={invegaLoanPaymentTerm}
                    selected={
                      invegaLoanPaymentTerm
                        ? new Date(invegaLoanPaymentTerm)
                        : ''
                    }
                    onChange={(e) => setinvegaLoanPaymentTerm(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                    style={{ width: '50px' }}
                  />

                  {notValid && !invegaLoanPaymentTerm && (
                    <p
                      className={styles.error}
                      style={{ textAlign: 'center', width: '100%' }}
                    >
                      Neįvestas terminas
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              className={styles.inputsContainer}
              style={{ marginTop: '1rem' }}
            >
              <div
                className={styles.inputsInline}
                style={{ marginTop: '1.2rem', marginLeft: '2rem' }}
              >
                <div
                // className={styles.inputsInline}
                // style={{ marginLeft: '2rem' }}
                >
                  {/*<label
                    style={{ width: '100%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Paskolos atidėjimo terminas{' '}
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '20px' }}
                    value={
                      invegaLoanDelayTermDate
                        ? new Date(invegaLoanDelayTermDate)
                        : ''
                    }
                    selected={
                      invegaLoanDelayTermDate
                        ? new Date(invegaLoanDelayTermDate)
                        : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setInvegaLoanDelayTermDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && !invegaLoanDelayTermDate && (
                    <p
                      className={styles.error}
                      style={{ marginLeft: '2.2rem' }}
                    >
                      Neįvesta data
                    </p>
                  )}*/}
                  <label
                  style={{ width: '100%' }}
                  className="inlineDatepickerInputTitle"
                >
                  Paskolos atidėjimo terminas (mėn.){' '}
                </label>
                   <InputWithTextInline
                    type="string"
                    value={invegaLoanDelayTerm}
                    onChange={(e) => setInvegaLoanDelayTerm(e.target.value)}
                    //placeholder={'Paskolos atidėjimo terminas (mėn.)'}
                    disabled={false}
                    style={{ marginTop: '-2.1rem' }}
                  />
                  {notValid &&
                    (validateTextinput(
                      invegaLoanDelayTerm,
                      'privalomas laukas',
                      1
                    ) ||
                      checkLoanDelayTermFormat(invegaLoanDelayTerm)) && (
                      //!disableCheck('LoanDelayTerm') &&
                      <p className={styles.error} style={{ width: '140px' }}>
                        {validateTextinput(
                          invegaLoanDelayTerm,
                          'privalomas laukas',
                          1
                        )}
                        {!validateTextinput(
                          invegaLoanDelayTerm,
                          'privalomas laukas',
                          1
                        ) && checkLoanDelayTermFormat(invegaLoanDelayTerm)}
                      </p>
                        )}
                </div>
              </div>
              <div
                className={styles.inputsInline}
                style={{ marginLeft: '2rem', marginTop: '2rem' }}
              >
                <label
                  style={{ width: '100%' }}
                  className="inlineDatepickerInputTitle"
                >
                  Išmokėjimo terminas{' '}
                </label>
                <DatePicker
                  className="inlineDatepickerInput"
                  style={{ marginLeft: '0px', padding: '20px' }}
                  value={invegaPaymentDate ? new Date(invegaPaymentDate) : ''}
                  selected={
                    invegaPaymentDate ? new Date(invegaPaymentDate) : ''
                  }
                  // onChange={(e) => console.log(e)}

                  onChange={(e) => setInvegaPaymentDate(e)}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  disabled={false}
                />
                {notValid && !invegaPaymentDate && (
                  <p className={styles.error} style={{ marginLeft: '2.2rem' }}>
                    Neįvesta data
                  </p>
                )}
              </div>
            </div>
            <div style={{ marginTop: '-1rem' }}></div>
            <div className={styles.inputsContainer}>
              <div
                className={styles.inputsInline}
                style={{ marginRight: '0rem', marginLeft: '2rem' }}
              >
                <div>
                  <InputWithTextInline
                    type="string"
                    value={invegaLoanInterestRate}
                    onChange={(e) => setInvegaLoanInterestRate(e.target.value)}
                    placeholder={'Marža, %'} // ex. Palukanu norma
                    disabled={false}
                  />
                  {notValid && (
                    <p
                      className={styles.error}
                      style={{ textAlign: 'center', width: '100%' }}
                    >
                      {isInterestRateValid(
                        invegaLoanInterestRate,
                        'privalomas laukas',
                        3
                      )}
                    </p>
                  )}
                </div>
              </div>

              <div
                className={styles.inputsInline}
                style={{
                  marginRight: '0rem',
                  marginLeft: '1rem',
                  width: '160px',
                }}
              >
               {interestTypeId != '2' && (
                  <div>
                    <InputWithTextInline
                      type="string"
                      value={invegaLoanVariableInterestRate}
                      onChange={(e) =>
                        setInvegaLoanVariableInterestRate(e.target.value)
                      }
                      placeholder={'Kintama palūkanų dalis, %'}
                      disabled={false}
                    />
                    {notValid && interestTypeId != '2' && (
                      <p
                        className={styles.error}
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {isInterestVariableRateValid(
                          invegaLoanVariableInterestRate,
                          'privalomas laukas',
                          3
                        )}
                      </p>
                    )}
                  </div>
               )}
              </div>

              <div
                className={styles.inputsInline}
                style={{ marginRight: '', marginLeft: '1rem' }}
              >
                <div>
                  {/* <InputWithTextInline
                    type="string"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                    placeholder={'Mokėjimo diena'}
                    disabled={false}
                  /> */}
                  <label
                    style={{
                      margin: '30px 0 8px',
                      color: '#597a96',
                      fontSize: '0.8rem',
                      lineHeight: '1.5',
                    }}
                  >
                    Mokėjimo diena
                  </label>
                  <select
                    disabled={false}
                    name="LoanPaymentDay"
                    onChange={(e) => setPaymentDate(e.target.value)}
                    value={paymentDate}
                  >
                    {[null, 5, 15, 25].map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                {notValid && !paymentDate && (
                  <p
                    className={styles.error2}
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      marginTop: '5px',
                    }}
                  >
                    Neįvesta mokėjimo diena
                  </p>
                )}
              </div>
            </div>

            <div style={{ marginTop: '0rem' }}></div>
            <div className={styles.inputsContainer}>
              <div
                className={styles.inputsInline}
                style={{ marginRight: '0rem', marginLeft: '2rem' }}
              >
                <label
                  style={{ marginTop: '33px', width: '110%' }}
                  className="inlineDatepickerInputTitle"
                >
                  Palūkanų normos rūšis
                </label>

                <select
                  disabled={false}
                  name="interestTypeId"
                  onChange={onInterestTypeIdChange}
                  value={interestTypeId}
                  style={{ marginTop: '13px' }}
                >
                  <option value={'1'}>Kintama</option>
                  <option value={'2'}>Fiksuota</option>
                  <option value={'3'}>Dalinai fiksuota</option>
                </select>
              </div>
              <div
                className={styles.inputsInline}
                style={{
                  marginRight: '0rem',
                  marginLeft: '2rem',
                  width: '170px',
                }}
              >
                {interestTypeId != '2' && (
                  <div
                    className={styles.inputsInline}
                    style={{ width: '170px' }}
                  >
                    <label
                      style={{ marginTop: '33px', width: '110%' }}
                      className="inlineDatepickerInputTitle"
                    >
                      Palūkanų normos perskaičiavimo dažnumas
                    </label>
                    <select
                      disabled={false}
                      name="euriborPeriodId"
                      onChange={onEuriborPeriodIdChange}
                      value={euriborPeriodId}
                    >
                      <option value={'1'}>1 mėn.</option>
                      <option value={'2'}>3 mėn.</option>
                      <option value={'3'}>6 mėn.</option>
                      <option value={'4'}>12 mėn.</option>
                    </select>
                  </div>
                )}
              </div>
              {interestTypeId == '1' && (
                <div
                  className={styles.inputsInline}
                  style={{ marginRight: '', marginLeft: '2rem' }}
                >
                  <label
                    style={{ marginTop: '33px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Pirma palūkanų keitimo diena
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '20px' }}
                    value={
                      firstInterestChangeDate
                        ? new Date(firstInterestChangeDate)
                        : firstInterestChangeDate2
                        ? new Date(firstInterestChangeDate2)
                        : ''
                    }
                    selected={
                      firstInterestChangeDate
                        ? new Date(firstInterestChangeDate)
                        : firstInterestChangeDate2
                        ? new Date(firstInterestChangeDate2)
                        : ''
                    }
                    onChange={(e) => setFirstInterestChangeDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={true}
                  />
                </div>
              )}
              {interestTypeId == '3' && (
                <div
                  className={styles.inputsInline}
                  style={{ marginRight: '', marginLeft: '2rem' }}
                >
                  <label
                    style={{ marginTop: '33px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Pirma palūkanų keitimo diena
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '20px' }}
                    value={
                      firstInterestChangeDate
                        ? new Date(firstInterestChangeDate)
                        : firstInterestChangeDate2
                        ? new Date(firstInterestChangeDate2)
                        : ''
                    }
                    selected={
                      firstInterestChangeDate
                        ? new Date(firstInterestChangeDate)
                        : firstInterestChangeDate2
                        ? new Date(firstInterestChangeDate2)
                        : ''
                    }
                    onChange={(e) => setFirstInterestChangeDate(e)}
                    //minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                </div>
              )}
              <div
                className={styles.inputsInline}
                style={{ marginLeft: '2rem' }}
              >
                {interestTypeId != '2' && (
                  <>
                    <label
                      style={{ marginTop: '33px', width: '110%', paddingBottom:'13px' }}
                      className="inlineDatepickerInputTitle"
                    >
                      Taikomo Euribor data
                    </label>
                    <DatePicker
                      className="inlineDatepickerInput"
                      style={{ marginLeft: '0px', padding: '20px'}}
                      value={
                        euriborChangeDate ? new Date(euriborChangeDate) : ''
                      }
                      selected={
                        euriborChangeDate ? new Date(euriborChangeDate) : ''
                      }
                      // onChange={(e) => console.log(e)}

                      onChange={(e) => setEuriborChangeDate(e)}
                      minDate={subDays(new Date(), 10)}
                      dateFormat="yyyy-MM-dd"
                      disabled={false}
                    />{' '}
                  </>
                )}
                {notValid && !euriborChangeDate && interestTypeId !== '2' && (
                  <p className={styles.error}>Neįvesta data</p>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: '2rem',
                marginLeft: '2rem',
                width: '31rem',
              }}
            >
              <InputWithTextInline
                type="string"
                value={iban}
                onChange={(e) => setIban(e.target.value)}
                placeholder={'Sąskaitos numeris'}
                disabled={false}
              />
              {notValid && !isValidIban(iban) && (
                <p
                  className={styles.error}
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  Neteisingas formatas
                </p>
              )}
            </div>
            <label
              className="containerLabel noBlock"
              style={{ marginTop: '1rem' }}
            >
              <input
                type="checkbox"
                name={'generateInvoice'}
                checked={generateInvoice}
                onChange={() => setGenerateInvoice(!generateInvoice)}
              />
              <span className="checkmark"></span>
              <span className={styles.checkboxTitle}>
                Generuoti sąskaitą faktūrą
              </span>
            </label>
            {generateInvoice && (
              <div className={styles.inputsContainer}>
                <div
                  className={styles.inputsInline}
                  style={{ marginRight: '2rem', marginLeft: '2rem' }}
                >
                  <div>
                    <InputWithTextInline
                      type="number"
                      value={invoiceFee}
                      onChange={(e) => setInvoiceFee(e.target.value)}
                      placeholder={'Sutarties pakeitimo mokestis'}
                      disabled={false}
                    />
                    {notValid && (
                      <p
                        className={styles.error}
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {isSumValid2(invoiceFee, 'privalomas laukas', 3)}
                        {/* Privalote įrašyti paskolos sumą */}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <br />
            <br />
            <br />
            <br />

            <div className={styles.addFileContainer}>
              <Button type="standart" onClick={() => selectFile('fileElem')}>
                Prisegti priedą
              </Button>
              <input
                type="file"
                id="fileElem"
                className="d-none"
                accept="application/pdf"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <p style={{ marginTop: '0.5rem' }}>
                {fileToUpload && fileToUpload.name ? fileToUpload.name : ''}
              </p>
              {notValid && !fileToUpload && (
                <p
                  className={styles.error}
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    color: '#bc2432',
                  }}
                >
                  Privalote pasirinkti priedą
                </p>
              )}
            </div>
            <br />
            <br />
            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(requestId)}>
                Išsaugoti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

export default connect()(SubmitConditionsChangeRequestModal);
