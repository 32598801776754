import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginBackOfficeModal from '../../modals/loginBackOfficeModal/loginBackOfficeModal';
import { Redirect } from 'react-router-dom';
import { config } from '../../api/config';
import { removeCookieItem } from '../../utils';

const BackofficeLogin = ({ loggedIn }) => {
  useEffect(() => {
    removeCookieItem('brzd');
  }, []);
  return (
    <>
      <div className="bg-picture-login"></div>
      {loggedIn ? <Redirect to={config.APP_PATHS.backofficeMain} /> : null}
      <LoginBackOfficeModal open={true} />
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps)(BackofficeLogin);
