import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import styles from './additionalFileUploadContainerOld.module.scss';
import {
  WithSpinner,
  Button,
  TableAdmin,
  TableRow,
  TableCell,
} from '../../components';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { actions } from './../../state/actions';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { fileTypeTranslation } from './../../utils/translation/translation';

const AdditionalFileUploadContainerOld = ({
  dispatch,
  loading,
  requestId,
  filesTableList,
  viewWindow,
  status,
  loanPurposeId,
}) => {
  const tableHeader = [
    'Priedo paskirtis',
    'Įkelto priedo pavadinimas',
    'Data',
    'Ar papildomas?',
    'Veiksmai',
  ];
  const [isLoading, setIsloading] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [additional, setAdditional] = useState(null);

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const gotoBottom = (id) => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const upload = (e) => {
    setIsloading(true);
    const file = e.target.files[0];

    if (file.size > 25 * 1048 * 1048) {
      setIsloading(false);

      swal({
        title: 'Įkeliamas priedas viršija 25mb leistiną limitą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
    } else {
      const formData = new FormData();
      formData.append('Files', file);
      formData.append('RequestId', requestId);
      formData.append('FileType', fileType);
      formData.append('Additional', additional);

      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadFiles, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.dashboard.getUploadedFileList(requestId));

            swal({
              title: 'Priedas sėkmingai įkeltas',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              let title = 'Nepavyko pateikti priedo';
              if (r?.errors?.RequestId) {
                title = r.errors.RequestId[0];
              } else if (r.errors) {
                title = r.errors['Files[0].FileName'][0];
              } else if (r.ErrorDescription) {
                title = r.ErrorDescription;
              }
              swal({
                title: title,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
          setTimeout(() => {
            gotoBottom();
          }, 2000);
        });
      //  dispatch(actions.dashboard.fileUploadAction(formData, 'upload'));
    }
  };

  /* const onFileTypeSelectorChange = ({ target: { name, value } }) => {
    setFileType(value === '-' ? null : value);
  }; */

  const onFileTypeSelectChange = ({ value }) => {
    setFileType(value === '-' ? null : value);
    setAdditional(value === '-' ? true : true);
  };

  const [fileArr, setFileArray] = useState([]);
  const [rejection, setRejection] = useState(null);

  useEffect(() => {
    if (filesTableList && filesTableList.length !== 0 && status === 10) {
      setRejection(filesTableList.slice(-1)[0]);
      setFileArray([filesTableList]);
      setFileArray([...filesTableList].slice(0, filesTableList.length - 1));
    } else {
      setFileArray(filesTableList);
    }
  }, [filesTableList, status, rejection]);

  const deleteFile = (id) => {
    setIsloading(true);
    const requestIdFileId = `${requestId}/${id}`;
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DeleteUploadedFile(requestIdFileId),
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.dashboard.getUploadedFileList(requestId));

          swal({
            title: 'Priedas sėkmingai ištrintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else {
          swal({
            title: 'Nepavyko pašalinti priedo',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log('catch block');
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
        setTimeout(() => {
          gotoBottom();
        }, 2000);
      });
  };

  const deleteConfirmation = (id) => {
    swal({
      title: 'Ar tikrai norite ištrinti šį failą?',
      icon: 'warning',
      showCancelButton: true,

      buttons: {
        confirm: {
          text: 'Taip',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
        cancel: {
          text: 'Ne',
          value: false,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true,
        },
      },
    }).then((result) => {
      if (result) {
        deleteFile(id);
      }
    });
  };

  const downloadRejectionFile = (requestId, id, fileName) => {
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadFile(requestId, id), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const acceptFiles = (fileType) => {
    if (+fileType === 1) {
      return '.pdf';
    }

    if (
      +fileType === 5 ||
      +fileType === 6 ||
      +fileType === 14 ||
      +fileType === 15
    ) {
      return ' .pdf, .jpg';
    }
    if (+fileType === 2) {
      return ' .doc, .docx, .pdf, .jpg';
    }
    if (+fileType === 9 || +fileType === 17) {
      return '.xlsx, .xls';
    }
    return '.xlsx, .xls, .doc, .docx, .xml, .txt, .pdf';
  };

  const investmentStringsArr = [
    {
      value: 1,
      explanation: '0x00B9',
      explanationValue: '1',
      explanationText:
        'Įmonės Vadovo pasirašyta SVV subjekto statuso deklaraciją (išskyrus atvejį, kai Paskolos gavėjas patvirtina, kad yra didelė įmonė);',
    },
    {
      value: 2,
      explanation: '0x00B2',
      explanationValue: '2',
      explanationText:
        'Paskolos gavėją apibūdinančius dokumentus: registracijos dokumentus, nuostatus, vadovo asmens tapatybės dokumento kopijas, informaciją apie akcininkus (savininkus, naudos gavėjus), aktualią Investicinės Paskolos gavėjo akcininkų struktūrą ir laisvos formos dokumentą, kuriame būtų nurodyti Verslo subjekto ir su juo susijusių įmonių ryšiai (netaikoma verslininkams);',
    },
    {
      value: 3,
      explanation: '0x00B3',
      explanationValue: '3',
      explanationText:
        'Asmens tapatybės dokumento kopija ir laisvos formos dokumentas, kuriame būtų nurodyti verslininko ir su juo susijusių įmonių ryšiai;',
    },
    //{
    //  value: 13,
    //  explanation: '0x2074',
    //  explanationValue: '4',
    //  explanationText:
    //    'Verslo planas, kuriame turi būti aprašytas vykdomas verslas, esama ir prognozuojama finansinė būklė, siūlymas dėl Investicinės Paskolos įmokų mokėjimo grafiko išdėstymo ir siūlymą dėl užtikrinimo priemonių. Taip pat turi būti pateiktos finansinės veiklos prognozės (skaičiavimai), atspindintys Investicinės Paskolos gavėjo galimybę laiku mokėti įmokas pagal visus esamus ir būsimus Investicinės Paskolos gavėjo finansinius įsipareigojimus visam Investicinės Paskolos terminui;',
    //},
    {
      value: 9,
    },
    //{
    //  value: 14,
    //  explanation: '0x2075',
    //  explanationValue: '5',
    //  explanationText:
    //    'Jei Investicinės Paskolos kreipiasi verslininkas, jis gali pateikti lygiaverčius dokumentus;',
    //},
   // {
   //   value: 15,
   // },
    //{
     // explanation: '0x2076',
     // explanationValue: '6',
    //  explanationText:
    //    'Jeigu dėl Investicinės Paskolos kreipiasi verslininkas, jis turi pateikti finansinius dokumentus už 2019 m. ir 2020 m. Reikalavimas pateikti finansinius dokumentus už 2019 m. netaikomas verslininkams veikiantiems nuo 2020 m. sausio 1 d.;',
    //  value: 12,
    //},
    
    {
      value: 8,
      explanation: '0x2074',
      explanationValue: '4',
      explanationText:
        'Verslo subjektas teikdamas Paraišką ne pirmą kartą (t.y., kai ankstesnė (-s) Paraiška (-os) buvo atmesta (-os)), turi pateikti motyvuotą paaiškinimą dėl įvykusių pokyčių Verslo subjekto veikloje ir teikiamuose su Paraiška dokumentuose (duomenyse) bei pateikti paaiškinimą pagrindžiančius dokumentus.',
    },
    //{
    //  value: 16,
     // explanation: '0x2077',
    //  explanationValue: '7',
    //  explanationText:
    //    'Principo "nedaryti reikšmingos žalos" klausimyną investiciniam projektui, kuris atitinka „nedaryti reikšmingos žalos“ principą, kaip apibrėžta 2020 m. birželio 18 d. Europos Parlamento ir Tarybos reglamento (ES) Nr. 2020/852 dėl sistemos tvariam investavimui palengvinti sukūrimo, kuriuo iš dalies keičiamas Reglamentas (ES) 2019/2088 17 straipsnyje, atsižvelgiant į 2021 m. vasario 18 d. Europos Komisijos pranešimu Nr. 2021/C 58/01 patvirtintas Reikšmingos žalos nedarymo principo taikymo pagal Ekonomikos gaivinimo ir atsparumo didinimo priemonės reglamentą technines gaires.',
    //},
    {
      value: 11,
    },
    {
      value: 10,
    },
  ];

  const workingCapitalLoanArr = [
    {
      value: 1,
      explanation: '0x00B9',
      explanationValue: '1',
      explanationText:
        'Įmonės Vadovo pasirašyta SVV subjekto statuso deklaraciją (išskyrus atvejį, kai Paskolos gavėjas patvirtina, kad yra didelė įmonė);',
    },
    {
      value: 2,
      explanation: '0x00B2',
      explanationValue: '2',
      explanationText:
        'Paskolos gavėją apibūdinančius dokumentus: registracijos dokumentus, nuostatus, vadovo asmens tapatybės dokumento kopijas, informaciją apie akcininkus (savininkus, naudos gavėjus), aktualią Apyvartinės Paskolos gavėjo akcininkų struktūrą ir laisvos formos dokumentą, kuriame būtų nurodyti Verslo subjekto ir su juo susijusių įmonių ryšiai (netaikoma verslininkams);',
    },
    {
      value: 3,
      explanation: '0x00B3',
      explanationValue: '3',
      explanationText:
        'Asmens tapatybės dokumento kopiją ir laisvos formos dokumentą, kuriame būtų nurodyti verslininko ir su juo susijusių įmonių ryšiai;',
    },
    {
      value: 4,
      explanation: '0x2074',
      explanationValue: '4',
      explanationText:
        'Verslo planą (Aprašo priedas Nr. 2), kuriame trumpai aprašomas vykdomas verslas, nurodoma esama ir prognozuojama finansinė būklė (skaičiavimai), atspindintys Verslo subjekto galimybę laiku mokėti įmokas pagal visus esamus ir būsimus Verslo subjekto finansinius įsipareigojimus visam Apyvartinės Paskolos terminui, siūlymą dėl Apyvartinės Paskolos užtikrinimo priemonių (jei taikoma);',
    },
    {
      value: 5,
      explanation: '0x2075',
      explanationValue: '5',
      explanationText:
        'Jeigu Verslininkai veiklą vykdo arba įmonės veikia trumpiau nei vienerius metus, gali pateikti lygiaverčius dokumentus;',
    },
    {
      value: 6,
    },
    {
      value: 7,
      explanation: '0x2076',
      explanationValue: '6',
      explanationText:
        'Reikalavimas pateikti finansinius dokumentus už 2019 m. netaikomas verslininkams veikiantiems nuo 2020 m. sausio 1 d. Reikalavimas pateikti finansinius dokumentus už 2020 m. netaikomas verslininkams veikiantiems nuo 2021 m. sausio 1 d.;',
    },
    //{
     // value: 8,
     // explanation: '0x2077',
     // explanationValue: '7',
     // explanationText:
     //   'Verslo subjektas teikdamas Paraišką ne pirmą kartą (t.y., kai ankstesnė (-s) Paraiška (-os) buvo atmesta (-os)), turi pateikti motyvuotą paaiškinimą dėl įvykusių pokyčių Verslo subjekto veikloje ir teikiamuose su Paraiška dokumentuose (duomenyse) bei pateikti paaiškinimą pagrindžiančius dokumentus.',
    //},
    //{
    //  value: 16,
     // explanation: '0x2077',
    //  explanationValue: '7',
    //  explanationText:
    //    'Principo "nedaryti reikšmingos žalos" klausimyną investiciniam projektui, kuris atitinka „nedaryti reikšmingos žalos“ principą, kaip apibrėžta 2020 m. birželio 18 d. Europos Parlamento ir Tarybos reglamento (ES) Nr. 2020/852 dėl sistemos tvariam investavimui palengvinti sukūrimo, kuriuo iš dalies keičiamas Reglamentas (ES) 2019/2088 17 straipsnyje, atsižvelgiant į 2021 m. vasario 18 d. Europos Komisijos pranešimu Nr. 2021/C 58/01 patvirtintas Reikšmingos žalos nedarymo principo taikymo pagal Ekonomikos gaivinimo ir atsparumo didinimo priemonės reglamentą technines gaires.',
   // },
    //{
    //  value: 17,
    //},
    {
      value: 11,
    },
    {
      value: 10,
    },
  ];

  const loanDocumentsByType = (loanDocumentsTypes) => {
    console.log(loanDocumentsTypes);
    return (
      <div className={styles.container}>
        {loanDocumentsTypes ? (
          <>
            <div style={{ display: 'flex', width: '100%' }}>
              <div className={styles.selectflex} style={{ width: '100%' }}>
                <label>Prisegamo priedo paskirtis</label>
                <div
                  className="containera w-100"
                  style={{ width: '100%', fontSize: '14px' }}
                >
                  <Select
                    placeholder="Prisegamo priedo paskirtis"
                    options={loanDocumentsTypes?.map(
                      ({ explanation, value }) => {
                        return {
                          value: value,
                          label: `${fileTypeTranslation(value)}${
                            explanation ? String.fromCharCode(explanation) : ''
                          }`,
                        };
                      }
                    )}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: '1px solid black',
                        // This line disable the blue border
                        boxShadow: state.isFocused ? '1px' : '3px',
                        '&:hover': {
                          border: '1px solid black',
                        },
                      }),
                    }}
                    onChange={onFileTypeSelectChange}
                  />
                  {/* <select
                    disabled={viewWindow}
                    name="InvoiceType"
                    style={{
                      background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                      height: '44px',
                      textOverflow: 'ellipsis',
                    }}
                    className="form-select h-100 w-100"
                    id="scrollToFromTop"
                    onChange={onFileTypeSelectorChange}
                  >
                    <option selected={fileType == null} value={null}>
                      -
                    </option>
                    {loanDocumentsTypes?.map(
                      ({ text, explanation, value }, index) => (
                        <option
                          key={index}
                          selected={fileType == value}
                          value={value}
                        >
                          {`${text}${
                            explanation ? String.fromCharCode(explanation) : ''
                          }`}
                        </option>
                      )
                    )}
                  </select> */}
                </div>
              </div>
              <span
                className={styles.disabled}
                style={{
                  width: '155px',
                  marginTop: '-5px',
                  marginLeft: '10px',
                }}
              >
                <span className={styles.textUptheButton}>
                  Pasirinkti priedą
                </span>

                <button
                  className={
                    fileType
                      ? 'btn ui-button btn-dark-blue-standart'
                      : 'btn ui-button btn-dark-blue-disabled'
                  }
                  type="longBtn"
                  onClick={() => selectFile('fileElem')}
                  disabled={!fileType}
                  style={{ marginTop: '9px', padding: '9px' }}
                >
                  PASIRINKTI PRIEDĄ
                </button>
                <input
                  type="file"
                  id="fileElem"
                  accept={acceptFiles(fileType)}
                  className="d-none"
                  onChange={upload}
                />
              </span>
            </div>

            <p className={styles.note}>
              Prisekite reikiamas rinkmenas. <br />
              Galimi priedų formatai: .xlsx, .xls, .doc, .docx, .xml, .txt,
              .pdf, .jpg. Maksimalus dydis 20MB. <br />
              <p>
                Teikiami dokumentai ir duomenys turi būti aktualūs, pvz. ne
                senesnis kaip 1 mėn. VĮ Registrų centro išrašas.{' '}
              </p>
              <br />
              <br />
              <p>
                <b>Priedų formos atsisiuntimui:</b>
              </p>
              -{' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq296"
                target="_blank"
                rel="noreferrer noopener"
              >
                Įmonės vadovo pasirašyta SVV deklaracija
              </a>
              *;
              <br />-{' '}
              <a
                href="https://invega.lt/duk/11/deklaracijos-2#!faq290"
                target="_blank"
                rel="noreferrer noopener"
              >
                Įmonės vadovo pasirašyta Vienos įmonės deklaracija
              </a>
             ;
             <br />-{' '} 
                  <a
                href="https://invega.lt/lt/doclib/qjgabty4fze4se22wwbvd4myc9mq3hg3"
                target="_blank"
                rel="noreferrer noopener"
              >
                  Paskolos gavėjo veiklos (finansinių) duomenų
                detalizacijos forma
                </a>
                <br />- Paskolos gavėjo atitikimo žemės ūkio inovacinei veiklai
                aprašymas (
                  <a
                href="https://invega.lt/lt/doclib/ox6ppkhbw4hccq5euuaz19wexjvd8hzq"
                target="_blank"
                rel="noreferrer noopener"
              >
                  Priedas Nr. 1
                </a>
                ).
              <br /> <br />
              {/*<p>
                <b>Galimi pateikti priedai yra šie:</b>
                <br />
                {loanDocumentsTypes.map(({ explanation, value }, index) => (
                  <React.Fragment key={index}>
                    {`- ${fileTypeTranslation(value)}${
                      explanation ? String.fromCharCode(explanation) : ''
                    }`}
                    <br />
                  </React.Fragment>
                ))}
                  </p>*/}
              <br />
              <b>Paaiškinimai:</b> <br />
              {loanDocumentsTypes
                .filter((item) => item?.explanationValue)
                .map(({ explanationValue, explanationText }, index) => (
                  <React.Fragment key={index}>
                    {`${explanationValue} - ${explanationText}`}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </>
        ) : (
          <p>Pasirinkite paskolos paskirtį prieš keldami dokumentus</p>
        )}
      </div>
    );
  };

  return (
    <WithSpinner loading={isLoading}>
      <div className={styles.container}>
        <h4>Papildomų priedų įkėlimas</h4>

        <>
          {loanDocumentsByType(
            loanPurposeId == '1'
              ? investmentStringsArr
              : loanPurposeId == '2'
              ? workingCapitalLoanArr
              : null
          )}
        </>
      </div>
      {rejection && (
        <div className={styles.container}>
          <h4> Sutarties nutraukimo dokumentas </h4>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              downloadRejectionFile(requestId, rejection.Id, rejection.FileName)
            }
          >
            {rejection.FileName}
          </p>
        </div>
      )}
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  filesTableList: state.dashboardState.filesTableList,
});

export default connect(mapStateToProps)(AdditionalFileUploadContainerOld);
