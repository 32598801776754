import React, { useState } from 'react';
import styles from './importFilesModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { connect } from 'react-redux';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';

const ImportFilesModal = ({ open, dispatch }) => {
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    const open = {
      modalName: 'importFilesModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
    setFileType('');
    setFile(null);
  };

  const upload = () => {
    if (!file) {
      swal({
        title: 'Pasirinkite priedą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
      return;
    }
    if (fileType === '') {
      swal({
        title: 'Pasirinkite tipą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('File', file);
    formData.append('FileType', fileType);

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.importFiles, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          setLoading(false);
          setFileType('');
          setFile(null);
          const open = {
            modalName: 'importFilesModal',
            visible: false,
          };
          dispatch(actions.modals.setModalVisibleFlagAction(open));
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          setLoading(false);
          res.json().then((r) => {
            if (r.ErrorDescription) {
              swal({
                title: r.ErrorDescription,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn btn-pink',
                    closeModal: true,
                  },
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setIsloading(false);
      });
  };

  const setUploadFile = (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file && file.size > 20 * 1048 * 1048) {
      swal({
        title: 'Įkeliamas priedas viršija 20mb leistiną limitą',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
      return;
    }

    setFile(file);
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '300px' }}
        >
          <div className={styles.contentBlock}>
            <h4 className={styles.title}>Duomenų iš priedų įkėlimas</h4>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className={styles.selectflex}>
                  <label
                    className="custom-file-upload"
                    style={{ width: '21rem' }}
                  >
                    Pasirinkite priedą
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      id="fileElem"
                      accept=".xlsx, .csv"
                      onChange={setUploadFile}
                      name="file"
                      className="inputfile"
                    />
                  </label>

                  <p style={{ marginTop: '5px', marginBottom: '1rem' }}>
                    {file && file.name ? file.name : null}
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <div className={styles.selectflex}>
                  <label>
                    <select
                      onChange={(e) => setFileType(e.target.value)}
                      className={styles.selectInput}
                    >
                      <option value="">Pasirinkite tipą</option>

                      <option value="LongTermDebtors">
                        VMI nepatikimi mokėtojai
                      </option>
                      {/*<option value="1">Sodros darbuotojų skaičius</option>*/}
                      <option value="2">EVRK blacklist</option>
                      <option value="3">Rizikingų šalių pagal AML/PEP sąrašas</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <WithSpinner loading={loading}>
              <></>
            </WithSpinner>
            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => upload()}>
                Įkelti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ImportFilesModal);
