import { dashboardConstants } from './dashboard/DashboardConstants';
import { modalsConstants } from './modals/ModalsConstants';
import { authConstants } from './auth/auth.constants';
import { backofficeConstants } from './backoffice/BackofficeConstants';

export const constants = {
  dashboard: dashboardConstants,
  modals: modalsConstants,
  auth: authConstants,
  backoffice: backofficeConstants,
};
