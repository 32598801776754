import React, { useEffect } from 'react';
import styles from './header.module.scss';
import { actions } from '../../state';
import { connect } from 'react-redux';
import Logo from '../../images/logo.png';
import { formatNumber } from '../../utils/helpers/helpers';
import { Link } from 'react-router-dom';
import { config } from './../../api/config';
import { isProd } from '../../api/config';
import { checkBOPage } from './../../utils/helpers/helpers';
import { removeLocalStorageItem } from '../../utils';

const Header = ({ dispatch, generalData, isRepresentative }) => {
  const logout = () => {
    dispatch(actions.auth.clearUserAction());
  };

  const goToMainWindow = () => {
    removeLocalStorageItem('boFilter');
    window.location.href = isProd()
      ? checkBOPage()
        ? 'https://paslaugos.invega.lt/backoffice/main'
        : 'https://paslaugos.invega.lt/platform'
      : checkBOPage()
      ? 'https://barzda.scorify.lt/backoffice/main'
      : 'https://barzda.scorify.lt/platform';
  };

  useEffect(() => {
    dispatch(actions.dashboard.loadGeneralDataAction());
  }, []);

  const returnReservedValueById = (id) => {
    if (id && generalData) {
      console.log(generalData);
      const filteredValue = generalData?.ReservedSums?.find(
        (val) => val.ProjectRegionId === id
      );

      return filteredValue?.ReservedSum || '';
    }
  };

  const returnTotalValueById = (id) => {
    if (id && generalData) {
      const filteredValue = generalData?.ReservedSums?.find(
        (val) => val.ProjectRegionId === id
      );
      return filteredValue?.MaxSum || '';
    }
  };

  return (
    <div className={styles.container}>
      <div className="container">
        <div className="row header-flext-align">
          <div className="col-lg-8 col-sm-12">
            <a href={config.APP_PATHS.requests} className="logo-menu">
              <img src={Logo} alt="logo" />
            </a>

            <Link to={'/about'} className="menu-align-all">
              Apie projektą
            </Link>{' '}
            <a
                href="https://invega.lt/verslui/visos-priemones/25/startuok-90"
                target="_blank"
                rel="noreferrer noopener"  className="menu-align-all"
              >Priemonės aprašymas</a>
            {/*<Link to={'/description'}>
              Priemonės aprašymas
  </Link>*/}
            {generalData && (
              // <span className="menu-align-all">
              //   <span className=""> Pasirašytų sutarčių suma: </span>
              //   <span className="bold">
              //     {' '}
              //     {formatNumber(generalData.ReservedSum)} EUR{' '}
              //   </span>
              //   iš
              //   <span className="bold">
              //     {' '}
              //     {formatNumber(generalData.TotalSum)} EUR{' '}
              //   </span>
              //   <br />
              //   <span className=""> Pasirašytų sutarčių suma: </span>
              //   <span className="bold">
              //     {' '}
              //     {formatNumber(generalData.ReservedSum)} EUR{' '}
              //   </span>
              //   iš
              //   <span className="bold">
              //     {' '}
              //     {formatNumber(generalData.TotalSum)} EUR{' '}
              //   </span>
              // </span>

              <div>
                <span className="menu-align-all sums-count-top">
                  <span className="">Pasirašytų sutarčių suma:</span>{' '}
                  <span className="bold">
                    {' '}
                    {formatNumber(generalData.IssuedSum)} EUR{' '}
                  </span>
                  iš
                  <span className="bold">
                    {' '}
                    {formatNumber(generalData.TotalSum)} EUR{' '}
                  </span>
                </span>
                <br />
               <span className="menu-align-all  sums-count-top">
                  <span className="">
                    {' '}
                    Rezervuota priemonės lėšų suma (Vidurio vakarų Lietuvos regionas):{' '}
                  </span>
                  <span className="bold">
                    {' '}
                    {formatNumber(returnReservedValueById(1))} EUR{' '}
                  </span>
                  iš
                  <span className="bold">
                    {' '}
                    {formatNumber(returnTotalValueById(1))} EUR{' '}
                  </span>
            </span>
                <span className="menu-align-all  sums-count-top">
                  <span className="">
                    {' '}
                    Rezervuota priemonės lėšų suma (Sostinės regionas ir visa Lietuva):{' '}
                    {/*Rezervuota priemonės lėšų suma:*/}
                  </span>
                  <span className="bold">
                    {' '}
                    {formatNumber(returnReservedValueById(2))} EUR{' '}
                  </span>
                  iš
                  <span className="bold">
                    {' '}
                    {formatNumber(returnTotalValueById(2))} EUR{' '}
                  </span>
                </span>
                {/*
                Startuok1 buvo taip: 
                <span className="menu-align-all  sums-count-top">
                  <span className="">
                    {' '}
                    Rezervuota pagal pateiktas paraiškas priemonės lėšų suma:{' '}
                  </span>
                  <span className="bold">
                    {' '}
                    {formatNumber(generalData.ReservedSum)} EUR{' '}
                  </span>
                  iš
                  <span className="bold">
                    {' '}
                    {formatNumber(generalData.TotalSum)} EUR{' '}
                  </span>
            </span>*/}
              </div>
            )}
          </div>
          <div
            className="col-lg-4 col-sm-12 header-align-right"
            style={{ display: 'flex' }}
          >
            <div
              className={styles.headerButtonsContainer}
              style={{ marginRight: '15px' }}
            >
              <button
                className="btn btn-cap logout"
                style={{
                  width: '180px',
                  backgroundColor: 'rgb(0, 159, 227)',
                  border: 'rgb(0, 159, 227)',
                }}
                onClick={() => goToMainWindow()}
              >
                <span className="btn-text">
                  <span className="btn-icon-text">
                    <span>Priemonių sąrašas</span>
                  </span>
                </span>
              </button>
            </div>
            <div className={styles.headerButtonsContainer}>
              <button className="btn btn-cap logout" onClick={() => logout()}>
                <span className="btn-text">
                  <span className="btn-icon-text">
                    <span>Atsijungti</span>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  generalData: state.dashboardState.generalData,
  isRepresentative: state.dashboardState.isRepresentative,
});

export default connect(mapStateToProps)(Header);
