export const modalsConstants = {
  SET_MODAL_VISIBLE_FLAG: 'modals/SET_MODAL_VISIBLE_FLAG',
  SET_PDF_DOWNLOAD_COMFIRM: 'modals/SET_PDF_DOWNLOAD_COMFIRM',
  SET_ON_SYNC_FLAG: 'modals/SET_ON_SYNC_FLAG',
  SET_ERROR_MESSAGE: 'modals/SET_ERROR_MESSAGE',
  SET_MODAL_CLOSED_OK_MESSAGE: 'modals/SET_MODAL_CLOSED_OK_MESSAGE',
  SET_MODAL_CONFIRM_ACTION: 'modals/SET_MODAL_CONFIRM_ACTION',
  SET_PAYMENT_MODAL_CONFIRM_ACTION: 'modals/SET_PAYMENT_MODAL_CONFIRM_ACTION',
  SET_VALIDATION_ERROR: 'modals/SET_VALIDATION_ERROR',
  CONFIRM_INVOICES_FROM_MANAGER: 'modals/CONFIRM_INVOICES_FROM_MANAGER',
  REGENERATE_DECISION_DOC: 'modals/REGENERATE_DECISION_DOC',
};
