import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header, Submenu, Footer } from './components';

import Guest from './layout/Guest/Guest';
import BackofficeLogin from './layout/BackOfficeGuest/BackOfficeGuest';
import PermissionDenied from './pages/permissionDenied/permissionDenied';
import About from './pages/about/about';
import Description from './pages/description/description';

import { config } from './api/config';
import PrivateRoute from './utils/auth/privateRoute';
import { getCookieValue } from './utils/auth/localStorage';
const Routes = ({ login }) => {
  // const token = window.localStorage.getItem('adminToken');
  const path = window.location?.pathname;
  const path2 = path.substring(0, path.lastIndexOf('/'));

  const token =
    window.localStorage.getItem('userToken') || getCookieValue('brzd');
  const adminToken = window.localStorage.getItem('token');
  const loggedIn = true
    ? path === '/' || path === '/login' || path === '/backofficelogin'
      ? false
      : true
    : false;
  const adminLoggedIn =
    adminToken != null
      ? path === '/' || path === '/backofficelogin' || path === '/login'
        ? false
        : true
      : false;

  const showSubmenu =
    path2 === '/main-window' ||
    path2 === '/main-window/requests' ||
    path2 === '/main-window/projects'
      ? true
      : false;

  return (
    <Router>
      <div className="wrapper" id="site-default">
        {(loggedIn || adminToken) && (
          <>
            <Header />
            {showSubmenu && <Submenu />}
          </>
        )}
        <Switch>
          <Route path={config.APP_PATHS.login} component={Guest} />
          <Route
            path={config.APP_PATHS.backoffice}
            component={BackofficeLogin}
          />
          <Route
            path={config.APP_PATHS.permisssionDenied}
            component={PermissionDenied}
          />
          <Route
            path={config.APP_PATHS.permisssionDenied1}
            component={PermissionDenied}
          />
          <Route path={config.APP_PATHS.about} component={About} />
          <Route path={config.APP_PATHS.description} component={Description} />
          <PrivateRoute />
        </Switch>
      </div>

      {/* {loggedIn && <Footer />} */}
    </Router>
  );
};

const mapStateToProps = (state) => ({
  login: state.auth.loggedIn,
  isOnSync: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(Routes);
