export const backofficeConstants = {
  LOAD_REQUESTS_LIST: 'backoffice/LOAD_REQUESTS_LIST',
  SET_REQUESTS_LIST: 'backoffice/SET_REQUESTS_LIST',
  GET_REQUEST: 'backoffice/GET_REQUEST',
  GET_PAYMENT_REQUEST: 'backoffice/GET_PAYMENT_REQUEST',
  SET_REQUEST: 'backoffice/SET_REQUEST',
  SET_REQUEST_STATUS: 'backoffice/SET_REQUEST_STATUS',
  SET_PAYMENT_REQUEST_STATUS: 'backoffice/SET_PAYMENT_REQUEST_STATUS',
  LOAD_INVOICE_LIST_DATA: 'backoffice/LOAD_INVOICE_LIST_DATA',
  SET_INVOICE_LIST_DATA: 'backoffice/SET_INVOICE_LIST_DATA',
  LOAD_INVOICE_DATA: 'backoffice/LOAD_INVOICE_DATA',
  SET_INVOICE_DATA: 'backoffice/SET_INVOICE_DATA',
  EXPORT_DATA_ACTION: 'backoffice/EXPORT_DATA_ACTION',
  GET_UPLOADED_FILES_LIST: 'backoffice/GET_UPLOADED_FILES_LIST',
  SET_UPLOADED_FILES_LIST: 'backoffice/SET_UPLOADED_FILES_LIST',
  GET_STATS: 'backoffice/GET_STATS',
  SET_STATS: 'backoffice/SET_STATS',
  DOWNLOAD_CONTRACT_DOC: 'backoffice/DOWNLOAD_CONTRACT_DOC',
  GET_SIGN_IN_DOC: 'backoffice/GET_SIGN_IN_DOC',
  LOAD_REPRESENTATIVES: 'backoffice/LOAD_REPRESENTATIVES',
  SET_REPRESENTATIVES: 'backoffice/SET_REPRESENTATIVES',
  SET_REPRESENTATIVE: 'backoffice/SET_REPRESENTATIVE',
  SAVE_REPRESENTATIVE: 'backoffice/SAVE_REPRESENTATIVE',
  SAVE_REPRESENTATIVE_ERROR: 'backoffice/SAVE_REPRESENTATIVE_ERROR',
  SAVE_EDIT_ADDITIONAL_COMMENTS: 'backoffice/SAVE_EDIT_ADDITIONAL_COMMENTS',
  SAVE_RISK_ASSESMENT_RESULT: 'backoffice/SAVE_RISK_ASSESMENT_RESULT',
  GET_ADDITIONAL_COMMENTS: 'backoffice/GET_ADDITIONAL_COMMENTS',
  GET_REQUEST_LOG: 'backoffice/GET_REQUEST_LOG',
  SET_REQUEST_LOG: 'backoffice/SET_REQUEST_LOG',
  GET_INVEGA_USERS: 'backoffice/GET_INVEGA_USERS',
  SET_INVEGA_USERS: 'backoffice/SET_INVEGA_USERS',
  CALL_SAGA_FOR_ERR_HANDLE: 'backoffice/CALL_SAGA_FOR_ERR_HANDLE',
  SET_ASSESSORS: 'backoffice/SET_ASSESSORS',
  SET_RELATED_REQUESTS: 'backoffice/SET_RELATED_REQUESTS',
  DOWNLOAD_LIST_ACTION: 'backoffice/DOWNLOAD_LIST_ACTION',
  DOWNLOAD_ALL_PR_FILES: 'backoffice/DOWNLOAD_ALL_PR_FILES',
  SET_REPRESENTAVITES_MANAGER_BY_REQUEST:
    'backoffice/SET_REPRESENTAVITES_MANAGER_BY_REQUEST',
};
