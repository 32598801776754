import React, { useEffect, useState } from 'react';
import { actions } from './../../state/actions';
import { connect } from 'react-redux';
import { WithSpinner, Header } from '../../components';
import { InputWithTitle } from '../../components';
import styles from './main.module.scss';
import { config } from '../../api/config';
import { Redirect } from 'react-router-dom';
import { validateEmail, validatePhone } from './../../utils/auth/validations';
import { isEnabled } from '../../utils';

const Main = ({
  dispatch,
  loading,
  isAgreed,
  consentData,
  firstStepPassed,
  loginType,
}) => {
  // const [notValidRegistration, setNotValidRegistration] = useState(false);
  const [values, setValues] = useState({
    name: '',
    surname: '',
    company: '',
    code: '',
    email: '',
    phone: '',
    checkbox: false,
  });
  const [notValid, setNotvalid] = useState(false);
  const [agree, setAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [agree4, setAgree4] = useState(false);
  const [agree5, setAgree5] = useState(false);
  const [isCompany, setIsCompany] = useState(null);

  const toggleAgree = () => {
    if (!agree === true) {
      setCheckBoxErr(false);
    }
    setAgree(!agree);
  };
  const [checkboxErr, setCheckBoxErr] = useState(false);

  const onInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const confirm = () => {
    const data = {
      email: values.email,
      phone: values.phone,
      checkbox: agree,
    };
    setNotvalid(true);
    if (agree && agree1 && agree2 && agree3 && agree4 && agree5) {
      if (
        validateEmail(values.email) === null &&
        validatePhone(values.phone) === null
      ) {
        dispatch(actions.dashboard.passCompanyInfoAction(data));
      }
    } else {
      setCheckBoxErr(true);
    }
  };

  useEffect(() => {
    dispatch(actions.dashboard.getIsConsentAgreedAction());
  }, []);

  useEffect(() => {
    if (loginType === 'Individual' || loginType === 'Company')
      setIsCompany(loginType === 'Individual' ? false : true);
  }, [loginType]);

  useEffect(() => {
    if (
      consentData != null &&
      consentData.UserAuthData != null &&
      consentData.UserAuthData.FirstName != null
    ) {
      const data = consentData.UserAuthData;
      setValues({
        name: data.FirstName,
        surname: data.LastName,
        company: data.CompanyName,
        code: data.CompanyCode,
      });
    }
  }, [consentData]);

  return (
    <WithSpinner loading={loading}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.credentialsContainer}>
              <h1>Sutikimo patvirtinimas</h1>
              <div className="row imputs-content-main-window">
                <div className="col-lg-6 col-sm-12">
                  <InputWithTitle
                    name="name"
                    type="string"
                    onChange={onInputChange}
                    value={values.name}
                    placeholder="Vardas"
                    disabled={true}
                  />

                  {isCompany === true && (
                    <InputWithTitle
                      name="company"
                      type="string"
                      onChange={onInputChange}
                      value={values.company}
                      placeholder="Atstovaujamos įmonės pavadinimas"
                      disabled={true}
                    />
                  )}

                  <InputWithTitle
                    name="email"
                    type="string"
                    onChange={onInputChange}
                    value={values.email}
                    placeholder="Elektroninio pašto adresas"
                  />
                  {notValid && (
                    <p className={styles.errorsBlock}>
                      {validateEmail(values.email)}
                    </p>
                  )}
                </div>
                <div className="col-lg-6 col-sm-12">
                  <InputWithTitle
                    name="surname"
                    type="string"
                    onChange={onInputChange}
                    value={values.surname}
                    placeholder="Pavardė"
                    disabled={true}
                  />
                  {isCompany === true && (
                    <InputWithTitle
                      name="code"
                      type="string"
                      onChange={onInputChange}
                      value={values.code}
                      placeholder="Atstovaujamos įmonės kodas"
                      disabled={true}
                    />
                  )}

                  <InputWithTitle
                    name="phone"
                    type="string"
                    onChange={onInputChange}
                    value={values.phone}
                    placeholder="Telefono numeris"
                  />
                  {notValid && (
                    <p className={styles.errorsBlock}>
                      {validatePhone(values.phone, 'telefono numeris')}
                    </p>
                  )}
                </div>
              </div>

              <div className="checkbox">
                <h4 style={{ textAlign: 'center' }}>
                 	SUTIKIMAS DĖL ASMENS DUOMENŲ TVARKYMO
                </h4>
                <div className="checkbox">
                    <label className="containerLabel noBlock">
                      <p>
                      Man yra žinoma , jog UŽDAROJI AKCINĖ BENDROVĖ
                    „INVESTICIJŲ IR VERSLO GARANTIJOS“ (toliau – INVEGA, Duomenų
                    valdytojas), juridinio asmens kodas 110084026, buveinės
                    adresas – Konstitucijos pr. 7, LT 09308 Vilnius, tel. (8 5)
                    210 7510, el. paštas{' '}
                    <a href="mailto:info@invega.lt ">info@invega.lt</a>,
                    (duomenų apsaugos pareigūno el. paštas{' '}
                    <a href="mailto:duomenu.apsauga@invega.lt">
                      duomenu.apsauga@invega.lt
                    </a>
                     ), įgyvendindama finansinę priemonę „STARTUOK“ (toliau – Priemonė), tvarkys ir
                    saugos su Priemonės įgyvendinimu susijusius paraiškoje ir
                    jos prieduose pateiktus asmens duomenis bei teisės aktų
                    nustatytais atvejais ir tvarka gautus duomenis ir
                    informaciją iš valstybės registrų ir informacinių sistemų,
                    viešų ir privačių duomenų bazių bei viešai prieinamų
                    šaltinių.
                      </p>
                      {checkboxErr && (
                    <p className="errors-block-message">
                      Privalote patvirtinti sutikimą
                    </p>
                  )}
                     <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    checked={agree}
                    onClick={toggleAgree}
                  ></input>
                  <span className="checkmark"></span>
                    </label>
                </div>
                <div className="checkbox">
                    <label className="containerLabel noBlock">
                      <p>
                      Man žinoma, kad asmens duomenys INVEGOJE tvarkomi vadovaujantis informacija pateikta: „Privatumo pranešimas duomenų subjektams apie asmens duomenų tvarkymą įgyvendinant finansinę priemonę „STARTUOK“ ir yra viešai skelbiamas Paskolos davėjo interneto svetainėje
                    {' '}
                    <a
                      href="https://invega.lt/doclib/u2y0afthkbcxpv9tfkrn8j9a9ngpv71j"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                    https://invega.lt/doclib/u2y0afthkbcxpv9tfkrn8j9a9ngpv71j
                    </a>{' '}.
                      </p>
                      {notValid && !agree5 && (
                        <p className="errors-block-message">
                          Privalote patvirtinti sutikimą
                        </p>
                      )}
                      <input
                        type="checkbox"
                        id="scales"
                        name="scales"
                        checked={agree5}
                        onClick={() => setAgree5(!agree5)}
                      ></input>
                      <span className="checkmark"></span>
                    </label>
                </div>
                <label className="containerLabel noBlock">             
                  <p>
                    <b>SVARBU!</b>
                  </p>
                </label>
              </div>
              <div className="checkbox">
                <label className="containerLabel noBlock">
                  <p>
                  Patvirtinu, kad tuo atveju, kai pateikiu kitų asmenų asmens duomenis, tokių asmenų asmens duomenys yra gauti teisėtai ir man suteikta teisė atskleisti šiuos asmens duomenis INVEGAI.
                  </p>
                  {notValid && !agree1 && (
                    <p className="errors-block-message">
                      Privalote patvirtinti sutikimą
                    </p>
                  )}
                  <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    checked={agree1}
                    onClick={() => setAgree1(!agree1)}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>

              <div className="checkbox">
                <label className="containerLabel noBlock">
                  <p>
                  Patvirtinu, kad asmenims, kurių asmens duomenis pateikiu (juridinių asmenų atstovai, darbuotojai, steigėjai, akcininkai, dalyviai, savininkai, partneriai ir pan.) yra žinoma, kad jų asmens duomenis tvarko INVEGA ir šie asmenys buvo supažindinti su šiuo pranešimu bei Asmens duomenų tvarkymo INVEGOJE taisyklėmis.
                  </p>
                  {notValid && !agree2 && (
                    <p className="errors-block-message">
                      Privalote patvirtinti sutikimą
                    </p>
                  )}
                  <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    checked={agree2}
                    onClick={() => setAgree2(!agree2)}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>

              <div className="checkbox">
                <label className="containerLabel noBlock">
                  <p>Tvirtinu, kad pateikta informacija yra teisinga.</p>
                  {notValid && !agree3 && (
                    <p className="errors-block-message">
                      Privalote patvirtinti sutikimą
                    </p>
                  )}
                  <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    checked={agree3}
                    onClick={() => setAgree3(!agree3)}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="checkbox">
                <label className="containerLabel noBlock">
                  <p>
                  Sutinku, kad Paskolos gavėjo kontaktiniai duomenys būtų tvarkomi informacijos apie INVEGOS vykdomą veiklą, naujienlaiškių, apklausų ir kitokios tiesioginės rinkodaros medžiagos siuntimo tikslu.
                  </p>
                  {notValid && !agree4 && (
                    <p className="errors-block-message">
                      Privalote patvirtinti sutikimą
                    </p>
                  )}
                  <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    checked={agree4}
                    onClick={() => setAgree4(!agree4)}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>
              <button
                className="btn standart mainconfirmbtn"
                onClick={() => confirm()}
              >
                Tvirtinu, kad pateikta informacija yra teisinga
              </button>
              <br />
            </div>
            {firstStepPassed || isAgreed ? (
              <Redirect to={config.APP_PATHS.requests} />
            ) : null}
          </div>
        </div>
      </div>
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardState.dashboardData,
  loading: state.dashboardState.isOnSync,
  firstStepPassed: state.dashboardState.firstStepPassed,
  isAgreed: state.dashboardState.isAgreed,
  consentData: state.dashboardState.consentData,
  loginType: state.dashboardState.loginType,
});

export default connect(mapStateToProps)(Main);
