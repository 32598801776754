import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { Api } from './../../api/api';
import { format } from 'date-fns';
import swal from 'sweetalert';
import { TableAdmin, TableRow, TableCell } from '../../components';
import { paymentRequestLogTranslation } from '../../utils/translation/translation';

const ManagerActionLog = ({ dispatch, paymentRequestId }) => {
  const [actionsLog, setActionsLog] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Api.GetActionLogManager(paymentRequestId);
        setActionsLog(response);
      } catch (e) {
        if (e.statusCode === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else
          swal({
            title: `Klaida`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
      } finally {
        // setIsloading(false);
      }
    };
    getData();
  }, []);

  const logTableHeader = ['Data', 'Mokėjimo prašymo būsena', 'Asmuo'];

  return (
    <div
      style={{
        marginTop: '1rem',
        marginBottom: '2rem',
        marginLeft: '15px',
      }}
    >
      <h4>Veiksmų istorija</h4>
      <TableAdmin header={logTableHeader} isEmpty={false}>
        {actionsLog &&
          actionsLog.map(({ ActionLogType, ChangedBy, Created }) => (
            <TableRow>
              <TableCell>
                {format(new Date(Created), 'yyyy-MM-dd HH:mm:ss')}
              </TableCell>
              <TableCell>
                {paymentRequestLogTranslation(ActionLogType)}
              </TableCell>
              <TableCell>{ChangedBy}</TableCell>
            </TableRow>
          ))}
      </TableAdmin>
    </div>
  );
};

const mapStateToProps = (state) => ({});
export default connect(null, mapStateToProps)(ManagerActionLog);
//export default ManagerActionLog;
