import { constants } from '../constants';

const getRequestListAction = (payload) => {
  return {
    type: constants.backoffice.LOAD_REQUESTS_LIST,
    payload,
  };
};

const setRequestsList = (payload) => {
  return {
    type: constants.backoffice.SET_REQUESTS_LIST,
    payload,
  };
};

const getRequestAction = (payload) => {
  return {
    type: constants.backoffice.GET_REQUEST,
    payload,
  };
};

const getPaymentRequestAction = (payload) => {
  return {
    type: constants.backoffice.GET_PAYMENT_REQUEST,
    payload,
  };
};

const setRequest = (payload) => {
  return {
    type: constants.backoffice.SET_REQUEST,
    payload,
  };
};

const setStatusChanged = (payload) => {
  return {
    type: constants.backoffice.SET_REQUEST_STATUS,
    payload,
  };
};

const setPaymentRequestStatusChanged = (payload) => {
  return {
    type: constants.backoffice.SET_PAYMENT_REQUEST_STATUS,
    payload,
  };
};

const loadInvoiceListDataAction = (payload) => {
  return {
    type: constants.backoffice.LOAD_INVOICE_LIST_DATA,
    payload,
  };
};

const setInvoiceListDataAction = (payload) => {
  return {
    type: constants.backoffice.SET_INVOICE_LIST_DATA,
    payload,
  };
};

const loadInvoiceDataAction = (payload) => {
  return {
    type: constants.backoffice.LOAD_INVOICE_DATA,
    payload,
  };
};

const setInvoiceDataAction = (payload) => {
  return {
    type: constants.backoffice.SET_INVOICE_DATA,
    payload,
  };
};

const exportDataAction = (payload) => {
  return {
    type: constants.backoffice.EXPORT_DATA_ACTION,
    payload,
  };
};

const downloadListAction = (payload) => {
  return {
    type: constants.backoffice.DOWNLOAD_LIST_ACTION,
    payload,
  };
};

const getGetUploadedFilesAction = (id) => {
  return {
    type: constants.backoffice.GET_UPLOADED_FILES_LIST,
    id,
  };
};

const downloadAllPrFilesAction = (id) => {
  return {
    type: constants.backoffice.DOWNLOAD_ALL_PR_FILES,
    id,
  };
};

const setGetUploadedFilesAction = (payload) => {
  return {
    type: constants.backoffice.SET_UPLOADED_FILES_LIST,
    payload,
  };
};

const getStatsAction = (payload) => {
  return {
    type: constants.backoffice.GET_STATS,
    payload,
  };
};

const setStatsAction = (payload) => {
  return {
    type: constants.backoffice.SET_STATS,
    payload,
  };
};

const downloadContractDocAction = (requestId) => {
  return {
    type: constants.backoffice.DOWNLOAD_CONTRACT_DOC,
    requestId,
  };
};

const getSignInDocAction = (requestId, user) => {
  return {
    type: constants.backoffice.GET_SIGN_IN_DOC,
    requestId,
    user,
  };
};

const loadRepresentatives = () => {
  return {
    type: constants.backoffice.LOAD_REPRESENTATIVES,
  };
};

const setRepresentatives = (payload) => {
  return {
    type: constants.backoffice.SET_REPRESENTATIVES,
    payload,
  };
};

const setRepresentativeAction = (payload) => {
  return {
    type: constants.backoffice.SET_REPRESENTATIVE,
    payload,
  };
};

const saveRepresentativeAction = (data) => {
  return {
    type: constants.backoffice.SAVE_REPRESENTATIVE,
    data,
  };
};

const setSaveRepresentativeError = (payload) => {
  return {
    type: constants.backoffice.SAVE_REPRESENTATIVE_ERROR,
    payload,
  };
};

const saveEditAdditionalComments = (action, payload) => {
  return {
    type: constants.backoffice.SAVE_EDIT_ADDITIONAL_COMMENTS,
    action,
    payload,
  };
};

const saveRiskAssessmentResult = (action, payload) => {
  return {
    type: constants.backoffice.SAVE_RISK_ASSESMENT_RESULT,
    action,
    payload,
  };
};

const getAdditionalComments = (payload) => {
  return {
    type: constants.backoffice.GET_ADDITIONAL_COMMENTS,
    payload,
  };
};

const getRequestLogAction = (requestId) => {
  return {
    type: constants.backoffice.GET_REQUEST_LOG,
    requestId,
  };
};

const setRequestLog = (payload) => {
  return {
    type: constants.backoffice.SET_REQUEST_LOG,
    payload,
  };
};

const getInvegaUsersAction = () => {
  return {
    type: constants.backoffice.GET_INVEGA_USERS,
  };
};

const setInvegaUsersAction = (payload) => {
  return {
    type: constants.backoffice.SET_INVEGA_USERS,
    payload,
  };
};

const callSagaForErrHandling = (payload) => {
  return {
    type: constants.backoffice.CALL_SAGA_FOR_ERR_HANDLE,
    payload,
  };
};

const setAssessorAction = (payload) => {
  return {
    type: constants.backoffice.SET_ASSESSORS,
    payload,
  };
};

const setRelatedRequestsAction = (payload) => {
  return {
    type: constants.backoffice.SET_RELATED_REQUESTS,
    payload,
  };
};

const downloadAllFilesAction = (id) => {
  return {
    type: constants.backoffice.DOWNLOAD_ALL_FILES,
    id,
  };
};

const setRepresentativesManagerByRequest = (payload) => {
  return {
    type: constants.backoffice.SET_REPRESENTAVITES_MANAGER_BY_REQUEST,
    payload,
  };
};
// const terminateRequestAction = (id, payload) => {
//   return {
//     type: constants.backoffice.GET_ADDITIONAL_COMMENTS,
//     payload,
//   };
// };

export const backofficeActions = {
  getRequestListAction,
  setRequestsList,
  getRequestAction,
  getPaymentRequestAction,
  setRequest,
  setStatusChanged,
  setPaymentRequestStatusChanged,
  loadInvoiceListDataAction,
  setInvoiceListDataAction,
  loadInvoiceDataAction,
  setInvoiceDataAction,
  exportDataAction,
  downloadListAction,
  getGetUploadedFilesAction,
  setGetUploadedFilesAction,
  getStatsAction,
  setStatsAction,
  downloadContractDocAction,
  getSignInDocAction,
  loadRepresentatives,
  setRepresentatives,
  setRepresentativeAction,
  saveRepresentativeAction,
  setSaveRepresentativeError,
  saveEditAdditionalComments,
  saveRiskAssessmentResult,
  getAdditionalComments,
  // terminateRequestAction,
  getRequestLogAction,
  setRequestLog,
  getInvegaUsersAction,
  setInvegaUsersAction,
  callSagaForErrHandling,
  setAssessorAction,
  setRelatedRequestsAction,
  downloadAllFilesAction,
  setRepresentativesManagerByRequest,
  downloadAllPrFilesAction,
};
