import React, { useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import styles from './globalConfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { config } from './../../api/config';
import { Api } from './../../api/api';

const ConfirmWithAdditionalDocs = ({
  open,
  data,
  loading,
  dispatch,
  requestId,
  comment,
  projectRegionId2,
  isNeedSubsidy2,
  formVersion,
}) => {
  const [showError, setShowError] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [invegaProjectRegionId, setInvegaProjectRegionId] = useState(projectRegionId2);
  const [invegaIsNeedSubsidy, setInvegaIsNeedSubsidy] = useState(isNeedSubsidy2);


  const cancel = () => {
    setInvegaProjectRegionId(projectRegionId2);
    setInvegaIsNeedSubsidy(isNeedSubsidy2);
    setNotValid(false);

    const open = {
      modalName: 'confirmRequestNew',
      visible: false,
    };
    setShowError(false);
    setFileToUpload(null);
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    if (fileToUpload) {
    const formData = new FormData();

    formData.append('RequestId', requestId);
    formData.append('InvegaProjectRegionId', invegaProjectRegionId);
    if (invegaProjectRegionId == 1) {formData.append('InvegaIsNeedSubsidy', invegaIsNeedSubsidy);}
    if (invegaProjectRegionId == 2) {formData.append('InvegaIsNeedSubsidy', false);}
    
    formData.append('File', fileToUpload);
    formData.append('Comment', comment);

    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.requestApprove(requestId), {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: 'include',

      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.backoffice.getRequestAction(requestId));
          dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
          dispatch(
            actions.backoffice.saveEditAdditionalComments('get', requestId)
          );
          dispatch(actions.backoffice.getRequestLogAction(requestId));

          cancel();

          swal({
            title: 'Finansavimo skyrimas sėkmingai patvirtintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          res.json().then((r) => {
            swal({
              title: `${
                r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
              }`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });
  } else {
    setNotValid(true);
  }
};

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const onInvegaProjectRegionIdChange = (val) => {
    setInvegaProjectRegionId(val.target.value);
  };

  const onInvegaIsNeedSubsidyChange = (val) => {
    setInvegaIsNeedSubsidy(val.target.value);
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={isLoading}>
            <h2>Patvirtinti finansavimo skyrimą</h2>
         
            <div
                className={styles.inputsInline}
                style={{ marginLeft: '6.5rem', width: '250px' }}
              >
                <div style={{ height: '100px' }}>
                  <label
                    style={{ width: '100%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Veiklos vykdymo regionas
                  </label>
                  <select
                    disabled={formVersion === 3 ? false : true}
                    name="invegaProjectRegionId"
                    onChange={onInvegaProjectRegionIdChange}
                    value={invegaProjectRegionId}
                    style={{ width: '280px', background: formVersion !== 3
                    ? 'rgb(241, 240, 241)'
                    : '#fff'}}
                  >
                    <option value={'1'}>
                      Vidurio vakarų Lietuvos regionas
                    </option>
                    <option value={'2'}>Sostinės regionas ir visa Lietuva</option>
                  </select>
    </div>
              </div>
              {invegaProjectRegionId == 1 && (
              <div style={{ height: '100px' }}>
                  <label
                    style={{ width: '100%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Ar skiriama dotacija?
                  </label>
                  <select
                      disabled={false}
                      name="invegaIsNeedSubsidy"
                      onChange={onInvegaIsNeedSubsidyChange}
                      value={invegaIsNeedSubsidy}
                      style={{ width: '280px'}}
                    >
                      <option value={null}>{null}</option>
                      <option value={false}>Ne</option>
                      <option value={true}>Taip</option>
                    </select>
                    {notValid && invegaIsNeedSubsidy == null && (
                    <p
                      className={styles.error} style={{color: '#bc2432'}}
                   
                    >
                      Pasirinkite reikšmę
                    </p>
                  )}
                </div>)}

            <br />
            <p style={{color: '#597a96'}}>Prašome prisegti sprendimą dėl finansavimo skyrimo</p>
            <br />
            <br />

            <div className={styles.addFileContainer}>
              <Button type="standart" onClick={() => selectFile('fileElem')}>
                Prisegti priedą
              </Button>
              <input
                type="file"
                id="fileElem"
                //accept="application/pdf"
                className="d-none"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <p style={{ marginTop: '0.5rem' }}>
                {fileToUpload && fileToUpload.name ? fileToUpload.name : ''}
              </p>
            </div>

            <br />
            <br />
            <br />
            <div className={styles.btnContainer}>
              <div>
                <Button type="standart" onClick={() => yes(data)}>
                  Patvirtinti
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(ConfirmWithAdditionalDocs);
