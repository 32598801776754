export const dashboardConstants = {
  SET_ON_SYNC_FLAG: 'dashboard/SET_ON_SYNC_FLAG',
  UPDATE_DASHBOARD_DATA: 'dashboard/UPDATE_DASHBOARD_DATA',
  CLEAR_USER_STATE: 'dashboard/CLEAR_USER_STATE',
  SET_DASHBOARD_DATA: 'dashboard/SET_DASHBOARD_DATA',
  SET_INVOICE_LIST_DATA: 'dashboard/SET_INVOICE_LIST_DATA',
  LOAD_INVOICE_LIST_DATA: 'dashboard/LOAD_INVOICE_LIST_DATA',
  PASS_COMPANY_INFO_ACTION: 'dashboard/PASS_COMPANY_INFO_ACTION',
  SET_FIRST_STEP_PASSED: 'dashboard/SET_FIRST_STEP_PASSED',
  DELETE_INVOICE: 'dashboard/DELETE_INVOICE',
  LOAD_INVOICE_APPROVE_LIST_DATA: 'dashboard/LOAD_INVOICE_APPROVE_LIST_DATA',

  GET_REQUEST_LIST: 'dashboard/GET_REQUEST_LIST',
  CREATE_NEW_REQUEST: 'dashboard/CREATE_NEW_REQUEST',
  CREATE_NEW_REQUEST_INDIVIDUAL: 'dashboard/CREATE_NEW_REQUEST_INDIVIDUAL',

  GET_PAYMENT_REQUESTS_LIST: 'dashboard/GET_PAYMENT_REQUESTS_LIST',
  SET_PAYMENT_REQUESTS_LIST: 'dashboard/SET_PAYMENT_REQUESTS_LIST',
  CREATE_NEW_PAYMENT_REQUEST: 'dashboard/CREATE_NEW_PAYMENT_REQUEST',
  SET_PAYMENT_REQUEST: 'dashboard/SET_PAYMENT_REQUEST',
  CREATE_PR_NEW_PR_DOCUMENT_REQUEST:
    'dashboard/CREATE_PR_NEW_PR_DOCUMENT_REQUEST',
  SET_PAYMENT_REQUEST_PR_DOCUMENT: 'SET_PAYMENT_REQUEST_PR_DOCUMENT',
  CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT:
    'dashboard/CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT',
  PR_DOCUMENT_DELETE_REQUEST: 'dashboard/PR_DOCUMENT_DELETE_REQUEST',

  SET_NEW_REQUEST_DATA: 'dashboard/SET_NEW_REQUEST_DATA',
  SET_REQUEST_LIST: 'dashboard/SET_REQUEST_LIST',
  SAVE_REQUEST: 'dashboard/SAVE_REQUEST',
  DELETE_REQUEST: 'dashboard/DELETE_REQUEST',
  LOAD_INVOICE_DATA: 'dashboard/LOAD_INVOICE_DATA',
  LOAD_OWNER_INVOICE_DATA: 'dashboard/LOAD_OWNER_INVOICE_DATA',
  SET_INVOICE_DATA: 'dashboard/SET_INVOICE_DATA',
  SET_OWNER_INVOICE_DATA: 'dashboard/SET_OWNER_INVOICE_DATA',
  SAVE_INVOICE_DATA: 'dashboard/SAVE_INVOICE_DATA',
  INVOICE_SAVED: 'dashboard/INVOICE_SAVED',
  INVOICE_SAVED_EROR: 'dashboard/INVOICE_SAVED_EROR',
  LOAD_REQUEST_LIMIT_DATA: 'dashboard/LOAD_REQUEST_LIMIT_DATA',
  SET_REQUEST_LIMIT_DATA: 'dashboard/SET_REQUEST_LIMIT_DATA',
  SET_REQUEST_GENERAL_LIMIT_DATA: 'dashboard/SET_REQUEST_GENERAL_LIMIT_DATA',
  LOAD_REQUEST_GENERAL_LIMIT_DATA: 'dashboard/LOAD_REQUEST_GENERAL_LIMIT_DATA',

  SAVE_COMPANY_DATA: 'dashboard/SAVE_COMPANY_DATA',
  SET_COMPANY_DATA: 'dashboard/SET_COMPANY_DATA',

  GET_IS_CONSENTAGREED: 'dashboard/GET_IS_CONSENTAGREED',
  SET_IS_CONSENTAGREED: 'dashboard/SET_IS_CONSENTAGREED',
  SET_CONSENT_DATA: 'dashboard/SET_CONSENT_DATA',

  GET_CAN_CREATE_REQUEST: 'dashboard/GET_CAN_CREATE_REQUEST',
  SET_CAN_CREATE_REQUEST: 'dashboard/SET_CAN_CREATE_REQUEST',

  LOAD_GENERAL_DATA: 'dashboard/LOAD_GENERAL_DATA',
  SET_GENERAL_DATA: 'dashboard/SET_GENERAL_DATA',

  APPROVE_REQUEST_SUCCESS: 'dashboard/APPROVE_REQUEST_SUCCESS',
  APPROVE_REQUEST_ERROR: 'dashboard/APPROVE_REQUEST_ERROR',
  APPROVE_REQUEST_RESSET: 'dashboard/APPROVE_REQUEST_RESSET',

  SET_SAVED_REQUEST_DESISSION_ACCEPTED:
    'dashboard/SET_SAVED_REQUEST_DESISSION_ACCEPTED',
  FILE_UPLOAD: 'dashboard/FILE_UPLOAD',
  GET_UPLOADED_FILES_LIST: 'dashboard/GET_UPLOADED_FILES_LIST',
  SET_UPLOADED_FILES_LIST: 'dashboard/SET_UPLOADED_FILES_LIST',
  GET_SIGNING_URL: 'dashboard/GET_SIGNING_URL',
  SET_SIGNING_URL: 'dashboard/GET_SIGNING_URL',
  SET_SIGNING_IN_INFO: 'dashboard/SET_SIGNING_IN_INFO',

  GET_SIGNING_DECISION_URL: 'dashboard/GET_SIGNING_DECISION_URL',

  GET_SIGNING_URL_BATCH: 'dashboard/GET_SIGNING_URL_BATCH',

  GET_FINANCIALCRITERIA_RESULTS: 'dashboard/GET_FINANCIALCRITERIA_RESULTS',
  SET_FINANCIALCRITERIA_RESULTS: 'dashboard/SET_FINANCIALCRITERIA_RESULTS',

  SET_LOGIN_TYPE: 'dashboard/SET_LOGIN_TYPE',

  SET_REQUEST_VIEW_WINDOW: 'dashboard/SET_REQUEST_VIEW_WINDOW',

  SAVE_REPRESENTATIVE: 'dashboard/SAVE_REPRESENTATIVE',
  LOAD_REPRESENTATIVES: 'dashboard/LOAD_REPRESENTATIVES',
  SET_REPRESENTATIVES: 'dashboard/SET_REPRESENTATIVES',
  SAVE_REPRESENTATIVE_ERROR: 'dashboard/SAVE_REPRESENTATIVE_ERROR',
  SET_REPRESENTATIVE: 'dashboard/SET_REPRESENTATIVE',
  LOAD_REPRESENTATIVE: 'dashboard/LOAD_REPRESENTATIVE',
  LOAD_COMPANY_REPRESENTATIVES: 'dashboard/LOAD_COMPANY_REPRESENTATIVES',
  SET_COMPANY_REPRESENTATIVES: 'dashboard/SET_COMPANY_REPRESENTATIVES',

  SEND_FOR_APPROVE: 'dashboard/SEND_FOR_APPROVE',
  SEND_FOR_APPROVE_WITH_DOCS: 'dashboard/SEND_FOR_APPROVE_WITH_DOCS',

  SEND_PAYMENT_REQUEST_FOR_APPROVE:
    'dashboard/SEND_PAYMENT_REQUEST_FOR_APPROVE',
  SEND_FOR_APPROVE_WITH_DOCS_FOR_CHANGE_CONDITIONS:
    'dashboard/SEND_FOR_APPROVE_WITH_DOCS_FOR_CHANGE_CONDITIONS',
  CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT_PAY_BACK:
    'dashboard/CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT_PAY_BACK',
  IS_REPRESENTATIVE: 'dashboard/IS_REPRESENTATIVE',
};
