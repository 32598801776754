import React from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';

const InputWithTextInline = ({
  name,
  type,
  onChange,
  value,
  placeholder,
  disabled,
  inOneLine,
  min,
  max,
  ...rest
}) => {
  return (
    <div
      className={inOneLine ? styles.inOneLineContainer : styles.inlineContainer}
    >
      <div className={styles.titleContainer}>
        <p>{placeholder}</p>
        {rest.subtitles && (
          <ul style={{ paddingLeft: '25px', paddingBottom: '5px' }}>
            {rest.subtitles.map((item, i) => (
              <li key={i}>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <input
        className={styles.inlineInput}
        // style={{ background: disabled && '#f1f0f1' }}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        disabled={disabled}
        min={min}
        max={max}
        {...rest}
      />
    </div>
  );
};

InputWithTextInline.defaultProps = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default InputWithTextInline;
