import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './takeRequestToEvaluateModal.module.scss';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';

const TakeRequestToEvaluateModal = ({
  open,
  id,
  loading,
  dispatch,
  assessorsList,
  requestId,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [assessor, setAssessor] = useState(null);

  const cancel = () => {
    const open = {
      modalName: 'takeRequestToEvaluateModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = (id) => {
    console.log(assessor);

    if (assessor) {
      setIsloading(true);
      fetch(
        Api.API_BASE_URL +
          config.API_ENDPOINTS.SetAssessor(requestId, assessor),
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        }
      )
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.backoffice.getRequestAction(requestId));
            dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
            dispatch(
              actions.backoffice.saveEditAdditionalComments('get', requestId)
            );
            dispatch(actions.backoffice.getRequestLogAction(requestId));

            cancel();

            swal({
              title: 'Asmuo sėkmingai priskirtas paraiškos vertinimui',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              // dispatch(actions.backoffice.callSagaForErrHandling(r));

              swal({
                title: 'Įvyko klaida priskiriant asmenį',
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn btn-pink',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: 'Įvyko klaida priskiriant asmenį',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        })
        .finally(() => {
          setIsloading(false);
        });
    } else {
      setNotValid(true);
    }
  };
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <h2>Pasirinkite, kam norite priskirti šią paraišką</h2>
            <div className="col-lg-12 col-sm-12" style={{ width: '345px' }}>
              <label
                style={{
                  margin: '8px 0 8px',
                  color: '#597a96',
                  fontSize: '0.8rem',
                  lineHeight: '1.5',
                }}
              >
                Asmuo priskiriamas šios paraiškos vertinimui
              </label>
              <select
                disabled={false}
                name="CompanySizeType"
                onChange={(e) => setAssessor(e.target.value)}
                style={{
                  background: '#fff',
                }}
                value={assessor}
              >
                <option value={null}>{null}</option>
                {assessorsList.length > 0 &&
                  assessorsList.map((val) => (
                    <option selected={assessor} value={val.Id}>
                      {val.FirstName} {val.LastName}
                    </option>
                  ))}
              </select>
              {notValid && !assessor && (
                <p className={styles.error}>Privalote pasirinkti asmenį</p>
              )}
            </div>
            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(id)}>
                Priskirti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  assessorsList: state.backofficeRequestsState.AssessorsList,
});

export default connect(mapStateToProps)(TakeRequestToEvaluateModal);
