export const evrkJson = {
  100000: 'Maisto produktų gamyba',
  101000: 'Mėsos perdirbimas ir konservavimas ir mėsos produktų gamyba',
  101100: 'Mėsos perdirbimas ir konservavimas',
  101200: 'Paukštienos perdirbimas ir konservavimas',
  101300: 'Mėsos ir paukštienos produktų gamyba',
  101310: 'Rūkytų mėsos ir paukštienos produktų gamyba',
  102000: 'Žuvų, vėžiagyvių ir moliuskų perdirbimas ir konservavimas',
  103000: 'Vaisių ir daržovių perdirbimas ir konservavimas',
  103100: 'Bulvių perdirbimas ir konservavimas',
  103110: 'Bulvių miltų gamyba',
  103200: 'Vaisių, uogų ir daržovių sulčių gamyba',
  103900: 'Kitas vaisių ir daržovių perdirbimas ir konservavimas',
  104000: 'Gyvūninių ir augalinių riebalų bei aliejaus gamyba',
  104100: 'Aliejaus ir riebalų gamyba',
  104200: 'Margarino ir panašių valgomųjų riebalų gamyba',
  105000: 'Pieno produktų gamyba',
  105100: 'Pieninių veikla ir sūrių gamyba',
  105200: 'Valgomųjų ledų gamyba',
  106000: 'Grūdų malimo produktų, krakmolo ir krakmolo produktų gamyba',
  106100: 'Grūdų malimo produktų gamyba',
  106110: 'Miltų gamyba',
  106120: 'Kruopų gamyba',
  106200: 'Krakmolo ir krakmolo produktų gamyba',
  106210: 'Gliukozės sirupo ir maltozės gamyba',
  106220: 'Krakmolo gamyba',
  107000: 'Kepyklos ir miltinių produktų gamyba',
  107100: '"Duonos gamyba; šviežių konditerijos kepinių ir pyragaičių gamyba"',
  107200:
    '"Džiūvėsių ir sausainių gamyba; ilgai išsilaikančių konditerijos kepinių ir pyragaičių gamyba"',
  107300: 'Makaronų, vermišelių, kuskuso ir panašių miltinių produktų gamyba',
  108000: 'Kitų maisto produktų gamyba',
  108100: 'Cukraus gamyba',
  108200: 'Kakavos, šokolado ir cukraus saldumynų gamyba',
  108300: 'Arbatos ir kavos apdorojimas ir perdirbimas',
  108400: 'Užgardų ir pagardų gamyba',
  108410: 'Acto gamyba',
  108500: 'Paruoštų valgių ir patiekalų gamyba',
  108600: 'Homogenizuotų ir dietinių maisto produktų gamyba',
  108900: 'Kitų, niekur kitur nepriskirtų, maisto produktų gamyba',
  108910: 'Mielių gamyba',
  109000: 'Paruoštų pašarų gyvuliams gamyba',
  109100: 'Paruoštų pašarų ūkio gyvuliams gamyba',
  109200: 'Paruošto ėdalo naminiams gyvūnėliams gamyba',
  110000: 'Gėrimų gamyba',
  110100: 'Spirito distiliavimas, rektifikavimas ir maišymas',
  110200: 'Vynuogių vyno gamyba',
  110300: 'Sidro ir kitokių vaisių, uogų vynų gamyba',
  110400: 'Kitų nedistiliuotų fermentuotų gėrimų gamyba',
  110500: 'Alaus gamyba',
  110600: 'Salyklo gamyba',
  110700:
    '"Nealkoholinių gėrimų gamyba; mineralinio ir kito, pilstomo į butelius, vandens gamyba"',
  110710: 'Nealkoholinių gėrimų gamyba',
  110720: 'Natūralaus mineralinio ir kito, pilstomo į butelius, vandens gamyba',
  120000: 'Tabako gaminių gamyba',
  130000: 'Tekstilės gaminių gamyba',
  131000: 'Tekstilės pluoštų paruošimas ir verpimas',
  132000: 'Tekstilės audimas',
  132010: 'Lininių audinių audimas',
  132020: 'Sintetinių audinių audimas',
  133000: 'Tekstilės apdaila',
  139000: 'Kitų tekstilės gaminių ir dirbinių gamyba',
  139100: 'Megztų (trikotažinių) ir nertų medžiagų gamyba',
  139200: 'Gatavų tekstilės dirbinių, išskyrus drabužius, gamyba',
  139300: 'Kilimų ir kilimėlių gamyba',
  139400: 'Virvių, lynų, virvelių ir tinklų gamyba',
  139500:
    'Neaustinių medžiagų ir neaustinių medžiagų gaminių ir dirbinių, išskyrus drabužius, gamyba',
  139600: 'Kitų techninės ir pramoninės tekstilės gaminių ir dirbinių gamyba',
  139900:
    'Kitų, niekur kitur nepriskirtų, tekstilės gaminių ir dirbinių gamyba',
  140000: 'Drabužių siuvimas (gamyba)',
  141000: 'Drabužių, išskyrus kailinius drabužius, siuvimas (gamyba)',
  141100: 'Odinių drabužių siuvimas',
  141200: 'Darbo drabužių siuvimas',
  141300: 'Kitų viršutinių drabužių siuvimas',
  141310: 'Vyriškų viršutinių drabužių siuvimas',
  141320: 'Moteriškų viršutinių drabužių siuvimas',
  141400: 'Apatinių drabužių siuvimas',
  141900: 'Kitų drabužių siuvimas ir drabužių priedų gamyba',
  141910: 'Vaiko drabužėlių siuvimas',
  141920: 'Sportinės aprangos siuvimas',
  141930: 'Kailinių kepurių ir kitų galvos apdangalų  gamyba',
  142000: 'Kailinių dirbinių ir gaminių gamyba',
  143000: 'Megztų (trikotažinių) ir nertų drabužių gamyba',
  143100:
    'Pėdkelnių, triko, kojinių ir kitų panašių megztų (trikotažinių) ir nertų gaminių bei dirbinių gamyba',
  143900: 'Kitų megztų (trikotažinių) ir nertų drabužių gamyba',
  150000: 'Odos ir odos dirbinių gamyba',
  151000:
    '"Odų rauginimas ir išdirbimas; lagaminų, rankinių, balno reikmenų ir pakinktų gamyba; kailių išdirbimas ir dažymas"',
  151100: '"Odų rauginimas ir išdirbimas; kailių išdirbimas ir dažymas"',
  151110: 'Odų rauginimas, naudojant augalines medžiagas',
  151120: 'Odų rauginimas, naudojant mineralines ir chemines medžiagas',
  151130: 'Odų išdirbimas',
  151200:
    'Lagaminų, rankinių ir panašių reikmenų, balno reikmenų ir pakinktų gamyba',
  152000: 'Avalynės gamyba',
  160000:
    'Medienos bei medienos ir kamštienos gaminių, išskyrus baldus, gamyba; gaminių iš šiaudų ir pynimo medžiagų gamyba',
  161000: 'Medienos pjaustymas ir obliavimas',
  161010: 'Cheminis medienos apdorojimas konservantais',
  162000:
    'Gaminių ir dirbinių iš medienos, kamštienos, šiaudų ir pynimo medžiagų gamyba',
  162100: 'Faneravimo dangos ir medienos plokščių gamyba',
  162110: 'Medienos plaušo plokščių, padengtų dekoratyvine plėvele, gamyba',
  162120: 'Presuotos medienos gamyba',
  162200: 'Sumontuotų parketo grindų gamyba',
  162300: 'Kitų statybinių dailidžių ir stalių dirbinių gamyba',
  162310: 'Fasoninių detalių ir malksnų (gontų) stogų dengimui gamyba',
  162320: 'Medinių surenkamųjų statinių ar jų detalių gamyba',
  162330: 'Medinių sieninių plokščių ir spintų gamyba',
  162400: 'Medinės taros gamyba',
  162410: 'Padėklų, stovų ir kitų medinių pakrovimo reikmenų gamyba',
  162900:
    'Kitų medienos gaminių gamyba; dirbinių iš kamštienos, šiaudų ir pynimo medžiagų gamyba',
  170000: 'Popieriaus ir popieriaus gaminių gamyba',
  171000: 'Plaušienos, popieriaus ir kartono gamyba',
  171100: 'Plaušienos gamyba',
  171200: 'Popieriaus ir kartono gamyba',
  172000: 'Popieriaus ir kartoninių gaminių gamyba',
  172100:
    'Gofruotojo popieriaus ir kartono bei taros iš popieriaus ir kartono gamyba',
  172200: 'Buitinių ir higienos reikmenų bei tualeto reikmenų gamyba',
  172210: 'Tualetinio popieriaus gamyba',
  172300: 'Popierinių raštinės reikmenų gamyba',
  172310: 'Kopijavimo popieriaus gamyba',
  172320: 'Vokų ir atvirukų gamyba',
  172400: 'Sienų apmušalų (tapetų) gamyba',
  172900: 'Kitų popierinių ir kartoninių gaminių gamyba',
  172910: 'Filtravimo popieriaus gamyba',
  180000: 'Spausdinimas ir įrašytų laikmenų tiražavimas',
  181000: 'Spausdinimas ir su spausdinimu susijusios paslaugos',
  181100: 'Laikraščių spausdinimas',
  181200: 'Kitas spausdinimas',
  181300: 'Parengiamoji spausdinimo ir žiniasklaidos veikla',
  181400: 'Įrišimas ir susijusios paslaugos',
  182000: 'Įrašytų laikmenų tiražavimas',
  190000: 'Kokso ir rafinuotų naftos produktų gamyba',
  191000: 'Koksavimo krosnių produktų gamyba',
  192000: 'Rafinuotų naftos produktų gamyba',
  192010: 'Skystojo arba dujinio kuro gamyba',
  192020: 'Tepalų ir kitų alyvų gamyba',
  192030: 'Parafino ir techninio vazelino  gamyba',
  192040: 'Naftos bitumo gamyba',
  200000: 'Chemikalų ir chemijos produktų gamyba',
  201000:
    'Pagrindinių chemikalų, trąšų ir azoto junginių, pirminių plastikų ir pirminio sintetinio kaučiuko gamyba',
  201100: 'Pramoninių dujų gamyba',
  201200: 'Dažiklių ir pigmentų gamyba',
  201300: 'Kitų pagrindinių neorganinių chemikalų gamyba',
  201400: 'Kitų pagrindinių organinių chemikalų gamyba',
  201500: 'Trąšų ir azoto junginių gamyba',
  201510: 'Grynųjų ar kompleksinių azoto ir fosforo trąšų gamyba',
  201520: 'Karbamido gamyba',
  201530: 'Amoniako gamyba',
  201600: 'Pirminių plastikų gamyba',
  201700: 'Pirminio sintetinio kaučiuko gamyba',
  202000: 'Pesticidų ir kitų agrocheminių medžiagų gamyba',
  202010: 'Dezinfekantų gamyba',
  203000:
    'Dažų, lakų ir panašių dangų medžiagų, spaustuvinių dažų ir mastikų gamyba',
  203010: 'Emalinių dažų ir lakų gamyba',
  203020: 'Organinių sudėtingų tirpiklių ir skiediklių gamyba',
  204000:
    'Muilo ir ploviklių, valiklių ir blizgiklių, kvepalų ir tualeto priemonių gamyba',
  204100: 'Muilo ir ploviklių, valiklių ir blizgiklių gamyba',
  204200: 'Kvepalų ir tualeto priemonių gamyba',
  205000: 'Kitų cheminių medžiagų gamyba',
  205100: 'Sprogiųjų medžiagų gamyba',
  205200: 'Klijų gamyba',
  205300: 'Eterinio aliejaus gamyba',
  205900: 'Kitų, niekur kitur nepriskirtų, cheminių medžiagų gamyba',
  205910: 'Lydymo, litavimo ar suvirinimo miltelių ir pastų gamyba',
  205920:
    'Aktyvintų anglių, tepalų priedų, paruoštų kaučiuko vulkanizavimo greitiklių, katalizatorių gamyba',
  205930: 'Antidetonatorių, antifrizų, skysčių hidraulinėms sistemoms gamyba',
  205940: 'Rašalo ir tušo gamyba',
  206000: 'Cheminių pluoštų gamyba',
  210000: 'Pagrindinių vaistų pramonės gaminių ir farmacinių preparatų gamyba',
  211010: 'Narkotinių medžiagų gamyba',
  212000: 'Farmacinių preparatų gamyba',
  212010: 'Vaistų gamyba',
  212020: 'Veterinarinių vaistų gamyba',
  220000: 'Guminių ir plastikinių gaminių gamyba',
  221000: 'Guminių gaminių gamyba',
  221100:
    '"Guminių padangų ir kamerų gamyba; guminių padangų restauravimas ir atnaujinimas"',
  221900: 'Kitų guminių gaminių gamyba',
  222000: 'Plastikinių gaminių gamyba',
  222100: 'Plastikinių plokščių, lakštų, vamzdžių ir profiliuočių gamyba',
  222200: 'Plastikinių pakuočių gamyba',
  222300: 'Plastikinių statybos dirbinių gamyba',
  222900: 'Kitų plastikinių gaminių gamyba',
  230000: 'Kitų nemetalo mineralinių produktų gamyba',
  231000: 'Stiklo ir stiklo gaminių bei dirbinių gamyba',
  231100: 'Plokščiojo stiklo gamyba',
  231200: 'Plokščiojo stiklo pjaustymas ir apdorojimas',
  231300: 'Tuščiavidurio stiklo gamyba',
  231310: 'Stiklinių talpyklų gamyba',
  231400: 'Stiklo pluoštų gamyba',
  231900:
    'Kito stiklo, įskaitant skirto techninėms reikmėms, gamyba ir apdorojimas',
  231910:
    'Stiklinių izoliatorių ir stiklinių izoliacinių jungiamųjų detalių gamyba',
  232000: 'Ugniai atsparių gaminių gamyba',
  233000: 'Statybinių medžiagų iš molio gamyba',
  233100: 'Keraminių apdailos ir šaligatvio plytelių gamyba',
  233110: 'Koklių gamyba',
  233120: 'Keraminių grindinio dangų gamyba',
  233200: 'Degto molio plytų, plytelių ir statybinių dirbinių gamyba',
  234000: 'Kitų porcelianinių ir keraminių gaminių bei dirbinių gamyba',
  234100: 'Keraminių buities ir puošybos gaminių bei dirbinių gamyba',
  234200: 'Keraminių santechnikos gaminių ir dirbinių gamyba',
  234300: 'Keraminių izoliatorių ir keraminių izoliacinių detalių gamyba',
  234400: 'Kitų techninės paskirties keraminių gaminių ir dirbinių gamyba',
  234900: 'Kitų keraminių gaminių ir dirbinių gamyba',
  235000: 'Cemento, kalkių ir gipso gamyba',
  235100: 'Cemento gamyba',
  235200: 'Kalkių ir gipso gamyba',
  236000: 'Betono, cemento ir gipso gaminių bei dirbinių gamyba',
  236100: 'Betono gaminių, skirtų statybinėms reikmėms, gamyba',
  236200: 'Gipso gaminių, naudojamų statybinėms reikmėms, gamyba',
  236300: 'Prekinio betono mišinio gamyba',
  236400: 'Statybinių skiedinių gamyba',
  236500: 'Fibrolito gamyba',
  236900: 'Kitų betono, gipso ir cemento gaminių bei dirbinių gamyba',
  237000: 'Akmens pjaustymas, tašymas ir apdailinimas',
  239000:
    'Abrazyvinių gaminių ir niekur kitur nepriskirtų nemetalo mineralinių produktų gamyba',
  239100: 'Abrazyvinių gaminių gamyba',
  239900:
    'Kitų, niekur kitur nepriskirtų, nemetalo mineralinių produktų gamyba',
  239910: 'Asbestinio pluošto, verpalų ir audinių bei gaminių iš jų gamyba',
  239920: 'Frikcinių medžiagų ir jų gaminių gamyba',
  239930: 'Mineralinių izoliacinių medžiagų gamyba',
  239940: 'Dirbinių iš bitumo gamyba',
  240000: 'Pagrindinių metalų gamyba',
  241000: 'Tomo ketaus ir plieno bei ferolydinių gamyba',
  242000:
    'Plieninių vamzdžių, vamzdelių, tuščiavidurių profilių ir susijusių jungiamųjų detalių gamyba',
  243000: 'Kitų plieno pirminio apdirbimo gaminių gamyba',
  243100: 'Šaltasis strypų tempimas',
  243200: 'Šaltasis siaurų juostų valcavimas',
  243300: 'Šaltasis formavimas ar lankstymas',
  243400: 'Šaltasis vielos tempimas',
  244000: 'Pagrindinių tauriųjų ir kitų spalvotųjų metalų gamyba',
  244100: 'Tauriųjų metalų gamyba',
  244200: 'Aliuminio gamyba',
  244300: 'Švino, cinko ir alavo gamyba',
  244400: 'Vario gamyba',
  244500: 'Kitų spalvotųjų metalų gamyba',
  244600: 'Branduolinio kuro perdirbimas',
  245000: 'Metalų liejinių gamyba',
  245100: 'Geležies liejinių gamyba',
  245200: 'Plieno liejinių gamyba',
  245300: 'Lengvųjų metalų liejinių gamyba',
  245400: 'Kitų spalvotųjų metalų liejinių gamyba',
  250000: 'Metalo gaminių, išskyrus mašinas ir įrenginius, gamyba',
  251000: 'Konstrukcinių metalo gaminių gamyba',
  251100: 'Metalo konstrukcijų ir jų dalių gamyba',
  251200: 'Metalinių durų ir langų gamyba',
  252000: 'Metalinių cisternų, rezervuarų ir talpyklų gamyba',
  252100: 'Centrinio šildymo radiatorių ir katilų gamyba',
  252900: 'Kitų metalinių cisternų, rezervuarų ir talpyklų gamyba',
  252910: 'Metalinių talpyklų suslėgtosioms ar suskystintosioms dujoms gamyba',
  253000:
    'Garo generatorių, išskyrus centrinio šildymo karšto vandens katilus, gamyba',
  254000: 'Ginklų ir šaudmenų gamyba',
  255000:
    '"Metalo kalimas, presavimas, štampavimas ir profiliavimas; miltelių metalurgija"',
  256000: '"Metalų apdorojimas ir dengimas; mechaninis apdirbimas"',
  256100: 'Metalų apdorojimas ir dengimas',
  256200: 'Mechaninis apdirbimas',
  257000: 'Valgomųjų įrankių, kitų įrankių ir bendrųjų metalo dirbinių gamyba',
  257100: 'Valgomųjų ir kitų pjovimo įrankių gamyba',
  257200: 'Spynų ir vyrių gamyba',
  257300: 'Kitų įrankių gamyba',
  259000: 'Kitų metalo gaminių gamyba',
  259100: 'Plieninių statinių ir panašių talpyklų gamyba',
  259200: 'Lengvųjų metalų taros gamyba',
  259300: 'Vielos gaminių, grandinių ir spyruoklių gamyba',
  259400: 'Tvirtinimo detalių ir sriegimo mašinų gaminių gamyba',
  259900: 'Kitų, niekur kitur nepriskirtų, metalo gaminių gamyba',
  260000: 'Kompiuterinių, elektroninių ir optinių gaminių gamyba',
  261000: 'Elektroninių komponentų ir plokščių gamyba',
  261100: 'Elektroninių komponentų gamyba',
  261200: 'Sumontuotų elektroninių plokščių gamyba',
  262000: 'Kompiuterių ir išorinės įrangos gamyba',
  263000: 'Ryšių įrangos gamyba',
  264000: 'Vartotojiškos elektroninės įrangos gamyba',
  265000:
    '"Matavimo, bandymo, navigacinės ir kontrolės įrangos gamyba; įvairių tipų laikrodžių gamyba"',
  265100:
    'Matavimo, bandymo, navigacinės ir kontrolės įrangos prietaisų ir aparatų gamyba',
  265110: 'Elektros skaitiklių gamyba',
  265120: 'Vandens skaitiklių gamyba',
  265130: 'Dujų skaitiklių gamyba',
  265140: 'Automatinio reguliavimo ir kontrolės prietaisų gamyba',
  265200: 'Įvairių tipų laikrodžių gamyba',
  266000: 'Švitinimo, elektromedicininės ir elektroterapinės įrangos gamyba',
  267000: 'Optinių prietaisų ir fotografijos įrangos gamyba',
  268000: 'Magnetinių ir optinių laikmenų gamyba',
  270000: 'Elektros įrangos gamyba',
  271000:
    'Elektros variklių, generatorių, transformatorių bei elektros skirstomosios ir valdymo įrangos gamyba',
  271100: 'Elektros variklių, generatorių ir transformatorių gamyba',
  271200: 'Elektros skirstomosios ir valdymo įrangos gamyba',
  272000: 'Baterijų ir akumuliatorių gamyba',
  273000: 'Laidų ir instaliacijos įtaisų gamyba',
  273100: 'Skaidulinės optikos kabelių gamyba',
  273200: 'Kitų elektronikos bei elektros laidų ir kabelių gamyba',
  273300: 'Instaliacijos įtaisų gamyba',
  274000: 'Elektros apšvietimo įrangos gamyba',
  275000: 'Buitinių aparatų ir prietaisų gamyba',
  275100: 'Buitinių elektrinių aparatų ir prietaisų gamyba',
  275200: 'Buitinių neelektrinių aparatų ir prietaisų gamyba',
  279000: 'Kitos elektros įrangos gamyba',
  280000: 'Niekur kitur nepriskirtų mašinų ir įrangos gamyba',
  281000: 'Bendrosios paskirties mašinų gamyba',
  281100:
    'Variklių ir turbinų, išskyrus orlaivių, transporto priemonių ir motociklų variklius, gamyba',
  281200: 'Hidraulinės energijos įrangos gamyba',
  281300: 'Kitų siurblių ir kompresorių gamyba',
  281400: 'Kitų čiaupų ir sklendžių gamyba',
  281500:
    'Guolių, krumpliaračių, krumplinių pavarų ir varomųjų elementų gamyba',
  282000: 'Kitų bendrosios paskirties mašinų ir įrangos gamyba',
  282100: 'Orkaičių, krosnių ir krosnių degiklių gamyba',
  282200: 'Kėlimo ir krovimo įrangos gamyba',
  282300:
    'Įstaigos mašinų ir įrangos (išskyrus kompiuterius ir išorinę įrangą) gamyba',
  282400: 'Variklinių rankinių įrankių gamyba',
  282500: 'Nebuitinių aušinimo ir vėdinimo įrenginių gamyba',
  282900:
    'Kitų, niekur kitur nepriskirtų, bendrosios paskirties mašinų ir įrangos gamyba',
  283000: 'Žemės ir miškų ūkio mašinų gamyba',
  284000: 'Metalo formavimo įrangos ir staklių gamyba',
  284100: 'Metalo formavimo įrangos gamyba',
  284900: 'Kitų staklių gamyba',
  289000: 'Kitų specialiosios paskirties mašinų gamyba',
  289100: 'Metalurgijos mašinų gamyba',
  289200: 'Kasybos, karjerų eksploatavimo ir statybos mašinų gamyba',
  289300: 'Maisto, gėrimų ir tabako apdorojimo mašinų gamyba',
  289400: 'Tekstilės, drabužių ir odos gaminių gamybos mašinų gamyba',
  289500: 'Popieriaus ir kartono gamybos mašinų gamyba',
  289600: 'Plastikų ir gumos gamybos mašinų gamyba',
  289900:
    'Kitų, niekur kitur nepriskirtų, specialiosios paskirties mašinų gamyba',
  290000: 'Variklinių transporto priemonių, priekabų ir puspriekabių gamyba',
  291000: 'Variklinių transporto priemonių gamyba',
  292000:
    '"Variklinių transporto priemonių kėbulų gamyba; priekabų ir puspriekabių gamyba"',
  293000: 'Variklinių transporto priemonių dalių ir pagalbinių reikmenų gamyba',
  293100:
    'Variklinių transporto priemonių elektros ir elektroninės įrangos gamyba',
  293200: 'Variklinių transporto priemonių kitų dalių ir reikmenų gamyba',
  300000: 'Kitų transporto priemonių ir įrangos gamyba',
  301000: 'Įvairių tipų laivų statyba',
  301100: 'Laivų ir plūdriųjų konstrukcijų statyba',
  301200: 'Pramoginių ir sportinių katerių (laivų) statyba',
  302000: 'Geležinkelio lokomotyvų bei riedmenų gamyba',
  303000: 'Orlaivių ir erdvėlaivių bei susijusios įrangos gamyba',
  304000: 'Karinių kovinių transporto priemonių gamyba',
  309000: 'Niekur kitur nepriskirtų transporto priemonių gamyba',
  309100: 'Motociklų gamyba',
  309200: 'Dviračių ir invalidų vežimėlių gamyba',
  309900: 'Kitos, niekur kitur nepriskirtos, transporto įrangos gamyba',
  310000: 'Baldų gamyba',
  310100: 'Įstaigos ir prekybos įmonių (parduotuvių) baldų gamyba',
  310200: 'Virtuvės baldų gamyba',
  310300: 'Čiužinių gamyba',
  310900: 'Kitų baldų gamyba',
  320000: 'Kita gamyba',
  321000: 'Papuošalų, juvelyrinių, bižuterijos ir panašių dirbinių gamyba',
  321100: 'Monetų kalimas',
  321200: 'Papuošalų, juvelyrinių ir panašių dirbinių gamyba',
  321300: 'Dirbtinės bižuterijos ir panašių dirbinių gamyba',
  322000: 'Muzikos instrumentų gamyba',
  323000: 'Sporto reikmenų gamyba',
  324000: 'Žaidimų ir žaislų gamyba',
  324010: 'Konstravimo rinkinių gamyba',
  324020: 'Plastikinių žaislų gamyba',
  324030: 'Medinių žaislų gamyba',
  324040: 'Kimštinių žaislų gamyba',
  324050: 'Stalo arba kambario žaidimų gamyba',
  324060: 'Plastikinių triračių ir kitų žaislų su ratukais gamyba',
  324070: 'Elektrinių žaislų ir žaidimų gamyba',
  325000:
    'Medicinos ir odontologijos prietaisų, instrumentų ir reikmenų gamyba',
  325010: 'Odontologinio cemento gamyba',
  325020: 'Ortopedinių ir kitokių įtaisų gamyba',
  325030: 'Protezų gamyba',
  329000: 'Niekur kitur nepriskirta gamyba',
  329100: 'Šluotų ir šepečių gamyba',
  329900: 'Kita, niekur kitur nepriskirta, gamyba',
  329910: 'Medinių karstų ir kitų laidojimo reikmenų gamyba',
  330000: 'Mašinų ir įrangos remontas ir įrengimas',
  331000: 'Metalo gaminių, mašinų ir įrangos remontas',
  331100: 'Metalo gaminių remontas',
  331200: 'Mašinų remontas',
  331300: 'Elektroninės ir optinės įrangos remontas',
  331400: 'Elektros įrangos remontas',
  331500: 'Įvairių tipų laivų remontas ir techninė priežiūra',
  331600: 'Orlaivių ir erdvėlaivių remontas ir techninė priežiūra',
  331700: 'Kitų transporto priemonių remontas ir techninė priežiūra',
  331900: 'Kitos įrangos remontas',
  332000: 'Pramoninių mašinų ir įrangos įrengimas',
  451000: 'Variklinių transporto priemonių pardavimas',
  451100: 'Automobilių ir lengvųjų variklinių transporto priemonių pardavimas',
  451900: 'Kitų variklinių transporto priemonių pardavimas',
  453000:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų pardavimas',
  453200:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų mažmeninė prekyba',
  454000:
    'Motociklų ir jų atsarginių dalių bei pagalbinių reikmenų pardavimas, techninė priežiūra ir remontas',
  470000:
    'Mažmeninė prekyba, išskyrus variklinių transporto priemonių ir motociklų prekybą',
  471000: 'Mažmeninė prekyba nespecializuotose parduotuvėse',
  471900: 'Kita mažmeninė prekyba nespecializuotose parduotuvėse',
  472600: 'Tabako gaminių mažmeninė prekyba specializuotose parduotuvėse',
  474000:
    'Informacijos ir ryšių technologijų (IRT) įrangos mažmeninė prekyba specializuotose parduotuvėse ',
  474100:
    'Kompiuterių, jų išorinės ir programinės įrangos mažmeninė prekyba specializuotose parduotuvėse ',
  474200:
    'Telekomunikacijų įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474300:
    'Garso ir vaizdo įrangos mažmeninė prekyba specializuotose parduotuvėse',
  475000:
    'Kitos namų ūkio įrangos mažmeninė prekyba specializuotose parduotuvėse',
  475100: 'Tekstilės gaminių mažmeninė prekyba specializuotose parduotuvėse',
  475200:
    'Metalo dirbinių, dažų ir stiklo mažmeninė prekyba specializuotose parduotuvėse',
  475210: 'Dažų, lakų ir politūrų mažmeninė prekyba',
  475220: 'Plokščiojo stiklo mažmeninė prekyba',
  475230: 'Statybinių medžiagų mažmeninė prekyba',
  475240: 'Medžiagų ir smulkiojo remonto darbams įrangos mažmeninė prekyba',
  475300:
    'Kilimų, kilimėlių, sienų ir grindų dangų mažmeninė prekyba specializuotose parduotuvėse',
  475400:
    'Elektrinių buitinių aparatų ir prietaisų mažmeninė prekyba specializuotose parduotuvėse',
  475900:
    'Baldų, apšvietimo įrangos ir kitų namų ūkio prekių mažmeninė prekyba specializuotose parduotuvėse',
  476000:
    'Kultūros ir poilsio prekių mažmeninė prekyba specializuotose parduotuvėse',
  476100: 'Knygų mažmeninė prekyba specializuotose parduotuvėse',
  476200:
    'Laikraščių ir raštinės reikmenų mažmeninė prekyba specializuotose parduotuvėse',
  476300:
    'Muzikos ir vaizdo įrašų mažmeninė prekyba specializuotose parduotuvėse',
  476400: 'Sporto įrangos mažmeninė prekyba specializuotose parduotuvėse',
  476500: 'Žaidimų ir žaislų mažmeninė prekyba specializuotose parduotuvėse',
  477000: 'Kitų prekių mažmeninė prekyba specializuotose parduotuvėse',
  477100: 'Drabužių mažmeninė prekyba specializuotose parduotuvėse',
  477200:
    'Avalynės ir odos gaminių mažmeninė prekyba specializuotose parduotuvėse',
  477400:
    'Medicinos ir ortopedinių prekių mažmeninė prekyba specializuotose parduotuvėse',
  477500:
    'Kosmetikos ir tualeto reikmenų mažmeninė prekyba specializuotose parduotuvėse',
  477600:
    'Gėlių, sodinukų, sėklų, trąšų, naminių gyvūnėlių ir jų ėdalo mažmeninė prekyba specializuotose parduotuvėse ',
  477700:
    'Laikrodžių, papuošalų ir juvelyrinių dirbinių mažmeninė prekyba specializuotose parduotuvėse',
  477800: 'Kita naujų prekių mažmeninė prekyba specializuotose parduotuvėse',
  477810:
    'Suvenyrų, meno dirbinių ir religinių reikmenų specializuota mažmeninė prekyba',
  477820:
    'Buitinio skystojo kuro, dujų balionų, anglies ir malkų specializuota mažmeninė prekyba',
  477890: 'Kita specializuota mažmeninė prekyba',
  477900: 'Naudotų daiktų mažmeninė prekyba parduotuvėse',
  477910: 'Antikvarinių daiktų mažmeninė prekyba',
  478000: 'Mažmeninė prekyba kioskuose ir prekyvietėse',
  478200:
    'Tekstilės, drabužių ir avalynės mažmeninė prekyba kioskuose ir prekyvietėse',
  478900: 'Kitų prekių mažmeninė prekyba kioskuose ir prekyvietėse',
  479000: 'Mažmeninė prekyba ne parduotuvėse, kioskuose ar prekyvietėse',
  479900: 'Kita mažmeninė prekyba ne parduotuvėse, kioskuose ar prekyvietėse',
  479910: 'Mažmeninė prekyba per keliaujančius firmos atstovus',
  479920: 'Mažmeninė prekyba iš automatų smulkioms prekėms pardavinėti',
  493000: 'Kitas keleivinis sausumos transportas ',
  493100: 'Keleivių vežimas miesto arba priemiestiniu sausumos transportu ',
  493110: 'Keleivių vežimas autobusais mieste ar priemiestyje ',
  501000: 'Jūrų ir pakrančių keleivinis vandens transportas ',
  511000: 'Keleivinis oro transportas',
  550000: 'Apgyvendinimo veikla ',
  551000: 'Viešbučių ir panašių laikinų buveinių veikla',
  552000: 'Poilsiautojų ir kita trumpalaikio apgyvendinimo veikla',
  552010: 'Poilsio namų veikla',
  552020: 'Vaikų poilsio stovyklų veikla',
  553000:
    'Poilsinių transporto priemonių, priekabų aikštelių ir stovyklaviečių veikla',
  559000: 'Kita apgyvendinimo veikla',
  560000: 'Maitinimo ir gėrimų teikimo veikla',
  561000: 'Restoranų ir pagaminto valgio teikimo veikla',
  562000:
    'Pagaminto valgio tiekimas renginiams ir kitų maitinimo paslaugų veikla',
  562100: 'Pagaminto valgio tiekimas renginiams',
  562900: 'Kitų maitinimo paslaugų teikimas',
  563000: 'Gėrimų pardavimo vartoti vietoje veikla',
  591400: 'Kino filmų rodymas',
  592000: 'Garso įrašymas ir muzikos įrašų leidyba ',
  742000: 'Fotografavimo veikla',
  771220: 'Turistinių autobusų nuoma',
  772000: 'Asmeninių ir namų ūkio prekių nuoma ir išperkamoji nuoma',
  772100: 'Poilsio ir sporto reikmenų nuoma ir išperkamoji nuoma',
  772110: 'Pramoginių valčių nuoma',
  772120: 'Jojamųjų žirgų nuoma',
  772130: 'Dviračių nuoma',
  772200: 'Vaizdajuosčių ir kompaktinių diskų nuoma',
  772900: 'Kitų asmeninių ir namų ūkio prekių nuoma ir išperkamoji nuoma',
  772910: 'Tekstilės dirbinių, drabužių ir avalynės nuoma',
  772920: 'Baldų ir kitų asmeniniam naudojimui skirtų daiktų nuoma',
  772930: 'Muzikos instrumentų, teatro dekoracijų ir kostiumų nuoma',
  773400: 'Vandens transporto priemonių ir įrangos nuoma ir išperkamoji nuoma',
  773900:
    'Kitų, niekur kitur nepriskirtų, mašinų, įrangos ir materialiųjų vertybių nuoma ir išperkamoji nuoma ',
  773920: 'Motociklų nuoma ',
  773930: 'Automobilinių namelių nuoma ',
  772140: 'Sporto įrangos nuoma',
  772150: 'Kitų turizmo priemonių nuoma',
  790000:
    'Kelionių agentūrų, ekskursijų organizatorių, išankstinio užsakymo paslaugų ir susijusi veikla',
  791000: 'Kelionių agentūrų ir ekskursijų organizatorių veikla',
  791100: 'Kelionių agentūrų veikla',
  791200: 'Ekskursijų organizatorių veikla',
  799000: 'Kitų išankstinio užsakymo ir susijusių paslaugų veikla',
  821900:
    'Fotokopijavimo, dokumentų rengimo ir kita specializuota įstaigai būdingų paslaugų veikla ',
  823000: 'Posėdžių ir verslo renginių organizavimas',
  850000: 'Švietimas',
  851000: 'Ikimokyklinis ugdymas',
  851010: 'Ikimokyklinio amžiaus vaikų ugdymas',
  851020: 'Priešmokyklinio amžiaus vaikų ugdymas',
  855000: 'Kitas mokymas',
  855100: 'Sportinis ir rekreacinis švietimas',
  855200: 'Kultūrinis švietimas',
  855300: 'Vairavimo mokyklų veikla',
  855900: 'Kitas, niekur kitur nepriskirtas, švietimas',
  860000: 'Žmonių sveikatos priežiūros veikla',
  861000: 'Ligoninių veikla',
  861020: 'Specializuotųjų ligoninių veikla',
  862000: 'Medicininės ir odontologinės praktikos veikla',
  862100: 'Bendrosios praktikos gydytojų veikla',
  862200: 'Gydytojų specialistų veikla',
  862300: 'Odontologinės praktikos veikla',
  869000: 'Kita žmonių sveikatos priežiūros veikla',
  869010:
    'Viduriniojo medicinos personalo paslaugų teikiamas ligoniams ne ligoninėse',
  889100: 'Vaikų dienos priežiūros veikla',
  900000: 'Kūrybinė, meninė ir pramogų organizavimo veikla',
  900100: 'Scenos pastatymų veikla',
  900200: 'Scenos pastatymams būdingų paslaugų veikla',
  900300: 'Meninė kūryba',
  900400: 'Meno įrenginių eksploatavimo veikla',
  910000: 'Bibliotekų, archyvų, muziejų ir kita kultūrinė veikla ',
  910100: 'Bibliotekų ir archyvų veikla ',
  910200: 'Muziejų veikla',
  910300:
    'Istorinių vietų ir pastatų bei panašių turistų lankomų vietų eksploatavimas',
  910400: 'Botanikos ir zoologijos sodų bei gamtos rezervatų veikla',
  910410: 'Zoologijos sodų veikla',
  910420: 'Botanikos sodų veikla',
  930000: 'Sportinė veikla, pramogų ir poilsio organizavimo veikla',
  931000: 'Sportinė veikla',
  931100: 'Sporto įrenginių eksploatavimas',
  931200: 'Sporto klubų veikla',
  931300: 'Kūno rengybos centrų veikla',
  931900: 'Kita sportinė veikla',
  931920: 'Sportinė ar mėgėjų žvejyba bei su ja susijusi veikla ',
  932000: 'Pramogų ir poilsio organizavimo veikla',
  932100: 'Atrakcionų ir teminių parkų veikla',
  932900: 'Kita pramogų ir poilsio organizavimo veikla',
  950000: 'Kompiuterių ir asmeninių bei namų ūkio reikmenų taisymas',
  951000: 'Kompiuterių ir ryšių įrangos remontas',
  951100: 'Kompiuterių ir išorinės įrangos remontas',
  951200: 'Ryšių įrangos remontas',
  951210: 'Ryšio sistemų techninė priežiūra',
  952000: 'Asmeninių ir namų ūkio reikmenų taisymas',
  952100: 'Vartotojiškos elektroninės įrangos remontas',
  952200: 'Buitinių aparatų ir prietaisų, namų bei sodo įrangos remontas',
  952300: 'Avalynės ir odos gaminių taisymas',
  952400: 'Baldų ir interjero reikmenų taisymas',
  952500: 'Įvairių tipų laikrodžių ir juvelyrinių dirbinių taisymas',
  952900: 'Kitų asmeninių ir namų ūkio reikmenų taisymas',
  960000: 'Kita asmenų aptarnavimo veikla',
  960100: 'Tekstilės ir kailių gaminių skalbimas ir (sausasis) valymas',
  960200: 'Kirpyklų ir kitų grožio salonų veikla',
  960400: 'Fizinės gerovės užtikrinimo veikla',
  960900: 'Kita, niekur kitur nepriskirta, asmenų aptarnavimo veikla',
  493900: 'Kitas, niekur kitur nepriskirtas, keleivinis sausumos transportas',
};

export const cSectorEvrk = [
  100000, 101000, 101100, 101200, 101300, 101310, 102000, 103000, 103100,
  103110, 103200, 103900, 104000, 104100, 104200, 105000, 105100, 105200,
  106000, 106100, 106110, 106120, 106200, 106210, 106220, 107000, 107100,
  107200, 107300, 108000, 108100, 108200, 108300, 108400, 108410, 108500,
  108600, 108900, 108910, 109000, 109100, 109200, 110000, 110100, 110200,
  110300, 110400, 110500, 110600, 110700, 110710, 110720, 120000, 130000,
  131000, 132000, 132010, 132020, 133000, 139000, 139100, 139200, 139300,
  139400, 139500, 139600, 139900, 140000, 141000, 141100, 141200, 141300,
  141310, 141320, 141400, 141900, 141910, 141920, 141930, 142000, 143000,
  143100, 143900, 150000, 151000, 151100, 151110, 151120, 151130, 151200,
  152000, 160000, 161000, 161010, 162000, 162100, 162110, 162120, 162200,
  162300, 162310, 162320, 162330, 162400, 162410, 162900, 170000, 171000,
  171100, 171200, 172000, 172100, 172200, 172210, 172300, 172310, 172320,
  172400, 172900, 172910, 180000, 181000, 181100, 181200, 181300, 181400,
  182000, 182000, 190000, 191000, 192000, 192010, 192020, 192030, 192040,
  200000, 201000, 201100, 201200, 201300, 201400, 201500, 201510, 201520,
  201530, 201600, 201700, 202000, 202010, 203000, 203000, 203010, 203020,
  204000, 204100, 204200, 205000, 205100, 205200, 205300, 205900, 205910,
  205920, 205930, 205940, 206000, 210000, 211010, 212000, 212010, 212020,
  220000, 221000, 221100, 221900, 222000, 222100, 222200, 222300, 222900,
  230000, 231000, 231100, 231200, 231300, 231310, 231400, 231900, 231910,
  232000, 232000, 233000, 233100, 233110, 233120, 233200, 234000, 234100,
  234200, 234300, 234400, 234900, 235000, 235100, 235200, 236000, 236100,
  236200, 236300, 236400, 236500, 236900, 237000, 239000, 239100, 239900,
  239910, 239920, 239930, 239940, 240000, 241000, 242000, 243000, 243100,
  243200, 243300, 243400, 244000, 244100, 244200, 244300, 244400, 244500,
  244600, 245000, 245100, 245200, 245300, 245400, 250000, 251000, 251100,
  251200, 252000, 252100, 252900, 252910, 253000, 254000, 255000, 256000,
  256100, 256200, 257000, 257100, 257200, 257300, 259000, 259100, 259200,
  259300, 259400, 259900, 260000, 261000, 261100, 261200, 262000, 263000,
  264000, 265000, 265100, 265110, 265120, 265130, 265140, 265200, 266000,
  267000, 268000, 270000, 271000, 271100, 271200, 272000, 273000, 273100,
  273200, 273300, 274000, 275000, 275100, 275200, 279000, 280000, 281000,
  281100, 281200, 281300, 281400, 281500, 282000, 282100, 282200, 282300,
  282400, 282500, 282900, 283000, 284000, 284100, 284900, 289000, 289100,
  289200, 289300, 289400, 289500, 289600, 289900, 290000, 291000, 292000,
  293000, 293100, 293200, 300000, 301000, 301100, 301200, 302000, 303000,
  304000, 309000, 309100, 309200, 309900, 310000, 310100, 310200, 310300,
  310900, 320000, 321000, 321100, 321200, 321300, 322000, 323000, 324000,
  324010, 324020, 324030, 324040, 324050, 324060, 324070, 325000, 325010,
  325020, 325030, 329000, 329100, 329900, 329910, 330000, 331000, 331100,
  331200, 331300, 331400, 331500, 331600, 331700, 331900, 332000,
];

export const supportedActivity = [
  451000, 451100, 451900, 453000, 453200, 454000, 470000, 471000, 471900,
  472600, 474000, 474100, 474200, 474300, 475000, 475100, 475200, 475210,
  475220, 475230, 475240, 475300, 475400, 475900, 476000, 476100, 476200,
  476300, 476400, 476500, 477000, 477100, 477200, 477400, 477500, 477600,
  477700, 477800, 477810, 477820, 477890, 477900, 477910, 478000, 478200,
  478900, 479000, 479900, 479910, 479920, 493000, 493100, 493110, 501000,
  511000, 550000, 551000, 552000, 552010, 552020, 553000, 559000, 560000,
  561000, 562000, 562100, 562900, 563000, 591400, 592000, 742000, 771220,
  772000, 772100, 772110, 772120, 772130, 772200, 772900, 772910, 772920,
  772930, 773400, 773900, 773920, 773930, 772140, 772150, 790000, 791000,
  791100, 791200, 799000, 821900, 823000, 850000, 851000, 851010, 851020,
  855000, 855100, 855200, 855300, 855900, 860000, 861000, 861020, 862000,
  862100, 862200, 862300, 869000, 869010, 889100, 900000, 900100, 900200,
  900300, 900400, 910000, 910100, 910200, 910300, 910400, 910410, 910420,
  930000, 931000, 931100, 931200, 931300, 931900, 931920, 932000, 932100,
  932900, 950000, 951000, 951100, 951200, 951210, 952000, 952100, 952200,
  952300, 952400, 952500, 952900, 960000, 960100, 960200, 960400, 960900,
  493900,
];

export const evrkFull = {
  '010000':
    'Augalininkystė ir gyvulininkystė, medžioklė ir susijusių paslaugų veikla',
  '011000': 'Vienmečių augalų auginimas',
  '011100':
    'Grūdinių (išskyrus ryžius), ankštinių ir aliejingų sėklų augalų auginimas',
  '011200': 'Ryžių auginimas',
  '011300': 'Daržovių ir melionų, šakniavaisių ir gumbavaisių auginimas',
  '011310': 'Bulvių auginimas',
  '011320': 'Cukrinių runkelių auginimas',
  '011330': 'Daržovių auginimas atvirame grunte',
  '011340': 'Daržovių auginimas uždarame grunte',
  '011350': 'Daržovių sėklų gavyba',
  '011360': 'Grybų ir valgomųjų trumų auginimas',
  '011400': 'Cukranendrių auginimas',
  '011500': 'Tabako auginimas',
  '011600': 'Pluoštinių augalų auginimas',
  '011900': 'Kitų vienmečių augalų auginimas',
  '011910': 'Gėlių auginimas',
  '012000': 'Daugiamečių augalų auginimas',
  '012100': 'Vynuogių auginimas',
  '012200': 'Tropinių ir subtropinių vaisių ir uogų auginimas',
  '012300': 'Citrusų vaisių auginimas',
  '012400': 'Sėklavaisių ir kaulavaisių auginimas',
  '012500': 'Kitų vaismedžių, uogakrūmių ir riešutmedžių vaisių auginimas',
  '012600': 'Aliejinių augalų vaisių ir uogų auginimas',
  '012700': 'Gėrimams gaminti vartojamų augalų auginimas',
  '012800':
    'Prieskoninių, kvapiųjų, vaistinių ir kitokių farmacijos pramonėje naudojamų augalų auginimas',
  '012900': 'Kitų daugiamečių augalų auginimas',
  '013000': 'Augalų dauginimas',
  '014000': 'Gyvulininkystė',
  '014100': 'Pieninių galvijų auginimas',
  '014200': 'Kitų galvijų ir buivolų auginimas',
  '014210': 'Galvijų auginimas ir penėjimas mėsai',
  '014220': 'Veislinių telyčių auginimas',
  '014230': 'Veislinių bulių auginimas',
  '014240': 'Veislinių bulių spermos gavyba',
  '014300': 'Arklių ir kitų kanopinių gyvūnų auginimas',
  '014400': 'Kupranugarių ir Camelidae šeimos gyvūnų auginimas',
  '014500': 'Avių ir ožkų auginimas',
  '014510': 'Avių auginimas',
  '014520': 'Ožkų auginimas',
  '014600': 'Kiaulių auginimas',
  '014610': 'Kiaulių auginimas ir penėjimas mėsai',
  '014620': 'Veislinių kiaulių auginimas',
  '014700': 'Naminių paukščių auginimas',
  '014710': 'Naminių paukščių auginimas mėsai ir kiaušinių gavybai',
  '014720': 'Veislinių naminių paukščių auginimas',
  '014900': 'Kitų gyvūnų auginimas',
  '014910': 'Švelniakailių žvėrelių auginimas',
  '014920': 'Bitininkystė',
  '014930': 'Vynuoginių sraigių ir sliekų auginimas',
  '015000': 'Mišrusis žemės ūkis',
  '016000': 'Žemės ūkiui būdingų paslaugų ir derliaus apdorojimo veikla',
  '016100': 'Augalininkystei būdingų paslaugų veikla',
  '016110':
    'Augalų apsaugos nuo ligų, kenkėjų ir piktžolių žemės ūkio paslaugų veikla',
  '016120': 'Žemės ūkio augalų tręšimas mineralinėmis ir organinėmis trąšomis',
  '016200': 'Gyvulininkystei būdingų paslaugų veikla',
  '016210': 'Dirbtinio apsėklinimo veikla',
  '016300': 'Derliaus apdorojimo veikla',
  '016400': 'Sėklininkystė',
  '017000': 'Medžioklė, gaudymas spąstais ir susijusių paslaugų veikla',
  '020000': 'Miškininkystė ir medienos ruoša',
  '021000': 'Miško medžių auginimas ir kita miškininkystės veikla',
  '022000': 'Medienos ruoša',
  '023000': 'Laukinių nemedieninių medžių produktų rinkimas',
  '023000': 'Laukinių nemedieninių produktų rinkimas',
  '023010': 'Miško grybų rinkimas',
  '023020': 'Riešutų ir miško uogų rinkimas',
  '024000': 'Miškininkystei būdingų paslaugų veikla',
  '030000': 'Žvejyba ir akvakultūra',
  '031000': 'Žvejyba',
  '031100': 'Jūrinė žvejyba',
  '031110': 'Žuvų perdirbimas žvejybiniuose laivuose',
  '031200': 'Gėlųjų vandenų žvejyba',
  '032000': 'Akvakultūra',
  '032100': 'Jūrinė akvakultūra',
  '032200': 'Gėlųjų vandenų akvakultūra',
  '050000': 'Akmens anglių ir rusvųjų anglių kasyba',
  '051000': 'Juodųjų akmens anglių (antracito) kasyba',
  '052000': 'Rusvųjų anglių kasyba',
  '060000': 'Žalios naftos ir gamtinių dujų gavyba',
  '061000': 'Žalios naftos gavyba',
  '062000': 'Gamtinių dujų gavyba',
  '070000': 'Metalų rūdų kasyba',
  '071000': 'Geležies rūdų kasyba',
  '072000': 'Spalvotųjų metalų rūdų kasyba',
  '072100': 'Urano ir torio rūdų kasyba',
  '072900': 'Kitų spalvotųjų metalų rūdų kasyba',
  '080000': 'Kita kasyba ir karjerų eksploatavimas',
  '081000': 'Akmens, smėlio ir molio karjerų eksploatavimas',
  '081100':
    'Dekoratyvinio ir statybinio akmens, klinčių, gipso, kreidos ir skalūno karjerų eksploatavimas',
  '081200': 'Smėlio ir žvyro karjerų eksploatavimas; molio ir kaolino kasyba',
  '089000': 'Niekur kitur nepriskirta kasyba ir karjerų eksploatavimas',
  '089100': 'Mineralinių iškasenų chemijos pramonei ir trąšoms gaminti kasyba',
  '089200': 'Durpių gavyba',
  '089300': 'Druskos gavyba',
  '089900': 'Kita, niekur kitur nepriskirta, kasyba ir karjerų eksploatavimas',
  '090000': 'Kasybai būdingų paslaugų veikla',
  '091000': 'Naftos ir gamtinių dujų gavybai būdingų paslaugų veikla',
  '091010': 'Gamtinių dujų parengimas transportuoti (gabenti)',
  '099000': 'Kitai kasybai ir karjerų eksploatavimui būdingų paslaugų veikla',
  100000: 'Maisto produktų gamyba',
  101000: 'Mėsos perdirbimas ir konservavimas ir mėsos produktų gamyba',
  101100: 'Mėsos perdirbimas ir konservavimas',
  101200: 'Paukštienos perdirbimas ir konservavimas',
  101300: 'Mėsos ir paukštienos produktų gamyba',
  101310: 'Rūkytų mėsos ir paukštienos produktų gamyba',
  102000: 'Žuvų, vėžiagyvių ir moliuskų perdirbimas ir konservavimas',
  103000: 'Vaisių ir daržovių perdirbimas ir konservavimas',
  103100: 'Bulvių perdirbimas ir konservavimas',
  103110: 'Bulvių miltų gamyba',
  103200: 'Vaisių, uogų ir daržovių sulčių gamyba',
  103900: 'Kitas vaisių ir daržovių perdirbimas ir konservavimas',
  104000: 'Gyvūninių ir augalinių riebalų bei aliejaus gamyba',
  104100: 'Aliejaus ir riebalų gamyba',
  104200: 'Margarino ir panašių valgomųjų riebalų gamyba',
  105000: 'Pieno produktų gamyba',
  105100: 'Pieninių veikla ir sūrių gamyba',
  105200: 'Valgomųjų ledų gamyba',
  106000: 'Grūdų malimo produktų, krakmolo ir krakmolo produktų gamyba',
  106100: 'Grūdų malimo produktų gamyba',
  106110: 'Miltų gamyba',
  106120: 'Kruopų gamyba',
  106200: 'Krakmolo ir krakmolo produktų gamyba',
  106210: 'Gliukozės sirupo ir maltozės gamyba',
  106220: 'Krakmolo gamyba',
  107000: 'Kepyklos ir miltinių produktų gamyba',
  107100: 'Duonos gamyba; šviežių konditerijos kepinių ir pyragaičių gamyba',
  107200:
    'Džiūvėsių ir sausainių gamyba; ilgai išsilaikančių konditerijos kepinių ir pyragaičių gamyba',
  107300: 'Makaronų, vermišelių, kuskuso ir panašių miltinių produktų gamyba',
  108000: 'Kitų maisto produktų gamyba',
  108100: 'Cukraus gamyba',
  108200: 'Kakavos, šokolado ir cukraus saldumynų gamyba',
  108300: 'Arbatos ir kavos apdorojimas ir perdirbimas',
  108400: 'Užgardų ir pagardų gamyba',
  108410: 'Acto gamyba',
  108500: 'Paruoštų valgių ir patiekalų gamyba',
  108600: 'Homogenizuotų ir dietinių maisto produktų gamyba',
  108900: 'Kitų, niekur kitur nepriskirtų, maisto produktų gamyba',
  108910: 'Mielių gamyba',
  109000: 'Paruoštų pašarų gyvuliams gamyba',
  109100: 'Paruoštų pašarų ūkio gyvuliams gamyba',
  109200: 'Paruošto ėdalo naminiams gyvūnėliams gamyba',
  110000: 'Gėrimų gamyba',
  110100: 'Spirito distiliavimas, rektifikavimas ir maišymas',
  110200: 'Vynuogių vyno gamyba',
  110300: 'Sidro ir kitokių vaisių, uogų vynų gamyba',
  110400: 'Kitų nedistiliuotų fermentuotų gėrimų gamyba',
  110500: 'Alaus gamyba',
  110600: 'Salyklo gamyba',
  110700:
    'Nealkoholinių gėrimų gamyba; mineralinio ir kito, pilstomo į butelius, vandens gamyba',
  110710: 'Nealkoholinių gėrimų gamyba',
  110720: 'Natūralaus mineralinio ir kito, pilstomo į butelius, vandens gamyba',
  120000: 'Tabako gaminių gamyba',
  130000: 'Tekstilės gaminių gamyba',
  131000: 'Tekstilės pluoštų paruošimas ir verpimas',
  132000: 'Tekstilės audimas',
  132010: 'Lininių audinių audimas',
  132020: 'Sintetinių audinių audimas',
  133000: 'Tekstilės apdaila',
  139000: 'Kitų tekstilės gaminių ir dirbinių gamyba',
  139100: 'Megztų (trikotažinių) ir nertų medžiagų gamyba',
  139200: 'Gatavų tekstilės dirbinių, išskyrus drabužius, gamyba',
  139300: 'Kilimų ir kilimėlių gamyba',
  139400: 'Virvių, lynų, virvelių ir tinklų gamyba',
  139500:
    'Neaustinių medžiagų ir neaustinių medžiagų gaminių ir dirbinių, išskyrus drabužius, gamyba',
  139600: 'Kitų techninės ir pramoninės tekstilės gaminių ir dirbinių gamyba',
  139900:
    'Kitų, niekur kitur nepriskirtų, tekstilės gaminių ir dirbinių gamyba',
  140000: 'Drabužių siuvimas (gamyba)',
  141000: 'Drabužių, išskyrus kailinius drabužius, siuvimas (gamyba)',
  141100: 'Odinių drabužių siuvimas',
  141200: 'Darbo drabužių siuvimas',
  141300: 'Kitų viršutinių drabužių siuvimas',
  141310: 'Vyriškų viršutinių drabužių siuvimas',
  141320: 'Moteriškų viršutinių drabužių siuvimas',
  141400: 'Apatinių drabužių siuvimas',
  141900: 'Kitų drabužių siuvimas ir drabužių priedų gamyba',
  141910: 'Vaiko drabužėlių siuvimas',
  141920: 'Sportinės aprangos siuvimas',
  141930: 'Kailinių kepurių ir kitų galvos apdangalų  gamyba',
  142000: 'Kailinių dirbinių ir gaminių gamyba',
  143000: 'Megztų (trikotažinių) ir nertų drabužių gamyba',
  143100:
    'Pėdkelnių, triko, kojinių ir kitų panašių megztų (trikotažinių) ir nertų gaminių bei dirbinių gamyba',
  143900: 'Kitų megztų (trikotažinių) ir nertų drabužių gamyba',
  150000: 'Odos ir odos dirbinių gamyba',
  151000:
    'Odų rauginimas ir išdirbimas; lagaminų, rankinių, balno reikmenų ir pakinktų gamyba; kailių išdirbimas ir dažymas',
  151100: 'Odų rauginimas ir išdirbimas; kailių išdirbimas ir dažymas',
  151110: 'Odų rauginimas, naudojant augalines medžiagas',
  151120: 'Odų rauginimas, naudojant mineralines ir chemines medžiagas',
  151130: 'Odų išdirbimas',
  151200:
    'Lagaminų, rankinių ir panašių reikmenų, balno reikmenų ir pakinktų gamyba',
  152000: 'Avalynės gamyba',
  160000:
    'Medienos bei medienos ir kamštienos gaminių, išskyrus baldus, gamyba; gaminių iš šiaudų ir pynimo medžiagų gamyba',
  161000: 'Medienos pjaustymas ir obliavimas',
  161010: 'Cheminis medienos apdorojimas konservantais',
  162000:
    'Gaminių ir dirbinių iš medienos, kamštienos, šiaudų ir pynimo medžiagų gamyba',
  162100: 'Faneravimo dangos ir medienos plokščių gamyba',
  162110: 'Medienos plaušo plokščių, padengtų dekoratyvine plėvele, gamyba',
  162120: 'Presuotos medienos gamyba',
  162200: 'Sumontuotų parketo grindų gamyba',
  162300: 'Kitų statybinių dailidžių ir stalių dirbinių gamyba',
  162310: 'Fasoninių detalių ir malksnų (gontų) stogų dengimui gamyba',
  162320: 'Medinių surenkamųjų statinių ar jų detalių gamyba',
  162330: 'Medinių sieninių plokščių ir spintų gamyba',
  162400: 'Medinės taros gamyba',
  162410: 'Padėklų, stovų ir kitų medinių pakrovimo reikmenų gamyba',
  162900:
    'Kitų medienos gaminių gamyba; dirbinių iš kamštienos, šiaudų ir pynimo medžiagų gamyba',
  170000: 'Popieriaus ir popieriaus gaminių gamyba',
  171000: 'Plaušienos, popieriaus ir kartono gamyba',
  171100: 'Plaušienos gamyba',
  171200: 'Popieriaus ir kartono gamyba',
  172000: 'Popieriaus ir kartoninių gaminių gamyba',
  172100:
    'Gofruotojo popieriaus ir kartono bei taros iš popieriaus ir kartono gamyba',
  172200: 'Buitinių ir higienos reikmenų bei tualeto reikmenų gamyba',
  172210: 'Tualetinio popieriaus gamyba',
  172300: 'Popierinių raštinės reikmenų gamyba',
  172310: 'Kopijavimo popieriaus gamyba',
  172320: 'Vokų ir atvirukų gamyba',
  172400: 'Sienų apmušalų (tapetų) gamyba',
  172900: 'Kitų popierinių ir kartoninių gaminių gamyba',
  172910: 'Filtravimo popieriaus gamyba',
  180000: 'Spausdinimas ir įrašytų laikmenų tiražavimas',
  181000: 'Spausdinimas ir su spausdinimu susijusios paslaugos',
  181100: 'Laikraščių spausdinimas',
  181200: 'Kitas spausdinimas',
  181300: 'Parengiamoji spausdinimo ir žiniasklaidos veikla',
  181400: 'Įrišimas ir susijusios paslaugos',
  182000: 'Įrašytų laikmenų tiražavimas',
  190000: 'Kokso ir rafinuotų naftos produktų gamyba',
  191000: 'Koksavimo krosnių produktų gamyba',
  192000: 'Rafinuotų naftos produktų gamyba',
  192010: 'Skystojo arba dujinio kuro gamyba',
  192020: 'Tepalų ir kitų alyvų gamyba',
  192030: 'Parafino ir techninio vazelino  gamyba',
  192040: 'Naftos bitumo gamyba',
  200000: 'Chemikalų ir chemijos produktų gamyba',
  201000:
    'Pagrindinių chemikalų, trąšų ir azoto junginių, pirminių plastikų ir pirminio sintetinio kaučiuko gamyba',
  201100: 'Pramoninių dujų gamyba',
  201200: 'Dažiklių ir pigmentų gamyba',
  201300: 'Kitų pagrindinių neorganinių chemikalų gamyba',
  201400: 'Kitų pagrindinių organinių chemikalų gamyba',
  201500: 'Trąšų ir azoto junginių gamyba',
  201510: 'Grynųjų ar kompleksinių azoto ir fosforo trąšų gamyba',
  201520: 'Karbamido gamyba',
  201530: 'Amoniako gamyba',
  201600: 'Pirminių plastikų gamyba',
  201700: 'Pirminio sintetinio kaučiuko gamyba',
  202000: 'Pesticidų ir kitų agrocheminių medžiagų gamyba',
  202010: 'Dezinfekantų gamyba',
  203000:
    'Dažų, lakų ir panašių dangų medžiagų, spaustuvinių dažų ir mastikų gamyba',
  203010: 'Emalinių dažų ir lakų gamyba',
  203020: 'Organinių sudėtingų tirpiklių ir skiediklių gamyba',
  204000:
    'Muilo ir ploviklių, valiklių ir blizgiklių, kvepalų ir tualeto priemonių gamyba',
  204100: 'Muilo ir ploviklių, valiklių ir blizgiklių gamyba',
  204200: 'Kvepalų ir tualeto priemonių gamyba',
  205000: 'Kitų cheminių medžiagų gamyba',
  205100: 'Sprogiųjų medžiagų gamyba',
  205200: 'Klijų gamyba',
  205300: 'Eterinio aliejaus gamyba',
  205900: 'Kitų, niekur kitur nepriskirtų, cheminių medžiagų gamyba',
  205910: 'Lydymo, litavimo ar suvirinimo miltelių ir pastų gamyba',
  205920:
    'Aktyvintų anglių, tepalų priedų, paruoštų kaučiuko vulkanizavimo greitiklių, katalizatorių gamyba',
  205930: 'Antidetonatorių, antifrizų, skysčių hidraulinėms sistemoms gamyba',
  205940: 'Rašalo ir tušo gamyba',
  206000: 'Cheminių pluoštų gamyba',
  210000: 'Pagrindinių vaistų pramonės gaminių ir farmacinių preparatų gamyba',
  211000: 'Pagrindinių vaistų pramonės gaminių gamyba',
  211010: 'Narkotinių medžiagų gamyba',
  212000: 'Farmacinių preparatų gamyba',
  212010: 'Vaistų gamyba',
  212020: 'Veterinarinių vaistų gamyba',
  220000: 'Guminių ir plastikinių gaminių gamyba',
  221000: 'Guminių gaminių gamyba',
  221100:
    'Guminių padangų ir kamerų gamyba; guminių padangų restauravimas ir atnaujinimas',
  221900: 'Kitų guminių gaminių gamyba',
  222000: 'Plastikinių gaminių gamyba',
  222100: 'Plastikinių plokščių, lakštų, vamzdžių ir profiliuočių gamyba',
  222200: 'Plastikinių pakuočių gamyba',
  222300: 'Plastikinių statybos dirbinių gamyba',
  222900: 'Kitų plastikinių gaminių gamyba',
  230000: 'Kitų nemetalo mineralinių produktų gamyba',
  231000: 'Stiklo ir stiklo gaminių bei dirbinių gamyba',
  231100: 'Plokščiojo stiklo gamyba',
  231200: 'Plokščiojo stiklo pjaustymas ir apdorojimas',
  231300: 'Tuščiavidurio stiklo gamyba',
  231310: 'Stiklinių talpyklų gamyba',
  231400: 'Stiklo pluoštų gamyba',
  231900:
    'Kito stiklo, įskaitant skirto techninėms reikmėms, gamyba ir apdorojimas',
  231910:
    'Stiklinių izoliatorių ir stiklinių izoliacinių jungiamųjų detalių gamyba',
  232000: 'Ugniai atsparių gaminių gamyba',
  233000: 'Statybinių medžiagų iš molio gamyba',
  233100: 'Keraminių apdailos ir šaligatvio plytelių gamyba',
  233110: 'Koklių gamyba',
  233120: 'Keraminių grindinio dangų gamyba',
  233200: 'Degto molio plytų, plytelių ir statybinių dirbinių gamyba',
  234000: 'Kitų porcelianinių ir keraminių gaminių bei dirbinių gamyba',
  234100: 'Keraminių buities ir puošybos gaminių bei dirbinių gamyba',
  234200: 'Keraminių santechnikos gaminių ir dirbinių gamyba',
  234300: 'Keraminių izoliatorių ir keraminių izoliacinių detalių gamyba',
  234400: 'Kitų techninės paskirties keraminių gaminių ir dirbinių gamyba',
  234900: 'Kitų keraminių gaminių ir dirbinių gamyba',
  235000: 'Cemento, kalkių ir gipso gamyba',
  235100: 'Cemento gamyba',
  235200: 'Kalkių ir gipso gamyba',
  236000: 'Betono, cemento ir gipso gaminių bei dirbinių gamyba',
  236100: 'Betono gaminių, skirtų statybinėms reikmėms, gamyba',
  236200: 'Gipso gaminių, naudojamų statybinėms reikmėms, gamyba',
  236300: 'Prekinio betono mišinio gamyba',
  236400: 'Statybinių skiedinių gamyba',
  236500: 'Fibrolito gamyba',
  236900: 'Kitų betono, gipso ir cemento gaminių bei dirbinių gamyba',
  237000: 'Akmens pjaustymas, tašymas ir apdailinimas',
  239000:
    'Abrazyvinių gaminių ir niekur kitur nepriskirtų nemetalo mineralinių produktų gamyba',
  239100: 'Abrazyvinių gaminių gamyba',
  239900:
    'Kitų, niekur kitur nepriskirtų, nemetalo mineralinių produktų gamyba',
  239910: 'Asbestinio pluošto, verpalų ir audinių bei gaminių iš jų gamyba',
  239920: 'Frikcinių medžiagų ir jų gaminių gamyba',
  239930: 'Mineralinių izoliacinių medžiagų gamyba',
  239940: 'Dirbinių iš bitumo gamyba',
  240000: 'Pagrindinių metalų gamyba',
  241000: 'Tomo ketaus ir plieno bei ferolydinių gamyba',
  242000:
    'Plieninių vamzdžių, vamzdelių, tuščiavidurių profilių ir susijusių jungiamųjų detalių gamyba',
  243000: 'Kitų plieno pirminio apdirbimo gaminių gamyba',
  243100: 'Šaltasis strypų tempimas',
  243200: 'Šaltasis siaurų juostų valcavimas',
  243300: 'Šaltasis formavimas ar lankstymas',
  243400: 'Šaltasis vielos tempimas',
  244000: 'Pagrindinių tauriųjų ir kitų spalvotųjų metalų gamyba',
  244100: 'Tauriųjų metalų gamyba',
  244200: 'Aliuminio gamyba',
  244300: 'Švino, cinko ir alavo gamyba',
  244400: 'Vario gamyba',
  244500: 'Kitų spalvotųjų metalų gamyba',
  244600: 'Branduolinio kuro perdirbimas',
  245000: 'Metalų liejinių gamyba',
  245100: 'Geležies liejinių gamyba',
  245200: 'Plieno liejinių gamyba',
  245300: 'Lengvųjų metalų liejinių gamyba',
  245400: 'Kitų spalvotųjų metalų liejinių gamyba',
  250000: 'Metalo gaminių, išskyrus mašinas ir įrenginius, gamyba',
  251000: 'Konstrukcinių metalo gaminių gamyba',
  251100: 'Metalo konstrukcijų ir jų dalių gamyba',
  251200: 'Metalinių durų ir langų gamyba',
  252000: 'Metalinių cisternų, rezervuarų ir talpyklų gamyba',
  252100: 'Centrinio šildymo radiatorių ir katilų gamyba',
  252900: 'Kitų metalinių cisternų, rezervuarų ir talpyklų gamyba',
  252910: 'Metalinių talpyklų suslėgtosioms ar suskystintosioms dujoms gamyba',
  253000:
    'Garo generatorių, išskyrus centrinio šildymo karšto vandens katilus, gamyba',
  254000: 'Ginklų ir šaudmenų gamyba',
  255000:
    'Metalo kalimas, presavimas, štampavimas ir profiliavimas; miltelių metalurgija',
  256000: 'Metalų apdorojimas ir dengimas; mechaninis apdirbimas',
  256100: 'Metalų apdorojimas ir dengimas',
  256200: 'Mechaninis apdirbimas',
  257000: 'Valgomųjų įrankių, kitų įrankių ir bendrųjų metalo dirbinių gamyba',
  257100: 'Valgomųjų ir kitų pjovimo įrankių gamyba',
  257200: 'Spynų ir vyrių gamyba',
  257300: 'Kitų įrankių gamyba',
  259000: 'Kitų metalo gaminių gamyba',
  259100: 'Plieninių statinių ir panašių talpyklų gamyba',
  259200: 'Lengvųjų metalų taros gamyba',
  259300: 'Vielos gaminių, grandinių ir spyruoklių gamyba',
  259400: 'Tvirtinimo detalių ir sriegimo mašinų gaminių gamyba',
  259900: 'Kitų, niekur kitur nepriskirtų, metalo gaminių gamyba',
  260000: 'Kompiuterinių, elektroninių ir optinių gaminių gamyba',
  261000: 'Elektroninių komponentų ir plokščių gamyba',
  261100: 'Elektroninių komponentų gamyba',
  261200: 'Sumontuotų elektroninių plokščių gamyba',
  262000: 'Kompiuterių ir išorinės įrangos gamyba',
  263000: 'Ryšių įrangos gamyba',
  264000: 'Vartotojiškos elektroninės įrangos gamyba',
  265000:
    'Matavimo, bandymo, navigacinės ir kontrolės įrangos gamyba; įvairių tipų laikrodžių gamyba',
  265100:
    'Matavimo, bandymo, navigacinės ir kontrolės įrangos prietaisų ir aparatų gamyba',
  265110: 'Elektros skaitiklių gamyba',
  265120: 'Vandens skaitiklių gamyba',
  265130: 'Dujų skaitiklių gamyba',
  265140: 'Automatinio reguliavimo ir kontrolės prietaisų gamyba',
  265200: 'Įvairių tipų laikrodžių gamyba',
  266000: 'Švitinimo, elektromedicininės ir elektroterapinės įrangos gamyba',
  267000: 'Optinių prietaisų ir fotografijos įrangos gamyba',
  268000: 'Magnetinių ir optinių laikmenų gamyba',
  270000: 'Elektros įrangos gamyba',
  271000:
    'Elektros variklių, generatorių, transformatorių bei elektros skirstomosios ir valdymo įrangos gamyba',
  271100: 'Elektros variklių, generatorių ir transformatorių gamyba',
  271200: 'Elektros skirstomosios ir valdymo įrangos gamyba',
  272000: 'Baterijų ir akumuliatorių gamyba',
  273000: 'Laidų ir instaliacijos įtaisų gamyba',
  273100: 'Skaidulinės optikos kabelių gamyba',
  273200: 'Kitų elektronikos bei elektros laidų ir kabelių gamyba',
  273300: 'Instaliacijos įtaisų gamyba',
  274000: 'Elektros apšvietimo įrangos gamyba',
  275000: 'Buitinių aparatų ir prietaisų gamyba',
  275100: 'Buitinių elektrinių aparatų ir prietaisų gamyba',
  275200: 'Buitinių neelektrinių aparatų ir prietaisų gamyba',
  279000: 'Kitos elektros įrangos gamyba',
  280000: 'Niekur kitur nepriskirtų mašinų ir įrangos gamyba',
  281000: 'Bendrosios paskirties mašinų gamyba',
  281100:
    'Variklių ir turbinų, išskyrus orlaivių, transporto priemonių ir motociklų variklius, gamyba',
  281200: 'Hidraulinės energijos įrangos gamyba',
  281300: 'Kitų siurblių ir kompresorių gamyba',
  281400: 'Kitų čiaupų ir sklendžių gamyba',
  281500:
    'Guolių, krumpliaračių, krumplinių pavarų ir varomųjų elementų gamyba',
  282000: 'Kitų bendrosios paskirties mašinų ir įrangos gamyba',
  282100: 'Orkaičių, krosnių ir krosnių degiklių gamyba',
  282200: 'Kėlimo ir krovimo įrangos gamyba',
  282300:
    'Įstaigos mašinų ir įrangos (išskyrus kompiuterius ir išorinę įrangą) gamyba',
  282400: 'Variklinių rankinių įrankių gamyba',
  282500: 'Nebuitinių aušinimo ir vėdinimo įrenginių gamyba',
  282900:
    'Kitų, niekur kitur nepriskirtų, bendrosios paskirties mašinų ir įrangos gamyba',
  283000: 'Žemės ir miškų ūkio mašinų gamyba',
  284000: 'Metalo formavimo įrangos ir staklių gamyba',
  284100: 'Metalo formavimo įrangos gamyba',
  284900: 'Kitų staklių gamyba',
  289000: 'Kitų specialiosios paskirties mašinų gamyba',
  289100: 'Metalurgijos mašinų gamyba',
  289200: 'Kasybos, karjerų eksploatavimo ir statybos mašinų gamyba',
  289300: 'Maisto, gėrimų ir tabako apdorojimo mašinų gamyba',
  289400: 'Tekstilės, drabužių ir odos gaminių gamybos mašinų gamyba',
  289500: 'Popieriaus ir kartono gamybos mašinų gamyba',
  289600: 'Plastikų ir gumos gamybos mašinų gamyba',
  289900:
    'Kitų, niekur kitur nepriskirtų, specialiosios paskirties mašinų gamyba',
  290000: 'Variklinių transporto priemonių, priekabų ir puspriekabių gamyba',
  291000: 'Variklinių transporto priemonių gamyba',
  292000:
    'Variklinių transporto priemonių kėbulų gamyba; priekabų ir puspriekabių gamyba',
  293000: 'Variklinių transporto priemonių dalių ir pagalbinių reikmenų gamyba',
  293100:
    'Variklinių transporto priemonių elektros ir elektroninės įrangos gamyba',
  293200: 'Variklinių transporto priemonių kitų dalių ir reikmenų gamyba',
  300000: 'Kitų transporto priemonių ir įrangos gamyba',
  301000: 'Įvairių tipų laivų statyba',
  301100: 'Laivų ir plūdriųjų konstrukcijų statyba',
  301200: 'Pramoginių ir sportinių katerių (laivų) statyba',
  302000: 'Geležinkelio lokomotyvų bei riedmenų gamyba',
  303000: 'Orlaivių ir erdvėlaivių bei susijusios įrangos gamyba',
  304000: 'Karinių kovinių transporto priemonių gamyba',
  309000: 'Niekur kitur nepriskirtų transporto priemonių gamyba',
  309100: 'Motociklų gamyba',
  309200: 'Dviračių ir invalidų vežimėlių gamyba',
  309900: 'Kitos, niekur kitur nepriskirtos, transporto įrangos gamyba',
  310000: 'Baldų gamyba',
  310100: 'Įstaigos ir prekybos įmonių (parduotuvių) baldų gamyba',
  310200: 'Virtuvės baldų gamyba',
  310300: 'Čiužinių gamyba',
  310900: 'Kitų baldų gamyba',
  320000: 'Kita gamyba',
  321000: 'Papuošalų, juvelyrinių, bižuterijos ir panašių dirbinių gamyba',
  321100: 'Monetų kalimas',
  321200: 'Papuošalų, juvelyrinių ir panašių dirbinių gamyba',
  321300: 'Dirbtinės bižuterijos ir panašių dirbinių gamyba',
  322000: 'Muzikos instrumentų gamyba',
  323000: 'Sporto reikmenų gamyba',
  324000: 'Žaidimų ir žaislų gamyba',
  324010: 'Konstravimo rinkinių gamyba',
  324020: 'Plastikinių žaislų gamyba',
  324030: 'Medinių žaislų gamyba',
  324040: 'Kimštinių žaislų gamyba',
  324050: 'Stalo arba kambario žaidimų gamyba',
  324060: 'Plastikinių triračių ir kitų žaislų su ratukais gamyba',
  324070: 'Elektrinių žaislų ir žaidimų gamyba',
  325000:
    'Medicinos ir odontologijos prietaisų, instrumentų ir reikmenų gamyba',
  325010: 'Odontologinio cemento gamyba',
  325020: 'Ortopedinių ir kitokių įtaisų gamyba',
  325030: 'Protezų gamyba',
  329000: 'Niekur kitur nepriskirta gamyba',
  329100: 'Šluotų ir šepečių gamyba',
  329900: 'Kita, niekur kitur nepriskirta, gamyba',
  329910: 'Medinių karstų ir kitų laidojimo reikmenų gamyba',
  330000: 'Mašinų ir įrangos remontas ir įrengimas',
  331000: 'Metalo gaminių, mašinų ir įrangos remontas',
  331100: 'Metalo gaminių remontas',
  331200: 'Mašinų remontas',
  331300: 'Elektroninės ir optinės įrangos remontas',
  331400: 'Elektros įrangos remontas',
  331500: 'Įvairių tipų laivų remontas ir techninė priežiūra',
  331600: 'Orlaivių ir erdvėlaivių remontas ir techninė priežiūra',
  331700: 'Kitų transporto priemonių remontas ir techninė priežiūra',
  331900: 'Kitos įrangos remontas',
  332000: 'Pramoninių mašinų ir įrangos įrengimas',
  350000: 'Elektros, dujų, garo tiekimas ir oro kondicionavimas',
  351000: 'Elektros energijos gamyba, perdavimas ir paskirstymas',
  351100: 'Elektros gamyba',
  351200: 'Elektros perdavimas',
  351300: 'Elektros paskirstymas',
  351400: 'Elektros pardavimas',
  352000: 'Dujų gamyba; dujinio kuro paskirstymas dujotiekiais',
  352100: 'Dujų gamyba',
  352200: 'Dujinio kuro paskirstymas dujotiekiais',
  352300: 'Dujų pardavimas dujotiekiais',
  353000: 'Garo tiekimas ir oro kondicionavimas',
  353010: 'Garo tiekimas',
  353020: 'Karšto vandens tiekimas',
  360000: 'Vandens surinkimas, valymas ir tiekimas',
  360010: 'Vandens surinkimas ir valymas',
  360020: 'Vandens tiekimas pramonės įmonėms',
  360030: 'Vandens tiekimas gyventojams',
  370000: 'Nuotekų valymas',
  380000: 'Atliekų surinkimas, tvarkymas ir šalinimas; medžiagų atgavimas',
  381000: 'Atliekų surinkimas',
  381100: 'Nepavojingų atliekų surinkimas',
  381200: 'Pavojingų atliekų surinkimas',
  382000: 'Atliekų tvarkymas ir šalinimas',
  382100: 'Nepavojingų atliekų tvarkymas ir šalinimas',
  382200: 'Pavojingų atliekų tvarkymas ir šalinimas',
  383000: 'Medžiagų atgavimas',
  383100: 'Mašinų duženų išmontavimas',
  383200: 'Išrūšiuotų medžiagų atgavimas',
  390000: 'Regeneravimas ir kita atliekų tvarkyba',
  410000: 'Pastatų statyba',
  411000: 'Statybų plėtra',
  412000: 'Gyvenamųjų ir negyvenamųjų pastatų statyba',
  412010: 'Naujų pastatų statyba',
  412020: 'Pastatų remontas, restauravimas ir  rekonstravimas',
  420000: 'Inžinerinių statinių statyba',
  421000: 'Kelių ir geležinkelių tiesimas',
  421100: 'Kelių ir automagistralių tiesimas',
  421110: 'Kelio dangos ir stovėjimo aikštelių ženklinimas',
  421200: 'Geležinkelių ir požeminių geležinkelių tiesimas',
  421300: 'Tiltų ir tunelių statyba',
  422000: 'Komunalinių statinių statyba',
  422100: 'Komunalinių nuotekų statinių statyba',
  422200: 'Komunalinių elektros ir telekomunikacijos statinių statyba',
  429000: 'Kitų inžinerinių statinių statyba',
  429100: 'Vandens statinių statyba',
  429900: 'Kitų, niekur kitur nepriskirtų, inžinerinių statinių statyba',
  430000: 'Specializuota statybos veikla',
  431000: 'Statinių nugriovimas ir statybvietės paruošimas',
  431100: 'Statinių nugriovimas',
  431200: 'Statybvietės paruošimas',
  431210: 'Paruošiamieji žemės kasimo darbai tiesiant kelius',
  431220: 'Žemės darbai statybos aikštelėse',
  431230: 'Sausinimo ir drėkinimo sistemų tiesimas',
  431300: 'Žvalgomasis gręžimas',
  432000: 'Elektros, vandentiekio ir kitos įrangos įrengimas',
  432100: 'Elektros sistemų įrengimas',
  432110: 'Pastatų ir statinių elektros tinklų įrengimas',
  432120: 'Gaisro pavojaus ir apsaugos signalizacijos įrengimas',
  432130: 'Antenų, ryšio sistemų įrengimas',
  432200: 'Vandentiekio, šildymo ir oro kondicionavimo sistemų įrengimas',
  432900: 'Kitos įrangos įrengimas',
  433000: 'Statybos baigimas ir apdaila',
  433100: 'Tinkavimas',
  433200: 'Staliaus dirbinių įrengimas',
  433300: 'Grindų ir sienų dengimas',
  433400: 'Dažymas ir stiklinimas',
  433900: 'Kiti statybos baigiamieji ir apdailos darbai',
  439000: 'Kita specializuota statybos veikla',
  439100: 'Stogų dengimas',
  439900: 'Kita, niekur kitur nepriskirta, specializuota statybos veikla',
  450000:
    'Variklinių transporto priemonių ir motociklų didmeninė ir mažmeninė prekyba bei remontas',
  451000: 'Variklinių transporto priemonių pardavimas',
  451100: 'Automobilių ir lengvųjų variklinių transporto priemonių pardavimas',
  451900: 'Kitų variklinių transporto priemonių pardavimas',
  452000: 'Variklinių transporto priemonių techninė priežiūra ir remontas',
  453000:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų pardavimas',
  453100:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų didmeninė prekyba',
  453200:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų mažmeninė prekyba',
  454000:
    'Motociklų ir jų atsarginių dalių bei pagalbinių reikmenų pardavimas, techninė priežiūra ir remontas',
  460000:
    'Didmeninė prekyba, išskyrus prekybą variklinėmis transporto priemonėmis ir motociklais',
  461000: 'Didmeninė prekyba už atlygį ar pagal sutartį',
  461100:
    'Žemės ūkio žaliavų, gyvų gyvulių, tekstilės žaliavų ir pusgaminių pardavimo agentų veikla',
  461200:
    'Degalų, rūdų, metalų ir pramoninių cheminių preparatų pardavimo agentų veikla',
  461300: 'Statybinio miško ir statybinių medžiagų pardavimo agentų veikla',
  461400: 'Mašinų, pramonės įrangos, laivų ir lėktuvų pardavimo agentų veikla',
  461500:
    'Baldų, namų ūkio reikmenų, metalo ir geležies dirbinių pardavimo agentų veikla',
  461600:
    'Tekstilės, drabužių, kailių, avalynės ir odos dirbinių pardavimo agentų veikla',
  461700: 'Maisto produktų, gėrimų ir tabako pardavimo agentų veikla',
  461800: 'Kitų specifinių produktų pardavimo agentų specializuota veikla',
  461900: 'Įvairių prekių pardavimo agentų veikla',
  462000: 'Žemės ūkio žaliavų ir gyvų gyvulių didmeninė prekyba',
  462100:
    'Grūdų, neperdirbto tabako, sėklų ir pašarų gyvuliams didmeninė prekyba',
  462200: 'Gėlių ir sodinukų didmeninė prekyba',
  462300: 'Gyvų gyvulių didmeninė prekyba',
  462400: 'Kailių ir odų didmeninė prekyba',
  463000: 'Maisto produktų, gėrimų, tabako ir jo gaminių didmeninė prekyba',
  463100: 'Vaisių, uogų ir daržovių didmeninė prekyba',
  463200: 'Mėsos ir mėsos produktų didmeninė prekyba',
  463300:
    'Pieno produktų, kiaušinių bei valgomųjų aliejaus ir riebalų didmeninė prekyba',
  463400: 'Gėrimų didmeninė prekyba',
  463410: 'Alkoholinių gėrimų didmeninė prekyba',
  463420: 'Nealkoholinių gėrimų didmeninė prekyba',
  463500: 'Tabako gaminių didmeninė prekyba',
  463600: 'Cukraus, šokolado ir cukraus saldumynų didmeninė prekyba',
  463700: 'Kavos, arbatos, kakavos ir prieskonių didmeninė prekyba',
  463800:
    'Kitų maisto produktų, įskaitant žuvis, vėžiagyvius ir moliuskus, didmeninė prekyba',
  463900: 'Maisto produktų, gėrimų ir tabako nespecializuota didmeninė prekyba',
  464000: 'Namų ūkio reikmenų didmeninė prekyba',
  464100: 'Tekstilės gaminių didmeninė prekyba',
  464200: 'Drabužių ir avalynės didmeninė prekyba',
  464210: 'Guminės avalynės didmeninė prekyba',
  464220: 'Odinės avalynės didmeninė prekyba',
  464230: 'Kailinių gaminių didmeninė prekyba',
  464240: 'Drabužių ir drabužių priedų didmeninė prekyba',
  464300: 'Elektrinių buitinių aparatų arba prietaisų didmeninė prekyba',
  464400:
    'Porceliano ir stiklo dirbinių bei valymo priemonių didmeninė prekyba',
  464410: 'Porceliano ir stiklo dirbinių didmeninė prekyba',
  464420: 'Valymo priemonių didmeninė prekyba',
  464500: 'Kvepalų ir kosmetikos priemonių didmeninė prekyba',
  464600: 'Farmacijos prekių didmeninė prekyba',
  464610:
    'Chirurginių ir ortopedinių instrumentų bei protezų didmeninė prekyba',
  464620: 'Vaistų ir vaistinių medžiagų didmeninė prekyba',
  464700: 'Baldų, kilimų ir apšvietimo įrangos didmeninė prekyba',
  464800: 'Laikrodžių, papuošalų ir juvelyrinių dirbinių didmeninė prekyba',
  464900: 'Kitų namų ūkio reikmenų didmeninė prekyba',
  465000: 'Informacijos ir ryšių technologijų (IRT) įrangos didmeninė prekyba',
  465100: 'Kompiuterių, jų išorinės ir programinės įrangos didmeninė prekyba',
  465200:
    'Elektroninės ir telekomunikacinės įrangos ir jos dalių didmeninė prekyba',
  466000: 'Kitų mašinų, įrangos ir reikmenų didmeninė prekyba',
  466100: 'Žemės ūkio mašinų, įrangos ir reikmenų didmeninė prekyba',
  466200: 'Staklių didmeninė prekyba',
  466300: 'Kasybos, statybos ir statybos inžinerijos mašinų didmeninė prekyba',
  466400:
    'Tekstilės pramonės mašinų bei siuvamųjų ir mezgimo mašinų didmeninė prekyba',
  466500: 'Įstaigos baldų didmeninė prekyba',
  466600: 'Kitų įstaigos mašinų ir įrangos didmeninė prekyba',
  466900: 'Kitų mašinų ir įrangos didmeninė prekyba',
  467000: 'Kita specializuota didmeninė prekyba',
  467100: 'Kietojo, skystojo ir dujinio kuro bei priedų didmeninė prekyba',
  467110: 'Kietojo kuro didmeninė prekyba',
  467120: 'Benzino didmeninė prekyba',
  467130: 'Dujinio kuro didmeninė prekyba',
  467140: 'Dyzelinio kuro didmeninė prekyba',
  467150: 'Tepalų didmeninė prekyba',
  467200: 'Metalų rūdų ir metalų didmeninė prekyba',
  467300:
    'Medienos, statybinių medžiagų ir sanitarinių įrenginių didmeninė prekyba',
  467310: 'Popierinių sienų apmušalų didmeninė prekyba',
  467400:
    'Metalinių dirbinių, vandentiekio ir šildymo įrangos bei reikmenų didmeninė prekyba',
  467410: 'Geležies dirbinių didmeninė prekyba',
  467420: 'Vandentiekio įrangos didmeninė prekyba',
  467430: 'Šildymo įrangos didmeninė prekyba',
  467500: 'Chemijos produktų didmeninė prekyba',
  467510: 'Agrocheminių produktų ir trąšų didmeninė prekyba',
  467520:
    'Ne vaistinės paskirties stipriai veikiančiųjų ir nuodingųjų medžiagų didmeninė prekyba',
  467600: 'Kitų tarpinių produktų didmeninė prekyba',
  467610: 'Popieriaus ritinių ir kartono didmeninė prekyba',
  467620: 'Linų pluošto didmeninė prekyba',
  467700: 'Atliekų ir laužo didmeninė prekyba',
  469000: 'Nespecializuota didmeninė prekyba',
  470000:
    'Mažmeninė prekyba, išskyrus variklinių transporto priemonių ir motociklų prekybą',
  471000: 'Mažmeninė prekyba nespecializuotose parduotuvėse',
  471100:
    'Mažmeninė prekyba nespecializuotose parduotuvėse, kuriose vyrauja maistas, gėrimai ir tabakas',
  471900: 'Kita mažmeninė prekyba nespecializuotose parduotuvėse',
  472000:
    'Maisto, gėrimų ir tabako mažmeninė prekyba specializuotose parduotuvėse',
  472100:
    'Vaisių, uogų ir daržovių mažmeninė prekyba specializuotose parduotuvėse',
  472200:
    'Mėsos ir mėsos produktų mažmeninė prekyba specializuotose parduotuvėse',
  472300:
    'Žuvų, vėžiagyvių ir moliuskų mažmeninė prekyba specializuotose parduotuvėse',
  472400:
    'Duonos, bandelių, konditerijos gaminių ir cukraus saldumynų mažmeninė prekyba specializuotose parduotuvėse',
  472410: 'Duonos, pyrago ir kitų miltinių produktų mažmeninė prekyba',
  472420: 'Konditerijos gaminių ir saldumynų mažmeninė prekyba',
  472500: 'Gėrimų mažmeninė prekyba specializuotose parduotuvėse',
  472510: 'Alkoholinių gėrimų mažmeninė prekyba',
  472520: 'Gaivinamųjų gėrimų mažmeninė prekyba',
  472600: 'Tabako gaminių mažmeninė prekyba specializuotose parduotuvėse',
  472900: 'Kita maisto produktų mažmeninė prekyba specializuotose parduotuvėse',
  473000: 'Automobilių degalų mažmeninė prekyba specializuotose parduotuvėse',
  474000:
    'Informacijos ir ryšių technologijų (IRT) įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474100:
    'Kompiuterių, jų išorinės ir programinės įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474200:
    'Telekomunikacijų įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474300:
    'Garso ir vaizdo įrangos mažmeninė prekyba specializuotose parduotuvėse',
  475000:
    'Kitos namų ūkio įrangos mažmeninė prekyba specializuotose parduotuvėse',
  475100: 'Tekstilės gaminių mažmeninė prekyba specializuotose parduotuvėse',
  475200:
    'Metalo dirbinių, dažų ir stiklo mažmeninė prekyba specializuotose parduotuvėse',
  475210: 'Dažų, lakų ir politūrų mažmeninė prekyba',
  475220: 'Plokščiojo stiklo mažmeninė prekyba',
  475230: 'Statybinių medžiagų mažmeninė prekyba',
  475240: 'Medžiagų ir smulkiojo remonto darbams įrangos mažmeninė prekyba',
  475300:
    'Kilimų, kilimėlių, sienų ir grindų dangų mažmeninė prekyba specializuotose parduotuvėse',
  475400:
    'Elektrinių buitinių aparatų ir prietaisų mažmeninė prekyba specializuotose parduotuvėse',
  475900:
    'Baldų, apšvietimo įrangos ir kitų namų ūkio prekių mažmeninė prekyba specializuotose parduotuvėse',
  476000:
    'Kultūros ir poilsio prekių mažmeninė prekyba specializuotose parduotuvėse',
  476100: 'Knygų mažmeninė prekyba specializuotose parduotuvėse',
  476200:
    'Laikraščių ir raštinės reikmenų mažmeninė prekyba specializuotose parduotuvėse',
  476300:
    'Muzikos ir vaizdo įrašų mažmeninė prekyba specializuotose parduotuvėse',
  476400: 'Sporto įrangos mažmeninė prekyba specializuotose parduotuvėse',
  476500: 'Žaidimų ir žaislų mažmeninė prekyba specializuotose parduotuvėse',
  477000: 'Kitų prekių mažmeninė prekyba specializuotose parduotuvėse',
  477100: 'Drabužių mažmeninė prekyba specializuotose parduotuvėse',
  477200:
    'Avalynės ir odos gaminių mažmeninė prekyba specializuotose parduotuvėse',
  477300: 'Vaistinių, ruošiančių ir parduodančių vaistus, veikla',
  477400:
    'Medicinos ir ortopedinių prekių mažmeninė prekyba specializuotose parduotuvėse',
  477500:
    'Kosmetikos ir tualeto reikmenų mažmeninė prekyba specializuotose parduotuvėse',
  477600:
    'Gėlių, sodinukų, sėklų, trąšų, naminių gyvūnėlių ir jų ėdalo mažmeninė prekyba specializuotose parduotuvėse',
  477700:
    'Laikrodžių, papuošalų ir juvelyrinių dirbinių mažmeninė prekyba specializuotose parduotuvėse',
  477800: 'Kita naujų prekių mažmeninė prekyba specializuotose parduotuvėse',
  477810:
    'Suvenyrų, meno dirbinių ir religinių reikmenų specializuota mažmeninė prekyba',
  477820:
    'Buitinio skystojo kuro, dujų balionų, anglies ir malkų specializuota mažmeninė prekyba',
  477830: 'Ginklų ir šaudmenų specializuota mažmeninė prekyba',
  477840: 'Optikos prekių mažmeninė prekyba',
  477890: 'Kita specializuota mažmeninė prekyba',
  477900: 'Naudotų daiktų mažmeninė prekyba parduotuvėse',
  477910: 'Antikvarinių daiktų mažmeninė prekyba',
  478000: 'Mažmeninė prekyba kioskuose ir prekyvietėse',
  478100:
    'Maisto, gėrimų ir tabako gaminių mažmeninė prekyba kioskuose ir prekyvietėse',
  478200:
    'Tekstilės, drabužių ir avalynės mažmeninė prekyba kioskuose ir prekyvietėse',
  478900: 'Kitų prekių mažmeninė prekyba kioskuose ir prekyvietėse',
  479000: 'Mažmeninė prekyba ne parduotuvėse, kioskuose ar prekyvietėse',
  479100: 'Užsakomasis pardavimas paštu arba internetu',
  479900: 'Kita mažmeninė prekyba ne parduotuvėse, kioskuose ar prekyvietėse',
  479910: 'Mažmeninė prekyba per keliaujančius firmos atstovus',
  479920: 'Mažmeninė prekyba iš automatų smulkioms prekėms pardavinėti',
  490000: 'Sausumos transportas ir transportavimas vamzdynais',
  491000: 'Tarpmiestinis keleivinis geležinkelio transportas',
  492000: 'Krovininis geležinkelio transportas',
  493000: 'Kitas keleivinis sausumos transportas',
  493100: 'Keleivių vežimas miesto arba priemiestiniu sausumos transportu',
  493110: 'Keleivių vežimas autobusais mieste ar priemiestyje',
  493120: 'Keleivių vežimas troleibusais mieste ar priemiestyje',
  493200: 'Taksi veikla',
  493900: 'Kitas, niekur kitur nepriskirtas, keleivinis sausumos transportas',
  494000: 'Krovininis kelių transportas ir perkraustymo veikla',
  494100: 'Krovininis kelių transportas',
  494200: 'Perkraustymo veikla',
  495000: 'Transportavimas vamzdynais',
  500000: 'Vandens transportas',
  501000: 'Jūrų ir pakrančių keleivinis vandens transportas',
  502000: 'Jūrų ir pakrančių krovininis vandens transportas',
  503000: 'Vidaus vandenų keleivinis transportas',
  504000: 'Vidaus vandenų krovininis transportas',
  510000: 'Oro transportas',
  511000: 'Keleivinis oro transportas',
  512000: 'Krovininis oro transportas ir kosminis transportas',
  512100: 'Krovininis oro transportas',
  512200: 'Kosminis transportas',
  520000: 'Sandėliavimas ir transportui būdingų paslaugų veikla',
  521000: 'Sandėliavimas ir saugojimas',
  522000: 'Transportui būdingų paslaugų veikla',
  522100: 'Sausumos transportui būdingų paslaugų veikla',
  522110: 'Traukinių eismo organizavimas ir reguliavimas',
  522120: 'Automobilių stovėjimo aikštelių eksploatavimas',
  522130: 'Techninių priemonių, reguliuojančių gatvių judėjimą, eksploatavimas',
  522140: 'Gatvių, kelių, tiltų, tunelių eksploatavimas',
  522150: 'Sauskelių transporto stočių eksploatavimas',
  522160: 'Riedmenų techninė priežiūra ir smulkus remontas',
  522170: 'Geležinkelių eksploatavimas',
  522200: 'Vandens transportui būdingų paslaugų veikla',
  522300: 'Oro transportui būdingų paslaugų veikla',
  522400: 'Krovinių tvarkymas',
  522900: 'Kita transportui būdingų paslaugų veikla',
  522910: 'Muitinės tarpininkų veikla',
  522920: 'Krovinių gabenimo agentų ir ekspeditorių veikla',
  522930: 'Vežimo dokumentų ir kelionės lapų parengimas ir išdavimas',
  530000: 'Pašto ir pasiuntinių (kurjerių) veikla',
  531000:
    'Pašto pagal įpareigojimą teikti universaliąsias paslaugas atliekama veikla',
  532000: 'Kita pašto ir pasiuntinių (kurjerių) veikla',
  550000: 'Apgyvendinimo veikla',
  551000: 'Viešbučių ir panašių laikinų buveinių veikla',
  552000: 'Poilsiautojų ir kita trumpalaikio apgyvendinimo veikla',
  552010: 'Poilsio namų veikla',
  552020: 'Vaikų poilsio stovyklų veikla',
  553000:
    'Poilsinių transporto priemonių, priekabų aikštelių ir stovyklaviečių veikla',
  559000: 'Kita apgyvendinimo veikla',
  560000: 'Maitinimo ir gėrimų teikimo veikla',
  561000: 'Restoranų ir pagaminto valgio teikimo veikla',
  562000:
    'Pagaminto valgio tiekimas renginiams ir kitų maitinimo paslaugų veikla',
  562100: 'Pagaminto valgio tiekimas renginiams',
  562900: 'Kitų maitinimo paslaugų teikimas',
  563000: 'Gėrimų pardavimo vartoti vietoje veikla',
  580000: 'Leidybinė veikla',
  581000: 'Knygų, periodinių leidinių leidyba ir kita leidybinė veikla',
  581100: 'Knygų leidyba',
  581200: 'Žinynų, katalogų ir adresų sąrašų leidyba',
  581300: 'Laikraščių leidyba',
  581400: 'Žurnalų ir periodinių leidinių leidyba',
  581900: 'Kita leidyba',
  582000: 'Programinės įrangos leidyba',
  582100: 'Kompiuterinių žaidimų leidyba',
  582900: 'Kita programinės įrangos leidyba',
  590000:
    'Kino filmų, vaizdo filmų ir televizijos programų gamyba, garso įrašymo ir muzikos įrašų leidybos veikla',
  591000: 'Kino filmų, vaizdo filmų ir televizijos programų rengėjų veikla',
  591100: 'Kino filmų, vaizdo filmų ir televizijos programų gamyba',
  591200:
    'Pagamintų kino filmų, vaizdo filmų ir televizijos programų meninis apipavidalinimas',
  591300: 'Kino filmų, vaizdo filmų ir televizijos programų platinimas',
  591400: 'Kino filmų rodymas',
  592000: 'Garso įrašymas ir muzikos įrašų leidyba',
  600000: 'Programų rengimas ir transliavimas',
  601000: 'Radijo programų transliavimas',
  602000: 'Televizijos programų rengimas ir transliavimas',
  610000: 'Telekomunikacijos',
  611000: 'Laidinio ryšio paslaugų veikla',
  612000: 'Belaidžio ryšio paslaugų veikla',
  613000: 'Palydovinio ryšio paslaugų veikla',
  619000: 'Kitų ryšių paslaugų veikla',
  620000: 'Kompiuterių programavimo, konsultacinė ir susijusi veikla',
  620100: 'Kompiuterių programavimo veikla',
  620200: 'Kompiuterių konsultacinė veikla',
  620300: 'Kompiuterinės įrangos tvarkyba',
  620900: 'Kita informacinių technologijų ir kompiuterių paslaugų veikla',
  630000: 'Informacinių paslaugų veikla',
  631000:
    'Duomenų apdorojimo, interneto serverių paslaugų (prieglobos) ir susijusi veikla; interneto vartų paslaugų veikla',
  631100:
    'Duomenų apdorojimo, interneto serverių paslaugų (prieglobos) ir susijusi veikla',
  631200: 'Interneto vartų paslaugų veikla',
  639000: 'Kita informacinių paslaugų veikla',
  639100: 'Naujienų agentūrų veikla',
  639900: 'Kita, niekur kitur nepriskirta, informacinių paslaugų veikla',
  640000:
    'Finansinių paslaugų veikla, išskyrus draudimą ir pensijų lėšų kaupimą',
  641000: 'Piniginis tarpininkavimas',
  641100: 'Centrinė bankininkystė',
  641900: 'Kitas piniginis tarpininkavimas',
  642000: 'Kontroliuojančiųjų bendrovių veikla',
  643000: 'Trestų, fondų ir panašių finansinių institucijų veikla',
  649000:
    'Kita finansinių paslaugų veikla, išskyrus draudimą ir pensijų lėšų kaupimą',
  649100: 'Finansinė išperkamoji nuoma',
  649200: 'Kitas kredito teikimas',
  649900:
    'Kita, niekur kitur nepriskirta, finansinių paslaugų veikla, išskyrus draudimą ir pensijų lėšų kaupimą',
  650000:
    'Draudimo, perdraudimo ir pensijų lėšų kaupimo, išskyrus privalomąjį socialinį draudimą, veikla',
  651000: 'Draudimas',
  651100: 'Gyvybės draudimas',
  651200: 'Ne gyvybės draudimas',
  651210: 'Kelionių draudimas',
  652000: 'Perdraudimas',
  653000: 'Pensijų lėšų kaupimas',
  660000: 'Pagalbinė finansinių paslaugų ir draudimo veikla',
  661000:
    'Pagalbinė finansinių paslaugų, išskyrus draudimą ir pensijų lėšų kaupimą, veikla',
  661100: 'Finansų rinkos valdymas',
  661200: 'Vertybinių popierių ir prekių sutarčių sudarymo tarpininkavimas',
  661900:
    'Kita pagalbinė finansinių paslaugų, išskyrus draudimą ir pensijų lėšų kaupimą, veikla',
  662000: 'Pagalbinė draudimo ir pensijų lėšų kaupimo veikla',
  662100: 'Rizikos ir žalos vertinimas',
  662200: 'Draudimo agentų ir brokerių veikla',
  662900: 'Kita pagalbinė draudimo ir pensijų lėšų kaupimo veikla',
  663000: 'Fondų valdymo veikla',
  680000: 'Nekilnojamojo turto operacijos',
  681000: 'Nuosavo nekilnojamojo turto pirkimas ir pardavimas',
  682000: 'Nuosavo arba nuomojamo nekilnojamojo turto nuoma ir eksploatavimas',
  683000: 'Nekilnojamojo turto operacijos už atlygį arba pagal sutartį',
  683100: 'Nekilnojamojo turto agentūrų veikla',
  683110: 'Nekilnojamojo turto vertinimas',
  683200: 'Nekilnojamojo turto tvarkyba už atlygį arba pagal sutartį',
  690000: 'Teisinė ir apskaitos veikla',
  691000: 'Teisinė veikla',
  691010:
    'Konsultacijos ir juridinis atstovavimas civilinėse ir baudžiamosiose bylose',
  691020: 'Konsultacijos ir atstovavimas darbo ginčuose',
  691030: 'Bendrieji patarimai ir konsultacijos, teisinių dokumentų rengimas',
  692000:
    'Apskaitos, buhalterijos ir audito veikla; konsultacijos mokesčių klausimais',
  692010: 'Buhalterinė apskaita  ir audito atlikimas',
  692020: 'Finansinių ataskaitų rengimas',
  692030: 'Firmų ir kitų bendrovių komercinių operacijų apskaitos tvarkymas',
  692040: 'Mokesčių konsultacijos',
  700000: 'Pagrindinių buveinių veikla; konsultacinė valdymo veikla',
  701000: 'Pagrindinių buveinių veikla',
  702000: 'Konsultacinė valdymo veikla',
  702100: 'Viešųjų ryšių ir komunikacijos veikla',
  702200: 'Konsultacinė verslo ir kito valdymo veikla',
  710000:
    'Architektūros ir inžinerijos veikla; techninis tikrinimas ir analizė',
  711000:
    'Architektūros ir inžinerijos veikla bei su ja susijusios techninės konsultacijos',
  711100: 'Architektūros veikla',
  711200: 'Inžinerijos veikla ir su ja susijusios techninės konsultacijos',
  711210: 'Inžinerinė - technologinė veikla',
  711220: 'Projektiniai - konstruktoriniai darbai',
  711230: 'Gatvių eismo projektavimas',
  711240: 'Geodezinė veikla',
  711250: 'Geologinės paieškos ir žvalgyba',
  711260: 'Miškotvarka ir žemėtvarka',
  712000: 'Techninis tikrinimas ir analizė',
  712010: 'Aplinkos kontrolė, ekologinis monitoringas',
  712020: 'Medžiagų bandymai ir analizė',
  712030: 'Produkcijos kokybės bandymai ir analizė',
  712040:
    'Maisto produktų tyrimas pagal higienos reikalavimus, įskaitant veterinarinį, susijusį su maisto produktų gamyba, tyrimą ir tikrinimą',
  712050:
    'Variklinių transporto priemonių saugaus eismo periodinis patikrinimas',
  712060: 'Matavimo priemonių patikra bei kalibravimas',
  712070:
    'Gaminių sertifikavimas, jų normatyvinių dokumentų reikalavimų kontrolė',
  720000: 'Moksliniai tyrimai ir taikomoji veikla',
  721000: 'Gamtos mokslų ir inžinerijos moksliniai tyrimai ir taikomoji veikla',
  721100: 'Biotechnologijos moksliniai tyrimai ir taikomoji veikla',
  721900:
    'Kiti gamtos mokslų ir inžinerijos moksliniai tyrimai ir taikomoji veikla',
  721910: 'Agrarinių mokslų tiriamieji ir taikomieji darbai',
  721920: 'Gamtos mokslų tiriamieji ir taikomieji darbai',
  721930: 'Matematikos mokslų tiriamieji ir taikomieji darbai',
  721940: 'Medicinos mokslų tiriamieji ir taikomieji darbai',
  721950: 'Technikos mokslų tiriamieji ir taikomieji darbai',
  722000:
    'Socialinių ir humanitarinių mokslų moksliniai tyrimai ir taikomoji veikla',
  722010: 'Humanitarinių mokslų tiriamieji ir taikomieji darbai',
  722020: 'Socialinių mokslų tiriamieji ir taikomieji darbai',
  722030: 'Teologijos mokslų tiriamieji ir taikomieji darbai',
  730000: 'Reklama ir rinkos tyrimas',
  731000: 'Reklama',
  731100: 'Reklamos agentūrų veikla',
  731200: 'Atstovavimas žiniasklaidai',
  732000: 'Rinkos tyrimas ir viešosios nuomonės apklausa',
  740000: 'Kita profesinė, mokslinė ir techninė veikla',
  741000: 'Specializuota projektavimo veikla',
  742000: 'Fotografavimo veikla',
  743000: 'Vertimo raštu ir žodžiu veikla',
  749000:
    'Kita, niekur kitur nepriskirta, profesinė, mokslinė ir techninė veikla',
  750000: 'Veterinarinė veikla',
  750010: 'Gyvulių ir gyvūnų stacionarinis gydymas',
  750020: 'Gyvulių ir gyvūnų ambulatorinis gydymas',
  750030: 'Veterinarinė diagnostinė veikla',
  750040: 'Veterinarijos laboratorijų veikla',
  770000: 'Nuoma ir išperkamoji nuoma',
  771000: 'Variklinių transporto priemonių nuoma ir išperkamoji nuoma',
  771100:
    'Automobilių ir lengvųjų variklinių transporto priemonių nuoma ir išperkamoji nuoma',
  771200:
    'Sunkiasvorių variklinių transporto priemonių nuoma ir išperkamoji nuoma',
  771210: 'Krovininių automobilių nuoma',
  771220: 'Turistinių autobusų nuoma',
  772000: 'Asmeninių ir namų ūkio prekių nuoma ir išperkamoji nuoma',
  772100: 'Poilsio ir sporto reikmenų nuoma ir išperkamoji nuoma',
  772110: 'Pramoginių valčių nuoma',
  772120: 'Jojamųjų žirgų nuoma',
  772130: 'Dviračių nuoma',
  772140: 'Sporto įrangos nuoma',
  772150: 'Kitų turizmo priemonių nuoma',
  772200: 'Vaizdajuosčių ir kompaktinių diskų nuoma',
  772900: 'Kitų asmeninių ir namų ūkio prekių nuoma ir išperkamoji nuoma',
  772910: 'Tekstilės dirbinių, drabužių ir avalynės nuoma',
  772920: 'Baldų ir kitų asmeniniam naudojimui skirtų daiktų nuoma',
  772930: 'Muzikos instrumentų, teatro dekoracijų ir kostiumų nuoma',
  773000:
    'Kitų mašinų, įrangos ir materialiųjų vertybių nuoma ir išperkamoji nuoma',
  773100: 'Žemės ūkio mašinų ir įrenginių nuoma ir išperkamoji nuoma',
  773200:
    'Statybos ir inžinerinių statybos darbų mašinų ir įrenginių nuoma ir išperkamoji nuoma',
  773300:
    'Įstaigos mašinų ir įrangos, įskaitant kompiuterius, nuoma ir išperkamoji nuoma',
  773310: 'Kompiuterių nuoma',
  773320: 'Kopijavimo, spausdinimo ir teksto apdorojimo mašinų nuoma',
  773330: 'Kasos aparatų nuoma',
  773400: 'Vandens transporto priemonių ir įrangos nuoma ir išperkamoji nuoma',
  773500: 'Oro transporto priemonių ir įrangos nuoma ir išperkamoji nuoma',
  773900:
    'Kitų, niekur kitur nepriskirtų, mašinų, įrangos ir materialiųjų vertybių nuoma ir išperkamoji nuoma',
  773910: 'Geležinkelio transporto priemonių nuoma',
  773920: 'Motociklų nuoma',
  773930: 'Automobilinių namelių nuoma',
  773940: 'Konteinerių, kilnojamųjų platformų ir padėklų nuoma',
  773950: 'Pramoninių elektrinių ir neelektrinių mašinų nuoma',
  773960: 'Radijo ir televizijos įrenginių nuoma',
  773970: 'Ryšių įrenginių nuoma',
  773980: 'Matavimo ir kontrolės įrenginių nuoma',
  774000:
    'Intelektinės nuosavybės ir panašių produktų, išskyrus autorių teisių saugomus objektus, išperkamoji nuoma',
  780000: 'Įdarbinimo veikla',
  781000: 'Įdarbinimo agentūrų veikla',
  782000: 'Laikinojo įdarbinimo agentūrų veikla',
  783000: 'Kitas darbo jėgos teikimas',
  790000:
    'Kelionių agentūrų, ekskursijų organizatorių, išankstinio užsakymo paslaugų ir susijusi veikla',
  791000: 'Kelionių agentūrų ir ekskursijų organizatorių veikla',
  791100: 'Kelionių agentūrų veikla',
  791200: 'Ekskursijų organizatorių veikla',
  799000: 'Kitų išankstinio užsakymo ir susijusių paslaugų veikla',
  800000: 'Apsaugos ir tyrimo veikla',
  801000: 'Privati apsauga',
  802000: 'Apsaugos sistemų paslaugų veikla',
  803000: 'Tyrimo veikla',
  810000: 'Pastatų aptarnavimas ir kraštovaizdžio tvarkymas',
  811000: 'Kombinuota patalpų funkcionavimo užtikrinimo veikla',
  812000: 'Valymo veikla',
  812100: 'Paprastasis pastatų valymas',
  812200: 'Kita pastatų ir pramoninio valymo veikla',
  812900: 'Kita valymo veikla',
  813000: 'Kraštovaizdžio tvarkymas',
  820000:
    'Administracinė veikla, įstaigų ir kitų verslo įmonių aptarnavimo veikla',
  821000: 'Įstaigų administracinė ir aptarnavimo veikla',
  821100: 'Kombinuotųjų įstaigos administracinių paslaugų veikla',
  821900:
    'Fotokopijavimo, dokumentų rengimo ir kita specializuota įstaigai būdingų paslaugų veikla',
  822000: 'Užsakomųjų informacinių paslaugų centrų veikla',
  823000: 'Posėdžių ir verslo renginių organizavimas',
  829000: 'Niekur kitur nepriskirta verslui būdingų paslaugų veikla',
  829100: 'Išieškojimo agentūrų ir kredito biurų veikla',
  829200: 'Fasavimo ir pakavimo veikla',
  829900: 'Kita, niekur kitur nepriskirta, verslui būdingų paslaugų veikla',
  840000: 'Viešasis valdymas ir gynyba; privalomasis socialinis draudimas',
  841000:
    'Valstybės valdymas ir bendroji ekonominė bei socialinė bendruomenės politika',
  841100: 'Bendroji viešojo valdymo veikla',
  841110: 'Lietuvos Respublikos apskričių veikla',
  841120: 'Lietuvos Respublikos savivaldybių veikla',
  841130: 'Lietuvos Respublikos seniūnijų veikla',
  841140: 'Iždo valdymas ir priežiūra',
  841150: 'Biudžeto vykdymas ir valstybinių fondų tvarkymas',
  841160:
    'Centrinių administracinių institucijų įstatymų leidžiamoji ir vykdomoji veikla',
  841170: 'Vadovavimas muitinės tarnyboms ir jų veikla',
  841180:
    'Vadovavimas statistikos ir sociologijos tarnyboms įvairiuose valstybės valdymo lygiuose bei jų veikla',
  841200:
    'Sveikatos priežiūros, švietimo, kultūros ir kitų socialinių paslaugų, išskyrus socialinį draudimą, veiklos reguliavimas',
  841300: 'Verslo veiklos reguliavimas ir parama efektyviau ją organizuoti',
  842000: 'Bendruomenei teikiamų paslaugų užtikrinimas',
  842100: 'Užsienio reikalai',
  842200: 'Gynybos veikla',
  842300: 'Teisingumo ir teisminė veikla',
  842400: 'Viešosios tvarkos ir apsaugos veikla',
  842500: 'Priešgaisrinių tarnybų veikla',
  843000: 'Privalomojo (valstybinio) socialinio draudimo veikla',
  850000: 'Švietimas',
  851000: 'Ikimokyklinis ugdymas',
  851010: 'Ikimokyklinio amžiaus vaikų ugdymas',
  851020: 'Priešmokyklinio amžiaus vaikų ugdymas',
  852000: 'Pradinis ugdymas',
  853000: 'Vidurinis ugdymas',
  853100: 'Bendrasis vidurinis ugdymas',
  853110: 'Pagrindinis ugdymas',
  853120: 'Vidurinis ugdymas',
  853200: 'Techninis ir profesinis vidurinis mokymas',
  854000: 'Aukštasis mokslas',
  854100:
    'Aukštojo mokslo laipsnio nesuteikiantis mokymas baigus vidurinę mokyklą',
  854200: 'Aukštasis (tretinis) mokslas',
  854210: 'Aukštasis neuniversitetinis mokslas',
  854220: 'Aukštasis universitetinis mokslas',
  854230: 'Doktorantūra',
  855000: 'Kitas mokymas',
  855100: 'Sportinis ir rekreacinis švietimas',
  855200: 'Kultūrinis švietimas',
  855300: 'Vairavimo mokyklų veikla',
  855900: 'Kitas, niekur kitur nepriskirtas, švietimas',
  856000: 'Švietimui būdingų paslaugų veikla',
  860000: 'Žmonių sveikatos priežiūros veikla',
  861000: 'Ligoninių veikla',
  861010: 'Bendrosios paskirties ligoninių veikla',
  861020: 'Specializuotųjų ligoninių veikla',
  861030: 'Reabilitacijos ligoninių veikla',
  861040: 'Slaugos ligoninių veikla',
  862000: 'Medicininės ir odontologinės praktikos veikla',
  862100: 'Bendrosios praktikos gydytojų veikla',
  862200: 'Gydytojų specialistų veikla',
  862300: 'Odontologinės praktikos veikla',
  869000: 'Kita žmonių sveikatos priežiūros veikla',
  869010:
    'Viduriniojo medicinos personalo paslaugų  teikiamas ligoniams ne ligoninėse',
  869020: 'Kraujo perpylimo įstaigų ir kraujo bankų veikla',
  869030: 'Medicinos laboratorijų veikla',
  869040: 'Greitosios pagalbos veikla',
  870000: 'Kita stacionarinė globos veikla',
  871000: 'Stacionarinė slaugos įstaigų veikla',
  872000:
    'Stacionarinė protiškai atsilikusių, psichikos ligonių, sergančiųjų priklausomybės ligomis globos veikla',
  873000: 'Stacionarinė pagyvenusių ir neįgaliųjų asmenų globos veikla',
  879000: 'Kita stacionarinė globos veikla',
  880000: 'Nesusijusio su apgyvendinimu socialinio darbo veikla',
  881000:
    'Nesusijusio su apgyvendinimu socialinio darbo su pagyvenusiais ir neįgaliaisiais asmenimis veikla',
  889000: 'Kita, nesusijusi su apgyvendinimu, socialinio darbo veikla',
  889100: 'Vaikų dienos priežiūros veikla',
  889900:
    'Kita, niekur kitur nepriskirta, nesusijusi su apgyvendinimu socialinio darbo veikla',
  900000: 'Kūrybinė, meninė ir pramogų organizavimo veikla',
  900100: 'Scenos pastatymų veikla',
  900200: 'Scenos pastatymams būdingų paslaugų veikla',
  900300: 'Meninė kūryba',
  900400: 'Meno įrenginių eksploatavimo veikla',
  910000: 'Bibliotekų, archyvų, muziejų ir kita kultūrinė veikla',
  910100: 'Bibliotekų ir archyvų veikla',
  910200: 'Muziejų veikla',
  910300:
    'Istorinių vietų ir pastatų bei panašių turistų lankomų vietų eksploatavimas',
  910400: 'Botanikos ir zoologijos sodų bei gamtos rezervatų veikla',
  910410: 'Zoologijos sodų veikla',
  910420: 'Botanikos sodų veikla',
  910430: 'Gamtos rezervatų ir draustinių veikla',
  920000: 'Azartinių žaidimų ir lažybų organizavimo veikla',
  920010: 'Loterijų organizavimo veikla',
  920020: 'Azartinių lošimų ir lažybų organizavimo veikla',
  920030: 'Kitų azartinių žaidimų organizavimo veikla',
  930000: 'Sportinė veikla, pramogų ir poilsio organizavimo veikla',
  931000: 'Sportinė veikla',
  931100: 'Sporto įrenginių eksploatavimas',
  931200: 'Sporto klubų veikla',
  931300: 'Kūno rengybos centrų veikla',
  931900: 'Kita sportinė veikla',
  931910: 'Sportinė ar mėgėjų medžioklė ir su ja susijusi veikla',
  931920: 'Sportinė ar mėgėjų žvejyba ir su ja susijusi veikla',
  932000: 'Pramogų ir poilsio organizavimo veikla',
  932100: 'Atrakcionų ir teminių parkų veikla',
  932900: 'Kita pramogų ir poilsio organizavimo veikla',
  940000: 'Narystės organizacijų veikla',
  941000: 'Verslininkų, darbdavių ir profesinių narystės organizacijų veikla',
  941100: 'Verslininkų ir darbdavių narystės organizacijų veikla',
  941200: 'Profesinių narystės organizacijų veikla',
  942000: 'Profesinių sąjungų veikla',
  949000: 'Kitų narystės organizacijų veikla',
  949100: 'Religinių organizacijų veikla',
  949110: 'Religinių bendrijų veikla',
  949120: 'Vienuolynų veikla',
  949200: 'Politinių organizacijų veikla',
  949900: 'Kitų, niekur kitur nepriskirtų, narystės organizacijų veikla',
  950000: 'Kompiuterių ir asmeninių bei namų ūkio reikmenų taisymas',
  951000: 'Kompiuterių ir ryšių įrangos remontas',
  951100: 'Kompiuterių ir išorinės įrangos remontas',
  951200: 'Ryšių įrangos remontas',
  951210: 'Ryšio sistemų techninė priežiūra',
  952000: 'Asmeninių ir namų ūkio reikmenų taisymas',
  952100: 'Vartotojiškos elektroninės įrangos remontas',
  952200: 'Buitinių aparatų ir prietaisų, namų bei sodo įrangos remontas',
  952300: 'Avalynės ir odos gaminių taisymas',
  952400: 'Baldų ir interjero reikmenų taisymas',
  952500: 'Įvairių tipų laikrodžių ir juvelyrinių dirbinių taisymas',
  952900: 'Kitų asmeninių ir namų ūkio reikmenų taisymas',
  960000: 'Kita asmenų aptarnavimo veikla',
  960100: 'Tekstilės ir kailių gaminių skalbimas ir (sausasis) valymas',
  960200: 'Kirpyklų ir kitų grožio salonų veikla',
  960300: 'Laidotuvių ir su jomis susijusi veikla',
  960400: 'Fizinės gerovės užtikrinimo veikla',
  960900: 'Kita, niekur kitur nepriskirta, asmenų aptarnavimo veikla',
  970000: 'Namų ūkių, samdančių namų ūkio personalą, veikla',
  980000:
    'Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų gaminių gamyba ir paslaugų teikimu',
  981000:
    ' Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų gaminių ar reikmenų gamyba',
  981000:
    'Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų gaminių ar reikmenų gamyba',
  982000:
    'Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų paslaugų teikimu',
  990000: 'Ekstrateritorinių organizacijų ir įstaigų veikla',
};

export const formatCountryJson = [
  {
    value: 'AF',
    label: 'Afganistanas',
  },
  {
    value: 'IE',
    label: 'Airija',
  },
  {
    value: 'AX',
    label: 'Alandų salos',
  },
  {
    value: 'AL',
    label: 'Albanija',
  },
  {
    value: 'DZ',
    label: 'Alžyras',
  },
  {
    value: 'AS',
    label: 'Amerikos Samoa',
  },
  {
    value: 'AD',
    label: 'Andora',
  },
  {
    value: 'AI',
    label: 'Angilija',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AQ',
    label: 'Antarktika',
  },
  {
    value: 'AG',
    label: 'Antigva ir Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armėnija',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AT',
    label: 'Austirija',
  },
  {
    value: 'AU',
    label: 'Australija',
  },
  {
    value: 'AZ',
    label: 'Azejbardžanas',
  },
  {
    value: 'BS',
    label: 'Bahamai',
  },
  {
    value: 'BH',
    label: 'Bahreinas',
  },
  {
    value: 'BY',
    label: 'Baltarusija',
  },
  {
    value: 'BD',
    label: 'Bangladešas',
  },
  {
    value: 'BB',
    label: 'Barbadosas',
  },
  {
    value: 'BE',
    label: 'Belgija',
  },
  {
    value: 'BZ',
    label: 'Belizas',
  },
  {
    value: 'BJ',
    label: 'Beninas',
  },
  {
    value: 'BM',
    label: 'Bermudai',
  },
  {
    value: 'GW',
    label: 'Bisau Gvinėja',
  },
  {
    value: 'BO',
    label: 'Bolivija',
  },
  {
    value: 'BQ',
    label: 'Bonaire, Saint Eustatius and Saba',
  },
  {
    value: 'BA',
    label: 'Bosnija ir Hercogovina',
  },
  {
    value: 'BW',
    label: 'Botsvana',
  },
  {
    value: 'BV',
    label: 'Bouvet sala',
  },
  {
    value: 'BR',
    label: 'Brazilija',
  },
  {
    value: 'IO',
    label: 'Britanijos Indijos vandenyno teritorija',
  },
  {
    value: 'BN',
    label: 'Brunėjaus Darusalamas',
  },
  {
    value: 'BG',
    label: 'Bulgarija',
  },
  {
    value: 'BF',
    label: 'Burkina Fasas',
  },
  {
    value: 'BI',
    label: 'Burundis',
  },
  {
    value: 'BT',
    label: 'Butanas',
  },
  {
    value: 'CF',
    label: 'Centrinė Afrikos respublika',
  },
  {
    value: 'TD',
    label: 'Čadas',
  },
  {
    value: 'CZ',
    label: 'Čekija',
  },
  {
    value: 'CL',
    label: 'Čilė',
  },
  {
    value: 'DK',
    label: 'Danija',
  },
  {
    value: 'DM',
    label: 'Dominyka',
  },
  {
    value: 'DO',
    label: 'Dominykos respublika',
  },
  {
    value: 'CI',
    label: 'Dramblio kaulo krantas',
  },
  {
    value: 'JE',
    label: 'Džersis',
  },
  {
    value: 'DJ',
    label: 'Džibutis',
  },
  {
    value: 'EG',
    label: 'Egiptas',
  },
  {
    value: 'EC',
    label: 'Ekvadoras',
  },
  {
    value: 'ER',
    label: 'Eritrėja',
  },
  {
    value: 'EE',
    label: 'Estija',
  },
  {
    value: 'ET',
    label: 'Etiopija',
  },
  {
    value: 'FO',
    label: 'Farerų salos',
  },
  {
    value: 'FJ',
    label: 'Fidžis',
  },
  {
    value: 'PH',
    label: 'Filipinai',
  },
  {
    value: 'FK',
    label: 'Folklando salos',
  },
  {
    value: 'GA',
    label: 'Gabonas',
  },
  {
    value: 'GY',
    label: 'Gajana',
  },
  {
    value: 'GM',
    label: 'Gambija',
  },
  {
    value: 'GH',
    label: 'Gana',
  },
  {
    value: 'GG',
    label: 'Gernsis',
  },
  {
    value: 'GI',
    label: 'Gibraltaras',
  },
  {
    value: 'GR',
    label: 'Grakija',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GL',
    label: 'Grenlandija',
  },
  {
    value: 'GU',
    label: 'Guamas',
  },
  {
    value: 'GP',
    label: 'Gvadelupe',
  },
  {
    value: 'GT',
    label: 'Gvatemala',
  },
  {
    value: 'GN',
    label: 'Gvinėja',
  },
  {
    value: 'HT',
    label: 'Haitis',
  },
  {
    value: 'HM',
    label: 'Heardo sala ir Makdonaldo salos',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong kongas',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonezija',
  },
  {
    value: 'IQ',
    label: 'Irakas',
  },
  {
    value: 'IR',
    label: 'Iranas',
  },
  {
    value: 'IS',
    label: 'Islandija',
  },
  {
    value: 'ES',
    label: 'Ispanija',
  },
  {
    value: 'IT',
    label: 'Italija',
  },
  {
    value: 'IL',
    label: 'Izraelis',
  },
  {
    value: 'JM',
    label: 'Jamaika',
  },
  {
    value: 'JP',
    label: 'Japonija',
  },
  {
    value: 'YE',
    label: 'Jemenas',
  },
  {
    value: 'JO',
    label: 'Jordanija',
  },
  {
    value: 'GB',
    label: 'Jungtinė karalystė',
  },
  {
    value: 'US',
    label: 'Jungtinės amerikos valstijos (JAV)',
  },
  {
    value: 'AE',
    label: 'Jungtiniai arabų emiratai (JAE)',
  },
  {
    value: 'ME',
    label: 'Juodkalnija',
  },
  {
    value: 'KY',
    label: 'Kaimano salos',
  },
  {
    value: 'CX',
    label: 'Kalėdų sala',
  },
  {
    value: 'CM',
    label: 'Kamerūnas',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'QA',
    label: 'Kataras',
  },
  {
    value: 'KZ',
    label: 'Kazachstanas',
  },
  {
    value: 'KE',
    label: 'Kenija',
  },
  {
    value: 'CN',
    label: 'Kinija',
  },
  {
    value: 'CY',
    label: 'Kipras',
  },
  {
    value: 'KG',
    label: 'Kirgiztanas',
  },
  {
    value: 'KI',
    label: 'Kiribatis',
  },
  {
    value: 'CC',
    label: 'Kokoso (Kylingo) salos',
  },
  {
    value: 'CO',
    label: 'Kolumbija',
  },
  {
    value: 'KH',
    label: 'Kombodža',
  },
  {
    value: 'KM',
    label: 'Komorai',
  },
  {
    value: 'CG',
    label: 'Kongas',
  },
  {
    value: 'CD',
    label: 'Kongo demokratinė respublika',
  },
  {
    value: 'KP',
    label: 'Korejos demokratinė respublika',
  },
  {
    value: 'KR',
    label: 'Korėjos respublika',
  },
  {
    value: 'CR',
    label: 'Kosta Rika',
  },
  {
    value: 'HR',
    label: 'Kroatija',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CK',
    label: 'Kuko salos',
  },
  {
    value: 'CW',
    label: 'Kurakao',
  },
  {
    value: 'KW',
    label: 'Kuveitas',
  },
  {
    value: 'LA',
    label: 'Laoso Liaudies Demokratinė Respublika',
  },
  {
    value: 'LV',
    label: 'Latvija',
  },
  {
    value: 'PL',
    label: 'Lenkija',
  },
  {
    value: 'LS',
    label: 'Lesotas',
  },
  {
    value: 'LB',
    label: 'Libanas',
  },
  {
    value: 'LR',
    label: 'Liberija',
  },
  {
    value: 'LY',
    label: 'Libija',
  },
  {
    value: 'LI',
    label: 'Lichtenšteinas',
  },
  {
    value: 'LT',
    label: 'Lietuva',
  },
  {
    value: 'LU',
    label: 'Liuksemburgas',
  },
  {
    value: 'MG',
    label: 'Madagaskaras',
  },
  {
    value: 'YT',
    label: 'Majotas',
  },
  {
    value: 'MO',
    label: 'Makao',
  },
  {
    value: 'MK',
    label: 'Makedonija',
  },
  {
    value: 'MW',
    label: 'Malavis',
  },
  {
    value: 'MY',
    label: 'Malazija',
  },
  {
    value: 'MV',
    label: 'Maldyvai',
  },
  {
    value: 'ML',
    label: 'Malis',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MQ',
    label: 'Marinika',
  },
  {
    value: 'MA',
    label: 'Marokas',
  },
  {
    value: 'MH',
    label: 'Maršalo salos',
  },
  {
    value: 'MU',
    label: 'Mauricijus',
  },
  {
    value: 'MR',
    label: 'Mauritanija',
  },
  {
    value: 'UM',
    label: 'Mažosios JAV atokiosios salos',
  },
  {
    value: 'MX',
    label: 'Meksika',
  },
  {
    value: 'IM',
    label: 'Meno sala',
  },
  {
    value: 'VG',
    label: 'Mergelių salos (Didžiosios Britanijos)',
  },
  {
    value: 'VI',
    label: 'Mergelių salos (U.S.)',
  },
  {
    value: 'MM',
    label: 'Mianmaras',
  },
  {
    value: 'FM',
    label: 'Mikronezija',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monakas',
  },

  {
    value: 'MN',
    label: 'Mongolija',
  },
  {
    value: 'MS',
    label: 'Montseratas',
  },
  {
    value: 'MZ',
    label: 'Mozambikas',
  },
  {
    value: 'NA',
    label: 'Namibija',
  },
  {
    value: 'NC',
    label: 'Naujoji Kaledonija',
  },
  {
    value: 'NZ',
    label: 'Naujoji Zelandija',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepalas',
  },
  {
    value: 'NL',
    label: 'Nyderlandai',
  },
  {
    value: 'NG',
    label: 'Nigerija',
  },
  {
    value: 'NE',
    label: 'Nigeris',
  },
  {
    value: 'NI',
    label: 'Nikaragva',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolko sala',
  },
  {
    value: 'NO',
    label: 'Norvegija',
  },
  {
    value: 'OM',
    label: 'Omanas',
  },
  {
    value: 'PK',
    label: 'Pakistanas',
  },
  {
    value: 'PW',
    label: 'Palauija',
  },
  {
    value: 'PS',
    label: 'Palestina',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua naujoji gvinėja',
  },
  {
    value: 'PY',
    label: 'Parugvajus',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'ZA',
    label: 'Pietų afrikos respublika',
  },
  {
    value: 'GS',
    label: 'Pietų Džordžija ir Pietų Sandvičo salos',
  },
  {
    value: 'SS',
    label: 'Pietų Sudanas',
  },
  {
    value: 'PN',
    label: 'Pitkernas',
  },
  {
    value: 'PT',
    label: 'Portugalija',
  },
  {
    value: 'FR',
    label: 'Prancūzija',
  },
  {
    value: 'GF',
    label: 'Prancūzijos Gviana',
  },
  {
    value: 'TF',
    label: 'Prancūzijos pietinės teritorijos',
  },
  {
    value: 'PF',
    label: 'Prancūzų Polinezija',
  },
  {
    value: 'PR',
    label: 'Puerto Rikas',
  },
  {
    value: 'GQ',
    label: 'Pusiaujo gvinėja',
  },
  {
    value: 'RE',
    label: 'Reinjonas',
  },
  {
    value: 'TL',
    label: 'Rytų Timoras',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'RO',
    label: 'Rumunija',
  },
  {
    value: 'RU',
    label: 'Rusijos federacija',
  },
  {
    value: 'GE',
    label: 'Sakartvelas',
  },
  {
    value: 'SB',
    label: 'Saliamono salos',
  },
  {
    value: 'SV',
    label: 'Salvadoras',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marinas',
  },
  {
    value: 'ST',
    label: 'San Tomė ir Prinsipė',
  },
  {
    value: 'SA',
    label: 'Saudo arabija',
  },
  {
    value: 'SC',
    label: 'Seišeliai',
  },
  {
    value: 'SX',
    label: 'Sen Martenas',
  },
  {
    value: 'MF',
    label: 'Sen Martenas',
  },
  {
    value: 'PM',
    label: 'Sen Pjeras ir Mikelonas',
  },
  {
    value: 'SN',
    label: 'Senegalas',
  },
  {
    value: 'VC',
    label: 'Sent Vinsentas ir Grenadinai',
  },
  {
    value: 'RS',
    label: 'Serbija',
  },
  {
    value: 'SL',
    label: 'Siera Leonė',
  },
  {
    value: 'SG',
    label: 'Singapūras',
  },
  {
    value: 'SY',
    label: 'Sirijos Arabų Respublika',
  },
  {
    value: 'SK',
    label: 'Slovakija',
  },
  {
    value: 'SI',
    label: 'Slovėnija',
  },
  {
    value: 'SO',
    label: 'Somalija',
  },
  {
    value: 'SD',
    label: 'Sudanas',
  },
  {
    value: 'FI',
    label: 'Suomiija',
  },
  {
    value: 'SR',
    label: 'Surinamas',
  },
  {
    value: 'SJ',
    label: 'Svalbardas ir Janas Mayenas',
  },
  {
    value: 'SZ',
    label: 'Svazilandas',
  },
  {
    value: 'MP',
    label: 'Šiaurės Marianų salos',
  },
  {
    value: 'LK',
    label: 'Šri lanka',
  },
  {
    value: 'SE',
    label: 'Švedija',
  },
  {
    value: 'CH',
    label: 'Šveicarija',
  },
  {
    value: 'BL',
    label: 'Šventasis Bartelemijas',
  },
  {
    value: 'KN',
    label: 'Šventasis Kitsas ir Nevis',
  },
  {
    value: 'VA',
    label: 'Šventasis Sostas',
  },
  {
    value: 'SH',
    label: 'Šventoji Elena ir Tristanas da Kunja',
  },
  {
    value: 'LC',
    label: 'Šventoji Liusija',
  },
  {
    value: 'TJ',
    label: 'Tadžikistanas',
  },
  {
    value: 'TH',
    label: 'Tailandas',
  },
  {
    value: 'TW',
    label: 'Taivanas',
  },
  {
    value: 'TZ',
    label: 'Tanzanija',
  },
  {
    value: 'TC',
    label: 'Terkso ir Kaikoso salos',
  },
  {
    value: 'TG',
    label: 'Togas',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tongas',
  },
  {
    value: 'TT',
    label: 'Trinidadas ir Tobagas',
  },
  {
    value: 'TN',
    label: 'Tunisas',
  },
  {
    value: 'TR',
    label: 'Turkija',
  },
  {
    value: 'TM',
    label: 'Turkmėnistanas',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraina',
  },
  {
    value: 'UY',
    label: 'Urugvajus',
  },
  {
    value: 'UZ',
    label: 'Uzbekistanas',
  },
  {
    value: 'EH',
    label: 'Vakarų Sachara',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venesuela (Bolivaro Respublika)',
  },
  {
    value: 'HU',
    label: 'Vengrija',
  },
  {
    value: 'VN',
    label: 'Vietnamas',
  },
  {
    value: 'DE',
    label: 'Vokietija',
  },
  {
    value: 'WF',
    label: 'Volisas ir Futuna',
  },
  {
    value: 'ZM',
    label: 'Zambija',
  },
  {
    value: 'ZW',
    label: 'Zimbabvė',
  },
  {
    value: 'CV',
    label: 'Žaliasis krantas',
  },
];

export const evrkFullWithoutZeros = {
  100000: 'Maisto produktų gamyba',
  101000: 'Mėsos perdirbimas ir konservavimas ir mėsos produktų gamyba',
  101100: 'Mėsos perdirbimas ir konservavimas',
  101200: 'Paukštienos perdirbimas ir konservavimas',
  101300: 'Mėsos ir paukštienos produktų gamyba',
  101310: 'Rūkytų mėsos ir paukštienos produktų gamyba',
  102000: 'Žuvų, vėžiagyvių ir moliuskų perdirbimas ir konservavimas',
  103000: 'Vaisių ir daržovių perdirbimas ir konservavimas',
  103100: 'Bulvių perdirbimas ir konservavimas',
  103110: 'Bulvių miltų gamyba',
  103200: 'Vaisių, uogų ir daržovių sulčių gamyba',
  103900: 'Kitas vaisių ir daržovių perdirbimas ir konservavimas',
  104000: 'Gyvūninių ir augalinių riebalų bei aliejaus gamyba',
  104100: 'Aliejaus ir riebalų gamyba',
  104200: 'Margarino ir panašių valgomųjų riebalų gamyba',
  105000: 'Pieno produktų gamyba',
  105100: 'Pieninių veikla ir sūrių gamyba',
  105200: 'Valgomųjų ledų gamyba',
  106000: 'Grūdų malimo produktų, krakmolo ir krakmolo produktų gamyba',
  106100: 'Grūdų malimo produktų gamyba',
  106110: 'Miltų gamyba',
  106120: 'Kruopų gamyba',
  106200: 'Krakmolo ir krakmolo produktų gamyba',
  106210: 'Gliukozės sirupo ir maltozės gamyba',
  106220: 'Krakmolo gamyba',
  107000: 'Kepyklos ir miltinių produktų gamyba',
  107100: 'Duonos gamyba; šviežių konditerijos kepinių ir pyragaičių gamyba',
  107200:
    'Džiūvėsių ir sausainių gamyba; ilgai išsilaikančių konditerijos kepinių ir pyragaičių gamyba',
  107300: 'Makaronų, vermišelių, kuskuso ir panašių miltinių produktų gamyba',
  108000: 'Kitų maisto produktų gamyba',
  108100: 'Cukraus gamyba',
  108200: 'Kakavos, šokolado ir cukraus saldumynų gamyba',
  108300: 'Arbatos ir kavos apdorojimas ir perdirbimas',
  108400: 'Užgardų ir pagardų gamyba',
  108410: 'Acto gamyba',
  108500: 'Paruoštų valgių ir patiekalų gamyba',
  108600: 'Homogenizuotų ir dietinių maisto produktų gamyba',
  108900: 'Kitų, niekur kitur nepriskirtų, maisto produktų gamyba',
  108910: 'Mielių gamyba',
  109000: 'Paruoštų pašarų gyvuliams gamyba',
  109100: 'Paruoštų pašarų ūkio gyvuliams gamyba',
  109200: 'Paruošto ėdalo naminiams gyvūnėliams gamyba',
  110000: 'Gėrimų gamyba',
  110100: 'Spirito distiliavimas, rektifikavimas ir maišymas',
  110200: 'Vynuogių vyno gamyba',
  110300: 'Sidro ir kitokių vaisių, uogų vynų gamyba',
  110400: 'Kitų nedistiliuotų fermentuotų gėrimų gamyba',
  110500: 'Alaus gamyba',
  110600: 'Salyklo gamyba',
  110700:
    'Nealkoholinių gėrimų gamyba; mineralinio ir kito, pilstomo į butelius, vandens gamyba',
  110710: 'Nealkoholinių gėrimų gamyba',
  110720: 'Natūralaus mineralinio ir kito, pilstomo į butelius, vandens gamyba',
  120000: 'Tabako gaminių gamyba',
  130000: 'Tekstilės gaminių gamyba',
  131000: 'Tekstilės pluoštų paruošimas ir verpimas',
  132000: 'Tekstilės audimas',
  132010: 'Lininių audinių audimas',
  132020: 'Sintetinių audinių audimas',
  133000: 'Tekstilės apdaila',
  139000: 'Kitų tekstilės gaminių ir dirbinių gamyba',
  139100: 'Megztų (trikotažinių) ir nertų medžiagų gamyba',
  139200: 'Gatavų tekstilės dirbinių, išskyrus drabužius, gamyba',
  139300: 'Kilimų ir kilimėlių gamyba',
  139400: 'Virvių, lynų, virvelių ir tinklų gamyba',
  139500:
    'Neaustinių medžiagų ir neaustinių medžiagų gaminių ir dirbinių, išskyrus drabužius, gamyba',
  139600: 'Kitų techninės ir pramoninės tekstilės gaminių ir dirbinių gamyba',
  139900:
    'Kitų, niekur kitur nepriskirtų, tekstilės gaminių ir dirbinių gamyba',
  140000: 'Drabužių siuvimas (gamyba)',
  141000: 'Drabužių, išskyrus kailinius drabužius, siuvimas (gamyba)',
  141100: 'Odinių drabužių siuvimas',
  141200: 'Darbo drabužių siuvimas',
  141300: 'Kitų viršutinių drabužių siuvimas',
  141310: 'Vyriškų viršutinių drabužių siuvimas',
  141320: 'Moteriškų viršutinių drabužių siuvimas',
  141400: 'Apatinių drabužių siuvimas',
  141900: 'Kitų drabužių siuvimas ir drabužių priedų gamyba',
  141910: 'Vaiko drabužėlių siuvimas',
  141920: 'Sportinės aprangos siuvimas',
  141930: 'Kailinių kepurių ir kitų galvos apdangalų  gamyba',
  142000: 'Kailinių dirbinių ir gaminių gamyba',
  143000: 'Megztų (trikotažinių) ir nertų drabužių gamyba',
  143100:
    'Pėdkelnių, triko, kojinių ir kitų panašių megztų (trikotažinių) ir nertų gaminių bei dirbinių gamyba',
  143900: 'Kitų megztų (trikotažinių) ir nertų drabužių gamyba',
  150000: 'Odos ir odos dirbinių gamyba',
  151000:
    'Odų rauginimas ir išdirbimas; lagaminų, rankinių, balno reikmenų ir pakinktų gamyba; kailių išdirbimas ir dažymas',
  151100: 'Odų rauginimas ir išdirbimas; kailių išdirbimas ir dažymas',
  151110: 'Odų rauginimas, naudojant augalines medžiagas',
  151120: 'Odų rauginimas, naudojant mineralines ir chemines medžiagas',
  151130: 'Odų išdirbimas',
  151200:
    'Lagaminų, rankinių ir panašių reikmenų, balno reikmenų ir pakinktų gamyba',
  152000: 'Avalynės gamyba',
  160000:
    'Medienos bei medienos ir kamštienos gaminių, išskyrus baldus, gamyba; gaminių iš šiaudų ir pynimo medžiagų gamyba',
  161000: 'Medienos pjaustymas ir obliavimas',
  161010: 'Cheminis medienos apdorojimas konservantais',
  162000:
    'Gaminių ir dirbinių iš medienos, kamštienos, šiaudų ir pynimo medžiagų gamyba',
  162100: 'Faneravimo dangos ir medienos plokščių gamyba',
  162110: 'Medienos plaušo plokščių, padengtų dekoratyvine plėvele, gamyba',
  162120: 'Presuotos medienos gamyba',
  162200: 'Sumontuotų parketo grindų gamyba',
  162300: 'Kitų statybinių dailidžių ir stalių dirbinių gamyba',
  162310: 'Fasoninių detalių ir malksnų (gontų) stogų dengimui gamyba',
  162320: 'Medinių surenkamųjų statinių ar jų detalių gamyba',
  162330: 'Medinių sieninių plokščių ir spintų gamyba',
  162400: 'Medinės taros gamyba',
  162410: 'Padėklų, stovų ir kitų medinių pakrovimo reikmenų gamyba',
  162900:
    'Kitų medienos gaminių gamyba; dirbinių iš kamštienos, šiaudų ir pynimo medžiagų gamyba',
  170000: 'Popieriaus ir popieriaus gaminių gamyba',
  171000: 'Plaušienos, popieriaus ir kartono gamyba',
  171100: 'Plaušienos gamyba',
  171200: 'Popieriaus ir kartono gamyba',
  172000: 'Popieriaus ir kartoninių gaminių gamyba',
  172100:
    'Gofruotojo popieriaus ir kartono bei taros iš popieriaus ir kartono gamyba',
  172200: 'Buitinių ir higienos reikmenų bei tualeto reikmenų gamyba',
  172210: 'Tualetinio popieriaus gamyba',
  172300: 'Popierinių raštinės reikmenų gamyba',
  172310: 'Kopijavimo popieriaus gamyba',
  172320: 'Vokų ir atvirukų gamyba',
  172400: 'Sienų apmušalų (tapetų) gamyba',
  172900: 'Kitų popierinių ir kartoninių gaminių gamyba',
  172910: 'Filtravimo popieriaus gamyba',
  180000: 'Spausdinimas ir įrašytų laikmenų tiražavimas',
  181000: 'Spausdinimas ir su spausdinimu susijusios paslaugos',
  181100: 'Laikraščių spausdinimas',
  181200: 'Kitas spausdinimas',
  181300: 'Parengiamoji spausdinimo ir žiniasklaidos veikla',
  181400: 'Įrišimas ir susijusios paslaugos',
  182000: 'Įrašytų laikmenų tiražavimas',
  190000: 'Kokso ir rafinuotų naftos produktų gamyba',
  191000: 'Koksavimo krosnių produktų gamyba',
  192000: 'Rafinuotų naftos produktų gamyba',
  192010: 'Skystojo arba dujinio kuro gamyba',
  192020: 'Tepalų ir kitų alyvų gamyba',
  192030: 'Parafino ir techninio vazelino  gamyba',
  192040: 'Naftos bitumo gamyba',
  200000: 'Chemikalų ir chemijos produktų gamyba',
  201000:
    'Pagrindinių chemikalų, trąšų ir azoto junginių, pirminių plastikų ir pirminio sintetinio kaučiuko gamyba',
  201100: 'Pramoninių dujų gamyba',
  201200: 'Dažiklių ir pigmentų gamyba',
  201300: 'Kitų pagrindinių neorganinių chemikalų gamyba',
  201400: 'Kitų pagrindinių organinių chemikalų gamyba',
  201500: 'Trąšų ir azoto junginių gamyba',
  201510: 'Grynųjų ar kompleksinių azoto ir fosforo trąšų gamyba',
  201520: 'Karbamido gamyba',
  201530: 'Amoniako gamyba',
  201600: 'Pirminių plastikų gamyba',
  201700: 'Pirminio sintetinio kaučiuko gamyba',
  202000: 'Pesticidų ir kitų agrocheminių medžiagų gamyba',
  202010: 'Dezinfekantų gamyba',
  203000:
    'Dažų, lakų ir panašių dangų medžiagų, spaustuvinių dažų ir mastikų gamyba',
  203010: 'Emalinių dažų ir lakų gamyba',
  203020: 'Organinių sudėtingų tirpiklių ir skiediklių gamyba',
  204000:
    'Muilo ir ploviklių, valiklių ir blizgiklių, kvepalų ir tualeto priemonių gamyba',
  204100: 'Muilo ir ploviklių, valiklių ir blizgiklių gamyba',
  204200: 'Kvepalų ir tualeto priemonių gamyba',
  205000: 'Kitų cheminių medžiagų gamyba',
  205100: 'Sprogiųjų medžiagų gamyba',
  205200: 'Klijų gamyba',
  205300: 'Eterinio aliejaus gamyba',
  205900: 'Kitų, niekur kitur nepriskirtų, cheminių medžiagų gamyba',
  205910: 'Lydymo, litavimo ar suvirinimo miltelių ir pastų gamyba',
  205920:
    'Aktyvintų anglių, tepalų priedų, paruoštų kaučiuko vulkanizavimo greitiklių, katalizatorių gamyba',
  205930: 'Antidetonatorių, antifrizų, skysčių hidraulinėms sistemoms gamyba',
  205940: 'Rašalo ir tušo gamyba',
  206000: 'Cheminių pluoštų gamyba',
  210000: 'Pagrindinių vaistų pramonės gaminių ir farmacinių preparatų gamyba',
  211000: 'Pagrindinių vaistų pramonės gaminių gamyba',
  211010: 'Narkotinių medžiagų gamyba',
  212000: 'Farmacinių preparatų gamyba',
  212010: 'Vaistų gamyba',
  212020: 'Veterinarinių vaistų gamyba',
  220000: 'Guminių ir plastikinių gaminių gamyba',
  221000: 'Guminių gaminių gamyba',
  221100:
    'Guminių padangų ir kamerų gamyba; guminių padangų restauravimas ir atnaujinimas',
  221900: 'Kitų guminių gaminių gamyba',
  222000: 'Plastikinių gaminių gamyba',
  222100: 'Plastikinių plokščių, lakštų, vamzdžių ir profiliuočių gamyba',
  222200: 'Plastikinių pakuočių gamyba',
  222300: 'Plastikinių statybos dirbinių gamyba',
  222900: 'Kitų plastikinių gaminių gamyba',
  230000: 'Kitų nemetalo mineralinių produktų gamyba',
  231000: 'Stiklo ir stiklo gaminių bei dirbinių gamyba',
  231100: 'Plokščiojo stiklo gamyba',
  231200: 'Plokščiojo stiklo pjaustymas ir apdorojimas',
  231300: 'Tuščiavidurio stiklo gamyba',
  231310: 'Stiklinių talpyklų gamyba',
  231400: 'Stiklo pluoštų gamyba',
  231900:
    'Kito stiklo, įskaitant skirto techninėms reikmėms, gamyba ir apdorojimas',
  231910:
    'Stiklinių izoliatorių ir stiklinių izoliacinių jungiamųjų detalių gamyba',
  232000: 'Ugniai atsparių gaminių gamyba',
  233000: 'Statybinių medžiagų iš molio gamyba',
  233100: 'Keraminių apdailos ir šaligatvio plytelių gamyba',
  233110: 'Koklių gamyba',
  233120: 'Keraminių grindinio dangų gamyba',
  233200: 'Degto molio plytų, plytelių ir statybinių dirbinių gamyba',
  234000: 'Kitų porcelianinių ir keraminių gaminių bei dirbinių gamyba',
  234100: 'Keraminių buities ir puošybos gaminių bei dirbinių gamyba',
  234200: 'Keraminių santechnikos gaminių ir dirbinių gamyba',
  234300: 'Keraminių izoliatorių ir keraminių izoliacinių detalių gamyba',
  234400: 'Kitų techninės paskirties keraminių gaminių ir dirbinių gamyba',
  234900: 'Kitų keraminių gaminių ir dirbinių gamyba',
  235000: 'Cemento, kalkių ir gipso gamyba',
  235100: 'Cemento gamyba',
  235200: 'Kalkių ir gipso gamyba',
  236000: 'Betono, cemento ir gipso gaminių bei dirbinių gamyba',
  236100: 'Betono gaminių, skirtų statybinėms reikmėms, gamyba',
  236200: 'Gipso gaminių, naudojamų statybinėms reikmėms, gamyba',
  236300: 'Prekinio betono mišinio gamyba',
  236400: 'Statybinių skiedinių gamyba',
  236500: 'Fibrolito gamyba',
  236900: 'Kitų betono, gipso ir cemento gaminių bei dirbinių gamyba',
  237000: 'Akmens pjaustymas, tašymas ir apdailinimas',
  239000:
    'Abrazyvinių gaminių ir niekur kitur nepriskirtų nemetalo mineralinių produktų gamyba',
  239100: 'Abrazyvinių gaminių gamyba',
  239900:
    'Kitų, niekur kitur nepriskirtų, nemetalo mineralinių produktų gamyba',
  239910: 'Asbestinio pluošto, verpalų ir audinių bei gaminių iš jų gamyba',
  239920: 'Frikcinių medžiagų ir jų gaminių gamyba',
  239930: 'Mineralinių izoliacinių medžiagų gamyba',
  239940: 'Dirbinių iš bitumo gamyba',
  240000: 'Pagrindinių metalų gamyba',
  241000: 'Tomo ketaus ir plieno bei ferolydinių gamyba',
  242000:
    'Plieninių vamzdžių, vamzdelių, tuščiavidurių profilių ir susijusių jungiamųjų detalių gamyba',
  243000: 'Kitų plieno pirminio apdirbimo gaminių gamyba',
  243100: 'Šaltasis strypų tempimas',
  243200: 'Šaltasis siaurų juostų valcavimas',
  243300: 'Šaltasis formavimas ar lankstymas',
  243400: 'Šaltasis vielos tempimas',
  244000: 'Pagrindinių tauriųjų ir kitų spalvotųjų metalų gamyba',
  244100: 'Tauriųjų metalų gamyba',
  244200: 'Aliuminio gamyba',
  244300: 'Švino, cinko ir alavo gamyba',
  244400: 'Vario gamyba',
  244500: 'Kitų spalvotųjų metalų gamyba',
  244600: 'Branduolinio kuro perdirbimas',
  245000: 'Metalų liejinių gamyba',
  245100: 'Geležies liejinių gamyba',
  245200: 'Plieno liejinių gamyba',
  245300: 'Lengvųjų metalų liejinių gamyba',
  245400: 'Kitų spalvotųjų metalų liejinių gamyba',
  250000: 'Metalo gaminių, išskyrus mašinas ir įrenginius, gamyba',
  251000: 'Konstrukcinių metalo gaminių gamyba',
  251100: 'Metalo konstrukcijų ir jų dalių gamyba',
  251200: 'Metalinių durų ir langų gamyba',
  252000: 'Metalinių cisternų, rezervuarų ir talpyklų gamyba',
  252100: 'Centrinio šildymo radiatorių ir katilų gamyba',
  252900: 'Kitų metalinių cisternų, rezervuarų ir talpyklų gamyba',
  252910: 'Metalinių talpyklų suslėgtosioms ar suskystintosioms dujoms gamyba',
  253000:
    'Garo generatorių, išskyrus centrinio šildymo karšto vandens katilus, gamyba',
  254000: 'Ginklų ir šaudmenų gamyba',
  255000:
    'Metalo kalimas, presavimas, štampavimas ir profiliavimas; miltelių metalurgija',
  256000: 'Metalų apdorojimas ir dengimas; mechaninis apdirbimas',
  256100: 'Metalų apdorojimas ir dengimas',
  256200: 'Mechaninis apdirbimas',
  257000: 'Valgomųjų įrankių, kitų įrankių ir bendrųjų metalo dirbinių gamyba',
  257100: 'Valgomųjų ir kitų pjovimo įrankių gamyba',
  257200: 'Spynų ir vyrių gamyba',
  257300: 'Kitų įrankių gamyba',
  259000: 'Kitų metalo gaminių gamyba',
  259100: 'Plieninių statinių ir panašių talpyklų gamyba',
  259200: 'Lengvųjų metalų taros gamyba',
  259300: 'Vielos gaminių, grandinių ir spyruoklių gamyba',
  259400: 'Tvirtinimo detalių ir sriegimo mašinų gaminių gamyba',
  259900: 'Kitų, niekur kitur nepriskirtų, metalo gaminių gamyba',
  260000: 'Kompiuterinių, elektroninių ir optinių gaminių gamyba',
  261000: 'Elektroninių komponentų ir plokščių gamyba',
  261100: 'Elektroninių komponentų gamyba',
  261200: 'Sumontuotų elektroninių plokščių gamyba',
  262000: 'Kompiuterių ir išorinės įrangos gamyba',
  263000: 'Ryšių įrangos gamyba',
  264000: 'Vartotojiškos elektroninės įrangos gamyba',
  265000:
    'Matavimo, bandymo, navigacinės ir kontrolės įrangos gamyba; įvairių tipų laikrodžių gamyba',
  265100:
    'Matavimo, bandymo, navigacinės ir kontrolės įrangos prietaisų ir aparatų gamyba',
  265110: 'Elektros skaitiklių gamyba',
  265120: 'Vandens skaitiklių gamyba',
  265130: 'Dujų skaitiklių gamyba',
  265140: 'Automatinio reguliavimo ir kontrolės prietaisų gamyba',
  265200: 'Įvairių tipų laikrodžių gamyba',
  266000: 'Švitinimo, elektromedicininės ir elektroterapinės įrangos gamyba',
  267000: 'Optinių prietaisų ir fotografijos įrangos gamyba',
  268000: 'Magnetinių ir optinių laikmenų gamyba',
  270000: 'Elektros įrangos gamyba',
  271000:
    'Elektros variklių, generatorių, transformatorių bei elektros skirstomosios ir valdymo įrangos gamyba',
  271100: 'Elektros variklių, generatorių ir transformatorių gamyba',
  271200: 'Elektros skirstomosios ir valdymo įrangos gamyba',
  272000: 'Baterijų ir akumuliatorių gamyba',
  273000: 'Laidų ir instaliacijos įtaisų gamyba',
  273100: 'Skaidulinės optikos kabelių gamyba',
  273200: 'Kitų elektronikos bei elektros laidų ir kabelių gamyba',
  273300: 'Instaliacijos įtaisų gamyba',
  274000: 'Elektros apšvietimo įrangos gamyba',
  275000: 'Buitinių aparatų ir prietaisų gamyba',
  275100: 'Buitinių elektrinių aparatų ir prietaisų gamyba',
  275200: 'Buitinių neelektrinių aparatų ir prietaisų gamyba',
  279000: 'Kitos elektros įrangos gamyba',
  280000: 'Niekur kitur nepriskirtų mašinų ir įrangos gamyba',
  281000: 'Bendrosios paskirties mašinų gamyba',
  281100:
    'Variklių ir turbinų, išskyrus orlaivių, transporto priemonių ir motociklų variklius, gamyba',
  281200: 'Hidraulinės energijos įrangos gamyba',
  281300: 'Kitų siurblių ir kompresorių gamyba',
  281400: 'Kitų čiaupų ir sklendžių gamyba',
  281500:
    'Guolių, krumpliaračių, krumplinių pavarų ir varomųjų elementų gamyba',
  282000: 'Kitų bendrosios paskirties mašinų ir įrangos gamyba',
  282100: 'Orkaičių, krosnių ir krosnių degiklių gamyba',
  282200: 'Kėlimo ir krovimo įrangos gamyba',
  282300:
    'Įstaigos mašinų ir įrangos (išskyrus kompiuterius ir išorinę įrangą) gamyba',
  282400: 'Variklinių rankinių įrankių gamyba',
  282500: 'Nebuitinių aušinimo ir vėdinimo įrenginių gamyba',
  282900:
    'Kitų, niekur kitur nepriskirtų, bendrosios paskirties mašinų ir įrangos gamyba',
  283000: 'Žemės ir miškų ūkio mašinų gamyba',
  284000: 'Metalo formavimo įrangos ir staklių gamyba',
  284100: 'Metalo formavimo įrangos gamyba',
  284900: 'Kitų staklių gamyba',
  289000: 'Kitų specialiosios paskirties mašinų gamyba',
  289100: 'Metalurgijos mašinų gamyba',
  289200: 'Kasybos, karjerų eksploatavimo ir statybos mašinų gamyba',
  289300: 'Maisto, gėrimų ir tabako apdorojimo mašinų gamyba',
  289400: 'Tekstilės, drabužių ir odos gaminių gamybos mašinų gamyba',
  289500: 'Popieriaus ir kartono gamybos mašinų gamyba',
  289600: 'Plastikų ir gumos gamybos mašinų gamyba',
  289900:
    'Kitų, niekur kitur nepriskirtų, specialiosios paskirties mašinų gamyba',
  290000: 'Variklinių transporto priemonių, priekabų ir puspriekabių gamyba',
  291000: 'Variklinių transporto priemonių gamyba',
  292000:
    'Variklinių transporto priemonių kėbulų gamyba; priekabų ir puspriekabių gamyba',
  293000: 'Variklinių transporto priemonių dalių ir pagalbinių reikmenų gamyba',
  293100:
    'Variklinių transporto priemonių elektros ir elektroninės įrangos gamyba',
  293200: 'Variklinių transporto priemonių kitų dalių ir reikmenų gamyba',
  300000: 'Kitų transporto priemonių ir įrangos gamyba',
  301000: 'Įvairių tipų laivų statyba',
  301100: 'Laivų ir plūdriųjų konstrukcijų statyba',
  301200: 'Pramoginių ir sportinių katerių (laivų) statyba',
  302000: 'Geležinkelio lokomotyvų bei riedmenų gamyba',
  303000: 'Orlaivių ir erdvėlaivių bei susijusios įrangos gamyba',
  304000: 'Karinių kovinių transporto priemonių gamyba',
  309000: 'Niekur kitur nepriskirtų transporto priemonių gamyba',
  309100: 'Motociklų gamyba',
  309200: 'Dviračių ir invalidų vežimėlių gamyba',
  309900: 'Kitos, niekur kitur nepriskirtos, transporto įrangos gamyba',
  310000: 'Baldų gamyba',
  310100: 'Įstaigos ir prekybos įmonių (parduotuvių) baldų gamyba',
  310200: 'Virtuvės baldų gamyba',
  310300: 'Čiužinių gamyba',
  310900: 'Kitų baldų gamyba',
  320000: 'Kita gamyba',
  321000: 'Papuošalų, juvelyrinių, bižuterijos ir panašių dirbinių gamyba',
  321100: 'Monetų kalimas',
  321200: 'Papuošalų, juvelyrinių ir panašių dirbinių gamyba',
  321300: 'Dirbtinės bižuterijos ir panašių dirbinių gamyba',
  322000: 'Muzikos instrumentų gamyba',
  323000: 'Sporto reikmenų gamyba',
  324000: 'Žaidimų ir žaislų gamyba',
  324010: 'Konstravimo rinkinių gamyba',
  324020: 'Plastikinių žaislų gamyba',
  324030: 'Medinių žaislų gamyba',
  324040: 'Kimštinių žaislų gamyba',
  324050: 'Stalo arba kambario žaidimų gamyba',
  324060: 'Plastikinių triračių ir kitų žaislų su ratukais gamyba',
  324070: 'Elektrinių žaislų ir žaidimų gamyba',
  325000:
    'Medicinos ir odontologijos prietaisų, instrumentų ir reikmenų gamyba',
  325010: 'Odontologinio cemento gamyba',
  325020: 'Ortopedinių ir kitokių įtaisų gamyba',
  325030: 'Protezų gamyba',
  329000: 'Niekur kitur nepriskirta gamyba',
  329100: 'Šluotų ir šepečių gamyba',
  329900: 'Kita, niekur kitur nepriskirta, gamyba',
  329910: 'Medinių karstų ir kitų laidojimo reikmenų gamyba',
  330000: 'Mašinų ir įrangos remontas ir įrengimas',
  331000: 'Metalo gaminių, mašinų ir įrangos remontas',
  331100: 'Metalo gaminių remontas',
  331200: 'Mašinų remontas',
  331300: 'Elektroninės ir optinės įrangos remontas',
  331400: 'Elektros įrangos remontas',
  331500: 'Įvairių tipų laivų remontas ir techninė priežiūra',
  331600: 'Orlaivių ir erdvėlaivių remontas ir techninė priežiūra',
  331700: 'Kitų transporto priemonių remontas ir techninė priežiūra',
  331900: 'Kitos įrangos remontas',
  332000: 'Pramoninių mašinų ir įrangos įrengimas',
  350000: 'Elektros, dujų, garo tiekimas ir oro kondicionavimas',
  351000: 'Elektros energijos gamyba, perdavimas ir paskirstymas',
  351100: 'Elektros gamyba',
  351200: 'Elektros perdavimas',
  351300: 'Elektros paskirstymas',
  351400: 'Elektros pardavimas',
  352000: 'Dujų gamyba; dujinio kuro paskirstymas dujotiekiais',
  352100: 'Dujų gamyba',
  352200: 'Dujinio kuro paskirstymas dujotiekiais',
  352300: 'Dujų pardavimas dujotiekiais',
  353000: 'Garo tiekimas ir oro kondicionavimas',
  353010: 'Garo tiekimas',
  353020: 'Karšto vandens tiekimas',
  360000: 'Vandens surinkimas, valymas ir tiekimas',
  360010: 'Vandens surinkimas ir valymas',
  360020: 'Vandens tiekimas pramonės įmonėms',
  360030: 'Vandens tiekimas gyventojams',
  370000: 'Nuotekų valymas',
  380000: 'Atliekų surinkimas, tvarkymas ir šalinimas; medžiagų atgavimas',
  381000: 'Atliekų surinkimas',
  381100: 'Nepavojingų atliekų surinkimas',
  381200: 'Pavojingų atliekų surinkimas',
  382000: 'Atliekų tvarkymas ir šalinimas',
  382100: 'Nepavojingų atliekų tvarkymas ir šalinimas',
  382200: 'Pavojingų atliekų tvarkymas ir šalinimas',
  383000: 'Medžiagų atgavimas',
  383100: 'Mašinų duženų išmontavimas',
  383200: 'Išrūšiuotų medžiagų atgavimas',
  390000: 'Regeneravimas ir kita atliekų tvarkyba',
  410000: 'Pastatų statyba',
  411000: 'Statybų plėtra',
  412000: 'Gyvenamųjų ir negyvenamųjų pastatų statyba',
  412010: 'Naujų pastatų statyba',
  412020: 'Pastatų remontas, restauravimas ir  rekonstravimas',
  420000: 'Inžinerinių statinių statyba',
  421000: 'Kelių ir geležinkelių tiesimas',
  421100: 'Kelių ir automagistralių tiesimas',
  421110: 'Kelio dangos ir stovėjimo aikštelių ženklinimas',
  421200: 'Geležinkelių ir požeminių geležinkelių tiesimas',
  421300: 'Tiltų ir tunelių statyba',
  422000: 'Komunalinių statinių statyba',
  422100: 'Komunalinių nuotekų statinių statyba',
  422200: 'Komunalinių elektros ir telekomunikacijos statinių statyba',
  429000: 'Kitų inžinerinių statinių statyba',
  429100: 'Vandens statinių statyba',
  429900: 'Kitų, niekur kitur nepriskirtų, inžinerinių statinių statyba',
  430000: 'Specializuota statybos veikla',
  431000: 'Statinių nugriovimas ir statybvietės paruošimas',
  431100: 'Statinių nugriovimas',
  431200: 'Statybvietės paruošimas',
  431210: 'Paruošiamieji žemės kasimo darbai tiesiant kelius',
  431220: 'Žemės darbai statybos aikštelėse',
  431230: 'Sausinimo ir drėkinimo sistemų tiesimas',
  431300: 'Žvalgomasis gręžimas',
  432000: 'Elektros, vandentiekio ir kitos įrangos įrengimas',
  432100: 'Elektros sistemų įrengimas',
  432110: 'Pastatų ir statinių elektros tinklų įrengimas',
  432120: 'Gaisro pavojaus ir apsaugos signalizacijos įrengimas',
  432130: 'Antenų, ryšio sistemų įrengimas',
  432200: 'Vandentiekio, šildymo ir oro kondicionavimo sistemų įrengimas',
  432900: 'Kitos įrangos įrengimas',
  433000: 'Statybos baigimas ir apdaila',
  433100: 'Tinkavimas',
  433200: 'Staliaus dirbinių įrengimas',
  433300: 'Grindų ir sienų dengimas',
  433400: 'Dažymas ir stiklinimas',
  433900: 'Kiti statybos baigiamieji ir apdailos darbai',
  439000: 'Kita specializuota statybos veikla',
  439100: 'Stogų dengimas',
  439900: 'Kita, niekur kitur nepriskirta, specializuota statybos veikla',
  450000:
    'Variklinių transporto priemonių ir motociklų didmeninė ir mažmeninė prekyba bei remontas',
  451000: 'Variklinių transporto priemonių pardavimas',
  451100: 'Automobilių ir lengvųjų variklinių transporto priemonių pardavimas',
  451900: 'Kitų variklinių transporto priemonių pardavimas',
  452000: 'Variklinių transporto priemonių techninė priežiūra ir remontas',
  453000:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų pardavimas',
  453100:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų didmeninė prekyba',
  453200:
    'Variklinių transporto priemonių atsarginių dalių ir pagalbinių reikmenų mažmeninė prekyba',
  454000:
    'Motociklų ir jų atsarginių dalių bei pagalbinių reikmenų pardavimas, techninė priežiūra ir remontas',
  460000:
    'Didmeninė prekyba, išskyrus prekybą variklinėmis transporto priemonėmis ir motociklais',
  461000: 'Didmeninė prekyba už atlygį ar pagal sutartį',
  461100:
    'Žemės ūkio žaliavų, gyvų gyvulių, tekstilės žaliavų ir pusgaminių pardavimo agentų veikla',
  461200:
    'Degalų, rūdų, metalų ir pramoninių cheminių preparatų pardavimo agentų veikla',
  461300: 'Statybinio miško ir statybinių medžiagų pardavimo agentų veikla',
  461400: 'Mašinų, pramonės įrangos, laivų ir lėktuvų pardavimo agentų veikla',
  461500:
    'Baldų, namų ūkio reikmenų, metalo ir geležies dirbinių pardavimo agentų veikla',
  461600:
    'Tekstilės, drabužių, kailių, avalynės ir odos dirbinių pardavimo agentų veikla',
  461700: 'Maisto produktų, gėrimų ir tabako pardavimo agentų veikla',
  461800: 'Kitų specifinių produktų pardavimo agentų specializuota veikla',
  461900: 'Įvairių prekių pardavimo agentų veikla',
  462000: 'Žemės ūkio žaliavų ir gyvų gyvulių didmeninė prekyba',
  462100:
    'Grūdų, neperdirbto tabako, sėklų ir pašarų gyvuliams didmeninė prekyba',
  462200: 'Gėlių ir sodinukų didmeninė prekyba',
  462300: 'Gyvų gyvulių didmeninė prekyba',
  462400: 'Kailių ir odų didmeninė prekyba',
  463000: 'Maisto produktų, gėrimų, tabako ir jo gaminių didmeninė prekyba',
  463100: 'Vaisių, uogų ir daržovių didmeninė prekyba',
  463200: 'Mėsos ir mėsos produktų didmeninė prekyba',
  463300:
    'Pieno produktų, kiaušinių bei valgomųjų aliejaus ir riebalų didmeninė prekyba',
  463400: 'Gėrimų didmeninė prekyba',
  463410: 'Alkoholinių gėrimų didmeninė prekyba',
  463420: 'Nealkoholinių gėrimų didmeninė prekyba',
  463500: 'Tabako gaminių didmeninė prekyba',
  463600: 'Cukraus, šokolado ir cukraus saldumynų didmeninė prekyba',
  463700: 'Kavos, arbatos, kakavos ir prieskonių didmeninė prekyba',
  463800:
    'Kitų maisto produktų, įskaitant žuvis, vėžiagyvius ir moliuskus, didmeninė prekyba',
  463900: 'Maisto produktų, gėrimų ir tabako nespecializuota didmeninė prekyba',
  464000: 'Namų ūkio reikmenų didmeninė prekyba',
  464100: 'Tekstilės gaminių didmeninė prekyba',
  464200: 'Drabužių ir avalynės didmeninė prekyba',
  464210: 'Guminės avalynės didmeninė prekyba',
  464220: 'Odinės avalynės didmeninė prekyba',
  464230: 'Kailinių gaminių didmeninė prekyba',
  464240: 'Drabužių ir drabužių priedų didmeninė prekyba',
  464300: 'Elektrinių buitinių aparatų arba prietaisų didmeninė prekyba',
  464400:
    'Porceliano ir stiklo dirbinių bei valymo priemonių didmeninė prekyba',
  464410: 'Porceliano ir stiklo dirbinių didmeninė prekyba',
  464420: 'Valymo priemonių didmeninė prekyba',
  464500: 'Kvepalų ir kosmetikos priemonių didmeninė prekyba',
  464600: 'Farmacijos prekių didmeninė prekyba',
  464610:
    'Chirurginių ir ortopedinių instrumentų bei protezų didmeninė prekyba',
  464620: 'Vaistų ir vaistinių medžiagų didmeninė prekyba',
  464700: 'Baldų, kilimų ir apšvietimo įrangos didmeninė prekyba',
  464800: 'Laikrodžių, papuošalų ir juvelyrinių dirbinių didmeninė prekyba',
  464900: 'Kitų namų ūkio reikmenų didmeninė prekyba',
  465000: 'Informacijos ir ryšių technologijų (IRT) įrangos didmeninė prekyba',
  465100: 'Kompiuterių, jų išorinės ir programinės įrangos didmeninė prekyba',
  465200:
    'Elektroninės ir telekomunikacinės įrangos ir jos dalių didmeninė prekyba',
  466000: 'Kitų mašinų, įrangos ir reikmenų didmeninė prekyba',
  466100: 'Žemės ūkio mašinų, įrangos ir reikmenų didmeninė prekyba',
  466200: 'Staklių didmeninė prekyba',
  466300: 'Kasybos, statybos ir statybos inžinerijos mašinų didmeninė prekyba',
  466400:
    'Tekstilės pramonės mašinų bei siuvamųjų ir mezgimo mašinų didmeninė prekyba',
  466500: 'Įstaigos baldų didmeninė prekyba',
  466600: 'Kitų įstaigos mašinų ir įrangos didmeninė prekyba',
  466900: 'Kitų mašinų ir įrangos didmeninė prekyba',
  467000: 'Kita specializuota didmeninė prekyba',
  467100: 'Kietojo, skystojo ir dujinio kuro bei priedų didmeninė prekyba',
  467110: 'Kietojo kuro didmeninė prekyba',
  467120: 'Benzino didmeninė prekyba',
  467130: 'Dujinio kuro didmeninė prekyba',
  467140: 'Dyzelinio kuro didmeninė prekyba',
  467150: 'Tepalų didmeninė prekyba',
  467200: 'Metalų rūdų ir metalų didmeninė prekyba',
  467300:
    'Medienos, statybinių medžiagų ir sanitarinių įrenginių didmeninė prekyba',
  467310: 'Popierinių sienų apmušalų didmeninė prekyba',
  467400:
    'Metalinių dirbinių, vandentiekio ir šildymo įrangos bei reikmenų didmeninė prekyba',
  467410: 'Geležies dirbinių didmeninė prekyba',
  467420: 'Vandentiekio įrangos didmeninė prekyba',
  467430: 'Šildymo įrangos didmeninė prekyba',
  467500: 'Chemijos produktų didmeninė prekyba',
  467510: 'Agrocheminių produktų ir trąšų didmeninė prekyba',
  467520:
    'Ne vaistinės paskirties stipriai veikiančiųjų ir nuodingųjų medžiagų didmeninė prekyba',
  467600: 'Kitų tarpinių produktų didmeninė prekyba',
  467610: 'Popieriaus ritinių ir kartono didmeninė prekyba',
  467620: 'Linų pluošto didmeninė prekyba',
  467700: 'Atliekų ir laužo didmeninė prekyba',
  469000: 'Nespecializuota didmeninė prekyba',
  470000:
    'Mažmeninė prekyba, išskyrus variklinių transporto priemonių ir motociklų prekybą',
  471000: 'Mažmeninė prekyba nespecializuotose parduotuvėse',
  471100:
    'Mažmeninė prekyba nespecializuotose parduotuvėse, kuriose vyrauja maistas, gėrimai ir tabakas',
  471900: 'Kita mažmeninė prekyba nespecializuotose parduotuvėse',
  472000:
    'Maisto, gėrimų ir tabako mažmeninė prekyba specializuotose parduotuvėse',
  472100:
    'Vaisių, uogų ir daržovių mažmeninė prekyba specializuotose parduotuvėse',
  472200:
    'Mėsos ir mėsos produktų mažmeninė prekyba specializuotose parduotuvėse',
  472300:
    'Žuvų, vėžiagyvių ir moliuskų mažmeninė prekyba specializuotose parduotuvėse',
  472400:
    'Duonos, bandelių, konditerijos gaminių ir cukraus saldumynų mažmeninė prekyba specializuotose parduotuvėse',
  472410: 'Duonos, pyrago ir kitų miltinių produktų mažmeninė prekyba',
  472420: 'Konditerijos gaminių ir saldumynų mažmeninė prekyba',
  472500: 'Gėrimų mažmeninė prekyba specializuotose parduotuvėse',
  472510: 'Alkoholinių gėrimų mažmeninė prekyba',
  472520: 'Gaivinamųjų gėrimų mažmeninė prekyba',
  472600: 'Tabako gaminių mažmeninė prekyba specializuotose parduotuvėse',
  472900: 'Kita maisto produktų mažmeninė prekyba specializuotose parduotuvėse',
  473000: 'Automobilių degalų mažmeninė prekyba specializuotose parduotuvėse',
  474000:
    'Informacijos ir ryšių technologijų (IRT) įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474100:
    'Kompiuterių, jų išorinės ir programinės įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474200:
    'Telekomunikacijų įrangos mažmeninė prekyba specializuotose parduotuvėse',
  474300:
    'Garso ir vaizdo įrangos mažmeninė prekyba specializuotose parduotuvėse',
  475000:
    'Kitos namų ūkio įrangos mažmeninė prekyba specializuotose parduotuvėse',
  475100: 'Tekstilės gaminių mažmeninė prekyba specializuotose parduotuvėse',
  475200:
    'Metalo dirbinių, dažų ir stiklo mažmeninė prekyba specializuotose parduotuvėse',
  475210: 'Dažų, lakų ir politūrų mažmeninė prekyba',
  475220: 'Plokščiojo stiklo mažmeninė prekyba',
  475230: 'Statybinių medžiagų mažmeninė prekyba',
  475240: 'Medžiagų ir smulkiojo remonto darbams įrangos mažmeninė prekyba',
  475300:
    'Kilimų, kilimėlių, sienų ir grindų dangų mažmeninė prekyba specializuotose parduotuvėse',
  475400:
    'Elektrinių buitinių aparatų ir prietaisų mažmeninė prekyba specializuotose parduotuvėse',
  475900:
    'Baldų, apšvietimo įrangos ir kitų namų ūkio prekių mažmeninė prekyba specializuotose parduotuvėse',
  476000:
    'Kultūros ir poilsio prekių mažmeninė prekyba specializuotose parduotuvėse',
  476100: 'Knygų mažmeninė prekyba specializuotose parduotuvėse',
  476200:
    'Laikraščių ir raštinės reikmenų mažmeninė prekyba specializuotose parduotuvėse',
  476300:
    'Muzikos ir vaizdo įrašų mažmeninė prekyba specializuotose parduotuvėse',
  476400: 'Sporto įrangos mažmeninė prekyba specializuotose parduotuvėse',
  476500: 'Žaidimų ir žaislų mažmeninė prekyba specializuotose parduotuvėse',
  477000: 'Kitų prekių mažmeninė prekyba specializuotose parduotuvėse',
  477100: 'Drabužių mažmeninė prekyba specializuotose parduotuvėse',
  477200:
    'Avalynės ir odos gaminių mažmeninė prekyba specializuotose parduotuvėse',
  477300: 'Vaistinių, ruošiančių ir parduodančių vaistus, veikla',
  477400:
    'Medicinos ir ortopedinių prekių mažmeninė prekyba specializuotose parduotuvėse',
  477500:
    'Kosmetikos ir tualeto reikmenų mažmeninė prekyba specializuotose parduotuvėse',
  477600:
    'Gėlių, sodinukų, sėklų, trąšų, naminių gyvūnėlių ir jų ėdalo mažmeninė prekyba specializuotose parduotuvėse',
  477700:
    'Laikrodžių, papuošalų ir juvelyrinių dirbinių mažmeninė prekyba specializuotose parduotuvėse',
  477800: 'Kita naujų prekių mažmeninė prekyba specializuotose parduotuvėse',
  477810:
    'Suvenyrų, meno dirbinių ir religinių reikmenų specializuota mažmeninė prekyba',
  477820:
    'Buitinio skystojo kuro, dujų balionų, anglies ir malkų specializuota mažmeninė prekyba',
  477830: 'Ginklų ir šaudmenų specializuota mažmeninė prekyba',
  477840: 'Optikos prekių mažmeninė prekyba',
  477890: 'Kita specializuota mažmeninė prekyba',
  477900: 'Naudotų daiktų mažmeninė prekyba parduotuvėse',
  477910: 'Antikvarinių daiktų mažmeninė prekyba',
  478000: 'Mažmeninė prekyba kioskuose ir prekyvietėse',
  478100:
    'Maisto, gėrimų ir tabako gaminių mažmeninė prekyba kioskuose ir prekyvietėse',
  478200:
    'Tekstilės, drabužių ir avalynės mažmeninė prekyba kioskuose ir prekyvietėse',
  478900: 'Kitų prekių mažmeninė prekyba kioskuose ir prekyvietėse',
  479000: 'Mažmeninė prekyba ne parduotuvėse, kioskuose ar prekyvietėse',
  479100: 'Užsakomasis pardavimas paštu arba internetu',
  479900: 'Kita mažmeninė prekyba ne parduotuvėse, kioskuose ar prekyvietėse',
  479910: 'Mažmeninė prekyba per keliaujančius firmos atstovus',
  479920: 'Mažmeninė prekyba iš automatų smulkioms prekėms pardavinėti',
  490000: 'Sausumos transportas ir transportavimas vamzdynais',
  491000: 'Tarpmiestinis keleivinis geležinkelio transportas',
  492000: 'Krovininis geležinkelio transportas',
  493000: 'Kitas keleivinis sausumos transportas',
  493100: 'Keleivių vežimas miesto arba priemiestiniu sausumos transportu',
  493110: 'Keleivių vežimas autobusais mieste ar priemiestyje',
  493120: 'Keleivių vežimas troleibusais mieste ar priemiestyje',
  493200: 'Taksi veikla',
  493900: 'Kitas, niekur kitur nepriskirtas, keleivinis sausumos transportas',
  494000: 'Krovininis kelių transportas ir perkraustymo veikla',
  494100: 'Krovininis kelių transportas',
  494200: 'Perkraustymo veikla',
  495000: 'Transportavimas vamzdynais',
  500000: 'Vandens transportas',
  501000: 'Jūrų ir pakrančių keleivinis vandens transportas',
  502000: 'Jūrų ir pakrančių krovininis vandens transportas',
  503000: 'Vidaus vandenų keleivinis transportas',
  504000: 'Vidaus vandenų krovininis transportas',
  510000: 'Oro transportas',
  511000: 'Keleivinis oro transportas',
  512000: 'Krovininis oro transportas ir kosminis transportas',
  512100: 'Krovininis oro transportas',
  512200: 'Kosminis transportas',
  520000: 'Sandėliavimas ir transportui būdingų paslaugų veikla',
  521000: 'Sandėliavimas ir saugojimas',
  522000: 'Transportui būdingų paslaugų veikla',
  522100: 'Sausumos transportui būdingų paslaugų veikla',
  522110: 'Traukinių eismo organizavimas ir reguliavimas',
  522120: 'Automobilių stovėjimo aikštelių eksploatavimas',
  522130: 'Techninių priemonių, reguliuojančių gatvių judėjimą, eksploatavimas',
  522140: 'Gatvių, kelių, tiltų, tunelių eksploatavimas',
  522150: 'Sauskelių transporto stočių eksploatavimas',
  522160: 'Riedmenų techninė priežiūra ir smulkus remontas',
  522170: 'Geležinkelių eksploatavimas',
  522200: 'Vandens transportui būdingų paslaugų veikla',
  522300: 'Oro transportui būdingų paslaugų veikla',
  522400: 'Krovinių tvarkymas',
  522900: 'Kita transportui būdingų paslaugų veikla',
  522910: 'Muitinės tarpininkų veikla',
  522920: 'Krovinių gabenimo agentų ir ekspeditorių veikla',
  522930: 'Vežimo dokumentų ir kelionės lapų parengimas ir išdavimas',
  530000: 'Pašto ir pasiuntinių (kurjerių) veikla',
  531000:
    'Pašto pagal įpareigojimą teikti universaliąsias paslaugas atliekama veikla',
  532000: 'Kita pašto ir pasiuntinių (kurjerių) veikla',
  550000: 'Apgyvendinimo veikla',
  551000: 'Viešbučių ir panašių laikinų buveinių veikla',
  552000: 'Poilsiautojų ir kita trumpalaikio apgyvendinimo veikla',
  552010: 'Poilsio namų veikla',
  552020: 'Vaikų poilsio stovyklų veikla',
  553000:
    'Poilsinių transporto priemonių, priekabų aikštelių ir stovyklaviečių veikla',
  559000: 'Kita apgyvendinimo veikla',
  560000: 'Maitinimo ir gėrimų teikimo veikla',
  561000: 'Restoranų ir pagaminto valgio teikimo veikla',
  562000:
    'Pagaminto valgio tiekimas renginiams ir kitų maitinimo paslaugų veikla',
  562100: 'Pagaminto valgio tiekimas renginiams',
  562900: 'Kitų maitinimo paslaugų teikimas',
  563000: 'Gėrimų pardavimo vartoti vietoje veikla',
  580000: 'Leidybinė veikla',
  581000: 'Knygų, periodinių leidinių leidyba ir kita leidybinė veikla',
  581100: 'Knygų leidyba',
  581200: 'Žinynų, katalogų ir adresų sąrašų leidyba',
  581300: 'Laikraščių leidyba',
  581400: 'Žurnalų ir periodinių leidinių leidyba',
  581900: 'Kita leidyba',
  582000: 'Programinės įrangos leidyba',
  582100: 'Kompiuterinių žaidimų leidyba',
  582900: 'Kita programinės įrangos leidyba',
  590000:
    'Kino filmų, vaizdo filmų ir televizijos programų gamyba, garso įrašymo ir muzikos įrašų leidybos veikla',
  591000: 'Kino filmų, vaizdo filmų ir televizijos programų rengėjų veikla',
  591100: 'Kino filmų, vaizdo filmų ir televizijos programų gamyba',
  591200:
    'Pagamintų kino filmų, vaizdo filmų ir televizijos programų meninis apipavidalinimas',
  591300: 'Kino filmų, vaizdo filmų ir televizijos programų platinimas',
  591400: 'Kino filmų rodymas',
  592000: 'Garso įrašymas ir muzikos įrašų leidyba',
  600000: 'Programų rengimas ir transliavimas',
  601000: 'Radijo programų transliavimas',
  602000: 'Televizijos programų rengimas ir transliavimas',
  610000: 'Telekomunikacijos',
  611000: 'Laidinio ryšio paslaugų veikla',
  612000: 'Belaidžio ryšio paslaugų veikla',
  613000: 'Palydovinio ryšio paslaugų veikla',
  619000: 'Kitų ryšių paslaugų veikla',
  620000: 'Kompiuterių programavimo, konsultacinė ir susijusi veikla',
  620100: 'Kompiuterių programavimo veikla',
  620200: 'Kompiuterių konsultacinė veikla',
  620300: 'Kompiuterinės įrangos tvarkyba',
  620900: 'Kita informacinių technologijų ir kompiuterių paslaugų veikla',
  630000: 'Informacinių paslaugų veikla',
  631000:
    'Duomenų apdorojimo, interneto serverių paslaugų (prieglobos) ir susijusi veikla; interneto vartų paslaugų veikla',
  631100:
    'Duomenų apdorojimo, interneto serverių paslaugų (prieglobos) ir susijusi veikla',
  631200: 'Interneto vartų paslaugų veikla',
  639000: 'Kita informacinių paslaugų veikla',
  639100: 'Naujienų agentūrų veikla',
  639900: 'Kita, niekur kitur nepriskirta, informacinių paslaugų veikla',
  640000:
    'Finansinių paslaugų veikla, išskyrus draudimą ir pensijų lėšų kaupimą',
  641000: 'Piniginis tarpininkavimas',
  641100: 'Centrinė bankininkystė',
  641900: 'Kitas piniginis tarpininkavimas',
  642000: 'Kontroliuojančiųjų bendrovių veikla',
  643000: 'Trestų, fondų ir panašių finansinių institucijų veikla',
  649000:
    'Kita finansinių paslaugų veikla, išskyrus draudimą ir pensijų lėšų kaupimą',
  649100: 'Finansinė išperkamoji nuoma',
  649200: 'Kitas kredito teikimas',
  649900:
    'Kita, niekur kitur nepriskirta, finansinių paslaugų veikla, išskyrus draudimą ir pensijų lėšų kaupimą',
  650000:
    'Draudimo, perdraudimo ir pensijų lėšų kaupimo, išskyrus privalomąjį socialinį draudimą, veikla',
  651000: 'Draudimas',
  651100: 'Gyvybės draudimas',
  651200: 'Ne gyvybės draudimas',
  651210: 'Kelionių draudimas',
  652000: 'Perdraudimas',
  653000: 'Pensijų lėšų kaupimas',
  660000: 'Pagalbinė finansinių paslaugų ir draudimo veikla',
  661000:
    'Pagalbinė finansinių paslaugų, išskyrus draudimą ir pensijų lėšų kaupimą, veikla',
  661100: 'Finansų rinkos valdymas',
  661200: 'Vertybinių popierių ir prekių sutarčių sudarymo tarpininkavimas',
  661900:
    'Kita pagalbinė finansinių paslaugų, išskyrus draudimą ir pensijų lėšų kaupimą, veikla',
  662000: 'Pagalbinė draudimo ir pensijų lėšų kaupimo veikla',
  662100: 'Rizikos ir žalos vertinimas',
  662200: 'Draudimo agentų ir brokerių veikla',
  662900: 'Kita pagalbinė draudimo ir pensijų lėšų kaupimo veikla',
  663000: 'Fondų valdymo veikla',
  680000: 'Nekilnojamojo turto operacijos',
  681000: 'Nuosavo nekilnojamojo turto pirkimas ir pardavimas',
  682000: 'Nuosavo arba nuomojamo nekilnojamojo turto nuoma ir eksploatavimas',
  683000: 'Nekilnojamojo turto operacijos už atlygį arba pagal sutartį',
  683100: 'Nekilnojamojo turto agentūrų veikla',
  683110: 'Nekilnojamojo turto vertinimas',
  683200: 'Nekilnojamojo turto tvarkyba už atlygį arba pagal sutartį',
  690000: 'Teisinė ir apskaitos veikla',
  691000: 'Teisinė veikla',
  691010:
    'Konsultacijos ir juridinis atstovavimas civilinėse ir baudžiamosiose bylose',
  691020: 'Konsultacijos ir atstovavimas darbo ginčuose',
  691030: 'Bendrieji patarimai ir konsultacijos, teisinių dokumentų rengimas',
  692000:
    'Apskaitos, buhalterijos ir audito veikla; konsultacijos mokesčių klausimais',
  692010: 'Buhalterinė apskaita  ir audito atlikimas',
  692020: 'Finansinių ataskaitų rengimas',
  692030: 'Firmų ir kitų bendrovių komercinių operacijų apskaitos tvarkymas',
  692040: 'Mokesčių konsultacijos',
  700000: 'Pagrindinių buveinių veikla; konsultacinė valdymo veikla',
  701000: 'Pagrindinių buveinių veikla',
  702000: 'Konsultacinė valdymo veikla',
  702100: 'Viešųjų ryšių ir komunikacijos veikla',
  702200: 'Konsultacinė verslo ir kito valdymo veikla',
  710000:
    'Architektūros ir inžinerijos veikla; techninis tikrinimas ir analizė',
  711000:
    'Architektūros ir inžinerijos veikla bei su ja susijusios techninės konsultacijos',
  711100: 'Architektūros veikla',
  711200: 'Inžinerijos veikla ir su ja susijusios techninės konsultacijos',
  711210: 'Inžinerinė - technologinė veikla',
  711220: 'Projektiniai - konstruktoriniai darbai',
  711230: 'Gatvių eismo projektavimas',
  711240: 'Geodezinė veikla',
  711250: 'Geologinės paieškos ir žvalgyba',
  711260: 'Miškotvarka ir žemėtvarka',
  712000: 'Techninis tikrinimas ir analizė',
  712010: 'Aplinkos kontrolė, ekologinis monitoringas',
  712020: 'Medžiagų bandymai ir analizė',
  712030: 'Produkcijos kokybės bandymai ir analizė',
  712040:
    'Maisto produktų tyrimas pagal higienos reikalavimus, įskaitant veterinarinį, susijusį su maisto produktų gamyba, tyrimą ir tikrinimą',
  712050:
    'Variklinių transporto priemonių saugaus eismo periodinis patikrinimas',
  712060: 'Matavimo priemonių patikra bei kalibravimas',
  712070:
    'Gaminių sertifikavimas, jų normatyvinių dokumentų reikalavimų kontrolė',
  720000: 'Moksliniai tyrimai ir taikomoji veikla',
  721000: 'Gamtos mokslų ir inžinerijos moksliniai tyrimai ir taikomoji veikla',
  721100: 'Biotechnologijos moksliniai tyrimai ir taikomoji veikla',
  721900:
    'Kiti gamtos mokslų ir inžinerijos moksliniai tyrimai ir taikomoji veikla',
  721910: 'Agrarinių mokslų tiriamieji ir taikomieji darbai',
  721920: 'Gamtos mokslų tiriamieji ir taikomieji darbai',
  721930: 'Matematikos mokslų tiriamieji ir taikomieji darbai',
  721940: 'Medicinos mokslų tiriamieji ir taikomieji darbai',
  721950: 'Technikos mokslų tiriamieji ir taikomieji darbai',
  722000:
    'Socialinių ir humanitarinių mokslų moksliniai tyrimai ir taikomoji veikla',
  722010: 'Humanitarinių mokslų tiriamieji ir taikomieji darbai',
  722020: 'Socialinių mokslų tiriamieji ir taikomieji darbai',
  722030: 'Teologijos mokslų tiriamieji ir taikomieji darbai',
  730000: 'Reklama ir rinkos tyrimas',
  731000: 'Reklama',
  731100: 'Reklamos agentūrų veikla',
  731200: 'Atstovavimas žiniasklaidai',
  732000: 'Rinkos tyrimas ir viešosios nuomonės apklausa',
  740000: 'Kita profesinė, mokslinė ir techninė veikla',
  741000: 'Specializuota projektavimo veikla',
  742000: 'Fotografavimo veikla',
  743000: 'Vertimo raštu ir žodžiu veikla',
  749000:
    'Kita, niekur kitur nepriskirta, profesinė, mokslinė ir techninė veikla',
  750000: 'Veterinarinė veikla',
  750010: 'Gyvulių ir gyvūnų stacionarinis gydymas',
  750020: 'Gyvulių ir gyvūnų ambulatorinis gydymas',
  750030: 'Veterinarinė diagnostinė veikla',
  750040: 'Veterinarijos laboratorijų veikla',
  770000: 'Nuoma ir išperkamoji nuoma',
  771000: 'Variklinių transporto priemonių nuoma ir išperkamoji nuoma',
  771100:
    'Automobilių ir lengvųjų variklinių transporto priemonių nuoma ir išperkamoji nuoma',
  771200:
    'Sunkiasvorių variklinių transporto priemonių nuoma ir išperkamoji nuoma',
  771210: 'Krovininių automobilių nuoma',
  771220: 'Turistinių autobusų nuoma',
  772000: 'Asmeninių ir namų ūkio prekių nuoma ir išperkamoji nuoma',
  772100: 'Poilsio ir sporto reikmenų nuoma ir išperkamoji nuoma',
  772110: 'Pramoginių valčių nuoma',
  772120: 'Jojamųjų žirgų nuoma',
  772130: 'Dviračių nuoma',
  772140: 'Sporto įrangos nuoma',
  772150: 'Kitų turizmo priemonių nuoma',
  772200: 'Vaizdajuosčių ir kompaktinių diskų nuoma',
  772900: 'Kitų asmeninių ir namų ūkio prekių nuoma ir išperkamoji nuoma',
  772910: 'Tekstilės dirbinių, drabužių ir avalynės nuoma',
  772920: 'Baldų ir kitų asmeniniam naudojimui skirtų daiktų nuoma',
  772930: 'Muzikos instrumentų, teatro dekoracijų ir kostiumų nuoma',
  773000:
    'Kitų mašinų, įrangos ir materialiųjų vertybių nuoma ir išperkamoji nuoma',
  773100: 'Žemės ūkio mašinų ir įrenginių nuoma ir išperkamoji nuoma',
  773200:
    'Statybos ir inžinerinių statybos darbų mašinų ir įrenginių nuoma ir išperkamoji nuoma',
  773300:
    'Įstaigos mašinų ir įrangos, įskaitant kompiuterius, nuoma ir išperkamoji nuoma',
  773310: 'Kompiuterių nuoma',
  773320: 'Kopijavimo, spausdinimo ir teksto apdorojimo mašinų nuoma',
  773330: 'Kasos aparatų nuoma',
  773400: 'Vandens transporto priemonių ir įrangos nuoma ir išperkamoji nuoma',
  773500: 'Oro transporto priemonių ir įrangos nuoma ir išperkamoji nuoma',
  773900:
    'Kitų, niekur kitur nepriskirtų, mašinų, įrangos ir materialiųjų vertybių nuoma ir išperkamoji nuoma',
  773910: 'Geležinkelio transporto priemonių nuoma',
  773920: 'Motociklų nuoma',
  773930: 'Automobilinių namelių nuoma',
  773940: 'Konteinerių, kilnojamųjų platformų ir padėklų nuoma',
  773950: 'Pramoninių elektrinių ir neelektrinių mašinų nuoma',
  773960: 'Radijo ir televizijos įrenginių nuoma',
  773970: 'Ryšių įrenginių nuoma',
  773980: 'Matavimo ir kontrolės įrenginių nuoma',
  774000:
    'Intelektinės nuosavybės ir panašių produktų, išskyrus autorių teisių saugomus objektus, išperkamoji nuoma',
  780000: 'Įdarbinimo veikla',
  781000: 'Įdarbinimo agentūrų veikla',
  782000: 'Laikinojo įdarbinimo agentūrų veikla',
  783000: 'Kitas darbo jėgos teikimas',
  790000:
    'Kelionių agentūrų, ekskursijų organizatorių, išankstinio užsakymo paslaugų ir susijusi veikla',
  791000: 'Kelionių agentūrų ir ekskursijų organizatorių veikla',
  791100: 'Kelionių agentūrų veikla',
  791200: 'Ekskursijų organizatorių veikla',
  799000: 'Kitų išankstinio užsakymo ir susijusių paslaugų veikla',
  800000: 'Apsaugos ir tyrimo veikla',
  801000: 'Privati apsauga',
  802000: 'Apsaugos sistemų paslaugų veikla',
  803000: 'Tyrimo veikla',
  810000: 'Pastatų aptarnavimas ir kraštovaizdžio tvarkymas',
  811000: 'Kombinuota patalpų funkcionavimo užtikrinimo veikla',
  812000: 'Valymo veikla',
  812100: 'Paprastasis pastatų valymas',
  812200: 'Kita pastatų ir pramoninio valymo veikla',
  812900: 'Kita valymo veikla',
  813000: 'Kraštovaizdžio tvarkymas',
  820000:
    'Administracinė veikla, įstaigų ir kitų verslo įmonių aptarnavimo veikla',
  821000: 'Įstaigų administracinė ir aptarnavimo veikla',
  821100: 'Kombinuotųjų įstaigos administracinių paslaugų veikla',
  821900:
    'Fotokopijavimo, dokumentų rengimo ir kita specializuota įstaigai būdingų paslaugų veikla',
  822000: 'Užsakomųjų informacinių paslaugų centrų veikla',
  823000: 'Posėdžių ir verslo renginių organizavimas',
  829000: 'Niekur kitur nepriskirta verslui būdingų paslaugų veikla',
  829100: 'Išieškojimo agentūrų ir kredito biurų veikla',
  829200: 'Fasavimo ir pakavimo veikla',
  829900: 'Kita, niekur kitur nepriskirta, verslui būdingų paslaugų veikla',
  840000: 'Viešasis valdymas ir gynyba; privalomasis socialinis draudimas',
  841000:
    'Valstybės valdymas ir bendroji ekonominė bei socialinė bendruomenės politika',
  841100: 'Bendroji viešojo valdymo veikla',
  841110: 'Lietuvos Respublikos apskričių veikla',
  841120: 'Lietuvos Respublikos savivaldybių veikla',
  841130: 'Lietuvos Respublikos seniūnijų veikla',
  841140: 'Iždo valdymas ir priežiūra',
  841150: 'Biudžeto vykdymas ir valstybinių fondų tvarkymas',
  841160:
    'Centrinių administracinių institucijų įstatymų leidžiamoji ir vykdomoji veikla',
  841170: 'Vadovavimas muitinės tarnyboms ir jų veikla',
  841180:
    'Vadovavimas statistikos ir sociologijos tarnyboms įvairiuose valstybės valdymo lygiuose bei jų veikla',
  841200:
    'Sveikatos priežiūros, švietimo, kultūros ir kitų socialinių paslaugų, išskyrus socialinį draudimą, veiklos reguliavimas',
  841300: 'Verslo veiklos reguliavimas ir parama efektyviau ją organizuoti',
  842000: 'Bendruomenei teikiamų paslaugų užtikrinimas',
  842100: 'Užsienio reikalai',
  842200: 'Gynybos veikla',
  842300: 'Teisingumo ir teisminė veikla',
  842400: 'Viešosios tvarkos ir apsaugos veikla',
  842500: 'Priešgaisrinių tarnybų veikla',
  843000: 'Privalomojo (valstybinio) socialinio draudimo veikla',
  850000: 'Švietimas',
  851000: 'Ikimokyklinis ugdymas',
  851010: 'Ikimokyklinio amžiaus vaikų ugdymas',
  851020: 'Priešmokyklinio amžiaus vaikų ugdymas',
  852000: 'Pradinis ugdymas',
  853000: 'Vidurinis ugdymas',
  853100: 'Bendrasis vidurinis ugdymas',
  853110: 'Pagrindinis ugdymas',
  853120: 'Vidurinis ugdymas',
  853200: 'Techninis ir profesinis vidurinis mokymas',
  854000: 'Aukštasis mokslas',
  854100:
    'Aukštojo mokslo laipsnio nesuteikiantis mokymas baigus vidurinę mokyklą',
  854200: 'Aukštasis (tretinis) mokslas',
  854210: 'Aukštasis neuniversitetinis mokslas',
  854220: 'Aukštasis universitetinis mokslas',
  854230: 'Doktorantūra',
  855000: 'Kitas mokymas',
  855100: 'Sportinis ir rekreacinis švietimas',
  855200: 'Kultūrinis švietimas',
  855300: 'Vairavimo mokyklų veikla',
  855900: 'Kitas, niekur kitur nepriskirtas, švietimas',
  856000: 'Švietimui būdingų paslaugų veikla',
  860000: 'Žmonių sveikatos priežiūros veikla',
  861000: 'Ligoninių veikla',
  861010: 'Bendrosios paskirties ligoninių veikla',
  861020: 'Specializuotųjų ligoninių veikla',
  861030: 'Reabilitacijos ligoninių veikla',
  861040: 'Slaugos ligoninių veikla',
  862000: 'Medicininės ir odontologinės praktikos veikla',
  862100: 'Bendrosios praktikos gydytojų veikla',
  862200: 'Gydytojų specialistų veikla',
  862300: 'Odontologinės praktikos veikla',
  869000: 'Kita žmonių sveikatos priežiūros veikla',
  869010:
    'Viduriniojo medicinos personalo paslaugų  teikiamas ligoniams ne ligoninėse',
  869020: 'Kraujo perpylimo įstaigų ir kraujo bankų veikla',
  869030: 'Medicinos laboratorijų veikla',
  869040: 'Greitosios pagalbos veikla',
  870000: 'Kita stacionarinė globos veikla',
  871000: 'Stacionarinė slaugos įstaigų veikla',
  872000:
    'Stacionarinė protiškai atsilikusių, psichikos ligonių, sergančiųjų priklausomybės ligomis globos veikla',
  873000: 'Stacionarinė pagyvenusių ir neįgaliųjų asmenų globos veikla',
  879000: 'Kita stacionarinė globos veikla',
  880000: 'Nesusijusio su apgyvendinimu socialinio darbo veikla',
  881000:
    'Nesusijusio su apgyvendinimu socialinio darbo su pagyvenusiais ir neįgaliaisiais asmenimis veikla',
  889000: 'Kita, nesusijusi su apgyvendinimu, socialinio darbo veikla',
  889100: 'Vaikų dienos priežiūros veikla',
  889900:
    'Kita, niekur kitur nepriskirta, nesusijusi su apgyvendinimu socialinio darbo veikla',
  900000: 'Kūrybinė, meninė ir pramogų organizavimo veikla',
  900100: 'Scenos pastatymų veikla',
  900200: 'Scenos pastatymams būdingų paslaugų veikla',
  900300: 'Meninė kūryba',
  900400: 'Meno įrenginių eksploatavimo veikla',
  910000: 'Bibliotekų, archyvų, muziejų ir kita kultūrinė veikla',
  910100: 'Bibliotekų ir archyvų veikla',
  910200: 'Muziejų veikla',
  910300:
    'Istorinių vietų ir pastatų bei panašių turistų lankomų vietų eksploatavimas',
  910400: 'Botanikos ir zoologijos sodų bei gamtos rezervatų veikla',
  910410: 'Zoologijos sodų veikla',
  910420: 'Botanikos sodų veikla',
  910430: 'Gamtos rezervatų ir draustinių veikla',
  920000: 'Azartinių žaidimų ir lažybų organizavimo veikla',
  920010: 'Loterijų organizavimo veikla',
  920020: 'Azartinių lošimų ir lažybų organizavimo veikla',
  920030: 'Kitų azartinių žaidimų organizavimo veikla',
  930000: 'Sportinė veikla, pramogų ir poilsio organizavimo veikla',
  931000: 'Sportinė veikla',
  931100: 'Sporto įrenginių eksploatavimas',
  931200: 'Sporto klubų veikla',
  931300: 'Kūno rengybos centrų veikla',
  931900: 'Kita sportinė veikla',
  931910: 'Sportinė ar mėgėjų medžioklė ir su ja susijusi veikla',
  931920: 'Sportinė ar mėgėjų žvejyba ir su ja susijusi veikla',
  932000: 'Pramogų ir poilsio organizavimo veikla',
  932100: 'Atrakcionų ir teminių parkų veikla',
  932900: 'Kita pramogų ir poilsio organizavimo veikla',
  940000: 'Narystės organizacijų veikla',
  941000: 'Verslininkų, darbdavių ir profesinių narystės organizacijų veikla',
  941100: 'Verslininkų ir darbdavių narystės organizacijų veikla',
  941200: 'Profesinių narystės organizacijų veikla',
  942000: 'Profesinių sąjungų veikla',
  949000: 'Kitų narystės organizacijų veikla',
  949100: 'Religinių organizacijų veikla',
  949110: 'Religinių bendrijų veikla',
  949120: 'Vienuolynų veikla',
  949200: 'Politinių organizacijų veikla',
  949900: 'Kitų, niekur kitur nepriskirtų, narystės organizacijų veikla',
  950000: 'Kompiuterių ir asmeninių bei namų ūkio reikmenų taisymas',
  951000: 'Kompiuterių ir ryšių įrangos remontas',
  951100: 'Kompiuterių ir išorinės įrangos remontas',
  951200: 'Ryšių įrangos remontas',
  951210: 'Ryšio sistemų techninė priežiūra',
  952000: 'Asmeninių ir namų ūkio reikmenų taisymas',
  952100: 'Vartotojiškos elektroninės įrangos remontas',
  952200: 'Buitinių aparatų ir prietaisų, namų bei sodo įrangos remontas',
  952300: 'Avalynės ir odos gaminių taisymas',
  952400: 'Baldų ir interjero reikmenų taisymas',
  952500: 'Įvairių tipų laikrodžių ir juvelyrinių dirbinių taisymas',
  952900: 'Kitų asmeninių ir namų ūkio reikmenų taisymas',
  960000: 'Kita asmenų aptarnavimo veikla',
  960100: 'Tekstilės ir kailių gaminių skalbimas ir (sausasis) valymas',
  960200: 'Kirpyklų ir kitų grožio salonų veikla',
  960300: 'Laidotuvių ir su jomis susijusi veikla',
  960400: 'Fizinės gerovės užtikrinimo veikla',
  960900: 'Kita, niekur kitur nepriskirta, asmenų aptarnavimo veikla',
  970000: 'Namų ūkių, samdančių namų ūkio personalą, veikla',
  980000:
    'Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų gaminių gamyba ir paslaugų teikimu',
  981000:
    ' Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų gaminių ar reikmenų gamyba',
  982000:
    'Privačių namų ūkių veikla, susijusi su savoms reikmėms tenkinti skirtų nediferencijuojamų paslaugų teikimu',
  990000: 'Ekstrateritorinių organizacijų ir įstaigų veikla',
};

export const evrkFullWithZeros = {
  '010000':
    'Augalininkystė ir gyvulininkystė, medžioklė ir susijusių paslaugų veikla',
  '011000': 'Vienmečių augalų auginimas',
  '011100':
    'Grūdinių (išskyrus ryžius), ankštinių ir aliejingų sėklų augalų auginimas',
  '011200': 'Ryžių auginimas',
  '011300': 'Daržovių ir melionų, šakniavaisių ir gumbavaisių auginimas',
  '011310': 'Bulvių auginimas',
  '011320': 'Cukrinių runkelių auginimas',
  '011330': 'Daržovių auginimas atvirame grunte',
  '011340': 'Daržovių auginimas uždarame grunte',
  '011350': 'Daržovių sėklų gavyba',
  '011360': 'Grybų ir valgomųjų trumų auginimas',
  '011400': 'Cukranendrių auginimas',
  '011500': 'Tabako auginimas',
  '011600': 'Pluoštinių augalų auginimas',
  '011900': 'Kitų vienmečių augalų auginimas',
  '011910': 'Gėlių auginimas',
  '012000': 'Daugiamečių augalų auginimas',
  '012100': 'Vynuogių auginimas',
  '012200': 'Tropinių ir subtropinių vaisių ir uogų auginimas',
  '012300': 'Citrusų vaisių auginimas',
  '012400': 'Sėklavaisių ir kaulavaisių auginimas',
  '012500': 'Kitų vaismedžių, uogakrūmių ir riešutmedžių vaisių auginimas',
  '012600': 'Aliejinių augalų vaisių ir uogų auginimas',
  '012700': 'Gėrimams gaminti vartojamų augalų auginimas',
  '012800':
    'Prieskoninių, kvapiųjų, vaistinių ir kitokių farmacijos pramonėje naudojamų augalų auginimas',
  '012900': 'Kitų daugiamečių augalų auginimas',
  '013000': 'Augalų dauginimas',
  '014000': 'Gyvulininkystė',
  '014100': 'Pieninių galvijų auginimas',
  '014200': 'Kitų galvijų ir buivolų auginimas',
  '014210': 'Galvijų auginimas ir penėjimas mėsai',
  '014220': 'Veislinių telyčių auginimas',
  '014230': 'Veislinių bulių auginimas',
  '014240': 'Veislinių bulių spermos gavyba',
  '014300': 'Arklių ir kitų kanopinių gyvūnų auginimas',
  '014400': 'Kupranugarių ir Camelidae šeimos gyvūnų auginimas',
  '014500': 'Avių ir ožkų auginimas',
  '014510': 'Avių auginimas',
  '014520': 'Ožkų auginimas',
  '014600': 'Kiaulių auginimas',
  '014610': 'Kiaulių auginimas ir penėjimas mėsai',
  '014620': 'Veislinių kiaulių auginimas',
  '014700': 'Naminių paukščių auginimas',
  '014710': 'Naminių paukščių auginimas mėsai ir kiaušinių gavybai',
  '014720': 'Veislinių naminių paukščių auginimas',
  '014900': 'Kitų gyvūnų auginimas',
  '014910': 'Švelniakailių žvėrelių auginimas',
  '014920': 'Bitininkystė',
  '014930': 'Vynuoginių sraigių ir sliekų auginimas',
  '015000': 'Mišrusis žemės ūkis',
  '016000': 'Žemės ūkiui būdingų paslaugų ir derliaus apdorojimo veikla',
  '016100': 'Augalininkystei būdingų paslaugų veikla',
  '016110':
    'Augalų apsaugos nuo ligų, kenkėjų ir piktžolių žemės ūkio paslaugų veikla',
  '016120': 'Žemės ūkio augalų tręšimas mineralinėmis ir organinėmis trąšomis',
  '016200': 'Gyvulininkystei būdingų paslaugų veikla',
  '016210': 'Dirbtinio apsėklinimo veikla',
  '016300': 'Derliaus apdorojimo veikla',
  '016400': 'Sėklininkystė',
  '017000': 'Medžioklė, gaudymas spąstais ir susijusių paslaugų veikla',
  '020000': 'Miškininkystė ir medienos ruoša',
  '021000': 'Miško medžių auginimas ir kita miškininkystės veikla',
  '022000': 'Medienos ruoša',
  '023000': 'Laukinių nemedieninių medžių produktų rinkimas',
  '023000': 'Laukinių nemedieninių produktų rinkimas',
  '023010': 'Miško grybų rinkimas',
  '023020': 'Riešutų ir miško uogų rinkimas',
  '024000': 'Miškininkystei būdingų paslaugų veikla',
  '030000': 'Žvejyba ir akvakultūra',
  '031000': 'Žvejyba',
  '031100': 'Jūrinė žvejyba',
  '031110': 'Žuvų perdirbimas žvejybiniuose laivuose',
  '031200': 'Gėlųjų vandenų žvejyba',
  '032000': 'Akvakultūra',
  '032100': 'Jūrinė akvakultūra',
  '032200': 'Gėlųjų vandenų akvakultūra',
  '050000': 'Akmens anglių ir rusvųjų anglių kasyba',
  '051000': 'Juodųjų akmens anglių (antracito) kasyba',
  '052000': 'Rusvųjų anglių kasyba',
  '060000': 'Žalios naftos ir gamtinių dujų gavyba',
  '061000': 'Žalios naftos gavyba',
  '062000': 'Gamtinių dujų gavyba',
  '070000': 'Metalų rūdų kasyba',
  '071000': 'Geležies rūdų kasyba',
  '072000': 'Spalvotųjų metalų rūdų kasyba',
  '072100': 'Urano ir torio rūdų kasyba',
  '072900': 'Kitų spalvotųjų metalų rūdų kasyba',
  '080000': 'Kita kasyba ir karjerų eksploatavimas',
  '081000': 'Akmens, smėlio ir molio karjerų eksploatavimas',
  '081100':
    'Dekoratyvinio ir statybinio akmens, klinčių, gipso, kreidos ir skalūno karjerų eksploatavimas',
  '081200': 'Smėlio ir žvyro karjerų eksploatavimas; molio ir kaolino kasyba',
  '089000': 'Niekur kitur nepriskirta kasyba ir karjerų eksploatavimas',
  '089100': 'Mineralinių iškasenų chemijos pramonei ir trąšoms gaminti kasyba',
  '089200': 'Durpių gavyba',
  '089300': 'Druskos gavyba',
  '089900': 'Kita, niekur kitur nepriskirta, kasyba ir karjerų eksploatavimas',
  '090000': 'Kasybai būdingų paslaugų veikla',
  '091000': 'Naftos ir gamtinių dujų gavybai būdingų paslaugų veikla',
  '091010': 'Gamtinių dujų parengimas transportuoti (gabenti)',
  '099000': 'Kitai kasybai ir karjerų eksploatavimui būdingų paslaugų veikla',
};
