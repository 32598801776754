import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './button.module.scss';

const Button = ({
  children,
  type,
  color,
  handleOnClick,
  classes = {},
  url,
  ...rest
}) => {
  if (type === 'bgHeader') {
    return (
      <Link
        className={cn(styles.bgHeaderBtn, styles[color], classes.button)}
        style={{ backgroundColor: color }}
        to={url}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  if (type === 'standart') {
    return (
      <button
        className="btn ui-button btn-dark-blue-standart medium-size"
        {...rest}
        style={{ backgroundColor: color, borderColor: color }}
      >
        <span className="btn-text">
          <span className="btn-icon-text"> {children}</span>
        </span>
      </button>
    );
  }

  if (type === 'longBtn') {
    return (
      <button
        className="btn ui-button btn-dark-blue-standart long-btn"
        {...rest}
        style={{ backgroundColor: color, borderColor: color }}
      >
        <span className="btn-text">
          <span className="btn-icon-text"> {children}</span>
        </span>
      </button>
    );
  }

  if (type === 'longBtn2') {
    return (
      <button
        className="btn ui-button btn-dark-blue-standart long-btn"
        {...rest}
      >
        <span className="btn-text">
          <span className="btn-icon-text"> {children}</span>
        </span>
      </button>
    );
  }

  if (type === 'cancel') {
    return (
      <button
        className="btn ui-button btn-dark-blue-cancel medium-size"
        {...rest}
      >
        <span className="btn-text">
          <span className="btn-icon-text"> {children}</span>
        </span>
      </button>
    );
  }
};

export default Button;
