export const authConstants = {
  LOGIN_VAIISIS: 'auth/LOGIN_VAIISIS',
  SET_AUTH_LOADING: 'auth/SET_AUTH_LOADING',
  LOGIN_USER: 'auth/LOGIN_USER',
  SET_USER: 'auth/SET_USER',
  CLEAR_USER: 'auth/CLEAR_USER',
  LOGIN_VAIISIS_TEST_CRED: 'auth/LOGIN_VAIISIS_TEST_CRED',
  SET_LOGIN_ERR_MESSAGE: 'auth/SET_LOGIN_ERR_MESSAGE',
  PUSH_TOKEN_TO_SERVER: 'auth/PUSH_TOKEN_TO_SERVER',
  SET_TOKEN_PUSH_STARTED: 'auth/SET_TOKEN_PUSH_STARTED',
};
