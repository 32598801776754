import { actions } from '../actions';
import { call, put, delay } from 'redux-saga/effects';
import {
  errorhandle,
  downloadXls,
  downloadDoc,
  downloadListXlsFile,
} from '../../utils/helpers/helpers';
import { Api } from '../../api/api';

function* getRequestsListSaga(payload) {
  try {
    const data = yield call(Api.GetManagerRequests, payload);
    yield put(actions.backoffice.setRequestsList(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* getRequestSaga(request) {
  try {
    const data = yield call(Api.GetManagerRequest, request.payload);
    yield put(actions.backoffice.setRequest(data));

    const relatedRequests = yield call(Api.GetRelatedRequests, request.payload);
    yield put(actions.backoffice.setRelatedRequestsAction(relatedRequests));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* downloadListSaga({ payload }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const response = yield call(Api.DownloadList, payload);

    const currentDate = new Date();
    const fileName =
      'paraiskos' + '_' + currentDate.toJSON().slice(0, 10).replace(/-/g, '/');
    downloadListXlsFile(response, fileName);
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getInvoicesSaga(request) {
  try {
    const data = yield call(Api.GetManagerInvoices, request.payload);
    yield put(actions.backoffice.setInvoiceListDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* downloadAllFiles({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const response = yield call(Api.DownloadAllFiles, id);
    console.log(response);
    const fileName = 'zip';
    downloadXls(response, fileName);
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getInvoiceSaga(request) {
  try {
    const data = yield call(Api.GetManagerInvoice, request.payload);
    yield put(actions.backoffice.setInvoiceDataAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* exportDataSaga({ payload }) {
  const hardCodedPayload = {
    dates: {
      dateFrom: '2020-04-12T08:57:31.646Z',
      dateTo: '2020-04-15T08:57:31.646Z',
    },
  };
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const response = yield call(Api.ExportData, payload);

    const currentDate = new Date();
    const fileName =
      'paraiškos' + '_' + currentDate.toJSON().slice(0, 10).replace(/-/g, '/');
    downloadXls(response, fileName);
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getUploadedFileListSaga({ id }) {
  try {
    const data = yield call(Api.GetManagerUploadedFiles, id);
    yield put(actions.backoffice.setGetUploadedFilesAction(data));

    //sign Table
    const signingInfo = yield call(Api.GetSigningInfoManager, id);
    yield put(actions.dashboard.setSigningInfoAction(signingInfo));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* downloadAllPrFiles({ id }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const response = yield call(Api.DownloadFilePrdAll, id);
    console.log(response);
    const fileName = 'zip';
    downloadXls(response, fileName);
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getStatsSaga() {
  try {
    const data = yield call(Api.GetStats);
    yield put(actions.backoffice.setStatsAction(data));
  } catch (error) {
    yield* errorhandle(error);
  }
}

function* downloadContractSaga({ requestId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const response = yield call(Api.DownloadContractDoc, requestId);

    const fileName = `sutartis_Nr_${requestId}`;
    downloadDoc(response, fileName);
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getSignInDocSaga({ requestId, user }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    if (user === 'user') {
      const response = yield call(Api.GetSignedDocumentUser, requestId);
    } else if (user === 'manager') {
      const currentDate = new Date();
      const response = yield call(Api.GetSignedDocumentManager, requestId);
      const fileName =
        'Sutartis' + '_' + currentDate.toJSON().slice(0, 10).replace(/-/g, '/');
      downloadDoc(response, fileName);
    }
    const response = yield call(Api.DownloadContractDoc, requestId);

    const currentDate = new Date();
    const fileName =
      'Sutartis' + '_' + currentDate.toJSON().slice(0, 10).replace(/-/g, '/');
    downloadDoc(response, fileName);
  } catch (error) {
    console.log(error);
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* loadRepresentatives() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    const data = yield call(Api.loadRepresentativesManager);
    yield put(actions.backoffice.setRepresentatives(data));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* saveRepresentative({ data }) {
  try {
    yield call(Api.saveRepresentativeManager, data);

    const open = {
      modalName: 'autorizationModal',
      visible: false,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));
    yield put(actions.backoffice.loadRepresentatives());
  } catch (error) {
    yield put(actions.backoffice.setSaveRepresentativeError(error));
    yield put(actions.backoffice.setRepresentativeAction(data));
    //yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* saveEditAdditionalCommentsSaga({ action, payload }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    if (action === 'save') {
      yield call(Api.saveAdditionalComments, payload);
    } else if (action === 'get') {
      const data = yield call(Api.getAdditionalComments, payload);
      yield put(actions.backoffice.getAdditionalComments(data));
    }
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* saveRiskAssessmentResultSaga({ action, payload }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    if (action === 'save') {
      yield call(Api.saveRiskAssessmentResult, payload);
      yield getRequestSaga({ payload: payload.requestId });
      //dispatch(actions.backoffice.getRequestAction(requestId));
    } //else if (action === 'get') {
    //const data = yield call(Api.getAdditionalComments, payload);
    //yield put(actions.backoffice.getAdditionalComments(data));
    //}
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getRequestLog({ requestId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const data = yield call(Api.getRequestLog, requestId);
    yield put(actions.backoffice.setRequestLog(data));

    const data2 = yield call(Api.GetEditListManagerByRequest, requestId);
    yield put(actions.backoffice.setRepresentativesManagerByRequest(data2));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* getInvegaUsers() {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const data = yield call(Api.getInvegaUsers);
    yield put(actions.backoffice.setInvegaUsersAction(data));

    const getsetAssessors = yield call(Api.GetAssessorsList);
    yield put(actions.backoffice.setAssessorAction(getsetAssessors));
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* callSagaForErrHandle({ error }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));
    yield* errorhandle(error);
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

export {
  getRequestsListSaga,
  getRequestSaga,
  getInvoicesSaga,
  getInvoiceSaga,
  getUploadedFileListSaga,
  exportDataSaga,
  downloadListSaga,
  getStatsSaga,
  downloadContractSaga,
  getSignInDocSaga,
  loadRepresentatives,
  saveRepresentative,
  saveEditAdditionalCommentsSaga,
  saveRiskAssessmentResultSaga,
  getRequestLog,
  getInvegaUsers,
  callSagaForErrHandle,
  downloadAllFiles,
  downloadAllPrFiles,
};
