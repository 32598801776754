import React from 'react';
import Routes from './routes';
import './style/index.scss';
import { connect } from 'react-redux';
import GlobalErrorModal from './modals/globalErrorModal/globalErrorModal';
import InformationModal from './modals/informationModal/informationModal';
import ValidationErrorModal from './modals/validationErrorModal/validationErrorModal';
import { Api } from './api/api';
import { config } from './api/config';
import { resetLocalStorageItem } from './utils';

import { createBrowserHistory as createHistory } from 'history';
const history = createHistory();

window.onload = () => {
  fetch(Api.API_BASE_URL + config.API_ENDPOINTS.getFeatureSettings, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((json) => {
      resetLocalStorageItem('features', JSON.stringify(json));
    })
    .finally(() => {});
};

function App({ infoModal, errModalOpen, errMsg, validationModal }) {
  return (
    <>
      <Routes />
      <GlobalErrorModal open={errModalOpen} data={errMsg} />
      <InformationModal open={infoModal} />
      <InformationModal open={infoModal} />
      <ValidationErrorModal open={validationModal} />
    </>
  );
}

const mapStateToProps = (state) => ({
  errModalOpen: state.modalsState.globalErrorModalVisible,
  errMsg: state.modalsState.errMsg,
  infoModal: state.modalsState.informationModalVisible,
  validationModal: state.modalsState.validationErrorModalVisible,
});

export default connect(mapStateToProps)(App);
export { history };
