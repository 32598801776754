import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { WithSpinner, Button } from '../../components';
import SurveyModal from '../../modals/surveyModal/surveyModal';
import styles from './paymentRequestsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faPencilAlt,
  faTrash,
  faPlusSquare,
  faFileAlt,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

//import DeleteComfirmationModal from '../../modals/deleteComfirmationModal/deleteComfirmationModal';

import { format } from 'date-fns';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../../api/config';
import { Api } from '../../api/api';
import {
  loanPurpose,
  paymentRequestStatus,
} from './../../utils/translation/translation';
import { checkBOPage, formatNumber } from './../../utils/helpers/helpers';
import { getLocalStorageItemIfNotExpired } from '../../utils';

const PaymentRequests = ({
  paymentRequestsList,
  dispatch,
  loading,
  surveyModalVisible,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(1);

  const actionClick = (act, id, paymentId) => {
    //dispatch(actions.dashboard.createNewRequestAction(Id, 'view'));
    dispatch(
      actions.dashboard.createNewPaymentRequestAction(id, act, paymentId)
    );
  };

  const viewRequest = () => {
    const loginType = getLocalStorageItemIfNotExpired('loginType');
    if (loginType === 'Company') {
      dispatch(actions.dashboard.createNewRequestAction(requestId, 'view'));
    } else if (loginType === 'Individual') {
      dispatch(
        actions.dashboard.createNewRequestIndividualAction(requestId, 'view')
      );
    } else {
      swal({
        title: 'Įvyko klaida',
        icon: 'Error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  };

  const url = new URL(window.location.href);
  const requestId = url.searchParams.get('requestId');

  const fetchdata = useCallback(() => {
    if (requestId && !loading) {
      dispatch(actions.dashboard.getPaymentRequestsListAction(requestId));
    }
  }, [dispatch, paymentRequestsList]);

  const addNewPaymentRequest = (act, paymentId) => {
    if (paymentRequestsList?.Items.length === 0) {
      const open = {
        modalName: 'surveyModal',
        visible: true,
      };
      dispatch(actions.modals.setModalVisibleFlagAction(open));
    } else {
      dispatch(
        actions.dashboard.createNewPaymentRequestAction(null, act, paymentId)
      );
    }
  };

  useEffect(() => {
    dispatch(actions.dashboard.clearUserStateAction());
    fetchdata();
    //dispatch(actions.dashboard.setSavedRequestDesissionAccepted(false));
    dispatch(actions.auth.pushTokenToServerAction());
  }, []);

  const formDate = (value = '2022-02-22') => {
    //return value;  const formDate = (value) => {
    return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
  };

  return (
    <WithSpinner loading={loading}>
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          textAlign: 'center',
          marginLeft: '10%',
          marginRight: '10%',
        }}
      ></div>
      <div style={{ width: '96%', marginLeft: '2%' }}>
        <h2>Mokėjimo prašymų sąrašas</h2>
        <div className={styles.shadow}>
          <table>
            <thead className={styles.paymentRequestsListTHead}>
              <tr>
                <th rowSpan="2">Numeris</th>
                <th rowSpan="2">Pateikimo data</th>
                <th rowSpan="2">Paskolos sumos likutis</th>
                <th rowSpan="2" style={{ maxWidth: '15rem' }}>
                  Bendra mokėjimo prašymo suma, pagal pateiktus dokumentus, EUR
                </th>
                <th rowSpan="2">Finansuojama suma, EUR</th>
                <th rowSpan="2">Paskolos paskirtis</th>
                <th colSpan="2">
                  Išmokėta paskolos suma
                  <br /> pagal paskolos tipą, EUR
                </th>
                <th colSpan="2">
                  Išmokėta paskolos suma
                  <br /> pagal paskolos tipą proc.:
                </th>
                <th rowSpan="2">Būsena</th>
                <th rowSpan="2" style={{ maxWidth: '8rem' }}>
                  Būsenos pasikeitimo data
                </th>
                <th rowSpan="2">Veiksmai</th>
              </tr>
              <tr>
                <th>Investicinė</th>
                <th>Apyvartinė</th>
                <th>Investicinė</th>
                <th>Apyvartinė</th>
              </tr>
            </thead>
            <tbody>
              {paymentRequestsList?.Items &&
                paymentRequestsList.Items.map((el, i) => (
                  <tr className={styles.paymentRequestsListTR} key={i}>
                    <td className={styles.paymentRequestsListTD}>
                      {el.Number.toString()}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {el.FirstSubmittedDate
                        ? format(new Date(el.FirstSubmittedDate), 'yyyy-MM-dd')
                        : '-'}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {formatNumber(el.LoanSumRemainder)}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {formatNumber(el.RequiredSumTotal)}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {formatNumber(el.FundedSumTotal)}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {loanPurpose(el.LoanPurposeId)}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {el.PaymentRequestStatusId === 2
                        ? el.InvestmentSumTotal
                          ? formatNumber(el.InvestmentSumTotal)
                          : formatNumber(0)
                        : '-'}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {el.PaymentRequestStatusId === 2
                        ? el.TurnoverSumTotal
                          ? formatNumber(el.TurnoverSumTotal)
                          : formatNumber(0)
                        : '-'}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {el.InvestmentSumTotalPercent &&
                      (!checkBOPage() ||
                        (checkBOPage() && el.PaymentRequestStatusId === 2))
                        ? `${formatNumber(el.InvestmentSumTotalPercent, 4)}%`
                        : '-'}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {el.TurnoverSumTotalPercent &&
                      (!checkBOPage() ||
                        (checkBOPage() && el.PaymentRequestStatusId === 2))
                        ? `${formatNumber(el.TurnoverSumTotalPercent, 4)}%`
                        : '-'}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {paymentRequestStatus(el.PaymentRequestStatusId)}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      {el.StatusChangeDate
                        ? format(new Date(el.StatusChangeDate), 'yyyy-MM-dd')
                        : '-'}
                    </td>
                    <td className={styles.paymentRequestsListTD}>
                      <div className={styles.iconsContainer}>
                        {/* <Link to={`${config.APP_PATHS.newRequest}/${Id}`}> */}
                        <Link
                          to={`${
                            checkBOPage()
                              ? config.APP_PATHS.backofficePaymentRequest
                              : config.APP_PATHS.paymentrequest
                          }?requestId=${requestId}&paymentRequestId=${
                            el.Id
                          }&actionType=view`}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className={styles.icon}
                            onClick={() =>
                              actionClick('view', requestId, el.Id)
                            }
                            style={{
                              color: '#009fe3',
                            }}
                          />
                        </Link>
                        {(el.PaymentRequestStatusId === 1 ||
                          el.PaymentRequestStatusId === 3) &&
                          !checkBOPage() && (
                            <Link
                              to={`${
                                checkBOPage()
                                  ? config.APP_PATHS.backofficePaymentRequest
                                  : config.APP_PATHS.paymentrequest
                              }?requestId=${requestId}&paymentRequestId=${
                                el.Id
                              }&actionType=edit`}
                            >
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                className={styles.icon}
                                onClick={() =>
                                  actionClick('edit', requestId, el.Id)
                                }
                                style={{
                                  color: '#005eae',
                                }}
                              />
                            </Link>
                          )}
                        {el.IsManagerEdited && (
                          <FontAwesomeIcon icon={faExclamation} />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {paymentRequestsList?.Totals && (
              <tfoot>
                <tr className={styles.paymentRequestsListTRFoot}>
                  <td className={styles.paymentRequestsListTD}>Viso:</td>
                  <td className={styles.paymentRequestsListTD} />
                  <td className={styles.paymentRequestsListTD}>
                    {formatNumber(paymentRequestsList.Totals.LoanSumRemainder)}
                  </td>
                  <td className={styles.paymentRequestsListTD}>
                    {formatNumber(paymentRequestsList.Totals.RequiredSumTotal)}
                  </td>
                  <td className={styles.paymentRequestsListTD}>
                    {formatNumber(paymentRequestsList.Totals.FundedSumTotal)}
                  </td>
                  <td className={styles.paymentRequestsListTD} />
                  <td className={styles.paymentRequestsListTD}>
                    {formatNumber(
                      paymentRequestsList.Totals.InvestmentSumTotal
                    )}
                  </td>
                  <td className={styles.paymentRequestsListTD}>
                    {formatNumber(paymentRequestsList.Totals.TurnoverSumTotal)}
                  </td>
                  <td className={styles.paymentRequestsListTD}>{`${formatNumber(
                    paymentRequestsList.Totals.InvestmentSumTotalPercent,
                    4
                  )}%`}</td>
                  <td className={styles.paymentRequestsListTD}>{`${formatNumber(
                    paymentRequestsList.Totals.TurnoverSumTotalPercent,
                    4
                  )}%`}</td>
                  <td className={styles.paymentRequestsListTD} />
                  <td className={styles.paymentRequestsListTD} />
                  <td className={styles.paymentRequestsListTD} />
                </tr>
              </tfoot>
            )}
          </table>
        </div>

        {!checkBOPage() && (
          <FontAwesomeIcon
            size="4x"
            icon={faPlusSquare}
            className={styles.icon}
            style={{ float: 'right', width: '26px', color: '#033878' }}
            onClick={() => addNewPaymentRequest('create', requestId)}
          />
        )}
      </div>

      <div
        className={styles.paymentRequestsBtnInline}
        style={{ marginTop: '4rem' }}
      >
        {checkBOPage() ? (
          <div>
            <Link
              to={`${config.APP_PATHS.backofficeRequest}/${requestId}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
                float: 'left',
              }}
            >
              <Button type="longBtn">Peržiūrėti paraiškos duomenis</Button>
            </Link>
            <Link
              to="/backoffice/main"
              style={{
                color: '#fff',
                textDecoration: 'none',
                float: 'left',
                marginLeft: '1rem',
              }}
            >
              <Button type="longBtn">Paraiškų sąrašas</Button>
            </Link>
          </div>
        ) : (
          <Link
            onClick={viewRequest}
            to={config.APP_PATHS.newRequest}
            style={{
              color: '#fff',
              textDecoration: 'none',
              float: 'left',
            }}
          >
            <Button type="longBtn">Peržiūrėti paraiškos duomenis</Button>
          </Link>
        )}
      </div>
      <SurveyModal open={surveyModalVisible} />
    </WithSpinner>
  );
};

/* 
<Link to={config.APP_PATHS.newRequest}>
</Link>
*/

const mapStateToProps = (state) => ({
  paymentRequestsList: state.dashboardState.paymentRequestsList,
  loading: state.dashboardState.isOnSync,
  canCreateRequest: state.dashboardState.canCreateRequest,
  surveyModalVisible: state.modalsState.surveyModalVisible,
});

export default connect(mapStateToProps)(PaymentRequests);
