import React from 'react';
import { TableAdmin, TableRow, TableCell } from '../../components';
import { formatNumber } from '../../utils/helpers/helpers';
import { getYear } from 'date-fns/esm';

const FinancialTable = ({ data, fsi, regDate }) => {
  const notApplied = (year) => {
    const year1 = year ? new Date(year, 0, 1) : null;
    const year2 = regDate ? new Date(regDate) : null;

    const yearX = getYear(year1);
    const yearY = getYear(year2);

    if (yearX && yearY) {

      if (yearX < yearY) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <div style={{ display: 'flex' }}>
      {!fsi && (
        <div style={{ width: '40%' }}>
          <TableAdmin
            header={['Finansinių atskaitomybių informacija']}
            isEmpty={false}
            xs
            noHeaderRound
          >
            <TableRow>
              <TableCell>Pateikta atskaitomybė Registrų centrui </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Turto suma, Eur </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Įsipareigojimų suma, Eur </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nuosavybės suma, Eur </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Apyvarta, Eur </TableCell>
            </TableRow>
          </TableAdmin>
        </div>
      )}
      <div style={{ display: 'flex', width: !fsi ? '60%' : '100%' }}>
        {data?.map((val, i) => (
          <TableAdmin header={[val?.FsiYear]} isEmpty={false} noHeaderRound>
            <TableRow>
              <TableCell>
                {val?.FsiSubmitted ? (
                  <span style={{ color: fsi ? 'green' : '#597a96' }}>Taip</span>
                ) : (
                  <span
                    style={{
                      color: notApplied(val?.FsiYear) ? 'red' : '#597a96',
                    }}
                  >
                    {notApplied(val?.FsiYear) ? 'Ne' : 'Netaikoma'}
                  </span>
                )}
              </TableCell>
            </TableRow>
            {!fsi && (
              <>
                <TableRow>
                  <TableCell>
                    {formatNumber(val?.Assets)}{' '}
                    <span style={{ visibility: 'hidden' }}>/</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {formatNumber(val?.Liabilities)}{' '}
                    <span style={{ visibility: 'hidden' }}>/</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {formatNumber(val?.PropertyAmount)}{' '}
                    <span style={{ visibility: 'hidden' }}>/</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {formatNumber(val?.Turnover)}{' '}
                    <span style={{ visibility: 'hidden' }}>/</span>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableAdmin>
        ))}
      </div>
    </div>
  );
};

export default FinancialTable;
