import React, { useState, useEffect } from 'react';
import styles from './confirmWithFinancialCriteriaModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { criteriaValuep, criteriaValuep2 } from '../../utils/translation/criteria';

const ConfirmWithFinancialCriteriaModal = ({
  open,
  dispatch,
  text,
  loading,
  financialCriteriaResults,
  id,
  finYear,
  formVersion,
}) => {
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);

  const onAgree = () => {
    agree === false ? setShowError(false) : setShowError(true);
    setAgree(!agree);
  };
  const cancel = () => {
    setAgree(false);
    setShowError(false);
    const open = {
      modalName: 'confirmWithFinancialCriteriaModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const cancel2 = () => {
    setAgree(false);
    setShowError(false);
    cancel();
    dispatch(
      actions.dashboard.createNewRequestAction(
        id.RequestSystemInfo.RequestId,
        'view'
      )
    );
  };
  const yes = () => {
    dispatch(
      actions.dashboard.sendForApproveAction(id.RequestSystemInfo.RequestId)
    );

    cancel();
  };

  const [data, setData] = useState(financialCriteriaResults);
  useEffect(() => {
    if (financialCriteriaResults && financialCriteriaResults.Rules) {
      setData(financialCriteriaResults);
    }
    // if denied - reload
  }, [financialCriteriaResults]);
  return (
    open && (
      <WithSpinner loading={loading}>
        <div className={styles.container}>
          <div
            className={styles.modal}
            id="modal"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal__title"
            aria-describedby="modal__desc"
            style={{ minHeight: '300px' }}
          >
            <div className={styles.contentBlock}>
              <h2>Atitikimo finansavimui kriterijai:</h2>
              <div className={styles.criteriaContainer}>
              {data != null  &&
                  data.Decision != null &&
                  data.Rules.length !== 0 && formVersion !== 3 ? (
                  data.Rules.map((val, i) => (
                   <div
                      style={{
                        color: val.Decision === 'Denied' ? 'red' : 'green',
                      }}
                      className={styles.criteriaContainer}
                      key={i}
                    >
                      {val.RuleName === 'FinancialIndicators'
                        ? finYear === 2020
                          ? criteriaValuep('FinancialIndicators2020')
                          : criteriaValuep(val.RuleName)
                        : criteriaValuep(val.RuleName)}
                    </div>
                    ))
                  ) : 
                  formVersion === 3 && (
                    data.Rules.map((val, i) => (
                     <div
                        style={{
                          color: val.Decision === 'Denied' ? 'red' : 'green',
                        }}
                        className={styles.criteriaContainer}
                        key={i}
                      >
                        {val.RuleName === 'FinancialIndicators'
                          ? finYear === 2020
                            ? criteriaValuep2('FinancialIndicators2020')
                            : criteriaValuep2(val.RuleName)
                          : criteriaValuep2(val.RuleName)}
                      </div>
                      ))
                  )
              }
               {data.Rules.length == 0 && (
                  <p
                    style={{
                      fontSize: '1.3rem',
                      marginBottom: '2rem',
                    }}
                  >
                    Fiziniams asmenims finansavimo kriterijai tikrinami teikiant
                    paraišką vertinimui.
                  </p>
                )}
              </div>

              {data && data.Decision === 'Accepted' ? (
                <>
                  <div className={styles.btnContainer}>
                    <Button type="standart" onClick={() => yes()}>
                      Pateikti
                    </Button>
                    <Button type="cancel" onClick={() => cancel()}>
                      Atšaukti
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: '1.3rem',
                      marginBottom: '2rem',
                      color: 'red',
                    }}
                  >
                    Jūsų paraiška neatitinka keliamų kriterijų, todėl pateikti
                    vertinimui negalite. Su sprendimu dėl kriterijų neatitikimo
                    galite susipažinti skiltyje "Sutartys, sprendimai ir prašymai".
                  </p>
                  <div>
                    <button
                      type="standart"
                      onClick={() => cancel2()}
                      className="vaiisisloginbtn"
                      style={{ marginTop: '0rem' }}
                    >
                      Gerai
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </WithSpinner>
    )
  );
};

const mapStateToProps = (state) => ({
  text: state.modalsState.msgText,
  // loading: state.modalsState.isOnSync,
  financialCriteriaResults: state.dashboardState.financialCriteriaResults,
  id: state.dashboardState.requestData,
  sectorType: state.dashboardState.requestData.LoanInfo,
});

export default connect(mapStateToProps)(ConfirmWithFinancialCriteriaModal);
