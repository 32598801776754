import React from 'react';

import PermissionDeniedModal from '../../modals/permissionDeniedModal/permissionDeniedModal';

const PermissionDenied = () => {

  return (
      <div >
        <p></p>
        <PermissionDeniedModal
        open={true}

      />
      </div>
  );
};


export default (PermissionDenied);
