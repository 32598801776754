import { call, put, delay, select } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { isProd } from '../../api/config';
import { actions } from '..';
import { resetLocalStorageItem, removeLocalStorageItem } from '../../utils';
import { history } from './../../App';
import { checkBOPage } from '../../utils/helpers/helpers';

const forwardTo = (location) => {
  history.push(location);
};

export function* loginVaiisisSaga() {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    const { token } = yield call(Api.Login);
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* loginVaiisisTestSaga({ credentials }) {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    const { token, loginType } = yield call(Api.loginVaiisisTest, credentials);
    console.log(token);

    resetLocalStorageItem('userToken', token);
    resetLocalStorageItem('loginType', loginType);

    const userDetails = {
      token: token,
    };
    yield put(actions.auth.setUserAction(userDetails, token));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* loginUserSaga({ credentials }) {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    removeLocalStorageItem('boFilter');

    yield call(Api.getToken, credentials);

    const userDetails = {
      user: 'admin',
      loggedIn: true,
      role: 'admin',
    };
    yield put(actions.auth.setUserAction(userDetails));
  } catch (e) {
    // yield put(actions.auth.setAuthError());
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* clearUserSaga() {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    yield call(Api.Logoff);
    removeLocalStorageItem('userToken');
    removeLocalStorageItem('token');
    removeLocalStorageItem('loginType');
    removeLocalStorageItem('Role');
    removeLocalStorageItem('boFilter');
    removeLocalStorageItem('currentPage');

    // yield call(forwardTo, config.API_BASE_URL_PROD);
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
    if (checkBOPage()) {
      window.location.href = isProd()
        ? 'https://paslaugos.invega.lt/backofficelogin'
        : 'https://barzda.scorify.lt/backofficelogin';
    } else {
      window.location.href = isProd()
        ? 'https://paslaugos.invega.lt/'
        : 'https://barzda.scorify.lt/';
    }
  }
}

export function* pushTokenToServerSaga() {
  const getTokenPushingStarted = (state) => state.auth.tokenPushingStarted;
  const selectGetTokenPushingStarted = yield select(getTokenPushingStarted);
  try {
    // if (!selectGetTokenPushingStarted) {
    yield put(actions.auth.setTokenPushStartAction(true));
    while (true) {
      yield delay(1000 * 60);
      // console.log('hello from push token saga')
    }
    // }
  } catch (e) {
    console.log(e);
  }
}
