import React, { useState, useEffect } from 'react';
import styles from './paymentRequestSendforApproveModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';

const PaymentRequestSendforApproveModal = ({
  open,
  dispatch,
  text,
  loading,
  id,
}) => {
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);

  const onAgree = () => {
    agree === false ? setShowError(false) : setShowError(true);
    setAgree(!agree);
  };

  const cancel = () => {
    setAgree(false);
    setShowError(false);
    const open = {
      modalName: 'paymentRequestSendforApproveModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };
  const url = new URL(window.location.href);
  const yes = () => {
    const id = url.searchParams.get('paymentRequestId');
    dispatch(actions.dashboard.sendForPaymentRequestApproveAction(id));

    cancel();
  };

  return (
    open && (
      <WithSpinner loading={loading}>
        <div className={styles.container}>
          <div
            className={styles.modal}
            id="modal"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal__title"
            aria-describedby="modal__desc"
            style={{ minHeight: '100px' }}
          >
            <div className={styles.contentBlock}>
              <h2>Ar tikrai norite pateikti mokėjimo prašymą vertinimui? </h2>
              <p>
                Prieš pasirenkatant "PATEIKTI", patikrinkite, ar prie mokėjimo
                prašymo pridėjote visus reikiamus dokumentus. Patikrinti
                galėsite pasirinkus "ATŠAUKTI". 
                <br />
              </p>
              {
                <div className={styles.btnContainer}>
                  <Button type="standart" onClick={() => yes()}>
                    Pateikti
                  </Button>
                  <Button type="cancel" onClick={() => cancel()}>
                    Atšaukti
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
      </WithSpinner>
    )
  );
};

const mapStateToProps = (state) => ({
  text: state.modalsState.msgText,
  id: state.dashboardState.requestData,
});

export default connect(mapStateToProps)(PaymentRequestSendforApproveModal);
