import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const MultiSelect = ({
  title,
  options,
  value,
  onChange,
  name,
  placeholder,
  tooltip,
  note,
  type,
  disabled,
  triedToSubmit,
  isValid,
  errorMessage,
  label,
}) => {
  const [dropOptions, setDropOptions] = useState([]);

  useEffect(() => {
    arrToDropdownObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const arrToDropdownObject = () => {
    const array = options.map((item) => ({
      value: item,
      label: item.value,
    }));
    setDropOptions(array);
  };

  const setDefaultValue = () =>
    value
      ? value?.map((val) => options?.find((x) => x?.value === val.value))
      : [];

  console.log(setDefaultValue());

  const options2 = options
    ? [{ label: 'Select All', value: options }, ...options]
    : options;

  return (
    <div className="input-container">
      {title && <p>{title}</p>}
      {label && <span className="input-container--label">{label}</span>}
      <div className="dropdown-select">
        <Select
          defaultValue={setDefaultValue()}
          value={setDefaultValue()}
          options={options}
          isMulti
          className="basic-multi-select"
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          styles={customStyles}
          isDisabled={disabled}
        />
      </div>
      {note && <p className="note">{note}</p>}
      {triedToSubmit && !isValid && <p className="error">{errorMessage}</p>}
    </div>
  );
};

export default MultiSelect;

export const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#fff',
    minHeight: '35px',
    boxShadow: 'none',
    height: '35px',
    fontSize: '10px',
    background: '#fff',
    border: '1px solid #033878',
    borderRadius: '5px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '35px',
    position: 'auto',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#eaf6fe',
    borderRadius: '4px',
    marginTop: '0px',
    fontSize: '15px',
    padding: '2px 5px 0px 5px',
    '&>div:hover': {
      backgroundColor: '#eaf6fe',
      cursor: 'pointer',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    paddingLeft: '7px',
    fontSize: '12px',
    marginTop: '0px',
    color: '#2b363b',
    paddingTop: '0px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    top: '50%',
    fontSize: '12px',
    paddingLeft: '7px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '35px',
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '180%',
    padding: '10px 15px 9px 10px',
  }),
  isFocused: (provided, state) => ({
    ...provided,
    backgroundColor: '#eaf6fe',
    borderRadius: '4px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: (state.isSelected || state.isFocused) && '#eaf6fe',
    borderRadius: (state.isSelected || state.isFocused) && '4px',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#eaf6fe',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  }),
};
