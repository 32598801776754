import React from 'react';
import styles from './description.module.scss';

const About = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className={styles.descriptionAllBlock}>
            <div style={{ float: 'right' }}>
              <div>
                <p style={{ marginBottom: '-13px' }}>PATVIRTINTA</p>
                <p style={{ marginBottom: '-13px' }}>
                  UAB „Investicijų ir verslo garantijos“
                </p>
                <p style={{ marginBottom: '-13px' }}>
                  generalinio direktoriaus
                </p>
                <p style={{ marginBottom: '-13px' }}>
                  2021 m. rugsėjo 13 d. įsakymu Nr. B-178
                </p>
                <p style={{ marginBottom: '-13px' }}>
                  (2023 m. rugsėjo 1 d. įsakymu Nr. B-205 patvirtinta
                  redakcija)
                </p>
              </div>
            </div>
            <h4
              style={{
                marginTop: '9rem',
                fontSize: '1.3rem',
                textAlign: 'center',
              }}
            >
              FINANSINĖS PRIEMONĖS
              <br />
              „STARTUOK“, FINANSUOJAMOS EUROPOS REGIONINĖS PLĖTROS FONDO
              LĖŠOMIS, APRAŠYMAS
              <br />
              1. ĮVADINĖ INFORMACIJA
              <br />
            </h4>

            <h5 style={{ marginTop: '0.5rem' }}>1.1. Priemonės tikslas</h5>

            <p className={styles.firstLineP}>
              Finansinės priemonės „Startuok“, finansuojamos Europos regioninės
              plėtros fondo lėšomis (toliau – Priemonė) tikslas – užtikrinti
              finansinių šaltinių prieinamumą smulkiojo ir vidutinio verslo
              (toliau – SVV) subjektams, veikiantiems ne ilgiau kaip trejus
              metus, SVV subjektams, vykdantiems socialinio poveikio projektus,
              veikiantiems ne ilgiau kaip penkerius metus, SVV subjektams, kurių
              dalyvis (akcininkas, narys, dalininkas) ar savininkas yra leidimą
              gyventi Lietuvoje turintis Ukrainos pilietis arba Ukrainos
              juridinis asmuo, veikiantiems ne ilgiau kaip penkerius metus, ir
              Ukrainos pilietybę ir leidimą gyventi Lietuvoje turintiems
              verslininkams, veikiantiems ne ilgiau kaip penkerius metus (toliau
              kartu – Verslo subjektas), suteikiant Verslo subjektams
              finansavimą paskolų forma.
            </p>
            <h5 style={{ marginTop: '0.5rem' }}>1.2. Priemonės aplinka</h5>
            <p className={styles.firstLineP}>
              Fondų fondo valdytoja uždaroji akcinė bendrovė „INVESTICIJŲ IR
              VERSLO GARANTIJOS“ (toliau – INVEGA), kuriai 2016 m. balandžio 15
              d. finansavimo sutartimi Nr. J07-FM-F-01-0001/1S-23/8-35, sudaryta
              tarp Lietuvos Respublikos ūkio ministerijos, Lietuvos Respublikos
              finansų ministerijos ir INVEGOS, pavesta įgyvendinti 2014–2020 m.
              Europos Sąjungos fondų investicijų veiksmų programos 1 prioriteto
              „Mokslinių tyrimų, eksperimentinės plėtros ir inovacijų
              skatinimas“ ir 3 prioriteto „Smulkiojo ir vidutinio verslo
              konkurencingumo skatinimas“ jungtinės priemonės „Verslo
              finansavimo fondas“ Priemonę, kurią įgyvendina teikdama Paskolas
              tiesiogiai Paskolų gavėjams.
            </p>
            <p className={styles.firstLineP}>
              Lietuvos Respublikos Vyriausybė 2018 m. spalio 17 d. nutarimu Nr.
              1046 „Dėl pavedimo vykdyti nacionalinės plėtros įstaigos veiklą“
              INVEGAI suteikė nacionalinės plėtros įstaigos statusą, o nuo 2018
              m. gruodžio 3 d. Lietuvos banko priežiūros tarnybos sprendimu
              INVEGA įtraukta į Nacionalinės plėtros įstaigų sąrašą.
            </p>
            <p className={styles.firstLineP}>
              Priemonei įgyvendinti skirta 35,29 mln. Eur Fondų fondo „Verslo
              finansavimo fondas, finansuojamas iš Europos regioninės plėtros
              fondo“ (toliau – Fondų fondas) lėšų (įskaitant valdymo išlaidas
              Fondų fondo valdytojui).
            </p>
            <p className={styles.firstLineP}>
              Priemonės aprašymui (toliau – Aprašymas) pritarta 2021 m. rugsėjo
              10 d. Verslo finansavimo fondo Priežiūros komiteto sprendimu,
              protokolo Nr. 252-213.
            </p>

            <h5 style={{ marginTop: '0.5rem', marginBottom: '0.25rem' }}>
              1.3. Reglamentuojantys teisės aktai: <br />
              Europos Sąjungos (toliau – ES) teisės aktai ir gairės:
            </h5>

            <ul>
              <li>
                2014 m. birželio 17 d. Komisijos reglamentas (ES) Nr. 651/2014,
                kuriuo tam tikrų kategorijų pagalba skelbiama suderinama su
                vidaus rinka taikant Sutarties 107 ir 108 straipsnius (toliau –
                Bendrosios išimties reglamentas);
              </li>
              <li>
                2013 m. gruodžio 18 d. Komisijos reglamentas (ES) Nr. 1407/2013
                dėl Sutarties dėl Europos Sąjungos veikimo 107 ir 108 straipsnių
                taikymo <i>de minimis</i> pagalbai (toliau – <i>De minimis</i>{' '}
                reglamentas);
              </li>
              <li>
                2013 m. gruodžio 17 d. Europos Parlamento ir Tarybos reglamentas
                (ES) Nr. 1303/2013, kuriuo nustatomos Europos regioninės plėtros
                fondui, Europos socialiniam fondui, Sanglaudos fondui, Europos
                žemės ūkio fondui kaimo plėtrai ir Europos jūrų reikalų ir
                žuvininkystės fondui bendros nuostatos ir Europos regioninės
                plėtros fondui, Europos socialiniam fondui, Sanglaudos fondui ir
                Europos jūrų reikalų ir žuvininkystės fondui taikytinos
                bendrosios nuostatos ir panaikinamas Tarybos reglamentas (EB)
                Nr. 1083/2006, (toliau – Reglamentas (ES) Nr. 1303/2013);
              </li>
              <li>
                2013 m. gruodžio 17 d. Europos Parlamento ir Tarybos reglamentas
                (ES) Nr. 1301/2013 dėl Europos regioninės plėtros fondo ir dėl
                konkrečių su investicijų į ekonomikos augimą ir darbo vietų
                kūrimą tikslu susijusių nuostatų, kuriuo panaikinamas
                Reglamentas (EB) Nr. 1080/2006 (toliau – Reglamentas (ES) Nr.
                1301/2013);
              </li>
              <li>
                2014 m. kovo 3 d. Komisijos deleguotasis reglamentas (ES) Nr.
                480/2014, kuriuo papildomas Europos Parlamento ir Tarybos
                reglamentas (ES) Nr. 1303/2013, kuriuo nustatomos Europos
                regioninės plėtros fondui, Europos socialiniam fondui,
                Sanglaudos fondui, Europos žemės ūkio fondui kaimo plėtrai ir
                Europos jūrų reikalų ir žuvininkystės fondui bendros nuostatos
                ir Europos regioninės plėtros fondui, Europos socialiniam
                fondui, Sanglaudos fondui ir Europos jūrų reikalų ir
                žuvininkystės fondui taikytinos bendrosios nuostatos, (toliau –
                Deleguotasis reglamentas);
              </li>
              <li>
                kiti Priemonei taikytini ES teisės aktai, direktyvos, gairės ir
                kt.
              </li>
            </ul>

            <h5 style={{ marginTop: '0.5rem', marginBottom: '0.25rem' }}>
              Nacionaliniai teisės aktai ir dokumentai:
            </h5>

            <ul>
              <li>
                Lietuvos Respublikos nacionalinių plėtros įstaigų įstatymas;
              </li>
              <li>
                Lietuvos Respublikos smulkiojo ir vidutinio verslo plėtros
                įstatymas (toliau – SVV įstatymas);
              </li>
              <li> Lietuvos Respublikos civilinis kodeksas;</li>
              <li>
                Lietuvos Respublikos Vyriausybės 2001 m. liepos 11 d. nutarimas
                Nr. 887 „Dėl uždarosios akcinės bendrovės „Investicijų ir verslo
                garantijos“ veiklos;
              </li>
              <li>
                Lietuvos Respublikos ūkio ministro 2008 m. kovo 26 d. įsakymas
                Nr. 4-119 „Dėl smulkiojo ar vidutinio verslo subjekto statuso
                deklaravimo tvarkos aprašo ir smulkiojo ar vidutinio verslo
                statuso deklaracijos formos patvirtinimo“;
              </li>
              <li>
                Lietuvos Respublikos finansų ministro 2014 m. spalio 16 d.
                įsakymu Nr. 1K-326 „Dėl Finansinių priemonių įgyvendinimo
                taisyklių patvirtinimo“ patvirtintos Finansinių priemonių
                įgyvendinimo taisyklės (toliau – Finansinių priemonių
                įgyvendinimo taisyklės);
              </li>
              <li>
                Finansinių priemonių išlaidų tinkamumo finansuoti
                rekomendacijos, patvirtintos 2016 m. birželio 29 d. Žmogiškųjų
                išteklių plėtros veiksmų programos, Ekonomikos augimo veiksmų
                programos, Sanglaudos skatinimo veiksmų programos ir 2014–2020
                metų Europos Sąjungos fondų investicijų veiksmų programos
                valdymo komitetų posėdžio protokolu Nr. 38 (toliau – Finansinių
                priemonių išlaidų tinkamumo finansuoti rekomendacijos), - kurios
                yra skelbiamos Europos Sąjungos struktūrinių fondų interneto
                svetainėje adresu{' '}
                <a href="http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3">
                  http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3
                </a>
                ;
              </li>
              <li>
                - Lietuvos Respublikos ekonomikos ir inovacijų ministro 2021 m.
                rugsėjo 2 d. įsakymu Nr. 4-970 „Dėl finansinės priemonės
                „Startuok, finansuojamos Europos regioninės plėtros fondo
                lėšomis, schemos patvirtinimo“ patvirtinta schema, su vėlesniais
                pakeitimais (toliau – Priemonės schema);
              </li>
              <li>
                Lietuvos Respublikos Vyriausybės 2014 m. birželio 25 d.
                nutarimas Nr. 571 „Dėl Lietuvos Respublikos 2014 – 2020 metų
                regioninės pagalbos žemėlapio“;
              </li>
              <li>
                kiti Priemonei taikytini teisės aktai, taisyklės,
                rekomendacijos, gairės, INVEGOS vidaus procedūros ir tvarkos, ir
                jų pakeitimai.
              </li>
            </ul>

            <h5 style={{ marginTop: '0.5rem' }}>
              1.4. Paraiškos teikimo procesas
            </h5>
            <p className={styles.firstLineP}>
              1.4.1. Paraiškų teikimas pradedamas po kvietimo, kuris skelbiamas
              viešai INVEGOS interneto svetainėje{' '}
              <a href="https://invega.lt">www.invega.lt</a>, paskelbimo.
            </p>
            <p className={styles.firstLineP}>
              1.4.2. Paraiškos teikiamos INVEGAI per elektroninę paraiškų
              teikimo sistemą INVEGOS internetinėje svetainėje{' '}
              <a href="https://paslaugos.invega.lt">
                https://paslaugos.invega.lt{' '}
              </a>
              (toliau – paraiškų teikimo sistema).
            </p>
            <p className={styles.firstLineP}>
            1.4.3. Vykdant Priemonę nustatytomis sąlygomis, fizinio asmens, dirbančio pagal verslo liudijimą arba užsiimančio individualia veikla (toliau – verslininkas), ar Verslo subjekto atstovo tapatybė nustatoma verslininkui, ar atstovui patvirtinus savo tapatybę naudojantis asmens tapatybės nustatymo sprendimu SMART-ID arba autentifikuojantis per el. bankininkystę. Jeigu Verslo subjekto atstovas yra užsienio valstybės pilietis, neturintis techninių galimybių, naudojantis asmens tapatybės nustatymo sprendimu SMART-ID arba autentifikuojantis per el. bankininkystę, patvirtinti savo tapatybės ar juridinis asmuo, kuris neturi vienasmenio valdymo organo, jis turi pateikti INVEGAI elektroniniu paštu igaliojimai-paskolos@invega.lt patvirtintą įgaliojimo kopiją ir INVEGA suteiks technines galimybes įgaliotam asmeniui atlikti Aprašymo 1.4 punkte nustatytus veiksmus Verslo subjekto vardu.
            </p>

            <p className={styles.firstLineP}>
              1.4.4. Kvietimas stabdomas pasibaigus paraiškų teikimo terminui
              arba paskirsčius Priemonei skirtą sumą.
            </p>
            <h5 style={{ marginTop: '0.5rem' }}>
              1.5. Paraiškos vertinimo procesas ir Paskolos sutarties sudarymas
            </h5>
            <p className={styles.firstLineP}>
              1.5.1. Paraiškos vertinamos eiliškumo tvarka pagal jų registravimo
              INVEGOJE datą ir laiką. INVEGA pateiktos paraiškos vertinimą
              atlieka ne vėliau kaip per 30 darbo dienų. Paraiška laikoma
              pateikta, kai paraiškų teikimo sistemoje Verslo subjektas mato
              būseną „Pateikta vertinti“. Paraiškos vertinimo terminas
              skaičiuojamas nuo tinkamai užpildytos paraiškos ir papildomų
              dokumentų pateikimo INVEGAI dienos. Paraiškos vertinimo terminas,
              atsižvelgiant į objektyvias aplinkybes (pvz., gautas ir vertinamas
              neįprastai didelis paraiškų kiekis, kilus techninėms kliūtims
              paraiškoms vertinti), INVEGOS vadovo sprendimu gali būti
              pratęstas, tačiau ne ilgesniam kaip 10 darbo dienų laikotarpiui.
            </p>
            <p className={styles.firstLineP}>
              1.5.2. Tuo atveju, jei Verslo subjekto atstovas per 20 darbo dienų
              nuo INVEGOS prašymo pateikti informaciją ar papildomus dokumentus
              gavimo dienos, nepateikia jų, paraiška toliau nevertinama ir yra
              atmetama, arba priimtas sprendimas dėl Paskolos suteikimo netenka
              galios.
            </p>
            <p className={styles.firstLineP}>
              1.5.3. INVEGA, priėmusi sprendimą dėl Paskolos (ne) suteikimo, per
              paraiškų teikimo sistemą, ne vėliau kaip per 5 darbo dienas
              išsiunčia Verslo subjektui sprendimą (ne) suteikti Paskolą. Jei
              INVEGOS sprendime dėl Paskolos suteikimo yra nustatytos sąlygos,
              kurios turi būti įvykdytos iki Paskolos sutarties sudarymo ar
              numatyti pateikti dokumentai, Paskolos sutartis Verslo subjektui
              išsiunčiama tik per sprendime nustatytą terminą įvykdžius
              atitinkamas sąlygas ar pateikus dokumentus. Verslo subjektui,
              įvykdžius sprendime numatytas sąlygas, INVEGA ne vėliau kaip per 5
              darbo dienas išsiunčia Paskolos sutartį. Verslo subjekto atstovas
              Paskolos sutartį turi pasirašyti ne vėliau kaip per 10 darbo dienų
              po Paskolos sutarties išsiuntimo. Paskolos sutartis pasirašoma
              kvalifikuotu elektroniniu parašu.
            </p>
            <p className={styles.firstLineP}>
              1.5.4. Nepasirašius Paskolos sutarties per Aprašymo 1.5.3
              papunktyje nurodytą terminą, sprendimas suteikti Paskolą netenka
              galios.
            </p>
            <p className={styles.firstLineP}>
              1.5.5. INVEGA turi teisę tvarkyti su Aprašymo įgyvendinimu
              susijusius asmens duomenis. Asmens duomenys tvarkomi vadovaujantis
              2016 m. balandžio 27 d. Europos Parlamento ir Tarybos reglamentu
              (ES) Nr. 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens
              duomenis ir dėl laisvo tokių duomenų judėjimo ir kuriuo
              panaikinama Direktyva Nr. 95/46/EB (Bendrasis duomenų apsaugos
              reglamentas) ir Lietuvos Respublikos asmens duomenų teisinės
              apsaugos įstatymu. Asmens duomenys tvarkomi viešinimo, apskaitos
              ir audito tikslais bei atrankinių patikrų metu tikrinant nurodytos
              Verslo subjekto informacijos tikrumą. Su Aprašymo įgyvendinimu
              susiję asmens duomenys INVEGOJE tvarkomi ir saugomi 10 metų nuo
              paskutinio dokumento pagal Priemonę gavimo datos. Informacija apie
              asmens duomenų tvarkymą pateikiama INVEGOS interneto svetainėje{' '}
              <a href="https://invega.lt/lt/asmens-duomenu-saugojimo-politika/">
                https://invega.lt/lt/asmens-duomenu-saugojimo-politika/
              </a>
              . INVEGA turi teisę perduoti tvarkomus asmens duomenis ir jais
              keistis su Lietuvos Respublikos valstybės institucijomis, kurios
              tvarko asmens duomenis, reikalingus šios Priemonės tinkamo
              įgyvendinimo tikslais.
            </p>
            <p className={styles.firstLineP}>
              1.5.6. INVEGA atsisako suteikti Paskolą arba nutraukia Paskolos
              sutartį su Paskolos gavėju Aprašyme nustatytais pagrindais, kai:
              <br />
              <div style={{ marginLeft: '3rem' }}>
                <span>
                  1.5.6.1. Verslo subjektui ar Paskolos gavėjui ir (ar) jo
                  naudos gavėjui, kaip jis apibrėžtas LR pinigų plovimo ir
                  teroristų finansavimo prevencijos įstatymo 2 straipsnio 14
                  dalyje, arba fiziniams ir juridiniams asmenims, kurių naudai
                  bus naudojamos Paskolos lėšos (toliau bet kuris iš jų
                  vadinamas Asmuo) taikomos sankcijos (bet kokios prekybinės,
                  ekonominės ar finansinės sankcijos, embargas ar kitos
                  ribojančios priemonės), kurias nustato, taiko ar administruoja
                  Jungtinių Tautų Saugumo Taryba, Europos Sąjunga, Lietuvos
                  Respublika, Jungtinių Amerikos Valstijų Vyriausybė (įskaitant
                  Jungtinių Amerikos Valstijų Iždo departamento Užsienio lėšų
                  kontrolės biurą (angl. The Office of Foreign Assets Control of
                  the U.S. Department of the Treasury), Jungtinė Didžiosios
                  Britanijos ir Šiaurės Airijos Karalystė;
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.2. INVEGA turi duomenų, kad bet kuris Asmuo yra arba
                  gali būti susijęs su pinigų plovimu, teroristų finansavimu ir
                  (ar) kita kriminaline veikla (pvz., Asmuo veikia ar veikė
                  didelės pinigų plovimo ir (ar) teroristų finansavimo rizikos
                  srityje ir Asmuo nepateikia pakankamos dokumentacijos ar kitų
                  įrodymų apie Asmens nuosavybės ir valdymo struktūrą, lėšų
                  sandoriams vykdyti šaltinį, verslo santykius ar kitos
                  informacijos ir dokumentų, kurie yra reikalingi laikantis
                  privalomų teisės aktų, reglamentuojančių pinigų plovimo ir
                  teroristų finansavimo prevenciją);
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.3. INVEGA dėl Verslo subjekto ar Paskolos gavėjo veiksmų
                  ar neveikimo negali tinkamai atlikti visų veiksmų, kurie pagal
                  taikytinus teisės aktus ir (ar) INVEGOS vidaus teisės aktus,
                  reikalingi „Pažink savo klientą“ principo įgyvendinimui (pvz.,
                  Verslo subjektas nepateikia visų reikalingų dokumentų ar
                  įrodymų, INVEGAI kyla abejonių dėl pateiktų dokumentų tikrumo
                  ar autentiškumo);
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.4. Verslo subjektas neatitinka Paskolos gavėjo tinkamumo
                  sąlygų;
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.5. Prašoma suteikti Paskola neatitinka Paskolos
                  tinkamumo sąlygų;
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.6. Verslo subjektas pateikė neužpildytas privalomas
                  formas, netikslius ar neišsamius duomenis, pagrindžiančius
                  atitikimą Paskolos gavėjo tinkamumo sąlygoms ir (ar) prašant
                  nustatytu terminu nepatikslino, nepapildė, nepaaiškino
                  pateiktų netikslių ar neišsamių duomenų;
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.7. Verslo subjektas pateikė informaciją, kurios
                  pagrįstumo negali įrodyti;
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.8. INVEGA, įvertinusi paraišką, nustato, kad Verslo
                  subjektui Paskola negali būti suteikiama dėl per didelės
                  Projekto ir (ar) Verslo subjekto rizikos. Rizikingumo lygio
                  vertinimas atliekamas INVEGOS vidaus procedūrų nustatyta
                  tvarka;
                </span>
                <br />
                <span style={{ marginLeft: '3rem' }}>
                  1.5.6.9. Paraiška atmetama pagal kitus paraiškos vertinimo
                  dieną galiojusius INVEGOS procedūrose ir tvarkose numatytus
                  pagrindus.
                </span>
                <br />
                <br />
              </div>
              <p className={styles.firstLineP}>
                1.5.7. Esant poreikiui, INVEGA turi teisę kreiptis į Valstybinę
                mokesčių inspekciją prie Lietuvos Respublikos finansų
                ministerijos, Finansinių nusikaltimų tyrimo tarnybą prie
                Lietuvos Respublikos vidaus reikalų ministerijos, Specialiųjų
                tyrimų tarnybą, VĮ „Registrų centras“, kredito biurą
                „Creditinfo“ ir kitas kompetentingas institucijas tam, kad būtų
                gauta visa informacija apie Verslo subjektą, ir atlikti
                papildomą patikrinimą prieš priimant sprendimą suteikti Paskolą
                Verslo subjektui. Tuo atveju, jeigu tokie asmenys ir (arba)
                Verslo subjektas nepatvirtina ir (ar) nepagrindžia paraiškoje
                pateiktos informacijos teisingumo, INVEGA atmeta paraišką.
              </p>
              <p className={styles.firstLineP}>
                1.5.8. Tuo atveju, jei iki Paskolos sutarties pasirašymo
                paaiškėja aplinkybės, dėl kurių Paskola Verslo subjektui pagal
                Priemonės sąlygas negali būti suteikiama, INVEGA atsisako
                suteikti Paskolą Verslo subjektui.
              </p>
            </p>
            <h4
              style={{
                marginTop: '0rem',
                fontSize: '1.3rem',
                textAlign: 'center',
              }}
            >
              2. PRIEMONĖS REIKALAVIMAI
            </h4>
            <h5 style={{ marginTop: '0.5rem', marginBottom: '0.15rem' }}>
              2.1. Priemonės aprašymas:
            </h5>
            <p>
              Priemonę įgyvendins INVEGA, teikdama Paskolas tiesiogiai Verslo
              subjektams, kurie atitinka Paskolos gavėjams nustatytus
              reikalavimus, ir Paskola atitinka Paskolos tinkamumo sąlygas,
              nustatytas Aprašyme.
            </p>
            <h5 style={{ marginTop: '0.5rem' }}>
              2.2. Pagrindinės sąvokos ir sąlygos
              <br />
            </h5>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td>Vartojamos sąvokos</td>
                  <td>
                    <b>Finansinės technologijos</b> – technologijomis
                    grindžiamos finansinės inovacijos, padedančios kurti naujus
                    verslo modelius, veiklos programas, procesus ir produktus,
                    kai įmonės vystomomis technologijomis naudojasi ne pati
                    įmonė, bet jos klientai – kitos finansų įstaigos, dirbančios
                    su galutiniais finansinių paslaugų, susijusių su
                    technologijomis, gavėjais.
                    <br /> <br />
                    <b>Įmonių grupė</b> – suprantama taip, kaip apibrėžta
                    Lietuvos Respublikos įmonių grupių konsoliduotosios
                    atskaitomybės įstatymo 3 straipsnio 1 dalyje.
                    <br /> <br />
                    <b>Paskola</b> – INVEGOS Paskolos gavėjui iš Priemonės lėšų
                    suteikta paskola.
                    <br /> <br />
                    <b>Paskolos gavėjas</b> – SVV subjektas, kuriam suteikta
                    Paskola.
                    <br /> <br />
                    <b>Paskolos sutartis</b> – tarp Paskolos teikėjo ir Paskolos
                    gavėjo pasirašyta Paskolos sutartis dėl Paskolos suteikimo.
                    <br /> <br />
                    <b>Privatus finansavimas</b> – Paskolos gavėjo nuosavos
                    lėšos ir (ar) paskola, gauta iš kitų kredito ar finansų
                    įstaigų, privačiųjų juridinių ir (ar) fizinių asmenų.
                    <br /> <br />
                    <b>Projektas</b> – Paskolos gavėjo investicijų įgyvendinimas
                    ir (ar) apyvartinio kapitalo lėšų papildymas.
                    <br /> <br />
                    <b>Socialinio poveikio projektas</b> – tai projektas, pagal
                    kurį verslininko ar įmonės pelno siekimas susiejamas su
                    socialiniais tikslais ir prioritetais.
                    <br /> <br />
                    <b>SVV subjektas</b> – Smulkiojo ir vidutinio verslo
                    subjektas, kaip jis apibrėžtas SVV įstatyme.
                    <br /> <br />
                    <b>SVV subjekto statuso deklaracija</b> – Smulkiojo ir
                    vidutinio verslo subjekto statuso deklaracija, kurios forma
                    ir pildymo instrukcija patvirtinta Lietuvos Respublikos ūkio
                    ministro 2008 m. kovo 26 d. įsakymu Nr. 4-119 „Dėl smulkiojo
                    ir vidutinio verslo subjekto statuso deklaravimo tvarkos
                    aprašo ir smulkiojo ir vidutinio verslo subjekto statuso
                    deklaracijos formos patvirtinimo“.
                    <br /> <br />
                    <b>Vienos įmonės deklaracija</b> – deklaracija, pagal kurią
                    yra patikrinama Verslo subjekto ar Paskolos gavėjo teisė
                    gauti bendrą vienai įmonei suteikiamą <i>de minimis</i>{' '}
                    pagalbą, kuri užpildoma pagal Lietuvos Respublikos
                    ekonomikos ir inovacijų ministerijos parengtą ir interneto
                    svetainėje{' '}
                    <a href="http://www.esinvesticijos.lt/lt/dokumentai/vienos-imones-deklaracijos-pagal-komisijos-reglamenta-es-nr-1407-2013">
                      http://www.esinvesticijos.lt/lt/dokumentai/vienos-imones-deklaracijos-pagal-komisijos-reglamenta-es-nr-1407-2013
                    </a>{' '}
                    paskelbtą rekomenduojamą formą (aktuali redakcija).
                    <br /> <br />
                    Kitos šiame Aprašyme vartojamos sąvokos atitinka Bendrosios
                    išimties reglamente, <i>De minimis</i> reglamente, Lietuvos
                    Respublikos įstatyme dėl užsieniečių teisinės padėties,
                    Lietuvos Respublikos smulkiojo ir vidutinio verslo plėtros
                    įstatyme, Lietuvos Respublikos technologijų ir inovacijų
                    įstatyme, Lietuvos Respublikos užimtumo įstatymo 25
                    straipsnio 5 dalyje ir Asmenų, sergančių priklausomybės nuo
                    psichoaktyvių medžiagų ligomis, integracijos į visuomenę
                    koncepcijoje, patvirtintoje Lietuvos Respublikos socialinės
                    apsaugos ir darbo ministro ir Lietuvos Respublikos sveikatos
                    apsaugos ministro 2001 m. spalio 4 d. įsakymu Nr. 129/518
                    „Dėl Asmenų, sergančių priklausomybės nuo psichoaktyvių
                    medžiagų ligomis, integracijos į visuomenę koncepcijos
                    patvirtinimo“, apibrėžtas sąvokas
                  </td>
                </tr>
                <tr>
                  <td>Paskolos teikėjas</td>
                  <td>INVEGA</td>
                </tr>
                <tr>
                  <td>
                    SVV subjekto, vykdančio Socialinio poveikio projektą,
                    papildomos specialiosios tinkamumo sąlygos
                  </td>
                  <td>
                    Verslo subjektas gali veikti tradiciškai, t. y. siekti
                    pelno, tačiau į verslą tiesiogiai integravus socialinę
                    misiją, jis tampa SVV subjektu, vykdančiu Socialinio
                    poveikio projektą.
                    <br />
                    <br />
                    SVV subjektas, vykdantis Socialinio poveikio projektą,
                    veikiantis ne ilgiau kaip penkerius metus, vykdo ekonominę
                    veiklą:
                    <br />
                    1. teikia paslaugas ir (arba) gamina prekes, skirtas
                    socialinėms reikmėms (socialinių globos namų, sveikatos
                    priežiūros, pagalbos pagyvenusiems asmenims ar
                    neįgaliesiems, socialiai pažeidžiamų grupių integracijos,
                    vaikų priežiūros ir t. t.), arba
                    <br />
                    2. gamina prekes arba teikia paslaugas tokiu būdu, kad būtų
                    siekiamas socialinio pobūdžio tikslas (socialinė ir (arba)
                    darbinė integracija, suteikiant galimybę dirbti socialiai
                    pažeidžiamiems asmenims).
                    <br />
                    <br />
                    <br />
                    Galimi SVV subjekto, vykdančio Socialinio poveikio projektą,
                    veikiančio ne ilgiau kaip penkerius metus, veiklos modeliai:{' '}
                    <br />
                    1. Verslo subjektas savo veikloje{' '}
                    <b>įdarbina socialiai pažeidžiamus asmenis</b>, arba
                    <br />
                    2. Verslo subjektas vykdydamas veiklą{' '}
                    <b>spręs visuomenės socialines problemas</b> bent vienoje iš
                    šių sričių:
                    <br />
                    a) biologinės įvairovės ir kraštovaizdžio apsauga, gyvūnų
                    globa;
                    <br />
                    b) kultūrinė veikla ir visuomenės kūrybiškumo ugdymas,
                    kultūros paslaugų prieinamumo plėtojimas;
                    <br />
                    c) ligų prevencija, pacientų teisių gynimas ir tarpusavio
                    pagalbos stiprinimas;
                    <br />
                    d) visuomenės sveikatinimas;
                    <br />
                    e) žmogaus teisių ir lygių galimybių gynimas ir
                    užtikrinimas;
                    <br />
                    f) vaikų ir jaunimo švietimas ir ugdymas bei pensinio
                    amžiaus žmonių neformalus švietimas ir asmenybės ugdymas;
                    <br />
                    g) socialinių paslaugų, nustatytų Socialinių paslaugų
                    kataloge, patvirtintame Lietuvos Respublikos socialinės
                    apsaugos ir darbo ministro 2006 m. balandžio 5 d. įsakymu
                    Nr. A1-93 „Dėl Socialinių paslaugų katalogo patvirtinimo“;
                    teikimas; <br />
                    h) neįgaliųjų veiklos sąlygų gerinimas ir galimybių laisvai
                    judėti bei naudotis visuomenėje teikiamomis paslaugomis
                    užtikrinimas;
                    <br />
                    i) neįgaliųjų, iš įkalinimo įstaigų grįžusių asmenų,
                    priklausomybės ligomis sergančių asmenų, profesinės
                    kvalifikacijos neturinčių asmenų, ilgalaikių bedarbių, kaip
                    jie apibrėžti Lietuvos Respublikos užimtumo įstatymo 25
                    straipsnio 5 punkte, pabėgėlių mokymai ir reintegracija į
                    darbo rinką.
                    <br />
                    <br />
                    <br />
                    Paskolos gavėjo atitiktis šiam reikalavimui bus nustatoma
                    pagal Paskolos gavėjo verslo plane, kuris bus teikiamas
                    Paskolos teikėjui kartu su paraiška, pateiktą informaciją.
                    Verslo plane Paskolos gavėjas privalo pagrįsti kaip spręs
                    visuomenės socialines problemas teikdamas paslaugas ar
                    gamindamas prekes ir (ar) įdarbins socialiai pažeidžiamus
                    asmenis.
                    <br />
                    <br />
                    Paskolos gavėjo metinės pajamos iš ekonominės veiklos, kuria
                    sprendžiamos socialinės problemos, ir (ar) įdarbinti
                    socialiai pažeidžiami asmenys, visu Paskolos sutarties
                    galiojimo metu turi sudaryti ne mažiau kaip 30 proc. visų
                    Paskolos gavėjo pajamų ir (ar) įdarbintų socialiai
                    pažeidžiamų asmenų dalį įmonėje.
                    <br />
                    <br />
                    Visą Paskolos sutarties galiojimo laikotarpį po Paskolos
                    panaudojimo termino pabaigos Paskolos gavėjo metinės pajamos
                    iš ekonominės veiklos, kuria sprendžiamos socialinės
                    problemos, ir (ar) įdarbinti socialiai pažeidžiami asmenys,
                    turi sudaryti ne mažiau kaip 30 proc. visų Paskolos gavėjo
                    pajamų ir (ar) 30 proc. įdarbintų socialiai pažeidžiamų
                    asmenų dalį įmonėje. Siekiant įvertinti atitikimą šiam
                    reikalavimui po Paskolos panaudojimo termino pabaigos,
                    Paskolos gavėjas, Paskolos teikėjui Paskolos sutartyje
                    nustatyta tvarka, kartą per 1 metus, turės teikti
                    informaciją apie Projekto įgyvendinimą, gaunamą pajamų dalį
                    iš ekonominės veiklos, kuria sprendžiamos socialinės
                    problemos, ir (ar) informaciją apie įdarbintus socialiai
                    pažeidžiamus asmenis.
                    <br />
                    <br />
                    Paskolos teikėjui nustačius, kad Paskolos gavėjo metinės
                    pajamos iš ekonominės veiklos, kuria sprendžiamos socialinės
                    problemos, ir (ar) įdarbinti socialiai pažeidžiami asmenys,
                    sudaro mažiau kaip 30 proc. visų Paskolos gavėjo pajamų ir
                    (ar) įdarbintų socialiai pažeidžiamų asmenų dalį įmonėje,
                    Paskolos gavėjui taikoma 5 proc. suteiktos Paskolos dydžio
                    bauda ir likusi Paskolos dalis bus perskaičiuojama netaikant
                    Palūkanų nuolaidos
                  </td>
                </tr>
                <tr>
                  <td>Privataus finansuotojo dalyvavimas Projekte</td>
                  <td>
                    Investicijų finansavimo atveju Projekto įgyvendinime privalo
                    dalyvauti ir privatūs finansuotojai, kurie turi prisidėti ne
                    mažiau kaip 20 proc. Projekto išlaidų (suma skaičiuojama be
                    PVM, išskyrus tuos atvejus kai Paskolos gavėjas yra ne PVM
                    mokėtojas), tarp jų ne mažiau kaip 10 proc. Projekto išlaidų
                    turi sudaryti paties Paskolos gavėjo nuosavos lėšos.
                    Paskolos teikėjo suteikiamos paskolos suma negali viršyti 80
                    proc. investicinio projekto išlaidų (suma skaičiuojama be
                    PVM, išskyrus tuos atvejus kai Paskolos gavėjas yra ne PVM
                    mokėtojas).
                    <br />
                    <br />
                    Privačiu finansuotoju gali būti Paskolos gavėjas ir Lietuvos
                    Respublikoje ar užsienio valstybėje veikiantis fizinis ir
                    (ar) privatusis juridinis asmuo, kuris prisideda prie
                    Projekto įgyvendinimo.
                    <br />
                    <br />
                    Paskolos gavėjas, kartu su paraiška Paskolai gauti, turi
                    pateikti Privataus finansuotojo ketinimo dalyvauti Projekte
                    raštą. Raštas teikiamas tuo atveju, kai Privatus
                    finansuotojas ketina suteikti paskolą. Jei Paskolos gavėjas
                    ketina Projekte dalyvauti nuosavomis lėšomis, tokiu atveju
                    ši informacija turi būti pagrindžiama verslo plane, kuris
                    teikiamas kartu su paraiška Paskolai gauti.
                    <br />
                    <br />
                    Privataus finansuotojo atliktos investicijos į Projektą
                    (apmokėtos Projekto išlaidos), kurios atliktos ne seniau
                    kaip 3 mėn. iki paraiškos Paskolai gauti pateikimo Paskolos
                    teikėjui dienos, laikomos tinkamu Privataus finansuotojo
                    dalyvavimu Projekte.
                    <br />
                    <br />
                    Privatus finansuotojas dalyvauti Projekte turi iki Paskolos
                    lėšų išmokėjimo pradžios arba kartu su Paskolos lėšų
                    išmokėjimu. Privačiam finansuotojui dalyvaujant Projekte
                    turi būti išlaikytas reikalaujamas Privataus finansuotojo
                    asmeninių ir Paskolos lėšų santykis. Paskolos gavėjas
                    privalo pateikti išlaidas pagrindžiančius dokumentus,
                    susijusius su Privataus finansuotojo dalyvavimu Projekte
                  </td>
                </tr>
                <tr>
                  <td>
                    Paskolos gavėjų bendrosios tinkamumo sąlygos (Paskola gali
                    būti teikiama Verslo subjektui, kuris atitinka šiuos
                    kriterijus)
                  </td>
                  <td>
                    <b>
                      <u>
                        Tinkamumo sąlygos, kurios yra bendros visiems Paskolos
                        gavėjams:
                      </u>
                    </b>
                    <br />
                    1. Verslo subjektas, kuris:
                    <br />
                    a) yra SVV subjektas,
                    <br />
                    b) veikia ne ilgiau kaip trejus metus arba <br />
                    c) vykdo Socialinio poveikio projektą ir veikia ne ilgiau
                    kaip penkerius metus arba <br />
                    d) yra įsteigtas Lietuvoje ne anksčiau kaip nuo 2022 m.
                    vasario 24 d., kurio dalyvis (akcininkas, narys, dalininkas)
                    ar savininkas yra leidimą gyventi Lietuvoje turintis
                    Ukrainos pilietis ar Ukrainos juridinis asmuo, ir kuris
                    veikia ne ilgiau kaip penkerius metus arba <br />
                    e) yra Ukrainos pilietybę ir ne anksčiau kaip nuo 2022 m.
                    vasario 24 d. leidimą gyventi Lietuvoje turintis
                    verslininkas, veikiantis Lietuvoje ne ilgiau kaip penkerius
                    metus.
                    <br />
                    Verslo subjekto veiklos vykdymo laikotarpis apskaičiuojamas
                    nuo įmonės įregistravimo Juridinių asmenų registre arba
                    individualios veiklos įregistravimo Valstybinėje mokesčių
                    inspekcijoje prie Lietuvos Respublikos finansų ministerijos
                    datos iki paraiškos suteikti Paskolą pateikimo Paskolos
                    teikėjui datos. Kai Verslo subjektui Paskola teikiama
                    vadovaujantis Bendrosios išimties reglamento nuostatomis,
                    Verslo subjekto veiklos vykdymo laikotarpis vertinamas pagal
                    ilgiausiai veikiančią įmonę įmonių grupėje (taikoma, jei
                    Verslo subjektas priklauso įmonių grupei);
                    <br />
                    2. kai teikiamos Paskolos iki 25 000 Eur, – Verslo subjektas
                    veikia ilgiau kaip 1 metus po jo registracijos (netaikoma
                    vidutinėms įmonėms ir SVV subjektams, kurių dalyvis
                    (akcininkas, narys, dalininkas) ar savininkas yra leidimą
                    gyventi Lietuvoje turintis Ukrainos pilietis arba Ukrainos
                    juridinis asmuo, ir Ukrainos pilietybę ir leidimą gyventi
                    Lietuvoje turintiems verslininkams). Verslo subjekto veiklos
                    vykdymo laikotarpis apskaičiuojamas nuo įmonės įregistravimo
                    Juridinių asmenų registre arba individualios veiklos
                    įregistravimo Valstybinėje mokesčių inspekcijoje prie
                    Lietuvos Respublikos finansų ministerijos datos iki
                    paraiškos suteikti Paskolą pateikimo Paskolos teikėjui
                    datos;
                    <br />
                    3. neveikia žemės ūkio sektoriuje, kaip jis apibrėžtas 2014
                    m. birželio 25 d. Komisijos reglamento (ES) Nr. 702/2014,
                    kuriuo skelbiama, kad tam tikrų kategorijų pagalba žemės bei
                    miškų ūkio sektoriuose ir kaimo vietovėse yra suderinama su
                    vidaus rinka pagal Sutarties dėl Europos Sąjungos veikimo
                    107 ir 108 straipsnius, su visais pakeitimais 2 straipsnio 3
                    dalyje, išskyrus atvejus, kai Paskolų gavėjas vykdo arba
                    ketina vykdyti inovacinę veiklą , nevykdo žuvininkystės ir
                    akvakultūros veiklos, kaip tai apibrėžta Lietuvos
                    Respublikos žuvininkystės įstatymo 2 straipsnio 1 ir 34
                    dalyse, ar jo vykdoma pagrindinė veikla pagal Valstybės
                    duomenų agentūros generalinio direktoriaus įsakymu
                    tvirtinamą Ekonominės veiklos rūšių klasifikatorių (EVRK 2
                    red.)(toliau – EVRK 2 red.) nėra finansinė ir draudimo
                    veikla (visi veiklos kodai pagal EVRK 2 red. 64–66 skyrius),
                    išskyrus atvejus, kai šis Paskolų gavėjas vysto finansines
                    technologijas;
                    <br />
                    4. paraiškos pateikimo metu veikia Lietuvos Respublikoje
                    arba kitoje ES šalyje narėje, jei vykdant Paskolos sutartį
                    sukurta nauda atitenka Lietuvos Respublikai, kaip nurodyta
                    Finansinių priemonių išlaidų tinkamumo finansuoti
                    rekomendacijose, tačiau toks finansavimas negali viršyti 15
                    procentų Priemonei skirtos bendros lėšų sumos, kaip tai
                    nurodyta Reglamento (ES) Nr. 1303/2013 70 straipsnio 2
                    dalies b punkte;
                    <br />
                    5. paraiškos pateikimo metu atitinka minimalius patikimo
                    mokesčių mokėtojo kriterijus, nustatytus Lietuvos
                    Respublikos mokesčių administravimo įstatymo 401
                    straipsnyje. Atitiktis šiai sąlygai vertinama pagal viešai
                    paskelbtą Valstybinės mokesčių inspekcijos prie Lietuvos
                    Respublikos finansų ministerijos informaciją, skelbiamą
                    interneto svetainėje adresu{' '}
                    <a href="https://www.vmi.lt/evmi/mokesciu-moketoju-informacija">
                      https://www.vmi.lt/evmi/mokesciu-moketoju-informacija
                    </a>
                    ;<br />
                    6. Verslo subjektui ar Paskolos gavėjui ir (ar) jo naudos
                    gavėjui, kaip jis apibrėžtas Lietuvos Respublikos pinigų
                    plovimo ir teroristų finansavimo prevencijos įstatymo 2
                    straipsnio 14 dalyje, arba fiziniams ir juridiniams
                    asmenims, kurių naudai bus naudojamos Paskolos lėšos, nėra
                    taikomos sankcijos (bet kokios prekybinės, ekonominės ar
                    finansinės sankcijos, embargas ar kitos ribojančios
                    priemonės), kurias nustato, taiko ar administruoja Jungtinių
                    Tautų Saugumo Taryba, Europos Sąjunga, Lietuvos Respublika,
                    Jungtinių Amerikos Valstijų Vyriausybė (įskaitant Jungtinių
                    Amerikos Valstijų Iždo departamento Užsienio lėšų kontrolės
                    biurą (angl.{' '}
                    <i>
                      The Office of Foreign Assets Control of the U.S.
                      Department of the Treasury
                    </i>
                    ), Jungtinė Didžiosios Britanijos ir Šiaurės Airijos
                    Karalystė;
                    <br />
                    7. Verslo subjekto nuosavybės rodiklis (nuosavo kapitalo ir
                    viso turto santykis) po Paskolos suteikimo nėra mažesnis
                    kaip 0,1, skaičiuojant pagal paskutinių finansinių metų
                    finansinių ataskaitų rinkinį ar paskutinį tarpinių
                    finansinių ataskaitų rinkinį, kaip tai apibrėžta Lietuvos
                    Respublikos įmonių finansinės atskaitomybės įstatymo 2
                    straipsnyje. Jeigu pagal paskutinių finansinių metų
                    finansinių ataskaitų rinkinį nuosavybės rodiklis po Paskolos
                    suteikimo yra mažesnis kaip 0,1, o pagal paskutinį tarpinių
                    finansinių ataskaitų rinkinį yra didesnis kaip 0,1, Verslo
                    subjektas privalo pateikti Paskolos teikėjui nuosavybės
                    struktūros pokyčius pagrindžiančius dokumentus (sukauptų
                    nuostolių padengimo, kapitalo padidinimo apmokėjimo, turto
                    vertinimo ir (arba) kitus nuosavybės struktūros pokyčius
                    įrodančius dokumentus). Nuosavybės rodiklis skaičiuojamas
                    pagal formulę:
                    <br />
                    NKR = NK / VT, kurioje
                    <br />
                    NK – nuosavas kapitalas (Kapitalas + Akcijų priedai +
                    Perkainojimo rezervai + Rezervai + Nepaskirstytasis pelnas
                    (nuostoliai);
                    <br />
                    VT – visas turtas (po naujos paskolos išdavimo).
                    <br />
                    <br />
                    8. paraiškos pateikimo metu, kai ją teikia juridinis asmuo,
                    jam nėra iškelta nemokumo byla ir (ar) jis nėra
                    restruktūrizuojamas pagal Lietuvos Respublikos juridinių
                    asmenų nemokumo įstatymą, kai paraišką teikia verslininkas,
                    kaip jis apibrėžtas SVV įstatymo 2 straipsnio 21 dalyje, –
                    jam nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
                    Respublikos fizinių asmenų bankroto įstatymą. Atitiktis šiai
                    sąlygai vertinama pagal viešai prieinamus Juridinių asmenų
                    registro ir Audito, apskaitos, turto vertinimo ir nemokumo
                    valdymo tarnybos prie Lietuvos Respublikos finansų
                    ministerijos duomenis
                    <br />
                    9. Verslo subjektas ir Įmonių grupė (jei Verslo subjektas
                    priklauso Įmonių grupei) sprendimo suteikti Paskolą metu
                    nėra sunkumų patiriantys, kaip nustatyta Bendrosios išimties
                    reglamento 2 straipsnio 18 punkte. Paskolos teikėjas,
                    siekdamas įsitikinti, kad Verslo subjektas ir Įmonių grupė
                    (jei Verslo subjektas priklauso Įmonių grupei) sprendimo
                    suteikti Paskolą metu nėra sunkumų patiriantys, iki
                    sprendimo suteikti Paskolą patikrina Verslo subjekto ir
                    Įmonių grupės (jei Verslo subjektas priklauso Įmonių grupei)
                    patvirtintus paskutinių dviejų finansinių metų metinių
                    finansinių ataskaitų rinkinius. Paaiškėjus, kad, pagal
                    paskutinių dviejų finansinių metų metinių finansinių
                    ataskaitų rinkinius, Verslo subjektas ir Įmonių grupė (jei
                    Verslo subjektas priklauso Įmonių grupei) buvo sunkumų
                    patiriantys, tačiau Verslo subjektui pateikus
                    pagrindžiančius dokumentus**, kad iki sprendimo suteikti
                    Paskolą dienos, Verslo subjektas ir Įmonių grupė (jei Verslo
                    subjektas priklauso Įmonių grupei) nebėra laikomi sunkumų
                    patiriančiais, bus laikoma, kad toks Verslo subjektas ir
                    Įmonių grupė (jei Verslo subjektas priklauso Įmonių grupei),
                    sprendimo dėl Paskolos suteikimo metu nėra sunkumų
                    patiriantys; ** Paskolos gavėjas turi pagrįsti, kad jis ir
                    Įmonių grupė (jei Paskolos gavėjas priklauso Įmonių grupei)
                    nėra sunkumų patiriantis, pateikdamas Paskolos teikėjui
                    finansinės atskaitomybės už paskutinį praėjusį ketvirtį arba
                    mėnesio, ėjusio prieš paraiškos Paskolai gauti pateikimą,
                    paskutinę dieną dokumentus ir, jei per laikotarpį nuo
                    finansinių metų metinių finansinių ataskaitų rinkinio
                    pateikimo VĮ „Registrų centras“ iki sprendimo suteikti
                    Paskolą dienos įvyko nuosavybės struktūros (kapitalo, akcijų
                    priedų, perkainojimo rezervų, rezervų) pokyčių, –
                    dokumentus, pagrindžiančius šiuos pokyčius (sukauptų
                    nuostolių padengimo, kapitalo padidinimo apmokėjimo, turto
                    vertinimo ir (arba) kitus susijusius dokumentus).
                    <br />
                    10. paraiškos pateikimo metu Verslo subjektas ir Įmonių
                    grupė (jei Verslo subjektas priklauso Įmonių grupei), yra
                    pateikusi VĮ „Registrų centras“ paskutinių finansinių metų
                    finansinių ataskaitų rinkinį, kuriame būtų išsamiai
                    išdėstyta nuosavo kapitalo sudėtis. Jei Verslo subjekto
                    Įmonės grupėje esanti įmonė (-ės) yra registruota ne
                    Lietuvos Respublikoje, tuomet paskutinių finansinių metų
                    finansinių ataskaitų rinkinį, kuriame būtų detaliai
                    atskleista nuosavo kapitalo sudėtis, privalo pateikti
                    Paskolos teikėjui. Šis reikalavimas netaikomas verslininkams
                    ir toms įmonėms, kurios veikia trumpiau nei vienerius
                    metus***;
                    <br />
                    *** Šiuo atveju Paskolos gavėjas turi pateikti einamųjų
                    finansinių metų tarpinių finansinių ataskaitų arba
                    lygiaverčių dokumentų kopijas.
                    <br />
                    11. Verslo subjektui nėra išduotas vykdomasis raštas sumoms
                    išieškoti pagal ankstesnį Europos Komisijos sprendimą,
                    kuriame Lietuvos valstybės suteikta pagalba skelbiama
                    neteisėta ir nesuderinama su vidaus rinka, arba Verslo
                    subjektas yra grąžinęs visą neteisėtos pagalbos sumą,
                    įskaitant palūkanas, kaip nustatyta 2015 m. liepos 13 d.
                    Tarybos reglamente (ES) 2015/1589, nustatančiame išsamias
                    Sutarties dėl Europos Sąjungos veikimo 108 straipsnio
                    taikymo taisykles (kodifikuota redakcija);
                    <br />
                    12. Verslo subjektui ir (ar) jo akcininkams, pajininkams ar
                    dalininkams, turintiems ne mažiau kaip 25 procentus Verslo
                    subjekto akcijų, pajų ar kitokių dalyvavimą įmonės kapitale
                    žyminčių kapitalo dalių arba 25 procentus ir daugiau įmonės
                    dalyvių balsų, Verslo subjekto vadovui, kitam valdymo ar
                    priežiūros organo nariui ar kitam asmeniui, turinčiam teisę
                    atstovauti Verslo subjektui ar jį kontroliuoti, jo vardu
                    priimti sprendimą, sudaryti sandorį, buhalteriui ar kitam
                    asmeniui, turinčiam teisę surašyti ir pasirašyti Verslo
                    subjekto apskaitos dokumentus, per pastaruosius 5 metus
                    nebuvo priimtas ir įsiteisėjęs apkaltinamasis teismo
                    nuosprendis ir šis asmuo neturi neišnykusio ar nepanaikinto
                    teistumo dėl dalyvavimo nusikalstamame susivienijime, jo
                    organizavimo ar vadovavimo jam; kyšininkavimo, prekybos
                    poveikiu, papirkimo; sukčiavimo, turto pasisavinimo, turto
                    iššvaistymo, apgaulingo pareiškimo apie juridinio asmens
                    veiklą, kredito, paskolos ar tikslinės paramos panaudojimą
                    ne pagal paskirtį ar nustatytą tvarką, kreditinį sukčiavimą,
                    neteisingų duomenų apie pajamas, pelną ar turtą pateikimą,
                    deklaracijos, ataskaitos ar kito dokumento nepateikimą,
                    apgaulingos apskaitos tvarkymą ar piktnaudžiavimą, kai
                    šiomis nusikalstamomis veikomis kėsinamasi į Europos
                    Sąjungos finansinius interesus, kaip apibrėžta Konvencijos
                    dėl Europos Bendrijų finansinių interesų apsaugos, parengtos
                    vadovaujantis Europos Sąjungos sutarties K.3 straipsniu, 1
                    straipsnio 1 dalyje; taip pat dėl nusikalstamo bankroto;
                    teroristinio ir su teroristine veikla susijusio nusikaltimo;
                    nusikalstamu būdu gauto turto legalizavimo; prekybos
                    žmonėmis, vaiko pirkimo arba pardavimo (toliau – ekonominės
                    veiklos pažeidimai). Verslo subjektui nepateikus šių
                    dokumentų ir (ar) Paskolos teikėjui nustačius Verslo
                    subjekto ekonominės veiklos pažeidimų, Paskolos teikėjas
                    turi teisę nesuteikti Paskolos, o šiai aplinkybei paaiškėjus
                    po Paskolos išmokėjimo Paskolos gavėjas privalo grąžinti
                    visą neteisėtai gautą valstybės pagalbą su palūkanomis, tai
                    yra Paskolą su Paskolos sutartyje nustatytomis palūkanomis
                    ir palūkanomis, apskaičiuotomis nuo valstybės pagalbos
                    suteikimo dienos iki jos išieškojimo dienos, kaip nustatyta
                    Reglamente (ES) 2015/1589;
                    <br />
                    13. kartu su paraiška dėl Paskolos suteikimo, Paskolos
                    gavėjas yra pateikęs:
                    <br />
                    a) SVV subjekto statuso deklaraciją; <br />
                    b) Kliento pažinimo anketą;
                    <br />
                    c) Vienos įmonės deklaraciją**** taikoma SVV subjektams,
                    vykdantiems Socialinio poveikio projektus, SVV subjektams,
                    kurių dalyvis (akcininkas, narys, dalininkas) ar savininkas
                    yra leidimą gyventi Lietuvoje turintis Ukrainos pilietis
                    arba Ukrainos juridinis asmuo, ir Ukrainos pilietybę ir
                    leidimą gyventi Lietuvoje turintiems verslininkams,
                    veikiantiems ne ilgiau kaip penkerius metus, kuriems yra
                    taikoma palūkanų nuolaida ir kurie yra vidutinė įmonė);
                    <br />
                    **** Ši tinkamumo sąlyga taikoma, jei pagalba Paskolos
                    gavėjui taikoma pagal <i>De minimis</i> reglamentą.
                    <br />
                    d) Privataus finansuotojo ketinimo dalyvauti Projekte raštą
                    (jei taikoma);
                    <br />
                    **** Ši tinkamumo sąlyga taikoma, jei pagalba Paskolos
                    gavėjui taikoma pagal <i>De minimis</i> reglamentą.
                    <br />
                    e) Verslo subjektą apibūdinančius dokumentus: registracijos
                    dokumentus, nuostatus, vadovo asmens tapatybės dokumento
                    kopijas, informaciją apie akcininkus (savininkus, naudos
                    gavėjus), aktualią Verslo subjekto akcininkų struktūrą ir
                    laisvos formos dokumentą, kuriame būtų nurodyti Verslo
                    subjekto ir su juo susijusių įmonių ryšiai (netaikoma
                    verslininkams);
                    <br />
                    f) verslininko individualios veiklos registracijos
                    dokumentus, asmens tapatybės dokumento kopiją ir laisvos
                    formos dokumentą, kuriame būtų nurodyti verslininko ir su
                    juo susijusių įmonių ryšiai (taikoma verslininkams); <br />
                    g) verslo planą (Projekto aprašymą), parengtą laikantis VšĮ
                    „Inovacijų agentūra“ rekomenduojamų reikalavimų verslo
                    planui, kuriame, be kita ko, būtų aprašyta vykdoma ir
                    planuojama vykdyti veikla, esama ir prognozuojama finansinė
                    būklė, siūlymas dėl Paskolos įmokų mokėjimo grafiko
                    išdėstymo ir užtikrinimo priemonių, o investicijų
                    finansavimo atveju taip pat turi būti pateiktas investicijų
                    aprašymas ir investicijas pagrindžiantys dokumentai, jei
                    tokie investicijas pagrindžiantys dokumentai yra, pvz.:
                    statybos, remonto ar rekonstrukcijos darbų sąmata, sutartys,
                    užsakymai, ketinimų protokolai, perkamo ir (ar) įkeičiamo
                    turto vertinimas, planuojamo įsigyti turto sąrašas su
                    kainomis, komerciniai pasiūlymai, leidimai ir licencijos,
                    reikalingos veiklai vykdyti ar projektui įgyvendinti ir pan.
                    SVV subjektas, vykdantis Socialinio poveikio projektą ir
                    veikia ne ilgiau kaip penkerius metus ar Paskolos gavėjas,
                    kuris finansuojamu Projektu siekia socialinio poveikio ir
                    įgyvendinus Projektą atitiks SVV subjekto, vykdančio
                    Socialinio poveikio projektą sąvoką, teikdamas paraišką
                    Verslo subjektas Paskolos teikėjui turi pateikti verslo
                    planą, kuriame būtų nustatyti aiškūs, pamatuojami
                    pagrindiniai socialinio teigiamo poveikio rodikliai, kurių
                    bus siekiama įgyvendinant Projektą (teigiamas socialinis
                    poveikis – prognozuojamas ir išmatuojamas poveikis, kuris
                    teigiama linkme keičia socialinę padėtį visuomenėje ar jos
                    grupėje). Taip pat turi būti pateiktos finansinės veiklos
                    prognozės (skaičiavimai), atspindintys Verslo subjekto
                    galimybę laiku mokėti įmokas pagal visus esamus ir būsimus
                    Verslo subjekto finansinius įsipareigojimus visam Paskolos
                    terminui;
                    <br />
                    h) einamųjų finansinių metų tarpinį paskutinio ketvirčio
                    finansinių ataskaitų rinkinį, jei nuo einamojo ketvirčio
                    pradžios yra praėję 40 kalendorinių dienų. Tuo atveju jei
                    nuo einamojo ketvirčio pradžios nėra praėję 40 dienų, turi
                    būti teikiama ketvirčio, ėjusio prieš paskutinį ketvirtį,
                    finansinių ataskaitų rinkinys arba lygiaverčiai dokumentai,
                    kai Verslininkai arba įmonės veikia trumpiau nei vienerius
                    metus;
                    <br />
                    i) Verslo subjekto veiklos (finansinių) duomenų
                    detalizacijos formą (skelbiama viešai interneto svetainėje
                    (www.invega.lt));
                    <br />
                    j) Verslo subjekto atitikties žemės ūkio inovacinei veiklai
                    aprašymas (Aprašymo priedas Nr. 1 (taikoma tik Paskolos
                    gavėjams, kurie veikia žemės ūkio sektoriuje ir vykdo arba
                    ketina vykdyti inovacinę veiklą);
                    <br />
                    k) Verslo subjekto dalyvio ir (arba) valdymo organo
                    (-ų)kurio (-ių) kompetencija pagal juridinio asmens steigimo
                    (veiklos) dokumentus (įstatus, nuostatus ir kt.) ar
                    galiojančius teisės aktus yra priimti sprendimą dėl Paskolos
                    gavimo, turto įkeitimo, atitinkamą (-us) sprendimą (-us) ir
                    sprendimą dėl asmenų, įgaliotų pasirašyti atitinkamas
                    sutartis ar sandorius, paskyrimo, išskyrus atvejus, kai
                    tokia kompetencija priklauso vienasmeniam valdymo organui
                    (vadovui) (netaikoma verslininkams). Tam tikrais atvejais,
                    Paskolos teikėjo sprendimu, šiame papunktyje nurodytas
                    dokumentas gali būti pateiktas iki Paskolos sutarties
                    pasirašymo;
                    <br />
                    l) Verslo subjekto ar jo dalyvio (akcininko, nario,
                    dalininko) ar savininko leidimo gyventi Lietuvoje kopiją ir
                    (ar) Ukrainos juridinio asmens steigimo dokumentų kopijas
                    (taikoma SVV subjektams, kurių dalyvis (akcininkas, narys,
                    dalininkas) ar savininkas yra leidimą gyventi Lietuvoje
                    turintis Ukrainos pilietis arba Ukrainos juridinis asmuo, ir
                    Ukrainos pilietybę ir leidimą gyventi Lietuvoje turintiems
                    verslininkams);
                    <br />
                    m) Verslo subjektas teikdamas paraišką ne pirmą kartą (t.
                    y., kai ankstesnė (-s) paraiška (-os) buvo atmesta (-os),
                    turi pateikti motyvuotą paaiškinimą dėl įvykusių pokyčių
                    Verslo subjekto veikloje ir su paraiška teikiamuose
                    dokumentuose (duomenyse) bei pateikti paaiškinimą
                    pagrindžiančius dokumentus, lyginant su paskutine teikta
                    Paraiška.
                    <br />
                    <br />
                    <b>
                      <u>
                        Papildomos tinkamumo sąlygos, taikomos tik SVV
                        subjektams, vykdantiems Socialinio poveikio projektus,
                      </u>{' '}
                      SVV subjektams, kurių dalyvis (akcininkas, narys,
                      dalininkas) ar savininkas yra leidimą gyventi Lietuvoje
                      turintis Ukrainos pilietis arba Ukrainos juridinis asmuo,
                      ir Ukrainos pilietybę ir leidimą gyventi Lietuvoje
                      turintiems verslininkams,
                      <u>
                        veikiantiems ne ilgiau kaip penkerius metus, kuriems bus
                        taikoma palūkanų nuolaida:
                      </u>
                    </b>
                    <br />
                    <b>
                      1.Jei Verslo subjektas yra labai maža arba maža įmonė arba
                      verslininkas*****, kuris atitinka Bendrosios išimties
                      reglamento 22 straipsnio 2 dalyje nustatytus reikalavimus,
                      tuomet Paskola teikiama vadovaujantis Bendrosios išimties
                      reglamento nuostatomis:
                      <br />
                      ***** Labai maža įmonė arba maža įmonė nėra įtraukta į
                      vertybinių popierių biržą (nėra listinguojama
                      reguliuojamoje rinkoje) veikianti ne ilgiau kaip penkerius
                      metus po jos registracijos, kuri dar nėra paskirsčiusi
                      pelno, nebuvo sukurta per susijungimą ir nėra perėmusi
                      kitos įmonės veiklos.
                      <br />
                    </b>
                    <br />
                    a) Verslo subjektas ir Įmonių grupė (jei Verslo subjektas
                    priklauso Įmonių grupei) paraiškos pateikimo metu nėra
                    sunkumus patiriantis, kaip nustatyta Bendrosios išimties
                    reglamento 2 straipsnio 18 punkte (atitiktis šiam
                    reikalavimui yra vertinama ir tuomet, kai Paskolos gavėjui
                    yra ilginama suteiktos Paskolos trukmė);
                    <br />
                    b) Verslo subjektui gali būti suteikta atitinkamo dydžio
                    valstybės pagalba pagal Bendrosios išimties reglamento
                    nuostatas (atitiktis šiam reikalavimui yra vertinama ir
                    tuomet, kai Paskolos gavėjui yra ilginama suteiktos Paskolos
                    trukmė), arba
                    <br />
                    <br />
                    <b>
                      Jei Verslo subjektas yra vidutinė įmonė, tuomet Paskola
                      gali būti teikiama vadovaujantis <i>De minimis</i>{' '}
                      reglamento nuostatomis:
                      <br />
                    </b>
                    a) Verslo subjektui paraiškos pateikimo metu nėra taikoma
                    kolektyvinė nemokumo procedūra ir ar jis neatitinka Lietuvos
                    Respublikos juridinių asmenų nemokumo įstatymo kriterijų,
                    pagal kuriuos kreditorių prašymu jam būtų taikoma
                    kolektyvinė nemokumo procedūra, kaip nustatyta{' '}
                    <i>De minimis</i> reglamento 4 straipsnio 3 dalies a punkte
                    (atitiktis šiam reikalavimui yra vertinama ir tuomet, kai
                    Paskolos gavėjui yra ilginama suteiktos Paskolos trukmė);
                    <br />
                    b) Verslo subjektui gali būti suteikta atitinkamo dydžio{' '}
                    <i>de minimis</i> pagalba pagal <i>De minimis</i> reglamento
                    nuostatas (atitiktis šiam reikalavimui yra vertinama ir
                    tuomet, kai Paskolos gavėjui yra ilginama suteiktos Paskolos
                    trukmė).
                  </td>
                </tr>
                <tr>
                  <td>Paskolos gavėjo įsipareigojimai</td>
                  <td>
                    Paskolos gavėjas, pasirašydamas Paskolos sutartį,
                    patvirtina, kad Paskolos gavėjui ir (ar) jo akcininkams,
                    pajininkams ar dalininkams, turintiems ne mažiau kaip 25
                    procentus Paskolos gavėjo akcijų, pajų ar kitokių dalyvavimą
                    įmonės kapitale žyminčių kapitalo dalių arba 25 procentus ir
                    daugiau įmonės dalyvių balsų, Paskolos gavėjo vadovui, kitam
                    valdymo ar priežiūros organo nariui ar kitam asmeniui,
                    turinčiam teisę atstovauti Paskolos gavėjui ar jį
                    kontroliuoti, jo vardu priimti sprendimą, sudaryti sandorį,
                    buhalteriui ar kitam asmeniui, turinčiam teisę surašyti ir
                    pasirašyti Paskolos gavėjo apskaitos dokumentus, per
                    pastaruosius 5 metus nebuvo priimtas ir įsiteisėjęs
                    apkaltinamasis teismo nuosprendis ir šis asmuo neturi
                    neišnykusio ar nepanaikinto teistumo už nusikalstamos
                    ekonominės veiklos pažeidimus, išvardytus Paskolos
                    sutartyje.
                    <br />
                    <br />
                    Paskolos sutartyje bus nustatyta, kad Paskolos gavėjas bus
                    įpareigotas sudaryti galimybę Europos Komisijos, Europos
                    Audito Rūmų, Finansų ministerijos, Ekonomikos ir inovacijų
                    ministerijos, Lietuvos Respublikos valstybės kontrolės,
                    Finansinių nusikaltimų tyrimo tarnybos prie Vidaus reikalų
                    ministerijos, Lietuvos Respublikos specialiųjų tyrimų
                    tarnybos, Konkurencijos tarybos įgaliotiems atstovams,
                    Paskolos teikėjo, kitiems ES institucijų ir ES įstaigų bei
                    tinkamai įgaliotų nacionalinių subjektų, turintiems teisę
                    tikrinti, kaip panaudojamos Priemonės lėšos atstovams
                    atlikti patikrinimą, įvertinant Paskolos gavėjo
                    įsipareigojimus pagal Paskolos sutartį vykdymą.
                    <br />
                    <br />
                    Paskolos gavėjas privalės visą informaciją ir dokumentus
                    (originalus arba tinkamai patvirtintas kopijas), susijusius
                    su gautos Paskolos lėšų panaudojimu, saugoti ne trumpesniu
                    nei 10 metų po Paskolos sutarties pasibaigimo laikotarpiu ir
                    ne trumpesniu kaip yra nustatyta Finansinių priemonių
                    įgyvendinimo taisyklių 118 punkte nustatytu laikotarpiu.
                    Dokumentų saugojimo terminai gali būti pratęsti Finansinių
                    priemonių įgyvendinimo taisyklių 119 punkte nurodytais
                    atvejais. Paskolos gavėjas visus dokumentus, reikalingus
                    atlikti auditui ar patikroms, teiks neatlygintinai.
                    <br />
                    <br />
                    Paskolos gavėjas įsipareigos Paskolą naudoti tik pagal
                    paskirtį, nustatytą Paskolos sutartyje, ir nenaudos Paskolos
                    lėšų Netinkamoms išlaidoms apmokėti.
                    <br />
                    <br />
                    Jei Paskolos gavėjas yra fizinis asmuo, dirbantis pagal
                    verslo liudijimą arba užsiimantis individualia veikla, viso
                    Projekto įgyvendinimo metu privalo turėti galiojantį verslo
                    liudijimą arba individualios veiklos pažymą.
                    <br />
                    <br />
                    Paskolos gavėjas neprieštaraus, kad duomenis apie iš
                    Paskolos lėšų įgyvendinamą ar įgyvendintą Projektą Paskolos
                    teikėjas naudos viešinimo tikslais. Informacija apie
                    Paskolos sutartį bus viešinama teisės aktuose nustatyta
                    apimtimi.
                    <br />
                    <br />
                    Paskolos gavėjas Paskolos sutartimi įpareigojamas visu
                    Paskolos laikotarpiu be išankstinio rašytinio Paskolos
                    teikėjo sutikimo nemokėti dividendų ar tantjemų, nesupirkti
                    Paskolos gavėjo kapitalo dalių (akcijų) ir (ar) neišmokėti
                    pelno dalies Paskolos gavėjo dalyviui (-iams) kitokiais
                    būdais.
                    <br />
                    <br />
                    Be kitų nuostatų, Paskolos sutartyje nustatomos šios
                    sąlygos: Paskolos sutarties šalių teisės ir pareigos,
                    Paskolos gavėjo įsipareigojimai, Paskolos sutarties sąlygų
                    keitimo ir nutraukimo galimi atvejai ir tvarka, dokumentų
                    saugojimo tvarka bei kitos sąlygos
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    style={{ textAlign: 'center', background: '#d6d8df' }}
                  >
                    PASKOLOS TINKAMUMO SĄLYGOS
                  </td>
                </tr>
                <tr>
                  <td>Paskolų teikimo sąlygos</td>
                  <td>
                    Paskola skiriama Paskolos gavėjo Projekto finansavimui,
                    išskyrus Netinkamoms išlaidoms, finansuoti, jeigu toks
                    finansavimas yra susijęs su Paskolos gavėjo naujos veiklos
                    pradėjimu arba jau egzistuojančios veiklos stiprinimu ar
                    plėtra.
                    <br />
                    <br />
                    Paskola, skirta investicijoms – tai tokia Paskola, kai
                    Paskolos dalis, skirta investicijoms finansuoti, sudaro ne
                    mažiau kaip 71 proc. visos Paskolos sumos, o likusi Paskolos
                    dalis skirta apyvartinio kapitalo trūkumui papildyti. Kai
                    Paskolos dalis, skirta investicijoms finansuoti, sudaro
                    mažiau kaip 71 proc. visos Paskolos sumos, laikoma, kad visa
                    Paskola yra skirta apyvartinio kapitalo finansavimui.
                    <br />
                    <br />
                    Apyvartinio kapitalo finansavimo atveju Paskolos suma Verslo
                    subjektui bus nustatoma įvertinus finansavimo poreikį pagal
                    Verslo subjekto pateiktą informaciją ir pagrindžiančius
                    dokumentus, neviršijant nustatyto didžiausio Finansavimo
                    dydžio vienam Paskolos gavėjui.
                    <br />
                    <br />
                    Finansuojamas Projektas paraiškos vertinimo metu INVEGOJE
                    turi būti pripažintas ekonomiškai pagrįstu ir finansiškai
                    atsiperkančiu, kurio atitiktis vertinama pagal finansines
                    ataskaitas, verslo plane ir Verslo subjekto veiklos
                    (finansinių) duomenų detalizacijos užpildytoje formoje
                    nurodytą informaciją.
                    <br />
                    <br />
                    Paskola Paskolos gavėjo investicijoms finansuoti gali būti
                    teikiama ne ilgesniam kaip 120 mėnesių laikotarpiui
                    (įskaitant ir atidėtą Paskolos grąžinimo terminą), o
                    apyvartiniam kapitalui finansuoti ne ilgesniam kaip 36
                    mėnesių laikotarpiui (įskaitant ir atidėtą Paskolos
                    grąžinimo terminą).
                    <br />
                    <br />
                    Paskolos lėšų panaudojimo ir išmokėjimo terminas Paskolos
                    gavėjui yra 12 mėn. nuo Paskolos sutarties pasirašymo
                    dienos. Esant pagrįstoms aplinkybėms Paskolos lėšų panaudojimo ir išmokėjimo terminas Paskolos teikėjo sprendimu gali būti nustatytas ilgesnis. Kai, nuo Paskolos
                    gavėjo nepriklausančių aplinkybių, Paskolos gavėjas Paskolos
                    lėšų nespėja panaudoti Paskolos sutartyje nustatytą terminą, atskiru Paskolos gavėjo prašymu, Paskolos lėšų panaudojimo ir išmokėjimo terminas, Paskolos teikėjui pritarus, gali būti pratęstas.
                    <br />
                    <br />
                    Investicijų finansavimo atveju už Paskolos lėšas įsigyjamas
                    ilgalaikis materialusis ir (ar) nematerialusis turtas
                    pirminiu įkeitimu yra įkeičiamas Paskolos grąžinimui
                    užtikrinti. Tuo atveju, kai Paskolos teikėjas remiantis
                    Paskolos teikėjo patvirtinta Užtikrinimo priemonių vertinimo
                    metodika įvertina, kad už Paskolos lėšas įsigyjamas
                    ilgalaikis materialusis ir (ar) nematerialusis turtas yra
                    netinkamas Paskolos grąžinimo užtikrinimo būdas dėl jo mažo
                    likvidumo ar teisinių kliūčių jį įkeisti, Paskolos gavėjas
                    Paskolos grąžinimui užtikrinti gali pasiūlyti įkeisti kitą,
                    ne mažesnės nei už Paskolos lėšas įsigyjamo ilgalaikio
                    materialiojo ir (ar) nematerialiojo turto vertės, ilgalaikį
                    materialųjį ir (ar) nematerialųjį turtą. Išimtinais Paskolos
                    teikėjo nustatytais atvejais, kai už Paskolos lėšas
                    įsigyjamas ilgalaikis materialusis ir (ar) nematerialusis
                    turtas yra netinkamas Paskolos grąžinimo užtikrinimo būdas
                    dėl jo mažo likvidumo ar teisinių kliūčių jį įkeisti, o
                    Paskolos gavėjas neturi kito ilgalaikio materialiojo ir (ar)
                    nematerialiojo turto ar pasiūlytas turtas taip pat yra
                    netinkamas Paskolos grąžinimo užtikrinimo būdas dėl jo mažo
                    likvidumo ar teisinių kliūčių jį įkeisti, prievolės įvykdymo
                    užtikrinimas gali būti netaikomas.
                    <br />
                    <br />
                    Visas išlaidas, susijusias su Paskolos užtikrinimo priemonių
                    įforminimu, apmoka Paskolos gavėjas.
                    <br />
                    <br />
                    Paskolos gavėjas moka 0,4 proc. dydžio Paskolos sutarties
                    sudarymo vienkartinį mokestį nuo Paskolos sumos, bet ne
                    mažiau kaip 200 Eur. Paskolos sudarymo mokestis, pagal
                    Paskolos teikėjo išrašytą išankstinę sąskaitą, mokamas prieš
                    Paskolos sutarties pasirašymą.
                    <br />
                    <br />
                    Keičiant Paskolos sutarties sąlygas, kai Paskolos sutarties
                    pakeitimo poreikį inicijuoja Paskolos gavėjas, Paskolos
                    gavėjas prieš Paskolos sutarties pakeitimo pasirašymą moka
                    100 Eur mokestį pagal Paslaugos teikėjo išrašytą išankstinę
                    sąskaitą.
                    <br />
                    <br />
                    Paskolos lėšos išmokamos tiesiogiai Paskolos gavėjo tiekėjui
                    ar Paskolos gavėjui, pagal Paskolos gavėjo pateiktus
                    mokėjimo prašymus. Kartu su mokėjimo prašymu, mokėjimo
                    prašyme nurodytai sumai, Paskolos gavėjas privalo pateikti
                    sąskaitas faktūras ir (ar) kitus pagrindžiančius dokumentus
                    (pvz., sutartys, darbo užmokesčio žiniaraščiai ir pan.).
                    Mokėjimo prašymo teikimo ir Paskolos pagal mokėjimo prašymą
                    išmokėjimo tvarka bus nustatyta Paskolos sutartyje.
                    <br />
                    <br />
                    Paskolos gavėjas Paskolos lėšų išmokėjimo Paskolos gavėjui
                    metu negalės būti įgijęs bankrutuojančios, bankrutavusios,
                    restruktūrizuojamos, likviduojamos įmonės statuso.
                    <br />
                    <br />
                    Įmokų mokėjimo grafikas yra linijinis ir sudaromas išmokėjus
                    visą Paskolos sumą arba pasibaigus Paskolos sutartyje
                    nustatytam išmokėjimo terminui. Atsižvelgiant į Paskolos
                    gavėjo prognozuojamus srautus ar kitas Paskolos vertinimo
                    metu nustatytas aplinkybes, įmokų mokėjimo grafikas gali
                    būti išdėstomas nelygiomis dalimis. Iki įmokų mokėjimo
                    grafiko sudarymo, Paskolos gavėjui kiekvieną mėnesį bus
                    siunčiamos sąskaitos. Įmokos, kurias sudaro Paskolos dalis
                    ir (ar) palūkanų dalis mokamos Paskolos sutartyje nustatytą
                    įmokos dieną. Įmokų mokėjimo grafikas atnaujinamas
                    pasikeitus įmokos dydžiui.
                    <br />
                    <br />
                    Paskolos gavėjas, pateikęs prašymą Paskolos teikėjui, turi
                    teisę Paskolą grąžinti anksčiau Paskolos termino be jokių
                    papildomų mokesčių.
                    <br />
                    <br />
                    Paskolos gavėjui vėluojant vykdyti savo finansinius
                    įsipareigojimus pagal Paskolos sutartį, jis privalo mokėti
                    0,03 proc. dydžio delspinigius už kiekvieną pradelstą mokėti
                    dieną.
                    <br />
                    <br />
                    Už Paskolos panaudojimą pagal Paskolos sutartyje nurodytą
                    paskirtį atsakingas Paskolos gavėjas.
                    <br />
                    <br />
                    Visos Paskolos sumos išreikštos eurais. Visi mokėjimai pagal
                    Paskolos sutartį atliekami eurais.
                  </td>
                </tr>
                <tr>
                  <td>Paskolų palūkanų norma </td>
                  <td>
                    Paskolų gavėjams taikoma iš dalies fiksuota metinė palūkanų
                    norma, nustatoma pagal viešai interneto svetainėje
                    (www.invega.lt) skelbiamą Paskolos teikėjo kainodaros
                    metodiką. Palūkanų normą sudaro palūkanų marža, kuri
                    priklauso nuo Paskolos gavėjo ir jo įgyvendinamo Projekto
                    rizikingumo, Paskolos užtikrinimo priemonių dydžio, Paskolos
                    trukmės, Paskolos grąžinimo grafiko ir kintamos palūkanų
                    dalies – 12 mėnesių EURIBOR. Kintama palūkanų dalis yra
                    fiksuojama Paskolos sutarties parengimo dieną penkerių metų
                    laikotarpiui. Po penkerių metų kintama palūkanų dalis yra
                    perskaičiuojama kas 12 mėnesių.
                    <br />
                    <br />
                    Palūkanos yra skaičiuojamos nuo Paskolos gavėjo gautos ir
                    negrąžintos Paskolos sumos. Laikytina, kad metai turi 360
                    dienų, o mėnuo – 30 dienų. Palūkanos pradedamos skaičiuoti
                    nuo Paskolos sumos išmokėjimo Paskolos gavėjui dienos ir
                    skaičiuojamos iki Paskolos visiško grąžinimo Paskolos
                    teikėjui dienos. Palūkanos Paskolos teikėjui mokamos kas
                    mėnesį.
                  </td>
                </tr>
                <tr>
                  <td>Palūkanų nuolaida</td>
                  <td>
                    Paskolų gavėjams, kurie vykdo Socialinio poveikio projektus
                    ir veikia ne ilgiau kaip penkerius metus (arba jei
                    Socialinio poveikio projektas bus vykdomas gavus
                    finansavimą), Paskolų gavėjams, kurių dalyvis (akcininkas,
                    narys, dalininkas) ar savininkas yra leidimą gyventi
                    Lietuvoje turintis Ukrainos pilietis arba Ukrainos juridinis
                    asmuo, ir Ukrainos pilietybę ir leidimą gyventi Lietuvoje
                    turintiems verslininkams Paskolos palūkanų normai bus
                    taikoma 30 proc. nuolaida.
                  </td>
                </tr>
                <tr>
                  <td>Finansavimo dydis vienam Paskolos gavėjui</td>
                  <td>
                    Investicijų finansavimo atveju – vienos Paskolos suma iki 3
                    000 000 Eur.
                    <br />
                    <br />
                    Apyvartinio kapitalo finansavimo atveju – vienos Paskolos
                    suma iki 200 000 Eur.
                    <br />
                    <br />
                    Vienam Paskolos gavėjui suteikiamų Paskolų skaičius
                    neribojamas.
                    <br />
                    <br />
                    Bet kuriuo metu iš Priemonės lėšų skirto finansavimo
                    negrąžintų Paskolų likučių suma tam pačiam Paskolos gavėjui
                    negali viršyti 3 000 000 Eur.
                    <br />
                    <br />
                    Suteiktos Paskolos suma negali būti didinama.
                    <br />
                    <br />
                    Visais atvejais, valstybės pagalbos suma, negalės viršyti
                    didžiausio galimo valstybės pagalbos dydžio, kaip tai
                    apibrėžta Valstybės pagalbos teikimo ir sumavimo skiltyje
                    (jei taikoma valstybės pagalba).
                  </td>
                </tr>
                <tr>
                  <td>Valstybės pagalbos teikimas ir sumavimas</td>
                  <td>
                    <b>
                      <u>
                        Valstybės pagalbos teikimas, kai Paskola teikiama
                        vadovaujantis Bendrosios išimties reglamento nuostatomis
                      </u>
                    </b>
                    <br />
                    Socialinio poveikio projektus vykdančiai labai mažai įmonei,
                    mažai įmonei ar verslininkui, labai mažai įmonei, mažai
                    įmonei, kurios dalyvis (akcininkas, narys, dalininkas) ar
                    savininkas yra leidimą gyventi Lietuvoje turintis Ukrainos
                    pilietis arba Ukrainos juridinis asmuo ir Ukrainos pilietybę
                    ir leidimą gyventi Lietuvoje turinčiam verslininkui, kuriems
                    pagalba teikiama vadovaujantis Bendrosios išimties
                    reglamentu, suteiktos valstybės pagalbos suma negali viršyti
                    Bendrosios išimties reglamento 22 straipsnio 3 dalyje
                    nustatytos sumos, o ši suma negali būti dirbtinai
                    išskaidyta, kaip Bendrosios išimties reglamento 4 straipsnio
                    2 dalyje.
                    <br />
                    Paskola teikiama vadovaujantis Bendrosios išimties
                    reglamento 22 straipsnio nuostatomis, visa Paskolos suma
                    laikoma valstybės pagalba Paskolos gavėjui. Apskaičiuojant
                    valstybės pagalbos sumą, visi naudojami skaičiai imami prieš
                    bet kokį mokesčių ar kitokios rinkliavos atskaitymą, kaip
                    nustatyta Bendrosios išimties reglamento 7 straipsnio 1
                    dalyje. <br />
                    <br />
                    Valstybės pagalba sumuojama, kaip tai nustatyta Bendrosios
                    išimties reglamento 8 straipsnyje.
                    <br />
                    <br />
                    <b>
                      <u>
                        Valstybės pagalbos teikimas, kai Paskola teikiama
                        vadovaujantis <i>De minimis</i> reglamento nuostatomis
                      </u>
                    </b>
                    <br />
                    Visi tos pačios Socialinio poveikio projektus vykdančios
                    vidutinės įmonės ir vidutinės įmonės, kurių dalyvis
                    (akcininkas, narys, dalininkas) ar savininkas yra leidimą
                    gyventi Lietuvoje turintis Ukrainos pilietis arba Ukrainos
                    juridinis asmuo, kuriems pagalba teikiama vadovaujantis{' '}
                    <i>De minimis</i> reglamentu, kontroliuojami subjektai,
                    atitinkantys bent vienos rūšies tarpusavio santykius,
                    nustatytus <i>De minimis</i> reglamento 2 straipsnio 2
                    dalyje, laikomi viena įmone.
                    <br />
                    <br />
                    Vienai Socialinio poveikio projektus vykdančiai vidutinei
                    įmonei ir vidutinei įmonei, kurios dalyvis (akcininkas,
                    narys, dalininkas) ar savininkas yra leidimą gyventi
                    Lietuvoje turintis Ukrainos pilietis arba Ukrainos juridinis
                    asmuo, kurioms pagalba teikiama vadovaujantis{' '}
                    <i>De minimis</i> reglamentu, suteiktos <i>de minimis</i>{' '}
                    pagalbos suma negali viršyti <i>De minimis</i> reglamento 3
                    straipsnyje nustatytos sumos. Finansavimo dydis atitinkamam
                    Paskolos gavėjui negali viršyti 200 000 Eur (dviejų šimtų
                    tūkstančių eurų) per 3 metų laikotarpį, o atitinkamam
                    Paskolos gavėjui, vykdančiam ar ketinančiam vykdyti krovinių
                    vežimo keliais veiklą samdos pagrindais arba už atlygį,
                    negali viršyti 100 000 Eur (šimto tūkstančių eurų) per 3
                    metų laikotarpį. Jeigu Paskolos gavėjas vykdo krovinių
                    vežimo keliais veiklą samdos pagrindais arba už atlygį ir
                    taip pat kitą veiklą, kuri nėra draudžiama pagal Priemonės
                    schemą, finansavimo dydis atitinkamam Paskolos gavėjui
                    negali viršyti 200 000 Eur (dviejų šimtų tūkstančių eurų)
                    per 3 metų laikotarpį, tačiau iš jų krovinių vežimo keliais
                    veiklai samdos pagrindais arba už atlygį skirtas
                    finansavimas negali viršyti 100 000 Eur (šimto tūkstančių
                    eurų) per 3 metų laikotarpį. <i>De minimis</i> reglamentas
                    taikomas tik tai pagalbai, kurios bendrąjį subsidijos
                    ekvivalentą įmanoma tiksliai ex ante apskaičiuoti
                    neatliekant rizikos vertinimo (skaidri pagalba).
                    <br />
                    <br />
                    Suteikiant Paskolą yra įvertintas skirtumas tarp 2008 m.
                    sausio 19 d. Komisijos komunikate dėl orientacinių ir
                    diskonto normų nustatymo metodo pakeitimo (2008/C 14/02)
                    (toliau – Europos Komisijos komunikatas) nustatytos
                    orientacinės paskolų palūkanų normos su atitinkama marža,
                    kuri priklauso nuo Paskolos gavėjo rizikos reitingo ir
                    įkeičiamo turto dydžio, ir konkrečiai Paskolai taikytos
                    palūkanų normos. Gautas skirtumas laikomas subsidijos
                    ekvivalentu ir laikomas <i>de minimis</i>pagalba Paskolos
                    gavėjui.
                    <br />
                    <br />
                    <i>De minimis</i> pagalbos, suteikiamos Paskolos gavėjui,
                    subsidijos ekvivalentas apskaičiuojamas laikantis{' '}
                    <i>De minimis</i> reglamento 3 straipsnio nuostatų pagal
                    formulę:
                    <br />
                    SE = K x T x (N proc. + R – M proc.), kurioje
                    <br />
                    SE – <i>de minimis</i> pagalbos dydis, išreikštas subsidijos
                    ekvivalentu;
                    <br />
                    K – Paskolos suma eurais;
                    <br />
                    T – Paskolos laikotarpis, kai Paskolos gavėjas naudojasi ar
                    gali naudotis Paskolos lėšomis, išreikštas metais;
                    <br />N – Europos Komisijos kiekvieno mėnesio pirmą
                    kalendorinę dieną interneto svetainėje{' '}
                    <a href="http://ec.europa.eu/competition/state_aid/legislation/reference_rates.html">
                      http://ec.europa.eu/competition/state_aid/legislation/reference_rates.html
                    </a>{' '}
                    skelbiama orientacinė palūkanų norma;
                    <br />
                    R – Europos Komisijos komunikate nustatyta marža;
                    <br />
                    M – Paskolos gavėjui taikoma metinė palūkanų norma,
                    pritaikius Palūkanų nuolaidą.
                    <br />
                    <br />
                    Suskaičiavus Paskolos subsidijos ekvivalentą, įvertinama, ar
                    jis neviršys didžiausio galimo <i>de minimis</i> pagalbos
                    dydžio, nurodyto <i>De minimis</i> reglamento 3 straipsnyje.
                    Jeigu Paskolos subsidijos ekvivalentas viršija didžiausią
                    galimą <i>de minimis</i> pagalbos dydį, atitinkamai
                    keičiamos Paskolos suteikimo sąlygos, jeigu su tuo sutinka
                    Paskolos gavėjas. Priešingu atveju paraiška toliau
                    nevertinama.
                    <br />
                    <br /> <i>De minimis</i> pagalba sumuojama, kaip tai
                    nustatyta <i>De minimis</i> reglamento 5 straipsnyje.
                    <br />
                    <br />
                    Paskola gali būti derinama su negrąžinamosiomis subsidijomis
                    ir kitomis finansinėmis priemonėmis, tačiau kartu sudėjus
                    visas finansavimo formas, konkrečioms Projekto išlaidoms
                    finansuoti negali būti skirta daugiau nei 100 (vienas
                    šimtas) proc. šių išlaidų dydžio sumos, kaip tai nurodyta
                    Finansinių priemonių įgyvendinimo taisyklių 50 punkte
                  </td>
                </tr>
                <tr>
                  <td>
                    Nefinansuotinos išlaidos (toliau – Netinkamos išlaidos)
                  </td>
                  <td>
                    Paskolos lėšos negali būti naudojamos:
                    <br />
                    1. Paskolos gavėjo išlaidoms, kurios nesusijusios su
                    Paskolos gavėjo Projektu;
                    <br />
                    2. Paskolos gavėjo ar kito ekonominę veiklą vykdančio
                    subjekto esamiems finansiniams įsipareigojimams finansuoti
                    ar jam restruktūrizuoti;
                    <br />
                    3. dividendams išsimokėti, kapitalui mažinti išmokant lėšas
                    Paskolos gavėjo dalyviams, supirkti savas akcijas ar atlikti
                    kitokius mokėjimus iš kapitalo Paskolos gavėjo dalyviams,
                    taip pat negali būti skirtos grąžinti arba suteikti paskolas
                    Paskolos gavėjo dalyviams;
                    <br />
                    4. išlaidoms, kurioms skiriama negrąžinamoji subsidija,
                    apmokėti, kaip nurodyta Finansinių priemonių įgyvendinimo
                    taisyklių 51 punkte;
                    <br />
                    5. Paskoloms, kurių suteikimo metu, investicijos yra
                    fiziškai užbaigtos, arba visiškai įgyvendintos, teikti, kaip
                    nurodyta Finansinių priemonių išlaidų tinkamumo finansuoti
                    rekomendacijose;
                    <br />
                    6. nepiniginiam įnašui finansuoti;
                    <br />
                    7. žemei įsigyti, kai skiriama daugiau kaip 10 procentų
                    Paskolos lėšų sumos, kaip nurodyta Deleguotojo reglamento 4
                    straipsnio 1 dalyje; gyvenamosios paskirties nekilnojamajam
                    turtui įsigyti ir statyti ir (ar) investicijoms į
                    gyvenamosios paskirties nekilnojamojo turto būklės
                    pagerinimą ar nekilnojamojo turto plėtrą, turint tikslą jį
                    perparduoti, nuomoti ar perleisti naudotis kitiems asmenims;
                    <br />
                    8. gyvenamosios paskirties nekilnojamajam turtui įsigyti ir
                    statyti ir (ar) investicijoms į gyvenamosios paskirties
                    nekilnojamojo turto būklės pagerinimą ar bet kokios
                    paskirties nekilnojamojo turto plėtrą, turint tikslą jį
                    perparduoti, nuomoti ar perleisti naudotis kitiems asmenims,
                    o ne naudoti savo veikloje. Nekilnojamojo turto naudojimu
                    savo veikloje laikoma, jei įsigyjamas, statomas, nuomojamas
                    ar būklei gerinti skirtas turtas bus naudojamas Paskolos
                    gavėjo ekonominei veiklai vykdyti;
                    <br />
                    9. Reglamento (ES) Nr. 1301/2013 3 straipsnio 3 dalyje
                    nurodytais atvejais:
                    <br />
                    a) atominių elektrinių eksploatacijos nutraukimui ar
                    statyboms;
                    <br />
                    b) investicijoms, kuriomis siekiama sumažinti šiltnamio
                    efektą sukeliančių dujų kiekį, išmetamą vykdant veiklą,
                    išvardintą Direktyvos 2003/87/EB I priede;
                    <br />
                    c) tabako ir tabako gaminių gamybai, perdirbimui ir
                    prekybai;
                    <br />
                    d) investicijoms į oro uostų infrastruktūrą, nebent jos būtų
                    susijusios su aplinkos apsauga arba kartu būtų vykdomos
                    investicijos, kurių reikia siekiant sušvelninti ar sumažinti
                    neigiamą oro uostų infrastruktūros poveikį aplinkai.
                    <br />
                    10. krovininėms transporto priemonėms įsigyti toms įmonėms,
                    kurios vykdo krovinių vežimo keliais veiklą (taikoma, jei
                    valstybės pagalba yra teikiama pagal <i>De minimis</i>{' '}
                    reglamento nuostatas);
                    <br />
                    11. <i>De minimis</i> reglamento 1 straipsnio 1 ir 2 dalyse
                    išvardytais atvejais (taikoma, jei valstybės pagalba yra
                    teikiama pagal <i>De minimis</i> reglamento nuostatas);
                    <br />
                    12. Bendrosios išimties reglamento 1 straipsnio 2-5 dalyse
                    išvardytais atvejais (taikoma, jei valstybės pagalba yra
                    teikiama pagal Bendrosios išimties reglamento nuostatas)
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>Kitos sąlygos</td>
                  <td>
                    Paskolos sutarties vykdymo laikotarpiu pasikeitus
                    taikytiniems teisės aktams, Paskolos sutarties šalims bus
                    taikomos aktualių galiojančių teisės aktų nuostatos.
                    <br />
                    <br />
                    Jei po Paskolos suteikimo nustatoma, kad Paskolos gavėjas
                    pateikė neteisingą informaciją, ir (ar) dokumentus ir (ar)
                    nuslėpė informaciją, turinčią reikšmės Paskolos suteikimui,
                    Paskolos gavėjas privalo grąžinti visą neteisėtai gautą
                    valstybės pagalbą su palūkanomis, tai yra Paskolą su
                    Paskolos sutartyje nustatytomis palūkanomis ir palūkanomis,
                    apskaičiuotomis nuo valstybės pagalbos suteikimo dienos iki
                    jos išieškojimo dienos, kaip nustatyta 2015 m. liepos 13 d.
                    Tarybos reglamente (ES) Nr. 2015/1589.
                    <br />
                    <br />
                    Paskolos teikėjas, esant poreikiui ir (ar) kilus įtarimui,
                    bet kuriuo paraiškos vertinimo metu ir (ar) po Paskolos
                    išmokėjimo, turi teisę reikalauti, o Paskolos gavėjas turi
                    pareigą pateikti dokumentus, patvirtinančius, kad Paskolos
                    gavėjui ir (ar) Paskolos gavėjo akcininkui, turinčiam ne
                    mažiau nei 25 proc. Paskolos gavėjo akcijų, Paskolos gavėjo
                    vadovui, kitam valdymo ar priežiūros organo nariui ar kitam
                    asmeniui, turinčiam (turintiems) teisę atstovauti Paskolos
                    gavėją ar jį kontroliuoti, jo vardu priimti sprendimą,
                    sudaryti sandorį, ar buhalteriui (buhalteriams) ar kitam
                    (kitiems) asmeniui (asmenims), turinčiam (turintiems) teisę
                    surašyti ir pasirašyti Paskolos gavėjo apskaitos dokumentus,
                    per pastaruosius 5 metus nebuvo priimtas ir įsiteisėjęs
                    apkaltinamasis teismo nuosprendis ir šis asmuo neturi
                    neišnykusio ar nepanaikinto teistumo už nusikalstamos
                    ekonominės veiklos pažeidimus, išvardytus Paskolos sutartyje
                    (toliau – ekonominės veiklos pažeidimai). Paskolos gavėjui
                    nepateikus šių dokumentų ir (ar) Paskolos teikėjui nustačius
                    Paskolos gavėjo ekonominės veiklos pažeidimus, Paskolos
                    teikėjas turi teisę nesuteikti Paskolos, o šiai aplinkybei
                    paaiškėjus po Paskolos išmokėjimo - Paskolos gavėjas privalo
                    grąžinti visą neteisėtai gautą valstybės pagalbą su
                    palūkanomis, tai yra Paskolą su Paskolos sutartyje
                    nustatytomis palūkanomis ir palūkanomis, apskaičiuotomis nuo
                    valstybės pagalbos suteikimo dienos iki jos išieškojimo
                    dienos, kaip nustatyta 2015 m. liepos 13 d. Tarybos
                    reglamente (ES) 2015/1589.
                    <br />
                    <br />
                    Kilus ginčams, Paskolos teikėjo priimti sprendimai per vieną
                    mėnesį nuo įteikimo dienos gali būti skundžiami Lietuvos
                    administracinių ginčų komisijai ar Vilniaus apygardos
                    administraciniam teismui Lietuvos Respublikos
                    administracinių bylų teisenos įstatymo nustatyta tvarka
                  </td>
                </tr>
                <tr>
                  <td>Probleminės Paskolos administravimas</td>
                  <td>
                    Paskola laikoma Problemine, kai Paskolos gavėjas nesugeba
                    vykdyti finansinių įsipareigojimų pagal Paskolos sutartį,
                    t.y.:
                    <br />
                    1. Paskolos gavėjui iškeliama nemokumo (restruktūrizavimo ar
                    bankroto) byla;
                    <br />
                    2. Paskolos gavėjas ne trumpiau kaip 90 (devyniasdešimt)
                    kalendorinių dienų nesumoka Įmokų pagal Įmokų mokėjimo
                    grafiką (t. y. negrąžina Paskolos dalies ir (ar) nesumoka
                    palūkanų).
                    <br />
                    <br />
                    Jei Paskola tampa Problemine, Paskolos teikėjas,
                    vadovaudamasi savo vidinėmis procedūromis, turi teisę
                    Paskolos sutartyje nustatytais atvejais ir tvarka,
                    vienašališkai, nesikreipdama į teismą, nutraukti Paskolos
                    sutartį ir pareikalauti nedelsiant grąžinti Paskolą (jos
                    dalį) kartu su mokėtinomis Palūkanomis ir kitomis pagal
                    Paskolos sutartį mokėtinomis sumomis.
                    <br />
                    <br />
                    Paskolai tapus Problemine, Paskolos gavėjas turi teisę
                    kreiptis į Paskolos teikėją dėl Paskolos restruktūrizavimo
                    neviršijant maksimalios Paskolos trukmės, numatytos
                    Aprašyme, o Paskolos teikėjas skolininko įsipareigojimų
                    vykdymo atžvilgiu, vadovaudamasi savo vidinėmis
                    procedūromis, turi teisę prašyti pateikti papildomas
                    prievolės užtikrinimo priemones už Įmokų mokėjimo grafiko
                    pakeitimą ir skolos dengimo grafiko sudarymą
                  </td>
                </tr>
                <tr>
                  <td>Pažeidimas</td>
                  <td>
                    Pažeidimas, suprantamas taip, kaip jis apibrėžtas Reglamento
                    (ES) Nr. 1303/2013 2 straipsnyje.
                    <br />
                    <br />
                    Nustačius Pažeidimą, Paskolos teikėjas privalo susigrąžinti
                    Paskolą kartu su palūkanomis, kaip nurodyta Deleguotojo
                    reglamento 6 straipsnio 3 dalyje
                  </td>
                </tr>
                <tr>
                  <td>Viešinimas</td>
                  <td>
                    Paskolos teikėjas informaciją apie Priemonės sąlygas skelbs
                    savo interneto svetainėje, taip pat savo iniciatyva galės
                    taikyti ir kitas Priemonės viešinimo priemones. Paskolų
                    gavėjų sąrašai bus skelbiami Paskolos teikėjo interneto
                    svetainėje.
                    <br />
                    <br />
                    Paskolos gavėjas atlikdamas bet kokius viešinimo veiksmus
                    privalo nurodyti, kad finansavimas Projektui yra skiriamas
                    iš Verslo finansavimo fondo lėšų
                  </td>
                </tr>
                <tr>
                  <td>Informacijos ir dokumentų saugojimas</td>
                  <td>
                    Paskolos teikėjas, vadovaudamasis Bendrosios išimties
                    reglamento 12 straipsnio,
                    <i>De minimis</i> reglamento 6 straipsnio nuostatomis ir
                    Finansinių priemonių įgyvendinimo taisyklių XIX skyriuje
                    nustatytais reikalavimais, užtikrina su Priemonės
                    įgyvendinimu ir Paskolų suteikimu susijusių dokumentų
                    saugojimą.
                    <br />
                    <br />
                    Paskolos gavėjas privalo visą informaciją ir dokumentus,
                    susijusius su gautos Paskolos lėšų panaudojimu, saugoti ne
                    trumpiau kaip 10 metų po Paskolos sutarties galiojimo
                    pabaigos ir ne trumpiau nei nustatyta Finansinių priemonių
                    įgyvendinimo taisyklių 118 punkte. Dokumentų saugojimo
                    terminai gali būti pratęsti Finansinių priemonių
                    įgyvendinimo taisyklių 119 punkte nurodytais atvejais.
                    <br />
                    <br />
                    Asmens duomenų valdytojas - Paskolos teikėjas, juridinio
                    asmens kodas 110084026, buveinės adresas – Konstitucijos pr.
                    7, 09308 Vilnius, tel. (8 5) 210 7510, el. paštas
                    info@invega.lt, duomenų apsaugos pareigūno el. paštas
                    duomenuapsauga@invega.lt, tvarko su Priemonės įgyvendinimu
                    susijusius asmens duomenis:
                    <br />
                    1. kai Paskolos gavėjas verslininkas, tvarkomi šie
                    paraiškoje ir jos prieduose pateikti asmens duomenys:
                    Paskolos gavėjo vardas, pavardė, asmens kodas, gyvenamosios
                    vietos adresas, elektroninio pašto adresas, telefono ryšio
                    numeris, pilietybė, informacija apie vykdomą pagrindinę
                    ekonominę veiklą, galiojančio verslo liudijimo ar
                    individualios veiklos pažymos išdavimo data ir numeris,
                    faktinės veiklos vykdymo adresas, atsiskaitomosios sąskaitos
                    numeris, informacija apie asmens gaunamas pajamas paraiškos
                    pateikimo metu bei paskutinius trejus kalendorinius metus
                    iki paraiškos pateikimo dienos, paraiškos pateikimo dieną
                    turimus finansinius įsipareigojimus, turimą registruotą
                    kilnojamąjį ir nekilnojamąjį turtą, kreditingumo istoriją,
                    valdomų įmonių pavadinimai, kapitalo ar balsavimo teisių
                    dalis, sąsajos pagal turimus sutartinius įsipareigojimus su
                    kitais juridiniais asmenimis.
                    <br />
                    2. kai Paskolos gavėjas įmonė, tvarkomi šie paraiškoje ir
                    jos prieduose pateikti asmens duomenys: Paskolos gavėjo
                    kontaktinio asmens finansavimo klausimais vardas, pavardė,
                    elektroninio pašto adresas, telefono ryšio numeris, Paskolos
                    gavėjo vadovo ir dalyvių vardas, pavardė, asmens kodas,
                    pilietybė, elektroninio pašto adresas, telefono ryšio
                    numeris, Paskolos gavėjo dalyvio (-ių) valdomų įmonių
                    pavadinimai, kapitalo ar balsavimo teisių dalis, sąsajos
                    pagal turimus sutartinius įsipareigojimus su kitais
                    juridiniais asmenimis, informacija apie Paskolos gavėjo
                    vadovų ir dalyvių įsiteisėjusius apkaltinamuosius
                    nuosprendžius.
                    <br />
                    3. tais atvejais, kai Paskolos grąžinimas užtikrinamas
                    fizinio asmens suteikiama užtikrinimo priemone, tvarkomi šie
                    fizinio asmens duomenys: vardas, pavardė, asmens kodas,
                    informacija apie asmens gaunamas pajamas paraiškos pateikimo
                    metu bei paskutinius trejus kalendorinius metus iki
                    paraiškos pateikimo dienos, paraiškos pateikimo dieną
                    turimus finansinius įsipareigojimus ir turimą registruotą
                    kilnojamąjį ir nekilnojamąjį turtą. <br />
                    4. tais atvejais, kai privatus finansuotojas yra fizinis
                    asmuo, tvarkomi šie fizinio asmens duomenys: vardas,
                    pavardė, asmens kodas, elektroninio pašto adresas, telefono
                    ryšio numeris.
                    <br />
                    <br />
                    Paskolos teikėjas asmens duomenis tvarko vykdydamas teisinę
                    prievolę (2016 m. balandžio 27 d. Europos Parlamento ir
                    Tarybos reglamento (ES) 2016/679 dėl fizinių asmenų apsaugos
                    tvarkant asmens duomenis ir dėl laisvo tokių duomenų
                    judėjimo ir kuriuo panaikinama Direktyva 95/46/EB (Bendrasis
                    duomenų apsaugos reglamentas) 6 straipsnio 1 dalies c
                    punktas), nustatytą Lietuvos Respublikos nacionalinių
                    plėtros įstaigų įstatymo 6 straipsnio 1 dalies 2 punkte.
                    Paskolos teikėjas asmens duomenis tvarko vadovaudamasis
                    Paskolos teikėjo generalinio direktoriaus patvirtintomis
                    Asmens duomenų tvarkymo UAB „Investicijų ir verslo
                    garantijos“ taisyklėmis. Informacija apie asmens duomenų
                    tvarkymą pateikiama Paskolos teikėjo interneto svetainės
                    www.invega.lt skiltyje ,,Asmens duomenų apsauga“.
                    <br />
                    <br />
                    Asmens duomenų tvarkymo tikslas – užtikrinti teisėtą
                    valstybės pagalbos Paskolos gavėjui teikimą, vertinant ir
                    nustatant Paskolos gavėjo atitiktį schemos reikalavimams ir
                    atlikti su nurodyto tikslo įgyvendinimu susijusius veiksmus:
                    tikrinti Paskolos gavėjo pateiktos informacijos tikrumą,
                    vykdyti Paskolos teikėjo suteiktų Paskolų apskaitą, Paskolos
                    teikėjo suteiktų Paskolų portfelio rizikingumo vertinimą,
                    registruoti ir viešinti informaciją apie suteiktą valstybės
                    pagalbą (Paskolos gavėjo vardas, pavardė, finansuojamas
                    projektas, suteiktos valstybės pagalbos suma) Suteiktos
                    valstybės pagalbos ir nereikšmingos (de minimis) pagalbos
                    registre, Europos Komisijos valstybės pagalbos skaidrumo
                    viešos paieškos svetainėje{' '}
                    <a href="https://webgate.ec.europa.eu/competition/transparency/">
                      https://webgate.ec.europa.eu/competition/transparency/
                    </a>{' '}
                    Paskolos teikėjo interneto svetainėje, atlikti Paskolos
                    teikėjo suteiktų Paskolų auditą. Paskolos gavėjo
                    (verslininko) ir Paskolos gavėjo (įmonės) kontaktinio asmens
                    finansavimo klausimais vardas, pavardė, elektroninio pašto
                    adresas, telefono ryšio numeris taip pat naudojami atliekant
                    Paskolos gavėjų apklausas dėl Paskolos teikėjo suteiktų
                    Paskolų teisėtumo bei efektyvumo, kai rengiamos statistinės
                    ataskaitos apie suteiktas Paskolos teikėjo Paskolas.
                    <br />
                    <br />
                    Paskolos teikėjas audito tikslais ir gavusi Reglamento (ES)
                    2016/679 reikalavimus atitinkantį oficialų Europos Sąjungos
                    ir (ar) Lietuvos Respublikos institucijų (Europos Audito
                    Rūmų, Europos Komisijos, Lietuvos Respublikos finansų
                    ministerijos, Ekonomikos ir inovacijų ministerijos, Lietuvos
                    Respublikos valstybės kontrolės, Finansinių nusikaltimų
                    tyrimo tarnybos prie Lietuvos Respublikos vidaus reikalų
                    ministerijos, Lietuvos Respublikos specialiųjų tyrimų
                    tarnybos, Lietuvos Respublikos konkurencijos tarybos)
                    paklausimą pateikia asmens duomenis kompetentingoms
                    institucijoms Paskolos teikėjas gali teikti asmens duomenis
                    asmenims, kurie atlikdami teisės aktų nustatytas valstybės
                    funkcijas, teisės aktų reglamentuojančių šių asmenų
                    funkcijas ir veiklą, nustatyta tvarka turi teisę gauti
                    informaciją ir duomenis, reikalingus jų funkcijoms atlikti.
                    <br />
                    <br />
                    Paskolos teikėjas, vadovaudamasi teisės aktų reikalavimais
                    gali naudoti duomenis iš šių šaltinių: Lietuvos banko,
                    Valstybės įmonės Registrų centras, Valstybinio socialinio
                    draudimo fondo valdybos, Valstybinės mokesčių inspekcijos
                    prie Lietuvos Respublikos finansų ministerijos, Informatikos
                    ir ryšių departamento prie Lietuvos Respublikos vidaus
                    reikalų ministerijos bei kitų valstybės institucijų,
                    bendrovių, tvarkančių jungtines skolininkų duomenų rinkmenas
                    bei duomenis apie kredito istoriją, įsiskolinimus (pvz., UAB
                    „Creditinfo Lietuva“), juridinių asmenių, kai duomenys
                    teikiami apie šių juridinių asmenų atstovus, darbuotojus,
                    steigėjus, akcininkus, dalyvius, savininkus ir pan., taip
                    pat kitų viešai prieinamų ir privačių šaltinių, kai iš jų
                    yra gaunami duomenys, reikalingi Verslo subjektų, jų atstovų
                    ir dalyvių tapatybei nustatyti, pareiškėjų finansinei
                    padėčiai ir kreditingumui įvertinti, Verslo subjektų
                    atitikčiai Priemonių įgyvendinimą reglamentuojančiuose
                    teisės aktuose nustatytiems tinkamumo reikalavimams
                    įvertinti, Verslo subjektų pateiktų duomenų teisingumui
                    patikrinti bei paskolos negrąžinimo, verslo subjekto,
                    projekto rizikai įvertinti bei teisės aktų,
                    reglamentuojančių pinigų plovimo ir teroristų finansavimo
                    prevencijos reikalavimų vykdymui.
                    <br />
                    <br />
                    Su Priemonės reikalavimų įgyvendinimu susiję asmens duomenis
                    Paskolos teikėjas tvarko ir saugo 10 metų nuo paskutinio
                    dokumento dėl Paskolos suteikimo gavimo datos.
                    <br />
                    <br />
                    Duomenų subjektas gali kreiptis į Paskolos teikėją ir
                    įgyvendinti šias Reglamente (ES) 2016/679 įtvirtintas
                    teises:
                    <br />
                    1. teisę būti informuotam apie savo asmens duomenų tvarkymą;
                    <br />
                    2. teisę susipažinti su savo asmens duomenimis;
                    <br />
                    3. teisę reikalauti ištaisyti duomenis;
                    <br />
                    4. teisę reikalauti ištrinti asmens duomenis (“teisė būti
                    pamirštam“);
                    <br />
                    5. teisę reikalauti apriboti asmens duomenų tvarkymą;
                    <br />
                    6. teisę, duomenų subjektui paprašius, būti informuotam apie
                    duomenų gavėjus.
                    <br />
                    <br />
                    Duomenų subjektas turi teisę pateikti skundą Valstybinei
                    duomenų apsaugos inspekcijai, jei mano, kad asmens duomenų
                    tvarkymas atliekamas pažeidžiant Reglamento (ES) 2016/679
                    nuostatas. Duomenų subjektas taip pat turi teisę pateikti
                    skundą teismui, jei mano, kad Reglamentu (ES) 2016/679
                    nustatytos jo teisės buvo pažeistos, nes jo asmens duomenys
                    buvo tvarkomi pažeidžiant Reglamento (ES) 2016/679
                    nuostatas.
                  </td>
                </tr>

                <tr>
                  <td>Skaidrumo reikalavimai</td>
                  <td>
                    Vadovaujantis Bendrosios išimties reglamento 9 straipsnio
                    nuostatomis, informaciją apie suteiktą valstybės pagalbą ne
                    vėliau kaip per 6 mėnesius nuo valstybės pagalbos suteikimo
                    dienos būtina paskelbti Europos Komisijos valstybės pagalbos
                    skaidrumo viešos paieškos svetainėje{' '}
                    <a href=" https://webgate.ec.europa.eu/competition/transparency/public?lang=en">
                      {' '}
                      https://webgate.ec.europa.eu/competition/transparency/public?lang=en
                    </a>
                    .
                    <br />
                    <br />
                    Bendrosios išimties reglamento III priede nurodytą
                    informaciją Pagalbos teikėjas ne vėliau kaip per 3 mėnesius
                    nuo pagalbos suteikimo dienos pateikia Lietuvos Respublikos
                    ekonomikos ir inovacijų ministerijai.
                  </td>
                </tr>
                <tr>
                  <td>Stebėsena</td>
                  <td>
                    Paskolos teikėjas turės užtikrinti, kad teikiamos Paskolos
                    Socialinio poveikio projektus vykdančioms labai mažoms
                    įmonėms, mažoms įmonėms ar verslininkams, labai mažoms
                    įmonėms, mažoms įmonėms, kurios dalyvis (akcininkas, narys,
                    dalininkas) ar savininkas yra leidimą gyventi Lietuvoje
                    turintis Ukrainos pilietis arba Ukrainos juridinis asmuo, ir
                    Ukrainos pilietybę ir leidimą gyventi Lietuvoje turintiems
                    verslininkams, kuriems pagalba teikiama vadovaujantis
                    Bendrosios išimties reglamentu, atitiktų Bendrosios išimties
                    reglamento nuostatas, o teikiamos Paskolos Socialinio
                    poveikio projektus vykdančioms vidutinėms įmonėms ir
                    vidutinėms įmonėms, kurios dalyvis (akcininkas, narys,
                    dalininkas) ar savininkas yra leidimą gyventi Lietuvoje
                    turintis Ukrainos pilietis arba Ukrainos juridinis asmuo,
                    kurioms pagalba teikiama vadovaujantis <i>De minimis</i>{' '}
                    reglamentu, atitiktų <i>De minimis</i> reglamento nuostatas
                    ir, kad visi Paskolų gavėjai atitiktų Priemonės schemos
                    nuostatas. <br />
                    <br />
                    Paskolos teikėjas atsakingas už suteiktos valstybės pagalbos
                    ir nereikšmingos (<i>de minimis</i>) pagalbos registravimą
                    Suteiktos valstybės pagalbos ir nereikšmingos (
                    <i>de minimis</i>) pagalbos registre, vadovaujantis
                    Suteiktos valstybės pagalbos ir nereikšmingos (
                    <i>de minimis</i>) pagalbos registro nuostatais,
                    patvirtintais Lietuvos Respublikos Vyriausybės 2005 m.
                    sausio 19 d. nutarimu Nr. 35 „Dėl Suteiktos valstybės
                    pagalbos ir nereikšmingos (<i>de minimis</i>) pagalbos
                    registro nuostatų patvirtinimo“.
                    <br />
                    <br />
                    Paskolos Paskolų gavėjams, kuriems netaikomi valstybės
                    pagalbos reikalavimai, teikiamos rinkos sąlygomis. Paskolų
                    teikėjas yra įpareigotas užtikrinti, kad Paskolų SVV
                    subjektams, kuriems netaikomi valstybės pagalbos
                    reikalavimai, teikimas atitiktų rinkos ekonomikos sąlygas ir
                    visus rinkos ekonomikos veiklos vykdytojui keliamus
                    reikalavimus, kaip tai apibrėžta 2016 m. liepos 19 d.
                    Komisijos pranešime Nr. 2016/C 262/01 dėl Sutarties dėl
                    Europos Sąjungos veikimo 107 straipsnio 1 dalyje vartojamos
                    valstybės pagalbos sąvokos.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
