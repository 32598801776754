import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import {
  WithSpinner,
  InputWithTextInline,
  Button,
  Input,
  TableAdmin,
  TableRow,
  TableCell,
  RowTableAdmin,
  RowTableRow,
  RowTableCell,
} from '../../components';
import {
  requestDataLabelTranslation,
  companySize,
  requestStatus,
  rentContractTranslation,
  requestLogTranslation,
  applicantTranslation,
  helpSchem,
  helpSchemInvega,
  CompanySizeTypeInvega,
  questionListTranslations,
  loanPurpose,
} from './../../utils/translation/translation';
import styles from '../newRequest/newRequest.module.scss';
import uploadStyles from '../../containers/fileUploadContainer/fileUploadContainer.module.scss';
import { Link } from 'react-router-dom';
import { format, subYears } from 'date-fns';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import FinancialCriteriaModal from './../../modals/financialCriteriaModal/financialCriteriaModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faDownload,
  faExclamation,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { checkBOPage, formatNumber } from './../../utils/helpers/helpers';
import { evrkFull, formatCountryJson } from './../../utils/helpers/evrk';
import DatePicker from 'react-datepicker';
import DoublecheckEvaluationModal from './../../modals/doublecheckEvaluationModal/doublecheckEvaluationModal';
import RegenerateDecisionDocModal from './../../modals/regenerateDecisionDocModal/regenerateDecisionDocModal';
import IncreaseInvegaLoanSumModal from './../../modals/increaseInvegaLoanSumModal/increaseInvegaLoanSumModal';
import ConfirmRequestModal from './../../modals/confirmRequestModal/confirmRequestModal';
import ChangeAdditionalInfoModal from './../../modals/changeAdditionalInfoModal/changeAdditionalInfoModal';
import InvegaLoanSumModal from './../../modals/invegaLoanSumModal/invegaLoanSumModal';
import { FinancialTable } from '../../containers/';
import TakeRequestToEvaluateModal from './../../modals/takeRequestToEvaluateModal/takeRequestToEvaluateModal';
import ChangeContractFile from './../../modals/changeContractFile/changeContractFile';
import AcceptConditionsChangeRequestModal from './../../modals/acceptConditionsChangeRequestModal/acceptConditionsChangeRequestModal';
import TerminateRequestModal from './../../modals/terminateRequestModal/terminateRequestModal';
import RejectConditionsChangeRequest from './../../modals/rejectConditionsChangeRequestModal/rejectConditionsChangeRequestModal';
import ChangeCompanySize from './../../modals/changeCompanySize/changeCompanySize';
import ChangeSetNotDeletable from './../../modals/changeSetNotDeletableModal/changeSetNotDeletable';
import { SurwayAnswers } from '../requests/surwayAnswers';
import { AddressModal } from '../../containers/addressInputContainer/addressInputContainer';
import { SelfDeclarationInfo } from '../../utils/mapsData/selfDeclaration/selfDeclaration';
import AutorizationModal from './../../modals/autorizationModalBackoffice/autorizationModal';
import ChangeConditionsBoModal from './../../modals/changeConditionsBoModal/changeConditionsBoModal';
import GlobalMessageModal from './../../modals/globalMessageModal/globalMessageModal';
import SubmitConditionsChangeRequestModal from './../../modals/submitConditionsChangeRequestModal/submitConditionsChangeRequestModal';
import ReturnChangeConditionsModal from './../../modals/returnChangeConditionsModal/returnChangeConditionsModal';
import ReturnModal from './../../modals/returnModal/returnModal';
import ConfirmWithAdditionalDocs from './../../modals/confirmWithAdditionalDocs/confirmWithAdditionalDocs';
import ConfirmRequestNew from './../../modals/confirmRequestNew/confirmRequestNew';
import ConfirmRejectNew from './../../modals/confirmRejectNew/confirmRejectNew';

const BackofficeRequest = ({
  requestsState,
  dispatch,
  confirmModalVisible,
  financialCriteriaModalVisible,
  doublecheckEvaluationModalVisible,
  regenerateDecisionDocModalVisible,
  invegaLoanSumModalVisible,
  increaseInvegaLoanSumModalVisible,
  terminateRequestModalVisible,
  confirmRequestModalVisible,
  takeRequestToEvaluateModalVisible,
  changeAdditionalInfoModalVisible,
  signInInfo,
  financialCriteriaResults,
  changeContractFileVisible,
  changeCompanySizeVisible,
  acceptConditionsChangeRequestModalVisible,
  submitConditionsChangeRequestModalVisible,
  rejectConditionsChangeRequestVisible,
  loading,
  autorizationModalVisible,
  changeConditionsBoModalVisible,
  globalMessageModalVisible,
  returnChangeConditionsModalVisible,
  returnModalVisible,
  confirmWithAdditionalDocsVisible,
  confirmRequestNewVisible,
  confirmRejectNewVisible,
  changeSetNotDeletableVisible,
}) => {
  const rowTableHeader = [
    'Tipas',
    'Sutarties priedo pavadinimas',
    'Sutarties sudarymo data',
    'Pareiškėjo pasirašymo data',
    'Invega pasirašymo data',
    'Vartotojo komentaras',
    'Invega komentaras',
    'Prastėjanti finansinė būklė',
    'Veiksmai',
    '',
  ];
  const rowTableHeader2 = ['Tipas', 'Pavadinimas', 'Veiksmai'];
  const tableHeader = [
    'Priedo tipas',
    'Įkelto priedo pavadinimas',
    'Data',
    'Ar papildomas?',
    'Veiksmai',
  ];
  const logTableHeader = ['Data', 'Paraiškos būsena', 'Asmuo'];
  const jadisTableHeader = [
    'Pavadinimas, kodas',
    'Akcininkai',
    'Turimų akcijų kiekis (procentais)',
    'Duomenų gavimo data',
    'Duomenys apie dalyvius (akcininkus) atnaujinti',
    'Įstatinis kapitalas',
    'Akcijų skaičius (vnt)',
    'Nominali akcijos vertė',
  ];
  const evrkTableHeader = [
    'Evrk kodas',
    'Galioja nuo',
    'Galioja iki',
    'Ar pagrindinė?',
  ];

  const AdditionalCheckTableHeader = [
    'Patikros kriterijus',
    'Tipas',
    'Vardas, pavardė / Pavadinimas',
    'Laukas',
    'Reikšmė',
    'Patikros rezultatas',
    'Pastabos',
    'Data',
  ];

  const PepResultData = (text) => {
    switch (text) {
      case 1:
        return 'Tolesnis vertinimas negalimas';
      case 2:
        return 'Turi įtakos vertinimui';
      case 3:
        return 'Neturi įtakos vertinimui';
      default:
        return text;
    }
  };

  const evrkTableHeaderIndivuduals = [
    'Evrk kodas',
    'Galioja nuo',
    'Galioja iki',
    'Registracijos data',
  ];

  const amlPepTableHeader = [
    'Vardas, pavardė / Pavadinimas',
    'Tipas',
    'Turi sankcijų',
    'Turi PEP',
    'Patikros rezultatas',
    'Rasta 100% atitikmuo (AML)',
    'Rasta 100% atitikmuo (PEP)',
    'Rasta >=85% atitikmuo (AML)',
    'Rasta >=85% atitikmuo (PEP)',
  ];

  const comfirmedRequestHeader = [
    'Sutarties numeris',
    'Paskolos suma, Eur',
    'Galutinis paskolos grąžinimo terminas',
    'Atidėjimo terminas (mėn.)',
    'Išmokėjimo terminas',
    'Palūkanų normos perskaičiavimo dažnumas',
    'Pirma palūkanų keitimo diena',
    'Taikomo Euribor data',
    'Marža, %',
    'Kintama palūkanų dalis, %',
  ];

  const changedRequestHeader = [
    'Sąlygų pakeitimo numeris',
    'Paskolos suma, Eur',
    'Galutinis paskolos grąžinimo terminas',
    'Atidėjimo terminas (mėn.)',
    'Išmokėjimo terminas',
    'Palūkanų normos perskaičiavimo dažnumas',
    'Pirma palūkanų keitimo diena',
    'Taikomo Euribor data',
    'Marža, %',
    'Kintama palūkanų dalis, %',
  ];

  const relatedRequestsHeader = [
    'Paraiškos numeris',
    'Paskolos suma, Eur',
    'Paskolos tipas',
    'Paraiškos statusas',
    'Veiksmai',
  ];

  const representativesHeader = [
    'Vardas',
    'Pavardė',
    'Asmens kodas',
    'Atstovaujamos įmonės kodas',
    'Atstovaujamo asmens kodas',
    'Įgaliojimo pradžia',
    'Įgaliojimo pabaiga',
    'Dokumentai',
  ];

  const commentsHistoryTableHeader = ['Data', 'Komentatorius', 'Komentaras'];

  const amlPepTrueFalseTranslation = (val) => (val === true ? 'Taip' : 'Ne');

  const CheckResultIdTranslation = (text) => {
    switch (text) {
      case 1:
        return 'Praėjo';
      case 2:
        return 'Nepraėjo';
      case 3:
        return 'Rankinė patikra';
      default:
        return text;
    }
  };

  const AmlPepResultTranslate = (val) => {
    if (val === 1) {
      return <span style={{ color: 'green' }}>praėjo</span>;
    } else if (val === 2) {
      return (
        <span style={{ color: 'red' }}>
          Nepraėjo. Tolesnis paraiškos vertinimas galimas tik suderinus su
          KLIENTŲ ATITIKTIES DEPARTAMENTO atsakingu darbuotoju.
        </span>
      );
    } else if (val === 3) {
      return <span style={{ color: 'orange' }}>reikia peržiūros</span>;
    }
  };

  const translateIsBussinessSorrow = (val) => {
    if (val === true) {
      return <span style={{ color: 'red' }}>Taip</span>;
    } else if (val === false) {
      return <span style={{ color: 'green' }}>Ne</span>;
    } else {
      return <span>-</span>;
    }
  };

  const RulesEndText = () => (
    <div>
      <p className="agreementTextp">
        Asmens duomenys tvarkomi vadovaujantis 2016 m. balandžio 27 d. Europos
        Parlamento ir Tarybos reglamentu (ES) Nr. 2016/679 dėl fizinių asmenų
        apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų judėjimo
        ir kuriuo panaikinama Direktyva Nr. 95/46/EB (Bendrasis duomenų apsaugos
        reglamentas), Lietuvos Respublikos asmens duomenų teisinės apsaugos
        įstatymu ir priemonės „Dalinis nuomos mokesčio kompensavimas labiausiai
        nuo COVID-19 nukentėjusioms įmonėms“ įgyvendinimo sąlygų aprašu (toliau
        - Priemonės aprašymas).
      </p>
      <p className="agreementTextp">
        Asmens duomenys tvarkomi viešinimo, apskaitos ir audito tikslais bei
        atrankinių patikrų metu tikrinant nurodytos pareiškėjo informacijos
        tikrumą.
      </p>

      <p className="agreementTextp">
        Asmens duomenys INVEGOJE tvarkomi ir saugomi 10 metų nuo paskutinio
        dokumento pagal Priemonės aprašą gavimo datos.
      </p>
      <p className="agreementTextp">
        Informacija apie asmens duomenų tvarkymą pateikiama INVEGOS interneto
        svetainėje.
      </p>
    </div>
  );

  const isSmallText = () => (
    <span>
      Bendrovė ir visos su ja susijusios įmonės atitinka labai mažos arba mažos
      įmonės statusą pagal{' '}
      <a
        href="https://www.e-tar.lt/portal/lt/legalActEditions/TAR.640D50DB8877?faces-redirect=true"
        target="_blank"
        rel="noreferrer noopener"
      >
        Lietuvos Respublikos smulkaus ir vidutinio verslo plėtros įstatymo{' '}
      </a>
      nuostatas. Pagrindiniai kriterijai: įmonėje (atsižvelgiant ir į susijusias
      ir partnerines įmones) dirba mažiau kaip 50 darbuotojų ir įmonė atitinka
      bent vieną iš šių kriterijų: metinės pajamos neviršija 10 mln. Eur arba
      įmonės balanse nurodyto turto vertė neviršija 10 mln. Eur
    </span>
  );

  const selectStyle = {
    control: (base, state) => ({
      ...base,
      borderColor: '#033878',
      fontSize: '0.8rem',
      background: 'rgb(241, 240, 241)',
    }),
  };

  const options = [
    { value: null, label: '-' },
    { value: true, label: 'Taip' },
    { value: false, label: 'Ne' },
  ];

  const viewWindow = true;
  const requestId = window.location.href.split('/').pop();

  const [companyQuestionList, setCompanyQuestionList] = useState({});
  const [ceoDataList, setCeoDataList] = useState({});
  const [companyDataList, setCompanyDataList] = useState({});
  const [rentContract, setRentContract] = useState(null);
  const [applicantData, setApplicantData] = useState(null);
  const [controllingCompanies, setControllingCompanies] = useState(null);
  const [controllingForeignCompanies, setControllingForeignCompanies] =
    useState(null);

  const [comment, setComment] = useState('');

  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalMainText, setModalMainText] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const [loginType, setLoginType] = useState('Company');

  const [evrksList, setevrksList] = useState([]);

  const [turnoversInfo, setTurnoversInfo] = useState([]);

  const [invoices, setInvoices] = useState({
    status: 1,
    number: '1',
    // invoiceDate: '2020-04-27T12:03:28.316Z',
    sum: '',
    debtSum: '',
    invoiceType: 1,
    RentDate: '2020-02-01',
    checkAlreadyPayed: true,
    vmiInvoiceStatus: 1,
    checkIsComparative: true,
    SumWithoutPvm: '',
    DebtSumWithoutPvm: '',
  });

  const [userCommentChecked, setUserCommentChecked] = useState(false);

  const [editAmlPepTable, setEditAmlPepTable] = useState(false);
  const [editRiskTable, setEditRiskTable] = useState(false);

  const [riskAssessmentResults, setRiskAssessmentResults] = useState(null);

  const [riskLevel, setRiskLevel] = useState(null);

  const onCommentChange = ({ target: { name, value } }) => {
    setComment(value);
  };

  const confirmClick = () => {
    const open = {
      modalName: 'confirmRequestModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeContract = () => {
    const open = {
      modalName: 'changeContractFile',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const acceptConditionsChangeRequest = () => {
    const open = {
      modalName: 'acceptConditionsChangeRequestModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const rejectConditionsChangeRequest = () => {
    const open = {
      modalName: 'rejectConditionsChangeRequest',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeCompanySize = () => {
    const open = {
      modalName: 'changeCompanySize',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeSetNotDeletable = () => {
    const open = {
      modalName: 'changeSetNotDeletable',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const takeRequestToEvaluate = () => {
    const open = {
      modalName: 'takeRequestToEvaluateModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  {
    /*const rejectClick = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText(
      'Ar tikrai norite atmesti paraišką?<br />Sprendimas negalės būti pakeistas.'
    );
    setModalData({ requestId, comment, userCommentChecked });
    setModalAction('INVEGA_REJECT_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };*/
  }

  {
    /*STAR-256 taskui*/
  }
  const rejectClick = () => {
    const open = {
      modalName: 'confirmRejectNew',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const confirmWithDocsClick = () => {
    const open = {
      modalName: 'confirmWithAdditionalDocs',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const returnClick = () => {
    const open = {
      modalName: 'returnModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const downloadUploadedFile = (id, fileName) => {
    setIsloading(true);
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadFileManager(requestId, id),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const sign = () => {
    // const open = {
    //   modalName: 'globalConfirmationModal',
    //   visible: true,
    // };

    // setModalMainText(null);
    // setModalTitle(null);

    // setModalText('Ar tikrai norite fiksuoti paskolos sutarties pasirašymą?');
    // setModalData({ requestId });
    // setModalAction('INVEGA_SIGN_REQUEST');
    // dispatch(actions.modals.setModalVisibleFlagAction(open));
    dispatch(actions.dashboard.getSigningUrlAction(null, requestId, 'manager'));
  };

  const generateNew = () => {
    const open = {
      modalName: 'regenerateDecisionDocModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const signDecision = () => {
    dispatch(actions.dashboard.getSigningDecisionUrlAction(requestId));
  };

  const downloadContractDoc = (requestId) => {
    dispatch(actions.backoffice.downloadContractDocAction(requestId));
  };

  const returnSignedDocumentEndpoint = (
    requestId,
    fileType,
    fileId,
    applicantSigningDate,
    invegaSigningDate
  ) => {
    if (fileType === 'Decision') {
      return config.API_ENDPOINTS.GetSignedDocumentDecision(requestId);
    }

    if (fileType === 'RejectDecision') {
      return config.API_ENDPOINTS.GetSignedDocumentRejectDecision(requestId);
    }

    {/*if (
      fileType === 'ChangeConditions' ||
      fileType === 'ChangeConditionsRequest'
    ) {
      if (!applicantSigningDate || !invegaSigningDate) {
        return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
      }
      return config.API_ENDPOINTS.GetSignedDocumentChangeConditions(requestId);
    }*/}
    if (
      fileType === 'ChangeConditionsRequest'
    ) {
      if (!applicantSigningDate || !invegaSigningDate) {
        return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
      }
      return config.API_ENDPOINTS.GetSignedDocumentChangeConditions(requestId);
    }

    if (
      fileType === 'ChangeConditions'
    ) {
        return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
    }


    if (
      fileType === 'ChangeConditionsReject' ||
      fileType === 'ChangeConditionsDecision'
    ) {
      return config.API_ENDPOINTS.GetChangeConditionsFile(requestId, fileId);
    }

    return config.API_ENDPOINTS.GetSignedDocumentContract(requestId);
  };

  const GetSignedDocumentContractManager = (
    requestId,
    fileType,
    name,
    fileId,
    applicantSigningDate,
    invegaSigningDate
  ) => {
    setIsloading(true);
    fetch(
      Api.API_BASE_URL +
        returnSignedDocumentEndpoint(
          requestId,
          fileType,
          fileId,
          applicantSigningDate,
          invegaSigningDate
        ),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/pdf',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Sutartis_Nr_${requestId}.pdf`;
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const GetSignedDocumentDecisionManager = (requestId) => {
    setIsloading(true);
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.GetSignedDocumentDecision(requestId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Sprendimas_Nr_${requestId}.pdf`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const getFinancialCriteriaResults = (id) => {
    const open = {
      modalName: 'financialCriteriaModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
    dispatch(
      actions.dashboard.getFinancialCriteriaResultsAction(id, 'Manager', true)
    );
  };

  const checkFinancialCriteriaResults = (id, AllEvaluationCompleted) => {
    // if (AllEvaluationCompleted === true) {
    getFinancialCriteriaResults(id);
    // } else {
    //   const open = {
    //     modalName: 'doublecheckEvaluationModal',
    //     visible: true,
    //   };

    //   dispatch(actions.modals.setModalVisibleFlagAction(open));
    // }
  };

  const terminateRequest = (id) => {
    const open = {
      modalName: 'terminateRequestModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const userComment = (e) => {
    setUserCommentChecked(e.target.checked);
  };

  const [requestSystemInfo, setRequestSystemInfo] = useState(null);

  const isCheckBox = (name) =>
    name.slice(0, 5) === 'check' || name.slice(0, 5) === 'Check' ? true : false;

  const isDate = (name) =>
    name.slice(name.length - 4) === 'Date' ? true : false;

  const [hasInvoice, setHasInvoice] = useState(true);

  const [fileArr, setFileArray] = useState([]);
  const [rejection, setRejection] = useState(null);

  useEffect(() => {
    if (requestsState && requestsState.data) {
      setCeoDataList(requestsState.data.ContactInfo);
      setCompanyDataList(requestsState.data.CompanyData);
      setCompanyQuestionList(requestsState.data.FinancialData);
      setComment(requestsState.data.Comment ? requestsState.data.Comment : '');
      setRiskAssessmentResults(requestsState.data.RiskAssessmentResults);
      setRiskLevel(requestsState.data?.RiskLevelId);

      setRentContract({
        ...requestsState.data.RentContract,
        checkAdditionalRule1:
          requestsState.data.RentContract != null &&
          requestsState.data.RentContract.CheckAdditionalRules === true
            ? true
            : false,
        checkAdditionalRule2:
          requestsState.data.RentContract != null &&
          requestsState.data.RentContract.CheckAdditionalRules === true
            ? true
            : false,
        checkAdditionalRule3:
          requestsState.data.RentContract != null &&
          requestsState.data.RentContract.CheckAdditionalRules === true
            ? true
            : false,
      });

      setApplicantData(requestsState.data.ApplicantData);

      setInvoices(requestsState.data.Invoice);
      setRequestSystemInfo(requestsState.data.RequestSystemInfo);
      setLoginType(
        requestsState.data.RequestSystemInfo != null &&
          requestsState.data.RequestSystemInfo.IndividualId === null
          ? 'Company'
          : 'Individual'
      );
      setUserCommentChecked(
        requestsState.data.UserComment === null ? false : true
      );

      if (requestsState.data.RentContract != null) {
        if (requestsState.data.RentContract.RealEstateObjectId != null) {
          requestsState.data.Invoice.Id == null
            ? setHasInvoice(false)
            : setHasInvoice(true);
        } else {
          setHasInvoice(true);
        }
      }
      setControllingCompanies(requestsState.data.ControllingCompanies);
      setControllingForeignCompanies(
        requestsState.data.ControllingForeignCompanies
      );
      setApproversCommen(requestsState.additionalComments.ApproversComment);
      setSignatorysComment(requestsState.additionalComments.SignatorysComment);
      setRiskAssessmentNote(requestsState.RiskAssessmentNote);
      setRiskAssessmentDate(requestsState.RiskAssessmentDate);

      if (
        requestsState.filesTableList &&
        requestsState.filesTableList.length !== 0 &&
        requestsState.data.RequestSystemInfo &&
        requestsState.data.RequestSystemInfo.Status === 10
      ) {
        if (requestsState.filesTableList.length === 1) {
          setRejection(requestsState.filesTableList[0]);
        } else {
          setRejection(requestsState.filesTableList.slice(-1)[0]);
          setFileArray([requestsState.filesTableList]);
          setFileArray(
            [...requestsState.filesTableList].slice(
              0,
              requestsState.filesTableList.length - 1
            )
          );
        }
      } else {
        setFileArray(requestsState.filesTableList);
      }
    }
  }, [requestsState]);

  const [hasParentCompanyValue, setHasParentCompanyValue] = useState(false);
  const [hasParentCompanyState, setHasParentCompanyState] = useState(false);
  const [declarationInfo, setDeclarationInfo] = useState(null);
  const [loanInfo, setLoanInfo] = useState(null);
  const [checks, setChecks] = useState(null);
  const [pepData, setPepData] = useState({});
  const [isStatusGood, setIsStatusGood] = useState(false);
  const [requestData, setRequestData] = useState(requestsState);

  const [suppliers, setSuppliers] = useState(null);

  // const financialDataArray = [
  //   'Fsi2019Submitted',
  //   'Fsi2020Submitted',
  //   'FsiStartDate',
  //   'FsiEndDate',
  //   'Assets19',
  //   'Assets20',
  //   'Liabilities19',
  //   'Liabilities20',
  //   'PropertyAmount19',
  //   'PropertyAmount20',
  //   'Turnover19',
  //   'TurnovFer20',
  // ];

  const financialDataArray2019 = [
    'Fsi2019Submitted',
    'FsiStartDate2019',
    'FsiEndDate2019',
    'Assets19',
    'Liabilities19',
    'PropertyAmount19',
    'Turnover19',
  ];

  const financialDataArray2020 = [
    'Fsi2020Submitted',
    'FsiStartDate2020',
    'FsiEndDate2020',
    'Assets20',
    'Liabilities20',
    'PropertyAmount20',
    'Turnover20',
  ];

  const financialDataArray2021 = [
    'Fsi2021Submitted',
    'FsiStartDate2021',
    'FsiEndDate2021',
    'Assets21',
    'Liabilities21',
    'PropertyAmount21',
    'Turnover21',
  ];

  const financialDataArray2022 = [
    'Fsi2022Submitted',
    'FsiStartDate2022',
    'FsiEndDate2022',
    'Assets22',
    'Liabilities22',
    'PropertyAmount22',
    'Turnover22',
  ];
  const financialDataArray = (fsiYear) =>
    fsiYear == 2022
      ? financialDataArray2022
      : fsiYear == 2021
      ? financialDataArray2021
      : fsiYear == 2019
      ? financialDataArray2019
      : financialDataArray2020;

  const agreementsCheckBoxesArray = [
    'Check2Correctness',
    'Check4AgreementToPublish',
    'Check3AgreementToCheck',

    'Check5AgreementToManage',
    'Check6OtherCircuference',
    'Check7Restructuring',
    'Check1Confirment',
  ];

  const fileTypeTranslation = (name) => {
    if (name === 'Contract') {
      return 'Sutartis';
    } else if (name === 'Decision') {
      return 'Sprendimas';
    } else if (name === 'ChangeConditionsRequest') {
      return 'Prašymas pakeisti susitarimą';
    } else if (name === 'ChangeConditions') {
      return 'Sąlygų keitimo dokumentas';
    } else if (name === 'ChangeConditionsReject') {
      return 'Sąlygų keitimas atmestas';
    } else if (name === 'ChangeConditionsDecision') {
      return 'Sąlygų keitimo sprendimas';
    } else if (name === 'RejectDecision') {
      return 'Atmetimo sprendimas';
    } else if (name === 'RejectRequest') {
      return 'Atmetimo prašymas';
    }
  };

  const euriborTranslation = (name) => {
    if (name === 1) {
      return '1 mėn.';
    } else if (name === 2) {
      return '3 mėn.';
    } else if (name === 3) {
      return '6 mėn.';
    } else if (name === 4) {
      return '12 mėn.';
    }
  };

  const fileTypeTranslation2 = (type) => {
    switch (type) {
      case 1:
        return 'SVV subjekto statuso deklaracija';
      case 2:
        return 'Vienos įmonės deklaracija';
      case 3:
        return 'Verslo subjektą apibūdinantys dokumentai';
      case 4:
        return 'Verslininko individualios veiklos registracijos dokumentai';
      case 5:
        return 'Verslo planas (Projekto aprašymas)';
      case 6:
        return 'Privataus finansuotojo ketinimo dalyvauti Projekte raštas';
      case 7:
        return 'Einamųjų finansinių metų tarpinė finansinė ataskaita arba lygiaverčiai dokumentai';
      case 8:
        return 'Verslo subjekto valdymo organo sprendimas dėl Paskolos gavimo, turto įkeitimo ir įgaliotų asmenų paskyrimo sutarties su Paskolos davėju sudarymui';
      case 9:
        return 'Paskolos gavėjo veiklos (finansinių) duomenų detalizacijos forma';
      case 10:
        return 'Kiti dokumentai';
      case 11:
        return 'Paskolos gavėjo atitikimo žemės ūkio inovacinei veiklai aprašymas (Priedas Nr. 1)';
      default:
        return type;
    }
  };

  const applicantDataArray = [
    'CompanyManagerName',
    'PersonalCode',
    'CompanyManagerCitizenshipId',
    'CompanyEvrk',
    'IndividualActivityCertificateNumberStartDate',
    'IndividualActivityCertificateNumber',
    'CompanyActivityAddress',
    // 'CompanyPhone',
    // 'CompanyEmail',
    // 'Iban',
  ];

  const ceoDataListArray = ['ApplicantName', 'ApplicantSurname'];

  const companyDataListArray = [
    'CompanyName',
    'CompanyCode',
    'CompanyManagerName',
    'CompanyManagerCitizenshipId',
    'CompanyType',
    'StatusInitial',
    'CompanyEvrk',

    'CompanyRegistrationDate',
    'CompanyRegistrationAddress',
    'CompanyActivityAddress',
    'CompanyPhone',
    'CompanyEmail',
    'ContractEmail',
    'Iban',
  ];

  const declarationInfoArray = [
    'EuLoanSum',
    'InvegaAsapSumLoan',
    'InvegaMostAffectedLoanSum',
    'InvegaTurapSumLoan',
    'InvegaPortfolioLoan2Sum',
  ];

  const translateTurnover = (val) => {
    const year = val.substring(0, 4);
    const month = val.substring(5, 7);

    const dictionary = {
      '01': 'sausio',
      '02': 'vasario',
      '03': 'kovo',
      '04': 'balandžio',
      '05': 'gegužės',
      '06': 'birželio',
      '07': 'liepos',
      '08': 'rugpjūčio',
      '09': 'rugsėjo',
      10: 'spalio',
      11: 'lapkričio',
      12: 'gruodžio',
    };

    if (val && val.length === 4) {
      return `Apyvarta ${year}m `;
    }
    return `Apyvarta ${year}m. ${dictionary[month]} mėn.`;
  };

  const shareHolderTypeTranslate = (val) => {
    switch (val) {
      case 'Company':
        return 'Juridinis asmuo';
      case 'Person':
        return 'Fizinis asmuo';
      default:
        return val;
    }
  };

  const criteriaIdEnums = (val) => {
    switch (val) {
      case 1:
        return 'Šalies kriterijus';
      case 2:
        return 'EVRK kriterijus';
      case 3:
        return 'JAR tarptautinės sankcijos';
      case 4:
        return 'AML';
      case 5:
        return 'PEP';
      default:
        return val;
    }
  };

  const fieldIdEnums = (val) => {
    switch (val) {
      case 1:
        return 'Pilietybė';
        case 2:
          return 'Įmonės registracijos šalis';
        case 3:
          return 'Įmonės registracijos šalis';
        case 4:
          return 'Valstybė, kurioje asmuo yra PEP';
        case 5:
          return 'Asmens tapatybės dokumentą išdavusi valstybė';
        case 6:
        return 'Pilietybė';
      case 7:
        return 'Rezidavimo valstybė mokesčių tikslais';
      case 8:
        return 'Mokesčio mokėtojo valstybė';
      case 9:
        return 'Nuolatinės gyvenamosios vietos valstybė ';
        case 10:
          return 'Rezidavimo valstybė mokesčių tikslais';
        case 11:
          return 'Mokesčio mokėtojo valstybė';
        case 12:
          return 'Rezidavimo valstybė mokesčių tikslais';
        case 13:
          return 'Mokesčio mokėtojo valstybė';
      default:
        return val;
    }
  };

  const resultIdEnum = (val) => {
    switch (val) {
      case 1:
        return 'Tolesnis vertinimas negalimas';
      case 2:
        return 'Turi įtakos vertinimui';
      case 3:
        return 'Neturi įtakos vertinimui';
      default:
        return val;
    }
  };

  const riskIdEnum = (val) => {
    switch (val) {
      case 1:
        return 'Aukšta';
      case 2:
        return 'Vidutinė';
      case 3:
        return 'Žema';
      default:
        return val;
    }
  };

  const check1Confirment = () => (
    <div>
      <p className="agreementTextp">
        Paskolos gavėjas patvirtina ir garantuoja, kad:
      </p>
      <p className="agreementTextp">
        1. Yra susipažinęs (-usi) su finansinės priemonės „Startuok“,
        finansuojamos Europos regioninės plėtros fondo lėšomis (toliau –
        priemonė), aprašyme (toliau – Aprašymas) nustatyta tvarka ir
        reikalavimais;
      </p>

      <p className="agreementTextp">
        2. Man žinoma, kad projektas, kuriam finansuoti teikiama ši paraiška,
        bus finansuojamas iš Verslo finansavimo fondo, finansuojamo iš Europos
        regioninės plėtros fondo, lėšų;
      </p>
      <p className="agreementTextp">
        3. Įsipareigoju per paskolos teikėjo nustatytą terminą pateikti jam
        reikiamą informaciją ir (arba) atlikti Lietuvos Respublikos ir ES
        atsakingų institucijų nurodytus veiksmus, vykdomus pagal Aprašymą ir
        pagal Lietuvos Respublikos ir ES teisės aktus, reglamentuojančius
        veiksmų programos įgyvendinimą;
      </p>
      <p className="agreementTextp">
        4. Paraiškos pateikimo metu veikia Lietuvos Respublikoje arba kitoje ES
        šalyje narėje, jei vykdant Paskolos sutartį sukurta nauda atitenka
        Lietuvos Respublikai, kaip nurodyta Finansinių priemonių išlaidų
        tinkamumo finansuoti rekomendacijose, kaip nurodyta Finansinių priemonių
        išlaidų tinkamumo finansuoti rekomendacijose, patvirtintose Veiksmų
        programos valdymo komiteto 2016 m. birželio 29 d. protokolo sprendimu
        Nr. 38 su vėlesniais pakeitimais (toliau – Finansinių priemonių išlaidų
        tinkamumo finansuoti rekomendacijos), kurios yra skelbiamos interneto
        svetainėje adresu
        <a
          href="http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          http://www.esinvesticijos.lt/lt/dokumentai/finansiniu-priemoniu-islaidu-tinkamumo-finansuoti-rekomendacijos-3
        </a>
        , tačiau toks finansavimas negali viršyti 15 procentų šios schemos 2
        punkte nurodytos Priemonei skirtos bendros lėšų sumos, kaip tai nurodyta
        2013 m. gruodžio 17 d. Europos Parlamento ir Tarybos reglamento (ES) Nr.
        1303/2013, kuriuo nustatomos Europos regioninės plėtros fondui, Europos
        socialiniam fondui, Sanglaudos fondui, Europos žemės ūkio fondui kaimo
        plėtrai ir Europos jūros reikalų ir žuvininkystės fondui bendros
        nuostatos ir Europos regioninės plėtros fondui, Europos socialiniam
        fondui, Sanglaudos fondui ir Europos jūros reikalų ir žuvininkystės
        fondui taikytinos bendrosios nuostatos ir panaikinamas Tarybos
        reglamentas (EB) Nr. 1083/2006, su paskutiniais pakeitimais, padarytais
        2020 m. gruodžio 23 d. Europos Parlamento ir Tarybos reglamentu (ES) Nr.
        2020/2221, 70 straipsnio 2 dalies b punkte;
      </p>
      <p className="agreementTextp">
        5. Įmonė ir (ar) įmonių grupė, kaip ši sąvoka apibrėžta Lietuvos
        Respublikos įmonių grupių konsoliduotosios finansinės atskaitomybės
        įstatyme, kuriai priklauso paskolos gavėjas, paskutinę praėjusių
        kalendorinių metų dieną ir paraiškos INVEGAI pateikimo metu nebuvo
        laikomi sunkumų patiriančiais, kaip tai apibrėžta:
        <br />
        - Europos Komisijos Gairėse dėl valstybės pagalbos sunkumų patiriančioms
        ne finansų įmonėms sanuoti ir restruktūrizuoti (2014/C 249/01) (taikoma
        visiems);
        <br />
        - Bendrosios išimties reglamento 2 straipsnio 18 punkte (taikoma tik
        labai mažoms, mažoms įmonėms ar verslininkams, vykdantiems socialinio
        poveikio projektą);
        <br />- De minimis reglamento 4 straipsnio 3 dalies a punkte (taikoma
        tik vidutinėms įmonėms, vykdančioms socialinio poveikio projektą);
      </p>
      <p className="agreementTextp">
        6. Paraiškos pateikimo metu atitinka minimalius patikimų mokesčių
        mokėtojų kriterijus, nustatytus Lietuvos Respublikos mokesčių
        administravimo įstatymo 401 straipsnyje. Atitiktis šiam reikalavimui
        vertinama pagal viešai paskelbtą Valstybinės mokesčių inspekcijos prie
        Lietuvos Respublikos finansų ministerijos informaciją, skelbiamą
        interneto svetainėje adresu
        <a
          href="https://www.vmi.lt/evmi/mokesciu-moketoju-informacija"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          https://www.vmi.lt/evmi/mokesciu-moketoju-informacija
        </a>
        ;
      </p>
      <p className="agreementTextp">
        7. Paraiškos pateikimo metu, kai ją teikia juridinis asmuo, jam nėra
        iškelta nemokumo byla ir (ar) jis nėra restruktūrizuojamas pagal
        Lietuvos Respublikos juridinių asmenų nemokumo įstatymą arba iki jo
        įsigaliojimo galiojusius Lietuvos Respublikos įmonių bankroto įstatymą
        ir Lietuvos Respublikos restruktūrizavimo įstatymą arba, kai paraišką
        teikia verslininkas, kaip jis apibrėžtas SVV įstatymo 2 straipsnio 21
        dalyje, – jam nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
        Respublikos fizinių asmenų bankroto įstatymą. Atitikimas reikalavimui
        vertinamas pagal viešai prieinamus Juridinių asmenų registro ir Audito,
        apskaitos, turto vertinimo ir nemokumo valdymo tarnybos prie Lietuvos
        Respublikos finansų ministerijos duomenis;
      </p>
      <p className="agreementTextp">
        8. Neveikia žemės ūkio sektoriuje, kaip tai apibrėžta 2014 m. birželio
        25 d. Komisijos reglamento (ES) Nr. 702/2014, kuriuo skelbiama, kad tam
        tikrų kategorijų pagalba žemės bei miškų ūkio sektoriuose ir kaimo
        vietovėse yra suderinama su vidaus rinka pagal Sutarties dėl Europos
        Sąjungos veikimo 107 ir 108 straipsnius, su visais pakeitimais 2
        straipsnio 3 dalyje, išskyrus atvejus, kai šis Paskolų gavėjas vykdo
        arba ketina vykdyti inovacinę veiklą, nevykdo žuvininkystės ir
        akvakultūros veiklos, kaip tai apibrėžta Lietuvos Respublikos
        žuvininkystės įstatyme, ar jo vykdoma pagrindinė veikla pagal
        Statistikos departamento prie Lietuvos Respublikos Vyriausybės
        generalinio direktoriaus įsakymu patvirtintą Ekonominės veiklos rūšių
        klasifikatorių (EVRK 2 red.)(toliau – EVRK 2 red.) nėra finansinė ir
        draudimo veikla (visi veiklos kodai pagal EVRK 2 red. 64–66 skyrius),
        išskyrus atvejus, kai šis Paskolų gavėjas vysto finansines
        technologijas;
      </p>
      <p className="agreementTextp">
        9. Paskolos gavėjui nėra išduotas vykdomasis raštas sumoms išieškoti
        pagal ankstesnį Europos Komisijos sprendimą, kuriame Lietuvos valstybės
        suteikta pagalba skelbiama neteisėta ir nesuderinama su vidaus rinka,
        arba Paskolos gavėjas yra grąžinęs visą neteisėtos pagalbos sumą,
        įskaitant palūkanas, kaip nustatyta 2015 m. liepos 13 d. Tarybos
        reglamente (ES) 2015/1589, nustatančiame išsamias Sutarties dėl Europos
        Sąjungos veikimo 108 straipsnio taikymo taisykles;
      </p>
      <p className="agreementTextp">
        10. Esant poreikiui ir (ar) kilus įtarimui, bet kuriuo paraiškos
        vertinimo metu ir (ar) po paskolos sutarties pasirašymo ir paskolos
        išmokėjimo, INVEGAI pareikalavus, Paskolos gavėjas pateiks dokumentus,
        patvirtinančius, kad Paskolos gavėjui ir (ar) Paskolos gavėjo
        akcininkui, turinčiam ne mažiau nei 25 proc. Verslo subjekto akcijų,
        Paskolos gavėjo vadovui, kitam valdymo ar priežiūros organo nariui ar
        kitam asmeniui, turinčiam (turintiems) teisę atstovauti Paskolos gavėją
        ar jį kontroliuoti, jo vardu priimti sprendimą, sudaryti sandorį, ar
        buhalteriui (buhalteriams) ar kitam (kitiems) asmeniui (asmenims),
        turinčiam (turintiems) teisę surašyti ir pasirašyti Paskolos gavėjo
        apskaitos dokumentus, per pastaruosius 5 metus nebuvo priimtas ir
        įsiteisėjęs apkaltinamasis teismo nuosprendis ir šis asmuo neturi
        neišnykusio ar nepanaikinto teistumo už nusikalstamos ekonominės veiklos
        pažeidimus;
      </p>
      <p className="agreementTextp">
        11. Man žinoma, kad projektas ir projekto veiklos negali būti finansuoti
        ar finansuojami bei suteikus finansavimą teikiami finansuoti iš kitų
        programų, finansuojamų valstybės biudžeto lėšomis, kitų fondų ar
        finansinių mechanizmų (Europos ekonominės erdvės, Norvegijos,
        Šveicarijos Konfederacijos ir pan.) ir kitų veiksmų programų priemonių,
        jei dėl to projekto ar jo dalies tinkamos finansuoti išlaidos gali būti
        finansuotos kelis kartus;{' '}
      </p>
      <p className="agreementTextp">
        12. Man žinoma, kad pareiškėjas, kuris yra fizinis asmuo, projekto
        įgyvendinimo ir kompensacijos mokėjimo laikotarpiu privalo vykdyti
        veiklą pagal individualios veiklos pažymą arba turėti išduotą galiojantį
        verslo liudijimą, patvirtinantį, kad ūkinė ir (arba) ekonominė veikla
        vykdoma;{' '}
      </p>
      <p className="agreementTextp">
        13. Yra informuotas (-a), kad paraiška gali būti atmesta, jeigu Aprašyme
        nustatyta tvarka ir terminais nebus pateikti prašomi dokumentai ir (ar)
        informacija.
      </p>
    </div>
  );

  useEffect(() => {
    if (requestsState && requestsState.data) {
      setCeoDataList(requestsState.data.ContactInfo); //kontaktinė informacija JA/FA bendras
      setCompanyDataList(requestsState.data.CompanyData);
      setHasParentCompanyValue(
        requestsState.data.CompanyData &&
          requestsState.data.CompanyData.ParentCompanyCodesFromRequest &&
          requestsState.data.CompanyData.ParentCompanyCodesFromRequest
            .length !== 0
          ? true
          : false
      );
      setDeclarationInfo(requestsState.data.DeclarationInfo);
      setLoanInfo(requestsState.data.LoanInfo);
      setChecks(requestsState.data.Checks);

      setevrksList(
        requestsState.data.evrksList ? requestsState.data.evrksList : []
      );

      requestsState.data.ApplicantData != null &&
        setApplicantData({
          ...requestsState.data.ApplicantData,
          IndividualActivityCertificateNumberStartDate:
            requestsState.data.ApplicantData
              .IndividualActivityCertificateNumberStartDate,
        });

      setCompanyQuestionList(requestsState.data.FinancialData);
      setRequestSystemInfo(requestsState.data.RequestSystemInfo);

      // Pep

      if (requestsState.data && requestsState.data.PepData) {
        for (let i = 0; i < 4; i++) {
          const item =
            requestsState.data.PepData.ImportantResponsibilityPersons[i];
          if (item && (item.Name || item.Surname)) {
            requestsState.data.PepData.ImportantResponsibilityPersons[i] = {
              name: item.Name,
              surname: item.Surname,
              birthDate: item.BirthDate,
              position: item.Position,
              country: item.Country,
              personRelation: item.PersonRelation,
            };
          } else if (!item?.name) {
            requestsState.data.PepData.ImportantResponsibilityPersons[i] = {
              name: '',
              surname: '',
              birthDate: '',
              position: '',
              country: '',
              personRelation: '',
            };
          }
        }
        // a
        for (let i = 0; i < 4; i++) {
          const item = requestsState.data.PepData.ControllingPersons[i];

          if (item && (item.Name || item.Surname)) {
            requestsState.data.PepData.ControllingPersons[i] = {
              name: item.Name,
              surname: item.Surname,
              citizenshipId: item.CitizenshipId,
              personCode: item.PersonCode,
              position: item.Position,
              sharesPart: item.SharesPart,
              votingPart: item.VotingPart,
            };
          } else if (!item?.name) {
            requestsState.data.PepData.ControllingPersons[i] = {
              name: '',
              surname: '',
              citizenshipId: '',
              personCode: '',
              position: '',
              sharesPart: '',
              votingPart: '',
            };
          }
        }

        for (let i = 0; i < 4; i++) {
          const item = requestsState.data.PepData.ControllingCompanies[i];
          if (item && (item.Name || item.Code)) {
            requestsState.data.PepData.ControllingCompanies[i] = {
              name: item.Name,
              code: item.Code,
              country: item.Country,
              sharesPart: item.SharesPart,
              votingPart: item.VotingPart,
            };
          } else if (!item?.name) {
            requestsState.data.PepData.ControllingCompanies[i] = {
              name: '',
              code: '',
              country: '',
              sharesPart: '',
              votingPart: '',
            };
          }
        }
      }

      if (requestsState.data) {
        setRequestData(requestsState.data);

        setPepData(requestsState.data.PepData);
        setSuppliers(requestsState.data.Suppliers);
      }

      //pep end

      setIsStatusGood(
        requestsState.data.RequestSystemInfo != null &&
          requestsState.data.RequestSystemInfo.Status != null &&
          requestsState.data.RequestSystemInfo.Status !== 6
          ? true
          : false
      );

      setControllingCompanies(requestsState.data.ControllingCompanies);
      setControllingForeignCompanies(
        requestsState.data.ControllingForeignCompanies
      );
      setTurnoversInfo(arrangeTurnovers(requestsState.data.TurnoversInfo));
      setSelfDeclarationtData(requestsState.data.SelfDeclaration);
    }
  }, [requestsState]);

  useEffect(() => {
    dispatch(actions.backoffice.getRequestAction(requestId));
    dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
    dispatch(actions.backoffice.saveEditAdditionalComments('get', requestId));
    dispatch(actions.backoffice.getRequestLogAction(requestId));
    dispatch(actions.backoffice.getInvegaUsersAction());
  }, [requestId]);

  const arrangeTurnovers = (list) => {
    if (
      list &&
      list.length === 6 &&
      list[0].Date &&
      list[0].Date === '2019-11-01T00:00:00' &&
      list[5].Date &&
      list[5].Date === '2021-01-01T00:00:00'
    ) {
      return [list[0], list[3], list[1], list[4], list[2], list[5]];
    }

    return list;
  };

  const trueFalseTranslation = (val) => (val == true ? 'Taip' : 'Ne');

  const trueFalseTranslation2 = (val) => {
    if (val === false) {
      return 'Ne';
    } else if (val === true) {
      return 'Taip';
    } else {
      return 'Neaišku';
    }
  };

  const [approversComment, setApproversCommen] = useState('');
  const [signatorysComment, setSignatorysComment] = useState('');

  const saveApproversComment = ({ target: { name, value } }) => {
    setApproversCommen(value);
  };

  const [riskAssessmentNote, setRiskAssessmentNote] = useState('');
  const [riskAssessmentDate, setRiskAssessmentDate] = useState('');
  const [riskAssessmentResult, setRiskAssessmentResult] = useState('');

  const saveRiskAssessmentNote = ({ target: { name, value } }) => {
    setRiskAssessmentNote(value);
  };

  const saveRiskAssessmentDate = (e) => {
    const formattedData = format(new Date(e), 'yyyy-MM-dd');
    setRiskAssessmentDate(formattedData);
  };
  const onRiskAssesmentResultChange = (val) => {
    setRiskAssessmentResult(val.target.value);
  };

  const saveSignatorysComment = ({ target: { name, value } }) => {
    setSignatorysComment(value);
  };

  const saveComment = () => {
    const data = {
      approversComment: approversComment,
      signatorysComment: signatorysComment,
      requestId: requestSystemInfo.RequestId,
    };

    dispatch(actions.backoffice.saveEditAdditionalComments('save', data));
  };
  const [showErr, setShowErr] = useState(false);
  const saveRiskAssesmentData = () => {
    if (riskAssessmentDate) {
      const riskData = {
        riskAssessmentResultId: riskAssessmentResult,
        riskAssessmentNote: riskAssessmentNote,
        riskAssessmentDate: riskAssessmentDate,
        requestId: requestSystemInfo.RequestId,
      };
      dispatch(actions.backoffice.saveRiskAssessmentResult('save', riskData));
      setRiskAssessmentResult(null);
      setShowErr(false);
    } else {
      setShowErr(true);
    }
  };

  const setLoanSum = () => {
    const open = {
      modalName: 'invegaLoanSumModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const setAdditionalInfo = () => {
    const open = {
      modalName: 'changeAdditionalInfoModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeStatusClick = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalText(null);

    setModalTitle('Ar tikrai norite pakeisti būseną?');
    setModalMainText('Paraiškos būsena bus pakeista į "Pateikta vertinti".');
    setModalData({ requestId, comment, userCommentChecked });
    setModalAction('INVEGA_CHANGE_TO_SENDFORAPPROVE_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeStatus3Click = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalText(null);

    setModalTitle('Ar tikrai norite pakeisti būseną?');
    setModalMainText('Paraiškos būsena bus pakeista į "Patvirtinta".');
    setModalData({ requestId, comment, userCommentChecked });
    setModalAction('INVEGA_CHANGE_TO_APPROVE_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeStatus13Click = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalText(null);

    setModalTitle('Ar tikrai norite pakeisti būseną?');
    setModalMainText('Paraiškos būsena bus pakeista į "Sąlygų keitimas".');
    setModalData({ requestId, comment, userCommentChecked });
    setModalAction('INVEGA_CHANGE_TO_CHANGECONDITIONS_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeStatus17Click = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalText(null);

    setModalTitle('Ar tikrai norite pakeisti būseną?');
    setModalMainText('Paraiškos būsena bus pakeista į "Patvirtintas sąlygų keitimas".');
    setModalData({ requestId, comment, userCommentChecked });
    setModalAction('INVEGA_CHANGE_TO_CHANGECONDITIONSAPPROVED_REQUEST');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const increaseInvegaLoanSum = () => {
    const open = {
      modalName: 'increaseInvegaLoanSumModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const isSelectedEvrkInTheList = (val) => {
    const isInArray = evrksList.filter((x) => x == val);
    return evrksList.length > 0 && isInArray.length > 0 ? false : true;
  };

  const formatEvrkJson2 = (evrk) => {
    return evrksList
      ? evrksList.map((val) => ({
          [val]: evrkFull[val],
        }))
      : null;
  };

  const from19To20 = (val) => `${val.slice(0, -2)}20`;

  const ShowFinancialDataBlock = (year, data, controllingCompaniesData) => {
    if (loginType === 'Company') {
      if (data) {
        return !data.FsiYear ? true : data.FsiYear == year;
      }
    }
    if (controllingCompaniesData && controllingCompaniesData.length > 0) {
      return controllingCompaniesData.some((val) => val.FsiYear == year);
    }
    return false;
  };

  const ageLessThen5 = (regDate) => {
    const date = new Date();
    const ageMinus5Years = subYears(new Date(date), 5);
    return new Date(regDate) > ageMinus5Years;
  };

  const ShowQuestionList = () => {
    const size =
      loginType === 'Company'
        ? companyDataList && companyDataList.CompanySizeType
        : applicantData && applicantData.CompanySizeType;
    const socialBusiness = loanInfo && loanInfo.LoanSocialBusiness;
    const companyRegistrationDate =
      companyDataList && companyDataList.CompanyRegistrationDate;
    const individualRegistrationData =
      applicantData &&
      applicantData.IndividualActivityCertificateNumberStartDate;
    if (size == 1 || size == 2) {
      if (
        (socialBusiness == 1 || socialBusiness == 2 || socialBusiness == 3) &&
        ageLessThen5(
          loginType === 'Company'
            ? companyRegistrationDate
            : individualRegistrationData
        )
      ) {
        return true;
      }
    }

    return false;
  };

  const questionListArray = [
    'IsSmall',
    'IsNotListed',
    'IsProfitNotShared',
    'IsNotJoined',
    'IsNotTaken',
    'IsNotProblemic',
  ];

  const setFAcolor = (submitted, year, fsiYear, FsiYear20XX) => {
    if (year === fsiYear || year === FsiYear20XX) {
      return submitted ? 'green' : 'red';
    }

    return '#033878';
  };

  const fsiTranslate = (fsiSubmitted, blue) => {
    if (blue) {
      return fsiSubmitted ? 'Taip' : 'Netaikoma';
    }

    return fsiSubmitted ? 'Taip' : 'Ne';
  };

  const returnCountryNameById = (id, x) => {
    const find = formatCountryJson.find((val) => val.value == id);
    if (x) {
      return find ? find?.label : id;
    }
    return find ? find[0]?.label : '-';
  };

  const [administrationInvoices, setAdministrationInvoices] = useState([]);
  const administrationInvoicesTableHeader = [
    'Sąskaitos numeris',
    'Failo pavadinimas',
    'Sukūrimo data ',
    'Paskolos paruošimo mokestis, Eur',
    'Būsena',
    'Veiksmai',
  ];

  const GetAdministrationInvoices = async () => {
    try {
      const response = await Api.GetAdministrationInvoices(requestId);
      setAdministrationInvoices(response);
    } catch (e) {
      // errorHandler(e, e?.response?.status, e?.response && e?.response?.data?.ErrorMessage, t('Ok'));
    } finally {
      // setLoadingStatus(false);
    }
  };

  useEffect(() => {
    GetAdministrationInvoices();
  }, [requestId]);

  const getContractName = (id) => {
    return `Sąskaita_${id}.pdf`;
  };

  const downloadAllFiles = async () => {
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.DownloadAllFiles(requestId), {
      method: 'GET',
      headers: {
        'content-Type': 'application/zip',
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Visi_priedai_${requestId}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .finally(() => {
        setIsloading(false);
      });
    // dispatch(actions.backoffice.downloadAllFilesAction(requestId));
  };

  const DownloadAdministrationInvoice = async (contractId, contractNumber) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadAdministrationInvoice(contractId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = getContractName(contractNumber);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const DownloadChangeConditionsInvoice = async (
    contractId,
    contractNumber
  ) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.DownloadChangeConditionsInvoice(contractId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = getContractName(contractNumber);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const [selfDeclarationData, setSelfDeclarationtData] = useState(null);

  const addExclamation = () => {
    return (
      <FontAwesomeIcon
        icon={faExclamation}
        style={{
          color: '#ff0000',
          marginRight: '5px',
        }}
      />
    );
  };

  const editAutorization = (id) => {
    dispatch(actions.backoffice.setSaveRepresentativeError({}));
    const row = requestsState?.representatives.find((r) => r.Id === id);

    row.RepresentativeType = row.RepresentedPersonalCode
      ? 'Personal'
      : 'Company';

    dispatch(actions.backoffice.setRepresentativeAction(row));

    const open = {
      modalName: 'autorizationModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const downloadFile = (id, fileId) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.downloadRepresentativeFileManager(id, fileId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Igaliojimas_Nr_${fileId}.pdf`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  const actionClick = (representativeId) => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalText('Ar tikrai norite ištrinti?');
    setModalData({ representativeId });
    setModalAction('DELETE_REPRESENTATIVE_MANAGER');
    setModalTitle('');

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const checkTypeIdTranslate = (id) => {
    switch (id) {
      case 1:
        return 'Pareiškėjas';
      case 2:
        return 'Pareiškėjas';
      case 3:
        return 'Kontroliuojanti įmonė';
      case 4:
        return 'Kontroliuojantis asmuo';
      case 5:
        return 'Tiekėjas';
      case 6:
        return 'Įmonės vadovas';
        case 7:
          return 'Viešas pareigas einantis asmuo';
          case 8:
            return 'Pirkėjas';
      default:
        return id;
    }
  };
  const changeContractModalOpen = () => {
    const open = {
      modalName: 'changeConditionsBoModal',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const confirmRequestNew = () => {
    const open = {
      modalName: 'confirmRequestNew',
      visible: true,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const changeConditionsModalOpen = () => {
    const hasChangeCOnditionsDoc = signInInfo.some(
      (val) => val.FileType === 'ChangeConditions'
    );

    const hasChangeConditionsRequestDoc = signInInfo.some(
      (val) => val.FileType === 'ChangeConditionsRequest'
    );
    if (!hasChangeCOnditionsDoc && !hasChangeConditionsRequestDoc) {
      swal({
        title: 'Neprisegtas sąlygų keitimo dokumentas',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
    } else {
      const open = {
        modalName: 'submitConditionsChangeRequestModal',
        visible: true,
      };

      dispatch(actions.modals.setModalVisibleFlagAction(open));
    }
  };

  const returnChangeConditionsModalOpen = () => {
    const hasChangeCOnditionsDoc = signInInfo.some(
      (val) => val.FileType === 'ChangeConditions'
    );
    const hasChangeConditionsRequestDoc = signInInfo.some(
      (val) => val.FileType === 'ChangeConditionsRequest'
    );
    if (!hasChangeCOnditionsDoc && !hasChangeConditionsRequestDoc) {
      swal({
        title: 'Neprisegtas sąlygų keitimo dokumentas',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn btn-pink',
            closeModal: true,
          },
        },
      });
    } else {
      const open = {
        modalName: 'returnChangeConditionsModal',
        visible: true,
      };

      dispatch(actions.modals.setModalVisibleFlagAction(open));
    }
  };

  const [openedMessageText, setOpenedMessagetext] = useState('');

  const openMessageModal = (message) => {
    setOpenedMessagetext(message);
    const open = {
      modalName: 'globalMessageModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
    return message;
  };

  const messageShow = (message) => {
    const trimmedMessage = message.slice(0, 50);

    if (message.length < 50) {
      return message;
    }

    return (
      <span>
        {trimmedMessage}
        <span> </span>
        <span
          className="show-more-span"
          onClick={() => openMessageModal(message)}
        >
          ... [Rodyti daugiau]
        </span>
      </span>
    );
  };

  const showChangeFile = (val) => {
    switch (val) {
      case 'ChangeConditionsRequest':
        return true;
      case 'ChangeConditions':
        return true;
      case 'ChangeConditionsReject':
        return true;
      case 'ChangeConditionsDecision':
        return true;
      default:
        return false;
    }
  };

  const changeFile = (id) => {
    document.getElementById(id).click();
  };

  const acceptFiles = () => {
    return '.xlsx, .xls, .doc, .docx, .xml, .txt, .pdf';
  };

  const selectFile = (e, id) => {
    setIsloading(true);

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('File', file);
    formData.append('RequestId', requestId);
    formData.append('FileId', id);

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.ChangeChangeConditionsFile, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));

          swal({
            title: 'Priedas sėkmingai pakeistas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          res.json().then((r) => {
            let title = 'Nepavyko pateikti priedo';
            if (r.errors) {
              title = r.errors['Files[0].FileName'][0];
            } else if (r.ErrorDescription) {
              title = r.ErrorDescription;
            }
            swal({
              title: title,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const onAmlPepNoteChange = (id, newNote) => {
    const updatedResults = riskAssessmentResults.map((result) => {
      if (result.Id === id) {
        return {
          ...result,
          Note: newNote,
        };
      } else {
        return result;
      }
    });

    setRiskAssessmentResults(updatedResults);
  };

  const onAmlPepResultIdChange = (id, resultId) => {
    const updatedResults = riskAssessmentResults.map((result) => {
      if (result.Id === id) {
        return {
          ...result,
          ResultId: resultId === 0 ? null : resultId,
        };
      } else {
        return result;
      }
    });

    setRiskAssessmentResults(updatedResults);
  };

  const onAmlPepDateChange = (id, newDate) => {
    const formattedData = format(new Date(newDate), 'yyyy-MM-dd');
    const updatedResults = riskAssessmentResults.map((result) => {
      if (result.Id === id) {
        return {
          ...result,
          Date: formattedData,
        };
      } else {
        return result;
      }
    });

    setRiskAssessmentResults(updatedResults);
  };

  const saveRiskLevel = async () => {
    try {
      await Api.ManagerSaveRiskLevel(requestId, riskLevel);
      setEditRiskTable(false);
      swal({
        title: `AML rizikos grupė sėkmingai pakeista`,
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } catch (e) {
      if (e.statusCode === 403) {
        swal({
          title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      } else
        swal({
          title: `Klaida`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
    } finally {
      // setLoadingStatus(false);
    }
  };

  const [additionalRequestsLoading, setAdditionalRequestsLoading] =
    useState(false);
  const additionalJadisRequest = async () => {
    try {
      setAdditionalRequestsLoading(true);
      await Api.RecheckJadis(requestId);
      swal({
        title: `Papildoma JADIS užklausa sėkmingai atlikta`,
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
      dispatch(actions.backoffice.getRequestAction(requestId));
    } catch (e) {
      if (e.statusCode === 403) {
        swal({
          title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      } else
        swal({
          title: `Klaida`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
    } finally {
      setAdditionalRequestsLoading(false);
    }
  };

  const additionalAMLRequest = async () => {
    try {
      setAdditionalRequestsLoading(true);
      await Api.RecheckAml(requestId);
      swal({
        title: `Papildoma AML užklausa sėkmingai atlikta`,
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
      dispatch(actions.backoffice.getRequestAction(requestId));
    } catch (e) {
      if (e.statusCode === 403) {
        swal({
          title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      } else
        swal({
          title: `Klaida`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
    } finally {
      setAdditionalRequestsLoading(false);
    }
  };
  const saveAmlPepAdditionalData = async () => {
    const payload = {
      requestId,
      riskAssessmentEntries: riskAssessmentResults,
    };
    try {
      await Api.ManagerSaveRiskAssessmentResult(payload);
      setEditAmlPepTable(false);
      swal({
        title: `Patikros duomenys sėkmingai atnaujinti`,
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } catch (e) {
      if (e.statusCode === 403) {
        swal({
          title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      } else
        swal({
          title: `Klaida`,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
    } finally {
      // setLoadingStatus(false);
    }
  };

  const getOptionClassName = (optionRiskLevel) => {
    if (riskLevel === optionRiskLevel) {
      return riskLevel === 3
        ? styles.greenInput
        : riskLevel === 2
        ? styles.yellowInput
        : styles.redInput;
    }
    return '';
  };

  const returnRiskLevelClass = (val) => {
    switch (val) {
      case 1:
        return styles.redInput;
      case 2:
        return styles.yellowInput;
      case 3:
        return styles.greenInput;
      default:
        return styles.greenInput;
    }
  };

  const checkIf2022FinancesToShow = () => {
    if (requestsState.data.RequestSystemInfo?.FirstSubmittedDate) {
      const checkDate = new Date('2023-05-31');
      const now = new Date(
        requestsState.data.RequestSystemInfo?.FirstSubmittedDate
      );
      if (now > checkDate) {
        return true;
      }
    }

    return false;
  };

  const isNumberType = (val) => {
    if (
      val === 'MonthlyRentalPrice' ||
      val === 'RentedArea' ||
      val === 'OwnerCompanyCode' ||
      val === 'AlreadyReceivedCovidSupportSum' ||
      val === 'OwnerPersonalCode' ||
      val === 'OwnerPhone'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const returnYesNO = (val) => {
    if (val === true) {
      return 'Taip';
    }

    if (val === false) {
      return 'Ne';
    }

    return '';
  };

  const findInvegaSigningDate = () => {
    if (signInInfo) {
      const contract = signInInfo?.find((val) => val.FileType === 'Contract');
      return contract ? contract?.InvegaSigningDate : '';
    }
    return '';
  };

  return (
    <WithSpinner
      loading={
        requestsState.loading === true || loading === true ? true : false
      }
    >
      <div
        className="container"
        style={{ backgroundColor: '#fff', marginTop: '4rem' }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.requestBlockAll}>
              <div className="row d-flex justify-content-between">
                <h1>Paraiška</h1>
                {checkBOPage() && (
                  <div
                    className="col-lg-3 col-sm-12"
                    style={{ textAlign: 'right' }}
                  >
                    <Link
                      to="/backoffice/main"
                      style={{
                        color: '#fff',
                        textDecoration: 'none',
                      }}
                    >
                      <Button type="standart">Paraiškų sąrašas</Button>
                    </Link>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h4>Paraiškos duomenys</h4>
                  {requestSystemInfo != null &&
                    requestSystemInfo.RequestId != null && (
                      <div className="row director-data-margin">
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            type="string"
                            value={requestSystemInfo.RequestId}
                            placeholder={'Paraiškos numeris'}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            type="string"
                            value={format(
                              new Date(requestSystemInfo.Created),
                              'yyyy-MM-dd HH:mm:ss'
                            )}
                            placeholder={'Paraiškos sukūrimo data'}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            type="string"
                            value={requestStatus(requestSystemInfo.Status)}
                            placeholder={'Paraiškos būsena'}
                            disabled={true}
                          />
                        </div>
                      </div>
                    )}

                  <h4>Kontaktinė informacija</h4>
                  {ceoDataList != null &&
                    requestsState.data.ContactInfo != null && (
                      <div className="row director-data-margin">
                        {ceoDataListArray.map((key, i) => (
                          <div className="col-lg-6 col-sm-12" key={i}>
                            <InputWithTextInline
                              name={key}
                              type={'string'}
                              value={ceoDataList[key]}
                              placeholder={
                                loginType === 'Company'
                                  ? requestDataLabelTranslation(key)
                                  : applicantTranslation(key)
                              }
                              disabled={true}
                            />
                          </div>
                        ))}

                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name={'ApplicantEmail'}
                            type={'string'}
                            value={ceoDataList['ApplicantEmail']}
                            placeholder={
                              loginType === 'Company'
                                ? requestDataLabelTranslation('ApplicantEmail')
                                : applicantTranslation('ApplicantEmail')
                            }
                            disabled={true}
                          />
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <InputWithTextInline
                            name={'ApplicantPhone'}
                            type={'string'}
                            value={ceoDataList['ApplicantPhone']}
                            placeholder={
                              loginType === 'Company'
                                ? requestDataLabelTranslation('ApplicantPhone')
                                : applicantTranslation('ApplicantPhone')
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                    )}

                  {/* {Company data andly for JA} */}
                  {loginType === 'Company' && (
                    <>
                      <h4>Duomenys apie Verslo subjektą </h4>
                      {companyDataList != null &&
                        requestsState.data.CompanyData != null && (
                          <div className="row director-data-margin">
                            {companyDataListArray.map((key, i) => {
                              if (isCheckBox(key) === true) {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <div className="checkbox">
                                      <label className="containerLabel noBlock">
                                        <input
                                          type="checkbox"
                                          name={key}
                                          checked={companyDataList[key]}
                                          disabled={viewWindow}
                                        />
                                        <span className="checkmark"></span>
                                        {requestDataLabelTranslation(key)}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else if (key === 'CompanyEvrk') {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'CompanyEvrk'
                                      )}
                                    </label>
                                    <select
                                      name="CompanyEvrk"
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                      }}
                                    >
                                      {!companyDataList.CompanyEvrk && (
                                        <option
                                          selected={
                                            companyDataList.CompanyEvrk === null
                                          }
                                          value={null}
                                        >
                                          -
                                        </option>
                                      )}
                                      {companyDataList.CompanyEvrk &&
                                        isSelectedEvrkInTheList(
                                          companyDataList.CompanyEvrk
                                        ) && (
                                          <option
                                            selected={
                                              companyDataList.CompanyEvrk
                                            }
                                            value={companyDataList.CompanyEvrk}
                                          >
                                            {`${
                                              companyDataList.CompanyEvrk
                                            } - ${
                                              evrkFull[
                                                companyDataList.CompanyEvrk
                                              ]
                                                ? evrkFull[
                                                    companyDataList.CompanyEvrk
                                                  ]
                                                : ''
                                            }`}
                                          </option>
                                        )}
                                      {formatEvrkJson2().map((val) => (
                                        <option
                                          selected={
                                            companyDataList.CompanyEvrk ==
                                            Object.keys(val)[0]
                                          }
                                          value={Object.keys(val)[0]}
                                        >
                                          {Object.keys(val)[0]} -{' '}
                                          {Object.values(val)[0]}
                                        </option>
                                      ))}
                                    </select>
                                    <p
                                      style={{
                                        color: '#597a96',
                                        marginTop: '5px',
                                      }}
                                    >
                                      *Jeigu įmonės pagrindinė veikla nesutampa
                                      su užregistruota, patikslinkite pagrindinę
                                      įmonės vykdomą veiklą VMI.
                                    </p>
                                  </div>
                                );
                              } else if (
                                key === 'CompanyManagerCitizenshipId'
                              ) {
                                return (
                                  <div
                                    className="col-lg-6 col-sm-12"
                                    style={{
                                      width: '200px',
                                    }}
                                  >
                                    <label className={styles.evrkTitle}>
                                      {' '}
                                      {requestDataLabelTranslation(
                                        'CompanyManagerCitizenship'
                                      )}
                                    </label>
                                    <select
                                      name="CompanyManagerCitizenshipId"
                                      disabled={viewWindow}
                                      style={{
                                        background: viewWindow
                                          ? 'rgb(241, 240, 241'
                                          : '#fff',
                                      }}
                                    >
                                      {companyDataList.CompanyManagerCitizenshipId ===
                                        requestsState.data.CompanyData
                                          .CompanyManagerCitizenshipId &&
                                        companyDataList.CompanyManagerCitizenshipId && (
                                          <option
                                            selected={
                                              companyDataList.CompanyManagerCitizenshipId
                                            }
                                            value={
                                              companyDataList.CompanyManagerCitizenshipId
                                            }
                                          >
                                            {returnCountryNameById(
                                              companyDataList.CompanyManagerCitizenshipId
                                            )}
                                          </option>
                                        )}

                                      <option
                                        selected={
                                          companyDataList.CompanyManagerCitizenshipId ===
                                          null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>

                                      {formatCountryJson.map((val) => (
                                        <option
                                          selected={
                                            companyDataList.CompanyManagerCitizenshipId ==
                                            val.value
                                          }
                                          value={val.value}
                                        >
                                          {val.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                );
                              } else if (
                                key === 'CompanyActivityAddress' ||
                                key === 'CompanyRegistrationAddress'
                              ) {
                                return (
                                  <AddressModal
                                    title={key}
                                    type={key.replace(/Company/gi, '')}
                                    data={companyDataList}
                                    viewWindow={viewWindow}
                                    key={i}
                                  />
                                );
                              } else {
                                return (
                                  <div className="col-lg-6 col-sm-12" key={i}>
                                    <InputWithTextInline
                                      name={key}
                                      type={'string'}
                                      value={
                                        key === 'CompanyRegistrationDate'
                                          ? companyDataList[key]
                                            ? format(
                                                new Date(companyDataList[key]),
                                                'yyyy-MM-dd'
                                              )
                                            : ''
                                          : key === 'CompanySizeType'
                                          ? companySize(companyDataList[key])
                                          : companyDataList[key]
                                      }
                                      placeholder={requestDataLabelTranslation(
                                        key
                                      )}
                                      disabled={true}
                                    />
                                  </div>
                                );
                              }
                            })}

                            <div className="col-lg-6 col-sm-12">
                              <label
                                style={{
                                  margin: '8px 0 8px',
                                  color: '#597a96',
                                  fontSize: '0.8rem',
                                  lineHeight: '1.5',
                                }}
                              >
                                Verslo subjekto statusas
                              </label>
                              <select
                                disabled={viewWindow}
                                name="CompanySizeType"
                                style={{
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  // color: '#2e2d2d',
                                }}
                                value={companyDataList.CompanySizeType}
                              >
                                <option value={null}>{null}</option>
                                <option value={'1'}>Labai maža įmonė</option>
                                <option value={'2'}>Maža įmonė</option>
                                <option value={'3'}>Vidutinė įmonė</option>
                                <option value={'4'}>Didelė įmonė</option>
                              </select>
                            </div>

                            <div
                              className="col-lg-6 col-sm-12"
                              style={{ marginTop: '10px' }}
                            >
                              <p style={{ color: '#597a96' }}>
                                Ar Verslo subjektas, teikiantis paraišką,
                                priklauso Įmonių grupei, kaip tai nurodyta
                                teikiamoje SVV deklaracijoje?
                              </p>

                              <label
                                style={{
                                  display: 'flex',
                                  // width: '48.5%',
                                  justifyContent: 'space-between',
                                  fontSize: '0.8rem',
                                  lineHeight: '0.8rem',
                                  marginTop: '1.5rem',
                                }}
                              >
                                <select
                                  style={{
                                    height: '39px',
                                    border: '1px solid #033878',
                                    borderRadius: '5px',
                                    background: viewWindow
                                      ? 'rgb(241, 240, 241'
                                      : '#fff',
                                    // color: '#2e2d2d',
                                  }}
                                >
                                  <option
                                    value={false}
                                    selected={
                                      companyDataList &&
                                      (!companyDataList.ParentCompanyCodesFromRequest ||
                                        companyDataList
                                          .ParentCompanyCodesFromRequest
                                          .length == 0)
                                    }
                                  >
                                    Ne
                                  </option>
                                  <option
                                    value={true}
                                    selected={
                                      companyDataList &&
                                      companyDataList.ParentCompanyCodesFromRequest &&
                                      companyDataList
                                        .ParentCompanyCodesFromRequest.length >
                                        0
                                    }
                                  >
                                    Taip
                                  </option>
                                </select>
                              </label>
                            </div>

                            <div className="col-lg-6 col-sm-12">
                              <p
                                className="text-style-new-request"
                                style={{ color: '#597a96' }}
                              >
                                Nurodykite visus Įmonių grupei priklausančių
                                įmonių kodus, kurie nurodyti jūsų užpildytoje ir
                                teikiamoje SVV subjekto statuso deklaracijoje.
                                Jei esate didelė įmonė, nurodykite visus Įmonių
                                grupei priklausančių įmonių kodus. Pvz.
                                111111111, 222222222
                              </p>
                              <Input
                                style={{ color: '#747474' }}
                                name="ParentCompanyCodesFromRequest"
                                type="string"
                                disabled={viewWindow || !hasParentCompanyValue}
                                value={
                                  companyDataList &&
                                  companyDataList.ParentCompanyCodesFromRequest
                                    ? companyDataList.ParentCompanyCodesFromRequest
                                    : ''
                                }
                              />
                            </div>

                            <div className="col-lg-6 col-sm-12">
                              <div className="checkbox">
                                <label
                                  className="containerLabel noBlock"
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  <input
                                    type="checkbox"
                                    name={'CheckCompanyAge'}
                                    checked={companyDataList['CheckCompanyAge']}
                                    disabled={viewWindow}
                                  />
                                  <span className="checkmark"></span>
                                  {requestDataLabelTranslation(
                                    'CheckCompanyAge'
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
                {/*{loginType === 'Company' && (
                <div style={{ width: '48.5%', marginLeft:'15px', paddingBottom:'15px' }}>
                  <div className={styles.inlineContainer}>
                    <div className={styles.titleContainer}>
                      <p>{rentContractTranslation('ControllingCompanies')}</p>
                      <p>
                        Jadis pateikti akcininkai:{' '}
                        {requestsState.data.ControllingCompaniesFromRegister !=
                        null
                          ? requestsState.data.ControllingCompaniesFromRegister
                          : 'nėra pateiktų akcininkų'}
                      </p>
                    </div>
                    <input
                      className={styles.inlineInput}
                      style={{
                        background: viewWindow && '#f1f0f1',
                      }}
                      name={'ControllingCompanies'}
                      type={'string'}
                      value={controllingCompanies}
                      disabled={viewWindow}
                    />

                    <InputWithTextInline
                      name={'ControllingForeignCompanies'}
                      type="text"
                      value={controllingForeignCompanies}
                      placeholder={rentContractTranslation(
                        'ControllingForeignCompanies'
                      )}
                      disabled={true}
                    />
                    <p style={{ color: '#597a96' }}>
                      *Kontrolė suprantama, kai įmonių tarpusavio santykiai yra
                      bent vienos rūšies iš toliau išvardintų: <br />
                      a) viena įmonė turi kitos įmonės akcininkų arba narių
                      balsų daugumą; <br />
                      b) viena įmonė turi teisę paskirti arba atleisti daugumą
                      kitos įmonės administracijos, valdymo arba priežiūros
                      organo narių; <br />
                      c) pagal sutartį arba vadovaujantis steigimo sutarties ar
                      įstatų nuostata vienai įmonei suteikiama teisė daryti
                      kitai įmonei lemiamą įtaką; <br />
                      d) viena įmonė, būdama kitos įmonės akcininkė arba narė,
                      vadovaudamasi su tos įmonės kitais akcininkais ar nariais
                      sudaryta sutartimi, viena.
                    </p>
                  </div>
                </div>
              )}*/}

                {loginType === 'Company' && (
                  <div className="row" style={{ marginLeft: '5px' }}>
                    <div className="col-lg-6 col-sm-12">
                      <div className={styles.inlineContainer}>
                        <div className={styles.titleContainer}>
                          <p>
                            {rentContractTranslation('ControllingCompanies')}
                          </p>
                          <p>
                            Jadis pateikti akcininkai:{' '}
                            {requestsState.data
                              .ControllingCompaniesFromRegister != null
                              ? requestsState.data
                                  .ControllingCompaniesFromRegister
                              : 'nėra pateiktų akcininkų'}
                          </p>
                        </div>
                        <input
                          className={styles.inlineInput}
                          style={{
                            background: viewWindow && '#f1f0f1',
                          }}
                          name={'ControllingCompanies'}
                          type={'string'}
                          value={controllingCompanies}
                          disabled={viewWindow}
                        />
                        {/* {notValid && (
                        <p
                          className="errorsBlockText error1"
                          style={{ color: 'var(--pinkDark)' }}
                        >
                          {controllingCompaniesInputValidation(
                            controllingCompanies
                          )}
                        </p>
                      )} */}
                      </div>
                    </div>{' '}
                    <div className="col-lg-6 col-sm-12">
                      <InputWithTextInline
                        name={'ControllingForeignCompanies'}
                        type="text"
                        value={controllingForeignCompanies}
                        placeholder={rentContractTranslation(
                          'ControllingForeignCompanies'
                        )}
                        disabled={true}
                      />
                      <p style={{ color: '#597a96' }}>
                        *Kontrolė suprantama, kai įmonių tarpusavio santykiai
                        yra bent vienos rūšies iš toliau išvardintų: <br />
                        a) viena įmonė turi kitos įmonės akcininkų arba narių
                        balsų daugumą; <br />
                        b) viena įmonė turi teisę paskirti arba atleisti daugumą
                        kitos įmonės administracijos, valdymo arba priežiūros
                        organo narių; <br />
                        c) pagal sutartį arba vadovaujantis steigimo sutarties
                        ar įstatų nuostata vienai įmonei suteikiama teisė daryti
                        kitai įmonei lemiamą įtaką; <br />
                        d) viena įmonė, būdama kitos įmonės akcininkė arba narė,
                        vadovaudamasi su tos įmonės kitais akcininkais ar
                        nariais sudaryta sutartimi, viena.
                      </p>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <InputWithTextInline
                        name={'NumberOfEmployees'}
                        type="text"
                        value={
                          requestsState?.data?.CompanyData?.NumberOfEmployees ??
                          '-'
                        }
                        placeholder={rentContractTranslation(
                          'NumberOfEmployees'
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                )}
                <br/>

                {/* {Company data for JA} */}
                {loginType === 'Company' && (
                    <>
                      <div className="col-lg-12 col-sm-12">
                        {companyDataList != null &&
                          requestData.FinancialData &&
                          requestData.RequestSystemInfo && (
                            <>
                              <h4>Finansiniai duomenys</h4>
                              {requestData.RequestSystemInfo.Status !== 1 &&
                                requestData.RequestSystemInfo.Status !== 6 && (
                                  <>
                                    <div
                                      className={styles.titleContainer}
                                      style={{ marginTop: '16px' }}
                                    ></div>
                                    <FinancialTable
                                      data={requestsState.data?.FinancialData}
                                      regDate={
                                        requestsState?.data?.CompanyData
                                          ?.CompanyRegistrationDate
                                      }
                                    />
                                  </>
                                )}
                            </>
                          )}
                      </div>
                    </>
                  )}
                {/*{loginType === 'Company' && (
                  <>
                    <div className="col-lg-12 col-sm-12">
                      {companyDataList != null &&
                        requestsState.data.FinancialData &&
                        requestsState.data.RequestSystemInfo && (
                          <>
                            {requestsState.data.RequestSystemInfo.Status !==
                              1 &&
                              requestsState.data.RequestSystemInfo.Status !==
                                6 && (
                                <h4 style={{ marginTop: '3rem' }}>
                                  Finansiniai duomenys{' '}
                                </h4>
                              )}
                            {requestsState.data.RequestSystemInfo.Status !==
                              1 &&
                              requestsState.data.RequestSystemInfo.Status !==
                                6 && (
                                <div
                                  className={styles.titleContainer}
                                  style={{ marginTop: '16px' }}
                                >
                                  <p style={{ fontSize: '16px' }}>
                                    Finansinių atskaitomybių informacija
                                  </p>
                                </div>
                              )}
                            {!checkIf2022FinancesToShow() && (
                              <div className="row">
                                {requestsState.data.RequestSystemInfo.Status !==
                                  1 &&
                                  requestsState.data.RequestSystemInfo
                                    .Status !== 6 &&
                                  financialDataArray(
                                    companyQuestionList.FsiYear
                                  ).map((key, i) => {
                                    if (
                                      key === 'Fsi2022Submitted' ||
                                      key === 'Fsi2021Submitted' ||
                                      key === 'Fsi2020Submitted' ||
                                      key === 'Fsi2019Submitted'
                                    ) {
                                      return (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={i}
                                        >
                                          <label
                                            style={{
                                              margin: '8px 0 8px',
                                              color: '#597a96',
                                              fontSize: '0.8rem',
                                              lineHeight: '1.5',
                                            }}
                                          >
                                            {requestDataLabelTranslation(key)}
                                          </label>
                                          <select
                                            disabled={true}
                                            name="Fsi2022Submitted"
                                            style={{
                                              background: true
                                                ? 'rgb(241, 240, 241'
                                                : '#fff',
                                              // color: '#2e2d2d',
                                            }}
                                            value={
                                              companyQuestionList.FsiYear ==
                                              2022
                                                ? companyQuestionList.Fsi2022Submitted
                                                : companyQuestionList.FsiYear ==
                                                  2021
                                                ? companyQuestionList.Fsi2021Submitted
                                                : companyQuestionList.FsiYear ==
                                                  2019
                                                ? companyQuestionList.Fsi2019Submitted
                                                : companyQuestionList.Fsi2020Submitted
                                            }
                                          >
                                            <option value={null}>Ne</option>
                                            <option value={true}>Taip</option>
                                            <option value={false}>Ne</option>
                                          </select>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={i}
                                        >
                                          <InputWithTextInline
                                            name={key}
                                            type={'string'}
                                            value={
                                              key === 'FsiEndDate' ||
                                              key === 'FsiStartDate' ||
                                              key === 'FsiEndDate2019' ||
                                              key === 'FsiStartDate2019' ||
                                              key === 'FsiEndDate2020' ||
                                              key === 'FsiStartDate2020' ||
                                              key === 'FsiEndDate2021' ||
                                              key === 'FsiStartDate2021' ||
                                              key === 'FsiEndDate2022' ||
                                              key === 'FsiStartDate2022' ||
                                              key === 'FsiEndDate2023' ||
                                              key === 'FsiStartDate2023'
                                                ? companyQuestionList[key] &&
                                                  format(
                                                    new Date(
                                                      companyQuestionList[key]
                                                    ),
                                                    'yyyy-MM-dd'
                                                  )
                                                : formatNumber(
                                                    companyQuestionList[key]
                                                  )
                                            }
                                            placeholder={requestDataLabelTranslation(
                                              key
                                            )}
                                            disabled={true}
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            )}

                            {checkIf2022FinancesToShow() &&
                              companyQuestionList?.Fsi2022Submitted && (
                                <>
                                  <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                    2022m. finansinės atskaitomybės duomenys{' '}
                                  </h4>
                                  <div className="row">
                                    {
                                      // requestData.RequestSystemInfo.Status !== 1 &&
                                      // requestData.RequestSystemInfo.Status !== 6 &&
                                      financialDataArray2022.map((key, i) => {
                                        if (key === 'Fsi2022Submitted') {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2022Submitted'
                                                    ? companyQuestionList.Fsi2022Submitted
                                                    : companyQuestionList.Fsi2021Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else if (key === 'br') {
                                          return (
                                            <p style={{ visibility: 'hidden' }}>
                                              /
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate' ||
                                                  key === 'FsiStartDate' ||
                                                  key === 'FsiEndDate2019' ||
                                                  key === 'FsiStartDate2019' ||
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020' ||
                                                  key === 'FsiEndDate2021' ||
                                                  key === 'FsiStartDate2021' ||
                                                  key === 'FsiEndDate2022' ||
                                                  key === 'FsiStartDate2022' ||
                                                  key === 'FsiEndDate2023' ||
                                                  key === 'FsiStartDate2023'
                                                    ? companyQuestionList[
                                                        key
                                                      ] &&
                                                      format(
                                                        new Date(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        ),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(
                                                        companyQuestionList[key]
                                                      )
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={true}
                                              />
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                  </div>
                                </>
                              )}

                            {checkIf2022FinancesToShow() &&
                              companyQuestionList?.Fsi2021Submitted && (
                                <>
                                  <h4 style={{ margin: '15px 0px 0px 0px' }}>
                                    2021m. finansinės atskaitomybės duomenys{' '}
                                  </h4>
                                  <div className="row">
                                    {
                                      // requestData.RequestSystemInfo.Status !== 1 &&
                                      // requestData.RequestSystemInfo.Status !== 6 &&
                                      financialDataArray2021.map((key, i) => {
                                        if (key === 'Fsi2021Submitted') {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  companyQuestionList.Fsi2021Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else if (key === 'br') {
                                          return (
                                            <p style={{ visibility: 'hidden' }}>
                                              /
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate' ||
                                                  key === 'FsiStartDate' ||
                                                  key === 'FsiEndDate2019' ||
                                                  key === 'FsiStartDate2019' ||
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020' ||
                                                  key === 'FsiEndDate2021' ||
                                                  key === 'FsiStartDate2021' ||
                                                  key === 'FsiEndDate2022' ||
                                                  key === 'FsiStartDate2022' ||
                                                  key === 'FsiEndDate2023' ||
                                                  key === 'FsiStartDate2023'
                                                    ? companyQuestionList[
                                                        key
                                                      ] &&
                                                      format(
                                                        new Date(
                                                          companyQuestionList[
                                                            key
                                                          ]
                                                        ),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(
                                                        companyQuestionList[key]
                                                      )
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled={true}
                                              />
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                  </div>
                                </>
                              )}
                          </>
                        )}
                        
                    </div>
                  </>
                )}*/}

            

                {/* {Person data data for FA} */}
                {loginType === 'Individual' && (
                  <div className="col-lg-12 col-sm-12">
                    <h4>Duomenys apie verslo subjektą</h4>
                    {applicantData != null &&
                      requestsState.data.ApplicantData != null && (
                        <div className="row">
                          {applicantDataArray.map((key, i) => {
                            if (isCheckBox(key) === true) {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <div className="checkbox">
                                    <label className="containerLabel noBlock">
                                      <input
                                        type="checkbox"
                                        name={key}
                                        checked={applicantData[key]}
                                        disabled={viewWindow}
                                      />
                                      <span className="checkmark"></span>
                                      {requestDataLabelTranslation(key)}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else if (isDate(key) === true) {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <div className={styles.selectflex}>
                                    <label className="inlineDatepickerInputTitle">
                                      {requestDataLabelTranslation(key)}
                                    </label>
                                    <DatePicker
                                      className="inlineDatepickerInput"
                                      selected={
                                        applicantData[key] != null
                                          ? new Date(applicantData[key])
                                          : ''
                                      }
                                      maxDate={
                                        key ===
                                        'IndividualActivityCertificateNumberStartDate'
                                          ? new Date()
                                          : null
                                      }
                                      // minDate={
                                      //   key ===
                                      //   'IndividualActivityCertificateNumberStartDate'
                                      //     ? new Date('2020-03-16')
                                      //     : null
                                      // }
                                      dateFormat="yyyy-MM-dd"
                                      disabled={viewWindow}
                                    />
                                    <span className={styles.clearDateButton}>
                                      X
                                    </span>
                                  </div>
                                </div>
                              );
                            } else if (key === 'CompanyEvrk') {
                              return (
                                <div
                                  className="col-lg-6 col-sm-12"
                                  style={{
                                    width: '200px',
                                  }}
                                >
                                  <label className={styles.evrkTitle}>
                                    {' '}
                                    {requestDataLabelTranslation(
                                      'IndividualEvrk'
                                    )}
                                  </label>
                                  <select
                                    name="CompanyEvrk"
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                    }}
                                  >
                                    {!applicantData.CompanyEvrk && (
                                      <option
                                        selected={
                                          applicantData.CompanyEvrk === null
                                        }
                                        value={null}
                                      >
                                        -
                                      </option>
                                    )}

                                    {applicantData.CompanyEvrk &&
                                      isSelectedEvrkInTheList(
                                        applicantData.CompanyEvrk
                                      ) && (
                                        <option
                                          selected={applicantData.CompanyEvrk}
                                          value={applicantData.CompanyEvrk}
                                        >
                                          {`${applicantData.CompanyEvrk} - ${
                                            evrkFull[applicantData.CompanyEvrk]
                                              ? evrkFull[
                                                  applicantData.CompanyEvrk
                                                ]
                                              : applicantData.CompanyEvrk
                                          }`}
                                        </option>
                                      )}

                                    {formatEvrkJson2().map((val) => (
                                      <option
                                        selected={
                                          applicantData.CompanyEvrk ==
                                          Object.keys(val)[0]
                                        }
                                        value={Object.keys(val)[0]}
                                      >
                                        {Object.keys(val)[0]} -{' '}
                                        {Object.values(val)[0]}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              );
                            } else if (key === 'CompanyManagerCitizenshipId') {
                              return (
                                <div
                                  className="col-lg-6 col-sm-12"
                                  style={{
                                    width: '200px',
                                  }}
                                >
                                  <label className={styles.evrkTitle}>
                                    {' '}
                                    {requestDataLabelTranslation(
                                      'CompanyManagerCitizenship'
                                    )}
                                  </label>
                                  <select
                                    name="CompanyManagerCitizenshipId"
                                    disabled={viewWindow}
                                    style={{
                                      background: viewWindow
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                    }}
                                  >
                                    {companyDataList.CompanyManagerCitizenshipId ===
                                      requestsState.data.CompanyData
                                        .CompanyManagerCitizenshipId &&
                                      companyDataList.CompanyManagerCitizenshipId && (
                                        <option
                                          selected={
                                            companyDataList.CompanyManagerCitizenshipId
                                          }
                                          value={
                                            companyDataList.CompanyManagerCitizenshipId
                                          }
                                        >
                                          {returnCountryNameById(
                                            companyDataList.CompanyManagerCitizenshipId
                                          )}
                                        </option>
                                      )}

                                    <option
                                      selected={
                                        companyDataList.CompanyManagerCitizenshipId ===
                                        null
                                      }
                                      value={null}
                                    >
                                      -
                                    </option>

                                    {formatCountryJson.map((val) => (
                                      <option
                                        selected={
                                          companyDataList.CompanyManagerCitizenshipId ==
                                          val.value
                                        }
                                        value={val.value}
                                      >
                                        {val.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              );
                            } else if (
                              key === 'CompanyActivityAddress' ||
                              key === 'CompanyRegistrationAddress'
                            ) {
                              return (
                                <AddressModal
                                  title={key}
                                  type={key.replace(/Company/gi, '')}
                                  data={applicantData}
                                  viewWindow={viewWindow}
                                  key={i}
                                />
                              );
                            } else {
                              return (
                                <div className="col-lg-6 col-sm-12" key={i}>
                                  <InputWithTextInline
                                    name={key}
                                    type={'string'}
                                    value={applicantData[key]}
                                    placeholder={
                                      key === 'CompanyManagerName'
                                        ? 'Verslininko vardas ir pavardė'
                                        : requestDataLabelTranslation(key)
                                    }
                                    disabled={true}
                                  />
                                </div>
                              );
                            }
                          })}

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'CompanyPhone'}
                              type={'string'}
                              value={applicantData['CompanyPhone']}
                              placeholder={requestDataLabelTranslation(
                                'CompanyPhone'
                              )}
                              disabled={true}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'CompanyEmail'}
                              type={'string'}
                              value={applicantData['CompanyEmail']}
                              placeholder={requestDataLabelTranslation(
                                'CompanyEmail'
                              )}
                              disabled={true}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'ContractEmail'}
                              type={'string'}
                              value={applicantData['ContractEmail']}
                              placeholder={requestDataLabelTranslation(
                                'ContractEmail'
                              )}
                              disabled={true}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              name={'Iban'}
                              type={'string'}
                              value={applicantData['Iban']}
                              placeholder={requestDataLabelTranslation('Iban')}
                              disabled={true}
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <label
                              style={{
                                margin: '8px 0 8px',
                                color: '#597a96',
                                fontSize: '0.8rem',
                                lineHeight: '1.5',
                              }}
                            >
                              Verslo subjekto statusas
                            </label>
                            <select
                              disabled={viewWindow}
                              name="CompanySizeType"
                              style={{
                                background: viewWindow
                                  ? 'rgb(241, 240, 241'
                                  : '#fff',
                                // color: '#2e2d2d',
                              }}
                              value={applicantData.CompanySizeType}
                            >
                              <option value={null}>{null}</option>
                              <option value={'1'}>Labai maža įmonė</option>
                              <option value={'2'}>Maža įmonė</option>
                              <option value={'3'}>Vidutinė įmonė</option>
                              <option value={'4'}>Didelė įmonė</option>
                            </select>
                          </div>

                          <div
                            className="col-lg-6 col-sm-12"
                            style={{ marginTop: '10px' }}
                          >
                            <p style={{ color: '#597a96' }}>
                              Ar Verslo subjektas, teikiantis paraišką,
                              priklauso Įmonių grupei, kaip tai nurodyta
                              teikiamoje SVV deklaracijoje?
                            </p>

                            <label
                              style={{
                                display: 'flex',
                                // width: '48.5%',
                                justifyContent: 'space-between',
                                fontSize: '0.8rem',
                                lineHeight: '0.8rem',
                                marginTop: '1.5rem',
                              }}
                            >
                              <select
                                disabled={viewWindow}
                                style={{
                                  height: '39px',
                                  border: '1px solid #033878',
                                  borderRadius: '5px',
                                  background: viewWindow
                                    ? 'rgb(241, 240, 241'
                                    : '#fff',
                                  // color: '#2e2d2d',
                                }}
                              >
                                <option
                                  value={false}
                                  selected={
                                    applicantData &&
                                    (!applicantData.ParentCompanyCodesFromRequest ||
                                      applicantData
                                        .ParentCompanyCodesFromRequest.length ==
                                        0)
                                  }
                                >
                                  Ne
                                </option>
                                <option
                                  value={true}
                                  selected={
                                    applicantData &&
                                    applicantData.ParentCompanyCodesFromRequest &&
                                    applicantData.ParentCompanyCodesFromRequest
                                      .length > 0
                                  }
                                >
                                  Taip
                                </option>
                              </select>
                            </label>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <p
                              className="text-style-new-request"
                              style={{ color: '#597a96' }}
                            >
                              Nurodykite visus Įmonių grupei priklausančių
                              įmonių kodus, kurie nurodyti jūsų užpildytoje ir
                              teikiamoje SVV subjekto statuso deklaracijoje. Jei
                              esate didelė įmonė, nurodykite visus Įmonių grupei
                              priklausančių įmonių kodus. Pvz. 111111111,
                              222222222.
                            </p>
                            <Input
                              style={{ color: '#747474' }}
                              name="ParentCompanyCodesFromRequest"
                              type="string"
                              disabled={viewWindow || !hasParentCompanyValue}
                              value={
                                applicantData &&
                                applicantData.ParentCompanyCodesFromRequest
                                  ? applicantData.ParentCompanyCodesFromRequest
                                  : ''
                              }
                            />
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <div className="checkbox">
                              <label className="containerLabel noBlock">
                                <input
                                  type="checkbox"
                                  name={'CheckIndividualAge'}
                                  checked={applicantData['CheckIndividualAge']}
                                  disabled={viewWindow}
                                />
                                <span className="checkmark"></span>
                                {requestDataLabelTranslation(
                                  'CheckIndividualAge'
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>

              {loginType === 'Individual' && (
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className={styles.inlineContainer}>
                      <div className={styles.titleContainer}>
                        <p>{rentContractTranslation('ControllingCompanies')}</p>
                        <p>
                          Jadis pateikti akcininkai:{' '}
                          {requestsState.data
                            .ControllingCompaniesFromRegister != null
                            ? requestsState.data
                                .ControllingCompaniesFromRegister
                            : 'nėra pateiktų akcininkų'}
                        </p>
                      </div>
                      <input
                        className={styles.inlineInput}
                        style={{
                          background: viewWindow && '#f1f0f1',
                        }}
                        name={'ControllingCompanies'}
                        type={'string'}
                        value={controllingCompanies}
                        disabled={viewWindow}
                      />
                      {/* {notValid && (
                        <p
                          className="errorsBlockText error1"
                          style={{ color: 'var(--pinkDark)' }}
                        >
                          {controllingCompaniesInputValidation(
                            controllingCompanies
                          )}
                        </p>
                      )} */}
                    </div>
                  </div>{' '}
                  <div className="col-lg-6 col-sm-12">
                    <InputWithTextInline
                      name={'ControllingForeignCompanies'}
                      type="text"
                      value={controllingForeignCompanies}
                      placeholder={rentContractTranslation(
                        'ControllingForeignCompanies'
                      )}
                      disabled={true}
                    />
                    <p style={{ color: '#597a96' }}>
                      *Kontrolė suprantama, kai įmonių tarpusavio santykiai yra
                      bent vienos rūšies iš toliau išvardintų: <br />
                      a) viena įmonė turi kitos įmonės akcininkų arba narių
                      balsų daugumą; <br />
                      b) viena įmonė turi teisę paskirti arba atleisti daugumą
                      kitos įmonės administracijos, valdymo arba priežiūros
                      organo narių; <br />
                      c) pagal sutartį arba vadovaujantis steigimo sutarties ar
                      įstatų nuostata vienai įmonei suteikiama teisė daryti
                      kitai įmonei lemiamą įtaką; <br />
                      d) viena įmonė, būdama kitos įmonės akcininkė arba narė,
                      vadovaudamasi su tos įmonės kitais akcininkais ar nariais
                      sudaryta sutartimi, viena.
                    </p>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <InputWithTextInline
                      name={'NumberOfEmployees'}
                      type="text"
                      value={
                        requestsState?.data?.ApplicantData?.NumberOfEmployees ??
                        '-'
                      }
                      placeholder={rentContractTranslation('NumberOfEmployees')}
                      disabled={true}
                    />
                  </div>
                </div>
              )}

              {loginType === 'Individual' && (
                <>
                  <h4 style={{ marginTop: '2rem' }}>
                    Finansinių atskaitomybių informacija
                  </h4>
                  <div
                    className={styles.titleContainer}
                    style={{ marginTop: '15px' }}
                  ></div>
                </>
              )}
              <br />
              {/*} {loginType === 'Individual' && (
                <div style={{ width: '48.5%' }}>
                  <div className={styles.inlineContainer}>
                    <div className={styles.titleContainer}>
                      <p>{rentContractTranslation('ControllingCompanies')}</p>
                      <p>
                        Jadis pateikti akcininkai:{' '}
                        {requestsState.data.ControllingCompaniesFromRegister !=
                        null
                          ? requestsState.data.ControllingCompaniesFromRegister
                          : 'nėra pateiktų akcininkų'}
                      </p>
                    </div>
                    <input
                      className={styles.inlineInput}
                      style={{
                        background: viewWindow && '#f1f0f1',
                      }}
                      name={'ControllingCompanies'}
                      type={'string'}
                      value={controllingCompanies}
                      disabled={viewWindow}
                    />

                    <InputWithTextInline
                      name={'ControllingForeignCompanies'}
                      type="text"
                      value={controllingForeignCompanies}
                      placeholder={rentContractTranslation(
                        'ControllingForeignCompanies'
                      )}
                      disabled={true}
                    />
                    <p style={{ color: '#597a96' }}>
                      *Kontrolė suprantama, kai įmonių tarpusavio santykiai yra
                      bent vienos rūšies iš toliau išvardintų: <br />
                      a) viena įmonė turi kitos įmonės akcininkų arba narių
                      balsų daugumą; <br />
                      b) viena įmonė turi teisę paskirti arba atleisti daugumą
                      kitos įmonės administracijos, valdymo arba priežiūros
                      organo narių; <br />
                      c) pagal sutartį arba vadovaujantis steigimo sutarties ar
                      įstatų nuostata vienai įmonei suteikiama teisė daryti
                      kitai įmonei lemiamą įtaką; <br />
                      d) viena įmonė, būdama kitos įmonės akcininkė arba narė,
                      vadovaudamasi su tos įmonės kitais akcininkais ar nariais
                      sudaryta sutartimi, viena.
                    </p>
                  </div>
                </div>
              )}*/}

              {/* ControllingCompaniesFromRequest */}
             {/*} {requestsState.data?.RequestSystemInfo &&
                requestsState.data?.ControllingCompaniesFromRequest &&
                requestsState.data?.RequestSystemInfo?.Status !== 1 &&
                requestsState.data?.RequestSystemInfo?.Status !== 6 &&
                requestsState.data?.ControllingCompaniesFromRequest.map(
                  (val, i) => {
                    return (
                      <>
                        <p
                          style={{
                            marginTop: '0rem',
                            marginLeft: '0rem',
                            fontSize: '1rem',
                          }}
                        >
                          Verslo subjekto kodas {val.CompanyCode}
                        </p>
                        {val.IsFound ? (
                          <>
                            <div
                              className="col-lg-12 col-sm-12"
                              style={{ paddingLeft: '0px' }}
                              key={i}
                            >
                              <div>
                                {!checkIf2022FinancesToShow() && (
                                  <div className="row">
                                    {requestData?.RequestSystemInfo?.Status !==
                                      1 &&
                                      requestData?.RequestSystemInfo?.Status !==
                                        6 &&
                                      financialDataArray(val?.FsiYear).map(
                                        (key, i) => {
                                          if (
                                            key === 'Fsi2022Submitted' ||
                                            key === 'Fsi2021Submitted' ||
                                            key === 'Fsi2020Submitted' ||
                                            key === 'Fsi2019Submitted'
                                          ) {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <label
                                                  style={{
                                                    margin: '8px 0 8px',
                                                    color: '#597a96',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.5',
                                                  }}
                                                >
                                                  {requestDataLabelTranslation(
                                                    key
                                                  )}
                                                </label>
                                                <select
                                                  disabled={true}
                                                  name={key}
                                                  style={{
                                                    background: true
                                                      ? 'rgb(241, 240, 241'
                                                      : '#fff',
                                                    // color: '#2e2d2d',
                                                  }}
                                                  value={
                                                    key === 'Fsi2022Submitted'
                                                      ? companyQuestionList?.Fsi2022Submitted
                                                      : key ===
                                                        'Fsi2021Submitted'
                                                      ? companyQuestionList?.Fsi2021Submitted
                                                      : key ===
                                                        'Fsi2019Submitted'
                                                      ? companyQuestionList?.Fsi2019Submitted
                                                      : companyQuestionList?.Fsi2020Submitted
                                                  }
                                                >
                                                  <option value={null}>
                                                    Ne
                                                  </option>
                                                  <option value={true}>
                                                    Taip
                                                  </option>
                                                  <option value={false}>
                                                    Ne
                                                  </option>
                                                </select>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="col-lg-6 col-sm-12"
                                                key={i}
                                              >
                                                <InputWithTextInline
                                                  name={key}
                                                  type={
                                                    isNumberType(key)
                                                      ? 'number'
                                                      : 'string'
                                                  }
                                                  value={
                                                    key === 'FsiEndDate' ||
                                                    key === 'FsiStartDate' ||
                                                    key === 'FsiEndDate2019' ||
                                                    key ===
                                                      'FsiStartDate2019' ||
                                                    key === 'FsiEndDate2020' ||
                                                    key ===
                                                      'FsiStartDate2020' ||
                                                    key === 'FsiEndDate2021' ||
                                                    key ===
                                                      'FsiStartDate2021' ||
                                                    key === 'FsiEndDate2022' ||
                                                    key ===
                                                      'FsiStartDate2022' ||
                                                    key === 'FsiEndDate2023' ||
                                                    key === 'FsiStartDate2023'
                                                      ? val[key] &&
                                                        format(
                                                          new Date(val[key]),
                                                          'yyyy-MM-dd'
                                                        )
                                                      : formatNumber(val[key])
                                                  }
                                                  placeholder={requestDataLabelTranslation(
                                                    key
                                                  )}
                                                  disabled
                                                />
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                )}

                                {checkIf2022FinancesToShow() &&
                                  val?.Fsi2022Submitted && (
                                    <>
                                      <h4
                                        style={{ margin: '15px 0px 0px 0px' }}
                                      >
                                        2022m. finansinės atskaitomybės duomenys{' '}
                                      </h4>
                                      <div className="row">
                                        {
                                          // requestData.RequestSystemInfo.Status !== 1 &&
                                          // requestData.RequestSystemInfo.Status !== 6 &&
                                          financialDataArray2022.map(
                                            (key, i) => {
                                              if (key === 'Fsi2022Submitted') {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <label
                                                      style={{
                                                        margin: '8px 0 8px',
                                                        color: '#597a96',
                                                        fontSize: '0.8rem',
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      {requestDataLabelTranslation(
                                                        key
                                                      )}
                                                    </label>
                                                    <select
                                                      disabled={true}
                                                      name={key}
                                                      style={{
                                                        background: true
                                                          ? 'rgb(241, 240, 241'
                                                          : '#fff',
                                                        // color: '#2e2d2d',
                                                      }}
                                                      value={
                                                        key ===
                                                        'Fsi2022Submitted'
                                                          ? val.Fsi2022Submitted
                                                          : val.Fsi2021Submitted
                                                      }
                                                    >
                                                      <option value={null}>
                                                        Ne
                                                      </option>
                                                      <option value={true}>
                                                        Taip
                                                      </option>
                                                      <option value={false}>
                                                        Ne
                                                      </option>
                                                    </select>
                                                  </div>
                                                );
                                              } else if (key === 'br') {
                                                return (
                                                  <p
                                                    style={{
                                                      visibility: 'hidden',
                                                    }}
                                                  >
                                                    /
                                                  </p>
                                                );
                                              } else {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <InputWithTextInline
                                                      name={key}
                                                      type={
                                                        isNumberType(key)
                                                          ? 'number'
                                                          : 'string'
                                                      }
                                                      value={
                                                        key === 'FsiEndDate' ||
                                                        key ===
                                                          'FsiStartDate' ||
                                                        key ===
                                                          'FsiEndDate2019' ||
                                                        key ===
                                                          'FsiStartDate2019' ||
                                                        key ===
                                                          'FsiEndDate2020' ||
                                                        key ===
                                                          'FsiStartDate2020' ||
                                                        key ===
                                                          'FsiEndDate2021' ||
                                                        key ===
                                                          'FsiStartDate2021' ||
                                                        key ===
                                                          'FsiEndDate2022' ||
                                                        key ===
                                                          'FsiStartDate2022' ||
                                                        key ===
                                                          'FsiEndDate2023' ||
                                                        key ===
                                                          'FsiStartDate2023'
                                                          ? val[key] &&
                                                            format(
                                                              new Date(
                                                                val[key]
                                                              ),
                                                              'yyyy-MM-dd'
                                                            )
                                                          : formatNumber(
                                                              val[key]
                                                            )
                                                      }
                                                      placeholder={requestDataLabelTranslation(
                                                        key
                                                      )}
                                                      disabled
                                                    />
                                                  </div>
                                                );
                                              }
                                            }
                                          )
                                        }
                                      </div>
                                    </>
                                  )}

                                {checkIf2022FinancesToShow() &&
                                  val?.Fsi2021Submitted && (
                                    <>
                                      <h4
                                        style={{ margin: '15px 0px 0px 0px' }}
                                      >
                                        2021m. finansinės atskaitomybės duomenys{' '}
                                      </h4>
                                      <div className="row">
                                        {
                                          // requestData.RequestSystemInfo.Status !== 1 &&
                                          // requestData.RequestSystemInfo.Status !== 6 &&
                                          financialDataArray2021.map(
                                            (key, i) => {
                                              if (key === 'Fsi2021Submitted') {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <label
                                                      style={{
                                                        margin: '8px 0 8px',
                                                        color: '#597a96',
                                                        fontSize: '0.8rem',
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      {requestDataLabelTranslation(
                                                        key
                                                      )}
                                                    </label>
                                                    <select
                                                      disabled={true}
                                                      name={key}
                                                      style={{
                                                        background: true
                                                          ? 'rgb(241, 240, 241'
                                                          : '#fff',
                                                        // color: '#2e2d2d',
                                                      }}
                                                      value={
                                                        val.Fsi2021Submitted
                                                      }
                                                    >
                                                      <option value={null}>
                                                        Ne
                                                      </option>
                                                      <option value={true}>
                                                        Taip
                                                      </option>
                                                      <option value={false}>
                                                        Ne
                                                      </option>
                                                    </select>
                                                  </div>
                                                );
                                              } else if (key === 'br') {
                                                return (
                                                  <p
                                                    style={{
                                                      visibility: 'hidden',
                                                    }}
                                                  >
                                                    /
                                                  </p>
                                                );
                                              } else {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <InputWithTextInline
                                                      name={key}
                                                      type={
                                                        isNumberType(key)
                                                          ? 'number'
                                                          : 'string'
                                                      }
                                                      value={
                                                        key === 'FsiEndDate' ||
                                                        key ===
                                                          'FsiStartDate' ||
                                                        key ===
                                                          'FsiEndDate2019' ||
                                                        key ===
                                                          'FsiStartDate2019' ||
                                                        key ===
                                                          'FsiEndDate2020' ||
                                                        key ===
                                                          'FsiStartDate2020' ||
                                                        key ===
                                                          'FsiEndDate2021' ||
                                                        key ===
                                                          'FsiStartDate2021' ||
                                                        key ===
                                                          'FsiEndDate2022' ||
                                                        key ===
                                                          'FsiStartDate2022' ||
                                                        key ===
                                                          'FsiEndDate2023' ||
                                                        key ===
                                                          'FsiStartDate2023'
                                                          ? val[key] &&
                                                            format(
                                                              new Date(
                                                                val[key]
                                                              ),
                                                              'yyyy-MM-dd'
                                                            )
                                                          : formatNumber(
                                                              val[key]
                                                            )
                                                      }
                                                      placeholder={requestDataLabelTranslation(
                                                        key
                                                      )}
                                                      disabled
                                                    />
                                                  </div>
                                                );
                                              }
                                            }
                                          )
                                        }
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                        )}
                      </>
                    );
                  }
                )}

              <div className="col-lg-12 col-sm-12">
                {requestsState.data.ControllingCompaniesFromRequest != null &&
                  requestsState.data.RequestSystemInfo &&
                  requestsState.data.RequestSystemInfo.Status !== 1 &&
                  requestsState.data.RequestSystemInfo.Status !== 6 &&
                  ((val, i) => {
                    return (
                      <div className="col-lg-12 col-sm-12">
                        <p
                          style={{
                            marginTop: '1rem',
                            marginLeft: '1rem',
                            fontSize: '1rem',
                          }}
                        >
                          Verslo subjekto kodas {val.CompanyCode}
                        </p>
                        {val.IsFound ? (
                          <>
                            <div className="col-lg-12 col-sm-12" key={i}>
                              <div>
                                {!checkIf2022FinancesToShow() && (
                                  <div className="row">
                                    {requestData.RequestSystemInfo.Status !==
                                      1 &&
                                      requestData.RequestSystemInfo.Status !==
                                        6 &&
                                      financialDataArray(
                                        companyQuestionList.FsiYear
                                      ).map((key, i) => {
                                        if (
                                          key === 'Fsi2022Submitted' ||
                                          key === 'Fsi2021Submitted' ||
                                          key === 'Fsi2020Submitted' ||
                                          key === 'Fsi2019Submitted'
                                        ) {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <label
                                                style={{
                                                  margin: '8px 0 8px',
                                                  color: '#597a96',
                                                  fontSize: '0.8rem',
                                                  lineHeight: '1.5',
                                                }}
                                              >
                                                {requestDataLabelTranslation(
                                                  key
                                                )}
                                              </label>
                                              <select
                                                disabled={true}
                                                name={key}
                                                style={{
                                                  background: true
                                                    ? 'rgb(241, 240, 241'
                                                    : '#fff',
                                                  // color: '#2e2d2d',
                                                }}
                                                value={
                                                  key === 'Fsi2022Submitted'
                                                    ? val.Fsi2022Submitted
                                                    : key === 'Fsi2021Submitted'
                                                    ? val.Fsi2021Submitted
                                                    : key === 'Fsi2019Submitted'
                                                    ? val.Fsi2019Submitted
                                                    : val.Fsi2020Submitted
                                                }
                                              >
                                                <option value={null}>Ne</option>
                                                <option value={true}>
                                                  Taip
                                                </option>
                                                <option value={false}>
                                                  Ne
                                                </option>
                                              </select>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className="col-lg-6 col-sm-12"
                                              key={i}
                                            >
                                              <InputWithTextInline
                                                name={key}
                                                type={
                                                  isNumberType(key)
                                                    ? 'number'
                                                    : 'string'
                                                }
                                                value={
                                                  key === 'FsiEndDate' ||
                                                  key === 'FsiStartDate' ||
                                                  key === 'FsiEndDate2019' ||
                                                  key === 'FsiStartDate2019' ||
                                                  key === 'FsiEndDate2020' ||
                                                  key === 'FsiStartDate2020' ||
                                                  key === 'FsiEndDate2021' ||
                                                  key === 'FsiStartDate2021' ||
                                                  key === 'FsiEndDate2022' ||
                                                  key === 'FsiStartDate2022' ||
                                                  key === 'FsiEndDate2023' ||
                                                  key === 'FsiStartDate2023'
                                                    ? val[key] &&
                                                      format(
                                                        new Date(val[key]),
                                                        'yyyy-MM-dd'
                                                      )
                                                    : formatNumber(val[key])
                                                }
                                                placeholder={requestDataLabelTranslation(
                                                  key
                                                )}
                                                disabled
                                              />
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                )}

                                {checkIf2022FinancesToShow() &&
                                  val?.Fsi2022Submitted && (
                                    <>
                                      <h4
                                        style={{ margin: '15px 0px 0px 0px' }}
                                      >
                                        2022m. finansinės atskaitomybės duomenys{' '}
                                      </h4>
                                      <div className="row">
                                        {
                                          // requestData.RequestSystemInfo.Status !== 1 &&
                                          // requestData.RequestSystemInfo.Status !== 6 &&
                                          financialDataArray2022.map(
                                            (key, i) => {
                                              if (key === 'Fsi2022Submitted') {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <label
                                                      style={{
                                                        margin: '8px 0 8px',
                                                        color: '#597a96',
                                                        fontSize: '0.8rem',
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      {requestDataLabelTranslation(
                                                        key
                                                      )}
                                                    </label>
                                                    <select
                                                      disabled={true}
                                                      name={key}
                                                      style={{
                                                        background: true
                                                          ? 'rgb(241, 240, 241'
                                                          : '#fff',
                                                        // color: '#2e2d2d',
                                                      }}
                                                      value={
                                                        key ===
                                                        'Fsi2022Submitted'
                                                          ? val.Fsi2022Submitted
                                                          : val.Fsi2021Submitted
                                                      }
                                                    >
                                                      <option value={null}>
                                                        Ne
                                                      </option>
                                                      <option value={true}>
                                                        Taip
                                                      </option>
                                                      <option value={false}>
                                                        Ne
                                                      </option>
                                                    </select>
                                                  </div>
                                                );
                                              } else if (key === 'br') {
                                                return (
                                                  <p
                                                    style={{
                                                      visibility: 'hidden',
                                                    }}
                                                  >
                                                    /
                                                  </p>
                                                );
                                              } else {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <InputWithTextInline
                                                      name={key}
                                                      type={
                                                        isNumberType(key)
                                                          ? 'number'
                                                          : 'string'
                                                      }
                                                      value={
                                                        key === 'FsiEndDate' ||
                                                        key ===
                                                          'FsiStartDate' ||
                                                        key ===
                                                          'FsiEndDate2019' ||
                                                        key ===
                                                          'FsiStartDate2019' ||
                                                        key ===
                                                          'FsiEndDate2020' ||
                                                        key ===
                                                          'FsiStartDate2020' ||
                                                        key ===
                                                          'FsiEndDate2021' ||
                                                        key ===
                                                          'FsiStartDate2021' ||
                                                        key ===
                                                          'FsiEndDate2022' ||
                                                        key ===
                                                          'FsiStartDate2022' ||
                                                        key ===
                                                          'FsiEndDate2023' ||
                                                        key ===
                                                          'FsiStartDate2023'
                                                          ? val[key] &&
                                                            format(
                                                              new Date(
                                                                val[key]
                                                              ),
                                                              'yyyy-MM-dd'
                                                            )
                                                          : formatNumber(
                                                              val[key]
                                                            )
                                                      }
                                                      placeholder={requestDataLabelTranslation(
                                                        key
                                                      )}
                                                      disabled
                                                    />
                                                  </div>
                                                );
                                              }
                                            }
                                          )
                                        }
                                      </div>
                                    </>
                                  )}

                                {checkIf2022FinancesToShow() &&
                                  val?.Fsi2021Submitted && (
                                    <>
                                      <h4
                                        style={{ margin: '15px 0px 0px 0px' }}
                                      >
                                        2021m. finansinės atskaitomybės duomenys{' '}
                                      </h4>
                                      <div className="row">
                                        {
                                          // requestData.RequestSystemInfo.Status !== 1 &&
                                          // requestData.RequestSystemInfo.Status !== 6 &&
                                          financialDataArray2021.map(
                                            (key, i) => {
                                              if (key === 'Fsi2021Submitted') {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <label
                                                      style={{
                                                        margin: '8px 0 8px',
                                                        color: '#597a96',
                                                        fontSize: '0.8rem',
                                                        lineHeight: '1.5',
                                                      }}
                                                    >
                                                      {requestDataLabelTranslation(
                                                        key
                                                      )}
                                                    </label>
                                                    <select
                                                      disabled={true}
                                                      name={key}
                                                      style={{
                                                        background: true
                                                          ? 'rgb(241, 240, 241'
                                                          : '#fff',
                                                        // color: '#2e2d2d',
                                                      }}
                                                      value={
                                                        val.Fsi2021Submitted
                                                      }
                                                    >
                                                      <option value={null}>
                                                        Ne
                                                      </option>
                                                      <option value={true}>
                                                        Taip
                                                      </option>
                                                      <option value={false}>
                                                        Ne
                                                      </option>
                                                    </select>
                                                  </div>
                                                );
                                              } else if (key === 'br') {
                                                return (
                                                  <p
                                                    style={{
                                                      visibility: 'hidden',
                                                    }}
                                                  >
                                                    /
                                                  </p>
                                                );
                                              } else {
                                                return (
                                                  <div
                                                    className="col-lg-6 col-sm-12"
                                                    key={i}
                                                  >
                                                    <InputWithTextInline
                                                      name={key}
                                                      type={
                                                        isNumberType(key)
                                                          ? 'number'
                                                          : 'string'
                                                      }
                                                      value={
                                                        key === 'FsiEndDate' ||
                                                        key ===
                                                          'FsiStartDate' ||
                                                        key ===
                                                          'FsiEndDate2019' ||
                                                        key ===
                                                          'FsiStartDate2019' ||
                                                        key ===
                                                          'FsiEndDate2020' ||
                                                        key ===
                                                          'FsiStartDate2020' ||
                                                        key ===
                                                          'FsiEndDate2021' ||
                                                        key ===
                                                          'FsiStartDate2021' ||
                                                        key ===
                                                          'FsiEndDate2022' ||
                                                        key ===
                                                          'FsiStartDate2022' ||
                                                        key ===
                                                          'FsiEndDate2023' ||
                                                        key ===
                                                          'FsiStartDate2023'
                                                          ? val[key] &&
                                                            format(
                                                              new Date(
                                                                val[key]
                                                              ),
                                                              'yyyy-MM-dd'
                                                            )
                                                          : formatNumber(
                                                              val[key]
                                                            )
                                                      }
                                                      placeholder={requestDataLabelTranslation(
                                                        key
                                                      )}
                                                      disabled
                                                    />
                                                  </div>
                                                );
                                              }
                                            }
                                          )
                                        }
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                        )}
                      </div>
                    );
                  })}
                </div>*/}
                {requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest && (
                  <h4 style={{ fontSize: '16px', marginTop: '30px' }}>
                    Įmonių grupės finansinių atskaitomybių informacija
                  </h4>
                )}
              {/* ControllingCompaniesFromRequest */}
              {requestData.RequestSystemInfo &&
                requestData.ControllingCompaniesFromRequest &&
                requestData.RequestSystemInfo.Status !== 1 &&
                requestData.RequestSystemInfo.Status !== 6 &&
                requestData.ControllingCompaniesFromRequest.map((val, i) => {
                  return (
                    <>
                      <div
                        className={styles.titleContainer}
                        style={{ marginTop: '16px' }}
                      ></div>
                      <p
                        style={{
                          marginTop: '1rem',
                          marginLeft: '0',
                          fontSize: '1rem',
                        }}
                      >
                        Verslo subjekto kodas {val.CompanyCode}
                      </p>
                      {val.IsFound ? (
                        <>
                          <div
                            className="col-lg-12 col-sm-12"
                            key={i}
                            style={{ paddingLeft: '0px' }}
                          ></div>

                          <FinancialTable
                            data={val?.Financials}
                            regDate={val.RegistrationDate}
                          />
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                      )}
                    </>
                  );
                })}
              <div className="col-lg-12 col-sm-12">
                {requestData.ControllingCompaniesFromRequest != null &&
                  requestData.RequestSystemInfo &&
                  requestData.RequestSystemInfo.Status !== 1 &&
                  requestData.RequestSystemInfo.Status !== 6 &&
                  ((val, i) => {
                    return (
                      <div className="col-lg-12 col-sm-12">
                        <p
                          style={{
                            marginTop: '1rem',
                            marginLeft: '1rem',
                            fontSize: '1rem',
                          }}
                        >
                          Verslo subjekto kodas {val.CompanyCode}
                        </p>
                        {val.IsFound ? (
                          <>
                            <div className="col-lg-12 col-sm-12" key={i}>
                              <div className="row">
                                {requestData.RequestSystemInfo.Status !== 1 &&
                                  requestData.RequestSystemInfo.Status !== 6 &&
                                  financialDataArray(2019).map((key, i) => {
                                    if (key === 'Fsi2019Submitted') {
                                      return (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={i}
                                        >
                                          <label
                                            style={{
                                              margin: '8px 0 8px',
                                              color: '#597a96',
                                              fontSize: '0.8rem',
                                              lineHeight: '1.5',
                                            }}
                                          >
                                            {requestDataLabelTranslation(
                                              val['Fsi2019Submitted']
                                                ? key
                                                : 'Fsi2020Submitted'
                                            )}
                                          </label>
                                          <select
                                            disabled={true}
                                            name={
                                              val['Fsi2019Submitted']
                                                ? 'Fsi2019Submitted'
                                                : 'Fsi2020Submitted'
                                            }
                                            style={{
                                              background: true
                                                ? 'rgb(241, 240, 241'
                                                : '#fff',
                                              // color: '#2e2d2d',
                                            }}
                                            value={
                                              val['Fsi2019Submitted']
                                                ? val[key]
                                                : val['Fsi2020Submitted']
                                            }
                                          >
                                            <option value={null}>Ne</option>
                                            <option value={true}>Taip</option>
                                            <option value={false}>Ne</option>
                                          </select>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={i}
                                        >
                                          <InputWithTextInline
                                            name={key}
                                            type={'string'}
                                            value={
                                              key === 'FsiEndDate2019' ||
                                              key === 'FsiStartDate2019'
                                                ? val[key] &&
                                                  format(
                                                    new Date(val[key]),
                                                    'yyyy-MM-dd'
                                                  )
                                                : formatNumber(
                                                    val.FsiYear2019 === 2019
                                                      ? val[key]
                                                      : val[from19To20(key)]
                                                  )
                                            }
                                            placeholder={requestDataLabelTranslation(
                                              key
                                            )}
                                            disabled={true}
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                        )}
                      </div>
                    );
                  })}
              </div>

              {/* Pep AML data */}

              <h4 style={{ marginTop: '2rem' }}>AML ir PEP </h4>

              {pepData && (
                <div>
                  <div style={{ width: '48.5%' }}>
                    <p className="text-style-new-request">
                      Ar Verslo subjekto vadovui, atstovui, naudos gavėjams ar
                      jų artimiesiems šeimos nariams[1] arba artimiesiems
                      pagalbininkams[2] šiuo metu arba per paskutinius 12
                      mėnesių patikėtos svarbios viešosios pareigos (PEP)?[3]
                    </p>

                    <label
                      style={{
                        display: 'flex',
                        // width: '48.5%',
                        justifyContent: 'space-between',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '0.8rem',
                        marginTop: '1.5rem',
                      }}
                    >
                      <select
                        disabled={viewWindow}
                        name="HasImportantResponsibilities"
                        style={{
                          background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                          // color: '#2e2d2d',
                        }}
                        value={pepData.HasImportantResponsibilities}
                      >
                        <option value={false}>Ne</option>
                        <option value={true}>Taip</option>
                      </select>
                    </label>
                  </div>

                  <p className="text-style-new-request">
                    Jei pažymėjote "taip", nurodykite asmenis ir jų informaciją:
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-lg-2 col-sm-2">Vardas</span>
                    <span className="col-lg-2 col-sm-2">Pavardė</span>
                    <span className="col-lg-2 col-sm-2">Gimimo data</span>
                    <span className="col-lg-2 col-sm-2">Pareigos</span>
                    <span className="col-lg-2 col-sm-2">
                      Valstybė, kurioje asmuo yra PEP
                    </span>
                    <span className="col-lg-2 col-sm-2">
                      Asmens ryšys su Verslo subjekte veikiančiais asmenimis
                    </span>
                  </div>

                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ImportantResponsibilityPersons &&
                      pepData.ImportantResponsibilityPersons.length > 0 &&
                      pepData.ImportantResponsibilityPersons.map((obj, k) =>
                        Object.keys(obj).map((p, i) => (
                          <div className="col-lg-2 col-sm-2 padding-column">
                            <Input
                              key={i}
                              name={p}
                              type="string"
                              disabled={viewWindow}
                              //value={obj[p]}
                              value={
                                p === 'country'
                                  ? returnCountryNameById(obj[p], true)
                                  : obj[p]
                              }
                            />
                          </div>
                        ))
                      )}
                  </div>

                  <p className="text-style-new-request">Paaiškinimas:</p>
                  <p className="text-style-new-request">
                    [1] Artimieji šeimos nariai - sutuoktinis, asmuo, su kuriuo
                    registruota partnerystė, tėvai, broliai, seserys, vaikai ir
                    vaikų sutuoktiniai, vaikų sugyventiniai.
                    <br />
                    [2] Artimas pagalbininkas:
                    <br />
                    1) fizinis asmuo, kuris su svarbias viešąsias pareigas
                    einančiu ar ėjusiu asmeniu yra to paties juridinio asmens ar
                    juridinio asmens statuso neturinčios organizacijos dalyvis
                    arba palaiko kitus dalykinius santykius;
                    <br />
                    2) fizinis asmuo, kuris yra vienintelis juridinio asmens ar
                    juridinio asmens statuso neturinčios organizacijos,
                    įsteigtos ar veikiančios de facto siekiant turtinės ar
                    kitokios asmeninės naudos svarbias viešąsias pareigas
                    einančiam ar ėjusiam asmeniui, naudos gavėjas.
                    <br />
                    [3] <b>Svarbios viešosios pareigos</b> – pareigos Lietuvos
                    Respublikoje, Europos Sąjungoje, tarptautinėse ar užsienio
                    valstybių institucijose:
                    <br />
                    1) valstybės vadovas, vyriausybės vadovas, ministras,
                    viceministras arba ministro pavaduotojas, valstybės
                    sekretorius, parlamento, vyriausybės arba ministerijos
                    kancleris;
                    <br />
                    2) parlamento narys;
                    <br />
                    3) aukščiausiųjų teismų, konstitucinių teismų ar kitų
                    aukščiausiųjų teisminių institucijų, kurių sprendimai negali
                    būti skundžiami, narys;
                    <br />
                    4) savivaldybės meras, savivaldybės administracijos
                    direktorius;
                    <br />
                    5) aukščiausiosios valstybių audito ir kontrolės
                    institucijos valdymo organo narys ar centrinio banko
                    valdybos pirmininkas, jo pavaduotojas ar valdybos narys;
                    <br />
                    6) ambasadorius, laikinasis reikalų patikėtinis, Lietuvos
                    kariuomenės vadas, kariuomenės pajėgų ir junginių vadai,
                    Gynybos štabo viršininkas ar užsienio valstybių aukšto rango
                    ginkluotųjų pajėgų karininkas;
                    <br />
                    7) valstybės įmonės, akcinės bendrovės, uždarosios akcinės
                    bendrovės, kurių akcijos ar dalis akcijų, suteikiančių
                    daugiau kaip 1/2 visų balsų šių bendrovių visuotiniame
                    akcininkų susirinkime, priklauso valstybei nuosavybės teise,
                    valdymo ar priežiūros organo narys;
                    <br />
                    8) savivaldybės įmonės, akcinės bendrovės, uždarosios
                    akcinės bendrovės, kurių akcijos ar dalis akcijų,
                    suteikiančių daugiau kaip 1/2 visų balsų šių bendrovių
                    visuotiniame akcininkų susirinkime, priklauso savivaldybei
                    nuosavybės teise ir kurios laikomos didelėmis įmonėmis pagal
                    Lietuvos Respublikos įmonių finansinės atskaitomybės
                    įstatymą, valdymo ar priežiūros organo narys;
                    <br />
                    9) tarptautinės tarpvyriausybinės organizacijos vadovas, jo
                    pavaduotojas, valdymo ar priežiūros organo narys;
                    <br />
                    10) politinės partijos vadovas, jo pavaduotojas, valdymo
                    organo narys.
                    <br />
                  </p>

                  <div className="checkbox">
                    <label className="containerLabel noBlock">
                      <input
                        type="checkbox"
                        name="HasImportantResponsibilitiesMoreThan4"
                        checked={
                          pepData &&
                          pepData.HasImportantResponsibilitiesMoreThan4
                        }
                        disabled={viewWindow}
                      />
                      <span className="checkmark"></span>
                      Ar yra daugiau Verslo subjekto vadovų, atstovų, naudos
                      gavėjų ar jų artimųjų šeimos narių arba artimųjų
                      pagalbininkų, kuriems šiuo metu arba per paskutinius 12
                      mėnesių patikėtos svarbios viešosios pareigos (PEP)?
                    </label>
                  </div>

                  <p
                    className="text-style-new-request"
                    style={{ marginLeft: '0px' }}
                  >
                    Nurodykite fizinius asmenis, kurie tiesiogiai ar
                    netiesiogiai turi arba kontroliuoja jūsų Verslo subjekto
                    daugiau negu 25 proc. akcijų ar balsavimo teisių, arba kurie
                    tiesiogiai ar netiesiogiai kontroliuoja jūsų Verslo subjektą
                    kitais būdais
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-seven">Vardas</span>
                    <span className="col-seven">Pavardė</span>
                    <span className="col-seven">
                      Pilietybė<sup>*</sup>
                    </span>
                    <span className="col-seven">
                      Asmens kodas (jei ne Lietuvos pilietis - gimimo data)
                    </span>
                    <span className="col-seven">Pareigos</span>
                    <span className="col-seven">Akcijų dalis, proc.</span>
                    <span className="col-seven">
                      Balsavimo teisių dalis, proc.
                    </span>
                  </div>

                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ControllingPersons &&
                      pepData.ControllingPersons.map((obj, k) =>
                        Object.keys(obj).map((p, i) => (
                          <div className="col-seven padding-column">
                            <Input
                              key={i}
                              name={p}
                              type="string"
                              disabled={viewWindow}
                              value={
                                p === 'citizenshipId'
                                  ? returnCountryNameById(obj[p], true)
                                  : obj[p]
                              }
                            />
                          </div>
                        ))
                      )}
                  </div>
                  <p className="specify-info" style={{ color: '#597a96' }}>
                    <sup>*</sup> jeigu asmuo be pilietybės – nurodoma valstybė,
                    kuri išdavė asmens tapatybę patvirtinantį dokumentą
                  </p>
                  <p className="text-style-new-request">
                    Nurodykite įmones, kurios tiesiogiai ar netiesiogiai turi
                    arba kontroliuoja jūsų Verslo subjekto daugiau negu 25 proc.
                    akcijų ar balsavimo teisių
                  </p>
                  <p className="text-style-new-request">
                    Jeigu nurodėte įmonę(-es), kuri(-os) tiesiogiai ar
                    netiesiogiai turi arba kontroliuoja jūsų Verslo subjekto
                    daugiau negu 25 proc. akcijų ar balsavimo teisių, būtina
                    užpildyti aukščiau prašomą informaciją apie fizinius asmenis
                    - netiesioginius kliento akcininkus (dalyvius) arba asmenis,
                    kitaip kontroliuojančius kliento valdymą.
                  </p>
                  <div
                    className="row table-style-header"
                    style={{ marginLeft: '0px' }}
                  >
                    <span className="col-five">Įmonės pavadinimas</span>
                    <span className="col-five">Įmonės kodas</span>
                    <span className="col-five">Įmonės registracijos šalis</span>
                    <span className="col-five">Akcijų dalis, proc.</span>
                    <span className="col-five">
                      Balsavimo teisių dalis, proc.
                    </span>
                  </div>
                  <div
                    className="row inputs-block"
                    style={{ marginLeft: '0px' }}
                  >
                    {pepData &&
                      pepData.ControllingCompanies &&
                      pepData.ControllingCompanies.map((obj, k) =>
                        Object.keys(obj).map((p, i) => (
                          <div className="col-five padding-column">
                            <Input
                              key={i}
                              name={p}
                              type="string"
                              disabled={viewWindow}
                              value={
                                p === 'country'
                                  ? returnCountryNameById(obj[p], true)
                                  : obj[p]
                              }
                            />
                          </div>
                        ))
                      )}
                  </div>
                  <p
                    className="text-style-new-request"
                    style={{ paddingBottom: '20px' }}
                  >
                    PASTABA: Jei tokių fizinių ir (ar) juridinių asmenų nėra
                    (nei vienas iš jų neturi arba nekontroliuoja daugiau nei 25
                    proc. akcijų ar balsavimo teisių), prašome nurodyti
                    pagrindinius akcininkus, turinčius daugiausiai akcijų.
                  </p>
                </div>
              )}

              {/* {Tiekėjo (-ų) informacija start} */}
              <h4>Tiekėjo (-ų) informacija</h4>

              <div
                className="row table-style-header"
                style={{ marginLeft: '0px' }}
              >
                <span className="col-five">
                  Tiekėjo įmonės/asmens pavadinimas
                </span>
                <span className="col-five">Tiekėjo įmonės/asmens kodas</span>

                <span className="col-five">Įmonės registracijos šalis</span>
                <span className="col-five">
                  Tiekėjo vykdoma veikla (pagal EVRK2)
                </span>
                <span className="col-five">Mokėjimų pagrindas </span>
              </div>

              <div
                className="row inputs-block"
                style={{ marginLeft: '0px', marginBottom: '20px' }}
              >
                {suppliers &&
                  suppliers?.map((obj, k) =>
                    Object.keys(obj).map((p, i) => {
                      if (p === 'evrk') {
                        return (
                          <div
                            className="col-five padding-column"
                            style={{
                              width: '203px',
                              margin: '8px 0px 0px 0px',
                            }}
                            key={i}
                          >
                            <select
                              name="evrk"
                              disabled={viewWindow}
                              style={{
                                background: viewWindow
                                  ? 'rgb(241, 240, 241'
                                  : '#fff',
                                height: 'auto',
                              }}
                            >
                              {(!viewWindow ||
                                !requestData?.Suppliers?.[k]?.evrk) && (
                                <option
                                  selected={
                                    requestData?.Suppliers?.[k]?.evrk === null
                                  }
                                  value={null}
                                >
                                  -
                                </option>
                              )}
                              {Object.keys(evrkFull).map((val, j) => (
                                <option
                                  key={j}
                                  selected={
                                    requestData?.Suppliers?.[k]?.evrk == val
                                  }
                                  value={val}
                                >
                                  {`${val} - ${evrkFull[val]}`}
                                </option>
                              ))}
                              {viewWindow && (
                                <option
                                  selected={true}
                                  value={requestData?.Suppliers?.[k]?.evrk}
                                >
                                  {requestData?.Suppliers?.[k]?.evrk
                                    ? `${requestData?.Suppliers?.[k]?.evrk} - ${
                                        evrkFull[
                                          requestData?.Suppliers?.[k]?.evrk
                                        ]
                                      }`
                                    : '-'}
                                </option>
                              )}
                            </select>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-five padding-column" key={i}>
                            <Input
                              key={i}
                              name={p}
                              type="string"
                              disabled={viewWindow}
                              value={
                                p === 'RegistrationCountryId'
                                  ? returnCountryNameById(obj[p], true)
                                  : p === 'Evrk'
                                  ? `${obj[p]} - ${evrkFull?.[obj[p]]}`
                                  : obj[p]
                              }
                            />
                          </div>
                        );
                      }
                    })
                  )}
                <p
                  className="text-style-new-request"
                  style={{ paddingBottom: '20px' }}
                >
                  PASTABA: turi būti pateikti 5 pagrindiniai tiekėjai. Esant
                  mažiau, teikiamas mažesnis skaičius, bet ne mažiau nei 1
                  tiekėjas.
                </p>
              </div>

              {/* STAR-125 */}
              <h4>Deklaracijos apie gautą valstybės pagalbą duomenys</h4>
              <div style={{ width: '100%' }}>
                <p className="text-style-new-request">
                  Deklaracijos apie gautą valstybės pagalbą pagal 2020 m. kovo
                  19 d. Europos Komisijos komunikatą dėl laikinosios valstybės
                  pagalbos priemonių, skirtų ekonomikai remti reaguojant į
                  dabartinį COVID-19 protrūkį, sistemą (toliau - Komunikatas) ir
                  vėlesnius pakeitimus:
                </p>
              </div>
              <div className="row">
                {selfDeclarationData &&
                  SelfDeclarationInfo.map((item, i) => {
                    if (item.type === 'input') {
                      return (
                        <div className="col-lg-6 col-sm-6" key={i}>
                          <InputWithTextInline
                            name={item.name}
                            type="number"
                            value={selfDeclarationData?.[item.name]}
                            placeholder={item.title}
                            subtitles={item.subtitles}
                            disabled
                          />
                        </div>
                      );
                    } else if (item.type === 'checkbox') {
                      return (
                        <div className="checkbox mb-0 mt-2 mx-3 pl-2" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={item.name}
                              checked={selfDeclarationData?.[item.name]}
                              disabled
                            />
                            <span className="checkmark"></span>
                            {item.title}
                          </label>
                        </div>
                      );
                    }
                  })}
              </div>

              {/* Pep Aml data end */}

              <h4>Informacija Paskolos sutarties parengimui</h4>
              {loanInfo && (
                <div className="row director-data-margin">
                  <div className="col-lg-6 col-sm-12">
                    <InputWithTextInline
                      name={'LoanSum'}
                      type={'string'}
                      value={loanInfo['LoanSum']}
                      placeholder={requestDataLabelTranslation('LoanSum')}
                      disabled={true}
                    />
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanSocialBusiness')}
                    </label>
                    <select
                      disabled={true}
                      name="LoanSocialBusiness"
                      style={{
                        background: 'rgb(241, 240, 241',
                      }}
                      value={loanInfo.LoanSocialBusiness}
                    >
                      <option value={null}>{null}</option>
                      <option value={1}>Taip</option>
                      <option value={0}>Ne</option>
                      <option value={3}>Taip (esu Ukrainos pilietis)</option>
                      <option value={2}>Ne (esu Ukrainos pilietis)</option>
                    </select>
                    <p style={{ color: '#597a96' }}>
                      * Socialinio poveikio projektas – tai projektas, pagal
                      kurį verslininko ar įmonės pelno siekimas susiejamas su
                      socialiniais tikslais ir prioritetais{' '}
                    </p>
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanPurposeId')}
                    </label>
                    <select
                      disabled={true}
                      name="LoanPurposeId"
                      style={{ backgroundColor: 'rgb(241, 240, 241' }}
                      value={loanInfo.LoanPurposeId}
                    >
                      <option value={null}>{null}</option>
                      <option value={'1'}>Investicinė</option>
                      <option value={'2'}>Apyvartinė</option>
                    </select>
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanTerm')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanTerm"
                      style={{
                        background: viewWindow ? 'rgb(241, 240, 241' : '#fff',
                        // color: '#2e2d2d',
                      }}
                      value={loanInfo.LoanTerm}
                    >
                      {[...new Array(121)].map((i, val) => (
                        <option value={i}>{val == 0 ? null : val}</option>
                      ))}
                    </select>
                    <p style={{ color: '#597a96', fontSize: '0.7rem' }}>
                      Pastaba. Minimalus terminas 12 mėn. Maksimalus apyvartinės
                      paskolos terminas 36 mėn., investicinės paskolos - 120
                      mėn.).
                    </p>
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanDelayTerm')}
                    </label>
                    <select
                      disabled={viewWindow}
                      name="LoanDelayTerm"
                      style={{
                        background: 'rgb(241, 240, 241',
                      }}
                      value={loanInfo.LoanDelayTerm}
                    >
                      {[null, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                        (val) => (
                          <option value={val}>{val}</option>
                        )
                      )}
                    </select>
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <label
                      style={{
                        margin: '8px 0 8px',
                        color: '#597a96',
                        fontSize: '0.8rem',
                        lineHeight: '1.5',
                      }}
                    >
                      {requestDataLabelTranslation('LoanPaymentDay')}
                    </label>
                    <select
                      disabled={true}
                      name="LoanPaymentDay"
                      style={{
                        background: 'rgb(241, 240, 241',
                      }}
                      value={loanInfo.LoanPaymentDay}
                    >
                      {[null, 5, 15, 25].map((val) => (
                        <option value={val}>{val}</option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="col-lg-12 col-sm-12">
                    <p
                      className="text-style-new-request"
                      style={{ marginTop: '6px' }}
                    >
                      1. Veikia Lietuvos Respublikoje, kaip tai detaliau
                      apibūdinta Apraše; <br />
                      2. Atitinka minimalius Lietuvos Respublikos mokesčių
                      administravimo įstatymo 401 straipsnyje nustatytus
                      patikimų mokesčių mokėtojų kriterijus;
                      <br />
                      3. Jam nėra iškelta nemokumo byla ir (ar) jis nėra
                      restruktūrizuojamas pagal Lietuvos Respublikos juridinių
                      asmenų nemokumo įstatymą arba iki jo įsigaliojimo
                      galiojusius Lietuvos Respublikos įmonių bankroto įstatymą
                      ir Lietuvos Respublikos restruktūrizavimo įstatymą; / jam
                      nėra iškelta fizinio asmens bankroto byla pagal Lietuvos
                      Respublikos fizinių asmenų bankroto įstatymą; <br />
                      4. Nėra gavęs neteisėtos pagalbos, kuri Europos Komisijos
                      sprendimu (dėl individualios pagalbos arba pagalbos
                      schemos) buvo pripažinta neteisėta ir nesuderinama su
                      vidaus rinka, arba yra grąžinęs visą jos sumą, įskaitant
                      palūkanas, teisės aktuose nustatyta tvarka. <br />
                    </p>
                  </div> */}
                </div>
              )}

              {/* inline checkBoxes */}
              {checks != null && (
                <div
                  className="col-lg-12 col-sm-12"
                  style={{ paddingLeft: '5px' }}
                >
                  {/* {Object.keys(checks).map((key, i) => { */}

                  {agreementsCheckBoxesArray.map((key, i) => {
                    if (isCheckBox(key) === true) {
                      return (
                        <div className="checkbox" key={i}>
                          <label className="containerLabel noBlock">
                            <input
                              type="checkbox"
                              name={key}
                              checked={checks[key]}
                              disabled={true}
                            />
                            <span className="checkmark"></span>
                            {/* {checks[key] === 'Check1Confirment' ? check1Confirment() : requestDataLabelTranslation(key)} */}
                            {key === 'Check1Confirment'
                              ? check1Confirment()
                              : requestDataLabelTranslation(key)}
                          </label>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {requestsState.data.CompanyData && ShowQuestionList() && (
          <div style={{ marginTop: '2rem' }} className="container">
            <h4>
              ATITIKIMO KOMISIJOS REGLAMENTE (ES) NR. 651/2014 NUSTATYTIEMS
              PAREIŠKĖJUI TAIKOMIEMS REIKALAVIMAMS KLAUSIMYNAS
            </h4>
            <div style={{ paddingBottom: '30px' }}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className={styles.tableHeaderLeft}>Kriterijai</th>
                    <th className={styles.tableHeaderRight}>
                      <span>Taip</span>
                      <span>Ne</span>
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.qtabletBody}>
                  {questionListArray.map((val, i) => (
                    <>
                      <tr key={i}>
                        <td
                          style={{
                            paddingLeft: '15px',
                            paddingRight: '25px',
                            color: '#597a96',
                            lineHeight: '20px',
                          }}
                        >
                          {val === 'IsSmall'
                            ? isSmallText()
                            : questionListTranslations(val)}
                        </td>
                        <td>
                          {' '}
                          <div className={styles.radioButtonsBox}>
                            {' '}
                            <input
                              type="radio"
                              className={styles.radioButton}
                              value={true}
                              checked={
                                requestsState.data.Questionnaire[val] == true
                              }
                              name={val}
                              disabled={viewWindow}
                            />{' '}
                            <input
                              type="radio"
                              className={styles.radioButton}
                              value={false}
                              checked={
                                requestsState.data.Questionnaire[val] == false
                              }
                              name={val}
                              disabled={viewWindow}
                            />
                          </div>
                        </td>
                      </tr>
                      {requestsState.data.Questionnaire[val] == false && (
                        <tr>
                          <td>
                            <textarea
                              style={{ width: '100%', height: '50px' }}
                              placeholder="Komentaras"
                              name={`${val}Comment`}
                              type="string"
                              value={
                                requestsState.data.Questionnaire[
                                  `${val}Comment`
                                ]
                              }
                              disabled={viewWindow}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <SurwayAnswers Ratings={requestsState?.data?.Ratings} />
        {fileArr != null && fileArr.length > 0 && (
          <div className="col-lg-12 col-sm-12" style={{ marginTop: '2rem' }}>
            <h4>Įkelti priedai</h4>
            <TableAdmin header={tableHeader} isEmpty={false}>
              {fileArr.map(
                ({ Id, FileName, UploadedAt, Additional, FileType }) => (
                  <TableRow key={Id}>
                    <TableCell>{fileTypeTranslation2(FileType)}</TableCell>

                    <TableCell>{FileName}</TableCell>
                    <TableCell>
                      {format(new Date(UploadedAt), 'yyyy-MM-dd')}
                    </TableCell>
                    <TableCell>{Additional ? 'Taip' : 'Ne'}</TableCell>
                    <TableCell>
                      <div className={uploadStyles.iconsContainer}>
                        <Link
                          onClick={() => downloadUploadedFile(Id, FileName)}
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            className={styles.icon}
                            style={{
                              color: '#597a96',
                            }}
                          />
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
             </TableAdmin>
            <br />
            <br />
            <WithSpinner loading={isLoading}>
              <Button
                type="standart"
                onClick={() => downloadAllFiles()}
                float="right"
              >
                Atsisiųsti visus įkeltus priedus
              </Button>
            </WithSpinner>
          </div>
        )}

        <div
          className="container"
          style={{ marginTop: '30px', paddingLeft: '0px' }}
        >
          <h4 style={{ marginLeft: '1rem' }}>Susijusios paraiškos </h4>
          <div className="col-lg-12">
            {' '}
            {requestsState.relatedRequests.length ? (
              <TableAdmin header={relatedRequestsHeader} isEmpty={false}>
                {requestsState.relatedRequests.map((val) => {
                  return (
                    <TableRow>
                      <TableCell>{val.Id}</TableCell>
                      <TableCell>{formatNumber(val.LoanSum)}</TableCell>
                      <TableCell>{loanPurpose(val.LoanPurpose)}</TableCell>
                      <TableCell>{requestStatus(val.Status)}</TableCell>

                      <TableCell>
                      <Link
                          to={
                            val.FormVersion === 2 || val.FormVersion == null || val.FormVersion === 3
                              ? `/backoffice/request/${val.Id}`
                              : `/backoffice/request-2/${val.Id}`
                          }
                        >
                          Eiti į paraišką
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableAdmin>
            ) : (
              <p>Nėra susijusių paraiškų</p>
            )}
          </div>
        </div>

        <div
          className="container"
          style={{ marginTop: '30px', paddingLeft: '0px' }}
        >
          <h4 style={{ marginLeft: '1rem' }}>Įgaliojimai </h4>
          <div className="col-lg-12">
            {' '}
            {requestsState?.representatives?.length ? (
              <TableAdmin header={representativesHeader} isEmpty={false}>
                {requestsState?.representatives != null &&
                  requestsState?.representatives.map(
                    ({
                      Id,
                      FirstName,
                      LastName,
                      PersonalCode,
                      ValidFrom,
                      ValidTo,
                      RepresentedCompanyCode,
                      RepresentedPersonalCode,
                      Files,
                    }) => (
                      <TableRow key={Id}>
                        <TableCell>{FirstName}</TableCell>
                        <TableCell>{LastName}</TableCell>
                        <TableCell>{PersonalCode}</TableCell>
                        <TableCell>{RepresentedCompanyCode}</TableCell>
                        <TableCell>{RepresentedPersonalCode}</TableCell>
                        <TableCell>{ValidFrom}</TableCell>
                        <TableCell>{ValidTo}</TableCell>
                        <TableCell>
                          {Files?.map((f) => (
                            <div>
                              <Link
                                onClick={() => downloadFile(Id, f.FileId)}
                                style={{
                                  color: '#009fe3',
                                  textDecoration: 'none',
                                }}
                              >
                                {`${
                                  f.FileTypeId === 0
                                    ? 'Įgaliojimas Nr.'
                                    : 'Dokumentas Nr.'
                                } ${f.FileId}`}
                              </Link>
                            </div>
                          ))}
                        </TableCell>

                        {/*<TableCell>
                          <div className={styles.iconsContainer}>
                            <Link onClick={() => editAutorization(Id)}>
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                className={styles.icon}
                                style={{
                                  color: '#597a96',
                                }}
                              />
                            </Link>
                            <Link onClick={() => actionClick(Id)}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={styles.icon}
                                style={{
                                  color: '#597a96',
                                }}
                              />
                            </Link>
                          </div>
                              </TableCell>*/}
                      </TableRow>
                    )
                  )}
              </TableAdmin>
            ) : (
              <p>Nėra įgaliojimų</p>
            )}
          </div>
        </div>
        <div className="col-lg-12 col-sm-12" style={{ marginTop: '2rem' }}>
          <h2 style={{ marginTop: '1rem', paddingBottom: '20px' }}>
            Patikrų rezultatai
          </h2>
          <h4 style={{ marginBottom: '0px' }}>AML ir PEP patikrų rezultatai</h4>

          {requestsState.data != null &&
          requestsState.data.AmlChecks != null &&
          requestsState.data.AmlChecks.length > 0 ? (
            <>
              <TableAdmin header={amlPepTableHeader} isEmpty={false}>
                {requestsState.data.AmlChecks.map(
                  ({ Query, HasSanctions, HasPep, CheckTypeId,  CheckResultId,
                    AmlCount100,
                    AmlCount85,
                    PepCount100,
                    PepCount85, }) => (
                    <TableRow>
                      <TableCell>{Query}</TableCell>
                      <TableCell>
                          {checkTypeIdTranslate(CheckTypeId)}
                        </TableCell>
                      <TableCell>{checkTypeIdTranslate(CheckTypeId)}</TableCell>

                      <TableCell>
                        <p
                          style={{
                            color: HasSanctions ? 'red' : 'green',
                          }}
                        >
                          {amlPepTrueFalseTranslation(HasSanctions)}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p
                          style={{
                            color: HasPep ? 'red' : 'green',
                          }}
                        >
                          {amlPepTrueFalseTranslation(HasPep)}
                        </p>
                      </TableCell>
                      <TableCell>
                          {CheckResultIdTranslation(CheckResultId)}
                        </TableCell>
                        <TableCell>{AmlCount100}</TableCell>
                        <TableCell>{PepCount100}</TableCell>
                        <TableCell>{AmlCount85}</TableCell>
                        <TableCell>{PepCount85}</TableCell>
                    </TableRow>
                  )
                )}
              </TableAdmin>
            </>
          ) : (
            <p>Rezultatų nėra</p>
          )}

          {requestsState.data != null &&
            requestsState.data.AmlCheckResultId != null && (
              <h5 style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Bendras patikros rezultatas:{' '}
                {AmlPepResultTranslate(requestsState.data.AmlCheckResultId)}
              </h5>
            )}
        </div>

        {/* {requestsState.data.AmlCheckResultId == 4 && ( */}
        <div className="col-lg-12 col-sm-12" style={{ marginTop: '2rem' }}>
          <div>
            <h4> Papildomos AML/PEP patikros įvedimas</h4>

            <TableAdmin header={AdditionalCheckTableHeader} isEmpty={false}>
              {requestsState.data &&
                requestsState.data.RiskAssessmentResults &&
                riskAssessmentResults?.length > 0 &&
                riskAssessmentResults.map((val, index) => (
                  <TableRow key={index}>
                    <TableCell>{criteriaIdEnums(val.CriteriaId)}</TableCell>
                    <TableCell>
                      {checkTypeIdTranslate(val.AmlCheckTypeId)}
                    </TableCell>
                    <TableCell>{val.CheckName}</TableCell>
                    <TableCell>{fieldIdEnums(val.RiskAssessmentFieldId)}</TableCell>
                    <TableCell>{val.AssessmentValue}</TableCell>

                    <TableCell>
                      {editAmlPepTable ? (
                        <select
                          value={val.ResultId}
                          onChange={(e) =>
                            onAmlPepResultIdChange(
                              val.Id,
                              e.target.value !== null ? +e.target.value : null
                            )
                          }
                        >
                          <option value={null}> </option>
                          <option value={3}> {resultIdEnum(3)} </option>
                          <option value={2}> {resultIdEnum(2)} </option>
                          <option value={1}> {resultIdEnum(1)}</option>
                        </select>
                      ) : (
                        resultIdEnum(val.ResultId)
                      )}
                    </TableCell>
                    <TableCell>
                      {editAmlPepTable ? (
                        <Input
                          key={index}
                          type="string"
                          value={val.Note}
                          onChange={(e) =>
                            onAmlPepNoteChange(val.Id, e.target.value)
                          }
                        />
                      ) : (
                        val.Note
                      )}
                    </TableCell>
                    <TableCell>
                      {editAmlPepTable ? (
                        <DatePicker
                          className="inlineDatepickerInput"
                          maxDate={new Date()}
                          selected={val.Date != null ? new Date(val.Date) : ''}
                          dateFormat="yyyy-MM-dd"
                          onChange={(e) => onAmlPepDateChange(val.Id, e)}
                        />
                      ) : val.Date ? (
                        <p style={{ minWidth: '80px' }}>
                          {format(new Date(val.Date), 'yyyy-MM-dd')}
                        </p>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableAdmin>
            {!editAmlPepTable && (
              <div>
                <Button type="longBtn" onClick={() => setEditAmlPepTable(true)}>
                  Redaguoti
                </Button>
              </div>
            )}

            {editAmlPepTable && (
              <div>
                <Button
                  type="longBtn"
                  onClick={() => saveAmlPepAdditionalData()}
                >
                  Išsaugoti
                </Button>
                <Button
                  type="cancel"
                  onClick={() => setEditAmlPepTable(false)}
                  style={{ marginLeft: '10px' }}
                >
                  Uždaryti
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              background: '#fff',
              marginTop: '1.5rem',
            }}
          >
            <h5
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                fontSize: '16px',
                color: '#597a96',
                marginRight: '15px',
                width: '173px',
              }}
            >
              AML rizikos grupė:
            </h5>
            {editRiskTable ? (
              <select
                value={riskLevel}
                onChange={(e) =>
                  setRiskLevel(e.target.value !== null ? +e.target.value : null)
                }
              >
                <option value={null}> </option>
                <option className={styles.greenInput} value={3}>
                  {riskIdEnum(3)}
                </option>
                <option className={styles.yellowInput} value={2}>
                  {riskIdEnum(2)}
                </option>
                <option className={styles.redInput} value={1}>
                  {riskIdEnum(1)}
                </option>
              </select>
            ) : (
              <p
                style={{
                  marginTop: '12px',
                  fontSize: '16px',
                  marginLeft: '-27px',
                }}
                className={returnRiskLevelClass(riskLevel)}
              >
                {riskIdEnum(riskLevel)}
              </p>
            )}
          </div>
          {!editRiskTable && (
            <div>
              <Button type="longBtn" onClick={() => setEditRiskTable(true)}>
                Redaguoti
              </Button>
            </div>
          )}

          {editRiskTable && (
            <div>
              <Button type="longBtn" onClick={() => saveRiskLevel()}>
                Išsaugoti
              </Button>
              <Button
                type="cancel"
                onClick={() => setEditRiskTable(false)}
                style={{ marginLeft: '10px' }}
              >
                Uždaryti
              </Button>
            </div>
          )}
          {/*<Button
              type="longBtn2"
              onClick={() => saveRiskLevel()}
              style={{ marginLeft: '10px' }}
            >
              Išsaugoti
                      </Button>*/}
        </div>

        <WithSpinner loading={additionalRequestsLoading}>
          <div className="col-lg-12 col-sm-12">
            {' '}
            <br />
            <h3>Pakartotinės užklausos</h3>
            <br />
            <br />
            <div className="flex">
              <Button
                type="longBtn2"
                onClick={() => additionalJadisRequest()}
                style={{ marginLeft: '10px' }}
              >
                Pakartoti JADIS užklausą
              </Button>
              <Button
                type="longBtn2"
                onClick={() => additionalAMLRequest()}
                style={{ marginLeft: '10px' }}
              >
                Pakartoti AML užklausą
              </Button>
            </div>
          </div>
        </WithSpinner>
        <div style={{ display: 'flex', width: '100%', background: '#fff' }}>
          <div style={{ width: '50%' }}>
            {requestsState &&
              requestsState.data &&
              requestsState.data.AllInvoicesConfirmed != undefined && (
                <>
                  <div
                    className="col-lg-12 col-sm-12"
                    style={{ marginTop: '2rem' }}
                  >
                    <h4 style={{ marginBottom: '0px' }}>
                      Ar visos patikros įvykdytos
                    </h4>
                    <p>
                      Ar paraiškos visos patikros įvykdytos:
                      <span
                        style={{
                          color:
                            !requestsState.data.RequestSystemInfo
                              .AllEvaluationCompleted && 'red',
                        }}
                      >
                        {' '}
                        {trueFalseTranslation(
                          requestsState.data.RequestSystemInfo
                            .AllEvaluationCompleted
                        )}
                      </span>
                    </p>
                  </div>
                </>
              )}
          </div>

          {/*<div className="col-lg-6 col-sm-6" style={{ marginTop: '2rem' }}>

            {((requestsState.data.FinancialData && loginType === 'Company') ||
              (requestsState.data.ControllingCompaniesFromRequest &&
                requestsState.data.ControllingCompaniesFromRequest.length >
                  0)) &&
              requestsState.data.FinancialData.Fsi2022Submitted != null && (
                <h4 style={{ marginTop: '2rem', marginBottom: '0px' }}>
                  Ar pateikta 2022m. FA
                </h4>
              )}
            {requestsState.data.FinancialData &&
              loginType === 'Company' &&
              requestsState.data.FinancialData.Fsi2022Submitted != null && (
                <p>
                  <span>Ar pareiškėjas pateikė 2022m. FA: </span>
                  <span
                    style={{
                      color: setFAcolor(
                        requestsState.data.FinancialData.Fsi2022Submitted,
                        2022,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2022
                      ),
                    }}
                  >
                    {fsiTranslate(
                      requestsState.data.FinancialData.Fsi2022Submitted,
                      setFAcolor(
                        requestsState.data.FinancialData.Fsi2022Submitted,
                        2022,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2022
                      ) === '#033878'
                    )}
                  </span>
                </p>
              )}
            {requestsState.data.ControllingCompaniesFromRequest &&
              requestsState.data.FinancialData.Fsi2022Submitted != null &&
              requestsState.data.ControllingCompaniesFromRequest.map((item) => (
                <p>
                  <span>
                    Ar grupės įmonė kodu {item.CompanyCode} pateikė 2022m. FA:{' '}
                  </span>
                  <span
                    style={{
                      color: setFAcolor(
                        item.Fsi2022Submitted,
                        2022,
                        item.FsiYear2022
                      ),
                    }}
                  >
                    {' '}
                    {fsiTranslate(
                      item.Fsi2022Submitted,
                      setFAcolor(
                        item.Fsi2022Submitted,
                        2022,
                        item.FsiYear,
                        item.FsiYear2022
                      ) === '#033878'
                    )}{' '}
                  </span>
                </p>
              ))}
            
            {((requestsState.data.FinancialData && loginType === 'Company') ||
              (requestsState.data.ControllingCompaniesFromRequest &&
                requestsState.data.ControllingCompaniesFromRequest.length >
                  0)) &&
              requestsState.data.FinancialData.Fsi2021Submitted != null && (
                <h4 style={{ marginTop: '2rem', marginBottom: '0px' }}>
                  Ar pateikta 2021m. FA
                </h4>
              )}
            {requestsState.data.FinancialData &&
              loginType === 'Company' &&
              requestsState.data.FinancialData.Fsi2021Submitted != null && (
                <p>
                  <span>Ar pareiškėjas pateikė 2021m. FA: </span>
                  <span
                    style={{
                      color: setFAcolor(
                        requestsState.data.FinancialData.Fsi2021Submitted,
                        2021,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2021
                      ),
                    }}
                  >
                    {fsiTranslate(
                      requestsState.data.FinancialData.Fsi2021Submitted,
                      setFAcolor(
                        requestsState.data.FinancialData.Fsi2021Submitted,
                        2021,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2021
                      ) === '#033878'
                    )}
                  </span>
                </p>
              )}
            {requestsState.data.ControllingCompaniesFromRequest &&
              requestsState.data.FinancialData.Fsi2021Submitted != null &&
              requestsState.data.ControllingCompaniesFromRequest.map((item) => (
                <p>
                  <span>
                    Ar grupės įmonė kodu {item.CompanyCode} pateikė 2021m. FA:{' '}
                  </span>
                  <span
                    style={{
                      color: setFAcolor(
                        item.Fsi2021Submitted,
                        2021,
                        item.FsiYear,
                        item.FsiYear2021
                      ),
                    }}
                  >
                    {' '}
                    {fsiTranslate(
                      item.Fsi2021Submitted,
                      setFAcolor(
                        item.Fsi2021Submitted,
                        2021,
                        item.FsiYear,
                        item.FsiYear2021
                      ) === '#033878'
                    )}{' '}
                  </span>
                </p>
              ))}
          
            {((requestsState.data.FinancialData && loginType === 'Company') ||
              (requestsState.data.ControllingCompaniesFromRequest &&
                requestsState.data.ControllingCompaniesFromRequest.length >
                  0)) &&
              !checkIf2022FinancesToShow() &&
              requestsState.data.FinancialData.Fsi2020Submitted !== null && (
                <h4 style={{ marginTop: '2rem', marginBottom: '0px' }}>
                  Ar pateikta 2020m. FA
                </h4>
              )}
            {requestsState.data.FinancialData &&
              !checkIf2022FinancesToShow() &&
              loginType === 'Company' &&
              requestsState.data.FinancialData.Fsi2020Submitted !== null && (
                <p>
                  <span>Ar pareiškėjas pateikė 2020m. FA: </span>
                  <span
                    style={{
                      color: setFAcolor(
                        requestsState.data.FinancialData.Fsi2020Submitted,
                        2020,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2020
                      ),
                    }}
                  >
                    {' '}
                    {fsiTranslate(
                      requestsState.data.FinancialData.Fsi2020Submitted,
                      setFAcolor(
                        requestsState.data.FinancialData.Fsi2020Submitted,
                        2020,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2020
                      ) === '#033878'
                    )}
                  </span>
                </p>
              )}
            {!checkIf2022FinancesToShow() &&
              requestsState.data.ControllingCompaniesFromRequest &&
              requestsState.data.FinancialData.Fsi2020Submitted !== null &&
              requestsState.data.ControllingCompaniesFromRequest.map((item) => (
                <p>
                  <span>
                    Ar grupės įmonė kodu {item.CompanyCode} pateikė 2020m. FA:{' '}
                  </span>
                  <span
                    style={{
                      color: setFAcolor(
                        item.Fsi2020Submitted,
                        2020,
                        item.FsiYear,
                        item.FsiYear2020
                      ),
                    }}
                  >
                    {' '}
                    {fsiTranslate(
                      item.Fsi2020Submitted,
                      setFAcolor(
                        item.Fsi2019Submitted,
                        2020,
                        item.FsiYear,
                        item.FsiYear2020
                      ) === '#033878'
                    )}{' '}
                  </span>
                </p>
              ))}
            
            {((requestsState.data.FinancialData && loginType === 'Company') ||
              (requestsState.data.ControllingCompaniesFromRequest &&
                requestsState.data.ControllingCompaniesFromRequest.length >
                  0)) &&
              !checkIf2022FinancesToShow() &&
              requestsState.data.FinancialData.Fsi2019Submitted !== null && (
                <h4 style={{ marginTop: '2rem', marginBottom: '0px' }}>
                  Ar pateikta 2019m. FA
                </h4>
              )}
            {requestsState.data.FinancialData &&
              !checkIf2022FinancesToShow() &&
              loginType === 'Company' &&
              requestsState.data.FinancialData.Fsi2020Submitted !== null && (
                <p>
                  <span>Ar pareiškėjas pateikė 2019m. FA: </span>
                  <span
                    style={{
                      color: setFAcolor(
                        requestsState.data.FinancialData.Fsi2019Submitted,
                        2019,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2019
                      ),
                    }}
                  >
                    {' '}
                    {fsiTranslate(
                      requestsState.data.FinancialData.Fsi2019Submitted,
                      setFAcolor(
                        requestsState.data.FinancialData.Fsi2019Submitted,
                        2019,
                        requestsState.data.FinancialData.FsiYear,
                        requestsState.data.FinancialData.FsiYear2019
                      ) === '#033878'
                    )}
                  </span>
                </p>
              )}
            {requestsState.data.ControllingCompaniesFromRequest &&
              requestsState.data.FinancialData.Fsi2019Submitted !== null &&
              !checkIf2022FinancesToShow() &&
              requestsState.data.ControllingCompaniesFromRequest.map((item) => (
                <p>
                  <span>
                    Ar grupės įmonė kodu {item.CompanyCode} pateikė 2019m. FA:{' '}
                  </span>
                  <span
                    style={{
                      color: setFAcolor(
                        item.Fsi2019Submitted,
                        2019,
                        item.FsiYear,
                        item.FsiYear2019
                      ),
                    }}
                  >
                    {' '}
                    {fsiTranslate(
                      item.Fsi2019Submitted,
                      setFAcolor(
                        item.Fsi2019Submitted,
                        2019,
                        item.FsiYear,
                        item.FsiYear2019
                      ) === '#033878'
                    )}
                  </span>
                </p>
              ))}
            </div>*/}
            <div className="col-lg-6 col-sm-6" style={{ marginTop: 'rem' }}>
            {((requestsState.data.FinancialData && loginType === 'Company') ||
              (requestsState.data.ControllingCompaniesFromRequest &&
                requestsState.data.ControllingCompaniesFromRequest.length >
                  0)) &&
              requestsState.data.FinancialData?.length > 0 && (
                <h4 style={{ marginTop: '2rem', marginBottom: '0px' }}>
                  Ar pateiktos finansinės ataskaitos
                </h4>
              )}
            {requestsState.data.FinancialData &&
              loginType === 'Company' &&
              requestsState.data.FinancialData?.length > 0 && (
                <FinancialTable
                  data={requestsState?.data?.FinancialData}
                  fsi
                  regDate={
                    requestsState?.data?.CompanyData?.CompanyRegistrationDate
                  }
                />
              )}

            {requestsState?.data?.ControllingCompaniesFromRequest?.length >
              0 && (
              <h4 style={{ marginTop: '2rem', marginBottom: '0px' }}>
                Ar pateiktos įmonės grupės finansinės ataskaitos
              </h4>
            )}

            {requestsState?.data?.ControllingCompaniesFromRequest?.length > 0 &&
              requestData?.ControllingCompaniesFromRequest?.map((val, i) => {
                return (
                  <>
                    <div
                      className={styles.titleContainer}
                      style={{ marginTop: '16px' }}
                    ></div>
                    <p
                      style={{
                        marginTop: '1rem',
                        marginLeft: '0',
                        fontSize: '1rem',
                      }}
                    >
                      Verslo subjekto kodas {val.CompanyCode}
                    </p>
                    {val.IsFound ? (
                      <>
                        <div
                          className="col-lg-12 col-sm-12"
                          key={i}
                          style={{ paddingLeft: '0px' }}
                        ></div>

                        <FinancialTable
                          data={val?.Financials}
                          fsi
                          regDate={val.RegistrationDate}
                        />
                      </>
                    ) : (
                      <p style={{ color: 'red' }}>Įmonė nerasta.</p>
                    )}
                  </>
                );
              })}
          </div>

          <div className="col-lg-6 col-sm-12" style={{ marginTop: '2rem' }}>
            <div>
              <p> Tvirtintojo komentaras</p>
              <div className="inputs-block">
                <textarea
                  style={{ width: '100%', height: '100px' }}
                  placeholder="Tvirtintojo komentaras"
                  name="Comment"
                  type="string"
                  disabled={false}
                  value={approversComment}
                  onChange={saveApproversComment}
                />
              </div>
            </div>
            <div style={{ marginTop: '1rem' }}>
              <Link
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                }}
                onClick={saveComment}
              >
                <Button type="longBtn">Išsaugoti komentarą</Button>
              </Link>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', background: '#fff' }}>
          <div className="col-lg-12 col-sm-12" style={{ marginTop: '2rem' }}>
            <h4 style={{ marginBottom: '0px' }}>Akcininkai</h4>

            {requestsState.data &&
            requestsState.data.SharesInfo &&
            requestsState.data.SharesInfo.length > 0 ? (
              <TableAdmin header={jadisTableHeader} isEmpty={false}>
                {requestsState.data &&
                  requestsState.data.SharesInfo &&
                  requestsState.data.SharesInfo.length > 0 &&
                  requestsState.data.SharesInfo.map(
                    ({
                      Code,
                      Name,
                      Shares,
                      Type,
                      DataRecieved,
                      DataUpdated,
                      CapitalSize,
                      CapitalSizeCurrency,
                      NumberOfShares,
                      NominalValue,
                      NominalValueCurrency,
                    }) => (
                      <TableRow>
                        <TableCell>
                          {`${Name ? Name : ''}${Name ? ',' : ''} ${
                            Code ? Code : ''
                          }`}
                        </TableCell>
                        <TableCell>{shareHolderTypeTranslate(Type)}</TableCell>
                        <TableCell>
                          {Shares &&
                            Shares.length > 0 &&
                            Shares.map((val, i) => <span> {val}</span>)}
                        </TableCell>
                        <TableCell>
                          {DataRecieved ? DataRecieved.slice(0, 10) : '-'}
                        </TableCell>
                        <TableCell>
                          {DataUpdated ? DataUpdated.slice(0, 10) : '-'}
                        </TableCell>
                        <TableCell>
                          {CapitalSize || CapitalSize === 0
                            ? `${CapitalSize} ${CapitalSizeCurrency ?? ''}`
                            : '-'}
                        </TableCell>
                        <TableCell>{NumberOfShares ?? '-'}</TableCell>
                        <TableCell>
                          {NominalValue || NominalValue === 0
                            ? `${NominalValue} ${NominalValueCurrency ?? ''}`
                            : '-'}
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableAdmin>
            ) : (
              <p>Akcininkų nėra</p>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', background: '#fff' }}>
          <div className="col-lg-6 col-sm-6">
            {loginType === 'Company' && (
              <div className="" style={{ marginTop: '2rem' }}>
                <h4 style={{ marginBottom: '0px' }}>EVRK veiklos iš VMI</h4>

                {requestsState.data &&
                requestsState.data.CompanyEvrks &&
                requestsState.data.CompanyEvrks.length > 0 ? (
                  <TableAdmin header={evrkTableHeader} isEmpty={false}>
                    {requestsState.data &&
                      requestsState.data.CompanyEvrks &&
                      requestsState.data.CompanyEvrks.length > 0 &&
                      requestsState.data.CompanyEvrks.map(
                        ({ DateFrom, DateTo, Evrk, IsMain, IsRed }, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {IsRed && addExclamation()}
                              {Evrk}
                            </TableCell>
                            <TableCell>
                              {DateFrom
                                ? format(new Date(DateFrom), 'yyyy-MM-dd')
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {DateTo
                                ? format(new Date(DateTo), 'yyyy-MM-dd')
                                : 'Dabar'}
                            </TableCell>
                            <TableCell>{IsMain ? 'Taip' : 'Ne'}</TableCell>
                          </TableRow>
                        )
                      )}
                  </TableAdmin>
                ) : (
                  <p>EVRK veiklų nėra</p>
                )}
              </div>
            )}
            {loginType === 'Individual' && (
              <div className="" style={{ marginTop: '2rem' }}>
                <h4 style={{ marginBottom: '0px' }}>EVRK veiklos iš VMI</h4>

                {requestsState.data &&
                requestsState.data.IndividualEvrks &&
                requestsState.data.IndividualEvrks.length > 0 ? (
                  <TableAdmin
                    header={evrkTableHeaderIndivuduals}
                    isEmpty={false}
                  >
                    {requestsState.data &&
                      requestsState.data.IndividualEvrks &&
                      requestsState.data.IndividualEvrks.length > 0 &&
                      requestsState.data.IndividualEvrks.map(
                        (
                          { DateFrom, DateTo, Evrk, RegistrationDate, IsRed },
                          index
                        ) => (
                          <TableRow key={index}>
                            <TableCell>
                              {IsRed && addExclamation()}
                              {Evrk}
                            </TableCell>
                            <TableCell>
                              {DateFrom
                                ? format(new Date(DateFrom), 'yyyy-MM-dd')
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {DateTo
                                ? format(new Date(DateTo), 'yyyy-MM-dd')
                                : 'Dabar'}
                            </TableCell>
                            <TableCell>
                              {RegistrationDate
                                ? format(
                                    new Date(RegistrationDate),
                                    'yyyy-MM-dd'
                                  )
                                : '-'}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableAdmin>
                ) : (
                  <p>EVRK veiklų nėra</p>
                )}

                {requestsState.data.ApplicantData && (
                  <p style={{ fontSize: '16px', marginTop: '1rem' }}>
                    Veikla pagal verslo liudijimą:{' '}
                    {translateIsBussinessSorrow(
                      requestsState.data.ApplicantData.IsBussinessSorrow
                    )}{' '}
                  </p>
                )}
              </div>
            )}
            <div style={{ width: '50%' }}>
              {requestsState &&
                requestsState.data &&
                requestsState.data.RequestSystemInfo &&
                requestsState.data.RequestSystemInfo.Status != 1 && (
                  <>
                    <div
                      className="col-lg-12 col-sm-12"
                      style={{
                        marginTop: '2rem',
                        width: '500px',
                        paddingLeft: '0px',
                      }}
                    >
                      <h4 style={{ marginBottom: '0px', width: '500px' }}>
                        Įmonės amžius
                      </h4>
                      <p style={{ marginTop: '10px', width: '500px' }}>
                        Įmonės/veiklos vykdymo amžius (metais):
                        {
                          <span style={{ marginLeft: '5px' }}>
                            {requestsState.data.CompanyAge}
                          </span>
                        }
                      </p>
                      <br />
                      <h4 style={{ marginBottom: '0px', width: '500px' }}>
                        Paraiškoje įvesti duomenys (apskaičiuota sistemos):
                      </h4>
                      <p style={{ marginTop: '10px', width: '500px' }}>
                        Taikoma valstybės pagalbos schema:
                        {
                          <span style={{ marginLeft: '5px' }}>
                            {helpSchem(requestsState.data.HelpSchema)}
                          </span>
                        }
                      </p>
                      <br/>
                      <br/>
                      <h4 style={{ marginBottom: '0px', width: '500px' }}>
                        Vertintojo įvesti duomenys
                      </h4>
                      <p style={{ marginTop: '10px', width: '500px' }}>
                        Verslo subjekto statusas:
                        {
                          <span style={{ marginLeft: '5px' }}>
                            {CompanySizeTypeInvega(
                              requestsState.data.CompanySizeTypeIdInvega
                            )}
                          </span>
                        }
                      </p>

                      {loginType === 'Individual' && (
                        <>
                          <p style={{ marginTop: '10px', width: '500px' }}>
                            Apyvarta, EUR:
                            {
                              <span style={{ marginLeft: '5px' }}>
                                {formatNumber(
                                  requestsState.data.InvegaTurnover
                                )}
                              </span>
                            }
                          </p>
                          <p style={{ marginTop: '10px', width: '500px' }}>
                            Turtas, EUR:
                            {
                              <span style={{ marginLeft: '5px' }}>
                                {formatNumber(requestsState.data.InvegaAssets)}
                              </span>
                            }
                          </p>
                        </>
                      )}

                      <p style={{ marginTop: '10px', width: '500px' }}>
                        Valstybės pagalbos schema (įvesta tvirtinant paraišką):
                        {
                          <span style={{ marginLeft: '5px' }}>
                            {helpSchemInvega(
                              requestsState.data.StateHelpSchemaId
                            )}
                          </span>
                        }
                      </p>
                      <p style={{ marginTop: '10px', width: '500px' }}>
                        De Minimis (įvestas tvirtinant paraišką), EUR:
                        {
                          <span style={{ marginLeft: '5px' }}>
                            {formatNumber(requestsState.data.DeMinimis)}
                          </span>
                        }
                      </p>
                    </div>
                  </>
                )}
            </div>
            <div style={{ width: '50%' }}>
              {requestsState &&
                requestsState.data &&
                requestsState.data.RequestSystemInfo &&
                requestsState.data.RequestSystemInfo.Status === 1 && (
                  <>
                    <div
                      className="col-lg-12 col-sm-12"
                      style={{
                        marginTop: '2rem',
                        width: '500px',
                        paddingLeft: '0px',
                      }}
                    >
                      <h4 style={{ marginBottom: '0px', width: '500px' }}>
                        Vertintojo įvesti duomenys
                      </h4>
                      <p style={{ marginTop: '10px', width: '500px' }}>
                        Paraiškos netrynimo požymis:
                        {
                          <span style={{ marginLeft: '5px' }}>
                            {requestsState.data.RequestSystemInfo
                              .NotDeletable === true
                              ? 'Negali būti ištrinta'
                              : 'Gali būti ištrinta'}
                          </span>
                        }
                      </p>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-sm-12">
          {requestsState &&
          requestsState.data &&
          requestsState.data.LoanLimit ? (
            <h4>
              Teikiama finansavimui suma{' '}
              {requestsState.data.LoanLimit.toFixed(2)} EUR.
            </h4>
          ) : (
            ''
          )}
          {requestsState &&
            requestsState.data &&
            requestsState.data.InvegaLoanSum != undefined && (
              <>
                <h4 style={{ marginTop: '2rem' }}>
                  Paskolos suma: {requestsState.data.InvegaLoanSum.toFixed(2)}{' '}
                  EUR
                </h4>
                {/*{loanInfo && loanInfo.SectorType === 1 && (
                  <h4 style={{ marginTop: '0rem' }}>
                    Padidinta paskolos suma:{' '}
                    {requestsState.data.InvegaIncreasedLoanSum ? (
                      <span style={{ color: 'red' }}>Taip</span>
                    ) : (
                      'Ne'
                    )}{' '}
                    <span style={{ color: 'red' }}>
                      {requestsState.data.InvegaIncreasedLoanSum &&
                        `(${requestsState.data.IncreasedDifference.toFixed(
                          2
                        )} Eur.) `}{' '}
                    </span>
                  </h4>
                        )}*/}
              </>
            )}

          {requestsState &&
            requestsState.data &&
            requestsState.data.RequestSystemInfo &&
            requestsState.data.RequestSystemInfo.Status === 5 && (
              <div>
                <>
                  <p style={{ marginTop: '10px', width: '500px' }}>
                    Paraišką pareiškėjas turi patikslinti iki:
                    {
                      <span style={{ marginLeft: '5px' }}>
                        {format(
                          new Date(requestsState?.data?.AdjustTill),
                          'yyyy-MM-dd'
                        )}
                      </span>
                    }
                  </p>
                </>
              </div>
            )}
          {requestsState &&
            requestsState.data &&
            requestsState.data.RequestSystemInfo && (
              <>
                <h4 style={{ marginTop: '2rem' }}>Invega komentaras</h4>

                <div className="inputs-block">
                  <textarea
                    style={{ width: '100%', height: '100px' }}
                    placeholder="Invega komentaras"
                    name="Comment"
                    type="string"
                    disabled={
                      requestsState.data.RequestSystemInfo.Status !== 2 &&
                      requestsState.data.RequestSystemInfo.Status !== 3 &&
                      requestsState.data.RequestSystemInfo.Status !== 5 &&
                      requestsState.data.RequestSystemInfo.Status !== 7 &&
                      requestsState.data.RequestSystemInfo.Status !== 8 &&
                      requestsState.data.RequestSystemInfo.Status !== 12 &&
                      requestsState.data.RequestSystemInfo.Status !== 11 &&
                      requestsState.data.RequestSystemInfo.Status !== 13 &&
                      requestsState.data.RequestSystemInfo.Status !== 14 &&
                      requestsState.data.RequestSystemInfo.Status !== 15 &&
                      requestsState.data.RequestSystemInfo.Status !== 17 &&
                      requestsState.data.RequestSystemInfo.Status !== 18 &&
                      requestsState.data.RequestSystemInfo.Status !== 19
                    }
                    value={comment}
                    onChange={onCommentChange}
                  />
                </div>
              </>
            )}
          {/* {requestsState &&
          requestsState.data &&
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.Status === 2 && (
            <div className="checkbox">
              <label className="containerLabel noBlock">
                <input
                  type="checkbox"
                  name={'userComment'}
                  checked={userCommentChecked}
                  disabled={false}
                  onChange={(e) => userComment(e)}
                />
                <span className="checkmark"></span>
                Pareiškėjas gali komentuoti
              </label>
            </div>
          )}

        {requestsState &&
          requestsState.data &&
          requestsState.data.RequestSystemInfo &&
          requestsState.data.UserComment && (
            <div style={{ marginTop: '2rem' }}>
              <h4>Pareiškėjo komentaras:</h4>
              <p>{requestsState.data.UserComment}</p>
            </div>
          )} */}
        </div>
        {signInInfo != null &&
          signInInfo[0] != null &&
          signInInfo[0].DocumentCreatedAt != null &&
          requestsState.data.RequestSystemInfo != null && (
            <div className="col-lg-12 col-sm-12" style={{ marginTop: '2rem' }}>
              <h4 style={{ marginTop: '1rem' }}>
                Sutartys, sprendimai ir prašymai
              </h4>
              <RowTableAdmin header={rowTableHeader} isEmpty={false}>
                {signInInfo.map((val, i) => (
                  <RowTableRow>
                    <RowTableCell>
                      {fileTypeTranslation(signInInfo[i].FileType)}
                    </RowTableCell>
                    <RowTableCell>{signInInfo[i].FileName}</RowTableCell>
                    <RowTableCell>
                      {' '}
                      {signInInfo[i].DocumentCreatedAt != null &&
                        format(
                          new Date(signInInfo[i].DocumentCreatedAt),
                          'yyyy-MM-dd HH:mm:ss'
                        )}
                    </RowTableCell>
                    <RowTableCell>
                      {' '}
                      {signInInfo[i].ApplicantSigningDate != null &&
                        format(
                          new Date(signInInfo[i].ApplicantSigningDate),
                          'yyyy-MM-dd HH:mm:ss'
                        )}
                    </RowTableCell>
                    <RowTableCell>
                      {' '}
                      {signInInfo[i].InvegaSigningDate != null &&
                        format(
                          new Date(signInInfo[i].InvegaSigningDate),
                          'yyyy-MM-dd HH:mm:ss'
                        )}
                    </RowTableCell>
                    <RowTableCell>
                      {signInInfo[i].CommentUser
                        ? messageShow(signInInfo[i].CommentUser)
                        : '-'}
                    </RowTableCell>
                    <RowTableCell>
                      {signInInfo[i].CommentInvega
                        ? messageShow(signInInfo[i].CommentInvega)
                        : '-'}
                    </RowTableCell>
                    <RowTableCell>
                      {signInInfo[i].BadFinancialCondition ? 'Taip' : ' '}
                    </RowTableCell>
                    <RowTableCell>
                      <p
                        className="pdownload"
                        onClick={() =>
                          GetSignedDocumentContractManager(
                            requestId,
                            val.FileType,
                            val.FileName,
                            val?.ChangeConditionsFileId,
                            val?.ApplicantSigningDate,
                            val?.InvegaSigningDate
                          )
                        }
                      >
                        Parsisiųsti
                      </p>

                      {signInInfo[i]?.Active &&
                        showChangeFile(signInInfo[i]?.FileType) && (
                          <>
                            <p
                              className="pdownload"
                              onClick={() =>
                                changeFile(
                                  signInInfo[i]?.ChangeConditionsFileId
                                )
                              }
                            >
                              Pakeisti failą
                            </p>

                            <input
                              type="file"
                              id={`${signInInfo[i]?.ChangeConditionsFileId}`}
                              accept={acceptFiles()}
                              className="d-none"
                              onChange={(e) =>
                                selectFile(
                                  e,
                                  signInInfo[i]?.ChangeConditionsFileId
                                )
                              }
                            />
                          </>
                        )}
                    </RowTableCell>

                    <RowTableCell>
                      {signInInfo[i].InvegaSigningDate === null &&
                        signInInfo[i].InvegaSignatureNeeded && (
                          <Button type="standart" onClick={() => sign()}>
                            Pasirašyti
                          </Button>
                        )}
                    </RowTableCell>
                  </RowTableRow>
                ))}
              </RowTableAdmin>
              {requestsState.data.RequestSystemInfo.Status !== 11 &&
                requestsState.data.RequestSystemInfo.Status !== 12 &&
                requestsState.data.RequestSystemInfo.Status !== 3 &&
                requestsState.data.RequestSystemInfo.Status !== 6 && (
                  <RowTableAdmin
                    header={comfirmedRequestHeader}
                    isEmpty={false}
                  >
                    <RowTableRow>
                      <RowTableCell>
                        {requestsState.data.InvegaContractNumber}
                      </RowTableCell>
                      <RowTableCell>
                        {formatNumber(requestsState.data.InvegaLoanSum)}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaLoanPaymentTerm &&
                          format(
                            new Date(requestsState.data.InvegaLoanPaymentTerm),
                            'yyyy-MM-dd'
                          )}
                      </RowTableCell>
                      <RowTableCell>
                      {/*{requestsState.data.InvegaLoanDelayTermDate
                          ? format(
                              new Date(
                                requestsState.data.InvegaLoanDelayTermDate
                              ),
                              'yyyy-MM-dd'
                            )
                              : requestsState.data.InvegaLoanDelayTerm} */}
                              {requestsState.data.InvegaLoanDelayTerm}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaPaymentDate
                          ? format(
                              new Date(requestsState.data.InvegaPaymentDate),
                              'yyyy-MM-dd'
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeId != 2
                          ? euriborTranslation(
                              requestsState.data.EuriborPeriodId
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeId != 2
                          ? requestsState.data.FirstInterestChangeDate &&
                            format(
                              new Date(
                                requestsState.data.FirstInterestChangeDate
                              ),
                              'yyyy-MM-dd'
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeId != 2
                          ? requestsState.data.EuriborChangeDate &&
                            format(
                              new Date(requestsState.data.EuriborChangeDate),
                              'yyyy-MM-dd'
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaLoanInterestRate}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaLoanVariableInterestRate}
                      </RowTableCell>
                    </RowTableRow>
                  </RowTableAdmin>
                )}

              {signInInfo != null &&
                signInInfo[0] != null &&
                signInInfo[0].DocumentCreatedAt != null &&
                requestsState.data.RequestSystemInfo != null &&
                (requestsState.data.RequestSystemInfo.Status === 14 ||
                  requestsState.data.RequestSystemInfo.Status === 15 ||
                  requestsState.data.RequestSystemInfo.Status === 16) && (
                  <RowTableAdmin header={changedRequestHeader} isEmpty={false}>
                    <RowTableRow>
                      <RowTableCell>
                        {requestsState.data.InvegaContractNumberCc}
                      </RowTableCell>
                      <RowTableCell>
                        {formatNumber(requestsState.data.InvegaLoanSumCc)}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaLoanPaymentTerm &&
                          format(
                            new Date(
                              requestsState.data.InvegaLoanPaymentTermCc
                            ),
                            'yyyy-MM-dd'
                          )}
                      </RowTableCell>

                      <RowTableCell>

                        {requestsState.data.InvegaLoanDelayTermCc}
                        {/*{requestsState.data.InvegaLoanDelayTermDateCc
                          ? format(
                              new Date(
                                requestsState.data.InvegaLoanDelayTermDateCc
                              ),
                              'yyyy-MM-dd'
                            )
                              : requestsState.data.InvegaLoanDelayTermDateCc}*/}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaPaymentDateCc
                          ? format(
                              new Date(requestsState.data.InvegaPaymentDateCc),
                              'yyyy-MM-dd'
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeIdCc != 2
                          ? euriborTranslation(
                              requestsState.data.EuriborPeriodIdCc
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeIdCc != 2
                          ? requestsState.data.FirstInterestChangeDateCc &&
                            format(
                              new Date(
                                requestsState.data.FirstInterestChangeDateCc
                              ),
                              'yyyy-MM-dd'
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeIdCc != 2
                          ? requestsState.data.EuriborChangeDateCc &&
                            format(
                              new Date(requestsState.data.EuriborChangeDateCc),
                              'yyyy-MM-dd'
                            )
                          : '-'}
                      </RowTableCell>
                      <RowTableCell>
                        {requestsState.data.InvegaLoanInterestRateCc}
                      </RowTableCell>
                      <RowTableCell>
                      {requestsState.data.InterestTypeIdCc != 2
                          ? requestsState.data.InvegaLoanVariableInterestRateCc
                          : '-'}
                      </RowTableCell>
                    </RowTableRow>
                  </RowTableAdmin>
                )}
            </div>
          )}

        {rejection && (
          <div className="col-lg-12 col-sm-12" style={{ marginTop: '1.5rem' }}>
            <h4> Sutarties nutraukimo dokumentas </h4>
            <RowTableAdmin header={rowTableHeader2} isEmpty={false}>
              <RowTableRow>
                <RowTableCell>Nutraukimo dokumentas</RowTableCell>
                <RowTableCell>{rejection.FileName}</RowTableCell>
                <RowTableCell>
                  <p
                    className="pdownload"
                    onClick={() =>
                      downloadUploadedFile(rejection.Id, rejection.FileName)
                    }
                  >
                    Parsisiųsti
                  </p>
                </RowTableCell>
              </RowTableRow>
            </RowTableAdmin>
          </div>
        )}

        {administrationInvoices != null &&
          administrationInvoices.length > 0 && (
            <div className="col-lg-12 col-sm-12" style={{ marginTop: '2rem' }}>
              <h4>Sąskaitos</h4>
              <RowTableAdmin
                header={administrationInvoicesTableHeader}
                isEmpty={false}
              >
                {administrationInvoices?.map(
                  ({
                    ContractId,
                    Active,
                    CreatedAt,
                    ContractNumber,
                    AdministrationFee,
                    ChangeConditionsFileId,
                  }) => (
                    <RowTableRow key={ContractId}>
                      <RowTableCell>{ContractNumber}</RowTableCell>
                      <RowTableCell>
                        {getContractName(ContractNumber)}
                      </RowTableCell>
                      <RowTableCell>
                        {format(new Date(CreatedAt), 'yyyy-MM-dd HH:mm:ss')}
                      </RowTableCell>
                      <RowTableCell>
                        {formatNumber(AdministrationFee)}
                      </RowTableCell>
                      <RowTableCell>
                        {Active ? 'Aktuali' : 'Neaktuali'}
                      </RowTableCell>
                      <RowTableCell>
                        <p
                          className="pdownload"
                          onClick={() =>
                            !ContractId && ChangeConditionsFileId
                              ? DownloadChangeConditionsInvoice(
                                  ChangeConditionsFileId,
                                  ContractNumber
                                )
                              : DownloadAdministrationInvoice(
                                  ContractId,
                                  ContractNumber
                                )
                          }
                        >
                          Parsisiųsti
                        </p>
                      </RowTableCell>
                    </RowTableRow>
                  )
                )}
              </RowTableAdmin>
            </div>
          )}

        <div className="row" style={{ marginTop: '2rem' }}>
          <div className="col-lg-3 col-sm-12 full-width-btn">
            <div
              className="col-lg-4 col-sm-12 full-width-btn"
              style={{ marginTop: '1rem' }}
            >
              <Link
                to="/backoffice/main"
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                }}
              >
                <Button type="standart">Paraiškų sąrašas</Button>
              </Link>
              {checkBOPage() &&
                (requestsState?.data?.RequestSystemInfo?.Status === 9 ||
                  requestsState?.data?.RequestSystemInfo?.Status === 13 ||
                  requestsState?.data?.RequestSystemInfo?.Status === 14 ||
                  requestsState?.data?.RequestSystemInfo?.Status === 15 ||
                  requestsState?.data?.RequestSystemInfo?.Status === 16 || 
                  requestsState?.data?.RequestSystemInfo?.Status === 17 || 
                  requestsState?.data?.RequestSystemInfo?.Status === 18 || 
                  requestsState?.data?.RequestSystemInfo?.Status === 19) && (
                  <div style={{ marginTop: '1rem' }}>
                    <Link
                      to={`${config.APP_PATHS.backofficePaymentRequests}?requestId=${requestsState.data.RequestSystemInfo.RequestId}`}
                      style={{
                        color: '#fff',
                        textDecoration: 'none',
                      }}
                    >
                      <Button type="standart">
                        Išmokėti paskolą arba jos dalį
                      </Button>
                    </Link>
                  </div>
                )}
            </div>
          </div>

          <div className="col-lg-3 col-sm-12 full-width-btn">
            <div style={{ marginTop: '1rem' }}>
              <Link
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                }}
                onClick={takeRequestToEvaluate}
              >
                <Button type="standart">Paskirti vertintoją</Button>
              </Link>
            </div>
            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status === 2 && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeCompanySize}
                  >
                    <Button type="standart">
                      Įvesti verslo subjekto statusą
                    </Button>
                  </Link>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status !== 1 &&
              loginType === 'Individual' && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={setAdditionalInfo}
                  >
                    <Button type="standart">Įvesti apyvartą ir turtą</Button>
                  </Link>
                </div>
              )}
          </div>

          <div
            className="col-lg-3 col-sm-12 full-width-btn"
            style={{
              textAlign: 'center',
              marginLeft: '-20px',
              marginRight: '20px',
            }}
          >
            {/* // check sužiūrėti būsenas */}
            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              (requestsState.data.RequestSystemInfo.Status === 3 ||
                requestsState.data.RequestSystemInfo.Status === 12) && (
                <div style={{ marginTop: '1rem' }}>
                  <Button type="standart" onClick={confirmClick}>
                    Pateikti pareiškėjo pasirašymui
                  </Button>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status === 2 && (
                <>
                  <div style={{ marginTop: '1rem' }}>
                    <Button
                      type="standart"
                      class="btn ui-button btn-dark-blue-standart long-btn"
                      onClick={confirmRequestNew}
                    >
                      Patvirtinti finansavimą
                    </Button>
                  </div>
                </>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              (requestsState.data.RequestSystemInfo.Status === 3 ||
                requestsState.data.RequestSystemInfo.Status === 12) && (
                <>
                  <div style={{ marginTop: '1rem' }}>
                    <Button
                      type="standart"
                      class="btn ui-button btn-dark-blue-standart long-btn"
                      onClick={confirmWithDocsClick}
                    >
                      Prašymas pateikti papildomus dokumentus
                    </Button>
                  </div>
                </>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status === 2 && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={returnClick}
                  >
                    <Button type="standart">Grąžinti tikslinimui</Button>
                  </Link>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              (requestsState.data.RequestSystemInfo.Status === 2 ||
                requestsState.data.RequestSystemInfo.Status === 3 ||
                requestsState.data.RequestSystemInfo.Status === 12 ||
                requestsState.data.RequestSystemInfo.Status === 11 ||
                requestsState.data.RequestSystemInfo.Status === 5 ||
                requestsState.data.RequestSystemInfo.Status === 7 ||
                requestsState.data.RequestSystemInfo.Status === 8) && (
                <>
                  <div style={{ marginTop: '1rem' }}>
                    <Link
                      style={{
                        color: '#fff',
                        textDecoration: 'none',
                      }}
                      onClick={rejectClick}
                    >
                      <Button type="standart">Atmesti paraišką</Button>
                    </Link>
                  </div>
                </>
              )}

            {requestsState.data.RequestSystemInfo != null &&
              (requestsState.data.RequestSystemInfo.Status == 9 ||
                requestsState.data.RequestSystemInfo.Status == 13 ||
                requestsState.data.RequestSystemInfo.Status == 14 ||
                requestsState.data.RequestSystemInfo.Status == 15 ||
                requestsState.data.RequestSystemInfo.Status == 16) && (
                <div style={{ marginTop: '1rem' }}>
                  <Button
                    type="standart"
                    onClick={() =>
                      terminateRequest(
                        requestsState.data.RequestSystemInfo.RequestId
                      )
                    }
                  >
                    Nutraukti sutartį
                  </Button>
                </div>
              )}
          </div>

          <div
            className="col-lg-3 col-sm-12 full-width-btn"
            style={{ textAlign: 'center' }}
          >
            {requestsState.data.RequestSystemInfo != null &&
              requestsState.data.RequestSystemInfo.Status != 1 && (
                <div style={{ marginTop: '1rem' }}>
                  <Button
                    type="standart"
                    onClick={() =>
                      checkFinancialCriteriaResults(
                        requestsState.data.RequestSystemInfo.RequestId,
                        requestsState.data.RequestSystemInfo
                          .AllEvaluationCompleted
                      )
                    }
                  >
                    Peržiūrėti atitikimo kriterijus
                  </Button>
                </div>
              )}

{requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status === 3 && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeStatusClick}
                  >
                    <Button type="standart">Grįžti į Pateikta vertinti</Button>
                  </Link>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              (requestsState.data.RequestSystemInfo.Status === 7 || requestsState.data.RequestSystemInfo.Status === 8 || requestsState.data.RequestSystemInfo.Status === 11 ||  requestsState.data.RequestSystemInfo.Status === 12 ) && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeStatus3Click}
                  >
                    <Button type="standart">Grįžti į Patvirtinta</Button>
                  </Link>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status === 17 && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeStatus13Click}
                  >
                    <Button type="longBtn">Grįžti į  Sąlygų keitimas</Button>
                  </Link>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              (requestsState.data.RequestSystemInfo.Status === 14 || requestsState.data.RequestSystemInfo.Status === 15 || requestsState.data.RequestSystemInfo.Status === 18) && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeStatus17Click}
                  >
                    <Button type="longBtn">Grįžti į  Patvirtintas sąlygų keitimas</Button>
                  </Link>
                </div>
              )}

            {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              (requestsState.data.RequestSystemInfo.Status === 7 ||
                requestsState.data.RequestSystemInfo.Status === 8) && (
                <div style={{ marginTop: '1rem' }}>
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeContract}
                  >
                    <Button type="standart">Pakeisti sutartį</Button>
                  </Link>
                </div>
              )}

          {requestsState &&
              requestsState.data &&
              requestsState.data.RequestSystemInfo &&
              requestsState.data.RequestSystemInfo.Status === 1 && (
                <div style={{ marginTop: '1rem' }}>
                  <span
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeSetNotDeletable}
                  >
                    <Button type="standart">Netrynimo požymis</Button>
                  </span>
                </div>
              )}

            <div className="row" style={{ marginTop: '2rem' }}>
              {' '}
              {requestsState &&
                requestsState.data &&
                requestsState.data.RequestSystemInfo &&
                (requestsState.data.RequestSystemInfo.Status === 9 ||
                  requestsState.data.RequestSystemInfo.Status === 16) && (
                  <div style={{ marginTop: '1rem' }}>
                    <Button
                      type="longBtn"
                      onClick={() => changeContractModalOpen()}
                    >
                      Inicijuoti sutarties sąlygų keitimą
                    </Button>
                  </div>
                )}
            </div>
          </div>
          {requestsState &&
            requestsState.data &&
            requestsState.data.RequestSystemInfo &&
            requestsState.data.RequestSystemInfo.Status === 17 && (
              <>
                {requestsState.data.RequestSystemInfo.Status === 17 && (
                  <>
                    <div
                      className="col-lg-3 col-sm-12 full-width-btn"
                      style={{ marginTop: '1rem' }}
                    >
                      <Link
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                        }}
                        onClick={acceptConditionsChangeRequest}
                      >
                        <Button type="longBtn">
                          Pateikti sąlygų keitimą pasirašymui
                        </Button>
                      </Link>
                    </div>
                  </>
                )}
              </>
            )}
          {requestsState &&
            requestsState.data &&
            requestsState.data.RequestSystemInfo &&
            (requestsState.data.RequestSystemInfo.Status === 13 ||
              requestsState.data.RequestSystemInfo.Status === 14 ||
              requestsState.data.RequestSystemInfo.Status === 17 ||
              requestsState.data.RequestSystemInfo.Status === 18) && (
              <>
                <div
                  className="col-lg-3 col-sm-12 full-width-btn"
                  style={{ marginTop: '0rem' }}
                >
                  <div
                    //className="col-lg-3 col-sm-12 full-width-btn"
                    style={{ marginTop: '1rem' }}
                  >
                    <Link
                      style={{
                        color: '#fff',
                        textDecoration: 'none',
                      }}
                      onClick={returnChangeConditionsModalOpen}
                    >
                      <Button type="longBtn">
                        Grąžinti tikslinti sąlygų keitimą
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          {requestsState &&
            requestsState.data &&
            requestsState.data.RequestSystemInfo &&
            (requestsState.data.RequestSystemInfo.Status === 13 ||
              requestsState.data.RequestSystemInfo.Status === 18) && (
              //|| requestsState.data.RequestSystemInfo.Status === 19
              <>
                <div
                  className="col-lg-3 col-sm-12 full-width-btn"
                  style={{ marginTop: '1rem' }}
                >
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={changeConditionsModalOpen}
                  >
                    <Button type="longBtn">Patvirtinti sąlygų keitimą</Button>
                  </Link>
                </div>
              </>
            )}
          {requestsState &&
            requestsState.data &&
            requestsState.data.RequestSystemInfo &&
            (requestsState.data.RequestSystemInfo.Status === 13 ||
              requestsState.data.RequestSystemInfo.Status === 14 ||
              requestsState.data.RequestSystemInfo.Status === 15 ||
              requestsState.data.RequestSystemInfo.Status === 17 ||
              requestsState.data.RequestSystemInfo.Status === 18 ||
              requestsState.data.RequestSystemInfo.Status === 19) && (
              <>
                <div
                  className="col-lg-3 col-sm-12 full-width-btn"
                  style={{ marginTop: '1rem' }}
                >
                  <Link
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={rejectConditionsChangeRequest}
                  >
                    <Button type="longBtn">
                      Atmesti sutarties sąlygų keitimą
                    </Button>
                  </Link>
                </div>
              </>
            )}
        </div>
        <div
          className="row"
          style={{ marginTop: '2rem', marginLeft: '0px' }}
        ></div>
        <div
          style={{
            marginTop: '3rem',
            marginBottom: '2rem',
            marginLeft: '15px',
          }}
        >
          <h4>Komentarų istorija </h4>
          {requestsState &&
          requestsState.data &&
          requestsState.data.CommentsHistory &&
          requestsState.data.CommentsHistory.length ? (
            <TableAdmin header={commentsHistoryTableHeader} isEmpty={false}>
              {requestsState.data.CommentsHistory.map((data) => {
                return (
                  <TableRow>
                    <TableCell>
                      {data.Date &&
                        format(new Date(data.Date), 'yyyy-MM-dd HH:mm:ss')}
                    </TableCell>
                    <TableCell>{data.CommentedBy}</TableCell>
                    <TableCell>{data.NewComment}</TableCell>
                  </TableRow>
                );
              })}
            </TableAdmin>
          ) : (
            <p>Nėra komentarų istorijos</p>
          )}
        </div>
        <div
          style={{
            marginTop: '1rem',
            marginBottom: '2rem',
            marginLeft: '15px',
          }}
        >
          <h4>Veiksmų istorija</h4>
          <TableAdmin header={logTableHeader} isEmpty={false}>
            {requestsState &&
              requestsState.requestLog &&
              requestsState.requestLog.map(
                ({ ActionLogType, ChangedBy, Created }) => (
                  <TableRow>
                    <TableCell>
                      {format(new Date(Created), 'yyyy-MM-dd HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      {requestLogTranslation(ActionLogType)}
                    </TableCell>
                    <TableCell>{ChangedBy}</TableCell>
                  </TableRow>
                )
              )}
          </TableAdmin>
        </div>
      </div>

      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle="Taip"
        modalTitle={modalTitle}
        checkbox={false}
        mainText={modalMainText}
      />

      <FinancialCriteriaModal
        open={financialCriteriaModalVisible}
        data={financialCriteriaResults}
        sectorType={loanInfo && loanInfo.SectorType}
        finYear={companyQuestionList?.FsiYear}
      />
      <DoublecheckEvaluationModal
        open={doublecheckEvaluationModalVisible}
        id={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        type="manager"
      />

      <TerminateRequestModal
        open={terminateRequestModalVisible}
        id={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />

      <RegenerateDecisionDocModal
        open={regenerateDecisionDocModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />

      <IncreaseInvegaLoanSumModal
        open={increaseInvegaLoanSumModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />

      <InvegaLoanSumModal
        open={invegaLoanSumModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />

      <ConfirmRequestModal
        open={confirmRequestModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
        invegaLoanDelayTerm2={requestsState?.data?.InvegaLoanDelayTerm}
        invegaPaymentDate2={requestsState?.data?.InvegaPaymentDate}
      />

      <TakeRequestToEvaluateModal
        open={takeRequestToEvaluateModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />

      <ChangeAdditionalInfoModal
        open={changeAdditionalInfoModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        invegaSigningDate={findInvegaSigningDate()}
      />

      <ChangeContractFile
        open={changeContractFileVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        invegaContractNumber2={
          requestsState.data && requestsState.data.InvegaContractNumber
        }
        invegaLoanInterestRate2={
          requestsState.data && requestsState.data.InvegaLoanInterestRate
        }
        invegaLoanVariableInterestRate2={
          requestsState.data &&
          requestsState.data.InvegaLoanVariableInterestRate
        }
        invegaLoanPaymentTerm2={
          requestsState.data && requestsState.data.InvegaLoanPaymentTerm
        }
        invegaLoanSum2={requestsState.data && requestsState.data.InvegaLoanSum}
        euriborChangeDate2={
          requestsState.data && requestsState.data.EuriborChangeDate
        }
        firstInterestChangeDate2={
          requestsState.data && requestsState.data.FirstInterestChangeDate
        }
        interestTypeId2={
          requestsState.data && requestsState.data.InterestTypeId
        }
        euriborPeriodId2={
          requestsState.data && requestsState.data.EuriborPeriodId
        }
        comment={comment}
        loanDelayTerm={
          requestsState?.data?.InvegaLoanDelayTerm ||
          requestsState?.data?.LoanInfo?.LoanDelayTerm
        }
        invegaSigningDate={findInvegaSigningDate()}
        invegaLoanDelayTerm2={requestsState?.data?.InvegaLoanDelayTerm}
        invegaPaymentDate2={requestsState?.data?.InvegaPaymentDate}
      />

      <AcceptConditionsChangeRequestModal
        open={submitConditionsChangeRequestModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        invegaContractNumber2={
          requestsState.data && requestsState.data.InvegaContractNumber
        }
        invegaLoanInterestRate2={
          requestsState.data && requestsState.data.InvegaLoanInterestRate
        }
        invegaLoanVariableInterestRate2={
          requestsState.data &&
          requestsState.data.InvegaLoanVariableInterestRate
        }
        invegaLoanPaymentTerm2={
          requestsState.data && requestsState.data.InvegaLoanPaymentTerm
        }
        invegaLoanSum2={requestsState.data && requestsState.data.InvegaLoanSum}
        euriborChangeDate2={
          requestsState.data && requestsState.data.EuriborChangeDate
        }
        firstInterestChangeDate2={
          requestsState.data && requestsState.data.FirstInterestChangeDate
        }
        interestTypeId2={
          requestsState.data && requestsState.data.InterestTypeId
        }
        euriborPeriodId2={
          requestsState.data && requestsState.data.EuriborPeriodId
        }
        invegaSigningDate={findInvegaSigningDate()}
      />
      <ChangeCompanySize
        open={changeCompanySizeVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />

      <RejectConditionsChangeRequest
        open={rejectConditionsChangeRequestVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
      />
      <AutorizationModal open={autorizationModalVisible} />
      <ChangeConditionsBoModal
        open={changeConditionsBoModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
      />
      <GlobalMessageModal
        message={openedMessageText}
        open={globalMessageModalVisible}
      />
      <SubmitConditionsChangeRequestModal
        open={acceptConditionsChangeRequestModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        invegaContractNumber2={
          requestsState.data && requestsState.data.InvegaContractNumber
        }
        invegaLoanInterestRate2={
          requestsState.data && requestsState.data.InvegaLoanInterestRate
        }
        invegaLoanVariableInterestRate2={
          requestsState.data &&
          requestsState.data.InvegaLoanVariableInterestRate
        }
        invegaLoanPaymentTerm2={
          requestsState.data && requestsState.data.InvegaLoanPaymentTerm
        }
        invegaLoanSum2={requestsState.data && requestsState.data.InvegaLoanSum}
        loanDelayTerm={requestsState.data.InvegaLoanDelayTerm}
        loanPurposeId={requestsState?.data?.LoanInfo?.LoanPurposeId}
        loanPaymentDay2={requestsState?.data?.LoanInfo?.LoanPaymentDay}
        euriborChangeDate2={
          requestsState.data && requestsState.data.EuriborChangeDate
        }
        firstInterestChangeDate2={
          requestsState.data && requestsState.data.FirstInterestChangeDate
        }
        interestTypeId2={
          requestsState.data && requestsState.data.InterestTypeId
        }
        euriborPeriodId2={
          requestsState.data && requestsState.data.EuriborPeriodId
        }
        iban2={requestsState?.data?.CompanyData?.Iban}
        comment={comment}
        invegaSigningDate={findInvegaSigningDate()}
        invegaLoanDelayTerm2={requestsState?.data?.InvegaLoanDelayTerm}
        invegaPaymentDate2={requestsState?.data?.InvegaPaymentDate}
      />
      <ReturnChangeConditionsModal
        open={returnChangeConditionsModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
      />

      <ReturnModal
        open={returnModalVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
      />

      <ConfirmWithAdditionalDocs
        open={confirmWithAdditionalDocsVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
      />

      <ConfirmRequestNew
        open={confirmRequestNewVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
      />

      <ConfirmRejectNew
        open={confirmRejectNewVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        comment={comment}
      />

<ChangeSetNotDeletable
        open={changeSetNotDeletableVisible}
        requestId={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.RequestId
        }
        notDeletableProp={
          requestsState.data.RequestSystemInfo &&
          requestsState.data.RequestSystemInfo.NotDeletable
        }
      />
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  requestsState: state.backofficeRequestsState,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
  terminateRequestModalVisible: state.modalsState.terminateRequestModalVisible,
  signInInfo: state.dashboardState.signInInfo,
  financialCriteriaModalVisible:
    state.modalsState.financialCriteriaModalVisible,
  doublecheckEvaluationModalVisible:
    state.modalsState.doublecheckEvaluationModalVisible,
  changeAdditionalInfoModalVisible:
    state.modalsState.changeAdditionalInfoModalVisible,
  regenerateDecisionDocModalVisible:
    state.modalsState.regenerateDecisionDocModalVisible,
  invegaLoanSumModalVisible: state.modalsState.invegaLoanSumModalVisible,
  increaseInvegaLoanSumModalVisible:
    state.modalsState.increaseInvegaLoanSumModalVisible,
  confirmRequestModalVisible: state.modalsState.confirmRequestModalVisible,
  rejectConditionsChangeRequestVisible:
    state.modalsState.rejectConditionsChangeRequestVisible,
  changeContractFileVisible: state.modalsState.changeContractFileVisible,
  changeCompanySizeVisible: state.modalsState.changeCompanySizeVisible,
  acceptConditionsChangeRequestModalVisible:
    state.modalsState.acceptConditionsChangeRequestModalVisible,
  takeRequestToEvaluateModalVisible:
    state.modalsState.takeRequestToEvaluateModalVisible,
  financialCriteriaResults: state.dashboardState.financialCriteriaResults,
  loading: state.dashboardState.isOnSync,
  autorizationModalVisible: state.modalsState.autorizationModalVisible,
  changeConditionsBoModalVisible:
    state.modalsState.changeConditionsBoModalVisible,
  submitConditionsChangeRequestModalVisible:
    state.modalsState.submitConditionsChangeRequestModalVisible,
  globalMessageModalVisible: state.modalsState.globalMessageModalVisible,
  returnChangeConditionsModalVisible:
    state.modalsState.returnChangeConditionsModalVisible,
  returnModalVisible: state.modalsState.returnModalVisible,
  confirmWithAdditionalDocsVisible:
    state.modalsState.confirmWithAdditionalDocsVisible,
  confirmRequestNewVisible: state.modalsState.confirmRequestNewVisible,
  confirmRejectNewVisible: state.modalsState.confirmRejectNewVisible,
  changeSetNotDeletableVisible: state.modalsState.changeSetNotDeletableVisible,
});

/*
        <TableCell>
          <div className={uploadStyles.iconsContainer}>
            <Link onClick={() => downloadUploadedFile(ContractId, FileName)}>
              <FontAwesomeIcon
                icon={faDownload}
                className={styles.icon}
                style={{
                  color: '#597a96',
                }}
              />
            </Link>
          </div>
        </TableCell>
        */

export default connect(mapStateToProps)(BackofficeRequest);