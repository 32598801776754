import React, { useEffect, useState } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { WithSpinner } from '../../components';
import { resetLocalStorageItem } from '../../utils';
import { config } from '../../api/config';
import { Redirect } from 'react-router-dom';

const Home = () => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const token = window.location.search.slice(7).split('&').shift();
    const loginType = window.location.search
      .slice(window.location.search.lastIndexOf('&') + 1)
      .slice(10);
    // dispatch(actions.dashboard.setLoginType(loginType));

    resetLocalStorageItem('userToken', token);
    resetLocalStorageItem('loginType', loginType);
    resetLocalStorageItem('Role', 0);
    if (token.length > 30) {
      setRedirect(true);
    }
  }, []);
  return (
    <WithSpinner loading={false}>
      <div>
        <p>Logged in</p>
      </div>
      {redirect ? <Redirect to={config.APP_PATHS.main} /> : null}
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardState.dashboardData,
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(Home);
