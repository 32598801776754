export const requestDataLabelTranslation = (text) => {
  switch (text) {
    case 'ApplicantName':
      return 'Pareiškėjo vardas';
    case 'ApplicantSurname':
      return 'Pareiškėjo pavardė';
    case 'ApplicantEmail':
      return 'Pareiškėjo el. pašto adresas';
    case 'ApplicantPhone':
      return 'Pareiškėjo kontaktinis telefono numeris';
      case 'FirstName':
        return 'Vardas';
      case 'LastName':
        return 'Pavardė';
        case 'Position':
      return 'Pareigos';
      case 'OtherIncomeSource':
      return 'Kitas lėšų šaltinis';
      case 'CompanyName':
      return 'Verslo subjekto pavadinimas';
    case 'CompanyCode':
      return 'Verslo subjekto JAR kodas';
    case 'CompanyManagerName':
      return 'Verslo subjekto vadovo vardas, pavardė';
    case 'CompanyType':
      return 'Verslo subjekto tipas';
    case 'CompanySizeType':
      return 'Smulkios ir vidutinės įmonės dydis';
    case 'CompanyRegistrationDate':
      return 'Verslo subjekto registracijos data';
    case 'CompanyRegistrationAddress':
      return 'Verslo subjekto registracijos adresas';
    case 'CompanyPhone':
      return 'Verslo subjekto kontaktinis telefono numeris';
    case 'CompanyEmail':
      return 'Verslo subjekto el. pašto adresas';
    case 'ContractEmail':
      return 'El. pašto adresas, kuriuo bus siunčiamas paskolos sutarties grafikas ir sąskaitos';
    case 'CompanyEvrk':
      return 'Pagrindinė ekonominė planuojamo projekto veikla';
    case 'IndividualEvrk':
      return 'Pagrindinė ekonominė planuojamo projekto veikla';
    case 'Fsi2018Submitted':
      return 'Ar pateikta 2018 m. finansinė atskaitomybė Registrų centrui?';
    case 'Fsi2019Submitted':
      return 'Ar pateikta 2019 m. finansinė atskaitomybė Registrų centrui?';
    case 'Fsi2021Submitted':
      return 'Ar pateikta 2021 m. finansinė atskaitomybė Registrų centrui?';
    case 'Fsi2022Submitted':
      return 'Ar pateikta 2022 m. finansinė atskaitomybė Registrų centrui?';
    case 'Fsi2020Submitted':
      return 'Ar pateikta 2020 m. finansinė atskaitomybė Registrų centrui?';
    case 'FsiStartDate':
      return 'Ataskaitos laikotarpis nuo';
    case 'FsiEndDate':
      return 'Ataskaitos laikotarpis iki';
    case 'FsiStartDate2021':
      return 'Ataskaitos laikotarpis nuo';
    case 'FsiEndDate2021':
      return 'Ataskaitos laikotarpis iki';
    case 'FsiStartDate2022':
      return 'Ataskaitos laikotarpis nuo';
    case 'FsiEndDate2022':
      return 'Ataskaitos laikotarpis iki';
    case 'FsiStartDate2020':
      return 'Ataskaitos laikotarpis nuo';
    case 'FsiEndDate2020':
      return 'Ataskaitos laikotarpis iki';
    case 'Turnover18':
      return 'Apyvarta 2018 m., EUR';
    case 'Turnover19':
      return 'Apyvarta 2019 m., EUR';
    case 'Turnover20':
      return 'Apyvarta 2020 m., EUR';
    case 'Turnover21':
      return 'Apyvarta 2021 m., EUR';
    case 'Turnover22':
      return 'Apyvarta 2022 m., EUR';
    case 'Income18':
      return 'Pajamos 2018 m., EUR';
    case 'Income19':
      return 'Pajamos 2019 m., EUR';
    case 'Assets18':
      return 'Turto suma 2018 m., EUR';
    case 'Assets19':
      return 'Turto suma 2019 m., EUR';
    case 'Assets20':
      return 'Turto suma 2020 m., EUR';
    case 'Assets21':
      return 'Turto suma 2021 m., EUR';
    case 'Assets22':
      return 'Turto suma 2022 m., EUR';
    case 'PropertyAmount18':
      return 'Nuosavybės suma 2018 m., EUR';
    case 'PropertyAmount20':
      return 'Nuosavybės suma 2020 m., EUR';
    case 'PropertyAmount21':
      return 'Nuosavybės suma 2021 m., EUR';
    case 'PropertyAmount22':
      return 'Nuosavybės suma 2022 m., EUR';
    case 'PropertyAmount19':
      return 'Nuosavybės suma 2019 m., EUR';
    case 'Liabilities18':
      return 'Įsipareigojimų suma 2018 m., EUR';
    case 'Liabilities19':
      return 'Įsipareigojimų suma 2019 m., EUR';
    case 'Liabilities20':
      return 'Įsipareigojimų suma 2020 m., EUR';
    case 'Liabilities21':
      return 'Įsipareigojimų suma 2021 m., EUR';
    case 'Liabilities22':
      return 'Įsipareigojimų suma 2022 m., EUR';
    case 'ShortTermDebts18':
      return 'Trumpalaikių skolų tiekėjams suma 2018 m., EUR';
    case 'ShortTermDebts19':
      return 'Trumpalaikių skolų tiekėjams suma 2019 m., EUR';
    case 'EmployeesCount2019March1':
      return 'Darbuotojų skaičius 2020 m. kovo 1 dienai';
    case 'CurrentEmployeesCount':
      return 'Darbuotojų skaičius paraiškos teikimo metu ';
    case 'IsUnreable':
      return 'Patikimo mokesčių mokėtojo kriterijų neatitinkanti įmonė';
    case 'CompanyActivityAddress':
      return 'Verslo subjekto faktinės veiklos vykdymo adresas';
    case 'ControlStructure':
      return 'Pateikite verslo subjekto nuosavybės ir (ar) kontrolės struktūros aprašymą ir (arba) įkelkite struktūros schemą prie teikiamų dokumentų ';
    case 'Iban':
      return 'Verslo subjekto atsiskaitomoji sąskaita (nurodoma LR įsteigtoje kredito įstaigoje ar užsienio kredito įstaigos filiale, įsteigtame Lietuvoje, esanti ir pareiškėjui priklausanti atsiskaitomoji sąskaita. Formatas: LT000000000000000000)';
    case 'MoreThan5Years':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: paskolos paskirtis Socialinio poveikio projektas Ir įmonės amžius didesnis nei 5 metai.';
    case 'MediumSizeBusinessman':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: paskolos paskirtis Socialinio poveikio projektas ir verslininko dydis pagal SVV deklaraciją - Vidutinė įmonė.';
    case 'BigSize':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: verslo subjekto dydis pagal SVV deklaraciją - didelė įmonė.';
    case 'MoreThan3YearsNotSocial':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: paskolos paskirtis nėra Socialinio poveikio projektas Ir įmonės amžius didesnis nei 3 metai.';
    case 'CompanyManagerCitizenship':
      return 'Pilietybė';
    case 'Status':
      return 'Teisinis statusas';
    case 'PersonalCode':
      return 'Asmens kodas';
      case 'ProjectRegionId':
        return 'Projekto veiklos vykdymo regionas';
        case 'IsNeedSubsidy':
      return 'Ar bus kreipiamasi dėl dotacijos?';
      case 'Evrk':
      return 'Patento ar individualios veiklos pažymėjimo, galiojančio paraiškos pateikimo dieną, EVRK kodas. Formatas: 000000';
    case 'PvmCode':
      return 'PVM kodas';
    case 'OldIndividualActivityCertificateNumber':
      return 'Patento ar individualios veiklos pažymėjimo, galiojusio iki 2020-03-16,  numeris';
    case 'OldIndividualActivityCertificateNumberStartDate':
      return 'Patento ar individualios veiklos pažymėjimo, galiojusio iki 2020-03-16, pradžios data';
    case 'OldEvrk':
      return 'Patento ar individualios veiklos pažymėjimo, galiojusio iki 2020-03-16, EVRK kodas Formatas: 000000';
    case 'IndividualActivityCertificateNumber':
      return 'Galiojančios individualios veiklos ar verslo liudijimo pažymėjimo numeris';
    case 'IndividualActivityCertificateNumberStartDate':
      return 'Galiojančio Verslo liudijimo ar IV pažymos išdavimo data';
    case 'CheckBusinessman':
      return 'Patvirtinu kad, aš esu verslininkas, kaip jis apibrėžtas Lietuvos Respublikos smulkiojo ir vidutinio verslo plėtros įstatyme.';

    case 'CheckCompanySize':
      return 'Ar įmonė, kurią aš atstovauju, atsižvelgiant į Lietuvos Respublikos smulkiojo ir vidutinio verslo plėtros įstatymą (t.y. įvertinus visų susijusių ir partnerinių įmonių duomenis) yra MVĮ (labai maža, maža arba vidutinė įmonė)?';

    case 'CheckCompanyAge':
      return 'Ar įmonė, kurią aš atstovauju ir visos į įmonių grupę patenkančios įmonės (visos su mano įmone susijusios įmonės) yra veikiančios trumpiau negu trejus metus?';
      case 'BasisOfRepresentationId':
        return 'Atstovavimo pagrindas';
      case 'DocumentTypeId':
        return 'Asmens tapatybės dokumento tipas';
      case 'DocumentNumber':
        return 'Asmens tapatybės dokumento Nr.';
      case 'DocumentIssuedCountryId':
        return 'Asmens tapatybės dokumentą išdavusi valstybė';
      case 'DocumentDate':
        return 'Asmens dokumento išdavimo data';
      case 'DocumentExpiredDate':
        return 'Asmens dokumento galiojimo pabaigos data';
      case 'StateOfTheTaxpayerId':
        return 'Mokesčio mokėtojo valstybė';
      case 'PermanentResidenceCountryId':
        return 'Nuolatinės gyvenamosios vietos valstybė';
      case 'BasisOfRepresentationId':
        return 'Atstovavimo pagrindas';
      case 'DocumentTypeId':
        return 'Asmens tapatybės dokumento tipas';
      case 'DocumentNumber':
        return 'Asmens tapatybės dokumento Nr.';
      case 'DocumentIssuedCountryId':
        return 'Asmens tapatybės dokumentą išdavusi valstybė';
      case 'DocumentDate':
        return 'Asmens dokumento išdavimo data';
      case 'DocumentExpiredDate':
        return 'Asmens dokumento galiojimo pabaigos data';
      case 'StateOfTheTaxpayerId':
        return 'Mokesčio mokėtojo valstybė';
      case 'PermanentResidenceCountryId':
        return 'Nuolatinės gyvenamosios vietos valstybė';
      case 'ActivityInfo':
        return 'Veiklos aprašymas (laisvu tekstu)';
      case 'LicenceNeeded':
        return 'Ar Jūsų vykdomai ekonominei veiklai yra reikalinga licencija ar speciali registracija? ';
      case 'CompanyRegistrationCountry':
        return 'Įmonės registracijos šalis';
      case 'StateTaxIdentificationNumber':
        return 'Mokesčių mokėtojo kodas';
      case 'StateOfResidenceForTaxPurposes':
        return 'Rezidavimo valstybė mokesčių tikslais ';
      case 'StateOfResidenceForTaxPurposesId':
        return 'Rezidavimo valstybė mokesčių tikslais ';
      case 'StateOfTheTaxpayer':
        return 'Mokesčio mokėtojo valstybė';
      case 'StateOfTheTaxpayerId':
        return 'Mokesčio mokėtojo valstybė';
    case 'CheckConsolidatedReport':
      return 'Ar įmonė, kurią aš atstovauju ir visos į įmonių grupę patenkančios įmonės (visos su mano įmone susijusios įmonės) sudaro konsoliduotąją ataskaitą? Jei taip, nurodykite įmonės kodą ir prie paraiškos prisekite konsoliduotą ataskaitą';
    case 'StatusInitial':
      return 'Teisinis statusas';

    case 'EuLoanSum':
      return 'Verslo subjektas yra gavęs paskolą (-as) ar sudaręs lizingo sandorį (-ius), kurie yra garantuoti skatinamosios finansinės priemonės „Portfelinės garantijos paskoloms 2“ lėšomis (įrašyti bendrą sumą). Jeigu nėra, įrašykite 0.00 EUR.';
    case 'InvegaAsapSumLoan':
      return 'Verslo subjektas yra gavęs paskolą iš skatinamosios finansinės priemonės „Paskolos labiausiai nuo COVID-19 nukentėjusiems verslams“ lėšų (įrašyti bendrą sumą). Jeigu nėra, įrašykite 0.00 EUR.';
    case 'InvegaMostAffectedLoanSum':
      return 'Veiklos subjektas yra paskolą iš skatinamosios finansinės priemonės „Apmokėtinų sąskaitų Paskolos“ lėšų (įrašyti bendrą sumą). Jeigu nėra, įrašykite 0.00 EUR.';
    case 'InvegaTurapSumLoan':
      return 'Veiklos subjektas yra gavęs paskolą iš skatinamosios finansinės priemonės "Paskolos turizmo ir viešojo maitinimo paslaugų teikėjams" lėšų (įrašyti bendrą sumą). Jeigu nėra, įrašykite 0.00 EUR.';

    case 'InvegaPortfolioLoan2Sum':
      return 'Kita (-os) pagal 2020 m. kovo 19 d. Europos Komisijos komunikatą dėl laikinosios valstybės pagalbos priemonių, skirtų ekonomikai remti reaguojant į dabartinį COVID-19 protrūkį, sistemos 3.2 ir (arba) 3.3 punktą įgyvendinama (-os) priemonė (-ės)  (įrašyti bendrą sumą). Jeigu nėra, įrašykite 0.00 EUR.';
    case '':
      return;
    case 'SectorType':
      return 'Paraiška teikiama už sektorių';
    case 'LoanSum':
      return 'Pageidaujama Paskolos suma, EUR';
    case 'LoanTerm':
      return 'Pageidaujamas Paskolos terminas mėn.';
    case 'LoanDelayTerm':
      return 'Paskolos atidėjimo terminas (mėn.)';
    case 'LoanSocialBusiness':
      return 'Ar paskola skirta socialinio poveikio projektui* vykdyti?';
    case 'LoanPurposeId':
      return 'Paskolos paskirtis';
    case 'LoanPaymentDay':
      return 'Pageidaujama mokėjimo diena';

    case 'ParentCompanyCode':
      return 'Nurodykite visus Įmonių grupei priklausančių įmonių kodus, kurie nurodyti jūsų užpildytoje ir teikiamoje SVV subjekto statuso deklaracijoje. Jei esate didelė įmonė, nurodykite visus Įmonių grupei priklausančių įmonių kodus. Pvz. 111111111, 222222222';
    case 'Check1Confirment':
      return;
    case 'Check2Correctness':
      return 'Patvirtinu, kad paraiškoje pateikti duomenys yra tikri ir teisingi;';
    case 'Check3AgreementToCheck':
      return 'Sutinku, kad INVEGA atliktų patikras susijusias su tinkamu Paskolos lėšų panaudojimu. Įsipareigoju pateikti atitinkamų dokumentų kopijas, o patikrų vietoje atveju pateikti atitinkamus dokumentus susipažinti;';
    case 'Check4AgreementToPublish':
      return 'Sutinku, kad mano kaip Paskolos gavėjo duomenys būtų skelbiami INVEGOS interneto svetainėje;';
    case 'Check5AgreementToManage':
      return 'Sutinku, kad duomenys apie mane ar mano atstovaujamą įmonę būtų tikrinami įvairiose viešose ir privačiose duomenų bazėse ir registruose, taip pat INVEGA keistųsi informacija su kitomis institucijomis, visu paskolos galiojimo laikotarpiu;';
    case 'Check6OtherCircuference':
      return 'Patvirtinu, kad Paraiškos pateikimo metu nėra kitų aplinkybių dėl ko man galėtų būti inicijuotas nemokumo procesas;';
    case 'Check7Restructuring':
      return 'Patvirtinu, kad negavau sanavimo ar restruktūrizavimo pagalbos.';
    case 'CheckIndividualAge':
      return 'Ar Verslo subjektas, kurį aš atstovauju ir visos į įmonių grupę patenkančios įmonės (visos su mano įmone susijusios įmonės) yra veikiančios trumpiau negu trejus metus?';
    case 'VatCode':
      return 'PVM kodas';
    default:
      return text;
  }
};

export const requestDataLabelTranslation2 = (text) => {
  switch (text) {
    case 'Check1Confirment':
      return;
    case 'Check2Correctness':
      return 'Patvirtinu, kad paraiškoje pateikti duomenys yra tikri ir teisingi;';
    case 'Check3AgreementToCheck':
      return 'Sutinku, kad INVEGA atliktų patikras susijusias su tinkamu Paskolos lėšų panaudojimu. Įsipareigoju pateikti atitinkamų dokumentų kopijas, o patikrų vietoje atveju pateikti atitinkamus dokumentus susipažinti;';
    case 'Check4AgreementToPublish':
      return 'Sutinku, kad mano kaip Paskolos gavėjo duomenys būtų skelbiami INVEGOS interneto svetainėje;';
    case 'Check5AgreementToManage':
      return 'Sutinku, kad duomenys apie mane ar mano atstovaujamą įmonę būtų tikrinami įvairiose viešose ir privačiose duomenų bazėse ir registruose, taip pat INVEGA keistųsi informacija su kitomis institucijomis, visu paskolos galiojimo laikotarpiu;';
    case 'Check6OtherCircuference':
      return 'Patvirtinu, kad Paraiškos pateikimo metu nėra kitų aplinkybių dėl ko man galėtų būti inicijuotas nemokumo ir(ar) fizinio asmens bankroto procesas;';
    case 'Check7Restructuring':
      return 'Patvirtinu, kad negavau sanavimo ar restruktūrizavimo pagalbos.';
    case 'CheckIndividualAge':
      return 'Ar Verslo subjektas, kurį aš atstovauju ir visos į įmonių grupę patenkančios įmonės (visos su mano įmone susijusios įmonės) yra veikiančios trumpiau negu trejus metus?';
    default:
      return text;
  }
};

export const applicantTranslation = (text) => {
  switch (text) {
    case 'ApplicantName':
      return 'Pareiškėjo vardas';
    case 'ApplicantSurname':
      return 'Pareiškėjo pavardė';
    case 'ApplicantEmail':
      return 'Pareiškėjo el. pašto adresas';
    case 'ApplicantPhone':
      return 'Pareiškėjo kontaktinis telefono numeris';
    default:
      return text;
  }
};

export const requestStatus = (text) => {
  switch (text) {
    case 1:
      return 'Rengiama';
    case 2:
      return 'Pateikta vertinti';
    case 3:
      return 'Patvirtinta';
    case 4:
      return 'Atmesta';
    case 5:
      return 'Grąžinta tikslinimui';
    case 6:
      return 'Neatitinka kriterijų';
    case 7:
      return 'Sugeneruota sutartis';
    case 8:
      return 'Pareiškėjo pasirašyta sutartis';
    case 9:
      return 'Pasirašyta finansavimo sutartis';
    case 10:
      return 'Nutraukta sutartis';
    case 11:
      return 'Laukiama papildomų dokumentų';
    case 12:
      return 'Pateikti papildomi dokumentai';
    case 13:
      return 'Sąlygų keitimas';
    case 14:
      return 'Sugeneruotas sąlygų keitimo dokumentas';
    case 15:
      return 'Pareiškėjo pasirašytas sąlygų keitimo dokumentas';
    case 16:
      return 'Invegos pasirašytas sąlygų keitimo dokumentas';
    case 17:
      return 'Patvirtintas sąlygų keitimas';
    case 18:
      return 'Pateikti papildomi dokumentai sąlygų keitimui';
    case 19:
      return 'Sąlygų keitimas grąžintas tikslinimui';

    default:
      return text;
  }
};

export const paymentRequestStatus = (text) => {
  switch (text) {
    case 1:
      return 'Nepateikta';
    case 2:
      return 'Patvirtinta';
    case 3:
      return 'Grąžinta tikslinimui';
    case 4:
      return 'Atmesta';
    case 5:
      return 'Pateikta vertinti';
    default:
      return text;
  }
};

export const loanPurpose = (text) => {
  switch (text) {
    case 1:
      return 'Investicinė';
    case 2:
      return 'Apyvartinė';
    default:
      return text;
  }
};

export const helpSchem = (text) => {
  switch (text) {
    case 1:
      return 'De minimis';
    case 2:
      return 'GBER';
    case 3:
      return 'Netaikoma';
    default:
      return text;
  }
};

export const helpSchemInvega = (text) => {
  switch (text) {
    case 1:
      return 'Temporary framework 3.1';
    case 2:
      return 'Temporary framework 3.2';
    case 3:
      return 'De minimis regulation';
    case 4:
      return 'Without guarantee';
      case 5:
        return 'GBER';
        case 6:
          return 'Deminimis';
    default:
      return text;
  }
};

export const CompanySizeTypeInvega = (text) => {
  switch (text) {
    case 1:
      return 'Labai maža';
    case 2:
      return 'Maža';
    case 3:
      return 'Vidutinė';
    case 4:
      return 'Didelė';
    default:
      return text;
  }
};

// statistikoje kitokie pavadinimai ALIG-36
export const requestStatusForStats = (text) => {
  switch (text) {
    case 110:
      return 'Teigiamas sprendimas';
    case 210:
      return 'Neigiamas sprendimas';
    case 3:
      return 'Patvirtinta';
    case 4:
      return 'Priimtas neigiamas sprendimas';
    case 5:
      return 'Grąžinta tikslinimui';
    case 6:
      return 'Atmesta sistemos';
    case 7:
      return 'Sugeneruota sutarčių';
    case 8:
      return 'Pareiškėjo pasirašyta sutartis';
    case 9:
      return 'Pasirašyta finansavimo sutartis';
    case 10:
      return 'Nutraukta sutartis';
    case 111:
      return 'socialiniam verslui vykdyti';
    case 2:
      return 'Vertinama';
    case 211:
      return 'socialiniam verslui vykdyti';
    case 112:
      return 'kita';
    case 212:
      return 'kita';
    case 15:
      return 'Keisti būseną';
    case 99:
      return 'VISO';
    case 100:
      return 'Rezervuota priemonės lėšų suma';
    case 101:
      return 'Likutis';
    case 17:
      return 'Patvirtintas sąlygų keitimas';
    case 18:
      return 'Pateikti papildomi dokumentai sąlygų keitimui';
    case 19:
      return 'Sąlygų keitimas grąžintas tikslinimui';
    case 13:
      return 'Sąlygų keitimas	';
    case 14:
      return 'Sugeneruotas sutarties sąlygų keitimas';

    case 16:
      return 'Invegos pasirašytas sąlygų keitimas';
    default:
      return text;
  }
};

export const companySize = (text) => {
  switch (text) {
    case 'NotApplicable':
      return 'Nepateko į kriterijus';
    case 'VerySmall':
      return 'Labai maža';
    case 'Small':
      return 'Maža';
    case 'MediumSize':
      return 'Vidutinė įmonė';
    default:
      return text;
  }
};

export const invoiceStatus = (id) => {
  switch (id) {
    case 1:
      return 'Rengiama';
    case 2:
      return 'Patikrinta';
    case 3:
      return 'Atmesta';
    case 4:
      return 'Finansuojama';
    case 5:
      return 'Nefinansuojama';
    default:
      return '-';
  }
};

export const vmiInvoiceStatus = (id) => {
  switch (id) {
    case 1:
      return 'Išsiųsta';
    case 2:
      return 'Patvirtinta';
    case 3:
      return 'Tikslintina';
    case 4:
      return 'Nerasta';
    default:
      return '-';
  }
};

export const amlCheckResultId = (id) => {
  switch (id) {
    case 1:
      return 'Accept';
    case 2:
      return 'Reject';
    case 3:
      return 'ManualCheck';
    default:
      return id;
  }
};

export const amlCheckTypeId = (id) => {
  switch (id) {
    case 1:
      return 'RequestSubject';
    case 2:
      return 'RequestCompany';
    case 3:
      return 'ContraollingCompany';
    case 4:
      return 'ControllingPerson';
    default:
      return id;
  }
};

export const rentContractTranslation = (name) => {
  switch (name) {
    case 'RealEstateObjectId':
      return 'NT objekto unikalus numeris. Formatas: xxxx-xxxx-xxxx:xxxx arba xxxx-xxxx-xxxx';
    case 'RealEstateAddress':
      return 'NT objekto adresas';
    case 'ResidentialUse':
      return 'NT objekto paskirtis';
    case 'ContractIsRegisteredAtAuthority':
      return 'Patvirtinu, kad nuomos sutartis registruota valstybės įmonėje Registrų centre.	';
    case 'ContractStartDate':
      return 'Turto nuomos pradžios data *';
    case 'ContractEndDate':
      return 'Turto nuomos pabaigos data *';
    case 'RentedArea':
      return 'Nuomojamas plotas (kv.m.)	';
    case 'MonthlyRentalPrice':
      return 'Mėnesio nuomos kaina, EUR be PVM';
    case 'ContactPersonName':
      return 'Atsakingo asmens vardas ir pavardė (kontaktinis asmuo)';
    case 'AlreadyReceivedCovidSupportSum':
      return 'Nurodykite bendrą kairiau išvardintų įmonių, iki šios paraiškos pateikimo dienos gautą pagalbos sumą EUR (tik subsidijų, dotacijų, mokesčių lengvatų forma) pagal kitas priemones, kurioms taikomas 2020 m. kovo 19 d. Europos Komisijos komunikato „Laikinoji valstybės pagalbos priemonių, skirtų ekonomikai remti reaguojant į dabartinį COVID-19 protrūkį, sistema" 3.1. skirsnis ir bendrosios nuostatos. Anksčiau gautos nuomos mokesčio kompensacijos suvesti nereikia. Įveskite 0.00, jei pagalbos nebuvo gauta.';
    case 'CheckTotalAmountLimit':
      return 'Patvirtinu, kad bendra man, kartu su įmonėmis, kurios gali turėti kontrolę man, kaip pareiškėjui, arba kurias aš, kaip pareiškėjas, galiu kontroliuoti, teikiamo finansavimo pagal 2020 m. kovo 19 d. Europos Komisijos komunikato „Laikinoji valstybės pagalbos priemonių, skirtų ekonomikai remti reaguojant į dabartinį COVID-19 protrūkį, sistema“ 3.1 dalį ir bendrąsias nuostatas (subsidijų, dotacijų, mokesčių lengvatų forma) suma neviršija 800 000 eurų, jei mano veikla vykdoma žuvininkystės ir akvakultūros sektoriuje – 120 000 eurų, jei pirminės žemės ūkio produktų gamybos srityje – 100 000 eurų per komunikato taikymo laikotarpį.';
    case 'CheckNotRentFromSelf':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) nesinuomoju patalpų iš nuomotojo, kuris yra fizinis asmuo, turintis daugumą dalyvių balsų įmonėje, kurią atstovauju.';
    case 'CheckRentForMainEconomicActivity':
      return 'Patvirtinu, kad mano (įmonės, kurią atstovauju) pagrindinė veikla yra ekonominė veikla ir su nuomotoju esu (įmonė, kurią atstovauju yra) sudaręs (-iusi) patalpų nuomos sutartį savo ekonominei veiklai vykdyti.';
    case 'CheckMainActivityWasDenied':
      return 'Patvirtinu, kad mano (įmonės, kurią atstovauju) vykdoma pagrindinė veikla atitinka veiklas, kurios karantino laikotarpiu buvo arba yra uždraustos Lietuvos Respublikos Vyriausybės 2020 m. kovo 14 d. nutarimu Nr. 207 „Dėl karantino Lietuvos Respublikoje paskelbimo“ ir aš draudimo laikotarpiu jos nevykdžiau.';
    case 'CheckNotOnlyInternetSales':
      return 'Patvirtinu, kad mano (įmonės, kurią atstovauju) vykdoma pagrindinė veikla nėra elektroninė prekyba, ginklų ir šaudmenų specializuota mažmeninė prekyba, azartinių žaidimų ir lažybų ar kitų azartinių lošimų organizavimo veikla, finansų įstaigų vykdoma veikla.';
    case 'CheckNoIllegalSupport':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) nesu (nėra) gavęs (-usi) neteisėtos pagalbos, kuri Europos Komisijos sprendimu (dėl individualios pagalbos arba pagalbos schemos) buvo pripažinta neteisėta ir nesuderinama su vidaus rinka arba esu (yra) grąžinęs (-usi) visą jos sumą, įskaitant palūkanas, teisės aktuose nustatyta tvarka.';
    case 'CheckDataIsCorrect':
      return 'Patvirtinu, kad paraiškoje pateikti nuomos sutarties duomenys ir duomenys apie pateiktą nuomos mokestį yra tikri ir teisingi.';
    case 'OwnerCompanyCode':
      return 'Nuomotojo įmonės kodas (jei nuomotojas įmonė)';
    case 'OwnerPersonalCode':
      return 'Asmens kodas (jei nuomotojas fizinis asmuo)';
    case 'OwnerIndividualActivityCertificateNumber':
      return 'Indiv. veiklos pažymėjimo/patento nr. (jei nuomotojas fizinis asmuo)';
    case 'OwnerName':
      return 'Nuomotojo įmonės pavadinimas arba asmens vardas pavardė';
    case 'OwnerPhone':
      return 'Nuomotojo kontaktinis telefono numeris';
    case 'OwnerContactPersonName':
      return 'Atsakingo asmens vardas ir pavardė (kontaktinis asmuo)';
    case 'OwnerEmail':
      return 'Nuomotojo kontaktinis el. pašto adresas';
    case 'CheckResidentialUse':
      return 'NT paskirtis';
    case 'CheckContractIsRegisteredAtAuthority':
      return 'Patvirtinu, kad nuomos sutartis registruota valstybės įmonėje Registrų centre.';
    case 'CheckNotSameCompanyGroup':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) ir nuomotojas nepriklausome tai pačiai įmonių grupei.';

    case 'CheckAdditionalRules':
      return 'Sutinku, kad INVEGA atliktų patikras susijusias su daliniu nuomos mokesčio kompensavimu. Įsipareigoju pateikti atitinkamų dokumentų kopijas, o patikrų vietoje atveju pateikti atitinkamus dokumentus susipažinti.';

    case 'checkAdditionalRule1':
      return 'Įsipareigoju saugoti su nuomos mokesčio skaičiavimu, sumokėjimu bei daliniu jo kompensavimu susijusius dokumentus ne trumpiau kaip 5 kalendorinius metus.';
    case 'checkAdditionalRule2':
      return 'Sutinku, kad mano vardas ir pavardė (mano įmonės pavadinimas) ir man išmokėtos kompensacijų sumos būtų skelbiami INVEGOS interneto svetainėje.';

    case 'checkAdditionalRule3':
      return 'Esu informuotas, kad INVEGA turi teisę tvarkyti mano pateiktus ar vertinant paraišką sužinotus asmens duomenis.';

    case 'CheckNotCoveredByBudget':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) nesu (nėra) gavęs (-usi) nuomos mokesčio kompensavimo už kompensavimo laikotarpiu patirtas nuomos mokesčio įšlaidas iš kitų Lietuvos Respublikos valstybės biudžeto ir (arba) savivaldybių biudžetų, kitų piniginių išteklių, kuriais disponuoja valstybė ir (ar) savivaldybė, Europos Sąjungos struktūrinių fondų, kitų Europos Sąjungos finansinės paramos priemonių ar kitos tarptautinės paramos lėšų. ';
    case 'ControllingCompanies':
      return 'Nurodykite patronuojančiosios ir patronuojamųjų įmonių kodus, t.y. įmonė(s), kurios gali turėti kontrolę* Jums, įmones, kurias Jūs kaip pareiškėjas galite kontroliuoti* ir įmones, kurias kontroliuoja* Jus patronuojanti (-ios) įmonė(s):';
    case 'ControllingForeignCompanies':
      return 'Nurodykite užsienio įmonių kodus, kurios gali turėti kontrolę Jums, kaip pareiškėjui, arba kurias Jūs, kaip pareiškėjas, galite kontroliuoti';
    case 'NumberOfEmployees':
      return 'Darbuotojų skaičius paraiškos pateikimo datai';
    default:
      return name;
  }
};

export const translationsForValidationInputs = (name) => {
  switch (name) {
    case 'RealEstateObjectId':
      return 'NT objekto unikalus numeris	';
    case 'RealEstateAddress':
      return 'NT objekto adresas';
    case 'ResidentialUse':
      return 'NT objekto paskirtis';
    case 'ContractIsRegisteredAtAuthority':
      return 'Patvirtinu, kad nuomos sutartis registruota valstybės įmonėje Registrų centre.	';
    case 'ContractStartDate':
      return 'Turto nuomos pradžios data *';
    case 'ContractEndDate':
      return 'Turto nuomos pabaigos data *';
    case 'RentedArea':
      return 'Nuomojamas plotas (kv.m.)	';
    case 'MonthlyRentalPrice':
      return 'Mėnesio nuomos kaina, EUR be PVM';
    case 'ContactPersonName':
      return 'Atsakingo asmens vardas ir pavardė (kontaktinis asmuo)';
    case 'AlreadyReceivedCovidSupportSum':
      return 'Nurodykite bendrą kairiau išvardintų įmonių, iki šios paraiškos pateikimo dienos gautą pagalbos sumą EUR (tik subsidijų, dotacijų, mokesčių lengvatų forma) pagal kitas priemones, kurioms taikomas 2020 m. kovo 19 d. Europos Komisijos komunikato „Laikinoji valstybės pagalbos priemonių, skirtų ekonomikai remti reaguojant į dabartinį COVID-19 protrūkį, sistema" 3.1. skirsnis ir bendrosios nuostatos. Anksčiau gautos nuomos mokesčio kompensacijos suvesti nereikia. Įveskite 0.00, jei pagalbos nebuvo gauta.';
    case 'CheckTotalAmountLimit':
      return 'Patvirtinu, kad bendra man, kartu su įmonėmis, kurios gali turėti kontrolę man, kaip pareiškėjui, arba kurias aš, kaip pareiškėjas, galiu kontroliuoti, teikiamo finansavimo pagal 2020 m. kovo 19 d. Europos Komisijos komunikato „Laikinoji valstybės pagalbos priemonių, skirtų ekonomikai remti reaguojant į dabartinį COVID-19 protrūkį, sistema“ 3.1 dalį ir bendrąsias nuostatas (subsidijų, dotacijų, mokesčių lengvatų forma) suma neviršija 800 000 eurų, jei mano veikla vykdoma žuvininkystės ir akvakultūros sektoriuje – 120 000 eurų, jei pirminės žemės ūkio produktų gamybos srityje – 100 000 eurų per komunikato taikymo laikotarpį.';
    case 'CheckNotRentFromSelf':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) nesinuomoju patalpų iš nuomotojo, kuris yra fizinis asmuo, turintis daugumą dalyvių balsų įmonėje, kurią atstovauju.';
    case 'CheckRentForMainEconomicActivity':
      return 'Patvirtinu, kad mano (įmonės, kurią atstovauju) pagrindinė veikla yra ekonominė veikla ir su nuomotoju esu (įmonė, kurią atstovauju yra) sudaręs (-iusi) patalpų nuomos sutartį savo ekonominei veiklai vykdyti.';
    case 'CheckMainActivityWasDenied':
      return 'Patvirtinu, kad mano (įmonės, kurią atstovauju) vykdoma pagrindinė veikla atitinka veiklas, kurios karantino laikotarpiu buvo arba yra uždraustos Lietuvos Respublikos Vyriausybės 2020 m. kovo 14 d. nutarimu Nr. 207 „Dėl karantino Lietuvos Respublikoje paskelbimo“ ir aš draudimo laikotarpiu jos nevykdžiau.';
    case 'CheckNotOnlyInternetSales':
      return 'Patvirtinu, kad mano (įmonės, kurią atstovauju) vykdoma pagrindinė veikla nėra elektroninė prekyba, ginklų ir šaudmenų specializuota mažmeninė prekyba, azartinių žaidimų ir lažybų ar kitų azartinių lošimų organizavimo veikla, finansų įstaigų vykdoma veikla.';
    case 'CheckNoIllegalSupport':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) nesu (nėra) gavęs (-usi) neteisėtos pagalbos, kuri Europos Komisijos sprendimu (dėl individualios pagalbos arba pagalbos schemos) buvo pripažinta neteisėta ir nesuderinama su vidaus rinka arba esu (yra) grąžinęs (-usi) visą jos sumą, įskaitant palūkanas, teisės aktuose nustatyta tvarka.';
    case 'CheckDataIsCorrect':
      return 'Patvirtinu, kad paraiškoje pateikti nuomos sutarties duomenys ir duomenys apie pateiktą nuomos mokestį yra tikri ir teisingi.';
    case 'OwnerCompanyCode':
      return 'Nuomotojo įmonės kodas (jei nuomotojas įmonė)';
    case 'OwnerPersonalCode':
      return 'Asmens kodas (jei nuomotojas fizinis asmuo)';
    case 'OwnerIndividualActivityCertificateNumber':
      return 'Indiv. veiklos pažymėjimo/patento nr. (jei nuomotojas fizinis asmuo)';
    case 'OwnerName':
      return 'Nuomotojo įmonės pavadinimas arba asmens vardas pavardė';
    case 'OwnerPhone':
      return 'Nuomotojo kontaktinis telefono numeris';
    case 'OwnerContactPersonName':
      return 'Atsakingo asmens vardas ir pavardė (kontaktinis asmuo)';
    case 'OwnerEmail':
      return 'Nuomotojo kontaktinis el. pašto adresas';
    case 'CheckResidentialUse':
      return 'NT paskirtis';
    case 'CheckContractIsRegisteredAtAuthority':
      return 'Patvirtinu, kad nuomos sutartis registruota valstybės įmonėje Registrų centre.';
    case 'CheckNotSameCompanyGroup':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) ir nuomotojas nepriklausome tai pačiai įmonių grupei.';

    case 'CheckAdditionalRules':
      return 'Sutinku, kad INVEGA atliktų patikras susijusias su daliniu nuomos mokesčio kompensavimu. Įsipareigoju pateikti atitinkamų dokumentų kopijas, o patikrų vietoje atveju pateikti atitinkamus dokumentus susipažinti.\nĮsipareigoju saugoti su nuomos mokesčio skaičiavimu, sumokėjimu bei daliniu jo kompensavimu susijusius dokumentus ne trumpiau kaip 5 kalendorinius metus.';

    case 'CheckNotCoveredByBudget':
      return 'Patvirtinu, kad aš (įmonė, kurią atstovauju) nesu (nėra) gavęs (-usi) nuomos mokesčio kompensavimo už kompensavimo laikotarpiu patirtas nuomos mokesčio įšlaidas iš kitų Lietuvos Respublikos valstybės biudžeto ir (arba) savivaldybių biudžetų, kitų piniginių išteklių, kuriais disponuoja valstybė ir (ar) savivaldybė, Europos Sąjungos struktūrinių fondų, kitų Europos Sąjungos finansinės paramos priemonių ar kitos tarptautinės paramos lėšų. ';
    case 'ApplicantName':
      return 'Verslo subjekto atstovo vardas';
    case 'ApplicantSurname':
      return 'Verslo subjekto atstovo pavardė';
    case 'ApplicantEmail':
      return 'Verslo subjekto atstovo el. pašto adresas';
    case 'ApplicantPhone':
      return 'Verslo subjekto atstovo kontaktinis telefono numeris';
    case 'CompanyName':
      return 'Verslo subjekto  pavadinimas';
    case 'CompanyCode':
      return 'Verslo subjekto  JAR kodas';
    case 'CompanyManagerName':
      return 'Verslo subjekto  vadovo vardas, pavardė';
    case 'CompanyType':
      return 'Verslo subjekto  tipas';
    case 'CompanySizeType':
      return 'Smulkios ir vidutinės įmonės dydis';
    case 'CompanyRegistrationDate':
      return 'Verslo subjekto  registracijos data';
    case 'CompanyRegistrationAddress':
      return 'Verslo subjekto  registracijos adresas (pvz. Gatvės g. 1, Gyvenvietė, Miesto raj. sav.)';
    case 'CompanyPhone':
      return 'Verslo subjekto  kontaktinis telefono numeris';
    case 'CompanyEmail':
      return 'Verslo subjekto  el. pašto adresas';
    case 'CompanyEvrk':
      return 'Pagrindinė ekonominė planuojamo projekto veikla';
   
      case 'IndividualEvrk':
      return 'Pagrindinė ekonominė planuojamo projekto veikla';
    case 'Fsi2018Submitted':
      return 'Ar pateikta 2018 m. finansinė atskaitomybė Registrų centrui?';
    case 'Fsi2019Submitted':
      return 'Ar pateikta 2019 m. finansinė atskaitomybė Registrų centrui?';
    case 'Fsi2020Submitted':
      return 'Ar pateikta 2020 m. finansinė atskaitomybė Registrų centrui?';
    case 'Turnover18':
      return 'Apyvarta 2018 m., EUR';
    case 'Turnover19':
      return 'Apyvarta 2019 m., EUR';
    case 'Turnover20':
      return 'Apyvarta 2020 m., EUR';
    case 'Turnover21':
      return 'Apyvarta 2021 m., EUR';
    case 'Income18':
      return 'Pajamos 2018 m., EUR';
    case 'Income19':
      return 'Pajamos 2019 m., EUR';
    case 'Assets18':
      return 'Turto suma 2018 m., EUR';
    case 'Assets19':
      return 'Turto suma 2019 m., EUR';
    case 'Assets20':
      return 'Turto suma 2020 m., EUR';
    case 'Assets21':
      return 'Turto suma 2021 m., EUR';
    case 'PropertyAmount18':
      return 'Nuosavybės suma 2018 m., EUR';
    case 'PropertyAmount19':
      return 'Nuosavybės suma 2019 m., EUR';
    case 'PropertyAmount20':
      return 'Nuosavybės suma 2020 m., EUR';
    case 'PropertyAmount21':
      return 'Nuosavybės suma 2021 m., EUR';
    case 'Liabilities18':
      return 'Įsipareigojimų suma 2018 m., EUR';
    case 'Liabilities19':
      return 'Įsipareigojimų suma 2019 m., EUR';
    case 'Liabilities20':
      return 'Įsipareigojimų suma 2020 m., EUR';
    case 'Liabilities21':
      return 'Įsipareigojimų suma 2021 m., EUR';
    case 'ShortTermDebts18':
      return 'Trumpalaikių skolų tiekėjams suma 2018 m., EUR';
    case 'ShortTermDebts19':
      return 'Trumpalaikių skolų tiekėjams suma 2019 m., EUR';
    case 'EmployeesCount2019March1':
      return 'Darbuotojų skaičius 2020 m. kovo 1 dienai';
    case 'CurrentEmployeesCount':
      return 'Darbuotojų skaičius paraiškos teikimo metu ';
    case 'IsUnreable':
      return 'Patikimo mokesčių mokėtojo kriterijų neatitinkanti įmonė';
    case 'CompanyActivityAddress':
      return 'Įmonės faktinės veiklos vykdymo adresas (pvz. Gatvės g. 1, Gyvenvietė, Miesto raj. sav.)';
    case 'ControlStructure':
      return 'Pateikite verslo subjekto nuosavybės ir (ar) kontrolės struktūros aprašymą ir (arba) įkelkite struktūros schemą prie teikiamų dokumentų ';
    case 'Iban':
      return 'Verslo subjekto  atsiskaitomoji sąskaita. Nurodoma LR įsteigtoje kredito įstaigoje ar užsienio kredito įstaigos filiale, įsteigtame Lietuvoje, esanti ir pareiškėjui priklausanti atsiskaitomoji sąskaita. Formatas: LT000000000000000000';
    case 'CompanyManagerCitizenship':
      return 'Įmonės vadovo pilietybė (jeigu asmuo be pilietybės – nurodoma valstybė, kuri išdavė asmens tapatybę patvirtinantį dokumentą)';
    case 'Status':
      return 'Teisinis statusas';
    case 'PersonalCode':
      return 'Asmens kodas';
    case 'Evrk':
      return 'Patento ar individualios veiklos pažymėjimo, galiojančio paraiškos pateikimo dieną, EVRK kodas. Formatas: 000000';
    case 'PvmCode':
      return 'PVM kodas';
    case 'ParentCompanyCode':
      return 'Nurodykite visus Įmonių grupei priklausančių įmonių kodus, kurie nurodyti jūsų užpildytoje ir teikiamoje SVV subjekto statuso deklaracijoje. Jei esate didelė įmonė, nurodykite visus Įmonių grupei priklausančių įmonių kodus. Pvz. 111111111, 222222222';

    case 'OldIndividualActivityCertificateNumber':
      return 'Patento ar individualios veiklos pažymėjimo, galiojusio iki 2020-03-16,  numeris';
    case 'OldIndividualActivityCertificateNumberStartDate':
      return 'Patento ar individualios veiklos pažymėjimo, galiojusio iki 2020-03-16, pradžios data';
    case 'OldEvrk':
      return 'Patento ar individualios veiklos pažymėjimo, galiojusio iki 2020-03-16, EVRK kodas Formatas: 000000';
    case 'IndividualActivityCertificateNumber':
      return 'Galiojančios individualios veiklos ar verslo liudijimo pažymėjimo numeris';
    case 'IndividualActivityCertificateNumberStartDate':
      return 'Galiojančio Verslo liudijimo ar IV pažymos išdavimo data';
    case 'CheckBusinessman':
      return 'Patvirtinu kad, aš esu verslininkas, kaip jis apibrėžtas Lietuvos Respublikos smulkiojo ir vidutinio verslo plėtros įstatyme.';

    case 'CheckCompanySize':
      return 'Ar įmonė, kurią aš atstovauju, atsižvelgiant į Lietuvos Respublikos smulkiojo ir vidutinio verslo plėtros įstatymą (t.y. įvertinus visų susijusių ir partnerinių įmonių duomenis) yra MVĮ (labai maža, maža arba vidutinė įmonė)?';

    case 'CheckCompanyAge':
      return 'Ar įmonė, kurią aš atstovauju ir visos į įmonių grupę patenkančios įmonės (visos su mano įmone susijusios įmonės) yra veikiančios trumpiau negu trejus metus?';
    case 'CheckConsolidatedReport':
      return 'Ar įmonė, kurią aš atstovauju ir visos į įmonių grupę patenkančios įmonės (visos su mano įmone susijusios įmonės) sudaro konsoliduotąją ataskaitą? Jei taip, nurodykite įmonės kodą ir prie paraiškos prisekite konsoliduotą ataskaitą';
    case 'RentDate':
      return 'Laikotarpis, už kurį išrašyta sąskaita';
    case 'RentContract':
      return 'NT objekto unikalus numeris ir Nuomojamas plotas (kv.m.)';
    case 'ParentCompanyCodesFromRequest':
      return 'Nurodykite visus Įmonių grupei priklausančių įmonių kodus, kurie nurodyti jūsų užpildytoje ir teikiamoje SVV subjekto statuso deklaracijoje. Jei esate didelė įmonė, nurodykite visus Įmonių grupei priklausančių įmonių kodus';
    default:
      return name;
  }
};

export const invoiceTranslation = (name) => {
  switch (name) {
    case '':
      return '';
    default:
      return name;
  }
};

export const invoiceType = (id) => {
  switch (id) {
    case 1:
      return 'PVM sąskaita faktūra';
    case 2:
      return 'Pinigų priėmimo orderis';
    case 3:
      return 'Kvitas';
    case 4:
      return 'Mokama (grynais arba pavedimu) tik pagal nuomos sutartį';
    case 5:
      return 'Sąskaita faktūra';
  }
};

export const documentType = (id) => {
  switch (id) {
    case 1:
      return 'Sąskaita faktūra arba PVM sąskaita faktūra';
    case 2:
      return 'Darbo užmokesčio žiniaraštis';
    case 3:
      return 'Apmokėjimą pagrindžiantis dokumentas';
    case 4:
      return 'Priėmimo-perdavimo aktas';
    case 5:
      return 'Kiti dokumentai';
  }
};

export const amlStatus = (id) => {
  switch (id) {
    case 1:
      return 'Priimta';
    case 2:
      return 'Atmesta';
    case 3:
      return 'Rankinis patikrinimas';
    default:
      return '-';
  }
};

export const requestLogTranslation = (id) => {
  switch (id) {
    case 1:
      return 'Rengiama';
    //case 2:
    //  return 'Neatitinka kriterijų';
    case 3:
      return 'Pateikta vertinti';
    case 4:
      return 'Patvirtinta';
    case 5:
      return 'Atmesta';
    case 6:
      return 'Grąžinta tikslinimui';
    case 7:
      return 'Sugeneruota sutartis';
    case 8:
      return 'Pareiškėjo pasirašyta sutartis';
    case 9:
      return 'Pasirašyta paskolos sutartis';
    case 10:
      return 'Nutraukta paskolos sutartis';
    //case 13:
    //  return 'Paskolos suma įvesta';
    //case 14:
    //  return 'Padidinta paskolos suma';
    case 16:
      return 'Keisti būseną';
    case 19:
      return 'Pakeista sutartis';
    case 18:
      return 'Paskirtas vertintojas';
    case 20:
      return 'Įvestas verslo subjekto statusas';
    case 21:
      return 'Patvirtinta paraiška (laukiama dokumentų)';
    case 50:
      return 'Paprašyta pateikti papildomus dokumentus';
    case 22:
      return 'Pateikti papildomi dokumentai vertinimui';
    case 39:
      return 'Pateiktas prašymas pakeisti sutarties sąlygas';
    case 40:
      return 'Sugeneruotas sąlygų keitimo dokumentas';
    case 41:
      return 'Pareiškėjo pasirašytas sąlygų keitimas';
    case 42:
      return 'Invegos pasirašytas sąlygų keitimas';
    case 43:
      return 'Invegos atmestas sąlygų keitimas';
    case 44:
      return 'Įvesta papildoma AML/PEP informacija';
    case 45:
      return 'Vertintojas inicijavo sutarties keitimą';
    case 46:
      return 'Vertintojas grąžino tikslinti sutarties keitimą';
    case 47:
      return 'Patvirtintas sąlygų keitimas';
    case 48:
      return 'Pateikti papildomi dokumentai sąlygų keitimui';
    case 49:
      return 'Pateikta pareiškėjo pasirašymui';
    case 51:
      return 'Pakartotinai patikrintas JADIS';
    case 52:
      return 'Pakartotinai patikrintas AML';
    case 53:
      return 'Įvesta rizikos grupė';
    case 54:
      return 'Pakartotinai patikrintas JAR';
      case 55:
        return 'Pakeista būsena į Patvirtinta';
        case 57:
      return ' Pakeista būsena į Sąlygų keitimas';
      case 56:
      return 'Pakeista būsena į Patvirtintas sąlygų keitimas';
      default:
      return id;
  }
};

export const StatusesStats = (text) => {
  switch (text) {
    case 'Created':
      return 'Rengiama';
    case 'Evaluating':
      return 'Vertinama';
    case 'Submitted':
      return 'Pateikta (nuo priemonės pradžios)';
    case 'PositiveDecision':
      return 'Teigiamas sprendimas';
    case 'NegativeDecision':
      return 'Neigiamas sprendimas';
    case 'ContractSigned':
      return 'Pasirašyta sutartis';
    case 'ContractTerminated':
      return 'Nutraukta sutartis';
    case 'NegativeDecisionSocial':
      return 'Socialinio poveikio projektai';
    case 'NegativeDecisionOther':
      return 'Kiti projektai';
    case 'PositiveDecisionSocial':
      return 'Socialinio poveikio projektai';
    case 'PositiveDecisionOther':
      return 'Kiti projektai';

    case 'ContractSignedSocial':
      return 'Socialinio poveikio projektai';
    case 'ContractSignedOther':
      return 'Kiti projektai';

    case 'ContractTerminatedSocial':
      return 'Socialinio poveikio projektai';
    case 'ContractTerminatedOther':
      return 'Kiti projektai';
    default:
      return text;
  }
};

export const fileTypeTranslation = (type) => {
  switch (type) {
    case 1:
      return 'SVV subjekto statuso deklaracija';
    case 2:
      return 'Vienos įmonės deklaracija';
    case 3:
      return 'Verslo subjektą apibūdinantys dokumentai';
    case 4:
      return 'Verslininko individualios veiklos registracijos dokumentai';
    case 5:
      return 'Verslo planas (Aprašo priedas Nr. 2)';
    // case 6:
    //   return 'Einamųjų finansinių metų tarpinės finansinės atskaitomybės';
    //case 6:
    //  return 'Verslo subjekto valdymo organo sprendimas dėl Apyvartinės Paskolos gavimo, turto įkeitimo ir įgaliotų asmenų paskyrimo sutarties su Paskolos davėju sudarymui';
    //case 7:
    //  return 'Finansiniai dokumentai';
    //case 8:
    //  return 'Motyvuotas paaiškinimas dėl įvykusių pokyčių Verslo subjekto veikloje ir teikiamuose su paraiška dokumentuose (duomenyse)';
    // case 9:
    //    return 'Investicinės Paskolos gavėjo finansinė būklė ir prognozės (Aprašo priedas Nr. 1)';
    case 9:
      return 'Paskolos gavėjo veiklos (finansinių) duomenų detalizacijos forma';
    case 6:
      return 'Privataus finansuotojo ketinimo dalyvauti Projekte raštas (jei taikoma)';
    case 10:
      return 'Kiti dokumentai';
    //case 12:
    //  return '2019 m. ir 2020 m. Finansiniai dokumentai';
    //case 13:
    //  return 'Verslo planas (Investicinio projekto aprašymas)';
    case 7:
      return 'Einamųjų finansinių metų tarpinės finansinės atskaitomybės';
    case 8:
      return 'Verslo subjekto valdymo organo sprendimas dėl Paskolos gavimo, turto įkeitimo ir įgaliotų asmenų paskyrimo sutarties su Paskolos davėju sudarymui ';
    // case 16:
    //  return 'Principo „nedaryti reikšmingos žalos" klausimynas';
    // case 17:
    //   return 'Paskolos gavėjo finansinė būklė ir prognozės (Aprašo priedas Nr. 1)';
    case 11:
      return 'Paskolos gavėjo atitikimo žemės ūkio inovacinei veiklai aprašymas (Priedas Nr. 1)';

    default:
      return type;
  }
};

export const fileTypeTranslationNew = (type) => {
  switch (type) {
    case 1:
      return 'SVV subjekto statuso deklaracija';
    case 2:
      return 'Vienos įmonės deklaracija';
    case 3:
      return 'Verslo subjektą apibūdinantys dokumentai';
    case 4:
      return 'Verslininko individualios veiklos registracijos dokumentai';
    case 5:
      return 'Verslo planas (Projekto aprašymas)';
    case 6:
      return 'Privataus finansuotojo ketimo dalyvauti Projekto finansavime raštas';
    case 7:
      return 'Einamųjų finansinių metų tarpinis paskutinio ketvirčio finansinių ataskaitų rinkinys';
    case 8:
      return 'Verslo subjekto valdymo organo sprendimas dėl Paskolos gavimo, turto įkeitimo ir įgaliotų asmenų paskyrimo sutarties su Paskolos davėju sudarymui';
    case 9:
      return 'Verslo subjekto veiklos (finansinių) duomenų detalizacijos forma';
      case 22:
        return 'Vadovo asmens tapatybės dokumentas (pvz., pasas, asmens tapatybės kortelė)';
        case 10:
      return 'Kiti dokumentai';
    case 11:
      return 'Verslo subjekto atitikties žemės ūkio inovacinei veiklai aprašymas';
      case 55:
        return 'Laisvos formos dokumentas, kuriame nurodyti Verslo subjekto ir su juo susijusių įmonių ryšiai';
      case 56:
        return 'Verslo subjekto ar jo dalyvio (akcininko, nario, dalininko) ar savininko leidimo gyventi Lietuvoje kopija';
      case 47:
        return 'Motyvuotas paaiškinimas dėl įvykusių pokyčių Verslo subjekto veikloje';
      case 39:
        return 'Reikšmingos žalos nedarymo principui klausimynas';
    default:
      return type;
  }
};

export const paymentRequestDocumentTypes = [
  {
    name: 'Apmokėjimą pagrindžiantys dokumentai',
    fileType: 3,
    allowedTypes: '.pdf',
  },
  {
    name: 'Kiti dokumentai',
    fileType: 5,
    allowedTypes: '.docx, .xlsx, .doc, .xls, .pdf, .jpeg, .png',
  },
];

export const getNameByFileType = (documentTypes, fileType) => {
  return documentTypes.find((el) => el.fileType === fileType)?.name || fileType;
};

export const getAllowedFileTypes = (documentTypes, fileType) => {
  return (
    documentTypes.find((el) => +el.fileType === +fileType)?.allowedTypes || ''
  );
};

export const questionListTranslations = (val) => {
  switch (val) {
    case 'IsSmall':
      return 'Bendrovė ir visos su ja susijusios įmonės atitinka labai mažos arba mažos įmonės statusą pagal Lietuvos Respublikos smulkaus ir vidutinio verslo plėtros įstatymo nuostatas. Pagrindiniai kriterijai: įmonėje (atsižvelgiant ir į susijusias ir partnerines įmones) dirba mažiau kaip 50 darbuotojų ir įmonė atitinka bent vieną iš šių kriterijų: metinės pajamos neviršija 10 mln. Eur arba įmonės balanse nurodyto turto vertė neviršija 10 mln. Eur';
    case 'IsNotListed':
      return 'Bendrovė arba kuri nors iš su ja susijusių įmonių nėra listinguojamos reguliuojamoje rinkoje';
    case 'IsProfitNotShared':
      return 'Bendrovė arba kuri nors iš su ja susijusių įmonių nėra skirsčiusi pelno akcininkams ar dalininkams';
    case 'IsNotJoined':
      return 'Bendrovė arba kuri nors iš su ja susijusių įmonių nėra sukurta per įmonių susijungimą';
    case 'IsNotTaken':
      return 'Bendrovė arba kuri nors iš su ja susijusių įmonių nėra perėmusi kitos įmonės veiklą';
    case 'IsNotProblemic':
      return 'Bendrovė arba kuri nors iš su ja susijusių įmonių nėra sunkumų patirianti įmonė. Sunkumų patiriančios įmonės apibrėžimas pateiktas Komisijos reglamento (ES) Nr. 651/2014 2 straipsnio 18 dalyje';
    default:
      return val;
  }
};

export const paymentRequestLogTranslation = (id) => {
  switch (id) {
    case 23:
      return 'Pridėtas mokėjimo prašymo failas';
    case 24:
      return 'Pridėtas mokėjimo prašymo dokumento failas';
    case 25:
      return 'Pašalintas mokėjimo prašymo failas';
    case 26:
      return 'Pašalintas mokėjimo prašymo dokumento failas';
    case 27:
      return 'Sukurtas mokėjimo prašymas';
    case 28:
      return 'Mokėjimo prašymas pateiktas vertinimui';
    case 29:
      return 'Mokėjimo prašymas grąžintas tikslinimui';
    case 30:
      return 'Atmestas mokėjimo prašymas';
    case 31:
      return 'Patvirtintas mokėjimo prašymas';
    case 32:
      return 'Redaguotas mokėjimo prašymas';
    case 33:
      return 'Pridėtas mokėjimo prašymo dokumentas';
    case 34:
      return 'Ištrintas mokėjimo prašymo dokumentas';
    case 35:
      return 'Patvirtintas mokėjimo prašymo dokumentas';
    case 36:
      return 'Grąžintas mokėjimo prašymo dokumentas';
    case 37:
      return 'Vertintojas redagavo mokėjimo prašymo dokumentą';
    default:
      return id;
  }
};
