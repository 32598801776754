import React, { useState } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import styles from './deleteComfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';

const ReturnModal = ({ open, requestId, loading, dispatch, comment }) => {
  const [isLoading, setIsloading] = useState(false);
  const [adjustTill, setAdjustTill] = useState(null);
  const [notValid, setNotValid] = useState(false);

  const cancel = () => {
    const open = {
      modalName: 'returnModal',
      visible: false,
    };
    setAdjustTill(null);
    setNotValid(false);
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    setNotValid(true);
    if (adjustTill) {
      setIsloading(true);

      const body = {
        comment: comment,
        adjustTill: format(new Date(adjustTill), 'yyyy-MM-dd'),
      };
      fetch(
        Api.API_BASE_URL + config.API_ENDPOINTS.setReturnRequest(requestId),
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
          body: JSON.stringify(body),
        }
      )
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.backoffice.getRequestAction(requestId));
            dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
            dispatch(
              actions.backoffice.saveEditAdditionalComments('get', requestId)
            );
            dispatch(actions.backoffice.getRequestLogAction(requestId));
            cancel();

            swal({
              title: 'Paraiška sėkmingai grąžinta tikslinimui',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 401) {
            swal({
              title: `Įvyko klaida`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              swal({
                title: `${
                  r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
                }`,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={isLoading}>
            <h2>Ar tikrai norite gražinti paraišką tikslinimui?</h2>

            <div className="modal-title">
              <div
                className={styles.inputsInline}
                style={{ marginRight: '0rem', width: '150px' }}
              >
                <div>
                  <label
                    className="inlineDatepickerInputTitle"
                    style={{ width: '150px', margin: '1.5rem 0 0.6rem 0.5rem' }}
                  >
                    Tikslinti iki:
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    value={adjustTill != null ? new Date(adjustTill) : ''}
                    selected={adjustTill != null ? new Date(adjustTill) : ''}
                    onChange={(e) => setAdjustTill(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                    style={{ width: '50px' }}
                  />
                </div>
              </div>
            </div>
            {notValid && !adjustTill && (
              <p className="errorsBlockText">Privaloma pasirinkti datą</p>
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <br />

            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes()}>
                Taip
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(ReturnModal);
