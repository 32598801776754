import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import {
  WithSpinner,
  TableAdmin,
  TableRow,
  TableCell,
  Button,
  MultiSelect,
} from '../../components';
import styles from '../backoffice/backoffice.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEuroSign,
  faEye,
  faFileAlt,
  faWindowRestore,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { config } from '../../api/config';
import {
  requestStatus,
  amlStatus,
  helpSchem,
  loanPurpose,
  paymentRequestStatus,
  paymentRequestLogTranslation,
} from './../../utils/translation/translation';
import { formatNumber } from './../../utils/helpers/helpers';
import { addDays, differenceInBusinessDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import {
  resetLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from './../../utils';
import ImportFilesModal from './../../modals/importFilesModal/importFilesModal';
import AdditionalVmiCheckConfirmation from './../../modals/AdditionalVmiCheckConfirmation/AdditionalVmiCheckConfirmation';

const countPerPage = 30;
const filterKey = 'boFilter';

const Backoffice = ({
  dispatch,
  requestsState,
  loading,
  importFilesModalVisible,
  additionalVmiCheckConfirmationVisible,
}) => {
  const tableHeader = [
    'Paraiškos numeris',
    'Pareiškėjas',
    'Pareiškėjo kodas',
    'Sukūrimo data',
    'Paskolos paskirtis socialiniam verslui vykdyti?',
    'Paskolos paskirtis',
    //'Taikoma valstybės pagalbos schema',
    'Pirmo pateikimo vertinti data / Pateikimo vertinimui data',
    'Paraiškos patvirtinimo data',
    'Sutarties pasirašymo data',
    'Liko dienų pareiškėjo pasirašymui',
    'Liko dienų paraiškos patikslinimui',
    'Pageidaujama paskolos suma, EUR',
    'Paskolos suma, EUR',
    'Mokėjimo prašymų sk.',
    'Patvirtintų mokėjimo prašymų sk.',
    'AML',
    'PEP',
    'Ar yra tvirtintojo komentaras?',
    'Ar keistos sąlygos?',
    'Ar atmesta pareiškėjo prašymu?',
    'Būsena',
    'Vertintojas',
    'Veiksmai',
  ];

  const [filter, setFilter] = useState({
    RequestId: null,
    RequestStatusId: null,
    RequestStatusIds: null,

    HasParentCompanyCode: null,
    CompanyCode: null,
    CompanyName: null,
    // OnlyRelevant: false,
    OnlyWithNewInvoices: false,
    AllEvaluationCompleted: null,
    InvegaIncreasedLoanSum: null,
    SubmittedDates: {
      dateFrom: null,
      dateTo: null,
    },
    ApprovedDates: {
      dateFrom: null,
      dateTo: null,
    },
    SignedDates: {
      dateFrom: null,
      dateTo: null,
    },
    ChangeConditionsDate: {
      dateFrom: null,
      dateTo: null,
    },

    FirstSubmittedDate: {
      dateFrom: null,
      dateTo: null,
    },
    UserEmail: null,
    RequestAction: null,
    BusinessDaysToSignature: null,
    DaysTillAdjust: null,
    AssessorId: null,
    IsReserved: null,
    FormVersion: null,
    ConditionsChanged: null,
    IsRejectedByClient: null,
    HasNewPaymentRequests: null,
    PaymentRequestSubmitDate: {
      dateFrom: null,
      dateTo: null,
    },
    paymentRequestStatuseId: null,
    LoanSocialBusiness: null,
    loanPurposeId: null,
    helpSchema: null,
    PaymentRequestAction: null,
    PaymentUserEmail: null,
  });

  const openPaymentRequest = (id) => {
    dispatch(actions.dashboard.getPaymentRequestsListAction(id));
  };

  const [clickedPage, setClickedPage] = useState(1);

  const clearFilters = (page) => {
    removeLocalStorageItem(filterKey);
    dispatch(
      actions.backoffice.getRequestListAction({
        RequestId: null,
        RequestStatusId: null,
        RequestStatusIds: null,

        HasParentCompanyCode: null,
        CompanyCode: null,
        CompanyName: null,
        //OnlyRelevant: false,
        OnlyWithNewInvoices: false,
        AllEvaluationCompleted: null,
        InvegaIncreasedLoanSum: null,
        SubmittedDates: null,
        ApprovedDates: null,
        SignedDates: null,
        ChangeConditionsDate: null,
        FirstSubmittedDate: null,
        page: page,
        countPerPage: countPerPage,
        sortDirection: 2,
        UserEmail: null,
        RequestAction: null,
        BusinessDaysToSignature: null,
        DaysTillAdjust: null,
        AssessorId: null,
        IsReserved: null,
        FormVersion: null,
        ConditionsChanged: null,
        IsRejectedByClient: null,
        HasNewPaymentRequests: null,
        PaymentRequestSubmitDate: null,
        paymentRequestStatuseId: null,
        LoanSocialBusiness: null,
        loanPurposeId: null,
        helpSchema: null,
        PaymentRequestAction: null,
        PaymentUserEmail: null,
      })
    );
    setFilter({
      RequestId: null,
      RequestStatusId: null,
      RequestStatusIds: null,

      HasParentCompanyCode: null,
      CompanyCode: '',
      CompanyName: '',
      //OnlyRelevant: false,
      OnlyWithNewInvoices: false,
      AllEvaluationCompleted: null,
      InvegaIncreasedLoanSum: null,
      IgnoreFiles: false,
      SubmittedDates: {
        dateFrom: null,
        dateTo: null,
      },
      ApprovedDates: {
        dateFrom: null,
        dateTo: null,
      },
      SignedDates: {
        dateFrom: null,
        dateTo: null,
      },

      ChangeConditionsDate: {
        dateFrom: null,
        dateTo: null,
      },
      FirstSubmittedDate: {
        dateFrom: null,
        dateTo: null,
      },
      UserEmail: null,
      RequestAction: null,
      BusinessDaysToSignature: null,
      DaysTillAdjust: null,
      AssessorId: null,
      IsReserved: null,
      FormVersion: null,
      ConditionsChanged: null,
      IsRejectedByClient: null, 
      HasNewPaymentRequests: null,
      PaymentRequestSubmitDate: {
        dateFrom: null,
        dateTo: null,
      },
      LoanSocialBusiness: null,
      loanPurposeId: null,
      helpSchema: null,
    });
    resetLocalStorageItem('currentPage', 1);
    setClickedPage(1);
    window.location.reload();
  };

  const filterStatus = ({ target: { name, value } }) => {
    setFilter({ ...filter, [name]: value === '-' ? null : value });
  };

  const setTrueFalseFilter = ({ target: { name, value } }) => {
    setFilter({
      ...filter,
      [name]: value === '-' ? null : value == 'true' ? true : false,
    });
  };

  const filterOnlyRelevant = ({ target: { name, value } }) => {
    setFilter({ ...filter, [name]: value === 'true' });
  };

  const handleDateChange = (date, name) => {
    if (date) {
      setFilter({
        ...filter,
        SubmittedDates: { ...filter.SubmittedDates, [name]: date },
      });
    }
  };

  const handleApprovedDatesChange = (date, name) => {
    if (date) {
      setFilter({
        ...filter,
        ApprovedDates: { ...filter.ApprovedDates, [name]: date },
      });
    }
  };

  const handleSignedDatesChange = (date, name) => {
    if (date) {
      setFilter({
        ...filter,
        SignedDates: { ...filter.SignedDates, [name]: date },
      });
    }
  };

  const handleChangeConditionsDateChange = (date, name) => {
    if (date) {
      setFilter({
        ...filter,
        ChangeConditionsDate: { ...filter.ChangeConditionsDate, [name]: date },
      });
    }
  };

  const handleFirstSubmittedDateChange = (date, name) => {
    if (date) {
      setFilter({
        ...filter,
        FirstSubmittedDate: { ...filter.FirstSubmittedDate, [name]: date },
      });
    }
  };

  const handlePaymentRequestSubmittedDateChange = (date, name) => {
    if (date) {
      setFilter({
        ...filter,
        PaymentRequestSubmitDate: {
          ...filter.PaymentRequestSubmitDate,
          [name]: date,
        },
      });
    }
  };

  const applyFilter = () => {
    resetLocalStorageItem(filterKey, JSON.stringify(filter));
    resetLocalStorageItem('currentPage', 1);
    fetchdata(1);
    setClickedPage(1);
  };

  const fetchdata = (page) => {
    let storageFilter = getLocalStorageItem(filterKey);
    if (storageFilter) {
      storageFilter = JSON.parse(storageFilter);
    } else {
      storageFilter = {
        RequestId: null,
        RequestStatusId: null,
        RequestStatusIds: null,

        HasParentCompanyCode: null,
        CompanyCode: '',
        CompanyName: '',
        //OnlyRelevant: false,
        OnlyWithNewInvoices: false,
        AllEvaluationCompleted: null,
        InvegaIncreasedLoanSum: null,
        IgnoreFiles: false,
        SubmittedDates: {
          dateFrom: null,
          dateTo: null,
        },
        ApprovedDates: {
          dateFrom: null,
          dateTo: null,
        },
        SignedDates: {
          dateFrom: null,
          dateTo: null,
        },

        ChangeConditionsDate: {
          dateFrom: null,
          dateTo: null,
        },
        FirstSubmittedDate: {
          dateFrom: null,
          dateTo: null,
        },
        UserEmail: null,
        RequestAction: null,
        BusinessDaysToSignature: null,
        DaysTillAdjust: null,
        AssessorId: null,
        IsReserved: null,
        FormVersion: null,
        ConditionsChanged: null,
        IsRejectedByClient: null,
        HasNewPaymentRequests: null,
        PaymentRequestSubmitDate: {
          dateFrom: null,
          dateTo: null,
        },
        paymentRequestStatuseId: null,
        LoanSocialBusiness: null,
        loanPurposeId: null,
        helpSchema: null,
        PaymentRequestAction: null,
        PaymentUserEmail: null,
      };
    }

    dispatch(actions.backoffice.setRequestsList([]));

    dispatch(
      actions.backoffice.getRequestListAction({
        RequestId: storageFilter.RequestId,
        RequestStatusId: storageFilter.RequestStatusId,
        RequestStatusIds: storageFilter.RequestStatusIds,

        HasParentCompanyCode: storageFilter.HasParentCompanyCode,
        CompanyName: storageFilter.CompanyName,
        CompanyCode: storageFilter.CompanyCode,
        // OnlyRelevant: storageFilter.OnlyRelevant,
        OnlyWithNewInvoices: storageFilter.OnlyWithNewInvoices,
        AllEvaluationCompleted: storageFilter.AllEvaluationCompleted,
        InvegaIncreasedLoanSum: storageFilter.InvegaIncreasedLoanSum,

        SubmittedDates: {
          dateFrom: storageFilter.SubmittedDates.dateFrom
            ? format(
                new Date(storageFilter.SubmittedDates.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: storageFilter.SubmittedDates.dateTo
            ? addDays(new Date(storageFilter.SubmittedDates.dateTo), 1)
            : null,
        },
        ApprovedDates: {
          dateFrom: storageFilter.ApprovedDates.dateFrom
            ? format(
                new Date(storageFilter.ApprovedDates.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: storageFilter.ApprovedDates.dateTo
            ? addDays(new Date(storageFilter.ApprovedDates.dateTo), 1)
            : null,
        },
        SignedDates: {
          dateFrom: storageFilter.SignedDates.dateFrom
            ? format(new Date(storageFilter.SignedDates.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: storageFilter.SignedDates.dateTo
            ? format(new Date(storageFilter.SignedDates.dateTo), 'yyyy-MM-dd')
            : null,
        },

        ChangeConditionsDate: {
          dateFrom: storageFilter.ChangeConditionsDate.dateFrom
            ? format(
                new Date(storageFilter.ChangeConditionsDate.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: storageFilter.ChangeConditionsDate.dateTo
            ? format(
                new Date(storageFilter.ChangeConditionsDate.dateTo),
                'yyyy-MM-dd'
              )
            : null,
        },

        FirstSubmittedDate: {
          dateFrom: storageFilter.FirstSubmittedDate.dateFrom
            ? format(
                new Date(storageFilter.FirstSubmittedDate.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: storageFilter.FirstSubmittedDate.dateTo
            ? format(
                new Date(storageFilter.FirstSubmittedDate.dateTo),
                'yyyy-MM-dd'
              )
            : null,
        },
        page: page,
        countPerPage: countPerPage,
        sortDirection: 2,
        UserEmail: storageFilter.UserEmail,
        RequestAction: storageFilter.RequestAction,
        BusinessDaysToSignature: storageFilter.BusinessDaysToSignature,
        DaysTillAdjust: storageFilter.DaysTillAdjust,
        AssessorId: storageFilter.AssessorId,
        IsReserved: storageFilter.IsReserved,
        FormVersion: storageFilter.FormVersion,
        ConditionsChanged: storageFilter.ConditionsChanged,
        IsRejectedByClient: storageFilter.IsRejectedByClient,
        HasNewPaymentRequests: storageFilter.HasNewPaymentRequests,
        PaymentRequestSubmitDate: {
          dateFrom: storageFilter.PaymentRequestSubmitDate.dateFrom
            ? format(
                new Date(storageFilter.PaymentRequestSubmitDate.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: storageFilter.PaymentRequestSubmitDate.dateTo
            ? format(
                new Date(storageFilter.PaymentRequestSubmitDate.dateTo),
                'yyyy-MM-dd'
              )
            : null,
        },
        paymentRequestStatuseId: storageFilter.paymentRequestStatuseId,
        LoanSocialBusiness: storageFilter.LoanSocialBusiness,
        loanPurposeId: storageFilter.loanPurposeId,
        helpSchema: storageFilter.helpSchema,
        paymentRequestAction: storageFilter.PaymentRequestAction,
        paymentUserEmail: storageFilter.PaymentUserEmail,
      })
    );
    setClickedPage(1);
  };

  useEffect(() => {
    dispatch(actions.backoffice.getInvegaUsersAction());
    const storageFilter = getLocalStorageItem(filterKey);
    if (storageFilter) {
      const parsedData = JSON.parse(storageFilter);
      setFilter(JSON.parse(storageFilter));
      setStatusIds(parsedData?.RequestStatusIds);
    }
    const page = getLocalStorageItem('currentPage') ?? 1;
    fetchdata(page);
    setClickedPage(page);
    checkIfTermIsExpiring();
  }, []);

  const onPageClick = (page) => {
    resetLocalStorageItem('currentPage', page);
    fetchdata(page);
    setClickedPage(page);
  };

  const download = () => {
    dispatch(
      actions.backoffice.exportDataAction({
        ...filter,
        SubmittedDates: {
          dateFrom: filter.SubmittedDates.dateFrom,
          dateTo: filter.SubmittedDates.dateTo
            ? addDays(new Date(filter.SubmittedDates.dateTo), 1)
            : null,
        },
        ApprovedDates: {
          dateFrom: filter.ApprovedDates.dateFrom,
          dateTo: filter.ApprovedDates.dateTo
            ? addDays(new Date(filter.ApprovedDates.dateTo), 1)
            : null,
        },
        SignedDates: {
          dateFrom: filter.SignedDates.dateFrom
            ? format(new Date(filter.SignedDates.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: filter.SignedDates.dateTo
            ? format(new Date(filter.SignedDates.dateTo), 'yyyy-MM-dd')
            : null,
        },

        ChangeConditionsDate: {
          dateFrom: filter.ChangeConditionsDate.dateFrom
            ? format(
                new Date(filter.ChangeConditionsDate.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: filter.ChangeConditionsDate.dateTo
            ? format(new Date(filter.ChangeConditionsDate.dateTo), 'yyyy-MM-dd')
            : null,
        },
        FirstSubmittedDate: {
          dateFrom: filter.FirstSubmittedDate.dateFrom
            ? format(new Date(filter.FirstSubmittedDate.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: filter.FirstSubmittedDate.dateTo
            ? format(new Date(filter.FirstSubmittedDate.dateTo), 'yyyy-MM-dd')
            : null,
        },
      })
    );
  };

  const downloadList = () => {
    dispatch(
      actions.backoffice.downloadListAction({
        ...filter,
        RequestId: filter.RequestId,
        RequestStatusId: filter.RequestStatusId,
        RequestStatusIds: filter.RequestStatusIds,

        CompanyName: filter.CompanyName,
        CompanyCode: filter.CompanyCode,
        SubmittedDates: {
          dateFrom: filter.SubmittedDates.dateFrom
            ? format(new Date(filter.SubmittedDates.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: filter.SubmittedDates.dateTo
            ? addDays(new Date(filter.SubmittedDates.dateTo), 1)
            : null,
        },
        ApprovedDates: {
          dateFrom: filter.ApprovedDates.dateFrom
            ? format(new Date(filter.ApprovedDates.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: filter.ApprovedDates.dateTo
            ? addDays(new Date(filter.ApprovedDates.dateTo), 1)
            : null,
        },
        SignedDates: {
          dateFrom: filter.SignedDates.dateFrom
            ? format(new Date(filter.SignedDates.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: filter.SignedDates.dateTo
            ? format(new Date(filter.SignedDates.dateTo), 'yyyy-MM-dd')
            : null,
        },
        ChangeConditionsDate: {
          dateFrom: filter.ChangeConditionsDate.dateFrom
            ? format(
                new Date(filter.ChangeConditionsDate.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: filter.ChangeConditionsDate.dateTo
            ? format(new Date(filter.ChangeConditionsDate.dateTo), 'yyyy-MM-dd')
            : null,
        },
        FirstSubmittedDate: {
          dateFrom: filter.FirstSubmittedDate.dateFrom
            ? format(new Date(filter.FirstSubmittedDate.dateFrom), 'yyyy-MM-dd')
            : null,
          dateTo: filter.FirstSubmittedDate.dateTo
            ? format(new Date(filter.FirstSubmittedDate.dateTo), 'yyyy-MM-dd')
            : null,
        },
        RequestAction: filter.RequestAction,
        BusinessDaysToSignature: filter.BusinessDaysToSignature,
        DaysTillAdjust: filter.DaysTillAdjust,
        AssessorId: filter.AssessorId,
        IsReserved: filter.IsReserved,
        FormVersion: filter.FormVersion,
        ConditionsChanged: filter.ConditionsChanged,
        IsRejectedByClient: filter.IsRejectedByClient,
        HasNewPaymentRequests: filter.HasNewPaymentRequests,
        PaymentRequestSubmitDate: {
          dateFrom: filter.PaymentRequestSubmitDate.dateFrom
            ? format(
                new Date(filter.PaymentRequestSubmitDate.dateFrom),
                'yyyy-MM-dd'
              )
            : null,
          dateTo: filter.PaymentRequestSubmitDate.dateTo
            ? format(
                new Date(filter.PaymentRequestSubmitDate.dateTo),
                'yyyy-MM-dd'
              )
            : null,
        },
        paymentRequestStatuseId: filter.paymentRequestStatuseId,
        LoanSocialBusiness: filter.LoanSocialBusiness,
        loanPurposeId: filter.loanPurposeId,
        helpSchema: filter.helpSchema,
        paymentRequestAction: filter.PaymentRequestAction,
        paymentUserEmail: filter.PaymentUserEmail,
      })
    );
  };
  const signAll = () => {
    dispatch(actions.dashboard.GetSigningUrlBatchAction());
  };

  const checkIfTermIsExpiring = (val, status) => {
    return false;
    // var utc = new Date().toJSON().slice(0, 10).replace(/-/g, ',');
    // const diff = differenceInBusinessDays(new Date(utc), new Date(val));
    // if (status === 2) {
    //   return diff > 6 ? true : false;
    // } else {
    //   return false;
    // }
  };

  const hasComments = (val1, val2) => {
    return `${val1 === true ? 'Taip' : 'Ne'}`;
  };

  const importFiles = () => {
    const open = {
      modalName: 'importFilesModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const [additionalCheckSelection, setAdditionalCheckSelection] =
    useState(null);
  const AdditionalVmiCheckClick = (selection) => {
    setAdditionalCheckSelection(selection);

    const open = {
      modalName: 'additionalVmiCheckConfirmation',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const PaymentRequestActionOptions = [
    { value: null, name: '-' },
    // { value: 23, name: paymentRequestLogTranslation(23) },
    // { value: 24, name: paymentRequestLogTranslation(24) },
    // { value: 25, name: paymentRequestLogTranslation(25) },
    // { value: 26, name: paymentRequestLogTranslation(26) },
    // { value: 27, name: paymentRequestLogTranslation(27) },
    // { value: 28, name: paymentRequestLogTranslation(28) },
    { value: 29, name: paymentRequestLogTranslation(29) },
    { value: 30, name: paymentRequestLogTranslation(30) },
    { value: 31, name: paymentRequestLogTranslation(31) },
    // { value: 32, name: paymentRequestLogTranslation(32) },
    // { value: 33, name: paymentRequestLogTranslation(33) },
    // { value: 34, name: paymentRequestLogTranslation(34) },
    // { value: 35, name: paymentRequestLogTranslation(35) },
    // { value: 36, name: paymentRequestLogTranslation(36) },
    { value: 37, name: paymentRequestLogTranslation(37) },
  ];

  const [statusIds, setStatusIds] = useState([]);

  const onMultiSelectChange = (event) => {
    setStatusIds(event);
    const requestStatusIds = event?.map((val) => val.value);

    setFilter({
      ...filter,
      RequestStatusIds: requestStatusIds?.length > 0 ? requestStatusIds : null,
    });
  };

  const requestStatusOptions = [
    { label: 'Rengiama', value: 1 },
    { label: 'Pateikta vertinti', value: 2 },
    { label: 'Patvirtinta', value: 3 },
    { label: 'Atmesta', value: 4 },
    { label: 'Grąžinta tikslinimui', value: 5 },
    { label: 'Neatitinka kriterijų', value: 6 },
    { label: 'Sugeneruota sutartis', value: 7 },
    { label: 'Pareiškėjo pasirašyta sutartis', value: 8 },
    { label: 'Pasirašyta finansavimo sutartis', value: 9 },
    { label: 'Nutraukta sutartis', value: 10 },
    { label: 'Laukiama papildomų dokumentų', value: 11 },
    { label: 'Pateikti papildomi dokumentai', value: 12 },

    { label: 'Sąlygų keitimas', value: 13 },
    { label: 'Sąlygų keitimas grąžintas tikslinimui', value: 19 },
    { label: 'Pateikti papildomi dokumentai sąlygų keitimui', value: 18 },
    { label: 'Patvirtintas sąlygų keitimas', value: 17 },
    { label: 'Sugeneruotas sąlygų keitimo dokumentas', value: 14 },
    { label: 'Pareiškėjo pasirašytas sąlygų keitimo dokumentas', value: 15 },
    { label: 'Invegos pasirašytas sąlygų keitimo dokumentas', value: 16 },
  ];

  return (
    <WithSpinner loading={loading}>
      <div style={{ width: '96%', marginLeft: '2%' }}>
        <h2 style={{ marginTop: '1.5rem' }}>Paraiškos</h2>
        <div>
          <div>
            <div>
              <label className={styles.filterText}>
                Paraiškos nr.:
                <input
                  type="number"
                  name="RequestId"
                  value={filter.RequestId}
                  onChange={(e) => filterStatus(e)}
                  style={{ color: '#597a96', width: '4rem' }}
                  className={styles.filterInput}
                />
              </label>
              <label className={styles.filterText}>
                Pareiškėjas:
                <input
                  type="text"
                  name="CompanyName"
                  value={filter.CompanyName}
                  onChange={(e) => filterStatus(e)}
                  style={{ color: '#597a96' }}
                  className={styles.filterInput}
                />
              </label>
              <label
                style={{ marginLeft: '0rem', marginRight: '1rem' }}
                className={styles.filterText}
              >
                Pareiškėjo kodas:
                <input
                  type="text"
                  name="CompanyCode"
                  value={filter.CompanyCode}
                  onChange={(e) => filterStatus(e)}
                  style={{ color: '#597a96' }}
                  className={styles.filterInput}
                />
              </label>
              <div style={{ display: 'flex' }}></div>
              {/*<label className={styles.filterText}>
                Statusas:
                <select
                  onChange={(e) => filterStatus(e)}
                  name="RequestStatusId"
                  className={styles.selectInput}
                >
                  <option selected={!filter.RequestStatusId} value={''}>
                    Visos
                  </option>
                  {[...Array(7).keys()].map((i) => (
                    <option
                      key={i}
                      selected={filter.RequestStatusId == i + 1}
                      value={i + 1}
                    >
                      {requestStatus(i + 1)}
                    </option>
                  ))}
                  <option selected={filter.RequestStatusId == 11} value={11}>
                    {requestStatus(11)}
                  </option>
                  <option selected={filter.RequestStatusId == 12} value={12}>
                    {requestStatus(12)}
                  </option>
                  <option selected={filter.RequestStatusId == 8} value={8}>
                    {requestStatus(8)}
                  </option>
                  <option selected={filter.RequestStatusId == 9} value={9}>
                    {requestStatus(9)}
                  </option>
                  <option selected={filter.RequestStatusId == 10} value={10}>
                    {requestStatus(10)}
                  </option>
                  <option selected={filter.RequestStatusId == 13} value={13}>
                    {requestStatus(13)}
                  </option>
                  <option selected={filter.RequestStatusId == 19} value={19}>
                    {requestStatus(19)}
                  </option>
                  <option selected={filter.RequestStatusId == 18} value={18}>
                    {requestStatus(18)}
                  </option>
                  <option selected={filter.RequestStatusId == 17} value={17}>
                    {requestStatus(17)}
                  </option>
                  <option selected={filter.RequestStatusId == 14} value={14}>
                    {requestStatus(14)}
                  </option>
                  <option selected={filter.RequestStatusId == 15} value={15}>
                    {requestStatus(15)}
                  </option>
                  <option selected={filter.RequestStatusId == 16} value={16}>
                    {requestStatus(16)}
                  </option>
                </select>
                  </label>*/}
            </div>
            <div>
              {' '}
              <div style={{ width: '100%', display: 'flex' }}>
                <lable className={styles.filterText2}>Statusas</lable>
                <div style={{ width: '100%' }}>
                  <MultiSelect
                    placeholder={'Pasirinkite statusus'}
                    onChange={(e) => onMultiSelectChange(e)}
                    defaultValue={statusIds || null}
                    value={statusIds || ''}
                    options={requestStatusOptions}
                  />
                </div>
              </div>
            </div>
            <div
              className={styles.dateFilterContainer}
              style={{ width: '1500px' }}
            >
              <label className={styles.filterText}>
                Ar paskola skirta socialiniam verslui vykdyti?
                <select
                  name="LoanSocialBusiness"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option
                    selected={filter.LoanSocialBusiness == null}
                    value={null}
                  >
                    -
                  </option>
                  <option selected={filter.LoanSocialBusiness == 1} value={1}>
                    Taip
                  </option>
                  <option selected={filter.LoanSocialBusiness == 0} value={0}>
                    Ne
                  </option>
                  <option selected={filter.LoanSocialBusiness == 3} value={3}>
                    Taip (esu Ukrainos pilietis)
                  </option>
                  <option selected={filter.LoanSocialBusiness == 2} value={2}>
                    Ne (esu Ukrainos pilietis)
                  </option>
                </select>
              </label>
              <label className={styles.filterText}>
                Paskolos paskirtis:
                <select
                  name="loanPurposeId"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option selected={filter.loanPurposeId == null} value={null}>
                    -
                  </option>
                  <option selected={filter.loanPurposeId == 1} value={1}>
                    Investicinė
                  </option>
                  <option selected={filter.loanPurposeId == 2} value={2}>
                    Apyvartinė
                  </option>
                </select>
              </label>
              <label className={styles.filterText}>
                Taikoma valstybės pagalbos schema:
                <select
                  name="helpSchema"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option selected={filter.helpSchema == null} value={null}>
                    -
                  </option>
                  <option selected={filter.helpSchema == 1} value={1}>
                    De minimis
                  </option>
                  <option selected={filter.helpSchema == 2} value={2}>
                    GBER
                  </option>
                  <option selected={filter.helpSchema == 3} value={3}>
                    Netaikoma
                  </option>
                </select>
              </label>
            </div>
            <div
              className={styles.dateFilterContainer}
              style={{ marginTop: '10px' }}
            >
              <label
                className={styles.filterText}
                style={{ marginTop: '15px' }}
              >
                Pirmo pateikimo vertinti data nuo:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.FirstSubmittedDate.dateFrom != null
                    ? new Date(filter.FirstSubmittedDate.dateFrom)
                    : ''
                }
                onChange={(e) => handleFirstSubmittedDateChange(e, 'dateFrom')}
                dateFormat="yyyy-MM-dd"
              />

              <label
                className={styles.filterText}
                style={{ marginLeft: '1rem', marginTop: '15px' }}
              >
                Pirmo pateikimo vertinti data iki:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.FirstSubmittedDate.dateTo != null
                    ? new Date(filter.FirstSubmittedDate.dateTo)
                    : ''
                }
                onChange={(e) => handleFirstSubmittedDateChange(e, 'dateTo')}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className={styles.dateFilterContainer}>
              <label
                className={styles.filterText}
                style={{ marginTop: '15px' }}
              >
                Pateikimo vertinimui data nuo:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.SubmittedDates.dateFrom != null
                    ? new Date(filter.SubmittedDates.dateFrom)
                    : ''
                }
                onChange={(e) => handleDateChange(e, 'dateFrom')}
                dateFormat="yyyy-MM-dd"
              />

              <label
                className={styles.filterText}
                style={{ marginLeft: '1rem', marginTop: '15px' }}
              >
                Pateikimo vertinimui data iki:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.SubmittedDates.dateTo != null
                    ? new Date(filter.SubmittedDates.dateTo)
                    : ''
                }
                onChange={(e) => handleDateChange(e, 'dateTo')}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className={styles.dateFilterContainer}>
              <label
                className={styles.filterText}
                style={{ marginTop: '15px' }}
              >
                Paraiškos patvirtinimo data nuo:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.ApprovedDates.dateFrom != null
                    ? new Date(filter.ApprovedDates.dateFrom)
                    : ''
                }
                onChange={(e) => handleApprovedDatesChange(e, 'dateFrom')}
                dateFormat="yyyy-MM-dd"
              />

              <label
                className={styles.filterText}
                style={{ marginLeft: '1rem', marginTop: '15px' }}
              >
                Paraiškos patvirtinimo data iki:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.ApprovedDates.dateTo != null
                    ? new Date(filter.ApprovedDates.dateTo)
                    : ''
                }
                onChange={(e) => handleApprovedDatesChange(e, 'dateTo')}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className={styles.dateFilterContainer}>
              <label
                className={styles.filterText}
                style={{ marginTop: '15px' }}
              >
                Invega sutarties pasirašymo data nuo:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.SignedDates.dateFrom != null
                    ? new Date(filter.SignedDates.dateFrom)
                    : ''
                }
                onChange={(e) => handleSignedDatesChange(e, 'dateFrom')}
                dateFormat="yyyy-MM-dd"
              />

              <label
                className={styles.filterText}
                style={{ marginLeft: '1rem', marginTop: '15px' }}
              >
                Invega sutarties pasirašymo data iki:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.SignedDates.dateTo != null
                    ? new Date(filter.SignedDates.dateTo)
                    : ''
                }
                onChange={(e) => handleSignedDatesChange(e, 'dateTo')}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className={styles.dateFilterContainer}>
              <label
                className={styles.filterText}
                style={{ marginTop: '15px' }}
              >
                Prašymo dėl sutarties sąlygų keitimo data nuo:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.ChangeConditionsDate.dateFrom != null
                    ? new Date(filter.ChangeConditionsDate.dateFrom)
                    : ''
                }
                onChange={(e) =>
                  handleChangeConditionsDateChange(e, 'dateFrom')
                }
                dateFormat="yyyy-MM-dd"
              />

              <label
                className={styles.filterText}
                style={{ marginLeft: '1rem', marginTop: '15px' }}
              >
                Prašymo dėl sutarties sąlygų keitimo data iki:
              </label>
              <DatePicker
                className={styles.filterInput}
                selected={
                  filter.ChangeConditionsDate.dateTo != null
                    ? new Date(filter.ChangeConditionsDate.dateTo)
                    : ''
                }
                onChange={(e) => handleChangeConditionsDateChange(e, 'dateTo')}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div
              className={styles.dateFilterContainer}
              style={{ width: '900px' }}
            >
              <label className={styles.filterText}>
                Liko dienų pareiškėjo pasirašymui:
                <input
                  type="text"
                  name="BusinessDaysToSignature"
                  value={filter.BusinessDaysToSignature}
                  onChange={(e) => filterStatus(e)}
                  style={{ color: '#597a96' }}
                  className={styles.filterInput}
                />
              </label>

              <label className={styles.filterText}>
                Liko dienų paraiškos patikslinimui:
                <input
                  type="text"
                  name="DaysTillAdjust"
                  value={filter.DaysTillAdjust}
                  onChange={(e) => filterStatus(e)}
                  style={{ color: '#597a96' }}
                  className={styles.filterInput}
                />
              </label>
            </div>

            <div>
              <label className={styles.filterText}>
                Veiksmas:
                <select
                  name="RequestAction"
                  onChange={filterStatus}
                  className={styles.selectInput}
                  style={{ width: '350px' }}
                >
                  <option selected={filter.RequestAction == null} value={null}>
                    -
                  </option>
                  <option selected={filter.RequestAction == 18} value={18}>
                    Paskirti vertintoją
                  </option>
                  <option selected={filter.RequestAction == 20} value={20}>
                    Įvesti verslo subjekto statusą
                  </option>
                  <option selected={filter.RequestAction == 6} value={6}>
                    Grąžinta tikslinti
                  </option>
                  <option selected={filter.RequestAction == 5} value={5}>
                    Atmesta paraiška
                  </option>
                  <option selected={filter.RequestAction == 4} value={4}>
                    Patvirtinta paraiška
                  </option>
                  <option selected={filter.RequestAction == 21} value={21}>
                    Patvirtinta paraiška (laukiama dokumentų)
                  </option>
                  <option selected={filter.RequestAction == 50} value={50}>
                    {/*TBD*/}
                    Prašymas pateikti papildomus dokumentus
                  </option>
                  <option selected={filter.RequestAction == 49} value={49}>
                    Pateikta pareiškėjo pasirašymui
                  </option>
                  <option selected={filter.RequestAction == 19} value={19}>
                    Pakeista sutartis
                  </option>
                  <option selected={filter.RequestAction == 10} value={10}>
                    Nutraukta sutartis
                  </option>

                  <option selected={filter.RequestAction == 9} value={9}>
                    Pasirašyta finansavimo sutartis
                  </option>
                  <option selected={filter.RequestAction == 40} value={40}>
                    Sugeneruotas sąlygų keitimo dokumentas
                  </option>
                  <option selected={filter.RequestAction == 43} value={43}>
                    Atmestas prašymas pakeisti sąlygas
                  </option>
                  <option selected={filter.RequestAction == 42} value={42}>
                    Pasirašytas sąlygų keitimo dokumentas
                  </option>
                  <option selected={filter.RequestAction == 45} value={45}>
                    Inicijuotas sutarties sąlygų keitimas
                  </option>
                  <option selected={filter.RequestAction == 46} value={46}>
                    Grąžinta tikslinti sąlygų keitimą
                  </option>
                  <option selected={filter.RequestAction == 47} value={47}>
                    Patvirtintas sąlygų keitimas
                  </option>
                  <option selected={filter.RequestAction == 44} value={44}>
                    Įvesta papildoma AML/PEP informacija
                  </option>
                  <option selected={filter.RequestAction == 16} value={16}>
                    Pakeista būsena į Pateikta vertinti
                  </option>
                  <option selected={filter.RequestAction == 55} value={55}>
                    Pakeista būsena į Patvirtinta
                  </option>
                  <option selected={filter.RequestAction == 57} value={57}>
                    Pakeista būsena į Sąlygų keitimas
                  </option>
                  <option selected={filter.RequestAction == 56} value={56}>
                    Pakeista būsena į Patvirtintas sąlygų keitimas
                  </option>
                </select>
              </label>

              <label className={styles.filterText}>
                Veiksmą atliko:
                <select
                  name="UserEmail"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option selected={filter.UserEmail == null} value={null}>
                    -
                  </option>
                  {requestsState &&
                    requestsState.invegaUsers &&
                    requestsState.invegaUsers
                      ?.sort((a, b) => a.FirstName?.localeCompare(b.FirstName))
                      ?.map((item) => (
                        <option
                          selected={filter.UserEmail == item.Email}
                          value={item.Email}
                        >{`${item.FirstName} ${item.LastName}`}</option>
                      ))}
                </select>
              </label>
              <label className={styles.filterText}>
                Vertintojas:
                <select
                  name="AssessorId"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option selected={filter.AssessorId == null} value={null}>
                    -
                  </option>
                  {requestsState &&
                    requestsState.invegaUsers &&
                    requestsState.invegaUsers
                      ?.sort((a, b) => a.FirstName?.localeCompare(b.FirstName))
                      ?.map((item) => (
                        <option
                          selected={filter.AssessorId == item.Id}
                          value={item.Id}
                        >{`${item.FirstName} ${item.LastName}`}</option>
                      ))}
                </select>
              </label>
              <br />

              <label
                className={styles.filterText}
                style={{ marginTop: '10px' }}
              >
                Ar rezervinė paraiška?
                <select
                  name="IsReserved"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option selected={filter.IsReserved == null} value={null}>
                    -
                  </option>
                  <option selected={filter.IsReserved == true} value={true}>
                    Taip
                  </option>
                  <option selected={filter.IsReserved == false} value={false}>
                    Ne
                  </option>
                </select>
              </label>
              <br />

              <label
                className={styles.filterText}
                style={{ marginTop: '10px' }}
              >
                Ar pakeistos sutarties sąlygos?
                <select
                  name="ConditionsChanged"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option
                    selected={filter.ConditionsChanged == null}
                    value={null}
                  >
                    -
                  </option>
                  <option
                    selected={filter.ConditionsChanged == true}
                    value={true}
                  >
                    Taip
                  </option>
                  <option
                    selected={filter.ConditionsChanged == false}
                    value={false}
                  >
                    Ne
                  </option>
                </select>
              </label>
              <br />

              <label
                className={styles.filterText}
                style={{ marginTop: '10px' }}
              >
                Ar atmesta pareiškėjo prašymu?
                <select
                  name="IsRejectedByClient"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option
                    selected={filter.IsRejectedByClient == null}
                    value={null}
                  >
                    -
                  </option>
                  <option
                    selected={filter.IsRejectedByClient == true}
                    value={true}
                  >
                    Taip
                  </option>
                  <option
                    selected={filter.IsRejectedByClient == false}
                    value={false}
                  >
                    Ne
                  </option>
                </select>
              </label>
              <br />
              <label
                className={styles.filterText}
                style={{ marginTop: '10px' }}
              >
                Pagal kokią formą parengta paraiška?
                <select
                  name="FormVersion"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option selected={filter.FormVersion == null} value={null}>
                    -
                  </option>
                  <option selected={filter.FormVersion == 3} value={3}>
                    Startuok2 pakeitimai
                  </option>
                  <option selected={filter.FormVersion == 2} value={2}>
                    Nauja forma
                  </option>
                  <option selected={filter.FormVersion == 1} value={1}>
                    Sena forma
                  </option>
                </select>
              </label>
              <br />
              <h3>Mokėjimo prašymai</h3>
              <label className={styles.filterText}>
                Ar paraiška turi naujų mokėjimo prašymų?
                <select
                  name="HasNewPaymentRequests"
                  onChange={filterStatus}
                  className={styles.selectInput}
                >
                  <option
                    selected={filter.HasNewPaymentRequests == null}
                    value={null}
                  >
                    -
                  </option>
                  <option
                    selected={filter.HasNewPaymentRequests == true}
                    value={true}
                  >
                    Taip
                  </option>
                  <option
                    selected={filter.HasNewPaymentRequests == false}
                    value={false}
                  >
                    Ne
                  </option>
                </select>
              </label>
              <br />
              <div className={styles.dateFilterContainer}>
                <label
                  className={styles.filterText}
                  style={{ marginTop: '10px' }}
                >
                  Mokėjimo prašymo (aktualaus) pirmo pateikimo vertinti data
                  nuo:
                </label>
                <DatePicker
                  className={styles.filterInput}
                  selected={
                    filter.PaymentRequestSubmitDate.dateFrom != null
                      ? new Date(filter.PaymentRequestSubmitDate.dateFrom)
                      : ''
                  }
                  onChange={(e) =>
                    handlePaymentRequestSubmittedDateChange(e, 'dateFrom')
                  }
                  dateFormat="yyyy-MM-dd"
                />

                <label
                  className={styles.filterText}
                  style={{ marginLeft: '1rem', marginTop: '10px' }}
                >
                  Mokėjimo prašymo (aktualaus) pirmo pateikimo vertinti data
                  iki:
                </label>
                <DatePicker
                  className={styles.filterInput}
                  selected={
                    filter.PaymentRequestSubmitDate.dateTo != null
                      ? new Date(filter.PaymentRequestSubmitDate.dateTo)
                      : ''
                  }
                  onChange={(e) =>
                    handlePaymentRequestSubmittedDateChange(e, 'dateTo')
                  }
                  dateFormat="yyyy-MM-dd"
                />
                <br />
              </div>
              <div>
                <label className={styles.filterText}>
                  Veiksmas:
                  <select
                    name="PaymentRequestAction"
                    onChange={filterStatus}
                    className={styles.selectInput}
                    style={{ width: '350px' }}
                  >
                    {PaymentRequestActionOptions.map((el) => (
                      <option
                        selected={filter.PaymentRequestAction == el.value}
                        value={el.value}
                      >
                        {el.name}
                      </option>
                    ))}
                  </select>
                </label>

                <label className={styles.filterText}>
                  Veiksmą atliko:
                  <select
                    name="PaymentUserEmail"
                    onChange={filterStatus}
                    className={styles.selectInput}
                  >
                    <option
                      selected={filter.PaymentUserEmail == null}
                      value={null}
                    >
                      -
                    </option>
                    {requestsState &&
                      requestsState.invegaUsers &&
                      requestsState.invegaUsers.map((item) => (
                        <option
                          selected={filter.PaymentUserEmail === item.Email}
                          value={item.Email}
                        >{`${item.FirstName} ${item.LastName}`}</option>
                      ))}
                  </select>
                </label>
              </div>
            </div>

            <br />
            <br />

            {/* <div>
              <br />
              <label className={styles.filterText}>
                <input
                  type="checkbox"
                  onChange={(e) => filterOnlyRelevant(e)}
                  checked={filter.OnlyRelevant}
                  name="OnlyRelevant"
                  value={!filter.OnlyRelevant}
                />{' '}
                Tik tinkamos paraiškos
              </label>
           </div>*/}
            <br />

            <div
              style={{
                display: 'flex',
                width: '21rem',
                justifyContent: 'space-between',
                zIndex: 0,
                textAlign: 'right',
              }}
            >
              <Button type="standart" onClick={() => applyFilter()}>
                Filtruoti
              </Button>
              <Button
                color="var(--gray)"
                type="standart"
                onClick={() => clearFilters(1)}
              >
                Išvalyti filtrus
              </Button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '2rem',
            }}
          >
            <div></div>
            {/*<div>
              <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '0rem' }}>
                  <Button
                    type="standart"
                    onClick={() => AdditionalVmiCheckClick('MarkForVmiRenew')}
                  >
                    Apyvartų kritimo užklausimas
                  </Button>
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  <Button
                    type="standart"
                    onClick={() =>
                      AdditionalVmiCheckClick('MarkForVmiAdditionalCheck')
                    }
                  >
                    Papildomas apyvartų užklausimas
                  </Button>
                </div>

                <div style={{ marginLeft: '1rem' }}>
                  <Button type="standart">
                    <Link
                      to="/backoffice/turnovers-files"
                      style={{
                        color: '#fff',
                        textDecoration: 'none',
                      }}
                    >
                      Apyvartų excel failai
                    </Link>
                  </Button>
                </div>
              </div>
            </div>*/}

            <div className={styles.rightBtnContainer}>
              <div style={{ marginRight: '1rem' }}>
                <Link
                  to="/backoffice/additionalRequests"
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                  }}
                >
                  <Button type="standart">Registrų patikros</Button>
                </Link>
              </div>
              <Link
                onClick={() => importFiles()}
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                }}
              >
                <Button type="standart">Priedų įkėlimas</Button>
              </Link>
              {/*<div style={{ marginLeft: '1rem' }}>
                <Link
                  to="/backoffice/representatives"
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                  }}
                >
                  <Button type="standart">Įgaliojimai</Button>
                </Link>
                </div>*/}
              {/* <Button type="standart" onClick={() => signAll()}>
              Masinis Pasirašymas
            </Button> */}
              <div style={{ marginLeft: '1rem' }}>
                <Link
                  to="/backoffice/stats"
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                  }}
                >
                  <Button type="standart">Statistika</Button>
                </Link>
              </div>
              <div style={{ marginLeft: '1rem' }}>
                <Button type="standart" onClick={() => download()}>
                  Parsisiųsti paraiškas
                </Button>
                <label
                  className={styles.filterText}
                  style={{ marginLeft: '0rem' }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => filterOnlyRelevant(e)}
                    checked={filter.IgnoreFiles}
                    name="IgnoreFiles"
                    value={!filter.IgnoreFiles}
                  />{' '}
                  Ignoruoti priedus
                </label>
              </div>
              <div style={{ marginLeft: '1rem' }}>
                <Button type="standart" onClick={() => downloadList()}>
                  Eksportuoti sąrašą
                </Button>
              </div>
            </div>
          </div>
        </div>
        <TableAdmin
          header={tableHeader}
          isEmpty={false}
          paging={true}
          totalPages={requestsState.data.TotalPageCount}
          onPageClick={onPageClick}
          total={requestsState.data.TotalCount}
          clickedPage={clickedPage}
        >
          {requestsState &&
            requestsState.data &&
            requestsState.data.SearchResult &&
            requestsState.data.SearchResult.map(
              ({
                Id,
                CompanyName,
                CompanyCode,
                CreateDate,
                LoanSocialBusiness,
                LoanPurposeId,
                HelpSchema,
                SubmittedDate,
                Status,
                SignatorysCommentExists,
                ApproversCommentExists,
                AmlCheckResultId,
                PepExists,
                InvegaLoanSum,
                LoanSum,
                PaymentRequestsCount,
                PaymentRequestsApprovedCount,
                Assessor,
                ApprovedDate,
                FirstSubmittedDate,
                ContractSigningTime,
                DeadlineBusinessDaysUntilSignature,
                DaysTillAdjust,
                ConditionsChanged,
                RejectByClient,
                FormVersion,
              }) => (
                <TableRow
                  key={Id}
                  expiring={checkIfTermIsExpiring(
                    new Date(SubmittedDate),
                    Status
                  )}
                >
                  <TableCell style={{ width: '30px' }}>{Id}</TableCell>
                  <TableCell>{CompanyName}</TableCell>
                  <TableCell>{CompanyCode}</TableCell>
                  <TableCell>
                    {CreateDate !== null &&
                      format(new Date(CreateDate), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>{LoanSocialBusiness ? 'Taip' : 'Ne'}</TableCell>
                  <TableCell>{loanPurpose(LoanPurposeId)}</TableCell>
                 {/*} <TableCell>{helpSchem(HelpSchema)}</TableCell>*/}
                  <TableCell>
                    {FirstSubmittedDate !== null &&
                      format(new Date(FirstSubmittedDate), 'yyyy-MM-dd')}
                    <p></p>
                    {SubmittedDate !== null &&
                      format(new Date(SubmittedDate), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>
                    {ApprovedDate !== null &&
                      format(new Date(ApprovedDate), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>
                    {ContractSigningTime !== null &&
                      format(new Date(ContractSigningTime), 'yyyy-MM-dd')}
                  </TableCell>

                  <TableCell>
                    {Status === 7 && DeadlineBusinessDaysUntilSignature}{' '}
                  </TableCell>
                  <TableCell>{Status === 5 && DaysTillAdjust} </TableCell>

                  <TableCell>{formatNumber(LoanSum)}</TableCell>
                  <TableCell>{formatNumber(InvegaLoanSum)}</TableCell>
                  <TableCell>{PaymentRequestsCount}</TableCell>
                  <TableCell>{PaymentRequestsApprovedCount}</TableCell>
                  <TableCell>{amlStatus(AmlCheckResultId)}</TableCell>
                  <TableCell>{PepExists ? 'Yra' : 'Nėra'}</TableCell>

                  <TableCell>
                    {hasComments(
                      ApproversCommentExists,
                      SignatorysCommentExists
                    )}
                  </TableCell>
                  <TableCell>{ConditionsChanged ? 'Taip' : 'Ne'}</TableCell>
                  <TableCell>{(Status === 4) &&  RejectByClient ? 'Taip' : 'Ne'}</TableCell>
                  
                  <TableCell>{requestStatus(Status)}</TableCell>

                  <TableCell>{Assessor}</TableCell>

                  <TableCell>
                    <div style={{ display: 'flex' }}>
                      <div className={styles.iconsContainer}>
                      <Link
                          to={
                            FormVersion === 2 || FormVersion == null || FormVersion === 3
                              ? `/backoffice/request/${Id}`
                              : `/backoffice/request-2/${Id}`
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className={styles.icon}
                            style={{
                              color: '#009fe3',
                            }}
                          />
                        </Link>
                        {(Status === 9 ||
                          Status === 13 ||
                          Status === 14 ||
                          Status === 15 ||
                          Status === 16) && (
                          <Link
                            to={`${config.APP_PATHS.backofficePaymentRequests}?requestId=${Id}`}
                          >
                            <FontAwesomeIcon
                              icon={faEuroSign}
                              className={styles.icon}
                              onClick={() => openPaymentRequest(Id)}
                              style={{
                                color: '#009fe3',
                              }}
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
        </TableAdmin>
      </div>
      <ImportFilesModal open={importFilesModalVisible} />
      <AdditionalVmiCheckConfirmation
        open={additionalVmiCheckConfirmationVisible}
        additionalCheckSelection={additionalCheckSelection}
        filter={filter}
      />
    </WithSpinner>
  );
};

/*
<div className={styles.iconsContainer}>
  <Link to={`/backoffice/paymentrequest/${Id}`}>
    <FontAwesomeIcon
      icon={faEuroSign}
      className={styles.icon}
      style={{
        color: '#009fe3',
      }}
    />
  </Link>
</div>
*/
const mapStateToProps = (state) => ({
  requestsState: state.backofficeRequestsState,
  loading: state.dashboardState.isOnSync,
  importFilesModalVisible: state.modalsState.importFilesModalVisible,
  additionalVmiCheckConfirmationVisible:
    state.modalsState.additionalVmiCheckConfirmationVisible,
});

export default connect(mapStateToProps)(Backoffice);
