import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import styles from './confirmWithoutFinancialCriteriaForChangeConditionsModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { Api } from './../../api/api';
import { config } from './../../api/config';

const ConfirmWithoutFinancialCriteriaForChangeConditionsModal = ({
  open,
  dispatch,
  text,
  loading,
  id,
}) => {
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const onAgree = () => {
    agree === false ? setShowError(false) : setShowError(true);
    setAgree(!agree);
  };

  const cancel = () => {
    setAgree(false);
    setShowError(false);
    const open = {
      modalName: 'confirmWithoutFinancialCriteriaForChangeConditionsModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    dispatch(
      actions.dashboard.sendForApproveWithDocsForChangeConditionsAction(
        id.RequestSystemInfo.RequestId
      )
    );

    cancel();
  };

  // const changeFile = (id) => {
  //   document.getElementById(id).click();
  // };

  // const selectFile = (e) => {
  //   setIsloading(true);

  //   console.log(id);
  //   const file = e.target.files[0];

  //   const formData = new FormData();
  //   formData.append('File', file);
  //   formData.append('RequestId', id);
  //   formData.append('FileId', id);

  //   fetch(Api.API_BASE_URL + config.API_ENDPOINTS.requestSendForApproveWithDocsForChangeConditions, {
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  //     },
  //     body: formData,
  //   })
  //     .then((res) => {
  //       if (res.ok === true) {
  //         // renew info
  //         dispatch(actions.dashboard.getUploadedFileList(id));
  //         swal({
  //           title: 'Papildomi dokumentai sėkmingai pakeistas',
  //           icon: 'success',
  //           buttons: {
  //             confirm: {
  //               text: 'Gerai',
  //               value: true,
  //               visible: true,
  //               className: 'btn btn-pink',
  //               closeModal: true,
  //             },
  //           },
  //         });
  //         cancel();
  //       } else if (res.status === 403) {
  //         swal({
  //           title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
  //           icon: 'error',
  //           buttons: {
  //             confirm: {
  //               text: 'Gerai',
  //               value: true,
  //               visible: true,
  //               className: 'btn swal-ok-btn',
  //               closeModal: true,
  //             },
  //           },
  //         });
  //       } else {
  //         res.json().then((r) => {
  //           let title = 'Nepavyko pateikti priedo';
  //           if (r.errors) {
  //             title = r.errors['Files[0].FileName'][0];
  //           } else if (r.ErrorDescription) {
  //             title = r.ErrorDescription;
  //           }
  //           swal({
  //             title: title,
  //             icon: 'error',
  //             buttons: {
  //               confirm: {
  //                 text: 'Gerai',
  //                 value: true,
  //                 visible: true,
  //                 className: 'btn swal-ok-btn',
  //                 closeModal: true,
  //               },
  //             },
  //           });
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setIsloading(false);
  //     });
  // };

  // const acceptFiles = () => {
  //   return '.xlsx, .xls, .doc, .docx, .xml, .txt, .pdf';
  // };

  return (
    open && (
      <WithSpinner loading={loading}>
        <div className={styles.container}>
          <div
            className={styles.modal}
            id="modal"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal__title"
            aria-describedby="modal__desc"
            style={{ minHeight: '100px' }}
          >
            <div className={styles.contentBlock}>
              <h2>
                Ar tikrai norite pateikti papildomus dokumentus sąlygų
                keitimui? 
              </h2>
              <p>
                Prieš pasirenkatant "PATEIKTI DOKUMENTUS", patikrinkite, ar prie
                paraiškos pridėjote visus prašomus papildomus dokumentus.
                Patikrinti galėsite pasirinkus "ATŠAUKTI". 
                <br />
              </p>
              {
                <>
                  <div className={styles.btnContainer}>
                    <Button type="standart" onClick={() => yes()}>
                      Pateikti dokumentus
                    </Button>
                    <Button type="cancel" onClick={() => cancel()}>
                      Atšaukti
                    </Button>
                  </div>
                  {/* <input
                    type="file"
                    id="fileElem3"
                    accept={acceptFiles()}
                    className="d-none"
                    onChange={(e) => selectFile(e)}
                  /> */}
                </>
              }
            </div>
          </div>
        </div>
      </WithSpinner>
    )
  );
};

const mapStateToProps = (state) => ({
  text: state.modalsState.msgText,
  id: state.dashboardState.requestData,
});

export default connect(mapStateToProps)(
  ConfirmWithoutFinancialCriteriaForChangeConditionsModal
);
