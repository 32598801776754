import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  user: null,
  token: null,
  loggedIn: false,
  loginErr: false,
  role: 'admin',
  tokenPushingStarted: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.auth.SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.auth.SET_USER:
      return {
        ...state,
        user: action.userDetails.user,
        token: action.userDetails.token,
        loggedIn: true,
        role: action.userDetails.role,
      };
    case constants.auth.CLEAR_USER:
      return {
        ...INITIAL_STATE,
      };
    case constants.auth.SET_LOGIN_ERR_MESSAGE:
      return {
        ...state,
        loginErr: true,
      };
    case constants.auth.SET_TOKEN_PUSH_STARTED:
      return {
        ...state,
        tokenPushingStarted: true,
      };
    default:
      return state;
  }
};
