import React from 'react';
import styles from './tableAdmin.module.scss';
import { Link } from 'react-router-dom';
import { Button } from '../../components';

const Table = ({
  children,
  header,
  isEmpty,
  paging,
  totalPages,
  onPageClick,
  clickedPage,
  expiring,
  total = null,
}) => {
  const row = Array.from({ length: header.length });

  const renderHeader = () =>
    header.map((col, i) => {
      if (col.type == 'checkbox') {
        return (
          <div
            className={styles.headerCell}
            style={{ verticalAlign: 'top' }}
            key={i}
            role="columnheader"
          >
            <div className={styles.headerTitle}>
              <div class="checkbox bills-aprove-checkbox-change">
                <label class="containerLabel noBlock">
                  <input type="checkbox" onClick={col.onClick} />
                  <span class="checkmark bills-aprove-checkmark-change"></span>
                </label>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <span className={styles.headerCell} key={i} role="columnheader">
            <div
              className={
                col !== 'Sukūrimo data'
                  ? col !== 'Pateikimo vertinimui data'
                    ? styles.headerTitle
                    : styles.headerTitle
                  : styles.headerTitleDates
              }
            >
              {col}
            </div>
          </span>
        );
      }
    });
  /*
    header.map((col, i) => {
      
      return (<span className={styles.headerCell} key={i} role="columnheader">
      <div className={styles.headerTitle}>
            {col}
        </div>
      </span>)
    }
    );*/

  const renderEmptyRow = () =>
    isEmpty && (
      <div className={styles.emptyRow}>
        {row.map((_, i) => (
          <TableCell key={i}> </TableCell>
        ))}
      </div>
    );
  const renderPaging = () =>
    paging &&
    totalPages > 1 && (
      <div className={styles.pageNumbers}>
        <ul className="pagination">
          <li
            className="previous"
            onClick={() =>
              onPageClick(
                clickedPage && clickedPage != 1 ? +clickedPage - 1 : 1
              )
            }
          >
            <span className="page-link">«</span>
          </li>
          {[...Array(totalPages).keys()].map((i) => (
            <li
              onClick={() => onPageClick(i + 1)}
              className={i + 1 == clickedPage ? 'active' : ''}
            >
              <span className="page-link">{i + 1}</span>
            </li>
          ))}
          <li
            className="next"
            onClick={() =>
              onPageClick(
                clickedPage != totalPages ? +clickedPage + 1 : totalPages
              )
            }
          >
            <span className="page-link">»</span>
          </li>
        </ul>
      </div>
    );

  return (
    <div className={styles.main}>
      <div className={styles.table} role="grid">
        <div className={styles.header} role="presentation">
          <div className={styles.headerRow} role="row">
            {renderHeader()}
          </div>
        </div>
        <div className={styles.body} role="presentation">
          {children}
        </div>
      </div>
      {renderEmptyRow()}
      {total && (
        <span className="text-style-new-request">Viso įrašų: {total}</span>
      )}
      {renderPaging()}
      {isEmpty && <p className={styles.infoText}> tuščia</p>}
    </div>
  );
};

export const TableRow = ({ children, expiring, onClick }) => (
  <div
    className={styles.bodyRow}
    onClick={onClick}
    role="row"
    style={{ background: expiring ? '#FFF0F0' : '' }}
  >
    {children}
  </div>
);

export const TableCell = ({ children }) => (
  <span className={styles.bodyCell} role="gridcell">
    {children}
  </span>
);

export default Table;
