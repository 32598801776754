import React from 'react';
import styles from '../newRequest/newRequest.module.scss';
import { checkBOPage } from './../../utils/helpers/helpers';

export const SurwayAnswers = ({ Ratings }) => {
  return Ratings?.StarterRatingId ||
    Ratings?.InvegaCommunicationRatingId ||
    Ratings?.InvegaFinancialRatingId ? (
    <div
      style={{ marginTop: '2rem', paddingBottom: '30px' }}
      className={checkBOPage() && 'container'}
    >
      <h4>Anketos atsakymai</h4>
      <table className={styles.table}>
        <tr>
          <td>
            1. Kaip vertinate finansinės priemonės „Startuok, finansuojamos iš
            Europos regioninės plėtros fondo" (toliau - finansinė priemonė)
            pridėtinę vertę verslo skatinimui (ar paskatina ir padeda verslui)?
          </td>
          <td style={{ width: '150px' }}>
            <select
              className={styles.selectDisabled}
              disabled
              value={Ratings?.StarterRatingId}
            >
              <option value={'-'}> - </option>
              <option value={1}> Labai gerai </option>
              <option value={2}> Gerai </option>
              <option value={3}> Patenkinamai </option>
              <option value={4}> Blogai </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            2. Kaip vertinate UAB „INVESTICIJŲ IR VERSLO GARANTIJOS"
            komunikaciją ir konsultavimą apie finansinę priemonę?{' '}
          </td>
          <td>
            <select
              className={styles.selectDisabled}
              disabled="true"
              value={Ratings?.InvegaCommunicationRatingId}
            >
              <option value={null}> - </option>
              <option value={1}> Labai gerai </option>
              <option value={2}> Gerai </option>
              <option value={3}> Patenkinamai </option>
              <option value={4}> Blogai </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            3. Kaip vertinate UAB „INVESTICIJŲ IR VERSLO GARANTIJOS" teikiamų
            paslaugų, susijusių su finansine priemone, kokybę (paskolos išdavimo
            procedūros, trukmė ir pan.)?{' '}
          </td>
          <td>
            <select
              className={styles.selectDisabled}
              disabled="true"
              value={Ratings?.InvegaFinancialRatingId}
            >
              <option value={null}> - </option>
              <option value={1}> Labai gerai </option>
              <option value={2}> Gerai </option>
              <option value={3}> Patenkinamai </option>
              <option value={4}> Blogai </option>
            </select>
          </td>
        </tr>
      </table>
    </div>
  ) : null;
};
