import React from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';

const InputWithTitle = ({
  name,
  type,
  onChange,
  value,
  placeholder,
  ...rest
}) => {
  return (
    <div className={styles.container}>
      <p>{placeholder}</p>
      <input
        className={styles.input}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

InputWithTitle.defaultProps = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default InputWithTitle;
