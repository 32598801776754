import React from 'react';
import { connect } from 'react-redux';
import styles from './globalErrorModal.module.scss';
import { WithSpinner } from './../../components';
import { actions } from './../../state/actions';
import { Button } from '../../components';

const GlobalErrorModal = ({ open, errMsg, loading, dispatch }) => {
  const cancel = () => {
    // window.location.reload();
    dispatch(actions.modals.setModalOnSyncFlagAction(false));
    dispatch(actions.dashboard.setUserOnSyncFlagAction(false));

    const open = {
      modalName: 'globalErrorModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
    dispatch(actions.modals.setErrorMessageAction(''));
  };
  console.log(errMsg);
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '460px' }}
        >
          <WithSpinner loading={false}>
            <div className={styles.contentBlock}>
              {Array.isArray(errMsg) ? (
                <div style={{ textAlign: 'left', marginLeft: '30px', padding:'30px', paddingLeft: '0px' }}>
                  <p style={{ fontSize: '20px' }}>
                    Privalomi pateikti priedai:
                  </p>
                  {errMsg.map((val, i) => (
                    <>
                      <span style={{ color: '#597a96' }}>&#8226; {val}</span>
                      <br />
                      <br />
                    </>
                  ))}
                </div>
              ) : (
                <p>{errMsg}</p>
              )}

              <div className={styles.btnContainer}>
                <button
                  className="btn-dark-blue-standart"
                  type="standart"
                  onClick={() => cancel()}
                  style={{ marginBottom: '20px' }}
                >
                  Gerai
                </button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.modalsState.isOnSync,
  errMsg: state.modalsState.errMsg,
});

export default connect(mapStateToProps)(GlobalErrorModal);
