import { constants } from '../constants';

const setUserOnSyncFlagAction = (flag) => {
  return {
    type: constants.dashboard.SET_ON_SYNC_FLAG,
    flag,
  };
};

const clearUserStateAction = () => {
  return {
    type: constants.dashboard.CLEAR_USER_STATE,
  };
};

const updateDashboardAction = () => {
  return {
    type: constants.dashboard.UPDATE_DASHBOARD_DATA,
  };
};

const setDashboardDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_DASHBOARD_DATA,
    payload,
  };
};

const loadInvoiceListDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_INVOICE_LIST_DATA,
    payload,
  };
};

const loadRequestLimitDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_REQUEST_LIMIT_DATA,
    payload,
  };
};

const loadRequestGeneralLimitDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_REQUEST_GENERAL_LIMIT_DATA,
    payload,
  };
};

const loadInvoiceApproveListDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_INVOICE_APPROVE_LIST_DATA,
    payload,
  };
};

const setInvoiceListDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_INVOICE_LIST_DATA,
    payload,
  };
};

const deleteInvoiceAction = (requestId, invoiceId) => {
  return {
    type: constants.dashboard.DELETE_INVOICE,
    requestId,
    invoiceId,
  };
};

const passCompanyInfoAction = (payload) => {
  return {
    type: constants.dashboard.PASS_COMPANY_INFO_ACTION,
    payload,
  };
};

const setFirstStepPassedAction = (payload) => {
  return {
    type: constants.dashboard.SET_FIRST_STEP_PASSED,
    payload,
  };
};

const getRequestListAction = () => {
  return {
    type: constants.dashboard.GET_REQUEST_LIST,
  };
};

const saveInvoiceDataAction = (requestId, data) => {
  return {
    type: constants.dashboard.SAVE_INVOICE_DATA,
    requestId,
    data,
  };
};

const setInvoiceSavedAction = (payload) => {
  return {
    type: constants.dashboard.INVOICE_SAVED,
    payload,
  };
};

const setInvoiceErrorAction = (payload) => {
  return {
    type: constants.dashboard.INVOICE_SAVED_EROR,
    payload,
  };
};

const setRequestListAction = (payload) => {
  return {
    type: constants.dashboard.SET_REQUEST_LIST,
    payload,
  };
};

const createNewRequestAction = (id, actionType, requestId) => {
  return {
    type: constants.dashboard.CREATE_NEW_REQUEST,
    id,
    actionType,
    requestId,
  };
};

const createNewRequestIndividualAction = (id, actionType, requestId) => {
  return {
    type: constants.dashboard.CREATE_NEW_REQUEST_INDIVIDUAL,
    id,
    actionType,
    requestId,
  };
};

const saveRquestAction = (payload, formVersion) => {
  return {
    type: constants.dashboard.SAVE_REQUEST,
    payload,
    formVersion,
  };
};

const deleteRequestAction = (id) => {
  return {
    type: constants.dashboard.DELETE_REQUEST,
    id,
  };
};

const loadInvoiceDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_INVOICE_DATA,
    payload,
  };
};

const loadOwnerInvoiceDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_OWNER_INVOICE_DATA,
    payload,
  };
};

const setInvoiceDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_INVOICE_DATA,
    payload,
  };
};

const setOwnerInvoiceDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_OWNER_INVOICE_DATA,
    payload,
  };
};

const setNewRequestData = (payload) => {
  return {
    type: constants.dashboard.SET_NEW_REQUEST_DATA,
    payload,
  };
};

const saveCompanyDataAction = (requestId, payload) => {
  return {
    type: constants.dashboard.SAVE_COMPANY_DATA,
    requestId,
    payload,
  };
};

const setCompanySavedAction = (payload) => {
  return {
    type: constants.dashboard.SET_COMPANY_DATA,
    payload,
  };
};

const getIsConsentAgreedAction = () => {
  return {
    type: constants.dashboard.GET_IS_CONSENTAGREED,
  };
};

const setIsConsentAgreedAction = (payload) => {
  return {
    type: constants.dashboard.SET_IS_CONSENTAGREED,
    payload,
  };
};

const setConsentDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_CONSENT_DATA,
    payload,
  };
};

const setRequestLimitDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_REQUEST_LIMIT_DATA,
    payload,
  };
};

const setRequestGeneralLimitDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_REQUEST_GENERAL_LIMIT_DATA,
    payload,
  };
};

const GetCanCreateRequest = () => {
  return {
    type: constants.dashboard.GET_CAN_CREATE_REQUEST,
  };
};

const SetCanCreateRequest = (payload) => {
  return {
    type: constants.dashboard.SET_CAN_CREATE_REQUEST,
    payload,
  };
};

const loadGeneralDataAction = (payload) => {
  return {
    type: constants.dashboard.LOAD_GENERAL_DATA,
    payload,
  };
};

const setGeneralDataAction = (payload) => {
  return {
    type: constants.dashboard.SET_GENERAL_DATA,
    payload,
  };
};

const approveRequestError = (payload) => {
  return {
    type: constants.dashboard.APPROVE_REQUEST_ERROR,
    payload,
  };
};

const approveRequestSuccess = (payload) => {
  return {
    type: constants.dashboard.APPROVE_REQUEST_SUCCESS,
    payload,
  };
};
const approveRequestResset = (payload) => {
  return {
    type: constants.dashboard.APPROVE_REQUEST_RESSET,
    payload,
  };
};

const setSavedRequestDesissionAccepted = (payload) => {
  return {
    type: constants.dashboard.SET_SAVED_REQUEST_DESISSION_ACCEPTED,
    payload,
  };
};

const fileUploadAction = (payload, actionType) => {
  return {
    type: constants.dashboard.FILE_UPLOAD,
    payload,
    actionType,
  };
};

const getUploadedFileList = (id) => {
  return {
    type: constants.dashboard.GET_UPLOADED_FILES_LIST,
    id,
  };
};

const setUploadedFileList = (payload) => {
  return {
    type: constants.dashboard.SET_UPLOADED_FILES_LIST,
    payload,
  };
};

const getSigningUrlAction = (payload, requestId, user) => {
  return {
    type: constants.dashboard.GET_SIGNING_URL,
    payload,
    requestId,
    user,
  };
};

const setSigningUrlAction = (payload) => {
  return {
    type: constants.dashboard.SET_SIGNING_URL,
    payload,
  };
};

const setSigningInfoAction = (payload) => {
  return {
    type: constants.dashboard.SET_SIGNING_IN_INFO,
    payload,
  };
};

const getSigningDecisionUrlAction = (requestId) => {
  return {
    type: constants.dashboard.GET_SIGNING_DECISION_URL,
    requestId,
  };
};

const GetSigningUrlBatchAction = () => {
  return {
    type: constants.dashboard.GET_SIGNING_URL_BATCH,
  };
};

const getFinancialCriteriaResultsAction = (id, user, doubleCheck, formVersion) => {
  return {
    type: constants.dashboard.GET_FINANCIALCRITERIA_RESULTS,
    id,
    user,
    doubleCheck,
    formVersion,
  };
};

const setFinancialCriteriaResultsAction = (payload, formVersion) => {
  return {
    type: constants.dashboard.SET_FINANCIALCRITERIA_RESULTS,
    payload,
    formVersion,
  };
};

const sendForApproveAction = (id) => {
  return {
    type: constants.dashboard.SEND_FOR_APPROVE,
    id,
  };
};

const sendForApproveWithDocsAction = (id) => {
  return {
    type: constants.dashboard.SEND_FOR_APPROVE_WITH_DOCS,
    id,
  };
};

const sendForPaymentRequestApproveAction = (id) => {
  return {
    type: constants.dashboard.SEND_PAYMENT_REQUEST_FOR_APPROVE,
    id,
  };
};

//login type set
const setLoginType = (payload) => {
  return {
    type: constants.dashboard.SET_LOGIN_TYPE,
    payload,
  };
};

const setRequestViewWindowAction = () => {
  return {
    type: constants.dashboard.SET_REQUEST_VIEW_WINDOW,
  };
};

const saveRepresentativeAction = (data) => {
  return {
    type: constants.dashboard.SAVE_REPRESENTATIVE,
    data,
  };
};

const setRepresentativeAction = (payload) => {
  return {
    type: constants.dashboard.SET_REPRESENTATIVE,
    payload,
  };
};

const setSaveRepresentativeError = (payload) => {
  return {
    type: constants.dashboard.SAVE_REPRESENTATIVE_ERROR,
    payload,
  };
};

const loadRepresentatives = () => {
  return {
    type: constants.dashboard.LOAD_REPRESENTATIVES,
  };
};

const setRepresentatives = (payload) => {
  return {
    type: constants.dashboard.SET_REPRESENTATIVES,
    payload,
  };
};

const loadCompanyRepresentatives = () => {
  return {
    type: constants.dashboard.LOAD_COMPANY_REPRESENTATIVES,
  };
};

const setCompanyRepresentatives = (payload) => {
  return {
    type: constants.dashboard.SET_COMPANY_REPRESENTATIVES,
    payload,
  };
};

const getPaymentRequestsListAction = (id) => {
  return {
    type: constants.dashboard.GET_PAYMENT_REQUESTS_LIST,
    id,
  };
};

const setPaymentRequestsListAction = (payload) => {
  return {
    type: constants.dashboard.SET_PAYMENT_REQUESTS_LIST,
    payload,
  };
};

const createNewPaymentRequestAction = (
  id,
  actionType,
  paymentId,
  requestId,
  surveyData
) => {
  console.log(requestId);
  console.log(surveyData);
  return {
    type: constants.dashboard.CREATE_NEW_PAYMENT_REQUEST,
    id,
    actionType,
    paymentId,
    requestId,
    surveyData,
  };
};

const setPaymentRequestAction = (payload) => {
  return {
    type: constants.dashboard.SET_PAYMENT_REQUEST,
    payload,
  };
};

const createPaymentRequestNewPrDocumentAction = (id, payload) => {
  return {
    type: constants.dashboard.CREATE_PR_NEW_PR_DOCUMENT_REQUEST,
    id,
    payload,
  };
};

const setPaymentRequestPrDocument = (payload) => {
  return {
    type: constants.dashboard.SET_PAYMENT_REQUEST_PR_DOCUMENT,
    payload,
  };
};

const createNewPaymentRequestPrDocumentAction = (
  id,
  docId,
  actionType,
  number
) => {
  return {
    type: constants.dashboard.CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT,
    id,
    docId,
    actionType,
    number,
  };
};

const createNewPaymentRequestPrDocumentPayBackAction = (
  id,
  docId,
  actionType,
  number,
  parentPrDocumentId
) => {
  return {
    type: constants.dashboard.CREATE_NEW_PAYMENT_REQUEST_PR_DOCUMENT_PAY_BACK,
    id,
    docId,
    actionType,
    number,
    parentPrDocumentId,
  };
};

const deletePrDocumentRequestAction = (docId, requestId, paymentId) => {
  return {
    type: constants.dashboard.PR_DOCUMENT_DELETE_REQUEST,
    docId,
    requestId,
    paymentId,
  };
};

const sendForApproveWithDocsForChangeConditionsAction = (id) => {
  console.log(id);
  return {
    type: constants.dashboard.SEND_FOR_APPROVE_WITH_DOCS_FOR_CHANGE_CONDITIONS,
    id,
  };
};

const setIsRepresentaviveAction = (payload) => {
  console.log(payload);
  return {
    type: constants.dashboard.IS_REPRESENTATIVE,
    payload,
  };
};

export const userActions = {
  clearUserStateAction,
  updateDashboardAction,
  setUserOnSyncFlagAction,
  setDashboardDataAction,
  loadInvoiceListDataAction,
  setInvoiceListDataAction,
  passCompanyInfoAction,
  setFirstStepPassedAction,
  deleteInvoiceAction,
  loadInvoiceApproveListDataAction,
  getRequestListAction,
  createNewRequestAction,
  setNewRequestData,
  setRequestListAction,
  loadInvoiceDataAction,
  setInvoiceDataAction,
  saveInvoiceDataAction,
  setInvoiceSavedAction,
  setInvoiceErrorAction,
  saveRquestAction,
  deleteRequestAction,
  saveCompanyDataAction,
  setCompanySavedAction,
  getIsConsentAgreedAction,
  setIsConsentAgreedAction,
  setConsentDataAction,
  loadRequestLimitDataAction,
  setRequestLimitDataAction,
  loadGeneralDataAction,
  setGeneralDataAction,
  approveRequestError,
  approveRequestSuccess,
  approveRequestResset,
  loadRequestGeneralLimitDataAction,
  setRequestGeneralLimitDataAction,
  setSavedRequestDesissionAccepted,
  fileUploadAction,
  getUploadedFileList,
  setUploadedFileList,
  getSigningUrlAction,
  setSigningUrlAction,
  setSigningInfoAction,
  getFinancialCriteriaResultsAction,
  setFinancialCriteriaResultsAction,
  setLoginType,
  createNewRequestIndividualAction,
  setRequestViewWindowAction,
  loadOwnerInvoiceDataAction,
  setOwnerInvoiceDataAction,
  getSigningDecisionUrlAction,
  GetSigningUrlBatchAction,
  saveRepresentativeAction,
  loadRepresentatives,
  setRepresentatives,
  setSaveRepresentativeError,
  setRepresentativeAction,
  loadCompanyRepresentatives,
  setCompanyRepresentatives,
  sendForApproveAction,
  sendForApproveWithDocsAction,
  GetCanCreateRequest,
  SetCanCreateRequest,
  getPaymentRequestsListAction,
  setPaymentRequestsListAction,
  createNewPaymentRequestAction,
  setPaymentRequestAction,
  createPaymentRequestNewPrDocumentAction,
  setPaymentRequestPrDocument,
  createNewPaymentRequestPrDocumentAction,
  deletePrDocumentRequestAction,
  sendForPaymentRequestApproveAction,
  sendForApproveWithDocsForChangeConditionsAction,
  createNewPaymentRequestPrDocumentPayBackAction,
  setIsRepresentaviveAction,
};
