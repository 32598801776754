import { userActions } from './dashboard/DashboardActions';
import { modalActions } from './modals/ModalsActions';
import { authActions } from './auth/auth.actions';
import { backofficeActions } from './backoffice/BackofficeActions';

export const actions = {
  dashboard: userActions,
  modals: modalActions,
  auth: authActions,
  backoffice: backofficeActions,
};
