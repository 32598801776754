import React, { useState, useEffect } from 'react';
import styles from './confirmWithoutFinancialCriteriaModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';

const ConfirmWithoutFinancialCriteriaModal = ({
  open,
  dispatch,
  text,
  loading,
  id,
}) => {
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);

  const onAgree = () => {
    agree === false ? setShowError(false) : setShowError(true);
    setAgree(!agree);
  };

  const cancel = () => {
    setAgree(false);
    setShowError(false);
    const open = {
      modalName: 'confirmWithoutFinancialCriteriaModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    dispatch(
      actions.dashboard.sendForApproveWithDocsAction(
        id.RequestSystemInfo.RequestId
      )
    );

    cancel();
  };

  return (
    open && (
      <WithSpinner loading={loading}>
        <div className={styles.container}>
          <div
            className={styles.modal}
            id="modal"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal__title"
            aria-describedby="modal__desc"
            style={{ minHeight: '100px' }}
          >
            <div className={styles.contentBlock}>
              <h2>
                Ar tikrai norite pateikti papildomus dokumentus vertinimui? 
              </h2>
              <p>
                Prieš pasirenkatant "PATEIKTI DOKUMENTUS", patikrinkite, ar prie
                paraiškos pridėjote visus prašomus papildomus dokumentus.
                Patikrinti galėsite pasirinkus "ATŠAUKTI". 
                <br />
              </p>
              {
                <div className={styles.btnContainer}>
                  <Button type="standart" onClick={() => yes()}>
                    Pateikti dokumentus
                  </Button>
                  <Button type="cancel" onClick={() => cancel()}>
                    Atšaukti
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
      </WithSpinner>
    )
  );
};

const mapStateToProps = (state) => ({
  text: state.modalsState.msgText,
  id: state.dashboardState.requestData,
});

export default connect(mapStateToProps)(ConfirmWithoutFinancialCriteriaModal);
