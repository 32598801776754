export const isProd = () => {
  const parts = window.location.hostname.split('.');
  const sndleveldomain = parts.slice(-2).join('.');
  console.log({ sndleveldomain });
  console.log(config.DOMAIN_URL_PROD);
  console.log('------');
  if (sndleveldomain === config.DOMAIN_URL_PROD) {
    return true;
  }
  return false;
};

export const isLocalhost = () => {
  const parts = window.location.hostname.split('.');
  const sndleveldomain = parts.slice(-2).join('.');
  if (sndleveldomain === config.DOMAIN_URL_LOCALHOST) {
    return true;
  }
  return false;
};

export const config = {
  API_BASE_URL_DEV: 'https://startuok_test.scorify.lt',
  //API_BASE_URL_DEV: 'https://localhost:44378',
  API_BASE_URL_PROD: `${window.location.protocol}//${window.location.host}`,
  API_BASE_VERSION: 'api/Auth',

  DOMAIN_URL_DEV: 'scorify.lt',
  DOMAIN_URL_PROD: `invega.lt`,
  DOMAIN_URL_LOCALHOST: `localhost`,

  API_ENDPOINTS: {
    login: '/api/Auth/Login',
    backOfficeLogin: '/api/Auth/Authenticate/backoffice',
    logout: '/api/Auth/logout',
    vaiisisTest: '/api/Auth/vaiisistest',
    customLogin: '/Auth/CustomLogin',

    startRegistersProcessing: '/api/Company/StartRegistersProcessing',
    startRegistersProcessingIndividual:
      '/api/Individual/StartRegistersProcessing',
    getRegistersProcessingStatus: (id) =>
      `/api/Company/GetRegistersProcessingStatus/${id}`,
    getRegistersProcessingStatusIndividual: (id) =>
      `/api/Individual/GetRegistersProcessingStatus/${id}`,
    getFinancialCriteriaResults: (id) =>
      `/api/request/GetFinancialCriteriaResults/${id}`,

    getFinancialCriteriaResultsFinal: (id) =>
      `/api/request/GetFinancialCriteriaResultsFinal/${id}`,

    getFinancialCriteriaResultsIndividual: (id) =>
      `/api/Individual/GetFinancialCriteriaResults/${id}`,

    getFinancialCriteriaResultsForSaveRequest: (id) =>
      `/api/Request/GetFinancialCriteriaResults/${id}`,

    doubleCheckRequestEvaluation: (id) =>
      `/api/Request/DoubleCheckRequestEvaluation?requestId=${id}`,
    copy: (id) => `/api/Request/Copy/${id}`,

    invoiceSellerList: (id) => `/api/Invoice/TenantList?requestId=${id}`,
    invoiceDelete: (id) => `/api/Invoice/Delete?invoiceId=${id}`,
    invoiceBuyerList: '/api/Invoice/OwnerList',
    confirmInvoice: '/api/Invoice/Confirm',
    rejectInvoice: '/api/Invoice/Reject',
    invoiceData: (requestId, id) =>
      `/api/Invoice/GetByTenant?requestId=${requestId}&invoiceId=${id}`,
    invoiceOwnerData: (requestId, id) =>
      `/api/Invoice/GetByOwner?requestId=${requestId}&invoiceId=${id}`,
    saveInvoiceData: (id) => `/api/Invoice/Save?requestId=${id}`,

    isConsentAgreed: '/api/Users/IsConsentAgreed',
    createConsent: '/api/Users/CreateConsent',
    getConsent: '/api/Users/GetConsent',
    logoff: '/api/Users/Logoff',

    requestList: '/api/Request/List',
    requestStart: '/api/Request/Start',
    requestGet: (id) => `/api/Request/Get?requestId=${id}`,
    requestSave: '/api/Request/Save',
    requestdelete: (id) => `/api/Request/Delete?requestId=${id}`,
    requestSendForApprove: (id) =>
      `/api/Request/SendForApprove?requestId=${id}`,

    paymentRequestList: (id) => `/api/PaymentRequest/List?requestId=${id}`,
    paymentRequest: (id, paymentId) =>
      `/api/PaymentRequest/Get?requestId=${id}&paymentRequestId=${paymentId}`,
    paymentRequestStart: (id) => `/api/PaymentRequest/Start?requestId=${id}`,
    paymentRequestSavePrDocument: (id) =>
      `/api/PaymentRequest/SavePrDocument?paymentRequestId=${id}`,
    paymentRequestSavePrDocumentManager: (id) =>
      `/api/PaymentRequest/SavePrDocumentManager?paymentRequestId=${id}`,
    paymentRequestPrDocument: (id, docId) =>
      `/api/PaymentRequest/GetPrDocument?paymentRequestId=${id}&prDocumentId=${docId}`,
    paymentRequestDeletePrDocument: (id) =>
      `/api/PaymentRequest/DeletePrDocument?prDocumentId=${id}`,
    UploadFilesPrd: '/api/PaymentRequest/uploadFilesPrd',
    DeleteUploadedFilePrd: (invoiceId, fileId) =>
      `/api/PaymentRequest/DeleteUploadedFilePrd/${invoiceId}/${fileId}`,
    DownloadFilePrd: (invoiceId, fileId) =>
      `/api/PaymentRequest/DownloadFilePrd/${invoiceId}/${fileId}`,
    DownloadFilePrdAll: (id) => `/api/PaymentRequest/DownloadFilePrdAll/${id}`,
    UploadFilesPr: '/api/PaymentRequest/uploadFilesPr',
    DownloadFilePr: (invoiceId, fileId) =>
      `/api/PaymentRequest/DownloadFilePr/${invoiceId}/${fileId}`,
    DeleteUploadedFilePr: (invoiceId, fileId) =>
      `/api/PaymentRequest/DeleteUploadedFilePr/${invoiceId}/${fileId}`,
    GetActionLogManager: (paymentRequestId) =>
      `/api/PaymentRequest/GetActionLogManager?paymentRequestId=${paymentRequestId}`,
    MarkUnpaidCheck: (paymentRequestId) =>
      `/api/PaymentRequest/MarkUnpaidCheck?paymentRequestId=${paymentRequestId}`,

    GetAdministrationInvoices: (requestId) =>
      `/api/Request/GetAdministrationInvoices/${requestId}`,
    DownloadAdministrationInvoice: (contractId) =>
      `/api/Request/DownloadAdministrationInvoice/${contractId}`,
    DownloadChangeConditionsInvoice: (contractId) =>
      `/api/Request/DownloadChangeConditionsInvoice/${contractId}`,

    requestSendForApproveWithDocs: (id) =>
      `/api/Request/SubmitAdditionalDocs?requestId=${id}`,

    paymentRequestSendForApprove: (paymentId) =>
      `/api/PaymentRequest/Submit?paymentRequestId=${paymentId}`,

    setApprovePaymentRequest: (paymentId) =>
      `/api/PaymentRequest/Approve?paymentRequestId=${paymentId}`,
    setRejectPaymentRequest: (paymentId) =>
      `/api/PaymentRequest/Reject?paymentRequestId=${paymentId}`,
    setReturnPaymentRequest: (paymentId) =>
      `/api/PaymentRequest/Return?paymentRequestId=${paymentId}`, //todo
    returnPaymentWithComment: (paymentId) =>
      `/api/PaymentRequest/ReturnWithComment?paymentRequestId=${paymentId}`,
    checkForApprove: `/api/Request/CheckForApprove`,
    ExportPr: (id, paymentId) =>
      `/api/PaymentRequest/ExportPr?requestId=${id}&paymentRequestId=${paymentId}`,
    requestApprove: (id) => `/api/Request/Approve?requestId=${id}`,
    requestReject: (id) => `/api/Request/Reject?requestId=${id}`,
    //requestReject: `/api/Request/Reject`,
    requestReturn: '/api/Request/Return',
    SetCompanySizeType: '/api/Request/ManagerSaveCompanySizeType',
    SetNotDeletable: (requestId, notDeletable) =>
    `/api/Request/SetNotDeletable?requestId=${requestId}&notDeletable=${notDeletable}`,
    requestInitialLimit: (id) => `/api/Request/GetInitialRequestLimit/${id}`,
    canCreateRequest: '/api/Request/CanCreateRequest',
    requestLimit: (id) => `/api/Request/GetRequestLimit/${id}`,

    DownloadFile: (requestId, fileId) =>
      `/api/request/DownloadFile/${requestId}/${fileId}`,
    DownloadAllFiles: (id) => `/api/Request/DownloadAllFiles/${id}`,
    DeleteUploadedFile: (id) => `/api/request/DeleteUploadedFile/${id}`,
    GetUploadedFiles: (id) => `/api/request/GetUploadedFiles/${id}`,
    UploadFiles: '/api/request/UploadFiles',
    UploadContract: (comment) =>
      `/api/request/UploadContract?comment=${comment}`,
    UploadChangeConditions: '/api/Request/UploadChangeConditions',
    RejectChangeConditions: `/api/Request/RejectChangeConditions`,

    Terminate: '/api/Request/Terminate',

    GetFinancialEvaluationStatus: (id) =>
      `/api/Request/GetFinancialEvaluationStatus/${id}`,
    companyCreate: '/api/company/save',
    getGeneralData: '/api/Request/GetGeneralInfo',
    saveUserComment: '/api/Request/SaveUserComment',

    getManagerRequests: '/api/Request/GetManagerRequests',
    getManagerRequest: (id) => `/api/Request/GetForManager?requestId=${id}`,
    setApproveRequest: (id) => `/api/Request/Approve?requestId=${id}`,
    setApproveWithDocsRequest: (id) =>
      `/api/Request/WaitForAdditionalDocsNew?requestId=${id}`,
    setRejectRequest: (id) => `/api/Request/Reject?requestId=${id}`,
    setReturnRequest: (id) => `/api/Request/Return?requestId=${id}`,
    setSignedRequest: (id) => `/api/Request/Signed?requestId=${id}`,
    setSendForApproveRequest: (id) =>
      `/api/Request/InvegaSendForApprove?requestId=${id}`,
      setApproveRequest: (id) =>
      `/api/Request/InvegaSetApprove?requestId=${id}`,
      setChangeConditionsRequest: (id) =>
      `/api/Request/InvegaSetChangeConditions?requestId=${id}`,
      setChangeConditionsApprovedRequest: (id) =>
      `/api/Request/InvegaSetChangeConditionsApproved?requestId=${id}`,
    getManagerInvoices: (id) => `/api/Invoice/ManagerList?requestId=${id}`,
    getManagerInvoice: (id) => `/api/Invoice/ManagerGet?invoiceId=${id}`,
    getManagerUploadedFiles: (id) =>
      `/api/request/GetUploadedFilesManager/${id}`,
    DownloadFileManager: (requestId, fileId) =>
      `/api/request/DownloadFileManager/${requestId}/${fileId}`,
    getStats: '/api/backoffice/GetStats',

    ExportData: '/api/BackOffice/ExportData',
    DownloadList: '/api/BackOffice/ExportManagerRequests',

    getSigningUrl: (id) => `/api/Request/GetSigningUrl/${id}`,
    getSigningUrlManager: (id) => `/api/Request/GetSigningUrlManager/${id}`,
    getSigningUrlDecision: (id) => `/api/Request/GetSigningUrlDecision/${id}`,
    regenerateDecisionDoc: (id) =>
      `/api/request/RegenerateDecisionDoc?requestId=${id}`,

    getSigningUrlBatch: '/api/Request/GetSigningUrlBatch',

    SaveLoanSums: (id) => `/api/Request/SaveLoanSums?requestId=${id}`,

    GenerateContractDoc: (id) => `/api/Request/GenerateContractDoc/${id}`,
    DownloadContractDoc: (id) => `/api/Request/DownloadContractDoc/${id}`,

    GetSignedDocumentManager: (id) => `/api/Request/GetSignedDocument/${id}`,
    GetSignedDocumentUser: (id) => `/api/Request/GetSignedDocument/${id}`,

    GetSignedDocumentContract: (id) =>
      `/api/Request/GetSignedDocumentContract/${id}`,
    GetSignedDocumentChangeConditions: (id) =>
      `/api/Request/GetSignedDocumentChangeConditions/${id}`,
    GetChangeConditionsFile: (requestId, fileId) =>
      `/api/Request/GetChangeConditionsFile/${requestId}/${fileId}`,
      GetSignedDocumentDecision: (requestId, fileId) =>
      `/api/Request/GetSignedDocumentDecision/${requestId}/${fileId}`,

    GetSigningInfo: (id) => `/api/Request/GetSigningInfo/${id}`,
    CanConfirmInvoicesFromManager: (id) =>
      `/api/request/CanConfirmInvoicesFromManager?requestId=${id}`,
    ConfirmInvoicesFromManager: (id) =>
      `/api/request/ConfirmInvoicesFromManager?requestId=${id}`,

    calculateFinancialCriteria: '/api/Request/CalculateFinancialCriteria',

    saveAdditionalComments: '/api/request/SaveAdditionalComments',
    getAdditionalComments: (id) => `/api/request/GetAdditionalComments/${id}`,

    saveRiskAssessmentResult: '/api/request/ManagerSaveRiskAssessmentResult',

    saveRepresentative: '/api/Representatives/SaveCompanyRepresentative',
    saveRepresentativeManager:
      '/api/Representatives/SaveCompanyRepresentativeManager',
    loadRepresentativesManager: '/api/Representatives/GetEditListManager',
    loadRepresentatives: '/api/Representatives/GetEditList',
    deleteRepresentative: (id) =>
      `/api/Representatives/DeleteCompanyRepresentative/${id}`,
    deleteRepresentativeManager: (id) =>
      `/api/Representatives/DeleteCompanyRepresentativeManager/${id}`,
    downloadRepresentativeFile: (id, fileId) =>
      `/api/Representatives/DownloadFile/${id}/${fileId}`,
    downloadRepresentativeFileManager: (id, fileId) =>
      `/api/Representatives/DownloadFileManager/${id}/${fileId}`,
    getCompanyRepresentations: '/api/Representatives/GetCompanyRepresentations',
    isRepresentative: '/api/Users/IsRepresentative',
    impersonate: '/api/Representatives/Impersonate',
    getRequestLog: (id) => `/api/Request/GetActionLogManager?requestId=${id}`,
    getInvegaUsers: '/api/BackOffice/GetInvegaUsers',
    getFeatureSettings: '/api/feature/get',
    SetInvegaLoanSum: '/api/Request/SetInvegaLoanSum',
    SetAdditionalInfo: '/api/Request/ManagerSaveAdditionalInfo',
    increaseInvegaLoanSum: '/api/Request/IcreaseInvegaLoanSum',

    importFiles: '/api/BackOffice/ImportDataList',

    CanCreateOrSubmit: (id) => `/api/Request/CanCreateOrSubmit?requestId=${id}`,
    GetIndividualEvrks: (id) =>
      `/api/Request/GetIndividualEvrks?requestId=${id}`,
    GetCompanyEvrks: (id) => `/api/Request/GetCompanyEvrks?requestId=${id}`,
    GetAssessorsList: '/api/Request/GetAssessorsList',
    SetAssessor: (requestId, userId) =>
      `/api/Request/SetAssessor?requestId=${requestId}&userId=${userId}`,

    ExcelList: `/api/Excel/list`,
    ExcelDownloadFile: (id) => `/api/Excel/DownloadFile?id=${id}`,

    MarkForVmiRenewMass: '/api/Request/MarkForVmiRenewMass',
    MarkForVmiAdditionalCheckMass: '/api/Request/MarkForVmiAdditionalCheckMass',

    GetRelatedRequests: (id) =>
      `/api/Request/GetRelatedRequests?requestId=${id}`,

    getAddressStructDistricts: '/api/Request/GetAddressStructDistricts',
    getAddressStructMunicipalities: (district) =>
      `/api/Request/GetAddressStructMunicipalities?districtId=${district}`,
    getAddressStructElderships: (municipality) =>
      `/api/Request/GetAddressStructElderships?municipalityId=${municipality}`,
    getAddressStructSettlements: (municipality, eldership) =>
      `/api/Request/GetAddressStructSettlements?municipalityId=${municipality}&eldershipId=${eldership}`,
    RequestChangeConditions: (id) => `/api/Request/RequestChangeConditions`,
    GetEditListManagerByRequest: (id) =>
      `/api/Representatives/GetEditListManagerByRequest?requestId=${id}`,
    ChangeChangeConditionsFile: '/api/Request/ChangeChangeConditionsFile',
    ApproveChangeConditions: `/api/Request/ApproveChangeConditions`,
    SavePrDocumentManager: `/api/PaymentRequest/SavePrDocumentManager`,
    SavePrDocumentPayBack: (id) =>
      `/api/PaymentRequest/SavePrDocumentPayBack?paymentRequestId=${id}`,
    paymentRequestPrDocumentPayBack: (
      paymentRequestId,
      parentPrDocumentId,
      prDocumentId
    ) =>
      `/api/PaymentRequest/GetPrDocumentPayBack?paymentRequestId=${paymentRequestId}&parentPrDocumentId=${parentPrDocumentId}&prDocumentId=${
        prDocumentId || ''
      }`,
    RequestChangeConditionsManager: `/api/Request/RequestChangeConditionsManager`,
    DeletePrDocumentPayBack: (id) =>
      `/api/PaymentRequest/DeletePrDocumentPayBack?prDocumentId=${id}`,
    ReturnChangeConditions: (id, comment) =>
      `/api/Request/ReturnChangeConditions?requestId=${id}&comment=${comment}`,
    requestSendForApproveWithDocsForChangeConditions: (id) =>
      `/api/Request/SubmitAdditionalDocsForChangeConditions?requestId=${id}`,
    GetSignedDocumentRejectDecision: (requestId) =>
      `/api/Request/GetSignedDocumentRejectDecision/${requestId}`,
    ManagerSaveRiskLevel: (id, level) =>
      `/api/Request/ManagerSaveRiskLevel?requestId=${id}&riskLevel=${level}`,
    ManagerSaveRiskAssessmentResult: `/api/Request/ManagerSaveRiskAssessmentResult`,
    RecheckJadis: (requestId) =>
      `/api/Request/RecheckJadis?requestId=${requestId}`,
    RecheckAml: (requestId) => `/api/Request/RecheckAml?requestId=${requestId}`,
    RecheckJarManager: (code) =>
      `/api/Request/RecheckJarManager?companyCode=${code}`,
    GetJarManagerChecks: `/api/Request/GetJarManagerChecks`,
  },

  APP_PATHS: {
    login: '/login',
    main: '/main-window',
    requests: '/main-window/requests',
    newRequest: '/main-window/requests/new-request',
    newRequest2: '/main-window/requests/new-request-2',
    newRequestWithId: '/main-window/requests/new-request/:id',
    authorization: '/main-window/authorization',
    permisssionDenied: `/permission-denied/:id`,
    permisssionDenied1: `/permission-denied`,
    representative: '/representative',
    paymentrequests: '/main-window/paymentrequests',
    paymentrequestsId: '/main-window/paymentrequests',
    paymentrequest: '/main-window/paymentrequest',
    paymentrequestId: '/main-window/paymentrequest',
    paymentDocument: '/main-window/paymentdocument',
    about: `/about`,
    description: `/description`,

    bills: '/main-window/bills',
    billsView: (id) => `/main-window/bills/${id}`,
    billsApprove: '/main-window/bills-approve',
    billCreate: '/main-window/bill/create',
    billEdit: '/main-window/bill/edit',
    billView: '/main-window/bill/view',
    billApproveView: '/main-window/bill/approveview',

    backoffice: '/backofficelogin',
    backofficeMain: '/backoffice/main',
    backofficeRequest: '/backoffice/request',
    backofficeRequest2: '/backoffice/request-2',
    backofficePaymentRequests: '/backoffice/paymentrequests',
    backofficePaymentRequest: '/backoffice/paymentrequest',
    backofficePaymentDocument: '/backoffice/paymentdocument',
    PaymentDocumentSumEdit: '/backoffice/paymentdocumentsumedit',
    AdditionalRequests: '/backoffice/additionalRequests',

    backofficeBills: '/backoffice/bills',
    backofficeBill: '/backoffice/bill',
    backofficeStats: '/backoffice/stats',
    backofficeRepresentatives: '/backoffice/representatives',
    turnoversFiles: '/backoffice/turnovers-files',

    home: '/',
    vaiisis: '/api/Auth/Login',
  },
  PAGES: {
    home: 'Home',
  },
  LOCAL_STORAGE: {
    adminToken: 'adminToken',
  },
};
