import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './globalConfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';

const GlobalConfirmationModal = ({
  open,
  text,
  data,
  action,
  loading,
  dispatch,
  checkbox,
  okTitle,
  modalTitle,
  userComment,
  mainText,
}) => {
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);

  const onAgree = () => {
    setAgree(!agree);
  };

  const cancel = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: false,
    };
    setAgree(false);
    setShowError(false);
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    console.log(data);
    console.log(action);
    if (!checkbox || (checkbox && agree)) {
      dispatch(actions.modals.setModalConfirmAction(data, action, userComment));
      const open = {
        modalName: 'globalConfirmationModal',
        visible: false,
      };
      setAgree(false);
      setShowError(false);
      dispatch(actions.modals.setModalVisibleFlagAction(open));
    } else {
      setShowError(true);
    }
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <div className="modal-title2">{modalTitle}</div>

            <div className={styles.btnContainer}>
              <div class="checkbox  margin-all-modal">
                <div
                  style={{
                    color: '#597a96',
                    lineHeight: '20px',
                  }}
                  dangerouslySetInnerHTML={{ __html: mainText }}
                ></div>
                <label class="containerLabel noBlock">
                  {checkbox && (
                    <span>
                      <input
                        type="checkbox"
                        checked={agree}
                        onChange={onAgree}
                      />
                      <span class="checkmark "></span>
                    </span>
                  )}
                  {checkbox === true ? (
                    <span dangerouslySetInnerHTML={{ __html: text }}></span>
                  ) : (
                    <h2
                      style={{
                        color: '#033878',
                        marginBottom: '1rem ',
                        fontSize: '22px',
                      }}
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></h2>
                  )}
                </label>
                {checkbox && showError && (
                  <div>
                    <span className="errors-block-message">
                      Turite pažymėti, kad sutinkate su sąlygomis.
                    </span>
                  </div>
                )}
              </div>
              <div>
                <Button type="standart" onClick={() => yes(data)}>
                  {okTitle ? okTitle : 'Tvirtinti'}
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(GlobalConfirmationModal);
