export const criteriaValue = (criteria) => {
  switch (criteria) {
    case 'MunicipalityShareCount':
      return 'Pareiškėjas negali būti valstybės ar savivaldybės įmonė ar įmonė, kurios 25 procentus ir daugiau įmonės akcijų, pajų ar kitokių dalyvavimą įmonės kapitale žyminčių kapitalo dalių arba 25 procentus ir daugiau įmonės dalyvių balsų tiesiogiai ar netiesiogiai (pagal balsavimo sutartį, balsavimo teisės perleidimo sutartį, įgaliojimą ir pan.) atskirai arba kartu turi valstybė ir (ar) savivaldybė.';
    case 'LegalStatus':
      return 'Pareiškėjas paraiškos pateikimo metu negali būti įgijęs bankrutuojančios, restruktūrizuojamos, likviduojamos įmonės statuso.';
    case 'RentParticipantShareCountCompany':
      return 'Pareiškėjas ir nuomotojas negali priklausyti tai pačiai įmonių grupei.';
    case 'RentParticipantShareCountPerson':
      return 'Pareiškėjas negali nuomotis patalpų iš nuomotojo, turinčio daugumą dalyvių balsų įmonėje, teikiančioje paraišką.';
    case 'RentParticipantShareholdersShareCountPersonRule':
      return 'Pareiškėjas negali nuomotis patalpų iš nuomotojo, turinčio daugumą dalyvių balsų įmonėje, teikiančioje paraišką.';
    case 'RegistrationDate':
      return 'Pareiškėjas (juridinis asmuo) turi būti įregistruotas Juridinių asmenų registre';
    case 'Activity':
      return 'Pareiškėjo vykdoma pagrindinė veikla negali būti elektroninė prekyba, ginklų ir šaudmenų specializuota mažmeninė prekyba, azartinių žaidimų ir lažybų ar kitų azartinių lošimų organizavimo veikla, finansų įstaigų vykdoma veikla.';
    case 'UndertakingInDifficulty':
      return 'Pareiškėjas 2019 m. gruodžio 31 d. negali būti laikomas sunkumų patiriančia įmone, kaip tai apibrėžta 2014 m. birželio 17 d. Komisijos reglamento (ES) Nr. 651/2014, kuriuo tam tikrų kategorijų pagalba skelbiama suderinama su vidaus rinka taikant Sutarties 107 ir 108 straipsnius, 2 straipsnio 18 punkte.';
    case 'FinancialIndicators':
      return 'Įmonė (įskaitant Įmonių grupę) Paraiškos pateikimo metu turi būti pateikusi VĮ „Registrų centrui“ finansinių ataskaitų rinkinį.';
    case 'FinancialIndicators2020':
      return 'Įmonė (įskaitant Įmonių grupę) Paraiškos pateikimo metu turi būti pateikusi VĮ „Registrų centrui“ finansinių ataskaitų rinkinį.';
    case 'FinancialIndicatorsIndividual':
      return 'Pareiškėjas (fizinis asmuo) paraiškos teikimo metu turi vykdyti paraiškoje nurodytą registruotą verslininko veiklą.';

    case 'IbanLithuanian':
      return 'Turi būti nurodyta LR įsteigtoje kredito įstaigoje ar užsienio kredito įstaigos filiale, įsteigtame Lietuvoje, esanti ir pareiškėjui priklausanti atsiskaitomoji sąskaita.';

    case 'PersonBankruptcy':
      return 'Pareiškėjas (fizinis asmuo) negali būti bankrutuojantis arba pradėjęs bankroto procedūras.';

    case 'CompanyIncome':
      return 'Pareiškėjo metinės pajamos 2019 m. negali viršyti 50 000 000 Eur (penkiasdešimt milijonų eurų) arba turto balansinė vertė 2019 m. gruodžio 31 d. negali viršyti 43 000 000 Eur (keturiasdešimt trijų milijonų eurų).';

    case 'ContractIsInNtrRegister':
      return 'Patalpų nuomos sutartis turi būti registruota valstybės įmonėje Registrų centre.';

    case 'ContractStartDate':
      return 'Patalpų nuomos sutartis turi būti pasirašyta ne vėliau kaip 2020 m. kovo 15 d.';

    case 'ContractEndDate':
      return 'Nuomos sutartis turi galioti bent iki 2020 m. liepos 1d.';

    case 'ContractStartDateMatchesRegister':
      return 'Paraiškoje pateikta nekilnojamo turto nuomos pradžios data nesutampa su valstybės įmonėje Registrų centras užregistuota nuomos pradžios data';
    case 'ContractEndDateMatchesRegister':
      return 'Paraiškoje pateikta nekilnojamo turto nuomos pabaigos data nesutampa su valstybės įmonėje Registrų centras užregistuota turto nuomos pabaigos data';

    case 'ContractPartiesMatches':
      return 'Paraiškoje pateikti nuomos sutarties nuomotojo ir pareiškėjo (nuomininko) duomenys nesutampa su valstybės įmonėje Registrų centre užregistruotos nuomos sutarties nuomotojo ir nuomininko duomenimis.';
    case 'NtrContractValidFromDateRule':
      return 'NTR registre pateikta sutarties sudarymo data turi būti ne vėlesnė nei 2020m.kovo 15d.';
    case 'AllowedEvrkCodes':
      return 'Paskolos gavėjas vykdo vieną iš finansų, žemės ūkio (išskyrus atvejus, kai gavėjas vykdo arba ketina vykdyti inovacinę veiklą), miškininkystės, žuvininkystės ir akvakultūros sektoriuose vykdomų veiklų, kuri nėra remtina pagal priemonės Aprašymą.';

    case 'AllINdividualActivitiesIsInRange':
      return '“Pagal VMI pateiktą informaciją, paraiškoje nurodyta veikla negaliojo visu laikotarpiu, imant nuo 2019 m. lapkričio 1 d. iki 2020 m. sausio 31 d.';
    case 'ActivityForbidden':
      return 'Pagrindinė ekonominė vykdoma veikla turi būti įtraukta į ribojamų veiklų sąrašą, jei pagrindinės vykdomos veiklos pradžios data (pagal VMI pateiktus duomenis) yra vėlesnė nei 2020 m. sausio 31 d.';
    case 'CompanyActivityOngoing':
      return 'Jei įmonė visu laikotarpiu nuo 2019 m. lapkričio 1 d. iki 2020 m. sausio 31 d. nevykdė veiklos, paraiška neatitinka kriterijų.”';
    case 'IndividualActivityForbidden':
      return 'Pagal VMI pateiktą informaciją, jei vykdomos veiklos pradžios data yra vėliau nei 2020m. sausio 31 d., ji turi būti įtraukta į ribojamų veiklų sąrašą (VMI). Veikloms, kurių pradžia yra anksčiau nei 2020m. sausio 31 d., taisyklė netaikoma.';

    default:
      return criteria;
  }
};

export const criteriaValuep = (criteria) => {
  switch (criteria) {
    case 'MunicipalityShareCount':
      return 'Pareiškėjas negali būti valstybės ar savivaldybės įmonė ar įmonė, kurios 25 procentus ir daugiau įmonės akcijų, pajų ar kitokių dalyvavimą įmonės kapitale žyminčių kapitalo dalių arba 25 procentus ir daugiau įmonės dalyvių balsų tiesiogiai ar netiesiogiai (pagal balsavimo sutartį, balsavimo teisės perleidimo sutartį, įgaliojimą ir pan.) atskirai arba kartu turi valstybė ir (ar) savivaldybė.';
    case 'LegalStatus':
      return 'Pareiškėjas paraiškos pateikimo metu negali būti įgijęs bankrutuojančios, restruktūrizuojamos, likviduojamos įmonės statuso.';
    case 'RentParticipantShareCountCompany':
      return 'Pareiškėjas ir nuomotojas negali priklausyti tai pačiai įmonių grupei.';
    case 'RentParticipantShareCountPerson':
      return 'Pareiškėjas negali nuomotis patalpų iš nuomotojo, turinčio daugumą dalyvių balsų įmonėje, teikiančioje paraišką.';
    case 'RentParticipantShareholdersShareCountPersonRule':
      return 'Pareiškėjas negali nuomotis patalpų iš nuomotojo, turinčio daugumą dalyvių balsų įmonėje, teikiančioje paraišką.';
    case 'RegistrationDate':
      return 'Pareiškėjas (juridinis asmuo) turi būti įregistruotas Juridinių asmenų registre';
    case 'Activity':
      return 'Pareiškėjo vykdoma pagrindinė veikla negali būti elektroninė prekyba, ginklų ir šaudmenų specializuota mažmeninė prekyba, azartinių žaidimų ir lažybų ar kitų azartinių lošimų organizavimo veikla, finansų įstaigų vykdoma veikla.';
    case 'UndertakingInDifficulty':
      return 'Pareiškėjas 2019 m. gruodžio 31 d. negali būti laikomas sunkumų patiriančia įmone, kaip tai apibrėžta 2014 m. birželio 17 d. Komisijos reglamento (ES) Nr. 651/2014, kuriuo tam tikrų kategorijų pagalba skelbiama suderinama su vidaus rinka taikant Sutarties 107 ir 108 straipsnius, 2 straipsnio 18 punkte.';
    case 'FinancialIndicators':
      return 'Įmonė (įskaitant Įmonių grupę) Paraiškos pateikimo metu turi būti pateikusi VĮ „Registrų centrui“ finansinių ataskaitų rinkinį.';
    case 'FinancialIndicators2020':
      return 'Įmonė (įskaitant Įmonių grupę) Paraiškos pateikimo metu turi būti pateikusi VĮ „Registrų centrui“ finansinių ataskaitų rinkinį.';
    case 'FinancialIndicatorsIndividual':
      return 'Pareiškėjas (fizinis asmuo) paraiškos teikimo metu turi vykdyti paraiškoje nurodytą registruotą verslininko veiklą.';
    case 'IbanLithuanian':
      return 'Turi būti nurodyta LR įsteigtoje kredito įstaigoje ar užsienio kredito įstaigos filiale, įsteigtame Lietuvoje, esanti ir pareiškėjui priklausanti atsiskaitomoji sąskaita.';
    case 'PersonBankruptcy':
      return 'Pareiškėjas (fizinis asmuo) negali būti bankrutuojantis arba pradėjęs bankroto procedūras.';
    case 'CompanyIncome':
      return 'Pareiškėjo metinės pajamos 2019 m. negali viršyti 50 000 000 Eur (penkiasdešimt milijonų eurų) arba turto balansinė vertė 2019 m. gruodžio 31 d. negali viršyti 43 000 000 Eur (keturiasdešimt trijų milijonų eurų).';
    case 'ContractIsInNtrRegister':
      return 'Patalpų nuomos sutartis turi būti registruota valstybės įmonėje Registrų centre.';
    case 'ContractStartDate':
      return 'Patalpų nuomos sutartis turi būti pasirašyta ne vėliau kaip 2020 m. kovo 15 d.';
    case 'ContractEndDate':
      return 'Nuomos sutartis turi galioti bent iki 2020 m. liepos 1d.';
    case 'ContractStartDateMatchesRegister':
      return 'Paraiškoje pateikta nekilnojamo turto nuomos pradžios data nesutampa su valstybės įmonėje Registrų centras užregistuota nuomos pradžios data';
    case 'ContractEndDateMatchesRegister':
      return 'Paraiškoje pateikta nekilnojamo turto nuomos pabaigos data nesutampa su valstybės įmonėje Registrų centras užregistuota turto nuomos pabaigos data';
    case 'ContractPartiesMatches':
      return 'Paraiškoje pateikti nuomos sutarties nuomotojo ir pareiškėjo (nuomininko) duomenys nesutampa su valstybės įmonėje Registrų centre užregistruotos nuomos sutarties nuomotojo ir nuomininko duomenimis.';
    case 'NtrContractValidFromDate':
      return 'NTR registre pateikta sutarties sudarymo data turi būti ne vėlesnė nei 2020m. kovo 15d.';
    case 'CompanyCountry':
      return 'Pareiškėjas (juridinis asmuo) turi būti įregistruotas Juridinių asmenų registre.';
    case 'CompanyLongTermDebtor':
      return 'Pareiškėjas turi atitikti minimalius Lietuvos Respublikos mokesčių administravimo įstatymo 40¹  straipsnyje nustatytų patikimų mokesčių mokėtojų kriterijus';
    case 'CompanyStatus':
      return 'Įmonei nėra iškelta nemokumo byla ir (ar) ji yra nėra restruktūrizuojama pagal Lietuvos Respublikos juridinių asmenų nemokumo įstatymą arba iki jo įsigaliojimo galiojusius Lietuvos Respublikos įmonių bankroto įstatymą ir Lietuvos Respublikos restruktūrizavimo įstatymą.';
    case 'CompanyActivityOngoing':
      return 'Jei įmonė visu laikotarpiu nuo 2019 m. lapkričio 1 d. iki 2020 m. sausio 31 d. nevykdė veiklos, paraiška neatitinka kriterijų.”';
    case 'IndividualActivityForbidden':
      return 'Pagal VMI pateiktą informaciją, jei vykdomos veiklos pradžios data yra vėliau nei 2020m. sausio 31 d., ji turi būti įtraukta į ribojamų veiklų sąrašą (VMI). Veikloms, kurių pradžia yra anksčiau nei 2020m. sausio 31 d., taisyklė netaikoma.';
    case 'AllINdividualActivitiesIsInRange':
      return '“Pagal VMI pateiktą informaciją, paraiškoje nurodyta veikla negaliojo visu laikotarpiu, imant nuo 2019 m. lapkričio 1 d. iki 2020 m. sausio 31 d.';
    case 'NtrContractValidFromDateRule':
      return 'NTR registre pateikta sutarties sudarymo data turi būti ne vėlesnė nei 2020m.kovo 15d.';
    case 'AllowedEvrkCodes':
      return 'Paskolos gavėjas vykdo vieną iš finansų, žemės ūkio (išskyrus atvejus, kai gavėjas vykdo arba ketina vykdyti inovacinę veiklą), miškininkystės, žuvininkystės ir akvakultūros sektoriuose vykdomų veiklų, kuri nėra remtina pagal priemonės Aprašymą.';
    case 'ActivityForbidden':
      return 'Pagrindinė ekonominė vykdoma veikla turi būti įtraukta į ribojamų veiklų sąrašą, jei pagrindinės vykdomos veiklos pradžios data (pagal VMI pateiktus duomenis) yra vėlesnė nei 2020 m. sausio 31 d.';
    case 'MoreThan5Years':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: paskolos paskirtis Socialinio poveikio projektas Ir įmonės amžius didesnis nei 5 metai.';
    case 'MediumSizeBusinessman':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: paskolos paskirtis Socialinio poveikio projektas ir verslininko dydis pagal SVV deklaraciją - Vidutinė įmonė.';
    case 'BigSize':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: verslo subjekto dydis pagal SVV deklaraciją - didelė įmonė.';
    case 'MoreThan3YearsNotSocial':
      return 'Pareiškėjas neatitinka priemonės aprašyme 6 punktu nustatyto Paskolos gavėjo apibrėžimo: paskolos paskirtis nėra Socialinio poveikio projektas Ir įmonės amžius didesnis nei 3 metai.';

    default:
      return criteria;
  }

  
};

export const criteriaValuep2 = (criteria) => {
  switch (criteria) {

    case 'BigSize':
      return 'Paskolos gavėjas turi atitikti priemonės Aprašyme nustatytą Paskolos gavėjo apibrėžimą, kuris nurodo, kad  Paskolos gavėjas yra smulkiojo ir vidutinio verslo (toliau -SVV) subjektas, kaip jis apibrėžtas SVV įstatyme. ';
      case 'FinancialIndicators':
        return 'Verslo subjektas (įskaitant įmonių grupę) yra pateikęs VĮ "Registrų centrui" finansinių ataskaitų rinkinius už paskutinius 2 finansinius metus ar trumpesnį terminą (jei įmonė veikia trumpiau nei 2 metus).'; 
        case 'FinancialIndicatorsIndividual':
          return 'Pareiškėjas (fizinis asmuo) paraiškos teikimo metu turi vykdyti paraiškoje nurodytą registruotą verslininko veiklą.';
          case 'MoreThan3YearsNotSocial':
            return 'Paskolos gavėjas neatitinka priemonės Aprašyme nustatyto Paskolos gavėjo apibrėžimo, kuris nurodo, kad  Paskolos gavėjas, nevykdantis Socialinio poveikio projekto, turi veikti ne ilgiau kaip trejus metus.';
            case 'MoreThan5Years':
              return 'Paskolos gavėjas neatitinka priemonės Aprašyme nustatyto Paskolos gavėjo apibrėžimo, kuris nurodo, kad  Paskolos gavėjas, vykdantis Socialinio poveikio projektą, turi veikti  ne ilgiau kaip penkerius metus.';     
              case 'CompanyStatus':
                return ' Verslo subjektas negali būti bankrutuojantis arba pradėjęs bankroto procedūrą.';
                case 'PersonBankruptcy':
                  return 'Verslo subjektas negali būti bankrutuojantis arba pradėjęs bankroto procedūrą';       
      case 'CompanyCountry':
        return 'Paskolos gavėjas (juridinis asmuo) turi būti įregistruotas VĮ Registrų centras Juridinių asmenų registre.';
      case 'CompanyLongTermDebtor':
        return 'Paskolos gavėjas turi atitikti minimalių Lietuvos Respublikos mokesčių administravimo įstatymo 40¹ nustatytų patikimų mokesčių mokėtojų kriterijų.';
           
    case 'MunicipalityShareCount':
      return 'Paskolos gavėjas nėra valstybės ir (ar) savivaldybių įmonė ir įmonė, kurioje 25 procentus ir daugiau įmonės akcijų, pajų ar kitokių dalyvavimą įmonės kapitale žyminčių kapitalo dalių atskirai arba kartu turi valstybė ir (ar) savivaldybė.';
    default:
      return criteria;
  }

  
};

