import { actions } from '../../state/actions';
import { put } from 'redux-saga/effects';
import swal from 'sweetalert';
import { removeLocalStorageItem } from '../../utils';
import { isProd } from '../../api/config';

export function* errorhandle(error) {
  if (error.statusCode === 401) {
    // yield put(actions.auth.clearUserAction());
    removeLocalStorageItem('userToken');
    removeLocalStorageItem('token');
    removeLocalStorageItem('loginType');
    removeLocalStorageItem('Role');
    removeLocalStorageItem('boFilter');
    removeLocalStorageItem('currentPage');

    if (checkBOPage()) {
      window.location.href = '/backofficelogin';
    } else {
      window.location.href = isProd()
        ? 'https://paslaugos.invega.lt/?sessionExpired=true'
        : 'https://barzda.scorify.lt/?sessionExpired=true';
    }
  } else if (
    error.statusCode === 400 &&
    error.error &&
    Array.isArray(error.error)
  ) {
    const openFin = {
      modalName: 'confirmWithFinancialCriteriaModal',
      visible: false,
    };
    yield put(actions.modals.setModalVisibleFlagAction(openFin));

    const err = error.error;
    yield put(actions.modals.setErrorMessageAction(err));

    const open = {
      modalName: 'globalErrorModal',
      visible: true,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));
  } else if (
    error.statusCode === 400 &&
    error.error != null &&
    error.error.ErrorId == null &&
    error.error.ErrorMessage == null
  ) {
    const err = error.error.errors;
    yield put(actions.modals.setValidationErrorAction(err));
    const open = {
      modalName: 'validationErrorModal',
      visible: true,
    };

    yield put(actions.modals.setModalVisibleFlagAction(open));
  } else if (
    error.statusCode === 400 &&
    error.error != null &&
    error.error.ErrorId == null &&
    error.error.ErrorMessage != null
  ) {
    const err = error.error.ErrorMessage;
    yield put(actions.modals.setValidationErrorAction(err));

    const open = {
      modalName: 'validationErrorModal',
      visible: true,
    };

    yield put(actions.modals.setModalVisibleFlagAction(open));
  } else {
    const openGlobalModal = {
      modalName: 'globalConfirmationModal',
      visible: false,
    };

    const open = {
      modalName: 'globalErrorModal',
      visible: true,
    };

    const ConfirmWithFinancialCriteriaModal = {
      modalName: 'confirmWithFinancialCriteriaModal',
      visible: false,
    };
    const ConfirmWithoutFinancialCriteriaModal = {
      modalName: 'confirmWithoutFinancialCriteriaModal',
      visible: false,
    };
    const PaymentRequestSendforApproveModal = {
      modalName: 'paymentRequestSendforApproveModal',
      visible: false,
    };

    yield put(actions.modals.setModalVisibleFlagAction(openGlobalModal));
    if (error.error != null && error.error.ErrorDescription != null) {
      console.log(error.error.ErrorDescription);
      const emptyMessage = error.error.ErrorDescription;
      // const msgtext =
      // if
      yield put(actions.modals.setModalVisibleFlagAction(open));

      yield put(
        actions.modals.setModalVisibleFlagAction(
          ConfirmWithFinancialCriteriaModal
        )
      );

      yield put(actions.modals.setErrorMessageAction(emptyMessage));
    } else if (error == 'NoLoginType') {
      console.log('NoLoginType');
      // yield put(actions.auth.clearUserAction());
      // window.location.reload();
    } else if (error.statusCode === 403) {
      swal({
        title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } else {
      const emptyMessage = 'Įvyko klaida';
      yield put(actions.modals.setModalVisibleFlagAction(open));
      yield put(actions.modals.setErrorMessageAction(emptyMessage));
    }
    yield put(actions.backoffice.setStatusChanged(false));
  }
}

export const formatNumber = (num, minFractionDigits) => {
  if (num != null && typeof num === 'number') {
    return num.toLocaleString('lt', {
      minimumFractionDigits: minFractionDigits || 2,
    });
  } else {
    return num;
  }
};

export const formatNumberStats = (num) => {
  if (num != null && typeof num === 'number') {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2 });
  } else {
    return num;
  }
};

export const downloadXls = (response, name) => {
  const url = window.URL.createObjectURL(
    new Blob([response], { 'Content-Type': 'application/vnd.ms-excel' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.zip`);
  document.body.appendChild(link);
  link.click();
};

export const downloadDoc = (response, name) => {
  const url = window.URL.createObjectURL(
    new Blob([response], { 'Content-Type': 'application/pdf' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.pdf`);
  document.body.appendChild(link);
  link.click();
};

export const downloadListXlsFile = (response, name) => {
  const url = window.URL.createObjectURL(
    new Blob([response], { 'Content-Type': 'application/vnd.ms-excel' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

export const downloadAllFilesHelper = (response, name) => {
  const url = window.URL.createObjectURL(
    new Blob([response], { 'Content-Type': 'application/vnd.ms-excel' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.zip`);
  document.body.appendChild(link);
  link.click();
};

export const checkBOPage = () => {
  return window.location?.pathname.indexOf('backoffice') !== -1 ? true : false;
};

export const getAutorization = () => {
  return `Bearer ${window.localStorage.getItem(
    checkBOPage() ? 'token' : 'userToken'
  )}`;
};

export const backToPrevHistoryPage = () => {
  document.referrer.includes(window.location.host)
    ? window.history.back()
    : (window.location = `${window.location.protocol}//${window.location.host}`);
};
