import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './doublecheckEvaluationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';

const DoublecheckEvaluationModal = ({ open, id, dispatch, loading, type, formVersion }) => {
  const cancel = () => {
    const open = {
      modalName: 'doublecheckEvaluationModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));

    if (type === 'manager') {
      dispatch(
        actions.dashboard.getFinancialCriteriaResultsAction(
          id,
          'Manager',
          false,
          formVersion,
        )
      );
    } else {
      dispatch(
        actions.dashboard.getFinancialCriteriaResultsAction(id, 'User', false, formVersion,)
      );
    }

    const open2 = {
      modalName: 'financialCriteriaModal',
      visible: true,
      formVersion: formVersion,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open2));
  };

  const yes = () => {
    const open = {
      modalName: 'doublecheckEvaluationModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));

    if (type === 'manager') {
      dispatch(
        actions.dashboard.getFinancialCriteriaResultsAction(id, 'Manager', true,formVersion,)
      );
    } else {
      dispatch(
        actions.dashboard.getFinancialCriteriaResultsAction(id, 'User', true,formVersion,)
      );
    }
    const open2 = {
      modalName: 'financialCriteriaModal',
      visible: true,
      formVersion: formVersion,
    };

    dispatch(actions.modals.setModalVisibleFlagAction(open2));
  };
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <div className={styles.btnContainer}>
              <div class="checkbox  margin-all-modal">
                <h4>
                  Šiai paraiškai dalis patikrų nebuvo vykdyta. Ar norite
                  užklausti registrus ir pakartotinai patikrinti atitikimą?
                </h4>
              </div>
              <div>
                <Button type="standart" onClick={() => yes()}>
                  Taip
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Ne
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(DoublecheckEvaluationModal);
