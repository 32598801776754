import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { config } from '../../api/config';

import styles from './permissionDeniedModal.module.scss';
// import { WithSpinner } from './../../components';
import { actions } from './../../state/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

const PermissionDeniedModal = ({ open, dispatch }) => {
  const [redirect, setredirect] = useState(false);
  const cancel = () => {
    setredirect(true);
    const open = {
      modalName: 'permissionDeniedModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };
  const id = window.location.href.split('/').pop();

  const errText = () => {
    if (id === '1') {
      return 'Negavome duomenų apie Jūsų tapatybę';
    } else if (id === '2') {
      return 'Prisijungiant per El. valdžios vartus nepasirinkote atstovaujamos įmonės. Prašome pakartoti prisijungimą pasirenkant atstovaujamą verslo subjektą.';
    } else if (id === '3') {
      return 'Pagal Juridinių asmenų duomenis, jūs negalite atstovauti pasirinktos įmonės';
    } else {
      return 'Įvyko klaida, prašome pabandyti prisijungti iš naujo';
    }
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '300px' }}
        >
          <div className={styles.contentBlock}>
            <h2>Dėmesio!</h2>

            <p>{errText()}</p>
            <div className={styles.btnContainer}>
              <button
                type="standart"
                onClick={() => cancel()}
                className="vaiisisloginbtn"
              >
                Prisijungti iš naujo
              </button>
            </div>
          </div>
        </div>
        {redirect ? <Redirect to={config.APP_PATHS.login} /> : null}
      </div>
    )
  );
};

export default connect()(PermissionDeniedModal);
