import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import {
  WithSpinner,
  TableAdmin,
  TableRow,
  TableCell,
  Button,
} from '../../components';
import { Link } from 'react-router-dom';
import {
  requestStatusForStats,
  StatusesStats,
} from './../../utils/translation/translation';
import { formatNumberStats } from '../../utils/helpers/helpers';

const BackofficeStats = ({ dispatch, statsState }) => {
  useEffect(() => {
    dispatch(actions.backoffice.getStatsAction());
  }, []);

  const totalsTableHeader = [
    'Rengiamos paraiškos, vnt.',
    'Pateikta vertinti (vakar)',
    'Atmesta sistemos',
    'Pateikta vertinti (nuo priemonės pradžios)',
  ];

  const requestsTableHeader = [
    'Būsena',
    'Paraiškų skaičius, vnt.',
    'Prašoma paskolos suma',
    'Suteiktos paskolos suma',
  ];

  const requestsTableHeader2 = [
    'Sprendimas',
    'Paraiškų skaičius, vnt.',
    'Prašoma paskolos suma',
    'Suteiktos paskolos suma',
    //'Pateiktų mokėjimo prašymų suma',
  ];
  const requestsTableHeader3 = [
    'Sutartis',
    'Paraiškų skaičius, vnt.',
    'Prašoma paskolos suma',
    'Suteiktos paskolos suma',
    //'Pateiktų mokėjimo prašymų suma',
  ];

  const requestsTableHeader4 = [
    //'Regionas',
    '',
    'Skirta priemonei suma',
    'Rezervuota priemonės suma',
    'Likutis',
  ];
  const decisionDIctonary = [110, 111, 112, 210, 211, 212];

  const findById = (id) =>
    statsState?.data?.Decisions.find((val) => val.RequestStatusId === id);
  return (
    <WithSpinner loading={statsState.loading}>
      <div style={{ width: '80%', marginLeft: '10%' }}>
        <h2 style={{ marginTop: '1rem' }}>Statistika</h2>
        {/*</div>TableAdmin header={totalsTableHeader} isEmpty={false}>
          {statsState && statsState.data && (
            <TableRow>
              <TableCell>{statsState.data.Totals.RequestsCount}</TableCell>
              <TableCell>
                {statsState.data.Totals.RequestsCountYesterday}
              </TableCell>
              <TableCell>
                {statsState.data.Totals.RequestsIneligibleForFunding}
              </TableCell>
              <TableCell>{statsState.data.Totals.RequestsToAsses}</TableCell>
            </TableRow>
          )}
          </TableAdmin>*/}

        <p style={{ fontSize: '16px', marginTop: '40px' }}>Paraiškos</p>

        {/*<TableAdmin header={requestsTableHeader} isEmpty={false}>
          {statsState &&
            statsState.data &&
            statsState.data.Requests.map(
              ({ RequestsCount, RequestStatusId, InvegaLoanSum, LoanSum }) => (
                <TableRow>
                  <TableCell>
                    <span
                      style={{
                        fontWeight:
                          RequestStatusId === 99 ||
                          RequestStatusId === 100 ||
                          RequestStatusId === 101
                            ? 'bold'
                            : 'normal',
                      }}
                    >
                      {' '}
                      {requestStatusForStats(RequestStatusId)}{' '}
                    </span>
                  </TableCell>
                  <TableCell>{RequestsCount}</TableCell>
                  <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                  <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                </TableRow>
              )
            )}
                    </TableAdmin>*/}

        <TableAdmin header={requestsTableHeader} isEmpty={false}>
          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum }) =>
                Name !== 'NegativeDecision' &&
                Name !== 'PositiveDecision' &&
                (Name === 'Created' ||
                  Name === 'Evaluating' ||
                  Name === 'Submitted') && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight:
                            Name === 'Created' ||
                            Name === 'Evaluating' ||
                            Name === 'Submitted'
                              ? 'bold'
                              : 'normal',
                        }}
                      >
                        {' '}
                        {StatusesStats(Name)}{' '}
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>
                      {Name !== 'Created' ? formatNumberStats(LoanSum) : ''}
                    </TableCell>
                    <TableCell>
                      {Name !== 'Created' && Name !== 'Evaluating'
                        ? formatNumberStats(InvegaLoanSum)
                        : ''}
                    </TableCell>
                  </TableRow>
                )
            )}
        </TableAdmin>

        <p style={{ fontSize: '16px', marginTop: '40px' }}>Sprendimai</p>
        <TableAdmin header={requestsTableHeader2} isEmpty={false}>
          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
              Name === 'PositiveDecision' &&
              ProjectRegionId == null && (
                <TableRow>
                  <TableCell>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {StatusesStats(Name)}{' '}
                    </span>
                  </TableCell>
                  <TableCell>{Count}</TableCell>
                  <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                  <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                </TableRow>
              )
            )}
         {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'PositiveDecision' &&
                ProjectRegionId == '1' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        Vidurio vakarų Lietuvos regionas
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}
          
          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'PositiveDecision' &&
                ProjectRegionId == '2' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        
                        Sostinės regionas ir visa Lietuva
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}

        {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'NegativeDecision' &&
                ProjectRegionId == null && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        {StatusesStats(Name)}{' '}
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}
          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'NegativeDecision' &&
                ProjectRegionId == '1' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        
                        Vidurio vakarų Lietuvos regionas
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}
          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'NegativeDecision' &&
                ProjectRegionId == '2' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        
                        Sostinės regionas ir visa Lietuva
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}
         
        </TableAdmin>

        {/*<TableAdmin header={requestsTableHeader2} isEmpty={false}>
          {statsState &&
            statsState.data &&
            statsState.data.Decisions &&
            decisionDIctonary.map((i) => (
              <TableRow>
                <TableCell>
                  <span
                    style={{
                      fontStyle:
                        i === 111 || i === 112 || i === 211 || i === 212
                          ? 'italic'
                          : 'normal',
                    }}
                  >
                    {requestStatusForStats(i)}
                  </span>
                </TableCell>
                <TableCell>
                  {findById(i) && findById(i).RequestsCount}
                </TableCell>
                <TableCell>
                  {findById(i) && formatNumberStats(findById(i).LoanSum)}
                </TableCell>
                <TableCell>
                  {findById(i) && formatNumberStats(findById(i).InvegaLoanSum)}
                </TableCell>
                <TableCell>
                  {findById(i) && formatNumberStats(findById(i).FundedSum)}
                </TableCell>
              </TableRow>
            ))}
                  </TableAdmin>*/}
        <br />
        <br />
        <p style={{ fontSize: '16px', marginTop: '40px' }}>Sutartys</p>
        <TableAdmin header={requestsTableHeader3} isEmpty={false}>
        {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'ContractSigned' &&
                ProjectRegionId == null && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight:
                            Name !== 'Created' ||
                            Name !== 'Evaluating' ||
                            Name !== 'Submitted'
                              ? 'bold'
                              : 'normal',
                        }}
                      >
                        {' '}
                        {StatusesStats(Name)}{' '}
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}
          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'ContractSigned' &&
                ProjectRegionId == '1' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                      
                        Vidurio vakarų Lietuvos regionas
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}

          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'ContractSigned' &&
                ProjectRegionId == '2' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        
                        Sostinės regionas ir visa Lietuva
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}

          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'ContractTerminated' &&
                ProjectRegionId == null && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight:
                            Name !== 'Created' ||
                            Name !== 'Evaluating' ||
                            Name !== 'Submitted'
                              ? 'bold'
                              : 'normal',
                        }}
                      >
                        {' '}
                        {StatusesStats(Name)}{' '}
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}

       {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'ContractTerminated' &&
                ProjectRegionId == '1' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                       
                        Vidurio vakarų Lietuvos regionas
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}

          {statsState &&
            statsState.data &&
            statsState.data.Requests?.map(
              ({ Count, Name, InvegaLoanSum, LoanSum, ProjectRegionId }) =>
                Name === 'ContractTerminated' &&
                ProjectRegionId == '2' && (
                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                     
                        Sostinės regionas ir visa Lietuva
                      </span>
                    </TableCell>
                    <TableCell>{Count}</TableCell>
                    <TableCell>{formatNumberStats(LoanSum)}</TableCell>
                    <TableCell>{formatNumberStats(InvegaLoanSum)}</TableCell>
                  </TableRow>
                )
            )}
        </TableAdmin>

        <br />
        <br />
        <p style={{ fontSize: '16px', marginTop: '40px' }}>Paraiškos</p>
        <TableAdmin header={requestsTableHeader4} isEmpty={false}>
       {statsState &&
            statsState.data &&
            statsState.data.Totals?.map(
              ({ MaxSum, Rezerved, Reminder, ProjectRegionId }) =>
                ProjectRegionId == '1' && (
                  <TableRow>
                     <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        
                        Vidurio vakarų Lietuvos regionas
                      </span>
                      </TableCell>
                    <TableCell>{formatNumberStats(MaxSum)}</TableCell>
                    <TableCell>{formatNumberStats(Rezerved)}</TableCell>
                    <TableCell>{formatNumberStats(Reminder)}</TableCell>
                  </TableRow>
                )
            )}
            {statsState &&
            statsState.data &&
            statsState.data.Totals?.map(
              ({ MaxSum, Rezerved, Reminder, ProjectRegionId }) =>
                ProjectRegionId == '2' && (
                  <TableRow>
                     <TableCell>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontStyle: 'italic',
                          marginLeft: '30px',
                        }}
                      >
                        {' '}
                        {/*} {StatusesStats(Name)}{' '}*/}
                        Sostinės regionas ir visa Lietuva
                      </span>
                      </TableCell>
                    <TableCell>{formatNumberStats(MaxSum)}</TableCell>
                    <TableCell>{formatNumberStats(Rezerved)}</TableCell>
                    <TableCell>{formatNumberStats(Reminder)}</TableCell>
                  </TableRow>
                )
            )}
        </TableAdmin>
        <br />
        <br />

        <Link
          to="/backoffice/main"
          style={{
            color: '#fff',
            textDecoration: 'none',
          }}
        >
          <Button type="standart">Paraiškų sąrašas</Button>
        </Link>
      </div>
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  statsState: state.backofficeStatsState,
});

export default connect(mapStateToProps)(BackofficeStats);
