import React, { useEffect } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { WithSpinner } from '../../components';
// import styles from './home.module.scss';

const Home = ({loading}) => {
  useEffect(() => {
    setTimeout(function() {
      document.location.reload()
}, 2000);
    

  }, [loading]);

  return (
    <WithSpinner loading={true}>
      <div >
      </div>
    </WithSpinner>
  );
};

const mapStateToProps = state => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(Home);
