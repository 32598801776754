import React, { useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import styles from './globalConfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { config } from './../../api/config';
import { Api } from './../../api/api';

const RejectConditionsChangeRequest = ({
  open,
  data,
  loading,
  dispatch,
  requestId,
  comment,
}) => {
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [badFinancialCondition, setBadFinancialCondition] = useState(false);
  const cancel = () => {
    const open = {
      modalName: 'rejectConditionsChangeRequest',
      visible: false,
    };
    setShowError(false);
    setFileToUpload(null);
    setBadFinancialCondition(false);
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    const formData = new FormData();

    formData.append('RequestId', requestId);
    formData.append('Comment', comment);
    formData.append('File', fileToUpload);
    formData.append('BadFinancialCondition', badFinancialCondition);
    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.RejectChangeConditions, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.backoffice.getRequestAction(requestId));
          dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
          dispatch(
            actions.backoffice.saveEditAdditionalComments('get', requestId)
          );
          dispatch(actions.backoffice.getRequestLogAction(requestId));

          cancel();

          swal({
            title: 'Prašymas pakeisti sąlygas sėkmingai atmestas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          res.json().then((r) => {
            swal({
              title: `${
                r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
              }`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={isLoading}>
            <div className="modal-title2">
              Ar tikrai norite atmesti prašymą pakeisti sutarties sąlygas?
            </div>

            {/* <div style={{ width: '100%' }}>
              <h4 style={{ marginTop: '20px' }}>Komentaras</h4>
              <textarea
                style={{
                  width: '98%',
                  height: '80px',
                  marginBottom: '10px',
                  marginTop: '10px',
                  padding: '5px',
                  lineHeight: '1.2rem',
                  border: '1px solid #597a96',
                }}
                placeholder=""
                name={'InvegaReason'}
                type="string"
                onChange={onChangeContractCommentChange}
                value={comment}
              />
            </div> */}
            <label
              className="containerLabel noBlock"
              style={{ marginTop: '1rem', marginBottom: '2rem' }}
            >
              <input
                type="checkbox"
                name={'badFinancialCondition'}
                checked={badFinancialCondition}
                onChange={() => setBadFinancialCondition(!badFinancialCondition)}
              />
              <span className="checkmark"></span>
              <span className={styles.RedCheckboxTitle}>
              Prastėjanti finansinė būklė
              </span>
          </label>
            <br />

            <div className={styles.addFileContainer}>
              <Button type="standart" onClick={() => selectFile('fileElem')}>
                Prisegti priedą
              </Button>
              <input
                type="file"
                id="fileElem"
                //accept="application/pdf"
                className="d-none"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <p style={{ marginTop: '0.5rem' }}>
                {fileToUpload && fileToUpload.name ? fileToUpload.name : ''}
              </p>
            </div>

            <br />
            <br />
            <br />
            <div className={styles.btnContainer}>
              <div>
                <Button type="standart" onClick={() => yes(data)}>
                  {'Taip'}
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(RejectConditionsChangeRequest);
