import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './signContractModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';

import { sumValidation } from './../../utils/auth/validations';

const SignContractModal = ({ open, loading, dispatch, requestId }) => {
  const cancel = () => {
    const open = {
      modalName: 'signContractModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const signContract = () => {
    // sign
    dispatch(actions.dashboard.getSigningUrlAction(null, requestId, 'user'));
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <div className="modal-title">
              Ar tikrai norite pasirašyti dokumentą?
            </div>

            <div className={styles.btnContainer}>
              <div>
                <Button type="standart" onClick={() => signContract()}>
                  Pasirašyti
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(SignContractModal);
