import React from 'react';
import { connect } from 'react-redux';
import styles from './deleteComfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import { history } from './../../App';

const GoBackConfirmationModal = ({ open, id, loading, dispatch }) => {
  const forwardTo = (location) => {
    history.push(location);
    window.location.reload();
  };

  const closeModal = {
    modalName: 'goBackConfirmationModal',
    visible: false,
  };

  const cancel = () => {
    dispatch(actions.modals.setModalVisibleFlagAction(closeModal));
  };

  const yes = (id) => {
    dispatch(actions.modals.setModalVisibleFlagAction(closeModal));
    forwardTo('/main-window/requests');
  };
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <h2>
              Užpildyti paraiškos duomenys nėra išsaugoti. <br /> Ar tikrai
              norite grįžti atgal neužsaugojus įvestų duomenų?{' '}
            </h2>

            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(id)}>
                Taip
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(GoBackConfirmationModal);
