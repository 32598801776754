import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import { WithSpinner, TableAdmin, TableRow, TableCell } from '../../components';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import { Link } from 'react-router-dom';
import styles from './authorization.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPlusSquare,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { config } from '../../api/config';
import { Api } from './../../api/api';
import AutorizationModal from '../../modals/autorizationModal/autorizationModal';

const Authorization = ({
  representativesList,
  dispatch,
  loading,
  autorizationModalVisible,
  confirmModalVisible,
}) => {
  const tableHeader = [
    'Vardas',
    'Pavardė',
    'Asmens kodas',
    'Įgaliojimo pradžia',
    'Įgaliojimo pabaiga',
    'Dokumentai',
    'Veiksmai',
  ];

  const [modalData, setModalData] = useState({});
  const [modalCheckbox, setModalCheckbox] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalOkTitle, setModalOkTitle] = useState();
  const [modalTitle, setModalTitle] = useState();

  const actionClick = (representativeId) => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalText('Ar tikrai norite ištrinti?');
    setModalData({ representativeId });
    setModalAction('DELETE_REPRESENTATIVE');
    setModalCheckbox(false);
    setModalTitle('');

    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const addNewAutorization = () => {
    dispatch(
      actions.dashboard.setRepresentativeAction({
        RoleId: 1,
        FirstName: '',
        LastName: '',
        PersonalCode: '',
        ValidFrom: '',
        ValidTo: '',
      })
    );
    dispatch(actions.dashboard.setSaveRepresentativeError({}));
    const open = {
      modalName: 'autorizationModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const editAutorization = (id) => {
    dispatch(actions.dashboard.setSaveRepresentativeError({}));
    const row = representativesList.find((r) => r.Id === id);

    dispatch(actions.dashboard.setRepresentativeAction(row));

    const open = {
      modalName: 'autorizationModal',
      visible: true,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const downloadFile = (id, fileId) => {
    fetch(
      Api.API_BASE_URL +
        config.API_ENDPOINTS.downloadRepresentativeFile(id, fileId),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `Igaliojimas_Nr_${fileId}.pdf`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        // setIsloading(false);
      });
  };

  useEffect(() => {
    dispatch(actions.dashboard.loadRepresentatives());
  }, []);

  const roleName = (roleId) => {
    switch (roleId) {
      case 1:
        return 'Pareiškėjas';
      case 2:
        return 'Sąskaitų tvirtintojas';
      case 3:
        return 'Pareiškėjas ir sąskaitų tvirtintojas';
    }
  };

  return (
    <WithSpinner loading={loading}>
      <div style={{ width: '96%', marginLeft: '2%' }}>
        <h2>Įgaliojimai</h2>

        <TableAdmin header={tableHeader} isEmpty={false}>
          {representativesList != null &&
            representativesList.map(
              ({
                Id,
                FirstName,
                LastName,
                PersonalCode,
                ValidFrom,
                ValidTo,
                Files,
              }) => (
                <TableRow key={Id}>
                  <TableCell>{FirstName}</TableCell>
                  <TableCell>{LastName}</TableCell>
                  <TableCell>{PersonalCode}</TableCell>
                  <TableCell>{ValidFrom}</TableCell>
                  <TableCell>{ValidTo}</TableCell>
                  <TableCell>
                    {Files.map((f) => (
                      <div>
                        <Link
                          onClick={() => downloadFile(Id, f)}
                          style={{ color: '#009fe3', textDecoration: 'none' }}
                        >
                          Įgaliojimas Nr. {f}
                        </Link>
                      </div>
                    ))}
                  </TableCell>

                  <TableCell>
                    <div className={styles.iconsContainer}>
                      <Link onClick={() => editAutorization(Id)}>
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          className={styles.icon}
                          style={{
                            color: '#597a96',
                          }}
                        />
                      </Link>
                      <Link onClick={() => actionClick(Id)}>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={styles.icon}
                          style={{
                            color: '#597a96',
                          }}
                        />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
        </TableAdmin>

        <Link onClick={() => addNewAutorization()}>
          <FontAwesomeIcon
            size="4x"
            icon={faPlusSquare}
            className={styles.icon}
            style={{ float: 'right', width: '26px', color: '#033878' }}
          />
        </Link>
      </div>
      <AutorizationModal open={autorizationModalVisible} />
      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle={modalOkTitle}
        modalTitle={modalTitle}
        checkbox={modalCheckbox}
      />
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  representativesList: state.dashboardState.representativesList,
  loading: state.dashboardState.isOnSync,
  autorizationModalVisible: state.modalsState.autorizationModalVisible,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
});

export default connect(mapStateToProps)(Authorization);
