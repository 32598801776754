import Cookies from 'universal-cookie';
import { config, isProd, isLocalhost } from '../../api/config';

const storageHasProperty = (itemKey) => {
  return localStorage.hasOwnProperty(itemKey);
};

export const getLocalStorageItemIfNotExpired = (
  itemKey,
  itemSetupTimeKey,
  itemExpiry
) => {
  if (storageHasProperty(itemKey, itemSetupTimeKey)) {
    const hours = itemExpiry;
    const now = new Date().getTime();
    const setupTime = localStorage.getItem(itemSetupTimeKey);

    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.removeItem(itemKey);
      localStorage.removeItem(itemSetupTimeKey);
      return null;
    } else {
      return localStorage.getItem(itemKey);
    }
  } else {
    return getCookieValue(itemKey);
  }
};

export const getLocalStorageItem = (itemKey) => {
  if (storageHasProperty(itemKey)) {
    return localStorage.getItem(itemKey);
  }

  return getCookieValue(itemKey);
};

export const resetLocalStorageItem = (itemKey, itemValue) => {
  if (storageHasProperty(itemKey)) {
    localStorage.removeItem(itemKey);
  }

  localStorage.setItem(itemKey, itemValue);
};

export const removeLocalStorageItem = (itemKey) => {
  if (storageHasProperty(itemKey)) {
    localStorage.removeItem(itemKey);
  }
};

const cookiesHasProperty = (itemKey) => {
  const cookies = new Cookies();

  const allCookies = cookies.getAll();

  return allCookies.hasOwnProperty(itemKey);
};

export const getCookieValue = (itemKey) => {
  const cookies = new Cookies();
  if (cookiesHasProperty(itemKey)) {
    return cookies.get(itemKey);
  }

  return null;
};

export const resetCookieItem = (itemKey, itemValue) => {
  const cookies = new Cookies();

  if (storageHasProperty(itemKey)) {
    cookies.remove(itemKey);
  }
  cookies.set(itemKey, itemValue, {
    path: '/',
    domain: isProd()
      ? config.DOMAIN_URL_PROD
      : isLocalhost()
      ? config.DOMAIN_URL_LOCALHOST
      : config.DOMAIN_URL_DEV,
  });
};

export const removeCookieItem = (itemKey) => {
  const cookies = new Cookies();
  if (cookiesHasProperty(itemKey)) {
    cookies.remove(itemKey);
  }
};
