import { constants } from '../constants';

const INITIAL_STATE = {
  isOnSync: false,
  dashboardData: [],
  requestList: [],
  requestData: {},
  firstStepPassed: false,
  isAgreed: null,
  concentData: {},
  requestLimit: 0,
  generalData: {},
  canCreateRequest: false,
  requestGeneralLimit: 0,
  savedRequestDesissionAccepted: false,
  filesTableList: [],
  signInInfo: null,
  signingUrl: '',
  financialCriteriaResults: null,
  loginType: null,
  representativesList: null,
  representative: {},
  errors: [],
  paymentRequestsList: [],
  paymentRequest: [],
  paymentRequestPrDocument: [],
  isRepresentative: null,
};

const INOVICE_INITIAL_STATE = {
  invoiceData: {},
  saved: false,
  error: false,
};

const COMPANY_INITIAL_STATE = {
  id: '',
  saved: false,
  error: false,
  checking: false,
  decision: {},
};

const REQUEST_APPROVE_STATE = {
  saved: false,
  error: false,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.dashboard.SET_ON_SYNC_FLAG:
      return {
        ...state,
        isOnSync: action.flag,
      };

    case constants.dashboard.SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case constants.dashboard.LOAD_INVOICE_LIST_DATA:
      return {
        ...state,
        isOnSync: true,
        saved: false,
        error: false,
      };
    case constants.dashboard.LOAD_INVOICE_APPROVE_LIST_DATA:
      return {
        ...state,
        isOnSync: true,
      };
    case constants.dashboard.SET_INVOICE_LIST_DATA:
      return {
        ...state,
        dashboardData: action.payload,
        isOnSync: false,
      };
    case constants.dashboard.SET_REQUEST_LIMIT_DATA:
      return {
        ...state,
        requestLimit: action.payload,
      };
    case constants.dashboard.SET_REQUEST_GENERAL_LIMIT_DATA:
      return {
        ...state,
        requestGeneralLimit: action.payload,
      };
    case constants.dashboard.SET_FIRST_STEP_PASSED:
      return {
        ...state,
        firstStepPassed: action.payload,
      };
    case constants.dashboard.SET_REQUEST_LIST:
      return {
        ...state,
        requestList: action.payload,
      };
    case constants.dashboard.SET_NEW_REQUEST_DATA:
      return {
        ...state,
        requestData: action.payload,
      };
    case constants.dashboard.SET_REQUEST_VIEW_WINDOW:
      return {
        ...state,
        requestData: { ...state.requestData, type: 'view' },
      };

    case constants.dashboard.SET_IS_CONSENTAGREED:
      return {
        ...state,
        isAgreed: action.payload.isAgreed,
      };
    case constants.dashboard.SET_CONSENT_DATA:
      return {
        ...state,
        consentData: action.payload,
      };
    case constants.dashboard.SET_GENERAL_DATA:
      return {
        ...state,
        generalData: action.payload,
      };
    case constants.dashboard.SET_CAN_CREATE_REQUEST:
      return {
        ...state,
        canCreateRequest: action.payload,
      };

    case constants.dashboard.SET_SAVED_REQUEST_DESISSION_ACCEPTED:
      return {
        ...state,
        savedRequestDesissionAccepted: action.payload,
      };
    case constants.dashboard.SET_UPLOADED_FILES_LIST:
      return {
        ...state,
        filesTableList: action.payload,
      };
    case constants.dashboard.SET_SIGNING_IN_INFO:
      return {
        ...state,
        signInInfo: action.payload,
      };

    case constants.dashboard.SET_FINANCIALCRITERIA_RESULTS:
      return {
        ...state,
        financialCriteriaResults: action.payload,
      };

    case constants.dashboard.SET_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case constants.dashboard.CLEAR_USER_STATE:
      return {
        ...INITIAL_STATE,
      };
    case constants.dashboard.SET_REPRESENTATIVES:
    case constants.dashboard.SET_COMPANY_REPRESENTATIVES:
      return {
        ...state,
        representativesList: action.payload,
      };
    case constants.dashboard.SAVE_REPRESENTATIVE_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case constants.dashboard.SET_REPRESENTATIVE:
      return {
        ...state,
        representative: action.payload,
      };

    case constants.dashboard.IS_REPRESENTATIVE:
      return {
        ...state,
        isRepresentative: action.payload,
      };
    case constants.dashboard.SET_PAYMENT_REQUESTS_LIST:
      return {
        ...state,
        paymentRequestsList: action.payload,
      };
    case constants.dashboard.SET_PAYMENT_REQUEST:
      return {
        ...state,
        paymentRequest: action.payload,
      };
    case constants.dashboard.SET_PAYMENT_REQUEST_PR_DOCUMENT:
      return {
        ...state,
        paymentRequestPrDocument: action.payload,
      };

    default:
      return state;
  }
};

export const invoiceReducer = (state = INOVICE_INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.dashboard.SET_INVOICE_DATA:
    case constants.dashboard.SET_OWNER_INVOICE_DATA:
      return {
        ...state,
        invoiceData: action.payload,
      };
    case constants.dashboard.SAVE_INVOICE_DATA:
      return {
        ...state,
        invoiceData: {},
      };
    case constants.dashboard.INVOICE_SAVED:
      return {
        ...state,
        saved: action.payload,
        error: false,
      };
    case constants.dashboard.INVOICE_SAVED_EROR:
      return {
        ...state,
        error: action.payload.error.ErrorDescription,
        saved: false,
      };
    default:
      return state;
  }
};

export const companyReducer = (state = COMPANY_INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.dashboard.SET_COMPANY_DATA:
      return {
        ...state,
        id: action.payload.id,
        saved: action.payload.saved,
        checking: false,
        error: action.payload.error,
        decision: action.payload.decision,
      };
    default:
      return state;
  }
};

export const requestReducer = (state = REQUEST_APPROVE_STATE, action) => {
  switch (action.type) {
    case constants.dashboard.APPROVE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload.error?.ErrorDescription || '',
        saved: false,
      };
    case constants.dashboard.APPROVE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        saved: true,
      };
    case constants.dashboard.APPROVE_REQUEST_RESSET:
      return {
        ...state,
        error: false,
        saved: false,
      };
    default:
      return state;
  }
};
