import React, { useEffect, useState } from 'react';
import styles from './financialCriteriaModal.module.scss';
import { WithSpinner } from '../../components';
import { actions } from './../../state/actions';
import { connect } from 'react-redux';
import { criteriaValuep,criteriaValuep2 } from '../../utils/translation/criteria';

const FinancialCriteriaModal = ({
  open,
  data,
  dispatch,
  text,
  loading,
  finYear,
  formVersion,
}) => {
  const cancel = () => {
    const open = {
      modalName: 'financialCriteriaModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const [dataFiltered, setDataFiltered] = useState(null);
  useEffect(() => {
    if (data && data.Rules) {
      setDataFiltered(data.Rules);
    }
  }, [data]);

  return (
    open && (
      <WithSpinner loading={loading}>
        <div className={styles.container}>
          <div
            className={styles.modal}
            id="modal"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal__title"
            aria-describedby="modal__desc"
            style={{ minHeight: '300px' }}
          >
            <div className={styles.contentBlock}>
              <h2>Atitikimo finansavimui kriterijai: </h2>
              
              {formVersion !== 3 && (
              <div className={styles.criteriaContainer}>
                {dataFiltered != null && dataFiltered.length !== 0 ? (
                  dataFiltered.map((val, i) => (
                    <div
                      style={{
                        color: val.Decision === 'Denied' ? 'red' : 'green',
                      }}
                      className={styles.criteriaContainer}
                      key={i}
                    >
                      {val.RuleName === 'FinancialIndicators'
                        ? finYear === 2020
                          ? criteriaValuep('FinancialIndicators2020')
                          : criteriaValuep(val.RuleName)
                        : criteriaValuep(val.RuleName)}
                    </div>
                  ))
                ) : text != null &&
                  text.Decision != null &&
                  text.Rules != null ? (
                  text.Rules.map((val, i) => (
                    <div
                      style={{
                        color: val.Decision === 'Denied' ? 'red' : 'green',
                      }}
                      className={styles.criteriaContainer}
                    >
                      {val.RuleName === 'FinancialIndicators'
                        ? finYear === 2020
                          ? criteriaValuep('FinancialIndicators2020')
                          : criteriaValuep(val.RuleName)
                        : criteriaValuep(val.RuleName)}
                    </div>
                  ))
                ) : (
                  <h4>Patikra neatlikta</h4>
                )}
                {/* {text} */}
                {text != null &&
                  text.Decision === 'Denied' &&
                  text.Rules != null && (
                    <p
                      style={{
                        fontSize: '1.3rem',
                        marginBottom: '2rem',
                        color: 'red',
                      }}
                    >
                      Paraiška neatitinka keliamų kriterijų. Su sprendimu dėl
                      kriterijų neatitikimo galite susipažinti skiltyje
                      "Sutartys, sprendimai ir prašymai".
                    </p>
                  )}
              </div>)}

              {formVersion == 3 && (
              <div className={styles.criteriaContainer}>
                {dataFiltered != null && dataFiltered.length !== 0 ? (
                  dataFiltered.map((val, i) => (
                    <div
                      style={{
                        color: val.Decision === 'Denied' ? 'red' : 'green',
                      }}
                      className={styles.criteriaContainer}
                      key={i}
                    >
                      {val.RuleName === 'FinancialIndicators'
                        ? finYear === 2020
                          ? criteriaValuep2('FinancialIndicators2020')
                          : criteriaValuep2(val.RuleName)
                        : criteriaValuep2(val.RuleName)}
                    </div>
                  ))
                ) : text != null &&
                  text.Decision != null &&
                  text.Rules != null ? (
                  text.Rules.map((val, i) => (
                    <div
                      style={{
                        color: val.Decision === 'Denied' ? 'red' : 'green',
                      }}
                      className={styles.criteriaContainer}
                    >
                      {val.RuleName === 'FinancialIndicators'
                        ? finYear === 2020
                          ? criteriaValuep2('FinancialIndicators2020')
                          : criteriaValuep2(val.RuleName)
                        : criteriaValuep2(val.RuleName)}
                    </div>
                  ))
                ) : (
                  <h4>Patikra neatlikta</h4>
                )}
                 {data.Rules.length == 0 && (
                  <p
                    style={{
                      fontSize: '1.3rem',
                      marginBottom: '2rem',
                    }}
                  >
                    Fiziniams asmenims finansavimo kriterijai tikrinami teikiant
                    paraišką vertinimui.
                  </p>
                )}
                {/* {text} */}
                {text != null &&
                  text.Decision === 'Denied' &&
                  text.Rules != null && (
                    <p
                      style={{
                        fontSize: '1.3rem',
                        marginBottom: '2rem',
                        color: 'red',
                      }}
                    >
                      Paraiška neatitinka keliamų kriterijų. Su sprendimu dėl
                      kriterijų neatitikimo galite susipažinti skiltyje
                      "Sutartys, sprendimai ir prašymai".
                    </p>
                  )}
              </div>)}
              <div className={styles.btnContainer}>
                <button
                  type="standart"
                  onClick={() => cancel()}
                  className="vaiisisloginbtn"
                  style={{ marginTop: '0rem' }}
                >
                  Gerai
                </button>
              </div>
            </div>
          </div>
        </div>
      </WithSpinner>
    )
  );
};

const mapStateToProps = (state) => ({
  text: state.modalsState.msgText,
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(FinancialCriteriaModal);
