import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { actions } from '../../../state/actions';
import { connect } from 'react-redux';
import { Api } from './../../../api/api';
import { config } from './../../../api/config';
import { format } from 'date-fns';

import swal from 'sweetalert';

import {
  WithSpinner,
  InputWithTextInline,
  Button,
  TableAdmin,
  TableRow,
  TableCell,
} from '../../../components';

const AdditionalRequests = ({ loading }) => {
  const [notValid, setNotValid] = useState(false);
  const [companyCode, setCompanyCode] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [list, setList] = useState([]);

  const tableHeader = [
    'Įmonės kodas',
    'Įmonės pavadinimas',
    'Vadovas',
    'Vadovauja nuo',
    'Užklausos data',
    'Atsakymo data',
    'Užklausą vykdė',
  ];

  const renewList = () => {
    setIsloading(true);

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.GetJarManagerChecks, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok === true) {
          console.log(res);
          res.json().then((r) => {
            setList(r);
          });
        } else {
          swal({
            title: 'Klaida',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });

    setNotValid(false);
  };

  const check = () => {
    setNotValid(true);
    if (companyCode.length === 9) {
      setIsloading(true);

      fetch(
        Api.API_BASE_URL + config.API_ENDPOINTS.RecheckJarManager(companyCode),
        {
          method: 'POST',
          credentials: 'include',
        }
      )
        .then((res) => {
          if (res.ok === true) {
            renewList();
            swal({
              title: 'Papildoma JAR užklausa sėkmingai įvykdyta',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else {
            swal({
              title: 'Užklausa į JAR buvo nesėkminga',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          }
        })
        .catch((err) => {
          console.log('catch block');
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
        });

      setNotValid(false);
    }
  };
  useEffect(() => {
    renewList();
  }, []);
  return (
    <WithSpinner loading={isLoading}>
      <div style={{ width: '96%', marginLeft: '2%', marginTop: '2rem' }}>
        <h2>Papildomos JAR užklausos</h2>

        <Button type="standart">
          <Link
            to="/backoffice/main"
            style={{
              color: '#fff',
              textDecoration: 'none',
            }}
          >
            Atgal
          </Link>
        </Button>

        <div
          className="col-lg-6 col-sm-12"
          style={{
            display: 'flex',
            justifyContext: 'spacebetween',
            width: '500px',
            marginTop: '30px',
            padding: 0,
          }}
        >
          <div style={{ width: '151%' }}>
            <InputWithTextInline
              name={'LastName'}
              type="string"
              onChange={(e) => setCompanyCode(e.target.value)}
              value={companyCode}
              placeholder={'Įmonės kodas'}
              disabled={false}
            />
            {notValid && companyCode.length !== 9 && (
              <p
                className="errorsBlockText error1"
                style={{ color: 'var(--pinkDark)', width: '100%' }}
              >
                * įmonės kodas turi būti sudarytas iš 9 skaitmenų
              </p>
            )}
          </div>
          <button
            type="longBtn"
            className="vaiisisloginbtn"
            style={{
              width: '500px',
              marginTop: '35px',
              height: '40px',
              marginLeft: '10px',
            }}
            onClick={() => check()}
          >
            Papildoma JAR patikra
          </button>
        </div>
        <div style={{ marginTop: '40px' }}>
          <h4>Užklausų į JAR sąrašas</h4>
          <TableAdmin header={tableHeader} isEmpty={false}>
            {list.map(
              ({
                Id,
                ResponseCreatedAt,
                CompanyCode,
                CompanyName,
                ManagerName,
                AppointedAt,
                CreatedAt,
                UserName,
              }) => (
                <TableRow key={Id}>
                  <TableCell>{CompanyCode}</TableCell>
                  <TableCell>{CompanyName}</TableCell>
                  <TableCell>{ManagerName}</TableCell>
                  <TableCell>
                    {AppointedAt !== null &&
                      format(new Date(AppointedAt), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>
                    {CreatedAt !== null &&
                      format(new Date(CreatedAt), 'yyyy-MM-dd HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {ResponseCreatedAt !== null &&
                      format(
                        new Date(ResponseCreatedAt),
                        'yyyy-MM-dd HH:mm:ss'
                      )}
                  </TableCell>
                  <TableCell>{UserName}</TableCell>
                </TableRow>
              )
            )}
          </TableAdmin>
        </div>
        {/* </div> */}
      </div>
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(AdditionalRequests);
