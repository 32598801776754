import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styles from '../newRequest/newRequest.module.scss';
import { actions } from '../../state/actions';
import { WithSpinner, Button } from '../../components';
import InputWithTextInline from '../../components/input/InputWithTextInline';
import PaymentDocumentFileUploadContainer from '../../containers/fileUploadContainer/paymentDocumentFileUploadContainer';
import {
  isNotEmptyString,
  isValidIban,
  isValidDropdown,
  isValidDateTillToday,
  isSumValid,
  minimum2Symbols,
} from '../../utils/helpers/validators';

import { checkBOPage, formatNumber } from '../../utils/helpers/helpers';
import { format } from 'date-fns';
import lt from 'date-fns/locale/lt';
import GlobalConfirmationModal from '../../modals/globalConfirmationModal/globalConfirmationModal';
import { config } from '../../api/config';

const PaymentDocument = ({
  dispatch,
  loading,
  requestState,
  paymentRequest,
  paymentRequestPrDocument,
  confirmModalVisible,
  closedDialogOKMessage,
}) => {
  registerLocale('lt', lt);
  const history = useHistory();
  const [triedSave, setTriedSave] = useState(false);
  const url = new URL(window.location.href);
  const [actionType, setActionType] = useState(
    url.searchParams.get('actionType')
  );

  const checkBOEditPage = () => {
    return checkBOPage() &&
      paymentRequest?.ContractInfo?.PaymentRequestStatusId === 5 &&
      actionType === 'edit'
      ? true
      : false;
  };
  const getInputEditStatus = (boCanEdit) => {
    if (checkBOEditPage() && boCanEdit) {
      return false;
    }
    if (
      (paymentRequest?.ContractInfo?.PaymentRequestStatusId === 1 ||
        paymentRequest?.ContractInfo?.PaymentRequestStatusId === 3) &&
      (!paymentRequestPrDocument?.IsApproved || !paymentRequestPrDocument.Id)
    ) {
      if (actionType === 'view') {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const getSupplierIsCompanyInputEditStatus = () => {
    if (checkBOEditPage()) {
      return true;
    }
    if (!paymentRequestPrDocument.Id) {
      return false;
    }
    return true;
  };

  const [boCanEditInfoValues, setBOCanEditInfoValues] = useState({});

  const [loanPurpose, setLoanPurpose] = useState(1);
  const [documentSum, setDocumentSum] = useState(0);
  const [investmentSumLeft, setInvestmentSumLeft] = useState(0);
  const [turnoverSumLeft, setTurnoverSumLeft] = useState(0);
  const [investmentSum, setInvestmentSum] = useState('');
  const [turnoverSum, setTurnoverSum] = useState('');
  const [managerInfo, setManagerInfo] = useState({});

  const getValue = (value, name) => {
    if (checkBOEditPage()) {
      return value
        ? convertStrValueToNumber(value)
        : boCanEditInfoValues[name]
        ? convertStrValueToNumber(boCanEditInfoValues[name])
        : 0;
    } else {
      return value || value === 0 ? convertStrValueToNumber(value) : '';
    }
  };
  const checkCalculatedSums = () => {
    const turnoverSumNumber = getValue(turnoverSum, 'TurnoverSum');
    const investmentSumNumber = getValue(investmentSum, 'InvestmentSum');
    const documentSumNumber = getValue(documentSum, 'DocumentSum');
    const currInvestmentSum = paymentRequestPrDocument?.InvestmentSum || 0;
    const currTurnoverSum = paymentRequestPrDocument?.TurnoverSum || 0;

    if (
      Number(turnoverSumNumber) + Number(investmentSumNumber) >
      Number(documentSumNumber)
    ) {
      return loanPurpose === 1
        ? `Įvestų laukų "Investicinė, EUR" ir "Apyvartinė, EUR" bendra suma negali viršyti lauko "Dokumento suma, EUR" ${formatNumber(
            +documentSumNumber
          )} EUR sumos`
        : `Įvesta suma negali viršyti lauko "Dokumento suma, EUR" ${formatNumber(
            +documentSumNumber
          )} EUR sumos`;
    }
    if (
      Number(turnoverSumNumber) + Number(investmentSumNumber) >
      Number(FinancialInfo.LoanSumRemainder) +
        Number(currInvestmentSum) +
        Number(currTurnoverSum)
    ) {
      return loanPurpose === 1
        ? `Įvestų laukų "Investicinė, EUR" ir "Apyvartinė, EUR" bendra suma negali viršyti paskolos sumos likučio ${formatNumber(
            FinancialInfo.LoanSumRemainder +
              Number(currInvestmentSum) +
              Number(currTurnoverSum)
          )} EUR`
        : `Įvesta suma negali viršyti lauko paskolos sumos likučio ${formatNumber(
            Number(FinancialInfo.LoanSumRemainder) +
              Number(currInvestmentSum) +
              Number(currTurnoverSum)
          )} EUR`;
    }
    return '';
  };

  const convertStrValueToNumber = (value) => {
    value = value?.toString().replace(/\s/gi, '');
    value = value?.toString().replace(/,/gi, '.');
    return value;
  };

  const checkTurnoverSum = () => {
    const turnoverSumNumber =
      turnoverSum || turnoverSum === 0
        ? convertStrValueToNumber(turnoverSum)
        : '';
    const sumValid = isSumValid(turnoverSumNumber);
    if (sumValid) {
      return sumValid;
    }
    if (turnoverSum > turnoverSumLeft) {
      return `Maksimali galima įvesti paskolos suma skirta apyvartai gali būti ${formatNumber(
        turnoverSumLeft
      )} EUR`;
    }
    return checkCalculatedSums();
  };

  const checkInvestmentSum = () => {
    const investmentSumNumber =
      investmentSum || investmentSum === 0
        ? convertStrValueToNumber(investmentSum)
        : '';
    const sumValid = isSumValid(investmentSumNumber);
    if (sumValid) {
      return sumValid;
    }
    if (investmentSum > investmentSumLeft) {
      return `Maksimali galima įvesti paskolos suma skirta investicijoms gali būti ${formatNumber(
        investmentSumLeft
      )} EUR`;
    }
    return checkCalculatedSums();
  };

  const checkFundsSum = (nr) => {
    if (
      !checkBOPage() &&
      documentInfoValues[`FundsOriginTypeId${nr}`] &&
      !documentInfoValues[`FundsSum${nr}`]
    ) {
      return 'Neįvesta suma. Formatas 0.00 EUR';
    }

    if (
      checkBOPage() &&
      !documentInfoValues[`FundsSum${nr}`] &&
      !boCanEditInfoValues[`FundsSum${nr}`] &&
      (documentInfoValues[`FundsOriginTypeId${nr}`] ||
        boCanEditInfoValues[`FundsOriginTypeId${nr}`])
    ) {
      return 'Neįvesta suma. Formatas 0.00 EUR';
    }

    return null;
  };

  const checkFundsDropdown = (nr) => {
    if (
      checkBOPage() &&
      documentInfoValues[`FundsOriginTypeId${nr}`] &&
      !documentInfoValues[`FundsOriginTypeId${+nr === 1 ? 2 : 1}`] &&
      +documentInfoValues[`FundsOriginTypeId${nr}`] ===
        +boCanEditInfoValues[`FundsOriginTypeId${+nr === 1 ? 2 : 1}`]
    ) {
      return 'Lėšų kilmė negali sutapti';
    }
    if (
      documentInfoValues.FundsOriginTypeId1 &&
      documentInfoValues.FundsOriginTypeId2 &&
      +documentInfoValues.FundsOriginTypeId1 ===
        +documentInfoValues.FundsOriginTypeId2
    ) {
      return 'Lėšų kilmė negali sutapti';
    }
    if (
      !checkBOPage() &&
      documentInfoValues[`FundsSum${nr}`] &&
      !documentInfoValues[`FundsOriginTypeId${nr}`]
    ) {
      return 'Nepasirinkta lėšų kilmė';
    }

    if (
      checkBOPage() &&
      !documentInfoValues[`FundsOriginTypeId${nr}`] &&
      !boCanEditInfoValues[`FundsOriginTypeId${nr}`] &&
      (documentInfoValues[`FundsSum${nr}`] ||
        boCanEditInfoValues[`FundsSum${nr}`])
    ) {
      return 'Nepasirinkta lėšų kilmė';
    }

    return null;
  };

  const { ContractInfo, FinancialInfo, Remainders } = paymentRequest;

  const supplierTypeDropdown = {
    options: [
      { name: ' ', value: '', type: 'boolean' },
      { name: 'Įmonė', value: true, type: 'boolean' },
      { name: 'Fizinis asmuo', value: false, type: 'boolean' },
    ],
  };

  const documentTypeDropdown = {
    options: [
      { name: ' ', value: '', type: 'boolean' },
      {
        name: 'Sąskaita faktūra arba PVM sąskaita faktūra',
        value: 1,
        type: 'number',
      },
      { name: 'Darbo užmokesčio žiniaraštis', value: 2, type: 'number' },
    ],
  };

  const fundsOriginTypeDropdown = {
    options: [
      { name: ' ', value: '', type: 'boolean' },
      {
        name: 'Nuosavos lėšos',
        value: 1,
        type: 'number',
      },
      { name: 'Skolintos lėšos', value: 2, type: 'number' },
    ],
  };

  const getDropdownNameByValue = (dropdown, value) => {
    return dropdown.find((el) => el.value === value)?.name || '';
  };

  const updateNumberValueOnBlur = (inputValue, name) => {
    if (inputValue !== '' && inputValue !== null) {
      const value = convertStrValueToNumber(inputValue);
      if (!isNaN(value)) {
        setDocumentInfoValues({
          ...documentInfoValues,
          [name]: formatNumber(formatNumber(+value)),
        });
      }
    }
  };

  const checkSupplierCode = () => {
    const value = documentInfoValues.SupplierCode;
    const emptyStr = isNotEmptyString(value);
    if (emptyStr) {
      return emptyStr;
    }
    if (documentInfoValues.SupplierIsCompany === 'true' && value.length > 9) {
      return 'Per ilgas įmonės kodas';
    }
    return '';
  };

  const documentInfo = [
    /*{
      title: 'Eil. Nr.',
      value: '',
      type: 'string',
      disabled: true,
      fieldType: 'input',
      name: 'Number',
    },*/
    {
      title: 'Tiekėjo tipas',
      value: '',
      type: 'string',
      disabled: getSupplierIsCompanyInputEditStatus(),
      fieldType: 'dropdown',
      dropdownInfo: supplierTypeDropdown,
      name: 'SupplierIsCompany',
      required: true,
      requiredType: 'dropdown',
      defaultErrorMessage: 'Turite pasirinkti tiekėjo tipą',
    },
    {
      title: 'Tiekėjo pavadinimas/Vardas pavardė',
      value: '',
      type: 'string',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'SupplierName',
      required: true,
      requiredType: 'anyString',
      boCanEdit: true,
    },
    {
      title: 'Tiekėjo įmonės kodas/Tiekėjo IV pažymėjimo arba VL Nr.',
      value: '',
      type: 'string',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'SupplierCode',
      required: true,
      requiredType: 'custom',
      customFunction: checkSupplierCode,
      boCanEdit: true,
    },
    {
      title: 'Tiekėjo banko sąskaitos numeris',
      value: '',
      type: 'string',
      maxlength: 20,
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'Iban',
      required: true,
      requiredType: 'iban',
      boCanEdit: true,
    },
    {
      title: 'Dokumento tipas',
      value: '',
      type: 'string',
      disabled: getInputEditStatus(true),
      fieldType: 'dropdown',
      dropdownInfo: documentTypeDropdown,
      name: 'DocumentType',
      required: true,
      requiredType: 'dropdown',
      defaultErrorMessage: 'Turite pasirinkti dokumento tipą',
      boCanEdit: true,
    },
    {
      title: 'Dokumento numeris',
      value: '',
      type: 'string',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'DocumentNumber',
      required: true,
      requiredType: 'minimum2Symbols',
      boCanEdit: true,
    },
    {
      title: 'Dokumento data',
      value: '',
      type: 'date',
      disabled: getInputEditStatus(true),
      fieldType: 'date',
      name: 'DocumentDate',
      required: true,
      requiredType: 'dateTillToday',
      boCanEdit: true,
    },
    {
      title: 'Dokumento suma, EUR',
      value: null,
      type: 'numberString',
      disabled: getInputEditStatus(),
      fieldType: 'input',
      name: 'DocumentSum',
      required: true,
      requiredType: 'numberSum',
      boCanEdit: false,
      onblur: updateNumberValueOnBlur,
    },
    {
      title: 'Investicinė, EUR',
      value: null,
      type: 'numberString',
      disabled: loanPurpose === 2 ? true : getInputEditStatus(true),
      fieldType: 'input',
      name: 'InvestmentSum',
      required: loanPurpose === 2 ? false : true,
      requiredType: 'custom',
      customFunction: checkInvestmentSum,
      boCanEdit: loanPurpose === 2 ? false : true,
      onblur: updateNumberValueOnBlur,
    },
    {
      title: 'Apyvartinė, EUR',
      value: null,
      type: 'numberString',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'TurnoverSum',
      required: true,
      requiredType: 'custom',
      customFunction: checkTurnoverSum,
      boCanEdit: true,
      onblur: updateNumberValueOnBlur,
    },
    {
      title: 'INVEGA teikiamos paskolos lėšomis finansuojama suma, EUR',
      value: null,
      type: 'numberString',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'RequiredSum',
    },
    {
      title: 'Išmokėta paskolos suma pagal paskolos tipą proc.:',
      value: '',
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'PaidOutTotalSumPercent',
      additionalSymbols: '%',
      showWhenStatus: [2],
      minFractionDigits: 4,
    },
    {
      title: 'Investicijoms',
      value: '',
      type: 'numberString',
      additionalSymbols: '%',
      disabled: true,
      fieldType: 'input',
      name: 'FundedInvestmentSumPercent',
      showWhenStatus: [2],
      minFractionDigits: 4,
    },
    {
      title: 'Apyvartai',
      value: '',
      type: 'numberString',
      additionalSymbols: '%',
      disabled: true,
      fieldType: 'input',
      name: 'FundedTurnoverSumPercent',
      showWhenStatus: [2],
      minFractionDigits: 4,
    },
    {
      title: 'Finansuojama suma, EUR',
      value: null,
      type: 'numberString',
      disabled: true,
      fieldType: 'input',
      name: 'FundedSum',
      showWhenStatus: [2],
    },
    {
      title: 'Pastabos',
      value: '',
      type: 'string',
      disabled: getInputEditStatus(false),
      fieldType: 'input',
      name: 'Comment',
    },
    // {
    //   title: 'Lėšų kilmė',
    //   value: '',
    //   type: 'string',
    //   disabled: getInputEditStatus(true),
    //   fieldType: 'dropdown',
    //   dropdownInfo: fundsOriginTypeDropdown,
    //   name: 'FundsOriginTypeId1',
    //   required: true,
    //   requiredType: 'custom',
    //   customFunction: () => checkFundsDropdown('1'),
    //   defaultErrorMessage: 'Turite pasirinkti dokumento tipą',
    //   boCanEdit: true,
    //   checkConnectedValues: true,
    // },
    {
      title: 'Kliento nuosavomis lėšomis finansuojama projekto dalis, EUR.',
      value: '',
      type: 'numberString',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'FundsSum1',
      required: true,
      requiredType: 'numberSum',
      customFunction: () => checkFundsSum('1'),
      onblur: updateNumberValueOnBlur,
      boCanEdit: true,
      checkConnectedValues: true,
    },
    // {
    //   title: 'Lėšų kilmė',
    //   value: '',
    //   type: 'string',
    //   disabled: getInputEditStatus(true),
    //   fieldType: 'dropdown',
    //   dropdownInfo: fundsOriginTypeDropdown,
    //   name: 'FundsOriginTypeId2',
    //   required: true,
    //   requiredType: 'custom',
    //   customFunction: () => checkFundsDropdown('2'),
    //   defaultErrorMessage: 'Turite pasirinkti dokumento tipą',
    //   boCanEdit: true,
    //   checkConnectedValues: true,
    // },
    {
      title:
        'Privataus finansuotojo (suteiktos paskolos) dalyvavimas projekte, EUR',
      value: '',
      type: 'numberString',
      disabled: getInputEditStatus(true),
      fieldType: 'input',
      name: 'FundsSum2',
      required: true,
      requiredType: 'numberSum',
      customFunction: () => checkFundsSum('2'),
      onblur: updateNumberValueOnBlur,
      boCanEdit: true,
      checkConnectedValues: true,
    },
  ];

  const k = {};
  documentInfo.forEach((el) => {
    k[el.name] = el.value;
  });
  const [documentInfoValues, setDocumentInfoValues] = useState(k);

  const setValueChanged = (value, name) => {
    if (saved || error) {
      dispatch(actions.dashboard.approveRequestResset(true));
    }
    if (!isUnsavedChanges) {
      setIsUnsavedChanges(true);
    }
    setDocumentInfoValues({ ...documentInfoValues, [name]: value });
  };

  const ValidateInput = (
    value,
    type,
    defaultMessage,
    minValue,
    maxValue,
    customFunction,
    checkConnectedValues
  ) => {
    if (
      !checkBOPage() ||
      (checkBOPage() && value) ||
      (checkBOPage() && checkConnectedValues)
    ) {
      if (type === 'anyString') {
        return isNotEmptyString(value);
      } else if (type === 'iban') {
        return isValidIban(value);
      } else if (type === 'dropdown') {
        return isValidDropdown(value, defaultMessage);
      } else if (type === 'dateTillToday') {
        return isValidDateTillToday(value);
      } else if (type === 'numberSum') {
        return isSumValid(convertStrValueToNumber(value), minValue, maxValue);
      } else if (type === 'custom') {
        return customFunction();
      } else if (type === 'minimum2Symbols') {
        return minimum2Symbols(value);
      }
    }
    return '';
  };

  const ValidateInputs = () => {
    let allValids = true;
    documentInfo
      .filter((el) => el.required)
      .forEach((item) => {
        const error = ValidateInput(
          documentInfoValues[item.name],
          item.requiredType,
          item.defaultErrorMessage,
          item.minValue,
          item.maxValue,
          item.customFunction,
          item.checkConnectedValues,
          item.minimum2Symbols
        );
        if (error) {
          allValids = false;
        }
      });
    return allValids;
  };

  function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  const save = (action) => {
    if (!ValidateInputs()) {
      setTriedSave(true);
      return;
    }
    setTriedSave(false);
    const currentDate = new Date();
    let payload = {};
    if (checkBOPage()) {
      for (let name in boCanEditInfoValues) {
        payload[lowerCaseFirstLetter(name)] =
          documentInfo.find(
            (el) => el.name === name && el.type === 'numberString'
          ) && documentInfoValues[name]
            ? convertStrValueToNumber(documentInfoValues[name])
            : documentInfoValues[name] || '';
      }
      payload.prDocumentId = documentInfoValues.Id;
      payload.comment = documentInfoValues.ManagerComment || '';
      payload.supplierIsCompany = documentInfoValues.SupplierIsCompany;
      payload.FundsOriginTypeId1 = 1;
      payload.FundsOriginTypeId2 = 2;
    } else {
      payload = {
        supplierIsCompany: documentInfoValues.SupplierIsCompany,
        supplierName: documentInfoValues.SupplierName,
        supplierCode: documentInfoValues.SupplierCode,
        iban: documentInfoValues.Iban,
        documentType: documentInfoValues.DocumentType,
        documentNumber: documentInfoValues.DocumentNumber,
        documentDate: documentInfoValues.DocumentDate,
        documentSum: convertStrValueToNumber(documentInfoValues.DocumentSum),
        investmentSum: convertStrValueToNumber(
          documentInfoValues.InvestmentSum
        ),
        turnoverSum: convertStrValueToNumber(documentInfoValues.TurnoverSum),
        requiredSum: convertStrValueToNumber(documentInfoValues.RequiredSum),
        comment: documentInfoValues.Comment,
        FundsOriginTypeId1: 1,
        FundsSum1: convertStrValueToNumber(documentInfoValues.FundsSum1),
        FundsOriginTypeId2: 2,
        FundsSum2: convertStrValueToNumber(documentInfoValues.FundsSum2),
        investmentSumPercent: 90,
        turnoverSumPercent: 10,
        created: currentDate.toJSON(),
      };
      if (documentInfoValues.Id) {
        payload.Id = documentInfoValues.Id;
      }
    }

    const id = ContractInfo.Id;
    dispatch(
      actions.dashboard.createPaymentRequestNewPrDocumentAction(id, payload)
    );
  };

  const onDropdownInputChange = ({ target: { name, value } }) => {
    setIsUnsavedChanges(true);
    setDocumentInfoValues({ ...documentInfoValues, [name]: value });
  };

  const checkBOCanEdit = (name) => {
    return documentInfo.find((el) => el.boCanEdit && el.name === name);
  };

  const RecalculateRequiredSum = () => {
    let managerInvestmentSum =
      paymentRequestPrDocument?.ManagerInfo?.InvestmentSum;
    let managerTurnoverSum = paymentRequestPrDocument?.ManagerInfo?.TurnoverSum;
    if (managerInvestmentSum !== null || managerTurnoverSum !== null) {
      if (managerInvestmentSum === null) {
        managerInvestmentSum = paymentRequestPrDocument?.InvestmentSum;
      }
      if (managerTurnoverSum === null) {
        managerTurnoverSum = paymentRequestPrDocument?.TurnoverSum;
      }
    }

    paymentRequestPrDocument.RequiredSum = Number(
      Number(managerTurnoverSum) + Number(managerInvestmentSum)
    );
  };
  useEffect(() => {
    if (checkBOEditPage()) {
      if (paymentRequestPrDocument?.ManagerInfo) {
        RecalculateRequiredSum();
      }
      const boCantEditFields = {};
      const boCanEdit = {};
      for (let name in paymentRequestPrDocument) {
        if (checkBOCanEdit(name)) {
          boCanEdit[name] = paymentRequestPrDocument[name];
          const managerValue = paymentRequestPrDocument?.ManagerInfo?.[name];
          if (managerValue || managerValue === 0) {
            boCantEditFields[name] = managerValue;
          } else if (documentInfoValues[name]) {
            boCantEditFields[name] = null;
          }
        } else {
          boCantEditFields[name] = paymentRequestPrDocument[name];
        }
      }
      boCantEditFields.ManagerComment =
        paymentRequestPrDocument?.ManagerComment || '';
      setDocumentInfoValues(boCantEditFields);
      setBOCanEditInfoValues(boCanEdit);
    } else {
      if (checkBOPage()) {
        paymentRequestPrDocument.ManagerComment =
          paymentRequestPrDocument?.ManagerComment || '';
      }
      setDocumentInfoValues(paymentRequestPrDocument);
    }
    setManagerInfo(paymentRequestPrDocument.ManagerInfo);
  }, [paymentRequestPrDocument, actionType]);

  useEffect(() => {
    const InvestmentSumNum = getValue(
      documentInfoValues.InvestmentSum,
      'InvestmentSum'
    );
    const TurnoverSumNum = getValue(
      documentInfoValues.TurnoverSum,
      'TurnoverSum'
    );

    setDocumentInfoValues({
      ...documentInfoValues,
      RequiredSum: formatNumber(
        Number(InvestmentSumNum) + Number(TurnoverSumNum)
      ),
    });
    setInvestmentSum(InvestmentSumNum);
    setTurnoverSum(TurnoverSumNum);
  }, [documentInfoValues.InvestmentSum, documentInfoValues.TurnoverSum]);

  useEffect(() => {
    setDocumentSum(documentInfoValues.DocumentSum);
  }, [documentInfoValues.DocumentSum]);

  useEffect(() => {
    setDocumentInfoValues({
      ...documentInfoValues,
      PaidOutTotalSumPercent:
        documentInfoValues.FundedInvestmentSumPercent +
        documentInfoValues.FundedTurnoverSumPercent,
    });
  }, [
    documentInfoValues.FundedInvestmentSumPercent,
    documentInfoValues.FundedTurnoverSumPercent,
  ]);

  const getSumValue = (name) => {
    if (checkBOEditPage()) {
      return (paymentRequestPrDocument?.ManagerInfo?.[name] &&
        paymentRequestPrDocument?.ManagerInfo?.[name] !== null) ||
        paymentRequestPrDocument?.ManagerInfo?.[name] === 0
        ? paymentRequestPrDocument?.ManagerInfo?.[name]
        : paymentRequestPrDocument?.[name] || 0;
    } else {
      return paymentRequestPrDocument?.[name] || 0;
    }
  };

  useEffect(() => {
    const currInvestmentSum = getSumValue('InvestmentSum');
    const currTurnoverSum = getSumValue('TurnoverSum');
    setLoanPurpose(FinancialInfo?.LoanPurposeId || 1);
    if (FinancialInfo?.LoanPurposeId === 2) {
      setDocumentInfoValues({ ...documentInfoValues, InvestmentSum: 0 });
      setTurnoverSumLeft(
        Number(FinancialInfo.LoanSumRemainder) +
          Number(currTurnoverSum) +
          Number(currInvestmentSum)
      );
    }

    if (FinancialInfo?.LoanPurposeId === 1) {
      setTurnoverSumLeft(
        Number(Remainders.TurnoverRemainder) + Number(currTurnoverSum)
      );
      setInvestmentSumLeft(
        Number(Remainders.InvestmentRemainder) + Number(currInvestmentSum)
      );
    }
  }, [FinancialInfo, documentInfoValues.Id]);

  const dropdownItemSelected = (value, type, name) => {
    if (type === 'boolean' && typeof value === 'boolean') {
      const valStr = value.toString();
      const infStr = documentInfoValues[name].toString();
      const selected = valStr && infStr && valStr === infStr ? true : false;
      return selected;
    } else if (value && type === 'number') {
      return Number(value) === Number(documentInfoValues[name]);
    }
  };

  const backToPaymentRequest = () => {
    window.location.href = `${
      checkBOPage()
        ? config.APP_PATHS.backofficePaymentRequest
        : config.APP_PATHS.paymentrequest
    }?requestId=${paymentRequest.requestId}&paymentRequestId=${
      ContractInfo?.Id
    }&actionType=${paymentRequest.actionType || 'view'}`;
    // history.goBack();
  };

  useEffect(() => {
    if (closedDialogOKMessage === 'closeIfNotAllSaved') {
      const payload = {
        message: '',
      };
      dispatch(actions.modals.setModalClosedOKAction(payload));
      dispatch(actions.dashboard.approveRequestResset(true));
      backToPaymentRequest();
    }
  }, [closedDialogOKMessage]);

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  useEffect(() => {
    setSaved(requestState.saved);
    setError(requestState.error);
    if (requestState.saved) {
      setIsUnsavedChanges(false);
      if (checkBOPage()) {
        dispatch(
          actions.dashboard.createNewPaymentRequestPrDocumentAction(
            ContractInfo?.Id,
            paymentRequestPrDocument.Id,
            'view',
            documentInfoValues.Number || ''
          )
        );
        setActionType('view');
      }
    }
  }, [requestState]);

  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalMainText, setModalMainText] = useState('');

  const [comments, setComments] = useState('');

  useEffect(() => {
    if (!paymentRequest?.ContractInfo) {
      // page reloaded. lost state -> back to prev page
      // backToPaymentRequest();
      history.goBack();
    }
    dispatch(actions.dashboard.approveRequestResset(true));
  }, []);

  const displayNotSavedMessage = () => {
    const open = {
      modalName: 'globalConfirmationModal',
      visible: true,
    };

    setModalMainText(null);
    setModalTitle(null);

    setModalText(
      'Ar tikrai norite grįžti į mokėjimų prašymų sąrašą. Ne visi pakeitimai yra išsaugoti.'
    );
    setModalData({ comments: 'closeIfNotAllSaved' });
    setModalAction('YES_NO_ACTION');
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const cancel = () => {
    if (isUnsavedChanges) {
      displayNotSavedMessage();
      return;
    }
    dispatch(actions.dashboard.approveRequestResset(true));
    backToPaymentRequest();
  };

  const createDateAsUTC = (date) => {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  };

  const checkVisibility = (statusArr) => {
    if (!statusArr) {
      return true;
    }
    if (
      paymentRequest?.ContractInfo?.PaymentRequestStatusId &&
      statusArr.includes(paymentRequest.ContractInfo?.PaymentRequestStatusId)
    ) {
      return true;
    }
    return false;
  };

  const saveChangesAfterFileUpload = (action) => {
    if (action === 'validateInputs') {
      if (!ValidateInputs()) {
        setTriedSave(true);
        return false;
      }
      return true;
    } else if (action === 'save') {
      save('fileUploaded');
    }
  };

  const clearDateField = (name) => {
    setDocumentInfoValues({ ...documentInfoValues, [name]: '' });
  };

  const changeActionType = () => {
    if (actionType === 'view') {
      setActionType('edit');
    }
  };

  const getBOValue = (name) => {
    return paymentRequestPrDocument?.ManagerInfo?.[name] != null
      ? paymentRequestPrDocument?.ManagerInfo?.[name]
      : '';
  };

  const checkValue = (name) => {
    return paymentRequestPrDocument?.ManagerInfo?.[name] != null;
  };

  return (
    <WithSpinner loading={loading === true ? true : false}>
      <div
        className="container"
        style={{ backgroundColor: '#fff', marginTop: '4rem' }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.requestBlockAll}>
              <h1>Dokumento duomenys</h1>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h4>Dokumento informacija</h4>
                  {
                    <div className="row director-data-margin">
                      {documentInfoValues.Number && (
                        <>
                          <div className="col-lg-6 col-sm-12">
                            <InputWithTextInline
                              type="string"
                              value={documentInfoValues.Number}
                              placeholder="Eil. Nr."
                              disabled={true}
                              name="Number"
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12"></div>
                        </>
                      )}
                      {documentInfo.map((el, i) => (
                        <React.Fragment key={i}>
                          {checkVisibility(el.showWhenStatus) && (
                            <div className="col-lg-6 col-sm-12">
                              {el.fieldType === 'input' && (
                                <>
                                  <InputWithTextInline
                                    type={el.type || 'string'}
                                    value={
                                      el.type === 'date'
                                        ? documentInfoValues[el.name].slice(
                                            0,
                                            10
                                          )
                                        : el.type === 'numberString'
                                        ? `${
                                            documentInfoValues[el.name] !==
                                              null &&
                                            (!isNaN(
                                              convertStrValueToNumber(
                                                documentInfoValues[el.name]
                                              )
                                            ) ||
                                              !isNaN(
                                                documentInfoValues[el.name]
                                              ))
                                              ? `${formatNumber(
                                                  documentInfoValues[el.name],
                                                  el.minFractionDigits || ''
                                                )}${
                                                  el?.additionalSymbols || ''
                                                }`
                                              : ''
                                          }`
                                        : documentInfoValues[el.name] !== '' &&
                                          documentInfoValues[el.name] !== null
                                        ? documentInfoValues[el.name]
                                        : ''
                                    }
                                    onKeyPress={(e) => {
                                      if (
                                        e.which === 45 ||
                                        (e.which === 46 &&
                                          el.name === 'DocumentNumber')
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={el.title}
                                    disabled={el.disabled}
                                    name={el.name}
                                    maxlength={el.maxlength || ''}
                                    onChange={(e) =>
                                      setValueChanged(
                                        e.target.value,
                                        e.target.name
                                      )
                                    }
                                    onBlur={(e) => {
                                      if (el.onblur) {
                                        el.onblur(e.target.value, el.name);
                                      }
                                    }}
                                  />
                                  {el.required &&
                                    triedSave &&
                                    ValidateInput(
                                      documentInfoValues[el.name],
                                      el.requiredType,
                                      el.defaultErrorMessage,
                                      el.minValue,
                                      el.maxValue,
                                      el.customFunction,
                                      el.checkConnectedValues
                                    ) && (
                                      <p className="errorsBlockText error1">
                                        {ValidateInput(
                                          documentInfoValues[el.name],
                                          el.requiredType,
                                          el.defaultErrorMessage,
                                          el.minValue,
                                          el.maxValue,
                                          el.customFunction,
                                          el.checkConnectedValues
                                        )}
                                      </p>
                                    )}
                                  {checkBOEditPage() &&
                                    checkBOCanEdit(el.name) &&
                                    !el.disabled && (
                                      <div className="boUserText">
                                        {`Pareiškėjo įvesta reikšmė: `}
                                        <span className="boUserValue">
                                          {el.type === 'numberString'
                                            ? `${
                                                boCanEditInfoValues[el.name] !==
                                                  null &&
                                                !isNaN(
                                                  boCanEditInfoValues[el.name]
                                                )
                                                  ? `${formatNumber(
                                                      boCanEditInfoValues[
                                                        el.name
                                                      ]
                                                    )}${
                                                      el?.additionalSymbols ||
                                                      ''
                                                    }`
                                                  : ''
                                              }`
                                            : boCanEditInfoValues[el.name]}
                                        </span>
                                      </div>
                                    )}
                                  {checkBOPage() &&
                                    checkBOCanEdit(el.name) &&
                                    actionType !== 'edit' &&
                                    checkValue(el.name) && (
                                      <div className="boUserText">
                                        {`Vertintojo įvesta reikšmė: `}
                                        <span className="boAdminValue">
                                          {el.type === 'numberString'
                                            ? `${
                                                getBOValue(el.name) !== null &&
                                                !isNaN(getBOValue(el.name))
                                                  ? `${formatNumber(
                                                      getBOValue(el.name)
                                                    )}${
                                                      el?.additionalSymbols ||
                                                      ''
                                                    }`
                                                  : ''
                                              }`
                                            : getBOValue(el.name)}
                                        </span>
                                      </div>
                                    )}
                                </>
                              )}
                              {el.fieldType === 'dropdown' && el.dropdownInfo && (
                                <>
                                  <label
                                    style={{
                                      margin: '8px 0 8px',
                                      color: '#597a96',
                                      fontSize: '0.8rem',
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    {el.title}
                                  </label>
                                  <select
                                    name={el.name}
                                    style={{
                                      background: el.disabled
                                        ? 'rgb(241, 240, 241'
                                        : '#fff',
                                    }}
                                    disabled={el.disabled}
                                    onChange={onDropdownInputChange}
                                  >
                                    {el.dropdownInfo.options.map((sel, j) => (
                                      <option
                                        value={sel.value}
                                        selected={dropdownItemSelected(
                                          sel.value,
                                          sel.type,
                                          el.name
                                        )}
                                        key={j}
                                      >
                                        {sel.name}
                                      </option>
                                    ))}
                                  </select>
                                  {el.required &&
                                    triedSave &&
                                    ValidateInput(
                                      documentInfoValues[el.name],
                                      el.requiredType,
                                      el.defaultErrorMessage,
                                      el.minValue,
                                      el.maxValue,
                                      el.customFunction,
                                      el.checkConnectedValues
                                    ) && (
                                      <p className="errorsBlockText error1">
                                        {ValidateInput(
                                          documentInfoValues[el.name],
                                          el.requiredType,
                                          el.defaultErrorMessage,
                                          el.minValue,
                                          el.maxValue,
                                          el.customFunction,
                                          el.checkConnectedValues
                                        )}
                                      </p>
                                    )}
                                  {checkBOEditPage() &&
                                    checkBOCanEdit(el.name) &&
                                    !el.disabled && (
                                      <div
                                        className="boUserText"
                                        style={{
                                          marginTop: '8px',
                                        }}
                                      >
                                        {`Pareiškėjo įvesta reikšmė: `}
                                        <span className="boUserValue">
                                          {getDropdownNameByValue(
                                            el.dropdownInfo.options,
                                            boCanEditInfoValues[el.name]
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  {checkBOPage() &&
                                    checkBOCanEdit(el.name) &&
                                    actionType !== 'edit' &&
                                    getBOValue(el.name) && (
                                      <div
                                        className="boUserText"
                                        style={{
                                          marginTop: '8px',
                                        }}
                                      >
                                        {`Vertintojo įvesta reikšmė: `}
                                        <span className="boAdminValue">
                                          {getDropdownNameByValue(
                                            el.dropdownInfo.options,
                                            getBOValue(el.name)
                                          )}
                                        </span>
                                      </div>
                                    )}
                                </>
                              )}
                              {el.fieldType === 'date' && (
                                <>
                                  <label
                                    style={{
                                      margin: '8px 0 8px',
                                      color: '#597a96',
                                      fontSize: '0.8rem',
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    {el.title}
                                  </label>
                                  <div className="customDatePickerWidth">
                                    <DatePicker
                                      className="inlineDatepickerInput"
                                      selected={
                                        documentInfoValues[el.name]
                                          ? new Date(
                                              documentInfoValues[el.name]
                                            )
                                          : ''
                                      }
                                      onChange={(e) => {
                                        setValueChanged(
                                          createDateAsUTC(e),
                                          el.name
                                        );
                                      }}
                                      maxDate={new Date()}
                                      dateFormat="yyyy-MM-dd"
                                      disabled={el.disabled}
                                      locale="lt"
                                    />
                                    <span
                                      className={styles.clearDateButton}
                                      onClick={() => clearDateField(el.name)}
                                    >
                                      X
                                    </span>
                                  </div>
                                  {el.required &&
                                    triedSave &&
                                    ValidateInput(
                                      documentInfoValues[el.name],
                                      el.requiredType,
                                      el.defaultErrorMessage,
                                      el.minValue,
                                      el.maxValue,
                                      el.customFunction
                                    ) && (
                                      <p className="errorsBlockText error1">
                                        {ValidateInput(
                                          documentInfoValues[el.name],
                                          el.requiredType,
                                          el.defaultErrorMessage,
                                          el.minValue,
                                          el.maxValue,
                                          el.customFunction
                                        )}
                                      </p>
                                    )}
                                  {checkBOEditPage() &&
                                    checkBOCanEdit(el.name) &&
                                    !el.disabled && (
                                      <div className="boUserText">
                                        {`Pareiškėjo įvesta reikšmė: `}
                                        <span className="boUserValue">
                                          {boCanEditInfoValues[el.name]?.slice(
                                            0,
                                            10
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  {checkBOPage() &&
                                    checkBOCanEdit(el.name) &&
                                    actionType !== 'edit' &&
                                    getBOValue(el.name) && (
                                      <div className="boUserText">
                                        {`Vertintojo įvesta reikšmė: `}
                                        <span className="boAdminValue">
                                          {getBOValue(el.name)?.slice(0, 10)}
                                        </span>
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                      {checkBOPage() && (
                        <div className="col-lg-12 col-sm-12">
                          <InputWithTextInline
                            type="string"
                            value={documentInfoValues.ManagerComment}
                            placeholder="Vertintojo pastaba"
                            disabled={getInputEditStatus(true)}
                            name="ManagerComment"
                            onChange={(e) =>
                              setValueChanged(e.target.value, e.target.name)
                            }
                          />
                        </div>
                      )}
                      {!checkBOPage() &&
                        (paymentRequest?.ContractInfo
                          ?.PaymentRequestStatusId === 2 ||
                          paymentRequest?.ContractInfo
                            ?.PaymentRequestStatusId === 3 ||
                          paymentRequest?.ContractInfo
                            ?.PaymentRequestStatusId === 4) &&
                        documentInfoValues.ManagerComment && (
                          <div className="col-lg-12 col-sm-12">
                            <InputWithTextInline
                              type="string"
                              value={documentInfoValues.ManagerComment}
                              placeholder="Vertintojo pastaba"
                              disabled={true}
                              name="ManagerComment"
                            />
                          </div>
                        )}
                    </div>
                  }
                </div>
              </div>
              <div className="row">
                {paymentRequestPrDocument?.Id && (
                  <div className="col-lg-12 col-sm-12">
                    <PaymentDocumentFileUploadContainer
                      data={paymentRequestPrDocument}
                      paymentRequestStatus={
                        paymentRequest?.ContractInfo.PaymentRequestStatusId
                      }
                      actionType={actionType}
                      saveDataAfterUpload={isUnsavedChanges}
                      saveDataOnUploadCallback={saveChangesAfterFileUpload}
                    />
                  </div>
                )}
                <br />
                <div className="col-lg-12 col-sm-12">
                  {saved && (
                    <p className="success-block-message">
                      <strong>Dokumentas išsaugotas sėkmingai</strong>
                    </p>
                  )}
                  {error && (
                    <p className="errors-block-message">
                      <strong>{error}</strong>
                    </p>
                  )}
                </div>
                <br />
                {((!paymentRequestPrDocument?.Id ||
                  !paymentRequestPrDocument?.IsApproved) &&
                  (paymentRequest?.ContractInfo?.PaymentRequestStatusId === 1 ||
                    paymentRequest?.ContractInfo?.PaymentRequestStatusId ===
                      3) &&
                  actionType !== 'view') ||
                (checkBOEditPage() && actionType !== 'view') ? (
                  <div className={`${styles.btnBlock} col-lg-12 col-sm-12`}>
                    <Button
                      className={'btn-dark-blue-standart'}
                      type="cancel"
                      onClick={() => save()}
                    >
                      Išsaugoti
                    </Button>
                    <Button type="cancel" onClick={() => cancel()}>
                      Atgal į mokėjimo prašymą
                    </Button>
                  </div>
                ) : (
                  <div className={`pt-5 col-lg-12 col-sm-12 row`}>
                    <div className={'col-lg-4 col-sm-4'}>
                      <Link
                        to={`${
                          checkBOPage()
                            ? config.APP_PATHS.backofficePaymentRequest
                            : config.APP_PATHS.paymentrequest
                        }?requestId=${
                          paymentRequest.requestId
                        }&paymentRequestId=${ContractInfo?.Id}&actionType=${
                          paymentRequest.actionType || 'view'
                        }`}
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                          float: 'left',
                        }}
                      >
                        <Button
                          type="cancel"
                          onClick={() =>
                            dispatch(
                              actions.dashboard.approveRequestResset(true)
                            )
                          }
                        >
                          Atgal
                        </Button>
                      </Link>
                    </div>
                    {checkBOPage() &&
                      paymentRequest?.ContractInfo?.PaymentRequestStatusId ===
                        5 &&
                      actionType === 'view' &&
                      !documentInfoValues?.IsPayBackDocument && (
                        <div className={'col-lg-4 col-sm-4'}>
                          <Link
                            to={`${config.APP_PATHS.backofficePaymentDocument}?documentId=${documentInfoValues.Id}&actionType=edit`}
                          >
                            <Button
                              style={{ width: '300px' }}
                              type="cancel"
                              onClick={() => changeActionType()}
                            >
                              Koreguoti dokumento duomenis
                            </Button>
                          </Link>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalConfirmationModal
        open={confirmModalVisible}
        text={modalText}
        data={modalData}
        action={modalAction}
        loading={false}
        okTitle="Taip"
        modalTitle={modalTitle}
        checkbox={false}
        mainText={modalMainText}
      />
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  requestState: state.requestState,
  paymentRequest: state.dashboardState.paymentRequest,
  paymentRequestPrDocument: state.dashboardState.paymentRequestPrDocument,
  confirmModalVisible: state.modalsState.globalConfirmationModalVisible,
  closedDialogOKMessage: state.modalsState.closedDialogOKMessage,
});
export default connect(mapStateToProps)(PaymentDocument);
