import { subYears, format } from 'date-fns';

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex = re.test(email);
  if (email == null || email.length === 0) {
    return '* neįvestas el.paštas  (pvz. test@test.lt)';
  } else if (!regex) {
    return '* neteisingas el.paštas  (pvz. test@test.lt) ';
  } else {
    return null;
  }
};

export const validatePhone = (phone, name) => {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{3,5}$/im;
  const regex = re.test(phone);
  if (phone != null) {
    if (phone.length === 0) {
      return `* neįvestas  ${name} (pvz. +37061111111)`;
    } else if (!regex) {
      return `* neteisingas  ${name} (pvz. +37061111111)`;
    } else {
      return null;
    }
  } else {
    return `* neteisingas  ${name} (pvz. +37061111111)`;
  }
};

export const validatePassword = (password) => {
  if (password.length === 0) {
    return '* neįvestas slaptažodis ';
  } else if (password.length < 4) {
    return '* per trumpas slaptažodis slaptažodis ';
  } else {
    return null;
  }
};

export const validateTextinput = (val, name, length, msg = 'neįvestas') => {
  if (val == null || val.length === 0) {
    return `* ${msg} ${name} `;
  } else if (length == null ? val.length < length : val.length < length) {
    return `* per trumpas ${name} `;
  } else {
    return null;
  }
};

export const validateCompanyCodeInput = (val, name) => {
  if (val == null || val.length === 0) {
    return `* neįvestas ${name} `;
  } else if (val.length !== 9) {
    return `* įmonės kodas turi būti sudarytas iš 9 skaitmenų `;
  } else {
    return null;
  }
};

export const sumValidation = (sum) => {
  var ex = /^-?\d+\.?\d*$/;
  if (sum === null || sum.length === 0) {
    return `* neįvestas laukas `;
  } else if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }
  return null;
};

export const twoDigitsAfterCommaValidation = (sum) => {
  if (sum !== 0 && !sum) {
    return 'Neįvestas laukas';
  }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }
  return null;
};

export const controllingCompaniesInputValidation = (val, letEmptyVal) => {
  if (letEmptyVal && (val == null || val.length < 1)) {
    return null;
  }

  if (val == null || val.length < 1) {
    return 'Neįvestas laukas';
  }
  const ex = /^(\d{9}|\d{9}|\d{9})(, +(\d{9}|\d{9}|\d{9}))*$/;

  if (ex.test(val) === false) {
    return 'Neteisingas formatas';
  }

  return null;
};

export const controlingForeignCompaiesInputValidation = (val) => {
  const ex = /^[^,\s]{1,22}(?:, [^,\s]{1,22})*$/;

  if (!val) {
    return false;
  }

  if (ex.test(val) === false) {
    return 'Neteisingas formatas';
  }
};

export const isSumValid = (sum) => {
  if (!sum && sum !== 0) {
    return 'Neįvesta suma. Formatas 0.00 EUR';
  }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }
  return null;
};

export const isSumValid2 = (sum) => {
  if (!sum && sum !== 0) {
    return 'Neįvesta suma. Formatas 0.00 EUR';
  }

  if (sum == 0 || sum == 0.0 || sum == 0.0) {
    return 'Paskolos suma negali būti 0';
  }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }
  return null;
};

export const isInterestRateValid = (sum) => {
  if (!sum && sum !== 0) {
    return 'Neįvestas skaičius. Formatas 0.00 ';
  }

 // if (sum == 0 || sum == 0.0 || sum == 0.0) {
 //   return 'Palūkanos negali būti 0';
 // }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }
  return null;
};

export const isInterestVariableRateValid = (sum) => {
  {/*if (!sum && sum !== 0) {
    return 'Neįvestas skaičius. Formatas 0.00 ';
  }

  if (sum == 0 || sum == 0.0 || sum == 0.0) {
    return 'Palūkanos negali būti 0';
  }*/}
  var ex = /^\d*\.?\d{0,3}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.000)';
  }
  return null;
};

export const isSumValidMax100000 = (sum) => {
  if (!sum && sum !== 0) {
    return 'Neįvesta suma. Formatas 0.00 EUR';
  }

  if (sum == 0 || sum == 0.0 || sum == 0.0) {
    return 'Paskolos suma negali būti 0';
  }

  if (sum > 100000) {
    return 'Paskolos suma negali būti didesnė nei 100 000';
  }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }
  return null;
};

export const isLoadSumValid = (
  sum,
  regDate,
  size,
  loanType,
  socialBusiness,
  loginType
) => {
  const date = new Date();
  const ageMinusYear = subYears(new Date(date), 1);
  const isYoungCompany = new Date(regDate) > ageMinusYear;

  if (!sum && sum !== 0) {
    return 'Neįvesta suma. Formatas 0.00 EUR';
  }

  if (sum == 0 || sum == 0.0 || sum == 0.0) {
    return 'Paskolos suma negali būti 0';
  }

  // if (sum > 100000) {
  //   return 'Paskolos suma negali būti didesnė nei 100 000';
  // }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }

  const loanTypeText = (type) => {
    if (type == 1) {
      return 'investicinė';
    }
    if (type == 2) {
      return 'apyvartinė';
    }
    return '';
  };
  if (isYoungCompany && size < 3) {
    if (sum * 1 < 25000) {
      return `Minimali ${loanTypeText(
        loanType
      )} paskolos suma jaunai įmonei yra 25 tūkst. Eur.`;
    }
  }

  /* Socialinis verslas  */

  // if (socialBusiness && socialBusiness !== 'false' && loanType == 1) {
  //   if (sum * 1 > 800000) {
  //     return 'Maksimali investicinė paskolos suma socialiniam verslui yra 800 tūkst. Eur.';
  //   }
  // }

  /* Investicinė  */

  if (loanType == 1) {
    if (sum * 1 > 3000000) {
      return 'Maksimali investicinės paskolos suma yra 3 mln. Eur.';
    }
  }
  /* Apyvartinė  */

  if (loanType == 2) {
    if (sum * 1 > 200000) {
      return 'Maksimali apyvartinės paskolos suma yra 200 tūkst. Eur.';
    }
  }

  return null;
};

export const LoanTermValidation = (val, loadType) => {
  if (val == null || val == 0) {
    return 'Privalote pasirinkti paskolos terminą';
  }

  if (loadType == 2 && val * 1 > 36) {
    return ' Maksimalus apyvartinės paskolos terminas yra 36 mėn.';
  }

  return null;
};

// export const sumValidation = (sum) => {
//   var ex = /^\d+\.\d{0,2}$/;
//   if (sum === null || sum.length === 0) {
//     return `* neįvestas laukas `;
//   } else if (ex.test(sum) === false) {
//     return 'Turi būti įvesti skaičiai (formatas 0.00)';
//   }
//   return null;
// };

export const isCommentFilled = (val) => {
  if (!val) {
    return 'privalote užpildyti komentaro lauką';
  }

  if (val.length < 6) {
    return 'įveskite ilgesnį/tikslesnį komentarą';
  }

  return false;
};

export const isIntegerNumber = (number) => {
  if (!number && number !== 0) {
    return '*neįvestas privalomas laukas';
  }
  if (isNaN(number) || Math.ceil(+number) !== Math.floor(+number)) {
    return '*neteisingas formatas. Galimi tik sveiki skaičiai';
  }
  return null;
};
