import React, { useEffect, useState } from 'react';
import styles from './addressInputContainer.module.scss';
import InputWithTextInline from '../../components/input/InputWithTextInline';
import { validateTextinput } from '../../utils/auth/validations';

import { requestDataLabelTranslation } from '../../utils/translation/translation';
import { checkBOPage } from '../../utils/helpers/helpers';
import { config } from '../../api/config';
import { Api } from './../../api/api';

const AddressInput = ({
  label,
  nameKey,
  onDataChange,
  dataValue,
  notValid,
  viewWindow,
}) => {
  return (
    <div className="col-sm-6">
      <InputWithTextInline
        name={nameKey}
        type="string"
        onChange={onDataChange}
        value={dataValue}
        placeholder={label}
        disabled={viewWindow}
      />
      {notValid && (
        <p
          className="errorsBlockText error1"
          style={{ color: 'var(--pinkDark)' }}
        >
          {validateTextinput(dataValue, 'privalomas laukas', 1)}
        </p>
      )}
    </div>
  );
};

const DropdownMenu = ({
  label,
  nameKey,
  dataValue,
  onDataChange,
  requestData,
  dropdownItems,
  disableIfNoDropdownItems,
  notValid,
  viewWindow,
}) => {
  // const returnEldership = (a, b) => {
  //   const arr = [11, 30, 27, 25, 21, 23, 12];
  //   const isInArray = arr.some((val) => val == b);

  //   if (isInArray) {
  //     return null;
  //   }

  //   return a;
  // };

  // const returnSettlement = (a, b) => {
  //   const arr = [13, 18, 19, 29];
  //   const isInArray = arr.some((val) => val == b);

  //   if (isInArray) {
  //     return null;
  //   }

  //   return a;
  // };

  // const checkIfNeedValidation = () => {
  //   // if dropdown is Eldership - check municipality
  //   if (
  //     nameKey === 'RegistrationAddressEldership' ||
  //     nameKey === 'ActivityAddressEldership'
  //   ) {
  //     const municipalityId = allData[`${type}MunicipalityId`];

  //     const elderShipId = allData[`${type}EldershipId`];

  //     // console.log({ municipalityId, elderShipId });

  //     const hasValue = returnEldership(elderShipId, municipalityId);
  //     // console.log(hasValue);
  //   }
  // };

  return (
    <div className="col-sm-6">
      <label className={styles.inputTitle}>{label}</label>
      <select
        name={nameKey}
        onChange={(e) => onDataChange(e, dropdownItems)}
        disabled={
          viewWindow ||
          (disableIfNoDropdownItems && dropdownItems === null) ||
          checkBOPage()
        }
        className={
          viewWindow ||
          (disableIfNoDropdownItems && dropdownItems === null) ||
          checkBOPage()
            ? styles.disabled
            : styles.visible
        }
      >
        {checkBOPage() && (
          <option selected={dataValue === null} value={null}>
            {dataValue}
          </option>
        )}
        <option selected={dataValue === null} value={null}></option>

        {dropdownItems &&
          dropdownItems.map((val, j) => {
            return (
              <option
                key={j}
                selected={dataValue === val?.value?.Name}
                value={val?.value?.Code}
                data-value={val}
              >
                {val?.label?.Name}
              </option>
            );
          })}
      </select>

      {notValid && dropdownItems && (
        <p
          className="errorsBlockText error1"
          style={{ color: 'var(--pinkDark)' }}
        >
          {validateTextinput(dataValue, 'privalomas laukas', 1)}
        </p>
      )}
    </div>
  );
};

export const AddressModal = ({
  title,
  type,
  onInputChange,
  onDropdownImputChange,
  data,
  notValid,
  viewWindow,
}) => {
  const [districtsList, setDistricts] = useState(null);
  const [municipalities, setMunicipalities] = useState(null);
  const [elderships, setElderships] = useState(null);
  const [settlements, setSettlements] = useState(null);

  const getAutorization = () => {
    return `Bearer ${window.localStorage.getItem(
      checkBOPage() ? 'token' : 'userToken'
    )}`;
  };

  useEffect(() => {
    if (checkBOPage()) {
      return;
    }

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.getAddressStructDistricts, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include', // Include cookies in the request
      headers: {
        'Content-Type': 'application/json', // Set the content type if needed
      },
    })
      .then((res) => {
        if (res.ok === true) {
          res.json().then((r) => {
            const districts = r.map((item) => {
              return { label: item, value: item };
            });
            setDistricts(districts);
          });
        }
      })
      .finally(() => {
        // setIsloading(false);
      });
  }, [data]);

  useEffect(() => {
    if (checkBOPage()) {
      setDistricts([
        {
          label: data[`${type}District`] ?? '',
          value: data[`${type}District`] ?? '',
        },
      ]);
      setMunicipalities([
        {
          label: data[`${type}Municipality`] ?? '',
          value: data[`${type}Municipality`] ?? '',
        },
      ]);
      setElderships([
        {
          label: data[`${type}Eldership`] ?? '',
          value: data[`${type}Eldership`] ?? '',
        },
      ]);
      setSettlements([
        {
          label: data[`${type}Settlement`] ?? '',
          value: data[`${type}Settlement`] ?? '',
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (checkBOPage()) {
      return;
    }
    if (data[`${type}District`] === null) {
      setMunicipalities(null);
      onInputChange({
        target: { name: `${type}District`, value: null },
      });
    }
    setElderships(null);
    setSettlements(null);
    if (data[`${type}District`] && data[`${type}District`] !== '-') {
      fetch(
        Api.API_BASE_URL +
          config.API_ENDPOINTS.getAddressStructMunicipalities([
            data[`${type}DistrictId`],
          ]),
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'include', // Include cookies in the request
          headers: {
            'Content-Type': 'application/json', // Set the content type if needed
          },
        }
      )
        .then((res) => {
          if (res.ok === true) {
            res.json().then((r) => {
              const municipalities = r.map((item) => {
                return { label: item, value: item };
              });

              setMunicipalities(municipalities);
              if (
                data[`${type}Municipality`] &&
                !r.includes(data[`${type}Municipality`])
              ) {
                // onInputChange({
                //   target: { name: `${type}Municipality`, value: null },
                // });
              }
            });
          }
        })
        .finally(() => {
          // setIsloading(false);
        });
    }
  }, [data[`${type}District`]]);

  useEffect(() => {
    if (checkBOPage()) {
      return;
    }
   // setSettlements(null);

    if (data[`${type}Municipality`] && !checkBOPage()) {
      fetch(
        Api.API_BASE_URL +
          config.API_ENDPOINTS.getAddressStructElderships([
            data[`${type}MunicipalityId`],
          ]),
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json', // Set the content type if needed
          },
        }
      )
        .then((res) => {
          if (res.ok === true) {
            res.json().then((r) => {
              const elderships = r
                .filter((item) => item !== null)
                .map((item) => {
                  return { label: item, value: item };
                });
              setElderships(elderships?.length > 0 ? elderships : null);
              if (elderships.length === 0) {
                fetch(
                  Api.API_BASE_URL +
                    config.API_ENDPOINTS.getAddressStructSettlements(
                      data[`${type}MunicipalityId`],
                      ''
                    ),
                  {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                      Authorization: getAutorization(),
                    },
                  }
                )
                  .then((res) => {
                    if (res.ok === true) {
                      res.json().then((r) => {
                        const settlements = r
                          .filter((item) => item !== null)
                          .map((item) => {
                            return { label: item, value: item };
                          });
                        setSettlements(
                          settlements?.length > 0 ? settlements : null
                        );
                        if (settlements.length === 0) {
                          // onInputChange({
                          //   target: { name: `${type}Settlement`, value: null },
                          // });
                        } else if (
                          data[`${type}Settlement`] &&
                          !r.includes(data[`${type}Settlement`])
                        ) {
                          // onInputChange({
                          //   target: { name: `${type}Settlement`, value: null },
                          // });
                        }
                      });
                    }
                  })
                  .finally(() => {
                    // setIsloading(false);
                  });
                if (data[`${type}Eldership`] !== '-') {
                  onInputChange({
                    target: { name: `${type}Eldership`, value: null },
                  });
                }
              } else if (
                data[`${type}Eldership`] &&
                !r.includes(data[`${type}Eldership`])
              ) {
                // onInputChange({
                //   target: { name: `${type}Eldership`, value: null },
                // });
              }
            });
          }
        })
        .finally(() => {
          // setIsloading(false);
        });
    }
  }, [data[`${type}Municipality`]]);

  useEffect(() => {
    if (checkBOPage()) {
      return;
    }
    if (
      data[`${type}Eldership`] &&
      data[`${type}Eldership`] !== '-' &&
      data[`${type}Municipality`] &&
      data[`${type}Municipality`] !== '-'
    ) {
      fetch(
        Api.API_BASE_URL +
          config.API_ENDPOINTS.getAddressStructSettlements(
            data[`${type}MunicipalityId`],
            data[`${type}EldershipId`]
          ),
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'include', // Include cookies in the request
          headers: {
            'Content-Type': 'application/json', // Set the content type if needed
          },
        }
      )
        .then((res) => {
          if (res.ok === true) {
            res.json().then((r) => {
              const settlements = r
                .filter((item) => item !== null)
                .map((item) => {
                  return { label: item, value: item };
                });
              setSettlements(settlements?.length > 0 ? settlements : null);
              if (settlements.length === 0) {
                // onInputChange({
                //   target: { name: `${type}Settlement`, value: null },
                // });
              } else if (
                data[`${type}Settlement`] &&
                !r.includes(data[`${type}Settlement`])
              ) {
                // onInputChange({
                //   target: { name: `${type}Settlement`, value: null },
                // });
              }
            });
          }
        })
        .finally(() => {
          // setIsloading(false);
        });
    }
    if (data[`${type}Eldership`] === '-') {
      // onInputChange({
      //   target: { name: `${type}Settlement`, value: '-' },
      // });
    }
  }, [data[`${type}Eldership`], data[`${type}Municipality`]]);

  return (
    <div
      className={`col-lg-12 col-sm-12 ${type === 'ActivityAddress' ? '' : ''}`}
      key={type}
    >
      <div className="row">
        <div className="col-lg-12">
          <h5 className={`mb-0 align-self-center ${styles.title}`}>
            {requestDataLabelTranslation(title)}
          </h5>
        </div>
        <div className="col-lg-12 ">
          <div className="row">
            <DropdownMenu
              label="Apskritis"
              onDataChange={onDropdownImputChange}
              nameKey={`${type}District`}
              dataValue={data[`${type}District`]}
              dropdownItems={districtsList}
              notValid={notValid}
              viewWindow={viewWindow}
            />
            <DropdownMenu
              label="Savivaldybė"
              onDataChange={onDropdownImputChange}
              nameKey={`${type}Municipality`}
              dataValue={data[`${type}Municipality`]}
              dropdownItems={municipalities}
              disableIfNoDropdownItems={true}
              notValid={notValid}
              viewWindow={viewWindow}
            />
            <DropdownMenu
              label="Seniūnija"
              onDataChange={onDropdownImputChange}
              nameKey={`${type}Eldership`}
              dataValue={data[`${type}Eldership`]}
              dropdownItems={elderships}
              disableIfNoDropdownItems={true}
              notValid={notValid}
              viewWindow={viewWindow}
            />

            <DropdownMenu
              label="Gyvenvietė"
              onDataChange={onDropdownImputChange}
              nameKey={`${type}Settlement`}
              dataValue={data[`${type}Settlement`]}
              dropdownItems={settlements}
              disableIfNoDropdownItems={true}
              notValid={
                settlements?.length === 1 &&
                settlements?.[0]?.label?.Code?.length === 0
                  ? false
                  : notValid
              }
              viewWindow={viewWindow}
            />

            <AddressInput
              label="Gatvė"
              onDataChange={onInputChange}
              nameKey={`${type}Street`}
              dataValue={data[`${type}Street`] ?? ''}
              notValid={notValid}
              viewWindow={viewWindow}
            />
            <AddressInput
              label="Namo ir buto numeris"
              nameKey={`${type}ApartmentNumber`}
              onDataChange={onInputChange}
              dataValue={data?.[`${type}ApartmentNumber`] ?? ''}
              notValid={notValid}
              viewWindow={viewWindow}
            />
            <AddressInput
              label="Pašto kodas"
              onDataChange={onInputChange}
              nameKey={`${type}PostalCode`}
              dataValue={data?.[`${type}PostalCode`] ?? ''}
              notValid={notValid}
              viewWindow={viewWindow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
