import { constants } from '../constants';

const setModalVisibleFlagAction = (open, msgText) => {
  return {
    type: constants.modals.SET_MODAL_VISIBLE_FLAG,
    open,
    msgText,
  };
};

const setPdfDownloadComfirmAction = (data) => {
  return {
    type: constants.modals.SET_PDF_DOWNLOAD_COMFIRM,
    data,
  };
};

const setModalOnSyncFlagAction = (flag) => {
  return {
    type: constants.modals.SET_ON_SYNC_FLAG,
    flag,
  };
};

const setErrorMessageAction = (msg) => {
  return {
    type: constants.modals.SET_ERROR_MESSAGE,
    msg,
  };
};

const setValidationErrorAction = (err) => {
  return {
    type: constants.modals.SET_VALIDATION_ERROR,
    err,
  };
};

const setModalClosedOKAction = (action) => {
  return {
    type: constants.modals.SET_MODAL_CLOSED_OK_MESSAGE,
    action,
  };
};

const setModalConfirmAction = (payload, action, userComment) => {
  return {
    type: constants.modals.SET_MODAL_CONFIRM_ACTION,
    payload,
    action,
    userComment,
  };
};

const setPaymentModalConfirmAction = (payload, action) => {
  return {
    type: constants.modals.SET_PAYMENT_MODAL_CONFIRM_ACTION,
    payload,
    action,
  };
};

const confirmInvoicesFromManagerAction = (requestId) => {
  return {
    type: constants.modals.CONFIRM_INVOICES_FROM_MANAGER,
    requestId,
  };
};

const regenerateDecisionDocAction = (requestId) => {
  return {
    type: constants.modals.REGENERATE_DECISION_DOC,
    requestId,
  };
};

export const modalActions = {
  setModalVisibleFlagAction,
  setPdfDownloadComfirmAction,
  setModalOnSyncFlagAction,
  setErrorMessageAction,
  setModalConfirmAction,
  setPaymentModalConfirmAction,
  setValidationErrorAction,
  setModalClosedOKAction,
  confirmInvoicesFromManagerAction,
  regenerateDecisionDocAction,
};
