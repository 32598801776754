import { actions } from '../actions';
import { call, put, select } from 'redux-saga/effects';
import { errorhandle } from '../../utils/helpers/helpers';
import { Api } from '../../api/api';
import swal from 'sweetalert';

function* modalConfirm(request) {
  try {
    if (request.action === 'DELETE_REPRESENTATIVE') {
      yield call(Api.deleteRepresentative, request.payload.representativeId);
      yield put(actions.dashboard.loadRepresentatives());
    }
    if (request.action === 'DELETE_REPRESENTATIVE_MANAGER') {
      yield call(
        Api.deleteRepresentativeManager,
        request.payload.representativeId
      );

      yield put(actions.backoffice.loadRepresentatives());
    }
    if (
      request.action === 'INVEGA_REJECT_REQUEST' ||
      request.action === 'INVEGA_CONFIRM_REQUEST' ||
      request.action === 'INVEGA_CONFIRMWITHDOCS_REQUEST' ||
      request.action === 'INVEGA_RETURN_REQUEST' ||
      request.action === 'INVEGA_SIGN_REQUEST' ||
      request.action === 'INVEGA_CHANGE_TO_SENDFORAPPROVE_REQUEST' ||
      request.action === 'INVEGA_CHANGE_TO_APPROVE_REQUEST' ||
      request.action === 'INVEGA_CHANGE_TO_CHANGECONDITIONS_REQUEST' ||
      request.action === 'INVEGA_CHANGE_TO_CHANGECONDITIONSAPPROVED_REQUEST' 
    ) {
      yield put(actions.backoffice.setStatusChanged(true));
      yield call(Api.SetInvegaRequestStatus, request);
      // if (request.action === 'INVEGA_CONFIRM_REQUEST') {
      //   yield call(Api.GenerateContractDoc, request.payload.requestId);
      // }
      yield put(actions.backoffice.setStatusChanged(false));
      yield put(actions.backoffice.getRequestAction(request.payload.requestId));
      yield put(
        actions.backoffice.getGetUploadedFilesAction(request.payload.requestId)
      );
      const comments = yield call(
        Api.getAdditionalComments,
        request.payload.requestId
      );
      yield put(actions.backoffice.getAdditionalComments(comments));

      // window.location.reload();
      const renderTitle = () => {
        if (request.action === 'INVEGA_REJECT_REQUEST') {
          return 'Paraiška sėkmingai atmesta';
        } else if (request.action === 'INVEGA_CONFIRM_REQUEST') {
          return 'Paraiškos finansavimas sėkmingai patvirtintas';
        } else if (request.action === 'INVEGA_CONFIRMWITHDOCS_REQUEST') {
          return 'Paraiškos finansavimas sėkmingai patvirtintas. Laukiama papildomų dokumentų.';
        } else if (request.action === 'INVEGA_RETURN_REQUEST') {
          return 'Paraiška sėkmingai grąžinta tikslinimui';
        } else if (request.action === 'INVEGA_SIGN_REQUEST') {
          return 'Paskolos sutartis sėkmingai pasirašyta';
        } else if (request.action === 'INVEGA_CHANGE_TO_APPROVE_REQUEST') {
          return 'Paraiškos būsena sėkmingai pakeista į "Patvirtinta"';
        } else if (request.action === 'INVEGA_CHANGE_TO_CHANGECONDITIONS_REQUEST') {
          return 'Paraiškos būsena sėkmingai pakeista į "Sąlygų keitimas"';
        } else if (request.action === 'INVEGA_CHANGE_TO_CHANGECONDITIONSAPPROVED_REQUEST') {
          return 'Paraiškos būsena sėkmingai pakeista į "Patvirtintas sąlygų keitimas"';
        } else if (
          request.action === 'INVEGA_CHANGE_TO_SENDFORAPPROVE_REQUEST'
        ) {
          return 'Paraiškos būsena sėkmingai pakeista į "Pateikta vertinti"';
        }
      };
      // window.location.href = '/backoffice/main';
      swal({
        title: renderTitle(),
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
    if (
      request.action === 'INVEGA_REJECT_PAYMENT_REQUEST' ||
      request.action === 'INVEGA_CONFIRM_PAYMENT_REQUEST' ||
      request.action === 'INVEGA_RETURN_PAYMENT_REQUEST'
    ) {
      yield put(actions.backoffice.setPaymentRequestStatusChanged(true));

      if (request.action === 'INVEGA_RETURN_PAYMENT_REQUEST') {
        // Iškviečia endpoint'ą gražinimui
        const payload = {
          comment: request.payload.comments,
          prDocumentIDs: request.payload.checkboxValues,
        };
        yield call(Api.returnPaymentWithComment, payload, request.payload.id);
      } else {
        // Iškviečia endpoint'ą  Patvirtinimui, atmetimui
        yield call(Api.SetInvegaPaymentRequestStatus, request);
      }

      const renderTitle = () => {
        if (request.action === 'INVEGA_REJECT_PAYMENT_REQUEST') {
          return 'Mokėjimo prašymas sėkmingai atmestas';
        } else if (request.action === 'INVEGA_CONFIRM_PAYMENT_REQUEST') {
          return 'Mokėjimo prašymo finansavimas sėkmingai patvirtintas';
        } else if (request.action === 'INVEGA_RETURN_PAYMENT_REQUEST') {
          return 'Mokėjimo prašymas sėkmingai grąžintas tikslinimui';
        }
      };

      swal({
        title: renderTitle(),
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
      yield put(
        actions.dashboard.createNewPaymentRequestAction(
          request.payload.requestId,
          'view',
          request.payload.id
        )
      );
    }
    if (request.action === 'YES_NO_ACTION') {
      const payload = {
        message: request.payload.comments,
      };
      yield put(actions.modals.setModalClosedOKAction(payload));
    }
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.backoffice.setPaymentRequestStatusChanged(false));
  }

  if (request.action === 'APPROVE_REQUEST') {
    try {
      // //Iškviesti ir rodyti šviesoforą
      const canCreateRequest = yield call(
        Api.CanCreateOrSubmit,
        request.payload
      );

      if (canCreateRequest) {
        yield put(
          actions.dashboard.getFinancialCriteriaResultsAction(
            request.payload,
            'User',
            'approve'
          )
        );
        const open = {
          modalName: 'confirmWithFinancialCriteriaModal',
          visible: true,
        };
        yield put(actions.modals.setModalVisibleFlagAction(open));
      } else {
        swal({
          title:
            'Nebegalima teikti paraiškų, nes pasibaigė finansavimo priemonei skirtos lėšos ',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      }
    } catch (error) {
      swal({
        title: error.error.ErrorDescription,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  }
  if (request.action === 'APPROVE_REQUEST_WITH_DOCS') {
    console.log('APPROVE_REQUEST_WITH_DOCS');
    try {
      // neberodyti šviesoforo
      const canCreateOrSubmit = yield call(
        Api.CanCreateOrSubmit,
        request.payload
      );

      {
        /*} if (canCreateOrSubmit) {
          yield put(
            actions.dashboard.getFinancialCriteriaResultsAction(
              request.payload,
              'User',
              'approve'
            )
          );
          const open = {
            modalName: 'confirmWithoutFinancialCriteriaModal',
            visible: true,
          };
          yield put(actions.modals.setModalVisibleFlagAction(open));
        } else {
          swal({
            title: 'Paraiškos teikiamos iki 2021 m. birželio 30 d. 23:59:59 val.',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }*/
      }
      const open = {
        modalName: 'confirmWithoutFinancialCriteriaModal',
        visible: true,
      };
      yield put(actions.modals.setModalVisibleFlagAction(open));
    } catch (error) {
      swal({
        title: error.error.ErrorDescription,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  }

  if (request.action === 'APPROVE_REQUEST_WITH_DOCS_FOR_CHANGE_CONDITIONS') {
    console.log('APPROVE_REQUEST_WITH_DOCS_FOR_CHANGE_CONDITIONS');
    try {
      // neberodyti šviesoforo
      // const canCreateOrSubmit = yield call(
      //   Api.CanCreateOrSubmit,
      //   request.payload
      // );

      const open = {
        modalName: 'confirmWithoutFinancialCriteriaForChangeConditionsModal',
        visible: true,
      };
      yield put(actions.modals.setModalVisibleFlagAction(open));
    } catch (error) {
      swal({
        title: error.error.ErrorDescription,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  }
}

function* paymentModalConfirm(paymentRequest) {
  if (paymentRequest.action === 'APPROVE_PAYMENT_REQUEST') {
    const open = {
      modalName: 'paymentRequestSendforApproveModal',
      visible: true,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));

    {
      /*try {
      const canCreateRequest = yield call(
        Api.CanCreateOrSubmit,
        paymentRequest.payload
      );

      if (canCreateRequest) {
        yield put(
          actions.dashboard.getFinancialCriteriaResultsAction(
            paymentRequest.payload,
            'User',
            'approve'
          )
        );
        const open = {
          modalName: 'confirmWithFinancialCriteriaModal',
          visible: true,
        };
        yield put(actions.modals.setModalVisibleFlagAction(open));
      } {/*else {
        swal({
          title:
            'Nebegalima teikti paraiškų, nes pasibaigė finansavimo priemonei skirtos lėšos ',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      }
    } catch (error) {
      swal({
        title: error.error.ErrorDescription,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }*/
    }
  }
}

function* confirmInvoicesFromManagerSaga({ requestId }) {
  try {
    const open = {
      modalName: 'confirmInvoicesFromManagerModal',
      visible: false,
    };
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const respond = yield call(Api.CanConfirmInvoicesFromManager, requestId);

    if (respond === true) {
      yield call(Api.ConfirmInvoicesFromManager, requestId);

      yield put(actions.modals.setModalVisibleFlagAction(open));

      swal({
        title: 'Naujos sąskaitos sėkmingai patvirtintos finansavimui',
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    } else {
      // yield call(Api.ConfirmInvoicesFromManager, body);
      yield put(actions.modals.setModalVisibleFlagAction(open));

      swal({
        title:
          'Nėra naujų sąskaitų, kurias būtų galima patvirtinti finansavimui',
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Gerai',
            value: true,
            visible: true,
            className: 'btn swal-ok-btn',
            closeModal: true,
          },
        },
      });
    }
  } catch (error) {
    const open = {
      modalName: 'confirmInvoicesFromManagerModal',
      visible: false,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));

    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}

function* regenerateDecisionDocSaga({ requestId }) {
  try {
    yield put(actions.dashboard.setUserOnSyncFlagAction(true));

    const respond = yield call(Api.regenerateDecisionDoc, requestId);
    console.log(respond);

    const open = {
      modalName: 'regenerateDecisionDocModal',
      visible: false,
    };
    yield put(actions.modals.setModalVisibleFlagAction(open));

    yield put(actions.backoffice.getRequestAction(requestId));
    yield put(actions.backoffice.getGetUploadedFilesAction(requestId));

    yield put(actions.modals.setModalVisibleFlagAction(open));

    swal({
      title: 'Sprendimas sėkmingai iš naujo sugeneruotas',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  } catch (error) {
    yield* errorhandle(error);
  } finally {
    yield put(actions.dashboard.setUserOnSyncFlagAction(false));
  }
}
export {
  modalConfirm,
  paymentModalConfirm,
  confirmInvoicesFromManagerSaga,
  regenerateDecisionDocSaga,
};
