import React from 'react';
import styles from './informationModal.module.scss';
import { actions } from './../../state/actions';
import { connect } from 'react-redux';
import { criteriaValue } from '../../utils/translation/criteria';

const InformationModal = ({ open, text, dispatch }) => {
  const cancel = () => {
    const open = {
      modalName: 'informationModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const deniedMsg =
    text != null &&
    text.Rules != null &&
    text.Rules.filter((r) => r.Decision === 'Denied').map((r) => (
      <div className={styles.ruleMessageText}>{criteriaValue(r.RuleName)}</div>
    ));

  const renderMessage = () => {
    if (text.Decision === 'Accepted') {
      return (
        <>
          <h2>
            Jūsų paraiška atitinka finansavimo kriterijus. Prašome pateikti
            finansuotinų sąskaitų faktūrų sąrašą
          </h2>
        </>
      );
    } else if (text.Decision === 'Denied') {
      return (
        <>
          <h2>Jūsų paraiška atmesta, nes neatitinka šių kriterijų:</h2>
          {deniedMsg}
        </>
      );
    } else if (text.Decision === 'DataIsMissing') {
      return (
        <>
          <h2>
            Tolesni veiksmai negalimi dėl vienos iš šių priežasčių: įmonės
            finansinės ataskaitos už 2018 ar 2019 m. pabaigą nepateiktos
            Registrų centrui arba pateiktos neteisingai; įmonė neatitinka mokumo
            kriterijų 2019-12-31 datai; nėra galimybės patikrinti įmonės mokumo,
            jei įmonės finansiniai metai nesutampa su kalendoriniais metais, ir
            jei įmonė nėra pateikusi patikslintų ataskaitų 2018 ir 2019 m.
            pabaigai Registrų centrui.
          </h2>
        </>
      );
    } else {
      return 'Sistemos klaida';
    }
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '300px' }}
        >
          <div className={styles.contentBlock}>
            {renderMessage()}
            <div className={styles.btnContainer}>
              <button
                type="standart"
                onClick={() => cancel()}
                className="vaiisisloginbtn"
              >
                Gerai
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  text: state.modalsState.msgText,
});

export default connect(mapStateToProps)(InformationModal);
