import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './regenerateDecisionDocModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';

import { sumValidation } from './../../utils/auth/validations';

const RegenerateDecisionDocModal = ({ open, loading, dispatch, requestId }) => {
  const cancel = () => {
    const open = {
      modalName: 'regenerateDecisionDocModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const regenerateDecisionDoc = () => {
    // sign
    dispatch(actions.modals.regenerateDecisionDocAction(requestId));
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <div className="modal-title">
              Ar tikrai norite sugeneruoti sprendimą iš naujo?
            </div>

            <div className={styles.btnContainer}>
              <div>
                <Button type="standart" onClick={() => regenerateDecisionDoc()}>
                  Taip
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Ne
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(RegenerateDecisionDocModal);
