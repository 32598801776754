/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '../../state/actions';
import styles from './globalMessageModal.module.scss';
import { Button } from '../../components';

const GlobalMessageModal = ({ dispatch, open, message }) => {
  const closeModal = () => {
    const close = {
      modalName: 'globalMessageModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(close));
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <p> {message} </p>

          <div className={styles.changeConditionsModal__btnContainer}>
            <div className="d-flex align-items-center justify-content-center w-100">
              <Button type="cancel" onClick={() => closeModal()}>
                Uždaryti
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
  yesNOModalInfo: state.modalsState.globalYesNoModalInfo,
});

export default connect(mapStateToProps)(GlobalMessageModal);

GlobalMessageModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
