import React, { useEffect, useState, useCallback } from 'react';
import { actions } from '../../state/actions';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../../api/config';
import { connect } from 'react-redux';
import { Api } from '../../api/api';
import {
  resetLocalStorageItem,
  hasRole,
  RoleEnum,
  resetCookieItem,
} from '../../utils';
import styles from './authorization.module.scss';

const Representative = ({ dispatch, representativesList }) => {
  const selectCompany = (e) => {
    if (e.target.value == 0) {
      return;
    }

    const parts = e.target.value.split('-');
    const reprId = parts[0];
    const type = parts[1];

    if (type == 'company') {
      resetCookieItem('loginType', 'Company');
    }
    if (type == 'personal') {
      resetCookieItem('loginType', 'Individual');
    }

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.impersonate, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
      body: JSON.stringify({
        representativeId: reprId,
      }),
    })
      .then((r) => r.json())
      .then((json) => {
        // reset cookie
        resetLocalStorageItem('userToken', json.Token);
        resetLocalStorageItem('Role', json.Role);
        window.location.href = config.APP_PATHS.requests;
      });
  };

  useEffect(() => {
    dispatch(actions.dashboard.loadCompanyRepresentatives());
  }, []);

  useEffect(() => {
    if (representativesList && representativesList.length == 0) {
      window.location.href = config.APP_PATHS.requests;
    }
  }, [representativesList]);

  return (
    <div style={{ width: '90%', marginLeft: '5%' }}>
      <h2 style={{ marginTop: '1rem' }}>Įmonės atstovavimas</h2>

      <label style={{ color: '#597a96', marginTop: '1rem' }}>
        Įmonė
        <select
          onChange={selectCompany}
          style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
        >
          <option value={0}>Pasirinkti</option>
          {representativesList &&
            representativesList
              ?.filter((r) => !r.Self && r.CompanyCode)
              .map((r) => (
                <option value={`${r.RepresentativeId}-company`}>
                  {r.CompanyName} ({r.CompanyCode})
                </option>
              ))}
          {representativesList &&
            representativesList
              .filter((r) => !r.Self && r.PersonalCode)
              .map((r) => (
                <option value={`${r.RepresentativeId}-personal`}>
                  {r.PersonalName} ({r.PersonalCode})
                </option>
              ))}
        </select>
      </label>

      <div>
        <Link
          to={config.APP_PATHS.requests}
          className={styles.linkText}
          style={{ color: '#009fe3', textDecoration: 'none' }}
          onClick={() => dispatch(actions.dashboard.loadGeneralDataAction())}
        >
          Atstovauju save
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  representativesList: state.dashboardState.representativesList,
});

export default connect(mapStateToProps)(Representative);
