import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  format,
  setYear,
  setMonth,
  setDate,
  addMonths,
  getMonth,
  subDays,
} from 'date-fns';

import { connect } from 'react-redux';
import styles from './confirmRequestModal.module.scss';
import { Button, WithSpinner, InputWithTextInline } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import {
  isSumValid2,
  isInterestRateValid,
  isInterestVariableRateValid,
} from './../../utils/auth/validations';
// import { errorhandle } from '../../utils/helpers/helpers';

const ChangeContractFile = ({
  open,
  requestId,
  loading,
  dispatch,
  invegaContractNumber2,
  invegaLoanInterestRate2,
  invegaLoanVariableInterestRate2,
  invegaLoanPaymentTerm2,
  invegaLoanSum2,
  loanDelayTerm,
  euriborChangeDate2,
  firstInterestChangeDate2,
  interestTypeId2,
  euriborPeriodId2,
  comment,
  invegaLoanDelayTerm2,
  invegaPaymentDate2,
  loanPreparationTax2,
}) => {
  console.log(loanDelayTerm);
  const [isLoading, setIsloading] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [invegaLoanInterestRate, setInvegaLoanInterestRate] = useState(
    invegaLoanInterestRate2
  );
  const [invegaLoanVariableInterestRate, setInvegaLoanVariableInterestRate] = useState(
    invegaLoanVariableInterestRate2
  );
  const [invegaLoanPaymentTerm, setinvegaLoanPaymentTerm] = useState(
    invegaLoanPaymentTerm2
  );
  const [invegaLoanSum, setInvegaLoanSum] = useState(invegaLoanSum2);
  const [loanPreparationTax, setLoanPreparationTax] =
    useState(loanPreparationTax2);
  const [invegaContractNumber, setInvegaContractNumber] = useState(
    invegaContractNumber2
  );
  const [euriborChangeDate, setEuriborChangeDate] =
    useState(euriborChangeDate2);
  
  const [firstInterestChangeDate, setFirstInterestChangeDate] = useState(
    firstInterestChangeDate2
  );
  
  const [interestTypeId, setInterestTypeId] = useState(interestTypeId2);
  const [euriborPeriodId, setEuriborPeriodId] = useState(euriborPeriodId2);
  const [invegaLoanDelayTerm, setInvegaLoanDelayTerm] = useState(
    loanDelayTerm ? loanDelayTerm : null
  );
  //const [invegaLoanDelayTermDate, setInvegaLoanDelayTermDate] = useState(
  //  invegaLoanDelayTermDate2
  //);
  const [invegaPaymentDate, setInvegaPaymentDate] =
    useState(invegaPaymentDate2);
  const [fileToUpload, setFileToUpload] = useState(null);
  const cancel = () => {
    setNotValid(false);
    setFileToUpload(null);

    const open = {
      modalName: 'changeContractFile',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const setInterestRateState = (val) => {
    setInvegaLoanInterestRate(val);
  };

  const validateFieldsBasedOnInterestType = (selectedField) => {
    if (interestTypeId != '2') {
      return true;
    }
    return selectedField;
  };

  const yes = () => {
    if (
      invegaLoanInterestRate &&
      //validateFieldsBasedOnInterestType(invegaLoanVariableInterestRate) &&
      invegaLoanPaymentTerm &&
      //validateFieldsBasedOnInterestType(euriborChangeDate) &&
      //euriborChangeDate &&
      isSumValid2(invegaLoanSum) === null &&
      isInterestRateValid(invegaLoanInterestRate) === null &&
      //isInterestVariableRateValid(invegaLoanVariableInterestRate) === null &&
      isInterestRateValid(loanPreparationTax) === null &&
      invegaContractNumber &&
      invegaLoanDelayTerm &&
      invegaPaymentDate &&
      fileToUpload
    ) {
      const formData = new FormData();
      const formattedData = format(
        new Date(invegaLoanPaymentTerm),
        'yyyy-MM-dd'
      );
      const formattedData2 = format(
        new Date(firstInterestChangeDate),
        'yyyy-MM-dd'
      );
      const formattedData3 = format(new Date(euriborChangeDate), 'yyyy-MM-dd');
      formData.append('RequestId', requestId);
      formData.append('InvegaLoanInterestRate', invegaLoanInterestRate);
      formData.append('InterestTypeId', interestTypeId ? interestTypeId : 1);
      if (interestTypeId == 1 || interestTypeId == 3) {
        formData.append(
          'EuriborChangeDate', formattedData3);
         formData.append('EuriborPeriodId', euriborPeriodId ? euriborPeriodId : 1);
         formData.append(
          'InvegaLoanVariableInterestRate',
           invegaLoanVariableInterestRate);
    };
    
    if (interestTypeId == 3) {
      formData.append('FirstInterestChangeDate', formattedData2);
     }
      formData.append('InvegaLoanPaymentTerm', formattedData);
      formData.append('InvegaLoanSum', invegaLoanSum);
      formData.append('LoanPreparationTax', loanPreparationTax);
      formData.append('InvegaContractNumber', invegaContractNumber);

      formData.append('InvegaLoanDelayTerm', invegaLoanDelayTerm);
      //formData.append(
      //  'InvegaLoanDelayTermDate',
      //  invegaLoanDelayTermDate
      //    ? format(new Date(invegaLoanDelayTermDate), 'yyyy-MM-dd')
      //    : ''
      //);
      formData.append(
        'InvegaPaymentDate',
        invegaPaymentDate
          ? format(new Date(invegaPaymentDate), 'yyyy-MM-dd')
          : ''
      );
      formData.append('File', fileToUpload);
      setIsloading(true);
      fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadContract(comment), {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok === true) {
            dispatch(actions.backoffice.getRequestAction(requestId));
            dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
            dispatch(
              actions.backoffice.saveEditAdditionalComments('get', requestId)
            );
            dispatch(actions.backoffice.getRequestLogAction(requestId));

            cancel();

            swal({
              title: 'Sutartis sėkmingai atnaujinta',
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn btn-pink',
                  closeModal: true,
                },
              },
            });
          } else if (res.status === 403) {
            swal({
              title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          } else {
            res.json().then((r) => {
              // dispatch(actions.backoffice.callSagaForErrHandling(r));

              console.log(r);

              swal({
                title: `${
                  r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
                }`,
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Gerai',
                    value: true,
                    visible: true,
                    className: 'btn swal-ok-btn',
                    closeModal: true,
                  },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsloading(false);
        });
    } else {
      setNotValid(true);
    }
  };
  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const onInterestTypeIdChange = (val) => {
    setInterestTypeId(val.target.value);
  };

  const onEuriborPeriodIdChange = (val) => {
    setEuriborPeriodId(val.target.value);
  };

  const validateTextinput = (val, name, length, msg = 'Neįvestas') => {
    if (val == null || val.length === 0) {
      return `${msg} ${name} `;
    } else if (length == null ? val.length < length : val.length < length) {
      return `* per trumpas ${name} `;
    } else {
      return null;
    }
  };

  const checkLoanDelayTermFormat = (val) => {
    if (!Number.isInteger(+val)) {
      return 'Turi būti įvestas sveikas skaičius';
    }
    // if (
    //   +val > 0 &&
    //   +val <= 120 &&
    //   invegaLoanPaymentTerm &&
    //   +val > +invegaLoanPaymentTerm
    // ) {
    //   return ' lauko [paskolos atidėjimo terminas (mėn)] reikšmė negali būti didesnė negu lauko [Pageidaujamas Paskolos terminas mėn].';
    // }
    if (+val >= 1 && +val <= 120) {
      return null;
    }

    return 'Turi būti įvestas sveikas skaičius nuo 1 iki 120';
  };

  {
    /*const returnFirstInterestChangeDate = () => {
    const invedaSign = new Date(invegaSigningDate);
    if (isNaN(invedaSign)) {
      return '';
    }
    if (euriborPeriodId == '4') {
      let nextYearFirstDay = setYear(invedaSign, invedaSign.getFullYear() + 1);
      nextYearFirstDay = setMonth(nextYearFirstDay, 0);
      nextYearFirstDay = setDate(nextYearFirstDay, 1);

      return nextYearFirstDay;
    } else if (euriborPeriodId == '3') {
      const month = getMonth(invedaSign);
      let halfYearLater = new Date(invedaSign);

      if (month > 5) {
        halfYearLater = addMonths(invedaSign, 6);
        halfYearLater = setMonth(halfYearLater, 0);
        halfYearLater = setDate(halfYearLater, 1);
      } else {
        halfYearLater = setMonth(halfYearLater, 5);
        halfYearLater = setDate(halfYearLater, 1);
      }

      return halfYearLater;
    } else if (euriborPeriodId == '2') {
      const month = getMonth(invedaSign);
      let quarterYearLater = new Date(invedaSign);

      if (month < 3) {
        quarterYearLater = setMonth(quarterYearLater, 3);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 2 && month < 6) {
        quarterYearLater = setMonth(quarterYearLater, 7);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 5 && month < 9) {
        quarterYearLater = setMonth(quarterYearLater, 9);
        quarterYearLater = setDate(quarterYearLater, 1);
      } else if (month > 8) {
        quarterYearLater = setYear(invedaSign, invedaSign.getFullYear() + 1);
        quarterYearLater = setMonth(quarterYearLater, 0);
        quarterYearLater = setDate(quarterYearLater, 1);
      }

      return quarterYearLater;
    }

    return '';
  };*/
  }

  //useEffect(() => {
  //  setFirstInterestChangeDate(returnFirstInterestChangeDate());
  //}, [euriborPeriodId]);

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{paddingBottom:'60px'}}
        >
          <WithSpinner loading={isLoading}>
            <h2>Sutarties keitimas</h2>
            <div className={styles.inputsContainer}>
              <div
                className={styles.inputsInline}
                style={{ marginLeft: '2.5rem' }}
              >
                <div style={{ height: '126px' }}>
                  <InputWithTextInline
                    type="string"
                    value={invegaContractNumber}
                    onChange={(e) => setInvegaContractNumber(e.target.value)}
                    placeholder={'Sutarties numeris'}
                    disabled={false}
                  />
                  {notValid && !invegaContractNumber && (
                    <p
                      className={styles.error}
                   
                    >
                      Neįvestas sut. numeris
                    </p>
                  )}
                </div>
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                >
                  <label className="inlineDatepickerInputTitle">
                    Galutinis paskolos grąžinimo terminas
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    value={format(
                      new Date(invegaLoanPaymentTerm),
                      'yyyy-MM-dd'
                    )}
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setinvegaLoanPaymentTerm(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                </div>
                {notValid && !invegaLoanPaymentTerm && (
                  <p className={styles.error}>
                    Neįvestas terminas
                  </p>
                )}

                <div className={styles.bottomInput}>
                  <InputWithTextInline
                    type="string"
                    value={invegaLoanInterestRate}
                    onChange={(e) => setInvegaLoanInterestRate(e.target.value)}
                    placeholder={'Marža, %'}
                    disabled={false}
                    style={{ marginTop: '0px' }}
                  />
                  {notValid && (
                    <p className={styles.error}>
                      {isInterestRateValid(
                        invegaLoanInterestRate,
                        'privalomas laukas',
                        3
                      )}
                      {/* Privalote įrašyti paskolos sumą */}
                    </p>
                  )}
                </div>
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                >
                  <label
                    style={{ marginTop: '33px', width: '110%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Palūkanų normos rūšis
                  </label>

                  <select
                    disabled={false}
                    name="interestTypeId"
                    onChange={onInterestTypeIdChange}
                    value={interestTypeId}
                    style={{ marginTop: '0.4rem' }}
                  >
                    <option value={'1'}>Kintama</option>
                    <option value={'2'}>Fiksuota</option>
                    <option value={'3'}>Dalinai fiksuota</option>
                  </select>
                </div>
                {interestTypeId != '2' && (
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1rem' }}
                >
                  <label
                    style={{ marginTop: '40px', width: '110%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Taikomo Euribor data
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '20px' }}
                    value={euriborChangeDate ? new Date(euriborChangeDate) : ''}
                    selected={
                      euriborChangeDate ? new Date(euriborChangeDate) : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setEuriborChangeDate(e)}
                    minDate={subDays(new Date(), 10)}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                   {notValid && !euriborChangeDate && interestTypeId != '2' && (
                    <p className={styles.error}>Neįvesta data</p>
                  )}
                </div>)}
                <div style={{ height: '135px', marginTop: '-20px' }}>
                  <InputWithTextInline
                    type="string"
                    value={loanPreparationTax}
                    onChange={(e) => setLoanPreparationTax(e.target.value)}
                    placeholder={'Sutarties paruošimo mokestis'}
                    disabled={false}
                  />
                  {notValid && (
                    <p className={styles.error}>
                      {isInterestRateValid(
                        loanPreparationTax,
                        'privalomas laukas',
                        3
                      )}
                      {/* Privalote įrašyti paskolos sumą */}
                    </p>
                  )}
                </div>
              </div>
              <div
                className={styles.inputsInline}
                style={{ marginRight: '2.5rem' }}
              >
                <div style={{ height: '135px' }}>
                  <InputWithTextInline
                    type="string"
                    value={invegaLoanSum}
                    onChange={(e) => setInvegaLoanSum(e.target.value)}
                    placeholder={'Paskolos suma'}
                    disabled={false}
                  />
                  {notValid && (
                    <p className={styles.error}>
                      {isSumValid2(invegaLoanSum, 'privalomas laukas', 3)}
                      {/* Privalote įrašyti paskolos sumą */}
                    </p>
                  )}
                </div>
                <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1.5rem' }}
                >
                  <label
                    
                    className="inlineDatepickerInputTitle"
                  >
                    Paskolos atidėjimo terminas (mėn.)
                  </label>
                  <InputWithTextInline
                    type="string"
                    value={invegaLoanDelayTerm}
                    onChange={(e) => setInvegaLoanDelayTerm(e.target.value)}
                    //placeholder={'Paskolos atidėjimo terminas (mėn.)'}
                    disabled={false}
                    style={{ marginTop: '-2.1rem' }}
                  />
                  {/*<DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '0px' }}
                    value={
                      invegaLoanDelayTermDate
                        ? new Date(invegaLoanDelayTermDate)
                        : ''
                    }
                    selected={
                      invegaLoanDelayTermDate
                        ? new Date(invegaLoanDelayTermDate)
                        : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setInvegaLoanDelayTermDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  
                  {notValid && !invegaLoanDelayTermDate && (
                    <p
                      className={styles.error}
                      style={{ marginLeft: '2.2rem' }}
                    >
                      Neįvesta data
                    </p>
                  )}*/}
                  {notValid &&
                    (validateTextinput(
                      invegaLoanDelayTerm,
                      'atidėjimo terminas',
                      1
                    ) ||
                      checkLoanDelayTermFormat(invegaLoanDelayTerm)) && (
                      //!disableCheck('LoanDelayTerm') &&
                      <p className={styles.error} style={{ width: '140px' }}>
                        {validateTextinput(
                          invegaLoanDelayTerm,
                          'atidėjimo terminas',
                          1
                        )}
                        {!validateTextinput(
                          invegaLoanDelayTerm,
                          'atidėjimo terminas',
                          1
                        ) && checkLoanDelayTermFormat(invegaLoanDelayTerm)}
                      </p>
                        )}
                    
                </div>
                <div
                  className={styles.inputsInline}
                  style={{
                    marginRight: '2.5rem',
                    width: '180px',
                    marginTop: '0.4rem',
                  }}
                >
                  <label
                    style={{ marginTop: '3px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Išmokėjimo terminas{' '}
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '0px' }}
                    value={invegaPaymentDate ? new Date(invegaPaymentDate) : ''}
                    selected={
                      invegaPaymentDate ? new Date(invegaPaymentDate) : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setInvegaPaymentDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && !invegaPaymentDate && (
                    <p
                      className={styles.error}
                      style={{ marginLeft: '2.2rem' }}
                    >
                      Neįvesta data
                    </p>
                  )}
                </div>
                {interestTypeId != '2' && (
                <div className={styles.bottomInput}  style={{ marginTop: '-1rem' }}>
                  
                    <InputWithTextInline
                  type="string"
                  value={invegaLoanVariableInterestRate}
                  onChange={(e) => setInvegaLoanVariableInterestRate(e.target.value)}
                  placeholder={'Kintama palūkanų dalis, %'}
                  disabled={false}
                  //style={{ marginTop: '10px' }}
                />
                {notValid && interestTypeId != '2' && (
                  <p className={styles.error}>
                    {isInterestVariableRateValid(
                      invegaLoanVariableInterestRate,
                      'privalomas laukas',
                      3
                    )}
                  </p>
                )}
                   
                  </div>
                )}
                  {interestTypeId != '2' && (
                  <div
                  className={styles.bottomInput}
                  style={{ marginTop: '-1.5rem' }}
                >
                  <label
                    style={{ marginTop: '33px', width: '110%' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Palūkanų normos perskaičiavimo dažnumas
                  </label>
                  <select
                    disabled={false}
                    name="euriborPeriodId"
                    onChange={onEuriborPeriodIdChange}
                    value={euriborPeriodId}
                  >
                    <option value={'1'}>1 mėn.</option>
                    <option value={'2'}>3 mėn.</option>
                    <option value={'3'}>6 mėn.</option>
                    <option value={'4'}>12 mėn.</option>
                  </select>
                </div>)}
                {interestTypeId == '3' && (
                <div
                  style={{
                    marginRight: '2.5rem',
                    width: '180px',
                    //marginTop: '1.25rem',
                  }}
                >
                  <label
                    style={{ marginTop: '18px' }}
                    className="inlineDatepickerInputTitle"
                  >
                    Pirma palūkanų keitimo diena{' '}
                  </label>
                  <DatePicker
                    className="inlineDatepickerInput"
                    style={{ marginLeft: '0px', padding: '0px' }}
                    value={firstInterestChangeDate ? new Date(firstInterestChangeDate) : ''}
                    selected={
                      firstInterestChangeDate ? new Date(firstInterestChangeDate) : ''
                    }
                    // onChange={(e) => console.log(e)}

                    onChange={(e) => setFirstInterestChangeDate(e)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    disabled={false}
                  />
                  {notValid && interestTypeId == '3' && !firstInterestChangeDate && (
                    <p
                      className={styles.error}
                      style={{ marginLeft: '2.2rem' }}
                    >
                      Neįvesta data
                    </p>
                  )}
                </div>
                )}
              </div>

            </div>
            <div className={styles.addFileContainer}>
              <Button type="standart" onClick={() => selectFile('fileElem')}>
                Prisegti priedą
              </Button>
              <input
                type="file"
                id="fileElem"
                accept="application/pdf"
                className="d-none"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <p style={{ marginTop: '0.5rem' }}>
                {fileToUpload && fileToUpload.name ? fileToUpload.name : ''}
              </p>
              {notValid && !fileToUpload && (
                <p
                  className={styles.error}
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  Privalote pasirinkti priedą
                </p>
              )}
            </div>
            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(requestId)}>
                Išsaugoti
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.dashboardState.isOnSync,
// });

export default connect()(ChangeContractFile);
