import React from 'react';
import styles from './rowTable.module.scss';

const RowTable = ({ children, header }) => {
  const renderHeader = () =>
    header.map((col, i) => {
      return (
        <span className={styles.headerCell} key={i} role="columnheader">
          <div
            className={
              col !== 'Sukūrimo data'
                ? col !== 'Pateikimo vertinimui data'
                  ? styles.headerTitle
                  : styles.headerTitle
                : styles.headerTitleDates
            }
          >
            {col}
          </div>
        </span>
      );
    });

  return (
    <div className={styles.main}>
      <div className={styles.table} role="grid">
        <div className={styles.header} role="presentation">
          <div className={styles.headerRow} role="row">
            {renderHeader()}
          </div>
        </div>
        <div className={styles.body} role="presentation">
          {children}
        </div>
      </div>
    </div>
  );
};

export const RowTableRow = ({ children, onClick }) => (
  <div className={styles.bodyRow} onClick={onClick} role="row">
    {children}
  </div>
);

export const RowTableCell = ({ children }) => (
  <span className={styles.bodyCell} role="gridcell">
    {children}
  </span>
);

export default RowTable;
