import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginModal from '../../modals/loginModal/loginModal';
import { Redirect } from 'react-router-dom';
import { config } from '../../api/config';

import styles from './Guest.module.scss';

const Guest = ({ loggedIn }) => {
  useEffect(() => {
    // {!loggedIn &&
    // removeLocalStorageItem('userToken');
    // removeLocalStorageItem('token');
    // }
  }, [loggedIn]);
  return (
    <div className={styles.container}>
      <h2>Finansinės priemonės „Startuok“ paskolos</h2>
      <div style={{ width: '100%', color: '#000' }}></div>
      {loggedIn ? <Redirect to={config.APP_PATHS.main} /> : null}
      <LoginModal open={true} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps)(Guest);

// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import LoginModal from '../../modals/loginModal/loginModal';
// import { Redirect } from 'react-router-dom';
// import { config } from '../../api/config';
// import { removeLocalStorageItem } from '../../utils';

// import styles from './Guest.module.scss';

// const Guest = ({ loggedIn }) => {
//   useEffect(() => {
//     // eslint-disable-next-line no-lone-blocks
//     // {
//     //   !loggedIn && removeLocalStorageItem('userToken');
//     //   removeLocalStorageItem('token');
//     // }
//   }, [loggedIn]);
//   return (
//     <div className={styles.container}>
//       <h2>Finansinės priemonės „Startuok“ paskolos</h2>
//       <div style={{ width: '100%', color: '#000' }}></div>
//       {loggedIn ? <Redirect to={config.APP_PATHS.main} /> : null}
//       <LoginModal open={true} />
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   loggedIn: state.auth.loggedIn,
// });

// export default connect(mapStateToProps)(Guest);
