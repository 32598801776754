import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styles from './input.module.scss';

const InputWithTrueFalseSelection = ({
  options,
  name,
  type,
  onChange,
  value,
  index,
  placeholder,
  disabled,
  style,
  ...rest
}) => {
  return (
    <div className={styles.inlineContainer}>
      <div className={styles.titleContainer}>
        <p>{placeholder}</p>
      </div>
      <Select
        value={options.find((option) => option.value === value)}
        // onChange={(e) => onChange(e.value, index)}
        options={(options, (disabled = { disabled }))}
        placeholder={'-'}
        className={styles.selectorContainer}
        disabled={disabled}
        styles={style}
      />
    </div>
  );
};

InputWithTrueFalseSelection.defaultProps = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default InputWithTrueFalseSelection;
