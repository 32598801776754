import React from 'react';
import { Link } from 'react-router-dom';
import styles from './about.module.scss';

const About = () => {
  return (
    <div className="container">
      <div className="row">
        <div class="col-lg-12">
          <div className={styles.aboutAllBlock}>
            <h1>Apie projektą</h1>
            <p>
              Ši interneto platforma skirta Verslo subjektams, norintiems teikti
              paraiškas pagal finansinę priemonę „Startuok” (toliau – Priemonė)
              ir gauti finansavimą paskolų forma investicinėms arba apyvartinėms
              išlaidoms finansuoti.
            </p>

            <p>
              Priemonei įgyvendinti skirta iki 20 mln. Eur Europos Sąjungos
              fondų lėšų. Priemonei skirta lėšų suma gali būti didinama arba
              mažinama, atsižvelgiant į rinkos poreikį.
            </p>

            <p>
              Priemonę parengė Ekonomikos ir inovacijų ministerija ir UAB
              Investicijų ir verslo garantijos (INVEGA). Projekto techninę pusę
              įgyvendina bendrovė „Fintegry".
            </p>
            <p>
              Daugiau informacijos apie finansavimo teikimo sąlygas rasite{' '}
              <Link to={'/description'}>priemonės aprašyme</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
