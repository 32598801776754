import React, { useEffect, useState } from 'react';
import { actions } from '../../state/actions';
import { connect } from 'react-redux';
import {
  WithSpinner,
  TableAdmin,
  TableRow,
  TableCell,
  Button,
} from '../../components';
import { Api } from './../../api/api';
import { config } from './../../api/config';
import { Link } from 'react-router-dom';
import { requestStatusForStats } from './../../utils/translation/translation';
import { formatNumberStats } from '../../utils/helpers/helpers';
import swal from 'sweetalert';
import { format } from 'date-fns';

const TurnoversFiles = ({ dispatch, statsState }) => {
  const [isLoading, setIsloading] = useState(false);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsloading(true);
        const response = await Api.ExcelList();
        setListData(response);
        console.log(response);
      } catch (e) {
        if (e.statusCode === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else
          swal({
            title: `Klaida`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
      } finally {
        setIsloading(false);
      }
    };
    getData();
  }, []);

  const tableHeader = ['Suformavimo data', 'Priedo pavadinimas', ''];

  const download = (id, name) => {
    console.log(id);

    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.ExcelDownloadFile(id), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        const fileName = `${name}`;
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  return (
    <WithSpinner loading={isLoading}>
      <div style={{ width: '80%', marginLeft: '10%' }}>
        <h2 style={{ marginTop: '1rem', marginBottom: '2rem' }}>
          Apyvartų excel failai
        </h2>
        <Link
          to="/backoffice/main"
          style={{
            color: '#fff',
            textDecoration: 'none',
          }}
        >
          <Button type="standart">Atgal</Button>
        </Link>

        <TableAdmin header={tableHeader} isEmpty={false}>
          {listData.length > 0 &&
            listData.map(({ Created, Id, Name }) => (
              <TableRow key={Id}>
                <TableCell>
                  {format(new Date(Created), 'yyyy-MM-dd hh:mm:ss')}
                </TableCell>
                <TableCell>{Name}</TableCell>
                <TableCell>
                  <div style={{ float: 'right', marginRight: '1rem' }}>
                    <p onClick={() => download(Id, Name)} className="pdownload">
                      Parsisiųsti
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableAdmin>
      </div>
    </WithSpinner>
  );
};

const mapStateToProps = (state) => ({
  statsState: state.backofficeStatsState,
});

export default connect(mapStateToProps)(TurnoversFiles);
