import request from 'request-promise';
import { config } from './config';
import { getLocalStorageItemIfNotExpired } from '../utils';
import { checkBOPage } from '../utils/helpers/helpers';

export class Api {
  static API_BASE_URL =
    process.env.NODE_ENV === 'production'
      ? config.API_BASE_URL_PROD
      : config.API_BASE_URL_DEV;

  static BASE_URL = Api.API_BASE_URL;

  static ENDPOINTS = config.API_ENDPOINTS;

  static getTokenFromLocalStorage() {
    const token = getLocalStorageItemIfNotExpired('userToken');
    return token;
  }

  static getManagerTokenFromLocalStorage() {
    const token = getLocalStorageItemIfNotExpired('token');
    return token;
  }

  static getLoginTypeFromLocalStorage() {
    const loginType = getLocalStorageItemIfNotExpired('loginType');
    return loginType;
  }

  static async withAuth(options) {
    const finalOptions = {
      json: true,
      withCredentials: true,

      headers: {
        withCredentials: true,
      },
      ...options,
    };
    return await request(finalOptions);
  }

  static async withMangerAuth(options) {
    const finalOptions = {
      json: true,
      withCredentials: true,

      headers: {
        withCredentials: true,
      },
      ...options,
    };
    return await request(finalOptions);
  }

  static async withAuthFileUpload(options) {
    const finalOptions = {
      json: true,
      ...options,
    };

    return await request(finalOptions);
  }

  static async withoutAuth(options) {
    const finalOptions = {
      json: true,
      ...options,
    };
    return await request(finalOptions);
  }

  static async getToken(credentials) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.backOfficeLogin,
      body: credentials,
    };
    return await Api.withoutAuth(options);
  }

  static async customLogin() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.customLogin,
    };
    return await Api.withoutAuth(options);
  }

  // login
  static async Login() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.login,
    };
    return await Api.withoutAuth(options);
  }

  static async loginVaiisisTest(credentials) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.vaiisisTest,
      body: credentials,
    };
    return await Api.withoutAuth(options);
  }

  static async Logoff() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.logoff,
    };
    return await Api.withoutAuth(options);
  }

  // Invoices
  static async getSellerInvoiceList(requestId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.invoiceSellerList(requestId),
      body: {},
    };
    return await Api.withAuth(options);
  }

  static async getInvoiceData(requestId, invoiceId) {
    const options = {
      method: 'GET',
      url:
        Api.BASE_URL +
        Api.ENDPOINTS.invoiceData(
          requestId,
          invoiceId === null ? '' : invoiceId
        ),
      body: {},
    };
    return await Api.withAuth(options);
  }

  static async getOwnerInvoiceData(requestId, invoiceId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.invoiceOwnerData(requestId, invoiceId),
      body: {},
    };
    return await Api.withAuth(options);
  }

  static async saveInvoiceData(requestId, data) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.saveInvoiceData(requestId),
      body: data,
    };
    return await Api.withAuth(options);
  }

  static async getBuyerInvoiceList() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.invoiceBuyerList,
      body: {},
    };
    return await Api.withAuth(options);
  }

  static async deleteInvoice(invoiceId) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.invoiceDelete(invoiceId),
      body: {},
    };
    return await Api.withAuth(options);
  }

  static async confirmInvoice(payload) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.confirmInvoice,
      body: payload,
    };
    return await Api.withAuth(options);
  }

  static async rejectInvoice(payload) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.rejectInvoice,
      body: payload,
    };
    return await Api.withAuth(options);
  }

  // Requests

  static async getRequestList() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.requestList,
    };
    return await Api.withAuth(options);
  }

  static async requestStart(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestStart,
      body,
    };
    return await Api.withAuth(options);
  }

  static async requestGet(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.requestGet(id),
    };
    return await Api.withAuth(options);
  }

  static async requestSave(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestSave,
      body,
    };
    return await Api.withAuth(options);
  }

  static async requestdelete(id) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.requestdelete(id),
    };
    return await Api.withAuth(options);
  }

  static async requestSendForApprove(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestSendForApprove(id),
    };
    return await Api.withAuth(options);
  }

  static async DownloadList(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.DownloadList,
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async requestSendForApproveWithDocs(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestSendForApproveWithDocs(id),
    };
    return await Api.withAuth(options);
  }

  static async requestApprove(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestApprove,
      body,
    };
    return await Api.withAuth(options);
  }

  static async checkForApprove(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.checkForApprove,
      body,
    };
    return await Api.withAuth(options);
  }

  static async requestReject(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestReject,
      body,
    };
    return await Api.withAuth(options);
  }

  static async requestReturn(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.requestReturn,
      body,
    };
    return await Api.withAuth(options);
  }

  static async ManagerSaveCompanySizeType(body, id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.SetCompanySizeType(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async startRegistersProcessing(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.startRegistersProcessing,
      body,
    };
    return await Api.withAuth(options);
  }

  static async startRegistersProcessingIndividual(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.startRegistersProcessingIndividual,
      body,
    };
    return await Api.withAuth(options);
  }

  static async saveCompanyData(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.companyCreate,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getRegistersProcessingStatus(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getRegistersProcessingStatus(id),
    };
    return await Api.withAuth(options);
  }

  static async getRegistersProcessingStatusIndividual(id) {
    const options = {
      method: 'GET',
      url:
        Api.BASE_URL + Api.ENDPOINTS.getRegistersProcessingStatusIndividual(id),
    };
    return await Api.withAuth(options);
  }

  static async getFinancialCriteriaResults(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getFinancialCriteriaResults(id),
    };
    return await Api.withAuth(options);
  }

  static async getFinancialCriteriaResultsFinal(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getFinancialCriteriaResultsFinal(id),
    };
    return await Api.withAuth(options);
  }

  static async getFinancialCriteriaResultsForSaveRequest(id) {
    const options = {
      method: 'GET',
      url:
        Api.BASE_URL +
        Api.ENDPOINTS.getFinancialCriteriaResultsForSaveRequest(id),
    };
    return await Api.withAuth(options);
  }

  static async copy(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.copy(id),
    };
    return await Api.withAuth(options);
  }

  static async doubleCheckRequestEvaluation(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.doubleCheckRequestEvaluation(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async getFinancialCriteriaResultsManager(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getFinancialCriteriaResultsFinal(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async calculateFinancialCriteriaManager(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.calculateFinancialCriteria,
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async isConsentAgreed() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.isConsentAgreed,
    };
    return await Api.withAuth(options);
  }

  static async getConsent() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getConsent,
    };
    return await Api.withAuth(options);
  }

  static async createConsent(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.createConsent,
      body,
    };
    return await Api.withAuth(options);
  }

  static async getRequestLimit(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.requestInitialLimit(id),
    };
    return await Api.withAuth(options);
  }

  static async GetFinancialEvaluationStatus(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetFinancialEvaluationStatus(id),
    };
    return await Api.withAuth(options);
  }

  static async getRequestGeneralLimit(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.requestLimit(id),
    };
    return await Api.withAuth(options);
  }

  static async canCreateRequest() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.canCreateRequest,
    };
    return await Api.withAuth(options);
  }

  static async getGeneralData() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getGeneralData,
    };
    return await Api.withoutAuth(options);
  }

  static async getBackofficeGeneralData() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getGeneralData,
    };
    return await Api.withoutAuth(options);
  }

  static async DownloadFile(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.DownloadFile(id),
    };
    return await Api.withAuthFileUpload(options);
  }

  static async DeleteUploadedFile(id) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.DeleteUploadedFile(id),
    };
    return await Api.withAuthFileUpload(options);
  }

  static async GetUploadedFiles(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetUploadedFiles(id),
      withCredentials: true,
    };
    return await Api.withAuthFileUpload(options);
  }

  static async UploadFiles(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.UploadFiles,
      body: body,
    };
    return await Api.withAuthFileUpload(options);
  }

  static async GetManagerRequests(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.getManagerRequests,
      body: body.payload,
    };
    return await Api.withMangerAuth(options);
  }

  static async GetManagerRequest(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getManagerRequest(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async saveAdditionalComments(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.saveAdditionalComments,
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async saveRiskAssessmentResult(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.saveRiskAssessmentResult,
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async getAdditionalComments(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getAdditionalComments(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async SetInvegaRequestStatus(request) {
    let endpoint = '';
    switch (request.action) {
      case 'INVEGA_REJECT_REQUEST':
        endpoint = Api.ENDPOINTS.setRejectRequest(request.payload.requestId);
        break;
      case 'INVEGA_RETURN_REQUEST':
        endpoint = Api.ENDPOINTS.setReturnRequest(request.payload.requestId);
        break;
      case 'INVEGA_CONFIRM_REQUEST':
        endpoint = Api.ENDPOINTS.setApproveRequest(request.payload.requestId);
        break;
      case 'INVEGA_CONFIRMWITHDOCS_REQUEST':
        endpoint = Api.ENDPOINTS.setApproveWithDocsRequest(
          request.payload.requestId
        );
        break;
      case 'INVEGA_SIGN_REQUEST':
        endpoint = Api.ENDPOINTS.setSignedRequest(request.payload.requestId);
        break;
      case 'INVEGA_CHANGE_TO_SENDFORAPPROVE_REQUEST':
        endpoint = Api.ENDPOINTS.setSendForApproveRequest(
          request.payload.requestId
        );
        break;
        case 'INVEGA_CHANGE_TO_APPROVE_REQUEST':
        endpoint = Api.ENDPOINTS.setApproveRequest(
          request.payload.requestId
        );
        break;
        case 'INVEGA_CHANGE_TO_CHANGECONDITIONS_REQUEST':
          endpoint = Api.ENDPOINTS.setChangeConditionsRequest(
            request.payload.requestId
          );
          break;
          case 'INVEGA_CHANGE_TO_CHANGECONDITIONSAPPROVED_REQUEST':
            endpoint = Api.ENDPOINTS.setChangeConditionsApprovedRequest(
              request.payload.requestId
            );
            break;
    }

    const options = {
      method: 'POST',
      url: Api.BASE_URL + endpoint,
      body: {
        comment: request.payload.comment,
        userCommentRequired: request.payload.userCommentChecked,
      },
    };
    return await Api.withMangerAuth(options);
  }

  static async GetManagerInvoices(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getManagerInvoices(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async ReturnRequest(id, body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.setReturnRequest(id),
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async GetManagerInvoice(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getManagerInvoice(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async ExportData(body) {
    const options = {
      method: 'POST',
      encoding: null,
      url: Api.BASE_URL + Api.ENDPOINTS.ExportData,
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async GetManagerUploadedFiles(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getManagerUploadedFiles(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async GetStats() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getStats,
    };
    return await Api.withMangerAuth(options);
  }

  static async getSigningUrl(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getSigningUrl(id),
    };
    return await Api.withAuth(options);
  }

  static async getSigningUrlManager(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getSigningUrlManager(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async getSigningUrlDecision(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getSigningUrlDecision(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async regenerateDecisionDoc(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.regenerateDecisionDoc(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async getSigningUrlBatch() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getSigningUrlBatch,
    };
    return await Api.withMangerAuth(options);
  }

  static async saveUserComment(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.saveUserComment,
      body,
    };
    return await Api.withAuth(options);
  }

  static async SaveLoanSums(body, id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.SaveLoanSums(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async GenerateContractDoc(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.GenerateContractDoc(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async DownloadContractDoc(id) {
    const options = {
      method: 'GET',
      encoding: null,

      url: Api.BASE_URL + Api.ENDPOINTS.DownloadContractDoc(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async ManagerSaveAdditionalInfo(body, id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.SetAdditionalInfo(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async GetSignedDocumentManager(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocument(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async GetSignedDocumentUser(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocument(id),
    };
    return await Api.withAuth(options);
  }

  //usersigninin DOc download
  static async GetSignedDocumentContractUser(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocumentContract(id),
    };
    return await Api.withAuth(options);
  }
  static async GetSignedDocumentDecisionUser(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocumentDecision(id),
    };
    return await Api.withAuth(options);
  }

  //Invegasigninin DOc download
  static async GetSignedDocumentContractManager(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocumentContract(id),
    };
    return await Api.withMangerAuth(options);
  }
  static async GetSignedDocumentDecisionManager(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocumentDecision(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async GetSigningInfoUser(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSigningInfo(id),
    };
    return await Api.withAuth(options);
  }

  static async GetSigningInfoManager(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetSigningInfo(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async CanConfirmInvoicesFromManager(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.CanConfirmInvoicesFromManager(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async ConfirmInvoicesFromManager(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.ConfirmInvoicesFromManager(id),
    };
    return await Api.withMangerAuth(options);
  }
  static async saveRepresentative(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.saveRepresentative,
      body,
    };
    return await Api.withAuthForm(options);
  }

  static async saveRepresentativeManager(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.saveRepresentativeManager,
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async loadRepresentativesManager() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.loadRepresentativesManager,
    };
    return await Api.withMangerAuth(options);
  }

  static async loadRepresentatives() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.loadRepresentatives,
    };
    return await Api.withAuth(options);
  }

  static async deleteRepresentative(id) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.deleteRepresentative(id),
      body: {},
    };
    return await Api.withAuth(options);
  }

  static async deleteRepresentativeManager(id) {
    const options = {
      method: 'DELETE',
      url: Api.BASE_URL + Api.ENDPOINTS.deleteRepresentativeManager(id),
      body: {},
    };
    return await Api.withMangerAuth(options);
  }

  static async loadCompanyRepresentatives() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getCompanyRepresentations,
    };
    return await Api.withAuth(options);
  }

  static async isRepresentative() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.isRepresentative,
    };
    return await Api.withAuth(options);
  }

  static async getRequestLog(requestId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getRequestLog(requestId),
    };
    return await Api.withMangerAuth(options);
  }

  static async getInvegaUsers() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.getInvegaUsers,
    };
    return await Api.withMangerAuth(options);
  }

  static async CanCreateOrSubmit(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.CanCreateOrSubmit(id),
    };
    return await Api.withAuth(options);
  }

  static async GetIndividualEvrks(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.GetIndividualEvrks(id),
    };
    return await Api.withAuth(options);
  }

  static async GetCompanyEvrks(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.GetCompanyEvrks(id),
    };
    return await Api.withAuth(options);
  }

  static async GetAssessorsList() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetAssessorsList,
    };
    return await Api.withMangerAuth(options);
  }

  static async SetAssessor(equestId, userId) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.SetAssessor(equestId, userId),
    };
    return await Api.withAuth(options);
  }

  static async ExcelList() {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.ExcelList,
    };
    return await Api.withMangerAuth(options);
  }

  static async ExcelDownloadFile(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.ExcelDownloadFile(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async MarkForVmiRenewMass(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.MarkForVmiRenewMass,
      body: body,
    };
    return await Api.withAuth(options);
  }

  static async MarkForVmiAdditionalCheckMass(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.MarkForVmiAdditionalCheckMass,
      body: body,
    };
    return await Api.withAuth(options);
  }

  static async paymentRequestList(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequestList(id),
    };
    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
    //return await Api.withAuth(options);
  }

  static async paymentRequest(id, paymentId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequest(id, paymentId),
    };

    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
  }

  static async paymentRequestStart(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequestStart(body.requestId),
      body,
    };
    return await Api.withAuth(options);
  }

  static async paymentRequestStartWithSurvey(requestId, body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequestStart(requestId),
      body,
    };
    return await Api.withAuth(options);
  }

  static async GetRelatedRequests(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.GetRelatedRequests(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async paymentRequestSendForApprove(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequestSendForApprove(id),
    };
    return await Api.withAuth(options);
  }

  static async SetInvegaPaymentRequestStatus(paymentRequest) {
    let endpoint = '';
    switch (paymentRequest.action) {
      case 'INVEGA_REJECT_PAYMENT_REQUEST':
        endpoint = Api.ENDPOINTS.setRejectPaymentRequest(
          paymentRequest.payload.id
        );
        break;
      case 'INVEGA_RETURN_PAYMENT_REQUEST':
        endpoint = Api.ENDPOINTS.setReturnPaymentRequest(
          paymentRequest.payload.id
        );
        break;
      case 'INVEGA_CONFIRM_PAYMENT_REQUEST':
        endpoint = Api.ENDPOINTS.setApprovePaymentRequest(
          paymentRequest.payload.id
        );
        break;
      default:
        return null;
    }

    const options = {
      method: 'POST',
      url: Api.BASE_URL + endpoint,
      body: {
        comment: paymentRequest.payload.comments,
      },
    };
    return await Api.withMangerAuth(options);
  }
  static async paymentRequestSavePrDocument(body, id) {
    const options = {
      method: 'POST',
      url: checkBOPage()
        ? Api.BASE_URL + Api.ENDPOINTS.paymentRequestSavePrDocumentManager(id)
        : Api.BASE_URL + Api.ENDPOINTS.paymentRequestSavePrDocument(id),
      body,
    };

    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
  }

  static async returnPaymentWithComment(body, id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.returnPaymentWithComment(id),
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async DownloadFilePrdAll(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.DownloadFilePrdAll(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async SavePrDocumentPayBack(id, body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.SavePrDocumentPayBack(id),
      body,
    };
    return await Api.withMangerAuth(options);
  }

  static async paymentRequestPrDocument(id, docId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequestPrDocument(id, docId),
    };
    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
  }

  static async paymentRequestPrDocumentPayBack(
    paymentRequestId,
    parentPrDocumentId,
    prDocumentId
  ) {
    const options = {
      method: 'GET',
      url:
        Api.BASE_URL +
        Api.ENDPOINTS.paymentRequestPrDocumentPayBack(
          paymentRequestId,
          parentPrDocumentId,
          prDocumentId
        ),
    };
    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
  }

  static async paymentRequestDeletePrDocument(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.paymentRequestDeletePrDocument(id),
    };
    return await Api.withAuth(options);
  }
  static async ExportPr(id, paymentId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.ExportPr(id, paymentId),
    };
    return await Api.withMangerAuth(options);
  }
  static async GetActionLogManager(paymentRequestId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetActionLogManager(paymentRequestId),
    };
    return await Api.withMangerAuth(options);
  }

  static async GetAdministrationInvoices(requestId) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetAdministrationInvoices(requestId),
    };
    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
  }

  static async DownloadAdministrationInvoice(contractId) {
    const options = {
      method: 'GET',
      url:
        Api.BASE_URL + Api.ENDPOINTS.DownloadAdministrationInvoice(contractId),
    };
    return checkBOPage()
      ? await Api.withMangerAuth(options)
      : await Api.withAuth(options);
  }

  static async RequestChangeConditions(id, body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.RequestChangeConditions(id),
      body,
    };
    return await Api.withAuth(options);
  }

  static async GetEditListManagerByRequest(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.GetEditListManagerByRequest(id),
    };
    return await Api.withMangerAuth(options);
  }

  static async requestSendForApproveWithDocsForChangeConditions(id) {
    const options = {
      method: 'POST',
      url:
        Api.BASE_URL +
        Api.ENDPOINTS.requestSendForApproveWithDocsForChangeConditions(id),
    };
    return await Api.withAuth(options);
  }

  static async GetSignedDocumentRejectDecision(id) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
      url: Api.BASE_URL + Api.ENDPOINTS.GetSignedDocumentRejectDecision(id),
    };
    return await Api.withMangerAuth(options);
  }
  static async ManagerSaveRiskLevel(id, level) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.ManagerSaveRiskLevel(id, level),
    };
    return await Api.withAuth(options);
  }

  static async ManagerSaveRiskAssessmentResult(body) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.ManagerSaveRiskAssessmentResult,
      body,
    };
    return await Api.withAuth(options);
  }

  static async RecheckAml(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.RecheckAml(id),
    };
    return await Api.withAuth(options);
  }

  static async RecheckJadis(id) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.RecheckJadis(id),
    };
    return await Api.withAuth(options);
  }

  static async RecheckJarManager(code) {
    const options = {
      method: 'POST',
      url: Api.BASE_URL + Api.ENDPOINTS.RecheckJarManager(code),
    };
    return await Api.withAuth(options);
  }
  static async DownloadAllFiles(id) {
    const options = {
      method: 'GET',
      url: Api.BASE_URL + Api.ENDPOINTS.DownloadAllFiles(id),
    };
    return await Api.withAuth(options);
  }
}