import React, { useState } from 'react';
import styles from './submenu.module.scss';
import { connect } from 'react-redux';
import { Button } from '../../components';
import { config } from '../../api/config';
import { hasRole, RoleEnum, isLoginType, LoginTypeEnum } from '../../utils';
import { actions } from '../../state';

const Submenu = ({ dispatch }) => {
  // const path = window.location.pathname.split('/')[2];
  // console.log(path);

  const [requestsActive, setRequestsAcvive] = useState(1);

  const clickMenu = (menuId) => {
    dispatch(actions.dashboard.loadGeneralDataAction());
    setRequestsAcvive(menuId);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          background: '#ffd663',
          paddingBottom: '14px',
          paddingTop: '15px',
        }}
      >
        <p style={{ width: '1140px', textAlign: 'left', paddingLeft: '40px' }}>
          Informuojame: nuo rugsėjo 9 d. <b>INVEGA tampa ILTE</b>.{' '}
        </p>
      </div>
    <div
      style={{
        // width: '22rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2rem',
        width: '405px',
      }}
      className={styles.header}
    >
      <Button
        type="bgHeader"
        color={requestsActive === 1 ? '#033878' : '#597a96'}
        url={config.APP_PATHS.requests}
        onClick={() => clickMenu(1)}
      >
        PARAIŠKŲ SĄRAŠAS
      </Button>
    </div> </div>
  );
};

export default connect()(Submenu);
