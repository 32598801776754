import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './acceptConditionsChangeRequestModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';

const SubmitConditionsChangeRequestModal = ({ open, requestId, dispatch }) => {
  const [isLoading, setIsloading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState([]);
  const [comment, setComment] = useState('');
  const [badFinancialCondition, setBadFinancialCondition] = useState(false);

  const onChangeContractCommentChange = ({ target: { name, value } }) => {
    setComment(value);
  };

  const selectFileToUpload = (file) => {
    setFileToUpload([...fileToUpload, file]);
  };
  const cancel = () => {
    setFileToUpload([]);
    setComment('');
    setBadFinancialCondition(false);

    const open = {
      modalName: 'submitConditionsChangeRequestModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = () => {
    const formData = new FormData();
    formData.append('RequestId', requestId);
    formData.append('Comment', comment);
    formData.append('BadFinancialCondition', badFinancialCondition);

    for (let i = 0; i < fileToUpload.length; i++) {
      formData.append('File', fileToUpload[i]);
    }

    setIsloading(true);
    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.UploadChangeConditions, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.backoffice.getRequestAction(requestId));
          dispatch(actions.backoffice.getGetUploadedFilesAction(requestId));
          dispatch(
            actions.backoffice.saveEditAdditionalComments('get', requestId)
          );
          dispatch(actions.backoffice.getRequestLogAction(requestId));

          cancel();

          swal({
            title: 'Paraiškos sąlygų keitimas patvirtintas',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          }).then((results) => {
            if (results) {
              window.location.reload();
            }
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else if (res.status === 401) {
          swal({
            title: `Įvyko klaida`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          res.json().then((r) => {
            console.log(r);

            swal({
              title: `${
                r.ErrorDescription ? r.ErrorDescription : 'Įvyko klaida'
              }`,
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Gerai',
                  value: true,
                  visible: true,
                  className: 'btn swal-ok-btn',
                  closeModal: true,
                },
              },
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });
  };
  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <div className={styles.changeConditionsModal}>
            <WithSpinner loading={isLoading}>
              <h2>Patvirtinti sutarties sąlygų keitimą</h2>
              
              <label
              className="containerLabel noBlock"
              style={{ marginTop: '1rem' }}
            >
              <input
                type="checkbox"
                name={'badFinancialCondition'}
                checked={badFinancialCondition}
                onChange={() => setBadFinancialCondition(!badFinancialCondition)}
              />
              <span className="checkmark"></span>
              <span className={styles.RedCheckboxTitle}>
              Prastėjanti finansinė būklė
              </span>
          </label>
              <div>
                <h4 style={{ marginTop: '20px' }}>Komentaras</h4>
                <textarea
                  style={{
                    width: '100%',
                    height: '80px',
                    marginBottom: '10px',
                    marginTop: '10px',
                    padding: '5px',
                    lineHeight: '1.2rem',
                  }}
                  placeholder=""
                  name={'InvegaReason'}
                  type="string"
                  onChange={onChangeContractCommentChange}
                  value={comment}
                />
              </div>
              <p>Prašome prisegti priedą sutarties sąlygų patvirtinimui</p>
              <div
                className={styles.addFileContainer}
                style={{ marginTop: '2rem' }}
              >
                <Button type="standart" onClick={() => selectFile('fileElem')}>
                  Prisegti priedą
                </Button>
                <input
                  type="file"
                  id="fileElem"
                  accept=".xlsx, .xls, .doc, .docx, .txt, .pdf, .adoc"
                  className="d-none"
                  onChange={(e) => selectFileToUpload(e.target.files[0])}
                />
                <div style={{ marginTop: '0.5rem' }}>
                  {fileToUpload && fileToUpload?.length > 0
                    ? fileToUpload.map((val, i) => (
                        <p key={i} style={{ marginTop: '0px' }}>
                          {val.name}
                        </p>
                      ))
                    : ''}
                </div>
              </div>
              <div className={styles.changeConditionsModal__btnContainer}>
                <Button type="standart" onClick={() => yes(requestId)}>
                  Patvirtinti
                </Button>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </WithSpinner>
          </div>
        </div>
      </div>
    )
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.dashboardState.isOnSync,
// });

export default connect()(SubmitConditionsChangeRequestModal);
