import { constants } from '..';

const loginVaiisisSagaAction = () => ({
  type: constants.auth.LOGIN_VAIISIS,
});

const setAuthLoadingAction = (flag) => ({
  type: constants.auth.SET_AUTH_LOADING,
  flag,
});

const loginUserAction = (credentials) => ({
  type: constants.auth.LOGIN_USER,
  credentials,
});

const setUserAction = (userDetails, token) => ({
  type: constants.auth.SET_USER,
  userDetails,
  token,
});

const mountUserAction = () => ({
  type: constants.auth.MOUNT_USER,
});

const clearUserAction = () => ({
  type: constants.auth.CLEAR_USER,
});

const loginTestViispCredAction = (credentials) => ({
  type: constants.auth.LOGIN_VAIISIS_TEST_CRED,
  credentials,
});

const setAuthError = () => ({
  type: constants.auth.SET_LOGIN_ERR_MESSAGE,
});

const pushTokenToServerAction = () => {
  return {
    type: constants.auth.PUSH_TOKEN_TO_SERVER,
  };
};

const setTokenPushStartAction = () => {
  return {
    type: constants.auth.SET_TOKEN_PUSH_STARTED,
  };
};

export const authActions = {
  loginVaiisisSagaAction,
  setAuthLoadingAction,
  loginUserAction,
  setUserAction,
  mountUserAction,
  clearUserAction,
  loginTestViispCredAction,
  setAuthError,
  pushTokenToServerAction,
  setTokenPushStartAction,
};
