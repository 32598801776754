import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../state';
import styles from './loginModal.module.scss';
import { WithSpinner } from './../../components';
import { Link } from 'react-router-dom';
import { config, isProd } from '../../api/config';
import swal from 'sweetalert';

const LoginModal = ({ dispatch, open, loginErr, loading }) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
    companyCode: '',
    personalCode: '',
  });

  const handleLoginInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const onTestLogin = () => {
    dispatch(actions.auth.loginTestViispCredAction(values));
  };

  const checkUrlAddress = () => {
    const url = window.location.hostname;
    if (url !== 'startuokpaskolos.invega.lt') {
      return true;
    } else {
      return false;
    }
  };

  const letLogin = () => {
    const getTime = new Date();
    const showAfter = new Date('2020-09-30T17:00:00.00');
    const showTill = new Date('2020-10-01T06:00:00.00');

    if (getTime > showAfter && getTime < showTill) {
      return false;
    }
    return true;
  };

  const showMessage = () => {
    swal({
      title:
        'Informuojame, kad dėl VĮ Registrų centro vykdomų informacinės sistemos darbų, nuo rugsėjo 30 d. (17 val.) iki spalio 1 d. (6 val.) nebus galimybės prisijungti ir pateikti paraiškų. Atsiprašome už laikinus nepatogumus.',
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'Gerai',
          value: true,
          visible: true,
          className: 'btn swal-ok-btn',
          closeModal: true,
        },
      },
    });
  };

  const goToMainWindow = () => {
    window.location.href = isProd()
      ? 'https://paslaugos.invega.lt/'
      : 'https://barzda.scorify.lt/';
  };

  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
          style={{ minHeight: '810px' }}
        >
          <WithSpinner loading={loading}>
            <div className={styles.loginInputsContainer}>
              <div>
                <h4>
                  Paraiškas gali teikti tik juridinio asmens
                  vadovas/verslininkas arba įgaliotas asmuo
                </h4>
              </div>
              <button
                type="button"
                className="btn ui-button btn-white  vaiisisloginbtn"
                onClick={(e) => goToMainWindow()}
              >
                {letLogin() ? (
                  <span>Prisijungti</span>
                ) : (
                  <span
                    className="btn btn-primary"
                    onClick={() => showMessage()}
                  >
                    Prisijungti per El. valdžios vartus
                  </span>
                )}
              </button>
            </div>
            <div className={styles.infoContainer}>
              <p style={{ fontSize: '1rem' }}>
                {' '}
                <b>
                  Prieš pradėdamas pildyti paraišką pareiškėjas privalo:
                </b>{' '}
              </p>
              <br />
              <p>
                1) Įsitikinti, kad paraiškos dėl Paskolos gavimo pateikimo metu
                Paskolos gavėjas ir Įmonių grupė (jei Paskolos gavėjas priklauso
                Įmonių grupei) yra pateikusi VĮ „Registrų centras“ paskutinių
                finansinių metų finansinių ataskaitų rinkinį, kuriame būtų
                detaliai atskleista nuosavo kapitalo sudėtis. Šis reikalavimas
                netaikomas verslininkams ir toms Įmonių grupės įmonėms, kurios
                yra įsteigtos nuo 2021 m. sausio 1 d.;
              </p>
              <p>
                2) Įsitikinti, kad Paskolos gavėjas ir Įmonių grupė (jei
                Paskolos gavėjas priklauso Įmonių grupei) 2019 m. gruodžio 31 d.
                nebuvo laikomi sunkumų patiriančiais, kaip tai apibrėžta 2014 m.
                birželio 17 d. Komisijos reglamente (ES) Nr. 651/2014, kuriuo
                tam tikrų kategorijų pagalba skelbiama suderinama su vidaus
                rinka taikant Sutarties 107 ir 108 straipsnius, su paskutiniais
                pakeitimais, padarytais 2020 m. liepos 2 d. Europos Komisijos
                reglamentu (ES) Nr. 2020/972 (toliau – Reglamentas Nr.
                651/2014), 2 straipsnio 18 punkte, arba 2019 m. gruodžio 31 d.
                Paskolos gavėjas ir Įmonių grupė (jei Paskolos gavėjas priklauso
                Įmonių grupei) buvo patiriantys sunkumų, kaip tai apibrėžta
                Reglamento Nr. 651/2014 2 straipsnio 18 punkte, tačiau paraiškos
                pateikimo metu nėra laikomi sunkumų patiriančiais (Paskolos
                gavėjas turi pagrįsti dokumentais, pateikdamas finansinės
                atskaitomybės už paskutinį praėjusį ketvirtį arba mėnesio,
                ėjusio prieš paraiškos pateikimą, paskutinę dieną dokumentus ir,
                jei per laikotarpį nuo 2020 m. sausio 1 d. iki paraiškos
                pateikimo dienos įvyko nuosavybės struktūros (kapitalo, akcijų
                priedų, perkainojimo rezervų, rezervų) pokyčių,– dokumentus,
                pagrindžiančius šiuos pokyčius (sukauptų nuostolių padengimo,
                kapitalo padidinimo apmokėjimo, turto vertinimo ir (arba) kitus
                susijusius dokumentus), išskyrus, kai dėl Paskolos kreipiasi
                Verslo subjektas, kuris yra labai maža ar maža įmonė, kaip
                nurodyta SVV įstatyme, arba verslininkas (kaip jis apibrėžtas
                SVV įstatyme), ir jei jiems nėra taikoma kolektyvinė nemokumo
                procedūra ir (ar) nėra suteikta sanavimo ir restruktūrizavimo
                pagalba. Verslininkas laikomas sunkumų patiriančiu, jei jam yra
                pradėta fizinio asmens bankroto procedūra.
                <br />
                Sunkumų patyrimas apskaičiuojamas pagal pateiktas skaičiuokles:
                <br />
              </p>
              <ul style={{ marginLeft: '3rem' }}>
                <li>
                  {' '}
                  Vidutinėms įmonėms:
                  <a
                    style={{ color: '#033878' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://invega.lt/lt/covid-19/kas-yra-sunkumu-patirianti-imone/sunkumu-vertinimo-skaiciuokle-mvi/"
                  >
                    {' '}
                    https://invega.lt/lt/covid-19/kas-yra-sunkumu-patirianti-imone/sunkumu-vertinimo-skaiciuokle-mvi/{' '}
                  </a>
                </li>
                <li>
                  {' '}
                  Didelėms įmonėms (DĮ):
                  <a
                    style={{ color: '#033878' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://invega.lt/lt/covid-19/kas-yra-sunkumu-patirianti-imone/sunkumu-vertinimo-skaiciuokle-di/"
                  >
                    {' '}
                    https://invega.lt/lt/covid-19/kas-yra-sunkumu-patirianti-imone/sunkumu-vertinimo-skaiciuokle-di/{' '}
                  </a>
                </li>
              </ul>

              <p>
                3) Įsitikinti, kad Paskolos gavėjas nėra vykdantis veiklą:
                <br />
                a) finansų ar draudimo srityse, kaip jis suprantamas pagal
                Lietuvos Respublikos finansinio tvarumo įstatymo 1 straipsnį
                <br />
                b) žemės ūkio, kaip tai apibrėžta 2014 m. birželio 25 d.
                Komisijos reglamento (ES) Nr. 702/2014, kuriuo skelbiama, kad
                tam tikrų kategorijų pagalba žemės bei miškų ūkio sektoriuose ir
                kaimo vietovėse yra suderinama su vidaus rinka pagal Sutarties
                dėl Europos Sąjungos veikimo 107 ir 108 straipsnius, 2
                straipsnio 3 dalyje
                <br />
                c) žuvininkystės ir akvakultūros, kaip tai apibrėžta 2014 m.
                birželio 27 d. Komisijos reglamento (ES) Nr. 717/2014, dėl
                Sutarties dėl Europos Sąjungos veikimo 107 ir 108 straipsnių
                taikymo de minimis pagalbai žuvininkystės ir akvakultūros
                sektoriuje, sektoriuose.
              </p>

              <br />
              <p style={{ fontSize: '1rem' }}>
                <b> Bendra informacija pareiškėjams teikiama:</b>
              </p>

              <p style={{ marginTop: '10px' }}>
                Elektroniniu paštu:{' '}
                <b>
                  <a
                    style={{ color: '#033878' }}
                    href="mailto:uzklausos@invega.lt"
                  >
                    uzklausos@invega.lt{' '}
                  </a>
                </b>
              </p>
              <p>
                Telefonu: <b>+370 5 210 7510</b>
              </p>

              <br />
            </div>
          </WithSpinner>
          {checkUrlAddress() && (
            <div style={{ marginTop: '109px' }}>
              <fieldset>
                <legend>Direktorius:</legend>
                <div>
                  <input
                    className="m-input"
                    placeholder="CompanyCode"
                    name="companyCode"
                    type="text"
                    onChange={handleLoginInputChange}
                  />
                  <input
                    className="m-input"
                    placeholder="PersonalCode"
                    name="personalCode"
                    type="text"
                    onChange={handleLoginInputChange}
                  />
                </div>
                <div>
                  <input
                    className="m-input"
                    placeholder="Email"
                    name="email"
                    type="text"
                    onChange={handleLoginInputChange}
                  />
                  <input
                    className="m-input"
                    placeholder="Password"
                    name="password"
                    type="text"
                    onChange={handleLoginInputChange}
                  />
                </div>
                <div>
                  <button type="button" onClick={(e) => onTestLogin()}>
                    Login
                  </button>
                </div>
              </fieldset>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loggedIn: state.auth.loggedIn,
  loginErr: state.auth.loginErr,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(LoginModal);
