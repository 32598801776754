import { constants } from '../constants';

const INITIAL_STATE = {
  loading: false,
  data: {},
  filesTableList: [],
  additionalComments: {
    ApproversComment: '',
    SignatorysComment: '',
  },
  requestLog: [],
  invegaUsers: [],
  AssessorsList: [],
  relatedRequests: [],
};

const PAYMENT_REQUEST_INITIAL_STATE = {
  loading: false,
  data: null,
};

const INVOICES_INITIAL_STATE = {
  loading: false,
  data: null,
};

const INVOICE_INITIAL_STATE = {
  loading: false,
  data: null,
};

const STATS_INITIAL_STATE = {
  loading: false,
  data: null,
};

const REPR_INITIAL_STATE = {
  representativesList: [],
  representative: {},
  loading: false,
  errors: [],
};

export const backofficeRequestsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.backoffice.LOAD_REQUESTS_LIST:
      return {
        ...state,
        loading: true,
      };
    case constants.backoffice.SET_REQUESTS_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case constants.backoffice.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.backoffice.SET_REQUEST:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case constants.backoffice.SET_REQUEST_STATUS:
      return {
        ...state,
        loading: action.payload,
      };

    case constants.backoffice.SET_PAYMENT_REQUEST_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case constants.backoffice.SET_UPLOADED_FILES_LIST:
      return {
        ...state,
        filesTableList: action.payload,
      };
    case constants.backoffice.GET_ADDITIONAL_COMMENTS:
      return {
        ...state,
        additionalComments: action.payload,
      };
    case constants.backoffice.SET_REQUEST_LOG:
      return {
        ...state,
        requestLog: action.payload,
      };
    case constants.backoffice.SET_REPRESENTAVITES_MANAGER_BY_REQUEST:
      return {
        ...state,
        representatives: action.payload,
      };
    case constants.backoffice.SET_INVEGA_USERS:
      return {
        ...state,
        invegaUsers: action.payload,
      };
    case constants.backoffice.SET_ASSESSORS:
      return {
        ...state,
        AssessorsList: action.payload,
      };
    case constants.backoffice.SET_RELATED_REQUESTS:
      return {
        ...state,
        relatedRequests: action.payload,
      };

    default:
      return state;
  }
};

export const backofficePaymentRequestsReducer = (
  state = PAYMENT_REQUEST_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case constants.backoffice.GET_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export const backofficeInvoicesReducer = (
  state = INVOICES_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case constants.backoffice.LOAD_INVOICE_LIST_DATA:
      return {
        ...state,
        loading: true,
      };
    case constants.backoffice.SET_INVOICE_LIST_DATA:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export const backofficeInvoiceReducer = (
  state = INVOICE_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case constants.backoffice.LOAD_INVOICE_DATA:
      return {
        ...state,
        loading: true,
      };
    case constants.backoffice.SET_INVOICE_DATA:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export const backofficeStatsReducer = (state = STATS_INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.backoffice.GET_STATS:
      return {
        ...state,
        loading: true,
      };
    case constants.backoffice.SET_STATS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export const backofficeRepresentetivesReducer = (
  state = REPR_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case constants.backoffice.SET_REPRESENTATIVES:
      return {
        ...state,
        representativesList: action.payload,
      };
    case constants.backoffice.SET_REPRESENTATIVE:
      return {
        ...state,
        representative: action.payload,
      };
    case constants.backoffice.SAVE_REPRESENTATIVE_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
