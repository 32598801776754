export const isNotEmptyString = (value) => {
  if (!value) {
    return 'Neįvestas privalomas laukas';
  }
  return '';
};

export const isValidIban = (value) => {
  if (!value) {
    return isNotEmptyString(value);
  }

  if (!value.match(/[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}/)) {
    return 'Neteisingas formatas';
  }
  return '';
};

export const isValidDropdown = (value, defaultMessage) => {
  if (!value && value !== false) {
    return defaultMessage || 'Nepasirinkta reikšmė';
  }
  return '';
};

export const isValidDateTillToday = (value) => {
  if (!value) {
    return isNotEmptyString(value);
  }
  const inputDate = new Date(value);
  const currDate = new Date();
  if (currDate - inputDate < 0) {
    return 'Neteisingai pasirinkta data';
  }
  return '';
};

export const isSumValid = (sum, minValue, maxValue) => {
  if (!sum && sum !== 0) {
    return 'Neįvesta suma. Formatas 0.00 EUR';
  }
  var ex = /^\d*\.?\d{0,2}$/;
  if (ex.test(sum) === false) {
    return 'Turi būti įvesti skaičiai (formatas 0.00)';
  }

  if (maxValue && sum > maxValue) {
    return `Suma negali būti didesnė už ${maxValue}`;
  }
  if (minValue && sum < minValue) {
    return `Suma negali būti mažesnė už ${minValue}`;
  }
  return '';
};

export const minimum2Symbols = (val) => {
  const re = /^([a-zA-Z0-9]+)$/im;
  const regex = re.test(val);
  if (!val) {
    return 'Laukas negali būti tuščias';
  }

  if (val?.length < 2) {
    return 'Privaloma įvesti bent 2 simbolius';
  }

  if (!regex) {
    return `Yra specialių simbolių. Turi būti tik skaičiai ir raidės.`;
  }
};
