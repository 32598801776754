import { constants } from '../constants';

const INITIAL_STATE = {
  globalErrorModalVisible: false,
  loginModalVisible: false,
  globalConfirmationModalVisible: false,
  requestsModalVisible: false,
  informationModalVisible: false,
  deleteComfirmationModalVisible: false,
  signContractModalVisible: false,
  financialCriteriaModalVisible: false,
  validationErrorModalVisible: false,
  confirmInvoicesFromManagerModalVisible: false,
  autorizationModalVisible: false,
  doublecheckEvaluationModalVisible: false,
  regenerateDecisionDocModalVisible: false,
  terminateRequestModalVisible: false,
  confirmWithFinancialCriteriaModalVisible: false,
  confirmWithoutFinancialCriteriaModalVisible: false,
  paymentRequestSendforApproveModalVisible: false,
  invegaLoanSumModalVisible: false,
  importFilesModalVisible: false,
  increaseInvegaLoanSumModalVisible: false,
  changeAdditionalInfoModalVisible: false,
  confirmRequestModalVisible: false,
  takeRequestToEvaluateModalVisible: false,
  changeContractFileVisible: false,
  changeConditionsModalVisible: false,
  acceptConditionsChangeRequestModalVisible: false,
  changeCompanySizeVisible: false,
  goBackConfirmationModalVisible: false,
  additionalVmiCheckConfirmationVisible: false,
  rejectConditionsChangeRequestVisible: false,
  changeConditionsBoModalVisible: false,
  globalMessageModalVisible: false,
  submitConditionsChangeRequestModalVisible: false,
  returnChangeConditionsModalVisible: false,
  confirmWithoutFinancialCriteriaForChangeConditionsModalVisible: false,
  returnModalVisible: false,
  confirmWithAdditionalDocsVisible: false,
  confirmRequestNewVisible: false,
  confirmRejectNewVisible: false,
changeSetNotDeletableVisible: false,
  surveyModalVisible: false,
  isOnSync: false,
  errMsg: '',
  validationErr: null,
  closedDialogOKMessage: null,
};

export const modalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.modals.SET_ON_SYNC_FLAG:
      return {
        ...state,
        isOnSync: action.flag,
      };
    case constants.modals.SET_MODAL_VISIBLE_FLAG:
      const { open, msgText } = action;
      const key = open.modalName + 'Visible';
      return {
        ...state,
        [key]: open.visible,
        msgText,
      };
    case constants.modals.SET_ERROR_MESSAGE:
      return {
        ...state,
        errMsg: action.msg,
      };
    case constants.modals.SET_VALIDATION_ERROR:
      return {
        ...state,
        validationErr: action.err,
      };
    case constants.modals.SET_MODAL_CLOSED_OK_MESSAGE:
      return {
        ...state,
        closedDialogOKMessage: action.action.message,
      };

    default:
      return state;
  }
};
