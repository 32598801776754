import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './terminateRequestModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';
import swal from 'sweetalert';
import { Api } from './../../api/api';
import { config } from './../../api/config';

const TerminateRequestModal = ({ open, id, loading, dispatch }) => {
  const [isLoading, setIsloading] = useState(false);

  const selectFile = (id) => {
    document.getElementById(id).click();
  };

  const cancel = () => {
    const open = {
      modalName: 'terminateRequestModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = (e) => {
    setIsloading(true);
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('Files', file);
    formData.append('RequestId', id);

    fetch(Api.API_BASE_URL + config.API_ENDPOINTS.Terminate, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok === true) {
          dispatch(actions.backoffice.getRequestAction(id));
          dispatch(actions.backoffice.getGetUploadedFilesAction(id));
          dispatch(actions.backoffice.saveEditAdditionalComments('get', id));
          dispatch(actions.backoffice.getRequestLogAction(id));
          swal({
            title: 'Sutartis sėkmingai nutraukta',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn btn-pink',
                closeModal: true,
              },
            },
          });
        } else if (res.status === 403) {
          swal({
            title: `Šiam veiksmui atlikti neturite reikiamų teisių. Kreipkitės į sistemos administratorių`,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        } else {
          console.log(res);

          swal({
            title: 'Nepavyko pateikti priedo ir nutraukti sutarties',
            icon: 'error',
            buttons: {
              confirm: {
                text: 'Gerai',
                value: true,
                visible: true,
                className: 'btn swal-ok-btn',
                closeModal: true,
              },
            },
          });
        }
      })
      .catch((err) => {
        swal({
          title: 'Įvyko klaida',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Gerai',
              value: true,
              visible: true,
              className: 'btn swal-ok-btn',
              closeModal: true,
            },
          },
        });
      })
      .finally(() => {
        setIsloading(false);
        cancel();
      });
  };
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner
            loading={loading === true || isLoading === true ? true : false}
          >
            <h2>Ar tikrai norite nutraukti šią sutartį?</h2>

            <div className={styles.btnContainer}>
              <Button
                className="standart"
                type="longBtn"
                color="#033878"
                onClick={() => selectFile('fileElem')}
                style={{ width: '100px', marginTop: '0px' }}
              >
                Įkelti paaiškinimą ir nutraukti
              </Button>
              <input
                type="file"
                id="fileElem"
                accept=".xlsx, .xls, .doc, .docx, .xml, .txt, .pdf"
                className="d-none"
                onChange={yes}
              />
              <div style={{ marginTop: '1rem' }}>
                <Button type="cancel" onClick={() => cancel()}>
                  Atšaukti
                </Button>
              </div>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(TerminateRequestModal);
