import React from 'react';
import { connect } from 'react-redux';
import styles from './deleteComfirmationModal.module.scss';
import { Button, WithSpinner } from '../../components';
import { actions } from '../../state/actions';

const DeleteComfirmationModal = ({ open, id, loading, dispatch }) => {
  const cancel = () => {
    const open = {
      modalName: 'deleteComfirmationModal',
      visible: false,
    };
    dispatch(actions.modals.setModalVisibleFlagAction(open));
  };

  const yes = (id) => {
    dispatch(actions.dashboard.deleteRequestAction(id));
  };
  return (
    open && (
      <div className={styles.container}>
        <div
          className={styles.modal}
          id="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal__title"
          aria-describedby="modal__desc"
        >
          <WithSpinner loading={loading}>
            <h2>Ar tikrai norite ištrinti šią paraišką?</h2>

            <div className={styles.btnContainer}>
              <Button type="standart" onClick={() => yes(id)}>
                Taip
              </Button>
              <Button type="cancel" onClick={() => cancel()}>
                Atšaukti
              </Button>
            </div>
          </WithSpinner>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  loading: state.dashboardState.isOnSync,
});

export default connect(mapStateToProps)(DeleteComfirmationModal);
